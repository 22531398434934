import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { DeleteModalIcon } from "../../../../utils/Icons";
import { deleteShowcaseRequest } from "../../../../store/User/actions";

const DeleteShowcase = ({ open, closeModal, showcaseInfo }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    company: {
      deleteShowcaseModal: {
        content,
        confirmationContent,
        subConfirmationContent
      }
    },
    shared
  } = messages[lang];

  const handleConfirmDelete = () => {
    dispatch(
      deleteShowcaseRequest({
        data: {
          Id: showcaseInfo.id
        },
        src: showcaseInfo.src,
        navigate
      })
    );
    closeModal();
  };

  const deleteShowcaseContent = () => {
    return (
      <div className="delete-product-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${showcaseInfo.name}"`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {subConfirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmDelete();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={deleteShowcaseContent()}
      hasActions={true}
      confirmBtnTxt={shared.delete}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default DeleteShowcase;

DeleteShowcase.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  showcaseInfo: PropTypes.object.isRequired
};
