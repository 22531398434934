import PropTypes from "prop-types";
import {
  Stepper as MuiStepper,
  Step,
  StepLabel
} from "@mui/material";
import { Check } from "@mui/icons-material";

import "./Stepper.scss";

const CustomStepIcon = ({ completed, icon, active, customIcon }) => (
  <div className="custom-step-icon-wrapper">
    {completed ? (
      <div className="step-count completed-step success-bg text-white circular-border-radius d-flex align-items-center justify-content-center">
        <Check fontSize="small" />
      </div>
    ) : active ? (
      <div className="step-count step-border light-primary-border circular-border-radius p-1">
        <div className="active-step w-100 h-100 light-blue-bg text-white circular-border-radius d-flex align-items-center justify-content-center">
          <span className="fweight-600 fsize-12">
            {customIcon || icon}
          </span>
        </div>
      </div>
    ) : (
      <div className="step-count inactive-step disabled-bg text-white circular-border-radius d-flex align-items-center justify-content-center">
        <span className="fweight-600 fsize-12">
          {customIcon || icon}
        </span>
      </div>
    )}
  </div>
);

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
  customIcon: PropTypes.element
};

const Stepper = ({
  steps,
  activeStep,
  alternativeLabel,
  wrapperClassName
}) => {
  return (
    <div className={`stepper-wrapper ${wrapperClassName}`}>
      <MuiStepper
        activeStep={activeStep}
        alternativeLabel={alternativeLabel}>
        {steps.map(({ label, className, customIcon }, i) => (
          <Step key={`step-${i}`} className={className}>
            <StepLabel
              StepIconComponent={(props) =>
                CustomStepIcon({ ...props, customIcon })
              }>
              <span className="fsize-13 fweight-600 title-color">
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </div>
  );
};

export default Stepper;

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]),
      customIcon: PropTypes.element,
      className: PropTypes.string
    })
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  alternativeLabel: PropTypes.bool,
  wrapperClassName: PropTypes.string
};

Stepper.defaultProps = {
  alternativeLabel: true
};
