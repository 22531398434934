export const LocationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
    {...props}>
    <path
      stroke="#767676"
      strokeWidth="1.2"
      d="M8.5 9.915a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z"></path>
    <path
      stroke="#767676"
      strokeWidth="1.2"
      d="M2.914 6.622C4.227.848 12.78.855 14.087 6.628c.767 3.387-1.34 6.254-3.187 8.027a3.462 3.462 0 01-4.806 0c-1.84-1.773-3.947-4.647-3.18-8.033z"></path>
  </svg>
);
