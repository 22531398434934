import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { Check, KeyboardArrowDown } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import noSubscription from "../../../assets/images/vectors/placeholders/noSearchFile.png";
import pendingSubscription from "../../../assets/images/vectors/placeholders/pendingSubscription.png";
import upgradePlan from "../../../assets/images/vectors/upgradePlan.png";
import premiumPlan from "../../../assets/images/vectors/premiumPlan.png";
import Button from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";
import LinearProgressBar from "../../../components/LinearProgressBar";
import DropdownMenu from "../../../components/DropdownMenu";
import Switch from "../../../components/Switch";
import CancelSubscription from "./CancelSubscription";
import VerificationsNotifications from "../../Exporters/Verification/VerificationsNotifications";
import {
  checkCanRenewSubscriptionRequest,
  enableAutomaticRenewalSubscriptionRequest,
  getUserSubscriptionPlanRequest
} from "../../../store/Subscription/actions";
import { getUserStatusRequest } from "../../../store/User/actions";
import {
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS,
  SUBSCRIPTION_PLANS_IDS,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import { formatDate } from "../../../utils/Helpers";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
// import AutomaticRenewal from "./AutomaticRenewal";

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const {
    myAccount: { subscriptionDetails },
    shared
  } = messages[lang];
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userId = useSelector((state) => state.auth.login?.Id);

  const delayedSubscriptionPlan = localStorage.getItem(
    `${userId}-delayed-automatic-subscription`
  );

  const [planPrice, setPlanPrice] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [
    cancelSubscriptionModalOpen,
    setCancelSubscriptionModalOpen
  ] = useState(false);

  useEffect(() => {
    dispatch(getUserStatusRequest());
  }, []);

  useEffect(() => {
    if (userStatusId === USER_STATUS_IDS.subscribed) {
      dispatch(getUserSubscriptionPlanRequest());
      localStorage.removeItem(
        `${userId}-delayed-automatic-subscription`
      );
    }
  }, [userStatusId]);

  useEffect(() => {
    if (userPlan && !!userPlan?.Features?.length) {
      setPlanPrice(
        userPlan?.Features?.find(
          (plan) => plan.Id === SUBSCRIPTION_PLAN_FEATURES_IDS.price
        )
      );
      setIsPremium(userPlan?.Id === SUBSCRIPTION_PLANS_IDS.premium);
    }
  }, [userPlan]);

  return (
    <div className="subscription-details-container">
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) && (
        <div className="mb-3">
          <VerificationsNotifications />
        </div>
      )}
      <div className="page-title pb-3 d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
        <Typography variant="h5" className="title-color mb-3 mb-md-0">
          {subscriptionDetails.subscriptionPlan}
        </Typography>
        {userPlan && !!Object.keys(userPlan).length && (
          <div className="title-buttons-container title-buttons-container d-flex justify-content-center gap-3 align-items-start align-items-md-center flex-column flex-md-row">
            {userStatusId === USER_STATUS_IDS.subscribed &&
              userPlan?.IsAutomaticSubscription && (
                <Button
                  label={
                    subscriptionDetails.actions.cancelSubscription
                  }
                  danger
                  outlined
                  onClick={() => setCancelSubscriptionModalOpen(true)}
                />
              )}
            <DropdownMenu
              menuName="manage-subscription"
              menuButton={
                <Button
                  label={
                    <>
                      <span>
                        {
                          subscriptionDetails.actions
                            .manageSubscription
                        }
                      </span>
                      <KeyboardArrowDown />
                    </>
                  }
                />
              }
              menuList={[
                userStatusId === USER_STATUS_IDS.subscribed &&
                  !userPlan?.IsAutomaticSubscription && {
                    label: (
                      <>
                        <span className="me-3">
                          {
                            subscriptionDetails.actions
                              .automaticRenewal
                          }
                        </span>
                        <Switch
                          checked={userPlan?.isAutomaticSubscription}
                          handleChange={() => {
                            dispatch(
                              enableAutomaticRenewalSubscriptionRequest()
                            );
                          }}
                        />
                        {/* <Switch
                          checked={isAutomaticSubscription}
                          handleChange={(isChecked) => {
                            setIsAutomaticSubscription(isChecked);
                          }}
                        /> */}
                      </>
                    ),
                    onClick: () => {}
                  },
                {
                  label:
                    subscriptionDetails.actions.renewSubscription,
                  onClick: () =>
                    dispatch(
                      checkCanRenewSubscriptionRequest({
                        params: {
                          subscriptionTypeId: userPlan?.Id
                        },
                        navigate,
                        route: ROUTE_PATHS.pricingDetails
                      })
                    )
                },
                userPlan?.Id !== SUBSCRIPTION_PLANS_IDS.premium && {
                  label: subscriptionDetails.actions.upgradePlan,
                  onClick: () =>
                    navigate(ROUTE_PATHS.pricingDetails, {
                      state: {
                        isUpgrade: true
                      }
                    })
                }
              ]}
            />
          </div>
        )}
      </div>
      <div className="bg-white border-radius-10 p-4">
        {userPlan && !!Object.keys(userPlan).length ? (
          <div className="plan-content d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <Box
              className="details-section"
              sx={{
                width: {
                  xs: "100%",
                  lg: "55%"
                }
              }}>
              <h6 className="fsize-17 fweight-600 title-color mb-4">
                {subscriptionDetails.currentPlan}
              </h6>
              <div className="plan-name d-flex align-items-center mb-3">
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(userPlan?.Icon)}`}
                  className="me-3"
                  style={{ width: "4rem" }}
                />
                <div>
                  <p className="mb-1 fsize-22 fweight-600 title-color">{`${userPlan?.Name} ${subscriptionDetails.plan}`}</p>
                  <p className="mb-0">
                    <span className="fsize-20 fweight-600 dark-blue-color">{`${planPrice?.CurrencyId === CURRENCIES_IDS.euro ? CURRENCIES_SYMBOLS.euro : CURRENCIES_SYMBOLS.usd}${planPrice?.Value}`}</span>
                    <span className="fsize-16 fweight-500 title-color">{` / ${subscriptionDetails.year}`}</span>
                  </p>
                </div>
              </div>
              <Box
                className={`border-radius-10 p-3 mb-3 ${userPlan?.RemainingDays > 0 ? "light-primary-bg" : "light-error-bg"}`}
                sx={{
                  width: {
                    xs: "100%",
                    md: "75%"
                  }
                }}>
                {userPlan?.RenewalDate && (
                  <span className="mb-3 d-flex align-items-center mb-2">
                    <span className="medium-text-color fsize-12 me-2">
                      {subscriptionDetails.renewalDate}
                    </span>
                    <span className="fweight-600">
                      {formatDate({ date: userPlan?.RenewalDate })}
                    </span>
                  </span>
                )}
                <div className="subscription-progress">
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <p className="fsize-12 text-color mb-0">
                      {subscriptionDetails.days}
                    </p>
                    <p className="fsize-12 text-color mb-0">{`${userPlan?.RemainingDays > 0 ? userPlan?.PassDays : userPlan?.TotalYearDays} ${subscriptionDetails.of} ${userPlan?.TotalYearDays} ${subscriptionDetails.days}`}</p>
                  </div>
                  <LinearProgressBar
                    total={userPlan?.TotalYearDays}
                    done={
                      userPlan?.PassDays <= userPlan?.TotalYearDays
                        ? userPlan?.PassDays
                        : userPlan?.TotalYearDays
                    }
                    color={
                      userPlan?.RemainingDays > 0
                        ? "primary"
                        : "error"
                    }
                  />
                  {userPlan?.RemainingDays > 0 ? (
                    <p className="fsize-11 medium-text-color mt-2">{`${userPlan?.RemainingDays} ${subscriptionDetails.daysRemaining}`}</p>
                  ) : (
                    <p className="fsize-11 text-error mt-2">{`${subscriptionDetails.expiredPlan}`}</p>
                  )}
                </div>
              </Box>
              <div className="features">
                <p className="fsize-16 fweight-600 text-color">
                  {subscriptionDetails.planFeatures}
                </p>
                <div className="row">
                  {userPlan?.Features?.filter(
                    (feature) =>
                      feature.Id !==
                      SUBSCRIPTION_PLAN_FEATURES_IDS.price
                  ).map(({ Name, Value, Id }, i) => (
                    <div
                      key={`plan-feature-${i}`}
                      className="col-12 col-xl-6">
                      <p
                        className={`mb-3 light-text-color fsize-14 fweight-600 plan-text d-flex ${
                          userPlan.id !==
                            SUBSCRIPTION_PLANS_IDS.premium &&
                          Id ===
                            SUBSCRIPTION_PLAN_FEATURES_IDS.priorityRanking &&
                          "ps-4 ms-2"
                        }`}>
                        {userPlan.id !==
                          SUBSCRIPTION_PLANS_IDS.premium &&
                        Id ===
                          SUBSCRIPTION_PLAN_FEATURES_IDS.priorityRanking ? null : (
                          <Check
                            className="me-2 plan-text"
                            color="success"
                          />
                        )}
                        <span>{`${Value} ${Name}`}</span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
            <Box
              className="avatar-section"
              sx={{
                width: {
                  xs: "100%",
                  lg: "40%"
                }
              }}>
              <div className="border-radius-10 px-4 py-5 light-border d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="fsize-24 fweight-500 title-color mb-0">
                    {isPremium
                      ? subscriptionDetails.avatars
                          .thankYouForChoosing
                      : subscriptionDetails.avatars.breakFromLimits}
                  </p>
                  <p className="fsize-24 fweight-500 text-green mb-1">
                    {isPremium
                      ? subscriptionDetails.avatars.premium
                      : subscriptionDetails.avatars.boostYourBusiness}
                  </p>
                  {isPremium && (
                    <p className="fsize-24 fweight-500 title-color mb-0">
                      {subscriptionDetails.avatars.keepGorwTogether}
                    </p>
                  )}
                  {!isPremium && (
                    <Button
                      label={shared.upgradeNow}
                      outlined
                      onClick={() =>
                        navigate(ROUTE_PATHS.pricingDetails, {
                          state: {
                            isUpgrade: true
                          }
                        })
                      }
                    />
                  )}
                  <div>
                    <img
                      src={isPremium ? premiumPlan : upgradePlan}
                      alt="upgrade-plan-vector"
                    />
                  </div>
                </div>
              </div>
            </Box>
          </div>
        ) : (
          <div>
            {!isLoading && (
              <EmptyState
                subTitle={
                  !!delayedSubscriptionPlan ? (
                    <span className="text-dark-blue">{`${subscriptionDetails.delayedSubscription.youSubscribedTo} ${delayedSubscriptionPlan == SUBSCRIPTION_PLANS_IDS.basic ? subscriptionDetails.delayedSubscription.basic : delayedSubscriptionPlan == SUBSCRIPTION_PLANS_IDS.standard ? subscriptionDetails.delayedSubscription.standard : subscriptionDetails.delayedSubscription.premium}`}</span>
                  ) : userStatusId ===
                    USER_STATUS_IDS.subscriptionExpired ? (
                    subscriptionDetails.subscriptionExpired
                  ) : (
                    subscriptionDetails.noSubscription
                  )
                }
                description={
                  !!delayedSubscriptionPlan ? (
                    <span className="light-text-color">
                      {
                        subscriptionDetails.delayedSubscription
                          .pendingPayment
                      }
                    </span>
                  ) : (
                    ""
                  )
                }
                showActionButton={!delayedSubscriptionPlan}
                buttonLabel={
                  userStatusId === USER_STATUS_IDS.subscriptionExpired
                    ? subscriptionDetails.renewNow
                    : subscriptionDetails.subscribeNow
                }
                imgSrc={
                  !!delayedSubscriptionPlan
                    ? pendingSubscription
                    : noSubscription
                }
                onButtonClick={() =>
                  navigate(ROUTE_PATHS.pricingDetails)
                }
                buttonClassName={"w-auto px-5 py-2"}
              />
            )}
          </div>
        )}
      </div>
      {cancelSubscriptionModalOpen && (
        <CancelSubscription
          open={cancelSubscriptionModalOpen}
          onClose={() => setCancelSubscriptionModalOpen(false)}
        />
      )}
      {/* {isAutomaticSubscription && (
        <AutomaticRenewal
          planId={userPlan?.Id}
          handleCancelAutomaticRenewal={() =>
            setIsAutomaticSubscription(false)
          }
        />
      )} */}
    </div>
  );
};

export default SubscriptionDetails;
