import PropTypes from "prop-types";

export const GreyProfileIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "17"}
    height={dimensions || "17"}
    fill="none"
    viewBox="0 0 25 25"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.717 11.442c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43c0-2.45 1.98-4.44 4.44-4.44a4.435 4.435 0 01.16 8.87zM7.717 15.132c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0z"></path>
  </svg>
);

GreyProfileIcon.propTypes = {
  dimensions: PropTypes.string
};
