import OffPlatform from "../../OffPlatform";

const UploadDocuments = () => {
  return (
    <div className="upload-documents-container">
      <OffPlatform />
    </div>
  );
};

export default UploadDocuments;
