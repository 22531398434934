import { axiosInstanceImporters } from ".";

const getSubCategoriesProductsList = async ({ params }) =>
  await axiosInstanceImporters.get("/api/SubCategory/GetById", {
    params,
    paramsSerializer: { indexes: null }
  });

const getAllSubCategoriesProductsList = async ({ params }) =>
  await axiosInstanceImporters.get(
    "/api/Product/GetBySubCategoryId",
    {
      params,
      paramsSerializer: { indexes: null }
    }
  );

const getSubCategoryProductDetails = async ({ id, currencyId }) =>
  await axiosInstanceImporters.get(
    `/api/Product/GetById/${id}/${currencyId}`
  );

const getProductsRecommendations = async (params) =>
  await axiosInstanceImporters.get(
    `/api/Product/GetProductRecommendation`,
    { params }
  );

export {
  getSubCategoriesProductsList,
  getAllSubCategoriesProductsList,
  getSubCategoryProductDetails,
  getProductsRecommendations
};
