import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import { FiltersIcon } from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import Button from "../Button";
import ModalDrawer from "../ModalDrawer";
import "./Filters.scss";

const Filters = ({
  FilterContent,
  handleClearFilters,
  handleApplyFilters,
  filterButtonClassName,
  disableApplyBtn,
  setIsModalOpen
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen && setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleConfirmSelect = () => {
    handleApplyFilters(true);
    handleClearFilters(false);
    setModalOpen(false);
  };

  const handleCancelSelect = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Button
        className={`px-0 ${filterButtonClassName}`}
        outlined
        whiteBg
        label={
          <span className={`d-flex align-items-center`}>
            <FiltersIcon fontSize="medium" />
            <span className="ps-3 d-none d-md-block">{`${shared.filters}`}</span>
          </span>
        }
        onClick={() => {
          setModalOpen(true);
        }}
      />

      <ModalDrawer
        open={modalOpen}
        maxWidth={"xs"}
        handleConfirm={handleConfirmSelect}
        handleClose={closeModal}
        modalClass={"filter-modal-drawer"}
        isContentText={false}
        content={FilterContent}
        contentClass={"px-0 overflowX-hidden scroll-within"}
        hasActions={true}
        confirmBtnTxt={shared.apply}
        cancelBtnTxt={shared.cancel}
        confirmBtnClass={`px-4  border-radius-8 action-btn`}
        cancelBtnClass={`px-4 border-radius-8 action-btn`}
        actionsClass={"justify-content-around"}
        handleCancel={handleCancelSelect}
        showCloseIcon={false}
        scrollMode="paper"
        disableConfirmBtn={disableApplyBtn}
        title={
          <div className="filters-modal-title d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Close className="pointer" onClick={closeModal} />
              <span className="fsize-20 ms-2">{shared.filters}</span>
            </div>
            <div
              onClick={() => {
                handleClearFilters(true);
                setTimeout(() => {
                  handleClearFilters(false);
                }, [500]);
              }}>
              <Typography className="fsize-14 fw-bold text-primary pointer">
                {shared.clearFilters}
              </Typography>
            </div>
          </div>
        }
      />
    </div>
  );
};

Filters.propTypes = {
  FilterContent: PropTypes.element.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  filterButtonClassName: PropTypes.string,
  disableApplyBtn: PropTypes.bool,
  setIsModalOpen: PropTypes.func
};

export default Filters;
