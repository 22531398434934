export const InfoNotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 0 17 17"
    {...props}>
    <path
      stroke="#21A1F6"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M8.887 5.111v2.22M8.9 2.151a4.438 4.438 0 00-4.44 4.44v1.4c0 .454-.187 1.134-.42 1.52l-.847 1.414c-.52.873-.16 1.846.8 2.166a15.56 15.56 0 009.82 0 1.48 1.48 0 00.8-2.166l-.846-1.414c-.234-.386-.42-1.073-.42-1.52v-1.4c-.007-2.44-2.007-4.44-4.447-4.44z"></path>
    <path
      stroke="#21A1F6"
      strokeMiterlimit="10"
      d="M11.107 13.365c0 1.22-1 2.22-2.22 2.22a2.224 2.224 0 01-1.567-.653c-.4-.4-.653-.96-.653-1.567"></path>
  </svg>
);
