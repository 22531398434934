export const RecordVoiceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 0 25 25"
    {...props}>
    <path
      stroke="#21A1F6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.032 16.046c2.21 0 4-1.79 4-4v-5.5c0-2.21-1.79-4-4-4s-4 1.79-4 4v5.5c0 2.21 1.79 4 4 4z"></path>
    <path
      stroke="#21A1F6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.382 10.196v1.7c0 4.22 3.43 7.65 7.65 7.65 4.22 0 7.65-3.43 7.65-7.65v-1.7M10.642 6.976c.9-.33 1.88-.33 2.78 0M11.232 9.096c.53-.14 1.08-.14 1.61 0M12.032 19.546v3"></path>
  </svg>
);
