import { axiosInstanceExporters } from ".";

const getVerificationMethods = async () =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/GetBusinessVerificationTypes"
  );

const getStripeClientSecret = async (params) =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/CreatePaymentIntent",
    { params }
  );

const confirmPaymentIntent = async (params) =>
  await axiosInstanceExporters.post(
    "/api/BusinessVerification/SendOnlinePaymentRequest",
    {},
    { params }
  );

const getExgatesBankInfo = async () =>
  await axiosInstanceExporters.get("/api/BankAccountInfo/Get");

const sendBankTransferRequest = async (data) =>
  await axiosInstanceExporters.post(
    "/api/BusinessVerification/SendBankTransferPaymentRequest",
    data
  );

const getCompanyVerificationStatus = async () =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/GetCurrentCompanyStatus",
    {
      params: { timestamp: new Date().getTime() }
    }
  );

const requestOffPlatformVerificaiton = async () =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/RequestRequiredDocs"
  );

const requestOffPlatformRequiredDocuments = async () =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/GetOFFPlatformRequiredDocuments"
  );

const uploadOffplatformRequestedDocuments = async (data) =>
  await axiosInstanceExporters.post(
    "/api/BusinessVerification/AddOFFPlatformRequestData",
    data
  );

const editOffplatformRequestedDocuments = async (data) =>
  await axiosInstanceExporters.put(
    "/api/BusinessVerification/EditOFFPlatformRequestData",
    data
  );

const requestOffPlatformRequestData = async () =>
  await axiosInstanceExporters.get(
    "/api/BusinessVerification/GetOFFPlatformRequestData"
  );

const confirmOffplatformPaymentIntent = async (params) =>
  await axiosInstanceExporters.put(
    "/api/BusinessVerification/SendOffPlatformOnlinePaymentRequest",
    {},
    { params }
  );

const cancelVerificationRequest = async () =>
  await axiosInstanceExporters.delete(
    "/api/BusinessVerification/DeleteRequest"
  );

export {
  getVerificationMethods,
  getStripeClientSecret,
  confirmPaymentIntent,
  getExgatesBankInfo,
  sendBankTransferRequest,
  getCompanyVerificationStatus,
  requestOffPlatformVerificaiton,
  requestOffPlatformRequiredDocuments,
  uploadOffplatformRequestedDocuments,
  requestOffPlatformRequestData,
  editOffplatformRequestedDocuments,
  confirmOffplatformPaymentIntent,
  cancelVerificationRequest
};
