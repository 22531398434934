import shared from "./shared";
import login from "./login";
import resetPassword from "./resetPassword";
import forgetPassword from "./forgetPassword";
import admins from "./admins";
import inputsValidations from "./inputsValidations";
import pagesTitles from "./pagesTitles";
import successMsgs from "./successMsgs";
import products from "./Products";
import home from "./home";
import subCategories from "./subCategories";
import exporters from "./exporters";
import search from "./search";
import upload from "./upload";
import notifications from "./notifications";
import registeration from "./registeration.js";
import categories from "./categories.js";
import company from "./company.js";
import verification from "./verification.js";
import myAccount from "./myAccount.js";
import settings from "./settings.js";
import subscription from "./subscription.js";
import productRequests from "./productRequests.js";
import subAccounts from "./subAccounts.js";
import contactUs from "./contactUs.js";
import aboutUs from "./aboutUs.js";
const en = {
  shared,
  login,
  resetPassword,
  forgetPassword,
  admins,
  inputsValidations,
  pagesTitles,
  successMsgs,
  products,
  home,
  subCategories,
  exporters,
  search,
  upload,
  notifications,
  registeration,
  categories,
  company,
  verification,
  myAccount,
  settings,
  subscription,
  productRequests,
  subAccounts,
  aboutUs,
  contactUs
};

export default en;
