import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";
import { Check } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import giftImg from "../../../assets/images/home/deals/gift.svg";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  checkCanRenewSubscriptionRequest,
  checkCanRenewSubscriptionResponse,
  getSubscriptionPlansDetailsRequest
} from "../../../store/Subscription/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS,
  generalImporterPagesPadding,
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  SUBSCRIPTION_PLANS_IDS
} from "../../../utils/Constants";
import { BoxCheckBgIcon, LoginBgIcon } from "../../../utils/Icons";
import { isExporterRole } from "../../../utils/Helpers";
import "./PricingDetails.scss";

const PricingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  const { subscription, shared } = messages[lang];

  const { plansDetails } = useSelector((state) => state.subscription);
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const isDowngrade = useSelector(
    (state) => state.subscription.checkCanRenewSuscription.IsDowngrade
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  useEffect(() => {
    dispatch(getSubscriptionPlansDetailsRequest());
    // return () => dispatch(checkCanRenewSubscriptionResponse({}));
  }, []);

  useEffect(() => {
    if (userType && userCurrentRole) {
      !isExporterRole(userCurrentRole, userType) &&
        navigate(ROUTE_PATHS.home);
    }
  }, [userType, userCurrentRole]);

  useEffect(() => {
    if (isDowngrade) {
      setDowngradeModalOpen(true);
    }
  }, [isDowngrade]);

  const renderModalContent = () => (
    <div className="text-center">
      <div className="avatar-section mb-3">
        <LoginBgIcon />
      </div>
      <p className="mb-3 fsize-18 fweight-600 title-color">
        {subscription.detailsPage.loginModal.needToLogin}
      </p>
      <p className="text-color mb-0">
        {subscription.detailsPage.loginModal.confirmMsg}
      </p>
    </div>
  );

  const renderDowngradeModalContent = () => (
    <div className="text-center">
      <div className="avatar-section mb-3">
        <BoxCheckBgIcon />
      </div>
      <p className="mb-3 fsize-18 fweight-600 title-color">
        {subscription.detailsPage.downgradePlan.downgradeYourPlan}
      </p>
      <p className="text-color mb-0">
        {subscription.detailsPage.downgradePlan.confirmMsg1}
        <span className="fweight-600">
          {subscription.detailsPage.downgradePlan.confirmMsg2}
        </span>
      </p>
    </div>
  );

  return (
    <div
      className={`pricing-details-page container-xxl ${generalImporterPagesPadding}`}>
      <Breadcrumbs
        list={[
          {
            label: subscription.breadcrumbs.home,
            link: ROUTE_PATHS.home
          },
          {
            label: subscription.breadcrumbs.pricing
          }
        ]}
      />
      <h3 className="title-color fsize-22 fweight-700 mb-3">
        {subscription.detailsPage.pricingPlans}
      </h3>
      <div className="big-news-section gradient-bg py-3 mb-5 d-flex justify-content-center border-radius-10">
        <div className="d-flex align-items-center justify-content-center w-lg-50 w-md-75 w-100 px-lg-0 px-4">
          <img src={giftImg} className="d-none d-md-block" />
          <div className="title-section">
            <h3 className="fsize-20 fweight-700 text-dark-blue">
              {subscription.detailsPage.bigNews}
            </h3>
            <p className="fsize-16 text-grey">
              {subscription.detailsPage.allFeaturesFree}
            </p>
          </div>
        </div>
      </div>
      <Box
        className="plans-section d-flex align-items-center justify-content-md-around justify-content-center flex-wrap mb-5"
        sx={{
          width: {
            xs: "100%",
            lg: "80%",
            margin: "0 auto"
          }
        }}>
        {plansDetails?.map(
          ({ Id: planId, Icon, Name, Features }, planIndex) => {
            const price = Features.find(
              (feature) =>
                feature.Id === SUBSCRIPTION_PLAN_FEATURES_IDS.price
            );
            return (
              <Box
                key={`plan-details-${planIndex}`}
                className={`plan-card faint-grey-bg border-radius-10 mb-4 ${
                  (location?.state?.isUpgrade
                    ? planId === SUBSCRIPTION_PLANS_IDS.premium
                    : userPlan?.Id
                      ? planId === userPlan?.Id
                      : planId === SUBSCRIPTION_PLANS_IDS.standard) &&
                  "active-plan"
                }`}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "60%",
                    md: "32%"
                  }
                }}>
                {((location?.state?.isUpgrade &&
                  planId === SUBSCRIPTION_PLANS_IDS.premium) ||
                  (userPlan?.Id
                    ? planId === userPlan?.Id
                    : planId ===
                      SUBSCRIPTION_PLANS_IDS.standard)) && (
                  <div className="active-plan-tag fsize-12 fweight-700 faint-blue-bg text-light-blue border-radius-50 py-2 px-3">
                    {location?.state?.isUpgrade &&
                    planId === SUBSCRIPTION_PLANS_IDS.premium
                      ? subscription.detailsPage.mostPopular
                      : userPlan?.Id
                        ? subscription.detailsPage.currentPlan
                        : subscription.detailsPage.mostPopular}
                  </div>
                )}
                <div className="p-xl-5 p-lg-3 p-4 ">
                  <div className="mb-3 light-border-bottom text-center header-section">
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(Icon)}`}
                      className="mb-2"
                    />
                    <p className="title-color fsize-20 fweight-600 plan-text">
                      {`${Name} ${subscription.detailsPage.plan}`}
                    </p>
                    <p className="text-dark-blue fsize-28 fweight-600 mb-1 plan-text">
                      {price?.CurrencyId === CURRENCIES_IDS.euro
                        ? CURRENCIES_SYMBOLS.euro
                        : CURRENCIES_SYMBOLS.usd}
                      {price?.Value}
                    </p>
                    <p className="medium-text-color plan-text">
                      {subscription.detailsPage.perYear}
                    </p>
                  </div>
                  <div className="features">
                    <p className="text-color fweight-600 plan-text">
                      {subscription.detailsPage.features}
                    </p>
                    {Features?.filter(
                      (feature) =>
                        feature.Id !==
                        SUBSCRIPTION_PLAN_FEATURES_IDS.price
                    ).map(({ Name, Value, Id }, i) => (
                      <p
                        className={`mb-4 light-text-color fsize-12 plan-text d-flex ${
                          planId !== SUBSCRIPTION_PLANS_IDS.premium &&
                          Id ===
                            SUBSCRIPTION_PLAN_FEATURES_IDS.priorityRanking &&
                          "ps-4 ms-2"
                        }`}
                        key={`plan-feature-${i}`}>
                        {planId !== SUBSCRIPTION_PLANS_IDS.premium &&
                        Id ===
                          SUBSCRIPTION_PLAN_FEATURES_IDS.priorityRanking ? null : (
                          <Check
                            className="me-2 plan-text"
                            color="success"
                          />
                        )}
                        <span>{`${Value} ${Name}`}</span>
                      </p>
                    ))}
                  </div>
                  <div className="w-100 text-center">
                    <Button
                      label={subscription.detailsPage.choosePlan}
                      reversedcolor={
                        location?.state?.isUpgrade
                          ? planId === SUBSCRIPTION_PLANS_IDS.premium
                          : userPlan?.Id
                            ? planId === userPlan?.Id
                            : planId ===
                              SUBSCRIPTION_PLANS_IDS.standard
                      }
                      disabled={
                        location?.state?.isUpgrade &&
                        userPlan?.Id &&
                        userPlan?.Id >= planId
                      }
                      onClick={() => {
                        if (isAuth) {
                          dispatch(
                            checkCanRenewSubscriptionRequest({
                              params: { subscriptionTypeId: planId },
                              navigate,
                              route:
                                ROUTE_PATHS.pricingDetailsPayment.replace(
                                  ":id",
                                  planId
                                )
                            })
                          );
                          setSelectedPlanId(planId);
                        } else {
                          setLoginModalOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </Box>
            );
          }
        )}
      </Box>
      {loginModalOpen && (
        <Modal
          open={loginModalOpen}
          title
          content={renderModalContent()}
          isContentText={false}
          confirmBtnTxt={subscription.detailsPage.loginModal.login}
          handleConfirm={() =>
            navigate(ROUTE_PATHS.login, {
              state: {
                prevLocation: location?.pathname
              }
            })
          }
          handleClose={() => setLoginModalOpen(false)}
        />
      )}
      {downgradeModalOpen && (
        <Modal
          open={downgradeModalOpen}
          title
          content={renderDowngradeModalContent()}
          isContentText={false}
          confirmBtnTxt={shared.continue}
          cancelBtnTxt={shared.cancel}
          handleConfirm={() => {
            setDowngradeModalOpen(false);
            setSelectedPlanId(null);
            dispatch(checkCanRenewSubscriptionResponse({}));
            navigate(
              ROUTE_PATHS.pricingDetailsPayment.replace(
                ":id",
                selectedPlanId
              )
            );
          }}
          handleCancel={() => {
            setDowngradeModalOpen(false);
            setSelectedPlanId(null);
            dispatch(checkCanRenewSubscriptionResponse({}));
          }}
          handleClose={() => {
            setDowngradeModalOpen(false);
            setSelectedPlanId(null);
            dispatch(checkCanRenewSubscriptionResponse({}));
          }}
        />
      )}
    </div>
  );
};

export default PricingDetails;
