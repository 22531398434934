import PropTypes from "prop-types";

export const GreyWhatsappIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "25"}
    height={dimensions || "25"}
    fill="none"
    viewBox="0 0 25 25"
    {...props}>
    <path
      fill="#455A64"
      d="M14.769 2.832c4.68.9 7.86 4.954 7.7 9.773-.147 4.444-3.742 8.317-8.27 8.872a9.55 9.55 0 01-5.318-.86 1.37 1.37 0 00-.84-.082c-1.356.325-2.701.695-4.05 1.046-.148.038-.3.064-.516.108.405-1.482.783-2.904 1.187-4.32.103-.357.074-.643-.091-.994-1.575-3.345-1.438-6.627.708-9.66 2.15-3.042 5.187-4.34 8.92-3.96.176.017.35.042.57.077zm5.914 11.004c.268-1.097.3-2.207.038-3.299-.788-3.287-2.834-5.418-6.16-6.092-3.263-.66-6.003.44-7.936 3.138-1.936 2.701-1.978 5.6-.38 8.503.205.373.26.68.135 1.078-.228.727-.405 1.471-.628 2.297.925-.24 1.736-.47 2.558-.65.233-.05.538-.023.742.088 4.696 2.585 10.278.2 11.63-5.064z"></path>
    <path
      fill="#455A64"
      d="M10.72 8.347c.18.427.3.84.51 1.203.3.518.21.953-.204 1.318-.445.392-.38.725-.06 1.175.735 1.036 1.658 1.813 2.823 2.322.32.14.563.164.77-.157.086-.132.206-.24.306-.364.583-.726.4-.72 1.324-.319.29.127.582.262.85.428.27.165.68.335.733.57.118.52-.048 1.048-.482 1.434-.8.712-1.72.83-2.725.552-2.174-.6-3.846-1.904-5.127-3.71-.452-.637-.856-1.344-1.11-2.078-.308-.894-.09-1.756.546-2.513.375-.445.83-.545 1.325-.426.199.048.338.344.522.565z"></path>
  </svg>
);

GreyWhatsappIcon.propTypes = {
  dimensions: PropTypes.string
};
