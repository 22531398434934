import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

import messages from "../../assets/locale/messages";
import {
  SignOutIcon,
  UserAccountCircleIcon
} from "../../utils/Icons";
import { getDeviceId } from "../../utils/Auth";
import {
  googleAnalyticsMeasurementId,
  USER_TYPES_IDS
} from "../../utils/Constants";
import { checkBackgroundImageValidity } from "../../utils/Helpers";
import { logoutRequest } from "../../store/Auth/actions";
import Avatar from "../Avatar";
import MenuDropDown from "../Menu";
import "./UserDataDropDownBtn.scss";

const UserProfileButton = ({
  className,
  contentClass,
  parentMenuList
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const login = useSelector((state) => state.auth.login);

  const { shared } = messages[lang];
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : "";
  const isUserLogged =
    localStorage.getItem(`${user.Id}-isLogged`) &&
    JSON.parse(localStorage.getItem(`${user.Id}-isLogged`));

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", googleAnalyticsMeasurementId, {
        page_path: location.pathname + location.search
      });
    }
  }, [location, window.gtag]);

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        device_id: getDeviceId(),
        navigate
      })
    );
  };
  const [isProfileImageValid, setIsProfileImageValid] =
    useState(false);

  useEffect(() => {
    if (login.ProfileImage) {
      checkBackgroundImageValidity(
        userType !== USER_TYPES_IDS.importer
          ? login?.CompanyLogo
          : login?.ProfileImage,
        (isValid) => {
          setIsProfileImageValid(isValid);
        }
      );
    } else {
      setIsProfileImageValid(false);
    }
  }, [login]);

  useEffect(() => {
    if (!isUserLogged) {
      dispatch(
        logoutRequest({
          device_id: getDeviceId(),
          navigate
        })
      );
    }
  }, [isUserLogged]);

  return (
    <div
      className={`h-100 UserDataDropDown-button-wrapper border-radius-8 pointer ${className} ${isProfileImageValid && " valid-admin-avatar"}`}>
      <MenuDropDown
        menuClass={"user-dropDown p-0"}
        buttonClassName={
          "no-shadow user-drop-down-btn mx-lg-0  mx-md-1 px-2 px-md-4 py-1 py-md-2"
        }
        mainText={
          <>
            <div className={`admin-avatar pe-md-2`}>
              {isProfileImageValid ? (
                <Avatar
                  name={shared.profileImg}
                  imgSrc={
                    userType !== USER_TYPES_IDS.importer
                      ? login?.CompanyLogo
                      : login?.ProfileImage
                  }
                  imgSrcAlt={shared.profileImg}
                  size={32}
                />
              ) : (
                <UserAccountCircleIcon className="account-circle" />
              )}
            </div>

            <div>
              <Typography
                className={`fsize-14 user-name d-none d-sm-block ${contentClass}`}
                sx={{ width: "max-content" }}>
                {`${shared.loggedInUserGreeting} ${userType !== USER_TYPES_IDS.importer ? (login?.CompanyName?.includes(" ") ? login?.CompanyName?.split(" ")[0] : login?.CompanyName) : login?.FirstName}`}
              </Typography>
            </div>
            <KeyboardArrowDownOutlined />
          </>
        }
        menuList={
          parentMenuList?.length
            ? [
                ...parentMenuList,
                {
                  icon: <SignOutIcon />,
                  label: shared.logout,
                  onClick: handleLogout
                }
              ]
            : [
                {
                  icon: <SignOutIcon />,
                  label: shared.logout,
                  onClick: handleLogout
                }
              ]
        }
      />
    </div>
  );
};

UserProfileButton.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  contentClass: PropTypes.string,
  parentMenuList: PropTypes.any
};

export default UserProfileButton;
