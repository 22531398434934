import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import snackbar from "./Snackbar/reducer";
import loader from "./Loader/reducer";
import navigation from "./Navigation/reducer";
import auth from "./Auth/reducer";
import products from "./Products/reducer";
import lookups from "./Lookups/reducer";
import search from "./Search/reducer";
import exporters from "./Exporters/reducer";
import home from "./Home/reducer";
import subCategoriesProducts from "./SubCategories/reducer";
import notifications from "./Notifications/reducer";
import categories from "./Categories/reducer";
import user from "./User/reducer";
import verification from "./Verification/reducer";
import myAccount from "./MyAccount/reducer";
import settings from "./Settings/reducer";
import subscription from "./Subscription/reducer";
import productRequests from "./ProductRequests/reducer";
import subAccounts from "./SubAccounts/reducer";

export default combineReducers({
  locale,
  snackbar,
  loader,
  navigation,
  auth,
  products,
  lookups,
  search,
  exporters,
  home,
  subCategoriesProducts,
  notifications,
  categories,
  user,
  verification,
  myAccount,
  settings,
  subscription,
  productRequests,
  subAccounts
});
