import { useSelector } from "react-redux";

import messages from "../../assets/locale/messages";
import notFoundImg from "../../assets/images/vectors/not-found-page.png";

const NotFound = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared: { notFound }
  } = messages[lang];

  return (
    <div className="not-found-container d-flex justify-content-center align-items-center white-bg border-radius-10 p-5">
      <div className="text-center">
        <img src={notFoundImg} className="mb-4" />
        <h6 className="fsize-24 fweight-600 w-80 mx-auto">
          <span className="text-dark-blue me-2">
            {notFound.sorry}
          </span>
          <span className="title-color">{notFound.noPermission}</span>
        </h6>
      </div>
    </div>
  );
};

export default NotFound;
