import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormHelperText, InputAdornment } from "@mui/material";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";

import messages from "../../assets/locale/messages";
import VoiceRecord from "../VoiceRecord";
import "./RichText.scss";

const RichTextInput = ({
  name,
  value,
  onChange,
  onKeyDown,
  id,
  label,
  type,
  placeholder,
  required,
  isInputHasErr,
  errMsg,
  disabled,
  multiline,
  rows,
  maxRows,
  minRows,
  startAdornment,
  endAdornment,
  fullWidth,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  inputEndAdorment,
  onChangeError,
  fileErrMsg,
  voiceInput,
  setTranscribedText,
  setIsAudioDescription,
  isAudioDescription,
  isAudioTranscribeSuccessful,
  setIsAudioTranscribeSuccessful,
  handleIsRecordingClicked,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];
  const [showVoiceRecord, setShowVoiceRecord] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const quillRef = useRef(null);
  const isInitializing = useRef(true);

  useEffect(() => {
    if (isFocused) {
      const quill = quillRef.current.getEditor();

      // Prevent pasting images
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        const ops = delta.ops.filter((op) => {
          // Check if the operation is an image
          return !(
            op.insert &&
            typeof op.insert === "object" &&
            op.insert.image
          );
        });
        return { ops };
      });
    }
    // Set initializing to false after the first render
    isInitializing.current = false;
  }, []);

  const handleChange = (content, delta, source, editor) => {
    if (isInitializing.current) return; // Skip onChange during initialization

    onChange(
      content.replace(/\s\s/g, ""),
      quillRef.current.getEditor().getText().replace("\n", "")
    );
  };
  return (
    <div
      className={`input-wrapper ${type === "file" && "file-input-wrapper"} ${inputWrapperClass}`}>
      <div className="rich-text-input">
        <span
          className={`fsize-13 fweight-300 title-color rich-text-label ${isFocused && "focused-label"} ${labelClassName} `}>
          {label}
          {labelAdornment && (
            <span className="ms-2">{labelAdornment}</span>
          )}
        </span>
        <ReactQuill
          bounds=".rich-text-input"
          contenteditable="true"
          theme="snow"
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          ref={quillRef}
          id={id || name}
          name={name}
          type={type}
          value={value}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          InputLabelProps={{
            required: false,
            shrink: true,
            variant: "outlined"
          }}
          disabled={disabled}
          error={isInputHasErr}
          fullWidth={fullWidth}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          minRows={minRows}
          className={`${inputClass} ${type === "file" && "file-input"} ${
            isFocused && "rich-text-focused"
          } ${!voiceInput && "rich-text-only-input"}`}
          disableunderline="true"
          autoComplete="off"
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">
                {startAdornment}
              </InputAdornment>
            ) : null,
            endAdornment,
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
          {...props}
        />
        {inputEndAdorment}
      </div>

      {voiceInput && (
        <VoiceRecord
          handleIsRecordingClicked={handleIsRecordingClicked}
          showVoiceRecord={showVoiceRecord}
          setShowVoiceRecord={setShowVoiceRecord}
          setTranscribedText={setTranscribedText}
          setIsAudioDescription={setIsAudioDescription}
          isAudioDescription={isAudioDescription}
          isAudioTranscribeSuccessful={isAudioTranscribeSuccessful}
          setIsAudioTranscribeSuccessful={
            setIsAudioTranscribeSuccessful
          }
        />
      )}
      <FormHelperText
        error={true}
        className={`${helperTextClass || "fsize-12"} ${
          lang === "en" ? "" : "text-end"
        } ${voiceInput && "mt-3"} ${fileErrMsg && "ps-3"}`}>
        {isInputHasErr
          ? inputsValidations[errMsg]
          : fileErrMsg || helperText}
      </FormHelperText>
    </div>
  );
};

export default RichTextInput;

RichTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  inputEndAdorment: PropTypes.element,
  startAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onChangeError: PropTypes.func,
  fileErrMsg: PropTypes.string,
  voiceInput: PropTypes.any,
  setTranscribedText: PropTypes.func,
  setIsAudioDescription: PropTypes.func,
  isAudioDescription: PropTypes.bool,
  isAudioTranscribeSuccessful: PropTypes.bool,
  setIsAudioTranscribeSuccessful: PropTypes.func,
  handleIsRecordingClicked: PropTypes.func
};

RichTextInput.defaultProps = {
  type: "text",
  fullWidth: true,
  value: ""
};
