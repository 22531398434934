import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormHelperText, Tooltip, Typography } from "@mui/material";
import * as Yup from "yup";
import { InfoOutlined } from "@mui/icons-material";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import messages from "../../../../assets/locale/messages";
import { showHideLoader } from "../../../../store/Loader/actions";
import store from "../../../../store";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../../utils/blobStorageServices";
import Button from "../../../../components/Button";
import Upload from "../../../../components/Upload";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import ProductCardGrid from "../../../../components/ProductCards/ProductCardGrid";
import {
  AddShowcaseProductIcon,
  CloseMediaIcon
} from "../../../../utils/Icons";
import AddShowcaseProduct from "./AddShowcaseProductModal";
import { getSubCategoriesListRequest } from "../../../../store/Lookups/actions";
import ScrollToFieldError from "../../../../components/ScrollToError";
import {
  addShowcaseRequest,
  editShowcaseRequest,
  getLoggedInExporterDetailsRequest
} from "../../../../store/User/actions";
import ChangeSubcategoryModal from "./ChangeSubcategoryModal";
import { getUserCurrency } from "../../../../utils/Helpers";
import "./AddShowcase.scss";

const AddShowcase = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const subCategoriesList = useSelector(
    (state) => state.lookups.subCategoriesList
  );

  const accountDetails = useSelector(
    (state) => state.user.accountDetails
  );
  const isPublished = useSelector(
    (state) => state.auth.login?.IsPublished
  );
  const coverPhotoElementRef = useRef();
  const websiteCoverPhotoElementRef = useRef();
  const {
    shared,
    company,
    company: {
      addShowcase: { inputLabels, placeholders, subcategoryWarning }
    },
    inputsValidations
  } = messages[lang];
  const [coverPhotoError, setCoverPhotoError] = useState(null);
  const [websiteCoverPhotoError, setWebsiteCoverPhotoError] =
    useState(null);
  const [productsList, setProductsList] = useState([]);
  const [productsCardsList, setProductsCardsList] = useState([]);
  const [selectedProductsId, setSelectedProductsId] = useState([]);
  const [addProductModalOpen, setAddProductModalOpen] = useState();
  const [subCategoriesOptions, setSubcategoriesOptions] = useState(
    []
  );
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState(false);

  const [changeSubcategoryModalOpen, setChangeSubcategoryModalOpen] =
    useState(false);
  const [
    confirmChangeSubcategoryChange,
    setConfirmSubcategoryChange
  ] = useState(false);

  const [tempSubcategory, setTempSubcategory] = useState();
  const [editedShowcase, setEditedShowcase] = useState();

  useEffect(() => {
    dispatch(getLoggedInExporterDetailsRequest());
  }, []);

  useEffect(() => {
    if (accountDetails && Object.keys(accountDetails).length) {
      dispatch(
        editedShowcase
          ? getSubCategoriesListRequest({
              userId: accountDetails.ExporterId,
              editId: editedShowcase.SubcategoryId
            })
          : getSubCategoriesListRequest({
              userId: accountDetails.ExporterId
            })
      );
    }
  }, [accountDetails, editedShowcase]);

  useEffect(() => {
    if (subCategoriesList?.length) {
      setSubcategoriesOptions(
        subCategoriesList.map((subcategory) => {
          return {
            id: subcategory.Id,
            label: subcategory.Name
          };
        })
      );
    }
  }, [subCategoriesList]);

  useEffect(() => {
    if (accountDetails && Object.keys(accountDetails).length) {
      setEditedShowcase(
        accountDetails?.ShowCases?.filter(
          (showcase) => showcase.Id == id
        )[0]
      );
    }
  }, [accountDetails, id]);

  const optionalSchema = Yup.object({
    subcategory: Yup.object().required("showcaseSubCategoryRequired"),
    description: Yup.string()
      .nullable()
      .max(500, "showcaseDescriptionLong"),
    coverPhoto: Yup.string().required("coverPhotoRequired"),
    products: Yup.array(),
    websiteCoverPhoto: Yup.string().required(
      "websiteCoverPhotoRequired"
    )
  });
  const requiredSChema = Yup.object({
    subcategory: Yup.object().required("showcaseSubCategoryRequired"),
    description: Yup.string()
      .nullable()
      .max(500, "showcaseDescriptionLong"),
    coverPhoto: Yup.string().required("coverPhotoRequired"),
    products: Yup.array()
      .min(1, "showcaseProductsRequired")
      .required("showcaseProductsRequired"),
    websiteCoverPhoto: Yup.string().required(
      "websiteCoverPhotoRequired"
    )
  });

  const formik = useFormik({
    initialValues: {
      subcategory: null,
      description: "",
      coverPhoto: "",
      products: [],
      websiteCoverPhoto: ""
    },
    validationSchema: isPublished ? requiredSChema : optionalSchema,

    onSubmit: async ({
      subcategory,
      description,
      coverPhoto,
      dbCoverPhoto,
      coverPhotoInfo,
      websiteCoverPhoto,
      dbWebsiteCoverPhoto,
      websiteCoverPhotoInfo
    }) => {
      store.dispatch(showHideLoader(true));

      let coverPhotoImgName = "";
      let websiteCoverPhotoImgName = "";

      const dbData = {
        dbCoverPhoto,
        coverPhotoInfo,
        dbWebsiteCoverPhoto,
        websiteCoverPhotoInfo
      };
      const companyData = {
        Name: subcategory.label,
        Description: description,
        SubcategoryId: subcategory.id,
        CoverImage: "",
        WebsiteCoverImage: "",
        ProductIds: selectedProductsId
      };

      if (coverPhoto) {
        if (coverPhotoInfo.isNew) {
          coverPhotoImgName = await UploadBlobToContainer(
            {
              blob: coverPhoto,
              ...coverPhotoInfo
            },
            BLOB_CONTAINERS.images
          );
        } else {
          coverPhotoImgName = coverPhoto.split("/images/")[1];
        }
        companyData.CoverImage = coverPhotoImgName;
      }

      if (websiteCoverPhoto) {
        if (websiteCoverPhotoInfo.isNew) {
          websiteCoverPhotoImgName = await UploadBlobToContainer(
            {
              blob: websiteCoverPhoto,
              ...websiteCoverPhotoInfo
            },
            BLOB_CONTAINERS.images
          );
        } else {
          websiteCoverPhotoImgName =
            websiteCoverPhoto.split("/images/")[1];
        }
        companyData.WebsiteCoverImage = websiteCoverPhotoImgName;
      }

      if (id) {
        companyData.Id = id;
        dispatch(
          editShowcaseRequest({
            data: {
              ...companyData
            },
            dbData,
            navigate
          })
        );
      } else {
        dispatch(
          addShowcaseRequest({
            data: {
              ...companyData
            },
            dbData,
            navigate
          })
        );
      }
      store.dispatch(showHideLoader(false));
    }
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    setValues
  } = formik;

  const fillInProductCards = (productsArr) => {
    const tempProductsCardsArray = [];
    if (productsArr?.length <= 6) {
      let emptyProductsIndex = 0;
      for (let index = 0; index < 6; index++) {
        if (!!productsArr.length && productsArr[index]) {
          const {
            Id,
            Name,
            DefaultImage,
            MinOrderQuantity,
            MoqUnitName,
            MinPriceRange,
            MaxPriceRange
          } = productsArr[index];
          tempProductsCardsArray.push(
            <div
              className={`single-product filled-product border-radius-10  editable-product-card`}>
              <div
                className="clear-product-icon"
                onClick={() => {
                  const filteredProductsList = productsList.filter(
                    (product) => product.Id !== Id && product.Id
                  );
                  const filteredProductsListId =
                    filteredProductsList.map((product) => product.Id);
                  setProductsList(filteredProductsList);
                  setSelectedProductsId(filteredProductsListId);
                }}>
                <CloseMediaIcon />
              </div>

              <ProductCardGrid
                cardClassName="w-100"
                key={Id}
                label={Name}
                src={DefaultImage}
                count={MinOrderQuantity}
                unit={MoqUnitName}
                minPrice={MinPriceRange}
                maxPrice={MaxPriceRange}
                currency={getUserCurrency()?.symbol}
              />
            </div>
          );
        } else {
          if (emptyProductsIndex === 0) {
            tempProductsCardsArray.push(
              !values?.subcategory ? (
                <Tooltip
                  classes={{
                    tooltip: "light-blue-tooltip-fill-icon ms-5"
                  }}
                  className="text-secondary"
                  title={
                    <ul className="mb-0 ps-0">
                      <li>{subcategoryWarning}</li>
                    </ul>
                  }>
                  <div
                    key={"add-sign-card"}
                    className={`single-product first-product-placeholder pointer border-radius-10 ${!values.subcategory && "disabled"}`}
                    onClick={() => {
                      if (!!values.subcategory) {
                        setFieldTouched("products");
                        setAddProductModalOpen(true);
                      }
                    }}>
                    <AddShowcaseProductIcon />
                    <Typography>{inputLabels.addProducts}</Typography>
                  </div>
                </Tooltip>
              ) : (
                <div
                  key={"add-sign-card"}
                  className={`single-product first-product-placeholder pointer border-radius-10 ${!values.subcategory && "disabled"}`}
                  onClick={() => {
                    if (!!values.subcategory) {
                      setFieldTouched("products");
                      setAddProductModalOpen(true);
                    }
                  }}>
                  <AddShowcaseProductIcon />
                  <Typography>{inputLabels.addProducts}</Typography>
                </div>
              )
            );
          } else {
            tempProductsCardsArray.push(
              <div
                key={`empty-card + ${emptyProductsIndex}`}
                className="single-product border-radius-10"></div>
            );
          }
          emptyProductsIndex += 1;
        }
      }
    }
    setProductsCardsList(tempProductsCardsArray);
  };

  useEffect(() => {
    fillInProductCards(productsList);
    setValues({ ...values, products: productsList });
  }, [productsList, values.subcategory]);

  useEffect(() => {
    if (!confirmChangeSubcategoryChange) return;

    let subcategoryDescription = "";
    if (tempSubcategory) {
      const selectedSubcategory = subCategoriesList.find(
        (subcategory) => subcategory?.Id === tempSubcategory?.id
      );
      subcategoryDescription = selectedSubcategory?.Description || "";
    }

    setValues((prevValues) => ({
      ...prevValues,
      subcategory: tempSubcategory,
      description: subcategoryDescription
    }));

    setConfirmSubcategoryChange(false);
    setProductsList([]);
    setProductsCardsList([]);
    setSelectedProductsId([]);
  }, [
    confirmChangeSubcategoryChange,
    tempSubcategory,
    subCategoriesList
  ]);

  useEffect(() => {
    if (editedShowcase && Object.keys(editedShowcase).length) {
      const {
        Description,
        Image,
        WebsiteCoverImage,
        SubcategoryId,
        SubcategoryName,
        Products
      } = editedShowcase;
      setValues({
        ...values,
        subcategory: { id: SubcategoryId, label: SubcategoryName },
        description: Description,
        coverPhoto: Image,
        dbCoverPhoto: Image,
        coverPhotoInfo: { isNew: false },
        websiteCoverPhoto: WebsiteCoverImage,
        dbWebsiteCoverPhoto: WebsiteCoverImage,
        websiteCoverPhotoInfo: { isNew: false },
        products: Products.map((product) => product.Id)
      });
      setFieldTouched("products");
      setSelectedProductsId(Products.map((product) => product.Id));
      setProductsList(Products);
      setSelectedProductsId(Products.map((product) => product.Id));
    }
  }, [accountDetails, subCategoriesList, editedShowcase]);

  return (
    <div className="add-showcase-container">
      <div className="page-title pb-3">
        <Breadcrumbs
          list={[
            {
              label: company.addShowcase.breadCrumbs.company,
              link: ROUTE_PATHS.companyProfile
            },
            {
              label: company.addShowcase.breadCrumbs.showcasesSetup
            }
          ]}
        />
        <Typography variant="h5" className="title-color">
          {company.addShowcase.title}
        </Typography>
      </div>
      <form
        className="add-showcase-form gap-5"
        onSubmit={handleSubmit}
        noValidate>
        <ScrollToFieldError
          formik={formik}
          submitButtonClicked={submitButtonClicked}
          handleSubmitButtonClicked={setSubmitButtonClicked}
        />
        <div className="company-details-container bg-white w-100 h-100 p-4 border-radius-8">
          <div>
            <Typography className="fweight-600 fsize-16">
              {company.addShowcase.showcaseInfo}
            </Typography>
          </div>
          <div
            className={`add-showcase-inputs-container d-flex my-4`}>
            <Select
              key={inputLabels.subcategory}
              wrapperClass="add-showcase-form-field"
              labelClassName="pb-1 font-medium"
              label={inputLabels.subcategory}
              placeholder={placeholders.subcategory}
              options={subCategoriesOptions}
              onChange={(value) => {
                let subcategoryDescription = "";
                if (!!value) {
                  subcategoryDescription = subCategoriesList.filter(
                    (subcategory) => subcategory.Id === value.id
                  )[0].Description;
                }
                if (values.subcategory && productsList.length !== 0) {
                  setChangeSubcategoryModalOpen(true);
                  setTempSubcategory(value);
                } else {
                  setFieldTouched("subcategory");
                  setValues({
                    ...values,
                    subcategory: value,
                    description: subcategoryDescription
                  });
                }
              }}
              value={values.subcategory}
              hasError={!!(touched.subcategory && errors.subcategory)}
              errMsg={errors.subcategory}
              id="subcategory"
              name="subcategory"
            />
          </div>
          <div className="add-showcase-inputs-container w-100 my-4">
            <Input
              key={inputLabels.description}
              label={inputLabels.description}
              placeholder={placeholders.description}
              labelClassName="pb-2 font-medium   main-text-color"
              rows={4}
              multiline
              onChange={(value) => {
                if (!value || !!value.trim()) {
                  const modifiedValue = value
                    .replace(/\s\s/g, " ")
                    .replace("\n\n", "\n");
                  setFieldTouched("description");
                  setFieldValue("description", modifiedValue);
                }
              }}
              value={values.description}
              isInputHasErr={
                !!(touched.description && errors.description)
              }
              errMsg={errors.description}
              name="description"
              id="description"
            />
          </div>
          <div className="showcase-img-products-container">
            <div
              className={`my-5 mt-lg-0 my-lg-0 showcase-img-container mb-md-5 col-12`}
              id={"coverPhoto"}>
              <div className="block-title d-flex gap-2 align-items-md-center align-items-start pb-2">
                <Typography>{inputLabels.coverPhoto}</Typography>
                <Tooltip
                  classes={{
                    tooltip: "light-blue-tooltip-fill-icon"
                  }}
                  className="text-secondary"
                  title={
                    <ul className="mb-0 ps-0">
                      <li>{inputsValidations.uploadImgSizeError}</li>
                      <li>
                        {inputsValidations.uploadImgResolutionError}
                      </li>
                      <li>{inputsValidations.uploadImgTypeError}</li>
                    </ul>
                  }>
                  <InfoOutlined />
                </Tooltip>
              </div>
              <Upload
                customPlaceholder
                placeholderText={inputLabels.coverPhoto}
                isShowcase
                isEditOnly
                hideErrMsg
                isCoverUpload
                className={`cover-upload`}
                touchInput={() => {
                  setFieldTouched("coverPhoto");
                }}
                onClear={() => {
                  setFieldTouched("coverPhoto");
                  setValues({
                    ...values,
                    coverPhoto: null,
                    coverPhotoInfo: {}
                  });
                }}
                label={<div ref={coverPhotoElementRef}></div>}
                userImage={values.coverPhoto}
                onChange={(img) => {
                  setFieldTouched("coverPhoto");
                  setValues({
                    ...values,
                    coverPhoto: img.blob,
                    coverPhotoInfo: {
                      ...img?.imageInfo,
                      isNew: true
                    }
                  });
                }}
                name="coverPhoto"
                isCropMode={true}
                cropAspect={1 / 1.25}
                onChangeError={setCoverPhotoError}
                isInputHasErr={
                  coverPhotoError ||
                  (touched.coverPhoto && errors.coverPhoto)
                }
                errMsg={coverPhotoError || errors.coverPhoto}
              />
              {(coverPhotoError ||
                (touched.coverPhoto &&
                  inputsValidations[errors.coverPhoto])) && (
                <div>
                  <FormHelperText
                    error={true}
                    className={`${"fsize-12 mt-2"} ${
                      lang === "en" ? "" : "text-end"
                    } `}>
                    {coverPhotoError ||
                      (touched.coverPhoto &&
                        inputsValidations[errors.coverPhoto])}
                  </FormHelperText>
                </div>
              )}
            </div>
            <div className="products-label-container w-100">
              <Typography className="pb-2 ps-xl-5">
                {inputLabels.addProducts}
                <span className=" ms-2 text-secondary">
                  {placeholders.maxProductNumber}
                </span>
              </Typography>
              <div
                className="products-container d-flex flex-wrap gap-3 ps-xl-5"
                id="products">
                {productsCardsList.map((product) => product)}
              </div>
              <div className="ps-xl-5">
                {touched.products &&
                  inputsValidations[errors.products] && (
                    <div>
                      <FormHelperText
                        error={true}
                        className={`${"fsize-12 mt-2"} ${
                          lang === "en" ? "" : "text-end"
                        } `}>
                        {touched.products &&
                          inputsValidations[errors.products]}
                      </FormHelperText>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-info-container bg-white w-100 h-100 my-4 p-4 border-radius-8">
          <div className="container-title">
            <div>
              <div className="block-title d-flex gap-2 align-items-center mb-3 mt-2">
                <Typography className="fweight-600 fsize-16 ">
                  {inputLabels.websiteCoverPhoto}
                </Typography>
                <Tooltip
                  classes={{
                    tooltip: "light-blue-tooltip-fill-icon"
                  }}
                  className="text-secondary"
                  title={
                    <ul className="mb-0 ps-0">
                      <li>{inputsValidations.uploadImgSizeError}</li>
                      <li>
                        {inputsValidations.uploadImgResolutionError}
                      </li>
                      <li>{inputsValidations.uploadImgTypeError}</li>
                    </ul>
                  }>
                  <InfoOutlined />
                </Tooltip>
              </div>

              <div className="block-subtitle text-secondary text-break">
                <Typography>
                  {placeholders.websiteCoverPhoto}
                </Typography>
              </div>
            </div>
            <div
              className={`mt-2 ${values.coverPhoto ? "company-cover-img-container-filled" : "company-cover-img-container mb-md-0 mb-3 "}  col-12`}
              id={"websiteCoverPhoto"}>
              <Typography>{inputLabels.companyCoverImage}</Typography>
              <Upload
                customPlaceholder
                isFitContainer
                placeholderText={inputLabels.coverPhoto}
                isEditOnly
                hideErrMsg
                isCoverUpload
                className={`cover-upload`}
                touchInput={() => {
                  setFieldTouched("websiteCoverPhoto");
                }}
                onClear={() => {
                  setFieldTouched("websiteCoverPhoto");
                  setValues({
                    ...values,
                    websiteCoverPhoto: null,
                    websiteCoverPhotoInfo: {}
                  });
                }}
                label={<div ref={websiteCoverPhotoElementRef}></div>}
                userImage={values.websiteCoverPhoto}
                onChange={(img) => {
                  setFieldTouched("websiteCoverPhoto");
                  setValues({
                    ...values,
                    websiteCoverPhoto: img.blob,
                    websiteCoverPhotoInfo: {
                      ...img?.imageInfo,
                      isNew: true
                    }
                  });
                }}
                name="websiteCoverPhoto"
                isCropMode={true}
                cropAspect={5.4 / 1}
                onChangeError={setWebsiteCoverPhotoError}
                isInputHasErr={
                  websiteCoverPhotoError ||
                  (touched.websiteCoverPhoto &&
                    errors.websiteCoverPhoto)
                }
                errMsg={
                  websiteCoverPhotoError || errors.websiteCoverPhoto
                }
              />
            </div>
            {(websiteCoverPhotoError ||
              (touched.websiteCoverPhoto &&
                inputsValidations[errors.websiteCoverPhoto])) && (
              <div>
                <FormHelperText
                  error={true}
                  className={`${"fsize-12 mt-3"} ${
                    lang === "en" ? "" : "text-end"
                  } `}>
                  {websiteCoverPhotoError ||
                    (touched.websiteCoverPhoto &&
                      inputsValidations[errors.websiteCoverPhoto])}
                </FormHelperText>
              </div>
            )}
          </div>
        </div>
        <div
          className={`form-buttons-container bg-white d-flex justify-content-between flex-wrap w-100 mt-4  p-md-4 p-3 bg-white border-radius-8`}>
          <Button
            key={shared.cancel}
            outlined
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={shared.cancel}
            onClick={() => navigate(ROUTE_PATHS.companyProfile)}
          />
          <Button
            key={id ? shared.save : shared.submit}
            type="button"
            onClick={() => {
              setSubmitButtonClicked(true);
              handleSubmit();
            }}
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={id ? shared.save : shared.submit}
          />
        </div>
      </form>
      {addProductModalOpen && (
        <AddShowcaseProduct
          open={addProductModalOpen}
          setShowcaseProductsIds={setSelectedProductsId}
          setShowcaseProducts={setProductsList}
          showcaseProducts={productsList}
          handleClose={() => {
            setAddProductModalOpen(false);
          }}
          subcategoryId={values?.subcategory?.id}
          showcaseId={id}
        />
      )}
      {changeSubcategoryModalOpen && (
        <ChangeSubcategoryModal
          handleClose={() => {
            setChangeSubcategoryModalOpen(false);
          }}
          open={changeSubcategoryModalOpen}
          handleConfirm={setConfirmSubcategoryChange}
          handleCancel={() => {
            setConfirmSubcategoryChange(false);
          }}
        />
      )}
    </div>
  );
};

export default AddShowcase;
