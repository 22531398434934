import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../assets/locale/messages";
import {
  checkBackgroundImageValidity,
  checkForMultiline,
  isExporterRole,
  isRichText,
  RichTextToHTML
} from "../../utils/Helpers";
import CountryFlag from "../CountryFlag";
import { USER_STATUS_IDS } from "../../utils/Constants";
import noImgPlaceholder from "../../assets/images/empty_data.png";
import "./ProductRequestCard.scss";

const ProductRequestCard = ({
  label,
  count,
  minPrice,
  maxPrice,
  link,
  unit,
  time,
  description,
  importerImg,
  importerName,
  importerCountry,
  importerCountyShort,
  currency
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    productRequests: { websiteProductRequests }
  } = messages[lang];

  const [isImporterImgValid, setIsImporterImgValid] = useState(false);
  useEffect(() => {
    checkBackgroundImageValidity(importerImg, (isLoaded) => {
      setIsImporterImgValid(isLoaded);
    });
  }, [importerImg]);

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const isNotAllowedToSeeData = isAuth
    ? isExporterRole(userCurrentRole, userType)
      ? userStatusId !== USER_STATUS_IDS.subscribed
      : true
    : true;

  const renderCardContent = () => (
    <div
      className={`product-card border-radius-10 p-4 border-light position-relative light-box-shadow ${link && "pointer box-shadow-hover"}`}>
      <div className="card-label mb-3">
        <Typography className="fweight-600 fsize-18 title-color mb-2">
          {label}
        </Typography>
        {time && (
          <div className="time d-flex mb-2">
            <span className="text-light-grey fsize-12 mx-md-1">
              {time}
            </span>
          </div>
        )}
      </div>
      <div className="d-md-flex w-100">
        <div className="light-border-right responsive-border product-request-info-section pt-2 pe-2 col-xl-10 col-lg-9 col-md-9 pb-md-0 pb-4 ">
          <div className="mb-2 w-100 text-break">
            {isRichText(description) ? (
              <p
                className="text-break"
                dangerouslySetInnerHTML={
                  description
                    ? RichTextToHTML(checkForMultiline(description))
                    : ""
                }></p>
            ) : (
              <p className="text-break">
                {description ? checkForMultiline(description) : ""}
              </p>
            )}
          </div>
          <div className="price-section d-md-flex align-items-center mb-2">
            <span className="text-light-grey fsize-12 fweight-400 col-xl-2 col-md-4">
              {websiteProductRequests.productRequestCard.budgetRange}
            </span>
            <div className="price mt-md-0 mt-2 text-md-start col-xl-2 col-lg-3 col-md-3 mt-md-0 mt-2">
              <p className="fsize-16 text-dark-blue fweight-600 mb-0">
                <span>
                  {currency}
                  {minPrice}
                </span>
                <span> - </span>
                <span>
                  {currency}
                  {maxPrice}
                </span>
              </p>
            </div>
          </div>
          <div className="budget-range-section d-md-flex align-items-center mb-md-0 mb-2">
            <span className="text-light-grey fsize-12 fweight-400 col-xl-2 col-md-4">
              {
                websiteProductRequests.productRequestCard
                  .requiredQuantity
              }
            </span>
            <p className="budget text-color fsize-16 col-xl-10 col-md-8 mt-md-0 mt-2 mb-md-0 text-start">
              {count} {unit || ""}
            </p>
          </div>
        </div>
        <div className="importer-info-section col-xl-2 col-lg-3 col-md-3 text-center mt-md-0 mt-4 ps-md-3">
          <div className="d-flex justify-content-center mb-3">
            <div
              className="importer-profile-img circular-border-radius d-flex justify-content-center align-items-center white-bg light-border"
              style={{
                width: "3.75rem",
                height: "3.75rem",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${
                  isNotAllowedToSeeData
                    ? noImgPlaceholder
                    : isImporterImgValid && importerImg
                })`,
                backgroundColor: "#f1faff"
              }}>
              {!isNotAllowedToSeeData && (
                <Typography className="fsize-24 fweight-400 medium-text-color text-capitalize">
                  {!isImporterImgValid &&
                    importerName &&
                    importerName[0]}
                </Typography>
              )}
            </div>
          </div>
          {isNotAllowedToSeeData && "*************"}
          <p className="text-break">{importerName}</p>
          <div className="importer-country d-flex justify-content-center gap-2">
            <CountryFlag
              countryCode={importerCountyShort}
              size={"1.25em"}
            />
            <p>{importerCountry}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {link ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default ProductRequestCard;

ProductRequestCard.propTypes = {
  importerImg: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  link: PropTypes.string,
  unit: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string,
  importerName: PropTypes.string,
  importerCountry: PropTypes.string,
  importerCountyShort: PropTypes.string,
  currency: PropTypes.any
};
