import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import TruncateText from "../../TruncateText";

const SubCategoryCardList = ({ src, label, link }) => {
  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  const renderCardContent = () => (
    <div
      className={`sub-category-card border-radius-10 p-3 faint-blue-bg ${link && "pointer box-shadow-hover"}`}>
      <div className="info-section d-flex align-items-center">
        <div
          className="card-img-container me-3 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
          style={{
            width: "11rem",
            height: "8rem"
          }}>
          <img
            src={src || emptyProductPlaceholder}
            onError={onImageError}
            className="w-100 item-cards-img"
          />
        </div>
        <div className="info-container w-100">
          <TruncateText
            text={label}
            linesNo={2}
            textClassName={"fsize-16 fweight-600 mb-0 title-color"}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {link ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default SubCategoryCardList;

SubCategoryCardList.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string
};
