import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Button from "../../../../components/Button";
import WaitForDocuments from "./WaitForDocuments";
import CancelRequestModal from "./CancelRequestModal";
import UploadDocuments from "./UploadDocuments";
import { cancelVerificationRequest } from "../../../../store/Verification/actions";
import { DocumentsBgIcon } from "../../../../utils/Icons";
import { COMPANY_VERIFICATION_STATUS } from "../../../../utils/Constants";

const OffPlatform = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { offPlatform, actions }
  } = messages[lang];

  const [cancelRequestModalOpen, setCancelRequestModalOpen] =
    useState(false);

  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );

  return (
    <div className="off-platform-container">
      <div className="method-declaration mb-4">
        <Box
          className="border-radius-10 light-border d-flex align-items-center p-3"
          sx={{
            width: {
              xs: "100%",
              md: "50%"
            }
          }}>
          <DocumentsBgIcon />
          <p className="ms-3 mb-0 fsize-16 fweight-600 title-color">
            {offPlatform.verificationMethod}
            {offPlatform.offplatformVerification}
          </p>
        </Box>
      </div>
      <div className="mb-4">
        {companyVerificationStatus ===
          COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments && (
          <WaitForDocuments />
        )}
        {[
          COMPANY_VERIFICATION_STATUS.pendingDocumentsUpload,
          COMPANY_VERIFICATION_STATUS.docsNeedUpdates
        ].includes(companyVerificationStatus) && <UploadDocuments />}
      </div>
      <div className="actions-section w-100 d-flex justify-content-end align-items-center">
        <Button
          outlined
          label={actions.back}
          customSpacing={"px-5 py-2"}
          className={"me-3"}
          onClick={() => {
            setCancelRequestModalOpen(true);
          }}
        />
        <Button
          label={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments
              ? actions.next
              : actions.submit
          }
          customSpacing={"px-5 py-2"}
          disabled={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments
          }
          form={
            [
              COMPANY_VERIFICATION_STATUS.pendingDocumentsUpload,
              COMPANY_VERIFICATION_STATUS.docsNeedUpdates
            ].includes(companyVerificationStatus)
              ? "upload-offplatform-required-documents-form"
              : null
          }
          type={
            [
              COMPANY_VERIFICATION_STATUS.pendingDocumentsUpload,
              COMPANY_VERIFICATION_STATUS.docsNeedUpdates
            ].includes(companyVerificationStatus)
              ? "submit"
              : "button"
          }
        />
      </div>
      <CancelRequestModal
        open={cancelRequestModalOpen}
        onClose={() => setCancelRequestModalOpen(false)}
        onConfirm={() => {
          dispatch(cancelVerificationRequest());
          setCancelRequestModalOpen(false);
        }}
      />
    </div>
  );
};

export default OffPlatform;
