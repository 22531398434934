export const ViewEditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M11.55 3.797l-6.842 7.242c-.259.275-.509.816-.559 1.191l-.308 2.7c-.108.975.592 1.642 1.558 1.475l2.684-.458c.375-.067.9-.342 1.158-.625l6.842-7.242c1.183-1.25 1.716-2.675-.125-4.416-1.834-1.725-3.225-1.117-4.409.133zM10.408 5.005a5.105 5.105 0 004.542 4.292M3 19.13h15"></path>
  </svg>
);
