import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormHelperText, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../../assets/locale/messages";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import Upload from "../../../../components/Upload";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import {
  editLoggedInExporterDetailsRequest,
  getLoggedInExporterDetailsRequest,
  getLoggedInExporterDetailsResponse
} from "../../../../store/User/actions";
import {
  BUSINESS_TYPES_IDS,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import {
  getCategoriesListRequest,
  getCategoriesListResponse,
  getCountriesListRequest,
  getCountriesListsResponse
} from "../../../../store/Lookups/actions";
import {
  COMPANY_WEBSITE_REGEX,
  EMAIL_REGEX,
  ALLOW_ALL_WITH_MANDATORY_CHARACTERS
} from "../../../../utils/Patterns";
import PhoneInput from "../../../../components/PhoneInput";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../../utils/blobStorageServices";
import EditCompanyProfileModal from "../EditCompanyProfileModal";
import DatePicker from "../../../../components/DatePicker";
import ScrollToFieldError from "../../../../components/ScrollToError";
import { showHideLoader } from "../../../../store/Loader/actions";
import { checkBackgroundImageValidity } from "../../../../utils/Helpers";
import inputsValidations from "../../../../assets/locale/En/inputsValidations";
import "./EditCompanyProfile.scss";

const EditCompanyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared,
    company,
    company: {
      editCompanyProfile: {
        inputLabels,
        placeholders,
        businessTypesOptionsLabels
      }
    }
  } = messages[lang];
  const circleUploadElementRef = useRef();
  const coverUploadElementRef = useRef();
  const companyDetails = useSelector(
    (state) => state.user.accountDetails
  );
  const countriesList = useSelector(
    (state) => state.lookups.countriesList
  );
  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const userCountry = useSelector(
    (state) => state.auth.login.CountryName
  );

  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const isPublished = useSelector(
    (state) => state.auth.login?.IsPublished
  );

  const [categoriesListOptions, setCategoriesListOptions] = useState(
    []
  );

  const [companyLogoError, setCompanyLogoError] = useState(null);
  const [companyCoverImageError, setCompanyCoverImageError] =
    useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [countriesListOptions, setCountriesListOptions] = useState(
    []
  );
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState(false);

  const [countryShort, setCountryShort] = useState("");
  const [countryNameState, setCountryNameState] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const [isValidCompanyCoverImage, setIsValidCompanyCoverImage] =
    useState(false);
  const [isValidLogoImage, setIsValidLogoImage] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  useEffect(() => {
    if (userStatusId) {
      setDisableInputs(
        userStatusId !== USER_STATUS_IDS.registered &&
          userStatusId !== USER_STATUS_IDS.pendingBusinessVerified &&
          userStatusId !== USER_STATUS_IDS.verified
      );
    }
  }, [userStatusId]);

  useEffect(() => {
    if (countriesList.length) {
      setCountryShort(
        countriesList.filter(
          (country) => country.Name === userCountry
        )[0].CountryNameShort
      );
    }
  }, [countriesList]);

  useEffect(() => {
    if (categoriesList && categoriesList?.length !== 0) {
      setCategoriesListOptions(
        categoriesList.map(({ Id, Name }) => ({
          id: Id,
          label: Name
        }))
      );
    }
  }, [categoriesList]);

  useEffect(() => {
    dispatch(getLoggedInExporterDetailsRequest());
    dispatch(getCountriesListRequest());
    dispatch(getCategoriesListRequest());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getLoggedInExporterDetailsResponse({}));
      dispatch(getCountriesListsResponse([]));
      dispatch(getCategoriesListResponse([]));
    };
  }, []);

  useEffect(() => {
    if (countriesList) {
      setCountriesListOptions(
        countriesList.map((country) => ({
          id: country.Id,
          label: country.Name
        }))
      );
    }
  }, [countriesList]);

  const registeredSchema = Yup.object({
    companyLogo: Yup.string().required("companyLogoRequired"),
    companyCoverImage: Yup.string().nullable(),
    companyLegalName: Yup.string()
      .max(50, "companyLegalNameLong")
      .required("companyLegalNameRequired")
      .nullable(),
    companyDisplayName: Yup.string()
      .nullable()
      .max(50, "companyDisplayNameLong")
      .required("companyDisplayNameRequired"),
    companyIntroduction: Yup.string()
      .nullable()
      .max(500, "companyIntroductionLong"),
    country: Yup.object().required("countryRequired"),
    businessType: Yup.object().required("businessTypeRequired"),
    yearEstablished: Yup.number().required("yearEstablishedRequired"),
    categories: Yup.array().nullable(),
    experienceYears: Yup.number().nullable(),
    exporterName: Yup.string()
      .matches(
        ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
        "fullNameNoSpecialChars"
      )
      .nullable()
      .max(50, "exporterNameLong"),
    exporterEmail: Yup.string()
      .nullable()
      .matches(EMAIL_REGEX, "invalidExporterEmail"),
    countryCodeLength: Yup.number(),
    exporterMobileNumber: Yup.string()
      .test(
        "valid-length",
        "exporterMobileNumberLength",
        function (value) {
          const { countryCodeLength } = this.parent;
          if (!countryCodeLength || !value) {
            return true;
          }
          const actualLength = value.length;
          if (actualLength !== countryCodeLength) {
            return actualLength >= countryCodeLength + 6;
          }
          return true;
        }
      )
      .nullable(),
    companyWebsite: Yup.string()
      .nullable()
      .matches(COMPANY_WEBSITE_REGEX, "invalidCompanyWebsite")
  });
  const verifiedSchema = Yup.object({
    companyLogo: Yup.string().required("companyLogoRequired"),
    companyCoverImage: Yup.string().nullable(),
    companyLegalName: Yup.string()
      .max(50, "companyLegalNameLong")
      .required("companyLegalNameRequired")
      .nullable(),
    companyDisplayName: Yup.string()
      .nullable()
      .max(50, "companyDisplayNameLong")
      .required("companyDisplayNameRequired"),
    companyIntroduction: Yup.string()
      .nullable()
      .max(500, "companyIntroductionLong"),
    country: Yup.object().required("countryRequired"),
    businessType: Yup.object().required("businessTypeRequired"),
    yearEstablished: Yup.number().required("yearEstablishedRequired"),
    categories: Yup.array().nullable(),
    experienceYears: Yup.number().nullable(),
    exporterName: Yup.string()
      .matches(
        ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
        "fullNameNoSpecialChars"
      )
      .nullable()
      .max(50, "exporterNameLong"),
    exporterEmail: Yup.string()
      .nullable()
      .matches(EMAIL_REGEX, "invalidExporterEmail"),
    countryCodeLength: Yup.number(),
    exporterMobileNumber: Yup.string()
      .test(
        "valid-length",
        "exporterMobileNumberLength",
        function (value) {
          const { countryCodeLength } = this.parent;
          if (!countryCodeLength || !value) {
            return true;
          }
          const actualLength = value.length;
          if (actualLength !== countryCodeLength) {
            return actualLength >= countryCodeLength + 6;
          }
          return true;
        }
      )
      .nullable(),
    companyWebsite: Yup.string()
      .nullable()
      .matches(COMPANY_WEBSITE_REGEX, "invalidCompanyWebsite")
  });

  const publishedSchema = Yup.object({
    companyLogo: Yup.string().required("companyLogoRequired"),
    companyCoverImage: Yup.string().required(
      "companyCoverImageRequired"
    ),
    companyLegalName: Yup.string()
      .max(50, "companyLegalNameLong")
      .required("companyLegalNameRequired"),
    companyDisplayName: Yup.string()
      .nullable()
      .max(50, "companyDisplayNameLong")
      .required("companyDisplayNameRequired"),
    companyIntroduction: Yup.string()
      .max(500, "companyIntroductionLong")
      .required("companyIntroductionRequired"),
    country: Yup.object().required("countryRequired"),
    businessType: Yup.object().required("businessTypeRequired"),
    yearEstablished: Yup.number().required("yearEstablishedRequired"),
    categories: Yup.array().min(1, "categoriesRequired"),
    experienceYears: Yup.number().nullable(),
    exporterName: Yup.string()
      .matches(
        ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
        "fullNameNoSpecialChars"
      )
      .max(50, "exporterNameLong")
      .required("exporterNameRequired"),
    exporterEmail: Yup.string()
      .required("exporterEmailRequired")
      .matches(EMAIL_REGEX, "invalidExporterEmail"),
    countryCodeLength: Yup.number(),
    exporterMobileNumber: Yup.string()
      .test(
        "valid-length",
        "exporterMobileNumberLength",
        function (value) {
          const { countryCodeLength } = this.parent;
          if (!countryCodeLength || !value) {
            return true;
          }
          const actualLength = value.length;
          if (actualLength !== countryCodeLength) {
            return actualLength >= countryCodeLength + 6;
          }
          return true;
        }
      )
      .nullable(),
    companyWebsite: Yup.string()
      .required("companyWebsiteRequired")
      .matches(COMPANY_WEBSITE_REGEX, "invalidCompanyWebsite")
  });

  const formik = useFormik({
    initialValues: {
      businessType: null,
      categories: [],
      country: null,
      companyLegalName: "",
      companyDisplayName: "",
      companyIntroduction: "",
      yearEstablished: "",
      experienceYears: "",
      exporterName: "",
      exporterEmail: "",
      exporterMobileNumber: "",
      companyWebsite: "",
      companyLogo: "",
      companyCoverImage: "",
      countryCodeLength: 0
    },
    validationSchema: !disableInputs
      ? registeredSchema
      : !isPublished &&
          (userStatusId === USER_STATUS_IDS.businessVerified ||
            userStatusId === USER_STATUS_IDS.subscribed ||
            userStatusId === USER_STATUS_IDS.subscriptionExpired)
        ? verifiedSchema
        : publishedSchema,
    onSubmit: async ({
      businessType,
      country,
      companyLegalName,
      companyDisplayName,
      categories,
      yearEstablished,
      companyIntroduction,
      exporterName,
      exporterEmail,
      exporterMobileNumber,
      companyWebsite,
      companyLogo,
      dbCompanyLogo,
      companyLogoInfo,
      companyCoverImage,
      dbCompanyCoverImage,
      companyCoverImageInfo
    }) => {
      dispatch(showHideLoader(true));
      let companyLogoImgName = "";
      let companyLogoImgObject = {};
      let companyCoverImageImgName = "";

      const dbData = {
        dbCompanyLogo,
        companyLogoInfo,
        dbCompanyCoverImage,
        companyCoverImageInfo,
        countryShort,
        countryNameState,
        countryId: country?.id || null
      };
      const companyData = {
        CompanyName: companyLegalName,
        DisplayName: companyDisplayName,
        CategoryIds:
          categories.map((category) => category.id) || null,
        ContactName: exporterName,
        ContactEmail: exporterEmail,
        BusinessTypeId: businessType.id || null,
        CountryId: country?.id || null,
        CompanyWebsite: companyWebsite,
        Intro: companyIntroduction,
        CompanyLogo: companyLogoImgName,
        CompanyCoverImage: companyCoverImageImgName,
        EstablishingYear: yearEstablished,
        MobileNumber: `${exporterMobileNumber.length > 0 && !exporterMobileNumber.startsWith("+") ? `+` : ""}${exporterMobileNumber}`
      };

      if (companyLogo) {
        if (companyLogoInfo.isNew) {
          companyLogoImgObject = await UploadBlobToContainer(
            {
              blob: companyLogo,
              ...companyLogoInfo
            },
            BLOB_CONTAINERS.images,
            true
          );
          companyLogoImgName = companyLogoImgObject.name;
          dbData.newImageUrl = companyLogoImgObject.url;
        } else {
          companyLogoImgName = companyLogo.split("/images/")[1];
          dbData.newImageUrl = companyLogo;
        }
        companyData.CompanyLogo = companyLogoImgName;
      }

      if (companyCoverImage) {
        if (companyCoverImageInfo.isNew) {
          companyCoverImageImgName = await UploadBlobToContainer(
            {
              blob: companyCoverImage,
              ...companyCoverImageInfo
            },
            BLOB_CONTAINERS.images
          );
        } else {
          companyCoverImageImgName =
            companyCoverImage.split("/images/")[1];
        }
        companyData.CompanyCoverImage = companyCoverImageImgName;
        dbData.CompanyCoverImage = companyCoverImageImgName;
      }

      dispatch(
        editLoggedInExporterDetailsRequest({
          data: {
            ...companyData
          },
          dbData,
          navigate
        })
      );
      dispatch(showHideLoader(false));
    }
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    isValid,
    setFieldTouched,
    setValues
  } = formik;

  const handleConfirmEdit = () => {
    handleSubmit();
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    if (companyDetails && Object.keys(companyDetails).length) {
      const {
        TradeName,
        DisplayName,
        BusinessTypeId,
        BusinessTypeName,
        CountryName,
        CountryId,
        Introduction,
        LogoImage,
        CoverImage,
        OriginalCoverImage,
        EstablishedYear,
        YearsOfExperience,
        Categories,
        CompanyExporterName,
        MobileNumber,
        Email,
        Website
      } = companyDetails;

      const categoriesIdList = Categories.map(
        (category) => category.Id
      );

      checkBackgroundImageValidity(LogoImage, (isValid) => {
        setIsValidLogoImage(isValid);
      });

      checkBackgroundImageValidity(CoverImage, (isValid) => {
        setIsValidCompanyCoverImage(isValid);
      });

      setValues({
        ...values,
        businessType: { id: BusinessTypeId, label: BusinessTypeName },
        companyLegalName: TradeName,
        companyDisplayName: DisplayName,
        yearEstablished: EstablishedYear ?? "",
        experienceYears: YearsOfExperience ?? "",
        companyIntroduction: Introduction ?? "",
        exporterName: CompanyExporterName,
        exporterEmail: Email,
        exporterMobileNumber: MobileNumber ?? "",
        companyWebsite: Website ?? "",
        companyLogo: isValidLogoImage ? LogoImage : null,
        dbCompanyLogo: isValidLogoImage ? LogoImage : null,
        companyLogoInfo: {
          isNew: false,
          name: ""
        },
        companyCoverImage: isValidCompanyCoverImage
          ? CoverImage
          : null,
        dbCompanyCoverImage: isValidCompanyCoverImage
          ? CoverImage
          : null,
        companyCoverImageInfo: {
          isNew: false,
          name: OriginalCoverImage
        },
        originalCoverImage: OriginalCoverImage,
        categories: categoriesList
          ?.filter((category) =>
            categoriesIdList?.includes(category.Id)
          )
          .map((unit) => ({
            id: unit.Id,
            label: unit.Name
          })),
        country: { id: CountryId, label: CountryName }
      });
    }
  }, [
    companyDetails,
    isValidCompanyCoverImage,
    isValidLogoImage,
    categoriesList,
    countriesList
  ]);

  const businessTypesOptions = [
    {
      id: BUSINESS_TYPES_IDS.trade,
      label: businessTypesOptionsLabels.trade
    },
    {
      id: BUSINESS_TYPES_IDS.factor,
      label: businessTypesOptionsLabels.factory
    },
    {
      id: BUSINESS_TYPES_IDS.both,
      label: businessTypesOptionsLabels.both
    }
  ];

  const currentYear = new Date().getFullYear();

  return (
    <div className="edit-company-container">
      <div className="page-title pb-3">
        <Breadcrumbs
          list={[
            {
              label: company.editCompanyProfile.breadCrumbs.company,
              link: ROUTE_PATHS.companyProfile
            },
            {
              label:
                company.editCompanyProfile.breadCrumbs.editCompany
            }
          ]}
        />
        <Typography variant="h5" className="title-color">
          {company.editCompanyProfile.title}
        </Typography>
      </div>
      <form
        className="edit-company-profile-form gap-5"
        onSubmit={handleSubmit}
        noValidate>
        <ScrollToFieldError
          formik={formik}
          submitButtonClicked={submitButtonClicked}
          handleSubmitButtonClicked={setSubmitButtonClicked}
        />
        <div className="company-details-container bg-white w-100 h-100 mb-5 p-5 border-radius-8">
          <div className="company-logo-container">
            <div className="container-title">
              <Typography className="fweight-600 fsize-16">
                {company.editCompanyProfile.companyDetails}
              </Typography>
            </div>
            <div
              id={"companyCoverImage"}
              className={`my-5 mt-3 company-cover-img-container col-12`}>
              <Typography className="pb-2 f-weight-400 fsize-13 title-color">
                {inputLabels.companyImages}
              </Typography>
              <Upload
                isFitContainer
                customPlaceholder
                placeholderText={inputLabels.companyCoverImage}
                isEditOnly
                hideErrMsg
                isCoverUpload
                className={`cover-upload cover-img-contain`}
                touchInput={() => {
                  setFieldTouched("companyCoverImage");
                }}
                onClear={() => {
                  setFieldTouched("companyCoverImage");
                  setValues({
                    ...values,
                    companyCoverImage: null,
                    companyCoverImageInfo: {}
                  });
                }}
                label={<div ref={coverUploadElementRef}></div>}
                userImage={values.companyCoverImage}
                onChange={(img) => {
                  setFieldTouched("companyCoverImage");
                  setValues({
                    ...values,
                    companyCoverImage: img.blob,
                    companyCoverImageInfo: {
                      ...img?.imageInfo,
                      isNew: true
                    }
                  });
                }}
                name="companyCoverImage"
                isCropMode={true}
                cropAspect={8.25 / 1}
                onChangeError={setCompanyCoverImageError}
                isInputHasErr={
                  !!companyCoverImageError ||
                  (touched.companyCoverImage &&
                    errors.companyCoverImage)
                }
                errMsg={
                  companyCoverImageError || errors.companyCoverImage
                }
              />
            </div>
            <div
              className="circle-upload-container company-logo circular-border-radius light-border"
              id={"companyLogo"}>
              <Upload
                isCircleUploader
                isEditOnly
                hideErrMsg
                touchInput={() => {
                  setFieldTouched("companyLogo");
                }}
                className={`circle-upload `}
                customPlaceholder
                placeholderText={
                  company.editCompanyProfile.uploadLogo
                }
                label={<div ref={circleUploadElementRef}></div>}
                onClear={() => {
                  setFieldTouched("companyLogo");
                  setValues({
                    ...values,
                    companyLogo: null,
                    companyLogoInfo: {}
                  });
                }}
                userImage={values.companyLogo}
                onChange={(img) => {
                  setFieldTouched("companyLogo");
                  setValues({
                    ...values,
                    companyLogo: img.blob,
                    companyLogoInfo: {
                      ...img?.imageInfo,
                      isNew: true
                    }
                  });
                }}
                name="companyLogo"
                onChangeError={setCompanyLogoError}
                isInputHasErr={
                  !!companyLogoError ||
                  (touched.companyLogo && errors.companyLogo)
                }
                errMsg={companyLogoError || errors.companyLogo}
                isCropMode={true}
                cropAspect={1 / 1}
              />
            </div>
          </div>
          {(companyCoverImageError ||
            (touched.companyCoverImage &&
              inputsValidations[errors.companyCoverImage])) && (
            <div>
              <FormHelperText
                error={true}
                className={`${"fsize-12 my-3"} ${
                  lang === "en" ? "" : "text-end"
                } `}>
                {companyCoverImageError ||
                  (touched.companyCoverImage &&
                    inputsValidations[errors.companyCoverImage])}
              </FormHelperText>
            </div>
          )}
          {(companyLogoError ||
            (touched.companyLogo &&
              inputsValidations[errors.companyLogo])) && (
            <div className="my-3">
              <FormHelperText
                error={true}
                className={`${"fsize-12 mt-2"} ${
                  lang === "en" ? "" : "text-end"
                } `}>
                {companyLogoError ||
                  (touched.companyLogo &&
                    inputsValidations[errors.companyLogo])}
              </FormHelperText>
            </div>
          )}

          <div
            className={`category-sub-category-container ${companyCoverImageError ? "mt-2" : "mt-3"} pt-3`}>
            <div
              className={`company-inputs-container gap-4 justify-content-between flex-wrap mb-4`}>
              <div
                className={`company-inputs-container d-flex  justify-content-between flex-wrap`}>
                <Input
                  inputWrapperClass={"company-form-field"}
                  key={inputLabels.companyLegalName}
                  label={inputLabels.companyLegalName}
                  labelClassName="pb-2 font-medium  main-text-color"
                  placeholder={
                    placeholders.companyLegalNamePlaceholder
                  }
                  disabled={disableInputs}
                  onChange={(value) => {
                    if (!value || !!value.trim()) {
                      const modifiedValue = value.replace(
                        /\s\s+/g,
                        " "
                      );
                      setFieldTouched("companyLegalName");
                      setFieldValue(
                        "companyLegalName",
                        modifiedValue
                      );
                    }
                  }}
                  value={values.companyLegalName}
                  isInputHasErr={
                    !!(
                      touched.companyLegalName &&
                      errors.companyLegalName
                    )
                  }
                  errMsg={errors.companyLegalName}
                  name="companyLegalName"
                  id="companyLegalName"
                />
                <Input
                  inputWrapperClass={"company-form-field"}
                  key={inputLabels.companyDisplayName}
                  label={inputLabels.companyDisplayName}
                  labelClassName="pb-2 font-medium  main-text-color"
                  placeholder={
                    placeholders.companyDisplayNamePlaceholder
                  }
                  onChange={(value) => {
                    if (!value || !!value.trim()) {
                      const modifiedValue = value.replace(
                        /\s\s+/g,
                        " "
                      );
                      setFieldTouched("companyDisplayName");
                      setFieldValue(
                        "companyDisplayName",
                        modifiedValue
                      );
                    }
                  }}
                  value={values.companyDisplayName}
                  isInputHasErr={
                    !!(
                      touched.companyDisplayName &&
                      errors.companyDisplayName
                    )
                  }
                  errMsg={errors.companyDisplayName}
                  name="companyDisplayName"
                  id="companyDisplayName"
                />
              </div>
            </div>
            <div className="company-inputs-container w-100 my-4">
              <Input
                key={inputLabels.companyIntroduction}
                label={inputLabels.companyIntroduction}
                placeholder={
                  placeholders.companyIntroductionPlaceholder
                }
                labelClassName="pb-2 font-medium   main-text-color"
                rows={4}
                multiline
                onChange={(value) => {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value
                      .replace(/\s\s/g, " ")
                      .replace("\n\n", "\n");
                    setFieldTouched("companyIntroduction");
                    setFieldValue(
                      "companyIntroduction",
                      modifiedValue
                    );
                  }
                }}
                value={values.companyIntroduction}
                isInputHasErr={
                  !!(
                    touched.companyIntroduction &&
                    errors.companyIntroduction
                  )
                }
                errMsg={errors.companyIntroduction}
                name="companyIntroduction"
                id="companyIntroduction"
              />
            </div>
            <div
              className={`company-inputs-container d-flex  justify-content-between flex-wrap my-4`}>
              <Select
                disabled={disableInputs}
                key={inputLabels.country}
                wrapperClass="company-form-field"
                labelClassName="pb-1 font-medium"
                label={inputLabels.country}
                placeholder={placeholders.countryPlaceholder}
                options={countriesListOptions}
                onChange={(value) => {
                  setFieldTouched("country");
                  setFieldValue("country", value);
                  if (
                    value?.label &&
                    values.exporterMobileNumber.length ===
                      values.countryCodeLength
                  ) {
                    setCountryShort(
                      countriesList.filter(
                        (country) =>
                          country.Name.toLowerCase() ===
                          value?.label.toLowerCase()
                      )[0].CountryNameShort
                    );
                    setCountryNameState(
                      countriesList.filter(
                        (country) =>
                          country.Name.toLowerCase() ===
                          value?.label.toLowerCase()
                      )[0].Name
                    );
                  }
                }}
                value={values.country}
                hasError={!!(touched.country && errors.country)}
                errMsg={errors.country}
                id="country"
                name="country"
              />
              <Select
                disabled={disableInputs}
                key={inputLabels.businessType}
                wrapperClass="company-form-field"
                labelClassName="pb-1 font-medium"
                label={inputLabels.businessType}
                placeholder={placeholders.businessTypePlaceholder}
                options={businessTypesOptions}
                onChange={(value) => {
                  setFieldTouched("businessType");
                  setFieldValue("businessType", value);
                }}
                value={values.businessType}
                hasError={
                  !!(touched.businessType && errors.businessType)
                }
                errMsg={errors.businessType}
                id="businessType"
                name="businessType"
              />
            </div>
            <div
              className={`company-inputs-container d-flex  justify-content-between flex-wrap my-4`}>
              <div
                className="company-form-field"
                id={`yearEstablished`}>
                <DatePicker
                  disabled={disableInputs}
                  key={inputLabels.yearEstablished}
                  onChange={(value) => {
                    if (
                      !value ||
                      (!!value.trim() && !value.includes(" "))
                    ) {
                      setFieldTouched("yearEstablished");
                      if (value.length === 4) {
                        setValues({
                          ...values,
                          yearEstablished: value,
                          experienceYears: currentYear - Number(value)
                        });
                      } else {
                        setValues({
                          ...values,
                          yearEstablished: value
                        });
                      }
                    }
                  }}
                  wrapperClass="p-0 "
                  inputClass="w-100 p-0"
                  label={inputLabels.yearEstablished}
                  labelClass="fsize-13 fweight-300 title-color pb-2 font-medium"
                  placeholder={
                    placeholders.yearEstablishedPlaceholder
                  }
                  margin="normal"
                  value={values.yearEstablished}
                  format="YYYY"
                  disableFuture
                  maxDate={currentYear}
                  views={["year"]}
                  yearOnlyDate
                  isInputHasErr={
                    !!(
                      touched.yearEstablished &&
                      errors.yearEstablished
                    )
                  }
                  errMsg={errors.yearEstablished}
                />
              </div>
              <Input
                disabled
                inputWrapperClass={"company-form-field"}
                key={inputLabels.experienceYears}
                label={inputLabels.experienceYears}
                labelClassName="pb-2 font-medium  main-text-color"
                type={"number"}
                placeholder={placeholders.experienceYearsPlaceholder}
                onChange={(value) => {
                  if (!value.includes(" ")) {
                    setFieldTouched("experienceYears");
                    setValues({
                      ...values,
                      experienceYears: value
                    });
                  }
                }}
                value={values.experienceYears}
                isInputHasErr={
                  !!(
                    touched.experienceYears && errors.experienceYears
                  )
                }
                errMsg={errors.experienceYears}
                name="experienceYears"
                id="experienceYears"
              />
            </div>
            <Select
              isMultipleSelect
              key={inputLabels.categories}
              wrapperClass="company-form-field"
              labelClassName="pb-1 font-medium"
              label={inputLabels.categories}
              placeholder={placeholders.categoriesPlaceholder}
              options={
                !!categoriesListOptions.length !== 0
                  ? categoriesListOptions
                  : []
              }
              onChange={(value) => {
                setFieldTouched("categories");
                setFieldValue("categories", value);
              }}
              value={values.categories}
              hasError={!!(touched.categories && errors.categories)}
              errMsg={errors.categories}
              id="categories"
              name="categories"
            />
          </div>
        </div>
        <div className="contact-info-container bg-white w-100 h-100 my-5  p-5 border-radius-8">
          <div className="container-title">
            <div>
              <Typography className="fweight-600 fsize-16">
                {company.editCompanyProfile.contactInfo}
              </Typography>
            </div>
          </div>
          <div>
            <div
              className={`company-inputs-container d-flex my-4  justify-content-between flex-wrap`}>
              <Input
                inputWrapperClass={"company-form-field"}
                key={inputLabels.exporterName}
                label={inputLabels.exporterName}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.exporterNamePlaceholder}
                onChange={(value) => {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("exporterName");
                    setFieldValue("exporterName", modifiedValue);
                  }
                }}
                value={values.exporterName}
                isInputHasErr={
                  !!(touched.exporterName && errors.exporterName)
                }
                errMsg={errors.exporterName}
                name="exporterName"
                id="exporterName"
              />
              <Input
                inputWrapperClass={"company-form-field"}
                key={inputLabels.exporterEmail}
                label={inputLabels.exporterEmail}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.exporterEmailPlaceholder}
                onChange={(value) => {
                  setFieldTouched("exporterEmail");
                  setFieldValue("exporterEmail", value);
                }}
                value={values.exporterEmail}
                isInputHasErr={
                  !!(touched.exporterEmail && errors.exporterEmail)
                }
                errMsg={errors.exporterEmail}
                name="exporterEmail"
                id="exporterEmail"
              />
            </div>
            <div
              className={`company-inputs-container mt-4 d-flex justify-content-between flex-wrap`}
              id="exporterMobileNumber">
              <PhoneInput
                onFocus={() => {
                  setIsPhoneFocused(true);
                }}
                onBlur={() => {
                  setIsPhoneFocused(false);
                }}
                isFocused={isPhoneFocused}
                onChange={(value, dialCodeLength) => {
                  setFieldTouched("exporterMobileNumber");
                  setFieldTouched("countryCodeLength");
                  setValues({
                    ...values,
                    exporterMobileNumber: value,
                    countryCodeLength: dialCodeLength
                  });
                }}
                userCountryShort={
                  (!isPhoneFocused &&
                    companyDetails &&
                    !!Object.keys(companyDetails)?.length &&
                    !companyDetails.MobileNumber &&
                    values.exporterMobileNumber.length === 0) ||
                  (values?.country?.label &&
                    values.exporterMobileNumber.length ===
                      values.countryCodeLength)
                    ? countryShort
                    : ""
                }
                inputWrapperClass={"company-form-field"}
                key={inputLabels.exporterMobileNumber}
                label={inputLabels.exporterMobileNumber}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={
                  placeholders.exporterMobileNumberPlaceholder
                }
                value={values.exporterMobileNumber}
                isInputHasErr={
                  !!(
                    touched.exporterMobileNumber &&
                    errors.exporterMobileNumber
                  )
                }
                errMsg={errors.exporterMobileNumber}
                name="exporterMobileNumber"
              />
              <Input
                inputWrapperClass={"company-form-field"}
                key={inputLabels.companyWebsite}
                label={inputLabels.companyWebsite}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.companyWebsitePlaceholder}
                onChange={(value) => {
                  setFieldTouched("companyWebsite");
                  setFieldValue("companyWebsite", value);
                }}
                value={values.companyWebsite}
                isInputHasErr={
                  !!(touched.companyWebsite && errors.companyWebsite)
                }
                errMsg={errors.companyWebsite}
                name="companyWebsite"
                id="companyWebsite"
              />
            </div>
          </div>
        </div>
        <div
          className={`form-buttons-container bg-white d-flex justify-content-between flex-wrap w-100 mt-5  p-4 bg-white border-radius-8`}>
          <Button
            key={shared.cancel}
            outlined
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={shared.cancel}
            onClick={() => navigate(ROUTE_PATHS.companyProfile)}
          />
          <Button
            key={shared.submit}
            type="button"
            onClick={() => {
              userStatusId === USER_STATUS_IDS.subscribed &&
              isValid &&
              isPublished
                ? setIsEditModalOpen(true)
                : handleSubmit();
              setSubmitButtonClicked(true);
            }}
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={shared.save}
          />
        </div>
      </form>
      {isEditModalOpen && (
        <EditCompanyProfileModal
          handleClose={() => {
            setIsEditModalOpen(false);
          }}
          open={isEditModalOpen}
          handleConfirm={handleConfirmEdit}
        />
      )}
    </div>
  );
};

export default EditCompanyProfile;
