import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Menu as MuiMenu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress
} from "@mui/material";

import { NotificationsIcon } from "../../utils/Icons";
import {
  getInitialNotificationsRequest,
  getInitialNotificationsResponse
} from "../../store/Notifications/actions";
import messages from "../../assets/locale/messages";
import {
  handleNotificationClick,
  markAllNotificationsAsRead
} from "../../notifications/helpers";
import { formatDate } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import defaultNotificationIcon from "../../assets/images/vectors/info-icon.svg";
import { USER_TYPES_IDS } from "../../utils/Constants";
import "./NotificationsBtn.scss";

const NotificationsButton = ({ className, labelClass }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const isLoading = useSelector((state) => state.loader.showLoader);
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const notificationsList = useSelector(
    (state) => state.notifications.dropdownList
  );
  const unseenNotificationsCount = useSelector(
    (state) => state.notifications.unseenNotificationsCount
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const lang = useSelector((state) => state.locale.lang);
  const {
    notifications,
    myAccount: {
      switchCurrentRole: { message }
    }
  } = messages[lang];

  useEffect(() => {
    if (isAuth && anchorEl) {
      dispatch(
        getInitialNotificationsRequest({
          params: {
            page: 1,
            reuqestType: "initial",
            userTypeId:
              userType === USER_TYPES_IDS.both
                ? userCurrentRole
                : userType
          }
        })
      );
    }
  }, [isAuth, anchorEl]);

  const handleControllerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(getInitialNotificationsResponse([]));
  };

  const onClickNotification = ({
    Id,
    ActionId,
    ClickActionName,
    IsRead
  }) => {
    handleNotificationClick({
      id: Id,
      clickAction: ClickActionName,
      actionId: ActionId,
      isRead: IsRead,
      navigate
    });
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead({ request: "initial" });
  };

  return (
    <>
      <div
        aria-controls="notifications-menu"
        onClick={handleControllerClick}
        className={`h-100 notifications-menu-controller notifications-button-wrapper edit-button d-flex justify-content-end  align-items-center border-radius-8 pointer p-2 ${className}`}>
        <NotificationsIcon
          className={`notifications-Icon  pointer mx-1 my-1 ${labelClass}`}
          hasUnseen={!!unseenNotificationsCount}
        />
      </div>
      <MuiMenu
        id="custom-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          paper: `notifications-menu scroll-within`,
          list: `notifications-list p-0`
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom"
        }}>
        <div className={`menu-header`} onClick={handleClose}>
          <div className="pt-3 px-4 mb-2 d-flex justify-content-between align-items-center">
            <p className="mb-0 title-color fsize-16 fweight-500">
              {notifications.notifications}
            </p>
            {!!notificationsList?.length && (
              <p
                className="mb-0 pointer fweight-500 fsize-12 text-primary"
                onClick={handleMarkAllAsRead}>
                {notifications.markAllAsRead}
              </p>
            )}
          </div>
        </div>
        {notificationsList?.length ? (
          notificationsList.map(
            (
              {
                Id,
                Title,
                ClickActionName,
                ActionId,
                CreatedDate,
                IsRead,
                Image
              },
              i
            ) => (
              <MenuItem
                key={`dp-notification-${Id}`}
                className={`notification-item d-flex justify-content-between px-4 py-3 ${!IsRead && "faint-grey-bg"} ${i < notificationsList?.length - 1 && "light-border-bottom "}`}
                onClick={() =>
                  onClickNotification({
                    Id,
                    ActionId,
                    ClickActionName,
                    IsRead
                  })
                }>
                <div
                  className="d-flex align-items-center justify-content-start"
                  onClick={() => {
                    handleClose();
                  }}>
                  <ListItemIcon className="me-1">
                    <img
                      src={
                        Image
                          ? `data:image/svg+xml;utf8,${encodeURIComponent(Image)}`
                          : defaultNotificationIcon
                      }
                      style={{ width: "2rem" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <p className="mb-0 text-color text-wrap">
                      {Title}
                    </p>
                    {CreatedDate && (
                      <p className="mb-0 fsize-11 fweight-300 text-light-grey">
                        {formatDate({ date: CreatedDate })}
                      </p>
                    )}
                  </ListItemText>
                </div>
              </MenuItem>
            )
          )
        ) : isLoading ? (
          <MenuItem className="px-4 py-4 d-flex justify-content-center">
            <CircularProgress />
          </MenuItem>
        ) : (
          <MenuItem className="px-4 py-3 text-light-grey">
            <ListItemText>
              {notifications.noNotifications}
            </ListItemText>
          </MenuItem>
        )}
        {!!notificationsList?.length && (
          <div className={`menu-footer`} onClick={handleClose}>
            <div className="py-4 px-4 ">
              <Link to={ROUTE_PATHS.notifications}>
                <p className="mb-0 pointer fweight-500 text-primary text-underline">
                  {notifications.seeAllNotifications}
                </p>
              </Link>
            </div>
          </div>
        )}
      </MuiMenu>
    </>
  );
};

NotificationsButton.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string
};

export default NotificationsButton;
