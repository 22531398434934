import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";

import "./LinearProgressBar.scss";

const LinearProgressBar = ({
  total,
  done,
  wrapperClass,
  color,
  variant
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if ((done || done === 0) && (total || total === 0)) {
      setValue((done / total) * 100);
    }
  }, [done, total]);

  return (
    <div className={`custom-progress-bar-container ${wrapperClass}`}>
      <LinearProgress color={color} value={value} variant={variant} />
    </div>
  );
};

export default LinearProgressBar;

LinearProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  done: PropTypes.number.isRequired,
  wrapperClass: PropTypes.number,
  color: PropTypes.string,
  variant: PropTypes.string
};

LinearProgressBar.defaultProps = {
  color: "primary",
  variant: "determinate"
};
