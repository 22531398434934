import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import {
  BANNER_COLORS,
  VERIFICATION_METHODS
} from "../../../../../utils/Constants";
import {
  getStripeClientSecretRequest,
  setPaymentFailureErrMsg,
  setSelectedVerificationMethod,
  setVerificationStep,
  setPaymentIntentId
} from "../../../../../store/Verification/actions";
import Alert from "../../../../../components/Alert";
import Button from "../../../../../components/Button";
import StripeCheckout from "../../../../../components/Payment/StripeCheckout";
import FailurePayment from "../FailurePayment";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const CardInfo = ({ paymentInfo }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { actions, onlinePayment }
  } = messages[lang];

  const paymentFailureMsg = useSelector(
    (state) => state.verification.paymentFailureErrMsg
  );
  const clientSecret = useSelector(
    (state) => state.verification.stripeClientSecret
  );

  useEffect(() => {
    if (paymentInfo?.amount)
      dispatch(
        getStripeClientSecretRequest({
          amount: paymentInfo?.amount
        })
      );
  }, [paymentInfo]);

  const handleCloseModal = () => {
    dispatch(setPaymentFailureErrMsg(null));
  };

  return (
    <div className="online-payment-card-info-container">
      <h4 className="fsize-18 fweight-600 title-color">
        {onlinePayment.paymentInformation}
      </h4>
      <p className="fsize-16 text-color">
        {onlinePayment.paymentInfoHint}
        <span className="fweight-600">
          {onlinePayment.nonRefundable}
        </span>
      </p>
      <div className="card-info-section border-radius-10 light-border p-4 mb-4">
        <Alert
          content={
            <p className="mb-0 fsize-16 ">
              {onlinePayment.paymentWarning}
            </p>
          }
          icon={<ChipInfoIcon color={BANNER_COLORS.warning} />}
          type="warning"
          className="mb-4"
        />
        <h6 className="fsize-16 fweight-600 title-color mb-4">
          {onlinePayment.requiredInformation}
        </h6>
        <div className="card-info-form">
          <StripeCheckout
            clientSecret={clientSecret || ""}
            redirectUrl={ROUTE_PATHS.onlinePaymentSuccess}
            setPaymentIntentFunc={setPaymentIntentId}
            setPaymentFailureMsgFunc={setPaymentFailureErrMsg}
            paymentInfo={paymentInfo}
          />
        </div>
      </div>
      <div className="actions-section w-100 d-flex justify-content-end align-items-center">
        <Button
          label={actions.back}
          customSpacing={"px-5 py-2"}
          className={"me-3"}
          outlined
          onClick={() => {
            dispatch(setVerificationStep(0));
            dispatch(
              setSelectedVerificationMethod(
                VERIFICATION_METHODS.onlinePayment
              )
            );
          }}
        />
        <Button
          label={actions.submit}
          customSpacing={"px-5 py-2"}
          type="submit"
          form="online-payment-form"
        />
      </div>
      {!!paymentFailureMsg && (
        <FailurePayment
          open={!!paymentFailureMsg}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default CardInfo;

CardInfo.propTypes = {
  paymentInfo: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
  }).isRequired
};
