import { all } from "redux-saga/effects";
import AuthSagas from "./Auth/sagas";
import ProductsSagas from "./Products/sagas";
import LookupsSagas from "./Lookups/sagas";
import SearchSagas from "./Search/sagas";
import ExportersSagas from "./Exporters/sagas";
import HomeSagas from "./Home/sagas";
import SubCategoriesProductsSagas from "./SubCategories/sagas";
import NotificationsSagas from "./Notifications/sagas";
import CategoriesSagas from "./Categories/sagas";
import UserSagas from "./User/sagas";
import VerificationSagas from "./Verification/sagas";
import MyAccountSagas from "./MyAccount/sagas";
import SettingsSaga from "./Settings/sagas";
import SubscriptionSagas from "./Subscription/sagas";
import ProductsRequestsSagas from "./ProductRequests/sagas";
import SubAccountsSagas from "./SubAccounts/sagas";

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    ProductsSagas(),
    LookupsSagas(),
    SearchSagas(),
    ExportersSagas(),
    HomeSagas(),
    SubCategoriesProductsSagas(),
    NotificationsSagas(),
    CategoriesSagas(),
    UserSagas(),
    VerificationSagas(),
    MyAccountSagas(),
    SettingsSaga(),
    SubscriptionSagas(),
    ProductsRequestsSagas(),
    SubAccountsSagas()
  ]);
}
