import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import TruncateText from "../../TruncateText";

const SubCategoryCardGrid = ({ src, label, link }) => {
  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  const renderCardContent = () => (
    <div
      className={`sub-category-card border-radius-10 px-3 py-4 faint-blue-bg pointer box-shadow-hover ${link && "pointer box-shadow-hover"}`}
      style={{
        height: "18rem"
      }}>
      <div
        className="w-100 card-img-container mb-3 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
        style={{
          height: "8rem"
        }}>
        <img
          src={src || emptyProductPlaceholder}
          onError={onImageError}
          className="w-100 item-cards-img"
        />
      </div>
      <div className="card-label d-flex align-items-center justify-content-center">
        <TruncateText
          text={label}
          linesNo={2}
          textClassName={"fsize-16 fweight-600 mb-0 title-color"}
        />
      </div>
    </div>
  );

  return (
    <>
      {link ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default SubCategoryCardGrid;

SubCategoryCardGrid.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string
};
