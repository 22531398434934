import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import resendEmail from "../../../../assets/images/vectors/resend-email.png";
import Button from "../../../../components/Button";
import { resendVerifyEmailRequest } from "../../../../store/Auth/actions";

const RegisterFailure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { registeration } = messages[lang];

  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");

  const handleResendEmail = () => {
    dispatch(
      resendVerifyEmailRequest({
        params: { email: userEmail.replace(" ", "+") },
        fromExpiredLink: true,
        navigate,
        navigateWithError: true
      })
    );
  };

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${40}px  - ${70}px)`,
        marginTop: "15rem"
      }}
      className="register-failure-page w-100">
      <Box
        className="text-center faint-grey-bg border-radius-10 px-3 px-md-5 py-5 position-relative"
        sx={{
          width: {
            xs: "95%",
            sm: "90%",
            md: "80%",
            lg: "50%"
          },
          margin: "0 auto"
        }}>
        <Box
          className="vector-section mb-3"
          sx={{
            position: "absolute",
            top: {
              xs: "-8%",
              sm: "-20%"
            },
            left: "40%",
            width: {
              xs: "5rem",
              sm: "auto"
            }
          }}>
          <img src={resendEmail} className="w-100" />
        </Box>
        <Box
          className="content-section mt-4"
          sx={{
            margin: "0 auto",
            width: {
              xs: "100%",
              sm: "80%",
              md: "65%",
              lg: "85%",
              xl: "75%"
            }
          }}>
          <h3 className="fsize-24 fweight-700 title-color mb-4">
            {registeration.failurePage.linkExpired}
          </h3>
          <p className="text-light-grey fsize-16 mb-4">
            {registeration.failurePage.message1}
          </p>
          <div className="actions-section d-flex justify-content-center">
            <Button
              label={registeration.failurePage.resend}
              onClick={handleResendEmail}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterFailure;
