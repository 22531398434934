import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Add, Verified } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";

import {
  BANNER_COLORS,
  COMPANY_PROFILE_CATEGORY_STATUS,
  generalImporterPagesPadding,
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  SUBSCRIPTION_PLANS_IDS,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import {
  ChipInfoIcon,
  GreyBriefCaseIcon,
  GreyCalendarIcon,
  GreyConvertCubeIcon,
  GreyLocationIcon,
  ViewEditIcon
} from "../../../../utils/Icons";
import {
  checkBackgroundImageValidity,
  checkForMultiline,
  getUserCurrency
} from "../../../../utils/Helpers";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import noImgPlaceholder from "../../../../assets/images/empty_data.png";
import noCoverImgPlaceholder from "../../../../assets/images/noCoverImgPlaceholder.png";
import messages from "../../../../assets/locale/messages";
import noShowcasesImage from "../../../../assets/images/vectors/placeholders/noShowcasePlaceholder.svg";
import EmptyState from "../../../../components/EmptyState";
import Button from "../../../../components/Button";
import Alert from "../../../../components/Alert";
import ProductCardGrid from "../../../../components/ProductCards/ProductCardGrid";
import ViewDeleteButton from "../../../../components/ViewDeleteButton";
import ContactInfoCard from "../../../../components/ContactInfoCard";
import {
  getLoggedInExporterDetailsRequest,
  getLoggedInExporterDetailsResponse
} from "../../../../store/User/actions";
import { getPreviousSubscriptionTypeRequest } from "../../../../store/Subscription/actions";
import MaxPremiumShowcasesModal from "../MaxPremiumShowcasesModal";
import ShowHideShowcases from "../../../Subscription/ShowHideShowcases";
import MaxShowcasesAvailableModal from "../MaxShowcasesAvailableModal";
import PublishProfileModal from "../PublishProfileModal";
import SubscribeModal from "../SubscribeModal";
import DeleteShowcase from "../DeleteShowcase";
import VerificationsNotifications from "../../Verification/VerificationsNotifications";
import showcaseInsufficientProductsCardImg from "../../../../assets/images/showcaseInsufficientProductsCardImg.svg";
import { disableSubAccountsWarningBanner } from "../../../../store/Auth/actions";
import DownloadInstructionsAlert from "../../../../components/DownloadInstructionsAlert";
import "./ViewCompanyProfile.scss";

const ViewCompanyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const {
    exporters,
    company,
    company: { showcasesAlert, downgradeShowcases },
    shared
  } = messages[lang];

  const isPublished = useSelector(
    (state) => state.auth.login?.IsPublished
  );
  const accountDetails = useSelector(
    (state) => state.user.accountDetails
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  const isReactivatedExporter = useSelector(
    (state) => state.auth.login?.ReactivatedExporter
  );

  const isInstructionsBannerActive = useSelector(
    (state) => state.auth.login?.DisplayBanner
  );

  const [isPublishModalOpen, setIsPublishModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedShowcaseInfo, setDeletedShowcaseInfo] = useState({});
  const [isCompanyCoverImgValid, setIsCompanyCoverImgValid] =
    useState(true);
  const [isCompanyLogoImgValid, setIsCompanyLogoImgValid] =
    useState(true);
  const [validShowcaseImagesId, setValidShowcaseImagesId] = useState(
    []
  );
  const [
    isMaxShowcaseCountModalOpen,
    setIsMaxShowcaseCountModalOpen
  ] = useState(false);
  const [
    isMaxPremiumShowcasesModalOpen,
    setIsMaxPremiumShowcasesModalOpen
  ] = useState(false);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] =
    useState(false);
  const [allowedShowcasesCount, setAllowedShowcasesCount] =
    useState(2);
  const [subscribedPlanName, setSubscribedPlanName] = useState("");
  const [editShowcasesModalOpen, setEditShowcasesModalOpen] =
    useState(false);

  useEffect(() => {
    if (userPlan && !!Object.keys(userPlan).length) {
      setSubscribedPlanName(userPlan.Name);
      setAllowedShowcasesCount(
        parseInt(
          userPlan.Features.find((feature) => {
            return (
              feature.Id ===
              SUBSCRIPTION_PLAN_FEATURES_IDS.showcasesNumber
            );
          }).Value
        )
      );
    }
  }, [userPlan]);

  useEffect(() => {
    checkBackgroundImageValidity(
      accountDetails?.LogoImage,
      (isLoaded) => {
        setIsCompanyLogoImgValid(isLoaded);
      }
    );
    checkBackgroundImageValidity(
      accountDetails?.CoverImage,
      (isLoaded) => {
        setIsCompanyCoverImgValid(isLoaded);
      }
    );
    if (accountDetails?.IsNeedToEdit) {
      dispatch(getPreviousSubscriptionTypeRequest());
    }
  }, [
    accountDetails,
    accountDetails?.LogoImage,
    accountDetails?.CoverImage,
    accountDetails?.IsNeedToEdit
  ]);

  useEffect(() => {
    dispatch(getLoggedInExporterDetailsRequest());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getLoggedInExporterDetailsResponse({}));
    };
  }, []);

  useEffect(() => {
    if (
      accountDetails &&
      accountDetails.ShowCases &&
      accountDetails?.ShowCases?.length
    ) {
      accountDetails?.ShowCases.forEach((showcase) => {
        checkBackgroundImageValidity(showcase?.Image, (isLoaded) => {
          if (isLoaded) {
            setValidShowcaseImagesId((previousState) => {
              if (previousState.includes(showcase.Id)) {
                return previousState;
              } else return [...previousState, showcase.Id];
            });
          }
        });
      });
    }
  }, [accountDetails, validShowcaseImagesId]);

  const fillInProductCards = (productsArr) => {
    const tempProductsCardsArray = [];
    if (productsArr?.length <= 6) {
      let emptyProductsIndex = 0;
      for (let index = 0; index < 6; index++) {
        if (!!productsArr.length && productsArr[index]) {
          const {
            Id,
            Name,
            DefaultImage,
            MinOrderQuantity,
            MoqUnitName,
            MinPriceRange,
            MaxPriceRange
          } = productsArr[index];
          tempProductsCardsArray.push(
            <div
              key={`${Name}-${Id}`}
              className="single-product filled-product border-radius-10">
              <ProductCardGrid
                cardClassName="w-100"
                label={Name}
                src={DefaultImage}
                count={MinOrderQuantity}
                unit={MoqUnitName}
                minPrice={MinPriceRange}
                maxPrice={MaxPriceRange}
                link={ROUTE_PATHS.viewProduct.replace(":id", Id)}
                currency={getUserCurrency()?.symbol}
              />
            </div>
          );
        }
      }
    }
    return tempProductsCardsArray;
  };

  const renderHeaderSection = () => (
    <div
      className={`header-section ${generalImporterPagesPadding} size-cover`}
      style={{
        backgroundImage: `url(${isCompanyCoverImgValid ? accountDetails?.CoverImage : noCoverImgPlaceholder})`
      }}>
      <div
        className="profile-img circular-border-radius white-bg light-border"
        style={{
          backgroundImage: `url(${isCompanyLogoImgValid ? accountDetails?.LogoImage : noImgPlaceholder})`
        }}></div>
    </div>
  );

  const renderLabelValue = (label, value, icon) => (
    <div className="d-flex align-items-center text-break">
      <div>{icon}</div>
      <div className="ms-3">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>

        <p className="text-color fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  const renderExporterInfoSection = () => (
    <div className="exporter-info-section mb-5 row">
      <div className="col-xl-7 col-lg-6 col-md-7">
        <div className="description-section mb-4">
          <h3 className="fsize-24 fweight-700 title-color mb-3 text-break">
            {accountDetails?.DisplayName}
            {![
              USER_STATUS_IDS.registered,
              USER_STATUS_IDS.verified,
              USER_STATUS_IDS.pendingBusinessVerified
            ].includes(userStatusId) && (
              <Verified color="primary" className="ms-1" />
            )}
          </h3>
          {accountDetails?.Introduction || "--"}
        </div>
        <div className="categories-section mb-4 mb-md-0">
          <h4 className="fsize-16 fweight-500 title-color mb-3">
            {exporters.mainInfo.categories}
          </h4>
          {accountDetails &&
            accountDetails?.Categories?.some(
              (category) =>
                category.Status ===
                COMPANY_PROFILE_CATEGORY_STATUS.pending_admin_approval
            ) && (
              <Typography className="text-secondary fsize-13 ps-md-1 pt-2 text-break mb-1">
                {company.companyProfile.pendingCategoryWarning}
              </Typography>
            )}
          {accountDetails &&
            accountDetails?.Categories?.some(
              (category) =>
                category.Status ===
                COMPANY_PROFILE_CATEGORY_STATUS.rejected
            ) && (
              <Typography className="text-secondary fsize-13 ps-md-1 pt-2 text-break mb-1">
                {company.companyProfile.rejectedCategoryWarning}
              </Typography>
            )}
          <div className="categories-chips d-flex justify-content-start flex-wrap">
            {accountDetails?.Categories &&
            !!accountDetails.Categories.length
              ? accountDetails?.Categories.map(
                  ({ Name, Id, Status }, i) => (
                    <Chip
                      key={`category-${Id}`}
                      label={Name}
                      className={`${Status === COMPANY_PROFILE_CATEGORY_STATUS.accepted ? "blue-chip" : Status === COMPANY_PROFILE_CATEGORY_STATUS.rejected ? "red-chip" : "yellow-chip"} border-radius-5 fsize-14 fweight-400 ${i === 0 ? "" : "mx-2"}  my-2`}
                    />
                  )
                )
              : "--"}
          </div>
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-5 ">
        <ContactInfoCard
          name={accountDetails?.CompanyExporterName}
          mobileNumber={accountDetails?.MobileNumber}
          emailAddress={accountDetails?.Email}
          website={accountDetails?.Website}
        />
      </div>
    </div>
  );

  const renderCompanyInfoSection = () => (
    <div className="company-info-section mb-5">
      <h4 className="fsize-20 fweight-600 title-color mb-4">
        {exporters.mainInfo.companyInfo}
      </h4>
      <div className="row">
        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
          {renderLabelValue(
            exporters.mainInfo.yearEstablished,
            accountDetails?.EstablishedYear || "--",
            <GreyCalendarIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
          {renderLabelValue(
            exporters.mainInfo.yearsOfExperience,
            accountDetails?.YearsOfExperience?.toString() || "--",
            <GreyConvertCubeIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6  mb-4 mb-md-0">
          {renderLabelValue(
            exporters.mainInfo.country,
            accountDetails?.CountryName || "--",
            <GreyLocationIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6">
          {renderLabelValue(
            exporters.mainInfo.businessType,
            accountDetails?.BusinessTypeName || "--",
            <GreyBriefCaseIcon />
          )}
        </div>
      </div>
    </div>
  );

  const renderShowcaseCountBanner = () => (
    <div className="showcases-count-warning-container">
      <Typography
        component={"span"}
        className="fsize-16 fweight-500 chip-text w-100">
        {`${
          userStatusId === USER_STATUS_IDS.subscribed
            ? showcasesAlert.textFirstPart
            : ""
        } `}
        <span
          className={`plan d-inline-block fweight-600 ${userStatusId !== USER_STATUS_IDS.subscribed && "d-none"}`}>{`${subscribedPlanName} ${company.companyProfile.plan},`}</span>
        {` ${
          userStatusId !== USER_STATUS_IDS.subscribed
            ? showcasesAlert.textSecondPartUnsubscribed
            : showcasesAlert.textSecondPart
        } ${allowedShowcasesCount} ${showcasesAlert.showcases} ${
          userStatusId === USER_STATUS_IDS.subscribed &&
          userPlan &&
          userPlan.Id !== SUBSCRIPTION_PLANS_IDS.premium
            ? showcasesAlert.textThirdPart
            : ""
        } `}
        <span
          onClick={() => {
            userStatusId === USER_STATUS_IDS.subscribed
              ? navigate(ROUTE_PATHS.pricingDetails, {
                  state: {
                    isUpgrade: true
                  }
                })
              : navigate(ROUTE_PATHS.subscription);
          }}
          className={`upgradePlanPart d-inline fweight-600 pointer text-underline ${userPlan && userPlan.Id === SUBSCRIPTION_PLANS_IDS.premium && "d-none"}`}>
          {userStatusId !== USER_STATUS_IDS.subscribed
            ? showcasesAlert.subscribePart
            : showcasesAlert.upgradePlanPart}
        </span>
        {userPlan &&
        userPlan.Id !== SUBSCRIPTION_PLANS_IDS.premium &&
        userStatusId === USER_STATUS_IDS.subscribed
          ? ` ${showcasesAlert.now}`
          : ""}
      </Typography>
    </div>
  );

  const renderDowngradeShowcasesAlert = () => (
    <>
      <p className="mb-0 fsize-16 text-color">
        <span>{downgradeShowcases.msg1}</span>
        <span className="fweight-600">
          {downgradeShowcases.downgraded}
        </span>
        <span>{downgradeShowcases.msg2}</span>
        <span className="fweight-600">
          {allowedShowcasesCount} {downgradeShowcases.showcases}
        </span>
        <span>{downgradeShowcases.msg3}</span>
      </p>
      <p className="mb-0 fsize-16 text-color">
        <span>{downgradeShowcases.msg4}</span>
        <span
          className="text-primary fweight-600 text-underline pointer"
          onClick={() => setEditShowcasesModalOpen(true)}>
          {downgradeShowcases.editShowcaseSelection}
        </span>
      </p>
    </>
  );

  const renderShowCasesSection = () => (
    <div className="showcases-section pb-5 px-md-5 px-md-5 px-3 py-2 my-md-5 my-3">
      {accountDetails?.ShowCases?.length ? (
        <div className="mb-3 mt-2">
          <div className="showcases-alert-container mb-3 mt-2">
            {accountDetails?.IsNeedToEdit ? (
              <Alert
                type="info"
                content={renderDowngradeShowcasesAlert()}
                icon={
                  <ChipInfoIcon
                    color={BANNER_COLORS.info}
                    style={{ width: "1.5rem" }}
                    className="ChipInfoIcon"
                  />
                }
              />
            ) : (
              <Alert
                type="info"
                content={renderShowcaseCountBanner()}
                icon={
                  <ChipInfoIcon
                    color={BANNER_COLORS.info}
                    style={{ width: "1.5rem" }}
                    className="ChipInfoIcon"
                  />
                }
              />
            )}
          </div>
          <div className="pt-3">
            <div className="title-add-showcase-container d-flex justify-content-between align-items-center w-100 ">
              <h4 className="fsize-20 fweight-600 title-color mb-0">
                {company.companyProfile.showcases}
              </h4>
              <Button
                className={`bg-white px-0 `}
                outlined
                label={
                  <span className="d-flex align-items-center">
                    <Add fontSize="medium" />
                    <span className="ps-3  d-md-block">
                      {
                        company.companyProfile.buttons
                          .addShowcaseButton
                      }
                    </span>
                  </span>
                }
                onClick={() => {
                  accountDetails?.ShowCases.length <
                  allowedShowcasesCount
                    ? navigate(ROUTE_PATHS.addShowcase)
                    : userStatusId !== USER_STATUS_IDS.subscribed
                      ? setIsSubscribeModalOpen(true)
                      : userPlan.Id !== SUBSCRIPTION_PLANS_IDS.premium
                        ? setIsMaxShowcaseCountModalOpen(true)
                        : setIsMaxPremiumShowcasesModalOpen(true);
                }}
              />
            </div>
            {accountDetails?.ShowCases?.map((showcase, index) => {
              const productsCardsArray = fillInProductCards(
                showcase?.Products
              );

              return (
                <div key={`${showcase?.Name}-${index}`}>
                  <div
                    className={`showcase-btns-container my-5 ${index === 0 && "mt-3"} ${
                      index !==
                        accountDetails?.ShowCases.length - 1 &&
                      "border-bottom pb-5"
                    }`}>
                    <div className="buttons-container d-flex justify-content-end mb-4 gap-3 ps-xl-5">
                      <div>
                        <Button
                          className={`bg-white px-0 `}
                          outlined
                          label={
                            <span className="d-flex align-items-center">
                              <ViewEditIcon
                                className={`table-icon action-icon`}
                              />
                              <span className="ps-3  d-md-block">
                                {shared.edit}
                              </span>
                            </span>
                          }
                          onClick={() => {
                            navigate(
                              ROUTE_PATHS.editShowcase.replace(
                                ":id",
                                showcase.Id
                              )
                            );
                          }}
                        />
                      </div>
                      <div>
                        <ViewDeleteButton
                          onClick={() => {
                            setDeletedShowcaseInfo({
                              id: showcase.Id,
                              name: showcase?.Name,
                              src: ROUTE_PATHS.companyProfile
                            });
                            setIsDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={`showcase-img-products-container`}>
                      <div className="img-container ">
                        <div
                          className="showcase-image"
                          style={{
                            backgroundImage: `url(${validShowcaseImagesId.includes(showcase?.Id) ? showcase?.Image : noImgPlaceholder})`
                          }}>
                          <div className="showcase-info d-flex justify-content-center align-items-center text-center">
                            <div>
                              <Typography className="showcase-name fweight-700 fsize-28 px-md-5 px-3">
                                {showcase?.Name}
                              </Typography>
                              <Typography
                                variant="span"
                                className="showcase-description px-lg-5 px-3 ">
                                {checkForMultiline(
                                  showcase?.Description,
                                  "fweight-500 fsize-16",
                                  "me-0 ms-0"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="products-buttons-container w-100 ps-5">
                        <div className="products-container d-flex flex-wrap gap-3 w-100">
                          {productsCardsArray.map(
                            (product) => product
                          )}
                          {productsCardsArray.length <= 3 && (
                            <div className="showcase-products-card d-md-flex p-md-5 p-3 py-4 border-radius-10 mt-xl-0 mt-4">
                              <div className="card-img-container mb-md-0 mb-3 d-flex justify-content-xl-center justify-content-md-start justify-content-center col-xxl-4  col-xl-4 col-lg-3 col-md-4 col-12 ">
                                <img
                                  style={{
                                    width: "10rem",
                                    height: "10rem"
                                  }}
                                  src={
                                    showcaseInsufficientProductsCardImg
                                  }
                                />
                              </div>
                              <div className="card-content-container col-xxl-8 col-xl-8 col-lg-9 col-md-8 col-12 ps-0 ms-xl-1 ms-md-0 ps-lg-5">
                                <p className="text-break fweight-500 title-color fsize-18 ">
                                  {
                                    shared.dashboardShowcaseInsufficientProductsCard
                                  }
                                </p>
                                <div className="d-flex justify-content-md-start justify-content-center">
                                  <Button
                                    labelClass=" text-white"
                                    label={
                                      company.companyProfile
                                        .addProduct
                                    }
                                    onClick={() => {
                                      navigate(
                                        ROUTE_PATHS.editShowcase.replace(
                                          ":id",
                                          showcase.Id
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <EmptyState
          subTitle={
            company.companyProfile.emptyShowCase.noShowcasePlaceholder
          }
          showActionButton
          buttonLabel={
            <span className="d-flex align-items-center">
              <Add fontSize="medium" />
              <span className="ps-1 ">
                {
                  company.companyProfile.emptyShowCase
                    .addShowcaseButton
                }
              </span>
            </span>
          }
          onButtonClick={() => {
            navigate(ROUTE_PATHS.addShowcase);
          }}
          buttonClassName="empty-state-create-button border-radius-8"
          imgSrc={noShowcasesImage}
        />
      )}
    </div>
  );

  const renderAlertContent = () => (
    <div className="alert-content d-flex align-items-center justify-content-start">
      <div className="fsize-16 mb-1">
        <span className="fw-bold ">
          {company.companyProfile.banner.boldContent}
        </span>
        {company.companyProfile.banner.normalContent}
      </div>
    </div>
  );

  const renderSubAccountsAlertContent = () => (
    <div className="alert-content d-flex align-items-center justify-content-start">
      <div className="fsize-16 mb-1">
        <span className="fw-bold ">
          {
            company.companyProfile.subAccountsWarningBanner
              .boldContent
          }
        </span>
        {
          company.companyProfile.subAccountsWarningBanner
            .normalContent
        }
      </div>
    </div>
  );

  return (
    <div className="exporter-profile-container">
      {isInstructionsBannerActive && (
        <div className="download-instructions-banner-container border-radius-8 mb-3">
          <DownloadInstructionsAlert isClosable />
        </div>
      )}
      {isReactivatedExporter && (
        <div className="subAccounts-warning-banner-container border-radius-8 mb-3">
          <Alert
            type="warning"
            content={renderSubAccountsAlertContent()}
            icon={
              <ChipInfoIcon
                color={BANNER_COLORS.warning}
                style={{ width: "1.5rem" }}
                className="ChipInfoIcon"
              />
            }
            isClosable
            onClose={() => {
              dispatch(disableSubAccountsWarningBanner());
            }}
          />
        </div>
      )}
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) && (
        <div className="mb-3">
          <VerificationsNotifications />
        </div>
      )}
      <div className="page-title pb-3 d-md-flex justify-content-between align-items-center">
        <div className="title-count-container d-flex justify-content-center align-items-center">
          <div>
            <Typography variant="h5" className="title-color">
              {company.companyProfile.companyProfileTitle}
            </Typography>
          </div>
        </div>
        <div className="title-buttons-container d-md-flex mt-md-0 mt-2 justify-content-center gap-3 align-items-center">
          {!isPublished && (
            <Button
              labelClass=" text-white"
              label={
                <span className="d-flex align-items-center">
                  <span className="ps-1 ">
                    {company.companyProfile.buttons.publishButton}
                  </span>
                </span>
              }
              onClick={() => {
                setIsPublishModal(true);
              }}
            />
          )}
          <Button
            className={`bg-white px-0 `}
            outlined
            label={
              <span className="d-flex align-items-center">
                <ViewEditIcon fontSize="medium" />
                <span className="ps-md-3 ps-2 d-md-block">
                  {company.companyProfile.buttons.editButton}
                </span>
              </span>
            }
            onClick={() => {
              navigate(ROUTE_PATHS.editCompanyProfile);
            }}
          />
        </div>
      </div>
      <div>
        {!isPublished &&
          ![
            USER_STATUS_IDS.registered,
            USER_STATUS_IDS.verified,
            USER_STATUS_IDS.pendingBusinessVerified
          ].includes(userStatusId) && (
            <div className="banner-container border-radius-8 mb-3">
              <Alert
                type="info"
                content={renderAlertContent()}
                icon={
                  <ChipInfoIcon
                    color={BANNER_COLORS.info}
                    style={{ width: "1.5rem" }}
                    className="ChipInfoIcon"
                  />
                }
              />
            </div>
          )}
      </div>
      <div className="bg-white border-radius-10">
        {renderHeaderSection()}
        <div className="px-md-5 px-4 py-2 my-5 border-radius-10 bg-white">
          {renderExporterInfoSection()}
          {renderCompanyInfoSection()}
        </div>
      </div>
      <div className="bg-white my-5 border-radius-10">
        {renderShowCasesSection()}
      </div>
      {isPublishModalOpen && (
        <PublishProfileModal
          open={isPublishModalOpen}
          handleClose={() => {
            setIsPublishModal(false);
          }}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteShowcase
          open={isDeleteModalOpen}
          closeModal={() => {
            setIsDeleteModalOpen(false);
          }}
          showcaseInfo={deletedShowcaseInfo}
        />
      )}
      {isMaxShowcaseCountModalOpen && (
        <MaxShowcasesAvailableModal
          open={isMaxShowcaseCountModalOpen}
          closeModal={() => {
            setIsMaxShowcaseCountModalOpen(false);
          }}
        />
      )}
      {isSubscribeModalOpen && (
        <SubscribeModal
          open={isSubscribeModalOpen}
          closeModal={() => {
            setIsSubscribeModalOpen(false);
          }}
        />
      )}
      {isMaxPremiumShowcasesModalOpen && (
        <MaxPremiumShowcasesModal
          open={isMaxPremiumShowcasesModalOpen}
          closeModal={() => {
            setIsMaxPremiumShowcasesModalOpen(false);
          }}
        />
      )}
      {accountDetails?.IsNeedToEdit && (
        <ShowHideShowcases
          open={editShowcasesModalOpen}
          onClose={() => setEditShowcasesModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ViewCompanyProfile;
