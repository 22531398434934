import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/settings";
import * as types from "./types";
import * as actions from "./actions";
import { loginResponse, logoutRequest } from "../Auth/actions";
import { setWebsiteCurrency } from "../Home/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import { setUser } from "../../utils/Auth";
import store from "..";

function* changePasswordSaga({ payload }) {
  try {
    yield call(apis.changePassword, payload);
    store.dispatch(actions.showChangePasswordSuccessModal(true));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* deleteExporterAccountSaga({ payload }) {
  try {
    yield call(apis.deleteExporterAccount);
    yield put(
      logoutRequest({
        ...payload.logoutParams
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deleteImporterAccountSaga({ payload }) {
  try {
    yield call(apis.deleteImporterAccount, payload);
    yield put(
      logoutRequest({
        ...payload.logoutParams
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSubscriptionEndDateSaga({ payload }) {
  try {
    const response = yield call(apis.getSubscriptionEndDate, payload);
    yield put(
      actions.getSubscriptionEndDateResponse(
        response.data.Data.EndDate
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deactivateExporterAccountSaga({ payload }) {
  try {
    yield call(apis.deactivateExporterAccount);
    yield put(
      logoutRequest({
        ...payload.logoutParams
      })
    );
    yield showSuccessMsg("deactivateAccountSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deactivateImporterAccountSaga({ payload }) {
  try {
    yield call(apis.deactivateImporterAccount, payload);
    yield put(
      logoutRequest({
        ...payload.logoutParams
      })
    );
    yield showSuccessMsg("deactivateAccountSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* changeCurrencySaga({ payload }) {
  try {
    const { currency } = payload;
    yield call(apis.changeCurrency, payload);
    yield showSuccessMsg("currencyChangedSuccessfully");

    const userData = store.getState().auth.login;
    const updatedUserDataWithCurrency = {
      ...userData,
      currency: {
        CurrencyId: currency.Id,
        CurrencyName: currency.Name,
        CurrencyCode: currency.Code,
        CurrencySymbol: currency.Symbol
      }
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithCurrency));
    yield rememberMe
      ? setUser(updatedUserDataWithCurrency, "local")
      : setUser(updatedUserDataWithCurrency, "session");

    const websiteCurrency = {
      Id: currency.Id,
      Name: currency?.Name,
      Code: currency?.Code,
      Symbol: currency?.Symbol
    };
    yield put(setWebsiteCurrency(websiteCurrency));
    yield sessionStorage.setItem(
      "website-currency",
      JSON.stringify(websiteCurrency)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* disableEnableProductRequestNotificationsSaga({ payload }) {
  try {
    yield call(apis.disableEnableProductRequestNotification, payload);
    yield showSuccessMsg("productRequestNotificationsUpdated");
    const userData = store.getState().auth.login;
    const updatedUserDataWithNotifications = {
      ...userData,
      CanRecieveProductRequestNotifications:
        payload.canRecieveProductRequestNotifications
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithNotifications));
    yield rememberMe
      ? setUser(updatedUserDataWithNotifications, "local")
      : setUser(updatedUserDataWithNotifications, "session");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* SettingsSaga() {
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(
    types.DELETE_EXPORTER_ACCOUNT_REQUEST,
    deleteExporterAccountSaga
  );
  yield takeLatest(
    types.DELETE_IMPORTER_ACCOUNT_REQUEST,
    deleteImporterAccountSaga
  );
  yield takeEvery(
    types.GET_SUBSCRIPTION_END_DATE_REQUEST,
    getSubscriptionEndDateSaga
  );
  yield takeLatest(
    types.DEACTIVATE_EXPORTER_ACCOUNT,
    deactivateExporterAccountSaga
  );
  yield takeLatest(
    types.DEACTIVATE_IMPORTER_ACCOUNT,
    deactivateImporterAccountSaga
  );
  yield takeLatest(types.CHANGE_CURRENCY_REQUEST, changeCurrencySaga);
  yield takeLatest(
    types.DISABLE_ENABLE_PRODUCT_REQUEST_NOTIFICATION,
    disableEnableProductRequestNotificationsSaga
  );
}
