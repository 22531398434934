const shared = {
  home: "Home",
  language: "Ar",
  admins: "Admins",
  addAdmin: "Add admins",
  lookups: "Lookups",
  serviceProviderSubCategories: "Service provider sub categories",
  serviceProviders: "Service providers",
  specialties: "Specialties",
  services: "Services",
  logout: "Logout",
  lang: "العربية",
  back: "Back",
  noSearch: "There are no results matching your search",
  noData: "There are no data yet",
  actions: "Actions",
  add: "Add",
  edit: "Edit",
  view: "View",
  delete: "Delete",
  deactivate: "Deactivate",
  save: "Save",
  change: "Change",
  savePublish: "Save and Publish",
  cancel: "Cancel",
  apply: "Apply",
  filter: "Filter",
  confirm: "Confirm",
  continue: "Continue",
  submit: "Submit",
  myAccount: "My Account",
  navbarTitle: "Welcome to Ex-Gates seamless trade across borders",
  copyrights: "©2024 ExGates  |  All Rights Reserved",
  filters: "Filters",
  clearFilters: "Clear Filters",
  clear: "Clear",
  from: "From",
  ok: "Ok",
  account: "Account",
  filtersDrawerLabels: {
    subCategoriesSearchBar: "Search by Sub Category Name",
    categoriesSearchBar: "Search by Category Name",
    status: "Status",
    subCategories: "Sub Categories",
    priceRange: "Price Range",
    viewMore: "View More",
    viewLess: "View Less",
    dateRange: "Publishing date range",
    categories: "Categories",
    roles: "Roles",
    rolesSearchBar: "Search by role"
  },
  lists: {
    enTitle: "Title (En)",
    arTitle: "Title (Ar)",
    enName: "Name (En)",
    arName: "Name (Ar)",
    name: "Name",
    email: "Email",
    phone: "Phone Number",
    image: "Image",
    subCategories: "Sub Categories",
    status: "Status"
  },
  filesAccepted:
    "Max size: 2MB, accepted formats: jpg, jpeg, png, and bmp",
  acceptedFormatLabel: "Accepted format",
  noOptions: "No options",
  auth: {
    message1: "Connecting Markets, Delivering Success",
    message2:
      "Join us and discover the endless possibilities for growth and success in the global economy"
  },
  passwordValidations: {
    passwordConditions: "Your password must contain",
    eightCharacters: "Minimum 8 characters",
    oneUpper: "Contains uppercase characters",
    oneLower: "Contains lowercase characters",
    numbersAndSpecial: "Contains numbers and symbols"
  },
  sideMenu: {
    company: "Company",
    products: "Products",
    myAccount: "My Account",
    myProfile: "My Profile",
    profile: "Profile",
    subscription: "Subscription",
    subAccounts: "Sub Accounts",
    settings: "Settings",
    productRequests: "Product Requests"
  },
  supportText: "Need Help ? You can ask for Support from our team ",
  supportTextHighlighted: "Send Message",
  supportTextClicked:
    "You have contacted our support team and will respond as soon as possible",
  to: "To",
  done: "Done",
  sortBy: "Sort by",
  loadMore: "Load More",
  loadLess: "Load Less",
  piece: "Piece",
  publish: "Publish",
  unPublish: "Unpublish",
  seeAllProducts: "See All Products",
  moq: "MOQ",
  myStore: "My Store",
  myDashboard: "My Dashboard",
  loggedInUserGreeting: "Hi",
  internetConnection: "No Internet Connection",
  generalErrMsg: "Sorry, Something Went Wrong",
  errSettingNotifications:
    "Sorry, there was an error setting up notifications",
  askForNotificationsPermission:
    "For notifications to work, kindly enable notifications permission on your browser",
  any: "any",
  noProductFound: "No product found",
  exporterContactCard: {
    sorry: "Sorry !",
    signInMsg: "You need to sign in to see exporter contact details",
    signInImporterMsg:
      "You need to sign in as a subscribed exporter to see importer contact details",
    notSubscribedSignInMsg:
      "You need to be a subscribed exporter to view importer contact details",
    signIn: "Sign In",
    subscribe: "Subscribe",
    cancel: "Cancel",
    showContactInfo: "Show Contact Info"
  },
  next: "Next",
  previous: "Previous",
  clickToView: "Click to view",
  downloadImage: "Download Image",
  browseFile: "Browse File",
  profileImg: "Profile-img",
  upgrade: "Upgrade",
  switchTo: "Switch to",
  upgradeNow: "Upgrade Now",
  exporterStillUnverified:
    "This exporter company's profile is still unverified.",
  voucher: {
    voucherDetails: "Voucher Details",
    requestVoucherButton: "Request Voucher",
    voucherRequestedText:
      "Your voucher request has been received. Please wait while our support team processes it. The voucher will be sent to your email at",
    shortly: "shortly.",
    voucherCode: "Voucher Code",
    voucherCodePlaceholder: "Enter the Code",
    applyButton: "Apply",
    clearButton: "Clear",
    subTotal: "Sub Total",
    voucher: "Voucher",
    total: "Total"
  },
  showcaseInsufficientProductsCard:
    "Discover even more products from this exporter! Browse the entire subcategory to see their full selection .",
  dashboardShowcaseInsufficientProductsCard:
    "Maximize your reach, Grow your business, Add more products to your showcase today!",
  exploreSubcategory: "Explore Subcategory",
  exporterName: "Exporter Name",
  importerName: "Importer Name",
  contactInfo: "Contact Info",
  mobileNumber: "Mobile Number",
  emailAddress: "Email Address",
  website: "Website",
  companyName: "Company Name",
  on: "ON",
  off: "OFF",
  notFound: {
    goBack: "Go Back",
    sorry: "SORRY!",
    noPermission: "you do not have permission to access this page."
  },
  downloadInstructionsBanner: {
    boldContent: "Make Your Company Shine! 🌟",
    normalContent:
      "High quality images and videos make your company look professional and build trust with buyers. Follow these tips to showcase your products at their best and leave a great impression!",
    downloadButton: "Download Instructions"
  },
  exgatesInstructionsPDF: "Exgates Instructions"
};

export default shared;
