import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import DownloadIcon from "@mui/icons-material/Download";
import messages from "../../../../assets/locale/messages";
import {
  BankBgIcon,
  BankSmallIcon,
  BuildingsSmallIcon,
  CardSmallIcon,
  ChipInfoIcon,
  GlobalSmallIcon,
  IdCardSmallIcon,
  MoneyBillCheckIcon
} from "../../../../utils/Icons";
import {
  BANNER_COLORS,
  EXGATES_BANK_INFO_PDF,
  OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES,
  VERIFICATION_METHODS
} from "../../../../utils/Constants";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../../utils/blobStorageServices";
import Alert from "../../../../components/Alert";
import Button from "../../../../components/Button";
import FileUpload from "../../../../components/Upload/FileUpload";
import TransferSuccess from "./TransferSuccess";
import {
  getExgatesBankInfoRequest,
  sendBankTransferRequest,
  setSelectedVerificationMethod,
  setVerificationStep
} from "../../../../store/Verification/actions";
import { showHideLoader } from "../../../../store/Loader/actions";

const BankTransfer = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { bankTransfer, actions },
    shared
  } = messages[lang];

  const bankInfo = useSelector(
    (state) => state.verification.exgatesBankInfo
  );

  useEffect(() => {
    dispatch(getExgatesBankInfoRequest());
  }, [dispatch]);

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    setValues
  } = useFormik({
    initialValues: {
      DefaultDocument: "",
      DefaultDocumentInfo: { name: "", type: "" },
      OriginlDefaultDocumentName: ""
    },
    validationSchema: Yup.object({
      DefaultDocument: Yup.mixed().required("bankTransciptRequired")
    }),
    onSubmit: async ({
      DefaultDocument,
      DefaultDocumentInfo,
      OriginlDefaultDocumentName
    }) => {
      dispatch(showHideLoader(true));
      let documentName = "";
      if (DefaultDocument) {
        documentName = await UploadBlobToContainer(
          {
            blob: DefaultDocument,
            ...DefaultDocumentInfo
          },
          DefaultDocumentInfo?.type.includes("pdf")
            ? BLOB_CONTAINERS.pdfs
            : BLOB_CONTAINERS.images
        );
      }
      const transferData = {
        DefaultDocument: documentName,
        OriginlDefaultDocumentName,
        DocumentTypeId: DefaultDocumentInfo?.type.includes("pdf")
          ? OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.pdf
          : OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.image
      };
      dispatch(sendBankTransferRequest(transferData));
    }
  });

  const renderBankInfoItem = (icon, label, value, largeLabel) => (
    <div className="col-12 col-lg-6 mb-3">
      <div className="row h-100">
        <div
          className={`d-flex align-items-center mb-2 mb-sm-0 ${largeLabel ? "col-lg-5 col-sm-4 col-12" : "col-sm-4 col-12"}`}>
          <div className="d-flex align-items-center">
            {icon}
            <span className="ms-2 text-light-grey">{label}</span>
          </div>
        </div>
        <div
          className={`d-flex align-items-center ms-4 ms-sm-0 ${largeLabel ? "col-sm-7 col-12" : "col-sm-8 col-12"}`}>
          <p className="mb-0 fsize-16 fweight-600 text-color text-break">
            {value}
          </p>
        </div>
      </div>
    </div>
  );

  const downloadPDF = () => {
    const url = EXGATES_BANK_INFO_PDF;
    window.open(url, "_blank");
  };

  return (
    <div className="bank-tansfer-container">
      <div className="method-declaration mb-4">
        <Box
          className="border-radius-10 light-border d-flex align-items-center p-3"
          sx={{
            width: {
              xs: "100%",
              md: "40%"
            }
          }}>
          <BankBgIcon />
          <p className="ms-3 mb-0 fsize-16 fweight-600 title-color">
            {bankTransfer.verificationMethod}
            {bankTransfer.bankTransfer}
          </p>
        </Box>
      </div>
      <h4 className="fsize-18 fweight-600 title-color">
        {bankTransfer.paymentInformation}
      </h4>
      <p className="fsize-16 text-color">
        {bankTransfer.paymentInfoHint}
        <span className="fweight-600">
          {bankTransfer.nonRefundable}
        </span>
      </p>
      <Alert
        content={
          <p className="mb-0 fsize-16 ">
            {bankTransfer.paymentWarning}
          </p>
        }
        icon={<ChipInfoIcon color={BANNER_COLORS.warning} />}
        type="warning"
        className="mb-4"
      />
      <div className="bank-info border-radius-10 faint-grey-bg p-4 mb-4">
        <div className="d-flex gap-3">
          <h6 className="fsize-16 fweight-600 title-color mb-4">
            {bankTransfer.exgatesBankInfo}
          </h6>
          <div
            className="download-icon-container pointer"
            onClick={downloadPDF}>
            <DownloadIcon />
          </div>
        </div>
        <div className="bank-info-section">
          <div className="row">
            {renderBankInfoItem(
              <BankSmallIcon />,
              bankTransfer.bankName,
              bankInfo?.BankName,
              true
            )}
            {renderBankInfoItem(
              <IdCardSmallIcon />,
              bankTransfer.name,
              bankInfo?.Name,
              false
            )}
            {renderBankInfoItem(
              <MoneyBillCheckIcon />,
              bankTransfer.accountNumber,
              bankInfo?.AccountNumber,
              true
            )}
            {renderBankInfoItem(
              <GlobalSmallIcon dimensions={25} />,
              bankTransfer.iban,
              bankInfo?.IBAN,
              false
            )}
            {renderBankInfoItem(
              <BuildingsSmallIcon />,
              bankTransfer.branch,
              bankInfo?.Branch,
              true
            )}
            {renderBankInfoItem(
              <CardSmallIcon />,
              bankTransfer.swiftCode,
              bankInfo?.SwiftCode,
              false
            )}
          </div>
        </div>
      </div>
      <div className="card-info-section border-radius-10 light-border p-4 mb-4">
        <form
          onSubmit={handleSubmit}
          noValidate
          id="bank-transfer-transcript">
          <h6 className="fsize-16 fweight-600 title-color mb-4">
            {bankTransfer.requiredInformation}
          </h6>
          <FileUpload
            id="transcript-file"
            name="transcript-file"
            label={bankTransfer.uploadBankTransferTranscript}
            onChange={(file) => {
              setFieldTouched("DefaultDocument");
              setValues({
                ...values,
                DefaultDocument: file,
                DefaultDocumentInfo: {
                  name: file?.name || "",
                  type: file?.type || ""
                },
                OriginlDefaultDocumentName: file?.name || ""
              });
            }}
            value={values.DefaultDocument}
            fileInfo={values.DefaultDocumentInfo}
            required={true}
            fileUploadLabel={shared.browseFile}
            acceptsImgs
            isInputHasErr={
              !!(touched.DefaultDocument && errors.DefaultDocument)
            }
            errMsg={errors.DefaultDocument}
          />
        </form>
      </div>
      <div className="actions-section w-100 d-flex justify-content-end align-items-center">
        <Button
          label={actions.back}
          customSpacing={"px-5 py-2"}
          className={"me-3"}
          outlined
          onClick={() => {
            dispatch(setVerificationStep(0));
            dispatch(
              setSelectedVerificationMethod(
                VERIFICATION_METHODS.bankTransfer
              )
            );
          }}
        />
        <Button
          label={actions.submit}
          customSpacing={"px-5 py-2"}
          type={"submit"}
          form="bank-transfer-transcript"
        />
      </div>
      <TransferSuccess />
    </div>
  );
};

export default BankTransfer;
