import {
  GET_COMPANY_VERIFICATION_STATUS_RESPONSE,
  GET_EXGATES_BANK_INFO_RESPONSE,
  GET_STRIPE_CLIENT_SECRET_RESPONSE,
  GET_VERIFICATION_METHODS_RESPONSE,
  REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE,
  REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE,
  SET_BANK_TRANSFER_SUCCESS,
  SET_PAYMENT_FAILURE_ERR_MSG,
  SET_PAYMENT_INTENT_ID,
  SET_SELECTED_VERIFICATION_METHOD,
  SET_VERIFICATION_STEP
} from "./types";

const INITIAL_STATE = {
  verificationStep: 0,
  selectedMethod: null,
  verificationMethods: [],
  stripeClientSecret: null,
  paymentFailureErrMsg: null,
  paymentIntentId: null,
  exgatesBankInfo: {},
  bankTransferSuccess: false,
  companyVerificationStatus: null,
  offplatformRequiredDocs: {},
  offplatformRequestData: {}
};

const verification = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VERIFICATION_STEP:
      return {
        ...state,
        verificationStep: action.payload
      };
    case SET_SELECTED_VERIFICATION_METHOD:
      return {
        ...state,
        selectedMethod: action.payload
      };
    case GET_VERIFICATION_METHODS_RESPONSE:
      return {
        ...state,
        verificationMethods: action.payload
      };
    case GET_STRIPE_CLIENT_SECRET_RESPONSE:
      return {
        ...state,
        stripeClientSecret: action.payload
      };
    case SET_PAYMENT_FAILURE_ERR_MSG:
      return {
        ...state,
        paymentFailureErrMsg: action.payload
      };
    case SET_PAYMENT_INTENT_ID:
      return {
        ...state,
        paymentIntentId: action.payload
      };
    case GET_EXGATES_BANK_INFO_RESPONSE:
      return {
        ...state,
        exgatesBankInfo: action.payload
      };
    case SET_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        bankTransferSuccess: action.payload
      };
    case GET_COMPANY_VERIFICATION_STATUS_RESPONSE:
      return {
        ...state,
        companyVerificationStatus: action.payload
      };
    case REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE:
      return {
        ...state,
        offplatformRequiredDocs: action.payload
      };
    case REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE:
      return {
        ...state,
        offplatformRequestData: action.payload
      };
    default:
      return state;
  }
};

export default verification;
