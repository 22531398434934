import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#0071BC"
    },
    secondary: {
      main: "#22b573"
    },
    success: {
      main: "#22b573"
    },
    error: {
      main: "#d32f2f"
    },
    warning: {
      main: "#e8b42d"
    }
    // Define rest of theme colors and add the brand own colors
  },
  tooltip: {
    // Add tooltip colors
  },
  typography: {
    fontFamily: "Poppins-Regular",
    h1: {
      fontFamily: "Poppins-Bold"
    },
    h2: {
      fontFamily: "Poppins-Bold"
    },
    h3: {
      fontFamily: "Poppins-Bold"
    },
    h4: {
      fontFamily: "Poppins-Bold"
    },
    h5: {
      fontFamily: "Poppins-Bold"
    },
    h6: {
      fontFamily: "Poppins-Bold"
    }
  }
});

export default Theme;
