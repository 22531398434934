import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

const Breadcrumbs = ({ list }) => {
  return (
    <div className={`bread-crumbs-container mb-2`}>
      <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
        {list?.map(({ label, link }, i) =>
          link ? (
            <Link
              to={link}
              className="fsize-12 text-light-grey"
              key={`bread-crumb-link-${i}`}>
              {label}
            </Link>
          ) : (
            <span
              className="fsize-12 text-light-grey"
              key={`bread-crumb-link-${i}`}>
              {label}
            </span>
          )
        )}
      </MuiBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired
};
