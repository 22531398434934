import { call, put, takeLatest } from "redux-saga/effects";

import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import * as apis from "../../network/apis/subAccounts";
import * as actions from "./actions";
import * as types from "./types";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";

function* subAccountsListSaga({ payload }) {
  try {
    const response = yield call(apis.getSubAccountsList, payload);

    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getSubAccountsListResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
    if (
      +CurrentPage !== 0 &&
      +TotalPages !== 0 &&
      +CurrentPage > +TotalPages
    ) {
      yield put(
        actions.getSubAccountsListRequest({
          ...payload,
          params: {
            ...payload.params,
            page: +TotalPages
          }
        })
      );
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getActiveSubAccountsCountSaga() {
  try {
    const response = yield call(apis.getSubAccountsCount);
    yield put(
      actions.getSubAccountsCountResponse(response.data.Data?.Count)
    );
    yield put(
      actions.setSubAccountsIsNeedEdit(response.data.Data?.IsNeedEdit)
    );
  } catch (error) {
    yield error.response?.data?.Message &&
      showErrorMsg(error.response?.data?.Message);
  }
}

function* addSubAccountSaga({ payload }) {
  try {
    const { navigate } = payload;
    yield call(apis.addSubAccount, payload);
    yield showSuccessMsg("subAccountAddedSuccessfully");
    yield navigate(ROUTE_PATHS.subAccounts);
  } catch (error) {
    yield error.response?.data?.Message &&
      showErrorMsg(error.response?.data?.Message);
  }
}

function* getSubAccountProfileSaga({ payload }) {
  try {
    const response = yield call(apis.getSubAccountProfile, payload);
    yield put(
      actions.getSubAccountProfileResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editSubAccountProfileSaga({ payload }) {
  const { data } = payload;
  try {
    yield call(apis.editSubAccountProfile, data);
    yield showSuccessMsg("editSubAccountSuccess");
    yield payload.navigate(ROUTE_PATHS.subAccounts);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* reactivateSubAccountProfileSaga({ payload }) {
  try {
    yield call(apis.reactivateSubAccountProfile, payload.data);
    yield showSuccessMsg("reactivateSubAccountSuccess");
    yield put(
      actions.getSubAccountsListRequest({ params: payload.params })
    );
    yield put(actions.getSubAccountsCountRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deactivateSubAccountProfileSaga({ payload }) {
  try {
    yield call(apis.deactivateSubAccountProfile, payload.data);
    yield showSuccessMsg("deactivateSubAccountSuccess");
    yield put(
      actions.getSubAccountsListRequest({ params: payload.params })
    );
    yield put(actions.getSubAccountsCountRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* SubAccountsSagas() {
  yield takeLatest(
    types.GET_SUB_ACCOUNTS_LIST_REQUEST,
    subAccountsListSaga
  );
  yield takeLatest(
    types.GET_SUB_ACCOUNTS_COUNT_REQUEST,
    getActiveSubAccountsCountSaga
  );
  yield takeLatest(types.ADD_SUB_ACCOUNT_REQUEST, addSubAccountSaga);
  yield takeLatest(
    types.GET_SUB_ACCOUNT_PROFILE_REQUEST,
    getSubAccountProfileSaga
  );
  yield takeLatest(
    types.EDIT_SUB_ACCOUNT_PROFILE_REQUEST,
    editSubAccountProfileSaga
  );
  yield takeLatest(
    types.REACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
    reactivateSubAccountProfileSaga
  );
  yield takeLatest(
    types.DEACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
    deactivateSubAccountProfileSaga
  );
}
