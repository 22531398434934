import {
  CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE,
  LOGIN_RESPONSE,
  SET_DISABLE_RESEND_EMAIL,
  SET_REGISTERATION_FORM_DATA,
  SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL,
  CHANGE_CURRENT_USER_ROLE
} from "./types";

const INITIAL_STATE = {
  login: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
  forgetPassword: {
    disableResendEmail: false
  },
  registeration: {
    formData: {},
    canResend: {},
    disableResendEmail: false
  },
  userCurrentRole:
    !!sessionStorage.getItem("user") &&
    !!sessionStorage.getItem("userType")
      ? JSON.parse(sessionStorage.getItem("userType"))
      : !!localStorage.getItem("userType")
        ? JSON.parse(localStorage.getItem("userType"))
        : null
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESPONSE:
      return { ...state, login: action.payload };
    case SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL:
      return {
        ...state,
        forgetPassword: {
          disableResendEmail: action.payload
        }
      };
    case SET_REGISTERATION_FORM_DATA:
      return {
        ...state,
        registeration: {
          ...state.registeration,
          formData: action.payload.clearForm
            ? {}
            : {
                ...state.registeration.formData,
                ...action.payload
              }
        }
      };
    case CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE:
      return {
        ...state,
        registeration: {
          ...state.registeration,
          canResend: { ...action.payload }
        }
      };
    case SET_DISABLE_RESEND_EMAIL:
      return {
        ...state,
        registeration: {
          ...state.registeration,
          disableResendEmail: action.payload
        }
      };
    case CHANGE_CURRENT_USER_ROLE:
      return {
        ...state,
        userCurrentRole: action.payload
      };
    default:
      return state;
  }
};

export default auth;
