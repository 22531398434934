export const GET_SUBSCRIPTION_PLANS_DETAILS_REQUEST =
  "GET_SUBSCRIPTION_PLANS_DETAILS_REQUEST";
export const GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE =
  "GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE";

export const GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST =
  "GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST";
export const GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE =
  "GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE";

export const CREATE_SUBSCRIPTION_PAYMENT_INTENT_REQUEST =
  "CREATE_SUBSCRIPTION_PAYMENT_INTENT_REQUEST";
export const CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE =
  "CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE";

export const CONFIRM_SUBSCRIPTION_PAYMENT =
  "CONFIRM_SUBSCRIPTION_PAYMENT";
export const SET_SUBSCRIPTION_SUCCESS_MSG =
  "SET_SUBSCRIPTION_SUCCESS_MSG";

export const SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG =
  "SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG";

export const SET_SUBSCRIPTION_PAYMENT_INTENT_ID =
  "SET_SUBSCRIPTION_PAYMENT_INTENT_ID";

export const GET_USER_SUBSCRIPTION_PLAN_REQUEST =
  "GET_USER_SUBSCRIPTION_PLAN_REQUEST";
export const GET_USER_SUBSCRIPTION_PLAN_RESPONSE =
  "GET_USER_SUBSCRIPTION_PLAN_RESPONSE";

export const GET_PREVIOUS_SUBSCRIPTION_TYPE_REQUEST =
  "GET_PREVIOUS_SUBSCRIPTION_TYPE_REQUEST";
export const GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE =
  "GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE";

export const DELETE_EXTRA_SHOWCASES = "DELETE_EXTRA_SHOWCASES";

export const REQUEST_VOUCHER_REQUEST = "REQUEST_VOUCHER_REQUEST";
export const REQUEST_VOUCHER_RESPONSE = "REQUEST_VOUCHER_RESPONSE";

export const APPLY_VOUCHER_REQUEST = "APPLY_VOUCHER_REQUEST";
export const APPLY_VOUCHER_RESPONSE = "APPLY_VOUCHER_RESPONSE";

export const CHECK_CAN_RENEW_SUBSCRIPTION_REQUEST =
  "CHECK_CAN_RENEW_SUBSCRIPTION_REQUEST";
export const CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE =
  "CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE";

export const GET_SUBSCRIPTION_FINAL_FEES_REQUEST =
  "GET_SUBSCRIPTION_FINAL_FEES_REQUEST";
export const GET_SUBSCRIPTION_FINAL_FEES_RESPONSE =
  "GET_SUBSCRIPTION_FINAL_FEES_RESPONSE";

export const CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_REQUEST =
  "CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_REQUEST";
export const CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE =
  "CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE";

export const ENABLE_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST =
  "ENABLE_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST";

export const CANCEL_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST =
  "CANCEL_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST";

export const UPDATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_TYPE_REQUEST =
  "UPDATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_TYPE_REQUEST";

export const GET_USER_SAVED_CARDS_REQUEST =
  "GET_USER_SAVED_CARDS_REQUEST";
export const GET_USER_SAVED_CARDS_RESPONSE =
  "GET_USER_SAVED_CARDS_RESPONSE";
