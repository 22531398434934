const subscription = {
  breadcrumbs: {
    home: "Home",
    pricing: "Pricing",
    payment: "Payment"
  },
  detailsPage: {
    pricingPlans: "Pricing Plans",
    bigNews: "Big News For Exporters!",
    allFeaturesFree:
      "All features now free! Explore and enjoy our full plan at no cost.",
    plan: "Plan",
    perYear: "Per Year",
    features: "Features:",
    choosePlan: "Choose Plan",
    mostPopular: "MOST POPULAR",
    currentPlan: "Current Plan",
    loginModal: {
      needToLogin: "Need To Login First!",
      confirmMsg:
        "You need to log in first to be able to complete the subscription to the plan.",
      login: "Login"
    },
    downgradePlan: {
      downgradeYourPlan: "Downgrade Your Plan !",
      confirmMsg1:
        "Please note if you are downgrading your plan, the limits of your features will be reduced, and ",
      confirmMsg2:
        "any showcases exceeding the new plan’s limit will be hidden"
    }
  },
  paymentPage: {
    voucher: {
      voucherDetails: "Voucher Details",
      requestVoucherButton: "Request Voucher",
      voucherRequestedText:
        "Your voucher request has been received. Please wait while our support team processes it. The voucher will be sent to your email at",
      shortly: "shortly.",
      voucherCode: "Voucher Code",
      voucherCodePlaceholder: "Enter the Code",
      applyButton: "Apply",
      clearButton: "Clear",
      subTotal: "Sub Total",
      voucher: "Voucher",
      total: "Total"
    },
    paymentInfo: "Payment Information",
    selectedPricing: "Selected Pricing:",
    plan: "Plan",
    price: "Price:",
    features: "Features:",
    requiredInfo: "Required Information",
    automaticRenewal: "Automatic Renewal",
    pay: "Pay",
    verifyAccount: {
      title: "Verify your business account first!",
      message:
        "You need to verify your professional account first to be able to complete the subscription to the plan.",
      verifyNow: "Verify Now"
    },
    successPayment: {
      thankYouNote: "Thank you for subscribing!",
      explorePlan:
        "Explore and utilize the features available in your plan.",
      backToPlanInfo: "Back To Plan Info"
    },
    failedPayment: {
      failedTransfer: "Failed Transfer!",
      failureMsg1: "Your bank transfer has failed.",
      failureMsg2:
        "Please try again or contact your bank for assistance.",
      tryAgain: "Try Again"
    },
    delayedSubscription: {
      delayMsg:
        "Subscription may take a few seconds to process; we'll notify you once it's complete."
    },
    downgradeShowcases: {
      successScreen: {
        successSubscription: "Subscription Successful",
        showcasesReduced:
          "Under your new plan, you're now eligible for only :showcasesNumber showcases & only :subaccountsNumber sub accounts Please select your preferred ones, as any additional showcases will be removed & extra subaccounts will be deactivated as well",
        editShowcases: "Edit Showcases"
      },
      editShowcasesModal: {
        banner: {
          msg1: "You downgraded your plan, so choose only ",
          showcases: "showcases.",
          msg2: "Note: You can ",
          only: "only ",
          msg3: "make this selection ",
          once: "once. ",
          msg4: "After that, you will only be able to delete and add items, not swap them."
        },
        selectShowcases: "Select Showcases",
        saveChanges: "Save Changes",
        noProducts:
          "There are no products to display as this showcase is still in draft mode"
      },
      saveChangesModal: {
        saveChangesCarefully: "Save Changes Carefully!",
        msg1: "By saving these changes, all other showcases will be ",
        msg2: "automatically deleted. ",
        msg3: "Please ensure that you have selected the correct showcases you wish to keep.  this cannot be undone."
      }
    },
    paymentDetails: {
      upgradePaymentDetails: "Upgrade Payment Details",
      upgradePolicy:
        "When you upgrade your plan, the cost adjusts to account for the remaining balance on your current subscription. The new plan starts immediately and it's valid for one year from the start date",
      planCost: "plan cost",
      remainingBalance: "plan remaining balance",
      total: "Total"
    },
    freeSubscription: {
      congratulations: "Congratulations!",
      receivedFreeSubscription:
        "You’ve received a 100% voucher, so there's no need to enter your card information at this step.",
      submitForFree: "Simply submit, and enjoy your free purchase!"
    },
    savedCards: {
      selectCard: "Select Card",
      cardExpiresAt: "Card expires at",
      payWithNewCard: "Pay With New Card",
      payWithNewCardDescription:
        "We’ll securely save your information for a hassle-free renewal experience. Don’t worry—your details are completely safe with us!"
    }
  },
  cannotRenewPlanMsg:
    "Your subscription is active until :date, early renewal is not possible at this time. We'll remind you closer to your renewal date"
};

export default subscription;
