export const ROUTE_PATHS = {
  root: "/",
  login: "/login",
  forgetPassword: "/forget-password",
  forgetPasswordConfirm: "/forget-password/confirm",
  resetPassword: "/reset-password",
  setNewPassword: "/set-new-password",
  register: "/register",
  verifyEmail: "/register/verify-email",
  registerSuccess: "/register/verify-email/success",
  registerFailure: "/register/verify-email/expired",
  home: "/home",
  searchPage: "/search-page",
  subCategoryDetails: "/sub-categories/:id",
  categoryDetails: "/categories/:id",
  productDetails: "/products/:id",
  exporters: "/exporters",
  exporterProfile: "/exporters/:id/profile",
  products: "/my-store/products",
  addProduct: "/my-store/products/add",
  editProduct: "/my-store/products/:id/edit",
  viewProduct: "/my-store/products/:id",
  notifications: "/notifications",
  companyProfile: "/my-store/company",
  addShowcase: "/my-store/company/showcases/add",
  editShowcase: "/my-store/company/showcases/:id/edit",
  editCompanyProfile: "/my-store/company/edit",
  businessVerification: "/exporter/business-verification",
  onlinePaymentSuccess:
    "/exporter/business-verification/online-payment/success",
  offPlatformPaymentSuccess:
    "/exporter/business-verification/offplatform-payment/success",
  myAccount: "/my-store/my-account",
  viewProfile: "/my-store/my-account/profile",
  editProfile: "/my-store/my-account/profile/edit",
  subscription: "/my-store/my-account/subscription",
  subAccounts: "/my-store/my-account/sub-accounts",
  addSubAccount: "/my-store/my-account/sub-accounts/add",
  editSubAccount: "/my-store/my-account/sub-accounts/:id/edit",
  settings: "/my-store/settings",
  changePassword: "/my-store/settings/change-password",
  pricingDetails: "/subscription/pricing-details",
  pricingDetailsPayment: "/subscription/pricing-details/payment/:id",
  subscriptionSuccess:
    "/subscription/pricing-details/payment/:id/:type/success",
  importerProductRequests: "/my-store/product-requests",
  addImporterProductRequests: "/my-store/product-requests/add",
  editImporterProductRequests: "/my-store/product-requests/:id/edit",
  viewImporterProductRequest: "/my-store/product-requests/:id",
  websiteProductRequests: "/product-requests",
  viewProductRequest: "/product-requests/:id",
  notFound: "/not-found",
  aboutUs: "/about-us",
  contactUs: "/contact-us"
};
