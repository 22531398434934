import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import contactUsCoverImg from "../../../assets/images/contactUsCoverImg.svg";
import messages from "../../../assets/locale/messages";
import {
  getImporterContactInfoRequest,
  getImporterProductRequestDetailsRequest,
  getImporterProductRequestDetailsResponse
} from "../../../store/ProductRequests/actions";
import { showHideLoader } from "../../../store/Loader/actions";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import {
  EmailFaintBlueBgIcon,
  PhoneFaintBlueBgIcon
} from "../../../utils/Icons";
import {
  EXGATES_CONTACT_EMAIL,
  EXGATES_CONTACT_PHONE
} from "../../../utils/Constants";
import { EMAIL_REGEX } from "../../../utils/Patterns";
import "./ContactUs.scss";
import { contactUsRequest } from "../../../store/Auth/actions";

const ContactUs = () => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();

  const {
    contactUs: {
      coverTitle,
      coverSubtitle,
      infoContainer,
      formContainer
    }
  } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      fullName: "",
      emailAddress: "",
      message: ""
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("fullNameRequired"),
      emailAddress: Yup.string()
        .matches(EMAIL_REGEX, "validEmailFormat")
        .required("emailRequired"),
      message: Yup.string().required("messageRequired")
    }),
    onSubmit: ({ fullName, emailAddress, message }) => {
      dispatch(showHideLoader(true));
      const messageData = {
        Email: emailAddress,
        Body: message
      };

      dispatch(
        contactUsRequest({
          data: messageData
        })
      );
      dispatch(showHideLoader(false));
    }
  });

  const renderLabelValue = (label, value, icon) => (
    <div className="d-flex align-items-center text-break">
      <div>{icon}</div>
      <div className="ms-3">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>

        <p className="text-color fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  return (
    <div className="contact-us-container w-100">
      <div
        className={`contact-us-cover-image d-flex flex align-items-end  w-100 size-cover mb-4`}
        style={{
          backgroundImage: `url(${contactUsCoverImg})`
        }}>
        <Box className="text-start d-flex flex-column justify-content-end pb-5 category-info w-100 ">
          <div className="container-xxl px-xl-4 px-md-4 px-3">
            <Typography
              className="text-white fweight-700 mb-3 ps-lg-2 ps-0"
              sx={{
                fontSize: {
                  sx: "1.3rem",
                  sm: "2.3rem"
                }
              }}>
              {coverTitle}
            </Typography>
            <p className="mb-0 text-white fweight-500 ps-lg-2 ps-0">
              {coverSubtitle}
            </p>
          </div>
        </Box>
      </div>
      <div className="container-xxl d-md-flex justify-content-between py-md-5 my-md-5 py-2 my-2 px-md-4 px-3">
        <div className="contact-us-info-container col-md-6 col-12 mb-md-0 mb-4 ps-lg-2">
          <Typography className="fweight-700 fsize-44 mb-4 title-color pe-md-4">
            {infoContainer.title}
          </Typography>
          <p className="mb-0 text-color fweight-400 fsize-16">
            {infoContainer.subtitle}
          </p>
          <div className="d-lg-flex w-100 align-items-center mt-xl-5 mt-lg-4 mt-4">
            <div className="me-lg-5 mb-lg-0 mb-4">
              {renderLabelValue(
                infoContainer.labels.mobileNumber,
                EXGATES_CONTACT_PHONE,
                <PhoneFaintBlueBgIcon />
              )}
            </div>
            <div className="ms-lg-4">
              {renderLabelValue(
                infoContainer.labels.emailAddress,
                EXGATES_CONTACT_EMAIL,
                <EmailFaintBlueBgIcon />
              )}
            </div>
          </div>
        </div>
        <div className="contact-us-form-container col-md-5 col-12 light-blue-border border-radius-10 p-md-5 p-3 py-md-4">
          <Typography className="fweight-600 fsize-22 mb-3 title-color">
            {formContainer.title}
          </Typography>
          <p className="mb-0 text-color fweight-400 fsize-15 text-light-grey">
            {formContainer.subtitle}
          </p>
          <form
            className="contact-us-form mt-5"
            onSubmit={handleSubmit}
            noValidate>
            <div>
              <div>
                <Input
                  id="fullName"
                  label={formContainer.inputLabels.fullName}
                  name="fullName"
                  type="text"
                  placeholder={formContainer.placeholders.fullName}
                  required
                  onChange={(value) => {
                    if (!value || value?.trim()) {
                      const modifiedValue = value.replace(
                        /\s\s+/g,
                        " "
                      );
                      setFieldTouched("fullName");
                      setFieldValue("fullName", modifiedValue);
                    }
                  }}
                  value={values.fullName}
                  inputClass="mb-1"
                  inputWrapperClass="mb-4"
                  isInputHasErr={
                    !!(touched.fullName && errors.fullName)
                  }
                  errMsg={errors.fullName}
                />
              </div>
              <div>
                <Input
                  id="emailAddress"
                  label={formContainer.inputLabels.emailAddress}
                  name="emailAddress"
                  type="email"
                  placeholder={
                    formContainer.placeholders.emailAddress
                  }
                  required
                  onChange={(value) => {
                    if (!value || value?.trim()) {
                      const modifiedValue = value.replace(
                        /\s\s+/g,
                        " "
                      );
                      setFieldTouched("emailAddress");
                      setFieldValue("emailAddress", modifiedValue);
                    }
                  }}
                  value={values.emailAddress}
                  inputClass="mb-1"
                  inputWrapperClass="mb-4"
                  isInputHasErr={
                    !!(touched.emailAddress && errors.emailAddress)
                  }
                  errMsg={errors.emailAddress}
                />
              </div>
              <div>
                <Input
                  id="message"
                  label={formContainer.inputLabels.message}
                  name="message"
                  type="text"
                  placeholder={formContainer.placeholders.message}
                  required
                  rows={7}
                  multiline
                  onChange={(value) => {
                    if (value?.trim() || !value) {
                      const modifiedValue = value.replace(
                        /\s\s+/g,
                        " "
                      );
                      if (value[0] !== " ") {
                        setFieldTouched("message");
                        setFieldValue("message", modifiedValue);
                      }
                    }
                  }}
                  value={values.message}
                  inputClass="mb-1"
                  inputWrapperClass="mb-4"
                  isInputHasErr={
                    !!(touched.message && errors.message)
                  }
                  errMsg={errors.message}
                />
              </div>
            </div>
            <div
              className={`form-button-container bg-white d-flex justify-content-start w-100 mt-4 p-4 bg-white border-radius-8 ps-0 w-100`}>
              <Button
                disabled={!dirty || !isValid}
                type="button"
                className={"w-auto"}
                labelClass={`py-1 ps-lg-5 pe-lg-5`}
                label={formContainer.sendMessageBtn}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
