export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const SHOW_CHANGE_PASSWORD_SUCCESS_MODAL =
  "SHOW_CHANGE_PASSWORD_SUCCESS_MODAL";
export const DELETE_EXPORTER_ACCOUNT_REQUEST =
  "DELETE_EXPORTER_ACCOUNT_REQUEST";
export const DELETE_IMPORTER_ACCOUNT_REQUEST =
  "DELETE_IMPORTER_ACCOUNT_REQUEST";
export const GET_SUBSCRIPTION_END_DATE_REQUEST =
  "GET_SUBSCRIPTION_END_DATE_REQUEST";
export const GET_SUBSCRIPTION_END_DATE_RESPONSE =
  "GET_SUBSCRIPTION_END_DATE_RESPONSE";
export const DEACTIVATE_EXPORTER_ACCOUNT =
  "DEACTIVATE_EXPORTER_ACCOUNT";
export const DEACTIVATE_IMPORTER_ACCOUNT =
  "DEACTIVATE_IMPORTER_ACCOUNT";
export const CHANGE_CURRENCY_REQUEST = "CHANGE_CURRENCY_REQUEST";
export const DISABLE_ENABLE_PRODUCT_REQUEST_NOTIFICATION =
  "DISABLE_ENABLE_PRODUCT_REQUEST_NOTIFICATION";
