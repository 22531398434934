import PropTypes from "prop-types";

export const ChipInfoIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    {...props}>
    <g clipPath="url(#clip0_4626_316)">
      <path
        fill={color || "#DEA71B"}
        fillRule="evenodd"
        d="M0 12.5C0 5.873 5.373.5 12 .5s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12zm2.036 0c0 5.502 4.462 9.964 9.964 9.964 5.502 0 9.964-4.462 9.964-9.964 0-5.502-4.462-9.964-9.964-9.964-5.502 0-9.964 4.462-9.964 9.964zm9.054-3.805a1.286 1.286 0 111.819-1.818 1.286 1.286 0 01-1.819 1.818zm.267 2.09h1.285c.118 0 .215.097.215.215v7.286a.215.215 0 01-.215.214h-1.285a.215.215 0 01-.215-.214V11c0-.118.097-.214.215-.214z"
        clipRule="evenodd"></path>
    </g>
    <defs>
      <clipPath id="clip0_4626_316">
        <path
          fill="#fff"
          d="M0 0H24V24H0z"
          transform="translate(0 .5)"></path>
      </clipPath>
    </defs>
  </svg>
);

ChipInfoIcon.propTypes = {
  color: PropTypes.string
};
