import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop } from "@mui/material";

import {
  hideLoaderOverlay,
  showHideLoaderText
} from "../../store/Loader/actions";
import { checkForMultiline } from "../../utils/Helpers";
import JsonViewer from "../JsonViewer";
import jsonLoader from "../../assets/jsonFiles/ExGates-loader.json";

const Loader = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.showLoader);
  const showText = useSelector((state) => state.loader.showText);
  const loadingText = useSelector(
    (state) => state.loader.loadingText
  );
  const hideOverlay = useSelector(
    (state) => state.loader.hideLoaderOverlay
  );

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        showHideLoaderText({
          loadingText: "",
          showText: false
        })
      );
      dispatch(hideLoaderOverlay(false));
    }
  }, [isLoading]);

  return (
    <div className="loader-container">
      {!hideOverlay && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 99999
          }}
          open={isLoading}>
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center col-md-12 col-8 text-break ">
            <JsonViewer
              play
              loop
              animationData={jsonLoader}
              style={{ width: 75, height: 75 }}
            />
            {showText && (
              <div className="text-center">
                {checkForMultiline(loadingText, "", true)}
              </div>
            )}
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default Loader;
