import store from "../../store";
import { loginResponse } from "../../store/Auth/actions";
import {
  hideLoaderOverlay,
  showHideLoader
} from "../../store/Loader/actions";
import { clearUserData } from "../../utils/Auth";
import { getCookieValue, showErrorMsg } from "../../utils/Helpers";
import messages from "../../assets/locale/messages";

export const isHandlerEnabled = (config = {}) => {
  return Object.hasOwn(config, "handlerEnabled") &&
    !config.handlerEnabled
    ? false
    : true;
};

// This is used to handle remove loader only if all pending requests are resolved
let numberOfAjaxCAllPending = 0;

export const requestHandler = (request) => {
  numberOfAjaxCAllPending++;
  if (isHandlerEnabled(request)) {
    store.dispatch(showHideLoader(true));
    if (request.url === "/api/Search/Search") {
      store.dispatch(hideLoaderOverlay(true));
    }
    if (
      request.url === "/api/Notification/GetNotifications" &&
      request.params.reuqestType === "initial"
    ) {
      store.dispatch(hideLoaderOverlay(true));
    }
    /*
     * In case of loader not needed in searching data tables
     * !request.params?.search && store.dispatch(showHideLoader(true));
     */

    const locale = localStorage.getItem("lang");
    request.headers.locale = locale;
    // for google analytics
    const googleAnalyticsCookieValue = getCookieValue("_ga");
    if (googleAnalyticsCookieValue) {
      request.headers.googleAnalyticsId = googleAnalyticsCookieValue;
    }

    // for google analytics

    const userData =
      JSON.parse(localStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    const token = userData?.Token;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }
  if (navigator.onLine) {
    return request;
  } else {
    const lang = localStorage.getItem("lang");
    showErrorMsg(messages[lang].shared.internetConnection);
    store.dispatch(showHideLoader(false));
  }
};

export const successHandler = (response) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(response)) {
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(showHideLoader(false));
    }
  }
  return response;
};

export const errorHandler = (error) => {
  if (navigator.onLine) {
    const lang = localStorage.getItem("lang");
    numberOfAjaxCAllPending--;
    if (isHandlerEnabled(error.config)) {
      if (numberOfAjaxCAllPending === 0) {
        store.dispatch(showHideLoader(false));
      }
    }

    if (error?.response?.status === 401) {
      store.dispatch(loginResponse({}));
      clearUserData();
      /*
       * Yield clearFirebaseToken();
       * yield generateFirebaseToken();
       */
    } else if (
      error?.message === "Network Error" &&
      !navigator.onLine
    ) {
      showErrorMsg(messages[lang].shared.internetConnection);
    } else if (!error?.response?.data?.Message) {
      showErrorMsg(messages[lang].shared.generalErrMsg);
    }
    return Promise.reject({ ...error });
  }
};
