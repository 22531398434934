const registeration = {
  createAccount: "Create Account",
  steps: {
    contactInfo: "Contact Info",
    companyInfo: "Company Info"
  },
  userTypes: {
    importer: "Importer",
    exporter: "Exporter",
    both: "Both"
  },
  alreadyMember: "Already a member?",
  signIn: "Sign in",
  contactInfo: {
    labels: {
      fullName: "Full Name",
      email: "Email Address",
      password: "Password"
    },
    placeholders: {
      fullName: "Enter your name",
      email: "Enter your email",
      password: "Enter your password"
    }
  },
  companyInfo: {
    labels: {
      companyLogo: "Company Logo",
      companyName: "Company Name",
      country: "Country",
      businessType: "Business Type",
      categories: "Categories",
      yearEstablished: "Year Established"
    },
    placeholders: {
      companyLogo: "Upload Logo",
      companyName: "Enter your company name",
      country: "Select the country",
      businessType: "Select the business type",
      categories: "Select the categories",
      yearEstablished: "Enter the Establishing Year"
    },
    businessTypesOptions: {
      trade: "Trade",
      factor: "Factory",
      both: "Both"
    },
    other: "Other"
  },
  verifyEmail: {
    verifyYourEmail: "Verify Your Email",
    message1: "Your personal account setup is almost complete",
    message2: "Please follow the instructions provided to this email",
    message3: "to verify your account. Thank you for joining us!",
    notGetEmail: "If you didn’t get any mails ",
    resend: "Resend"
  },
  successPage: {
    congratulations: "Congratulations!",
    message1:
      "You have successfully created your account as an exporter",
    message2: "Please verify your business as an exporter",
    message3:
      "You have successfully created your account as an Importer",
    verifyBusiness: "Verify your business",
    skip: "Skip this for now",
    goHome: "Go To Home"
  },
  failurePage: {
    linkExpired: "The link has expired!",
    message1:
      "The link we sent to you has expired. Please request a new link to verify your email",
    resend: "Resend Link"
  }
};

export default registeration;
