import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import DatePicker from "../../DatePicker";
import "./DateRangeFilter.scss";

const DateRangeFilter = ({
  name,
  label,
  onRequestFiltering,
  clearFilters,
  collabsible,
  selectedDateFrom,
  selectedDateTo,
  addBottomMargin
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  const [fromValue, setFromValue] = useState(null);
  const [toValue, setToValue] = useState(null);
  const [key, setKey] = useState(0);

  const handleRequestFiltering = () => {
    onRequestFiltering({ from: fromValue, to: toValue });
  };

  useEffect(() => {
    if (clearFilters) {
      setFromValue(null);
      setToValue(null);
      setKey((prevKey) => prevKey + 1); // Force re-render by changing key
    }
  }, [clearFilters]);

  useEffect(() => {
    handleRequestFiltering();
  }, [fromValue, toValue]);

  useEffect(() => {
    if (selectedDateFrom) {
      setFromValue(selectedDateFrom);
    }
    if (selectedDateTo) {
      setToValue(selectedDateTo);
    }
  }, [selectedDateFrom, selectedDateTo]);

  const renderFilterContent = () => (
    <div className={`range-inputs row ${collabsible && "mt-2"}`}>
      <div className=" col-6">
        <DatePicker
          key={`from-${key}`}
          onChange={(value) => {
            setFromValue(value);
          }}
          wrapperClass="p-0"
          inputClass="w-100 p-0"
          placeholder={shared.from}
          margin="normal"
          value={fromValue || ""}
          name={`start-${name}-From`}
          id={`start-${name}-From`}
          format="DD/MM/YYYY"
          disableFuture
          maxDate={toValue}
          addBottomMargin={addBottomMargin}
        />
      </div>
      <div className="col-6 ">
        <DatePicker
          key={`to-${key}`}
          onChange={(value) => {
            setToValue(value);
          }}
          errMsg={"invalidToDate"}
          isInputHasErr={
            !!(fromValue && toValue && fromValue > toValue)
          }
          wrapperClass=""
          inputClass="w-100 "
          placeholder={shared.to}
          margin="normal"
          value={toValue || ""}
          name={`start-${name}-To`}
          id={`start-${name}-To`}
          format="DD/MM/YYYY"
          disableFuture
          minDate={fromValue}
          addBottomMargin={addBottomMargin}
        />
      </div>
    </div>
  );

  return (
    <div className="date-range-filter w-100">
      {collabsible ? (
        <div className="collabsible-filter">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-${name}-content`}
              id={`panel-${name}-header`}>
              <h6 className="fweight-600 fsize-16 mb-0 title-color">
                {label}
              </h6>
            </AccordionSummary>
            <AccordionDetails>
              {renderFilterContent()}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className="non-collapsible-filter">
          <h6 className="fweight-600 fsize-16 mb-3 title-color">
            {label}
          </h6>
          {renderFilterContent()}
        </div>
      )}
    </div>
  );
};

export default DateRangeFilter;

DateRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onRequestFiltering: PropTypes.func.isRequired,
  clearFilters: PropTypes.bool,
  collabsible: PropTypes.bool,
  selectedDateFrom: PropTypes.string,
  selectedDateTo: PropTypes.string,
  addBottomMargin: PropTypes.bool
};
