import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { TableCell, TableRow, Typography } from "@mui/material";

import noSubAccountsImage from "../../../assets/images/vectors/placeholders/noSubAccountsPlaceholderImage.svg";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";
import Filters from "../../../components/Filters";
import ListFilter from "../../../components/Filters/ListFilter";
import SearchInput from "../../../components/SearchInput";
import Switch from "../../../components/Switch";
import Table from "../../../components/Table";
import TruncateText from "../../../components/TruncateText";
import Pagination from "../../../components/Pagination";
import Alert from "../../../components/Alert";
import DeactivateSubAccount from "./DeactivateSubAccount";
import ActivateSubAccount from "./ActivateSubAccount";
import {
  getSubAccountsCountRequest,
  getSubAccountsListRequest
} from "../../../store/SubAccounts/actions";
import { ListEditIcon } from "../../../utils/Icons/ListEdit";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { ChipInfoIcon } from "../../../utils/Icons";
import {
  BANNER_COLORS,
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  SUB_ACCOUNT_ROLE_ID,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import VerificationsNotifications from "../../Exporters/Verification/VerificationsNotifications";
import "./SubAccounts.scss";

const SubAccounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const subAccountsList = useSelector(
    (state) => state.subAccounts.subAccountsList
  );
  const meta = useSelector(
    (state) => state.subAccounts.subAccountsMeta
  );
  const activeSubAccountsCount = useSelector(
    (state) => state.subAccounts.activeSubAccountsCount
  );
  const subAccountsNeedEdit = useSelector(
    (state) => state.subAccounts.isNeedEdit
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const isNotVerified = [
    USER_STATUS_IDS.registered,
    USER_STATUS_IDS.verified,
    USER_STATUS_IDS.pendingBusinessVerified
  ].includes(userStatusId);

  const [searchValue, setSearchValue] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [roleFilterValue, setRoleFilterValue] = useState([]);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [paramsObject, setParamsObject] = useState({
    role_ids: [],
    isActive: null
  });
  const [
    deactivateSubAccountModalOpen,
    setDeactivateSubAccountModalOpen
  ] = useState(false);
  const [
    activateSubAccountModalOpen,
    setActivateSubAccountModalOpen
  ] = useState(false);
  const [subAccountDetails, setSubAccountDetails] = useState({});
  const [allowedSubaccountsCount, setAllowedSubaccountsCount] =
    useState(0);

  useEffect(() => {
    dispatch(getSubAccountsCountRequest());
  }, []);

  useEffect(() => {
    setAllowedSubaccountsCount(
      +userPlan?.Features?.find(
        (feature) =>
          feature.Id ===
          SUBSCRIPTION_PLAN_FEATURES_IDS.subaccountsNumber
      )?.Value
    );
  }, [userPlan?.Features]);

  const {
    shared,
    subAccounts: {
      subAccountsList: {
        subAccounts,
        addMoreBtn,
        searchPlaceholder,
        noSubAccountsPlaceholder,
        noSearchFilterResults,
        list: { name, email, role, edit, activate },
        banners: {
          subscribe,
          renewSubscription,
          maxAccounts,
          downgradePlan
        }
      }
    }
  } = messages[lang];

  const requestSubAccountsList = () => {
    dispatch(
      getSubAccountsListRequest({
        params: {
          page,
          search_value: searchValue,
          ...paramsObject
        }
      })
    );
  };

  useEffect(() => {
    if (isApplyFilters) {
      setParamsObject({
        role_ids: roleFilterValue,
        isActive:
          statusFilterValue?.length === 2 ||
          statusFilterValue?.length === 0
            ? null
            : statusFilterValue?.includes(1)
      });
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    if (clearFilters) {
      setRoleFilterValue([]);
      setStatusFilterValue([]);
      setParamsObject({
        role_ids: [],
        isActive: null
      });
      setClearFilters(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    requestSubAccountsList();
  }, [page, searchValue]);

  useEffect(() => {
    page > 1 ? setPage(1) : requestSubAccountsList();
  }, [paramsObject]);

  const handleDeactivateSubAccount = (id, name, status) => {
    setSubAccountDetails({
      id,
      name,
      status
    });
    setDeactivateSubAccountModalOpen(true);
  };

  const handleActivateSubAccount = (id, name, status) => {
    setSubAccountDetails({
      id,
      name,
      status
    });
    setActivateSubAccountModalOpen(true);
  };
  const handleCloseModal = () => {
    setDeactivateSubAccountModalOpen(false);
    setActivateSubAccountModalOpen(false);
  };

  const renderSubAccountsTableRow = ({
    Id,
    Name,
    Email,
    RoleId,
    RoleName,
    IsActive
  }) => {
    return (
      <TableRow key={`subaccount-${Id}`}>
        <TableCell>
          <TruncateText text={Name || "__"} />
        </TableCell>
        <TableCell>{Email || "__"}</TableCell>
        <TableCell>{RoleName || "__"}</TableCell>
        <TableCell>
          <ListEditIcon
            className="pointer list-action-icon"
            onClick={() =>
              navigate(ROUTE_PATHS.editSubAccount.replace(":id", Id))
            }
          />
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-start">
            <Switch
              checked={IsActive}
              id={Id}
              handleChange={(isChecked) => {
                if (isChecked) {
                  handleActivateSubAccount(Id, Name, IsActive);
                } else {
                  handleDeactivateSubAccount(Id, Name, IsActive);
                }
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const SUB_ACCOUNTS_STATUS_FILTER = [
    { Id: 1, Name: "Active" },
    { Id: 2, Name: "Inactive" }
  ];
  const SUB_ACCOUNTS_ROLE_FILTER = [
    { Id: SUB_ACCOUNT_ROLE_ID.manager, Name: "Manager" },
    { Id: SUB_ACCOUNT_ROLE_ID.agent, Name: "Agent" }
  ];

  const renderFilterContent = () => {
    return (
      <div className="filter-content-container h-100">
        <div className="border-bottom py-3 px-md-5 px-3">
          <ListFilter
            list={SUB_ACCOUNTS_ROLE_FILTER}
            label={shared.filtersDrawerLabels.roles}
            name="Roles"
            selectedOptionsIds={roleFilterValue}
            setSelectedOptionsIds={setRoleFilterValue}
            showSearchBar={true}
            clearFilters={clearFilters}
            collabsible
            isApplyFilters={isApplyFilters}
            searchPlaceholder={
              shared.filtersDrawerLabels.rolesSearchBar
            }
            filterModalOpen={filterModalOpen}
          />
        </div>
        <div className="border-bottom py-3 px-md-5 px-3">
          <ListFilter
            list={SUB_ACCOUNTS_STATUS_FILTER}
            label={shared.filtersDrawerLabels.status}
            name="Status"
            selectedOptionsIds={statusFilterValue}
            setSelectedOptionsIds={setStatusFilterValue}
            showSearchBar={false}
            clearFilters={clearFilters}
            collabsible
            isApplyFilters={isApplyFilters}
            filterModalOpen={filterModalOpen}
          />
        </div>
      </div>
    );
  };

  const renderSubscribeBanner = () => (
    <div>
      <p className="mb-0 d-flex align-items-center flex-wrap fweight-500">
        <span className="me-1 fsize-16">{subscribe.msg}</span>
        <Link to={ROUTE_PATHS.pricingDetails}>
          <span className="highlighted-text pointer text-underline fsize-14 fweight-600">
            {subscribe.subscribeNow}
          </span>
        </Link>
      </p>
    </div>
  );

  const renderExpiredSubscribeBanner = () => (
    <div>
      <p className="mb-0 d-flex align-items-center flex-wrap fweight-500">
        <span className="me-1 fsize-16">{renewSubscription.msg}</span>
        <Link to={ROUTE_PATHS.pricingDetails}>
          <span className="highlighted-text pointer text-underline fsize-14 fweight-600">
            {renewSubscription.renewNow}
          </span>
        </Link>
      </p>
    </div>
  );

  const renderMaxAccountBanner = () => (
    <div>
      <p className="mb-0 d-flex align-items-center flex-wrap fweight-500">
        <span className="me-1 fsize-16 text-color">
          {maxAccounts.withThe}
          <span className="fweight-600">{` ${userPlan?.Name} ${maxAccounts.plan} `}</span>
          {maxAccounts.allowedToAdd}
          <span className="fweight-600">{` ${allowedSubaccountsCount} ${maxAccounts.subAccounts} `}</span>
          {maxAccounts.needToAddMore}
        </span>
        <span
          className="highlighted-text pointer text-underline fsize-14 fweight-600"
          onClick={() =>
            navigate(ROUTE_PATHS.pricingDetails, {
              state: {
                isUpgrade: true
              }
            })
          }>
          {` ${maxAccounts.upgradePlan}`}
        </span>
        <span className="me-1 fsize-16 text-color">
          {maxAccounts.now}
        </span>
      </p>
    </div>
  );

  const renderSubaccountsNeedEditBanner = () => (
    <div>
      <p className="mb-1 fweight-500 fsize-16 text-color">
        {downgradePlan.msg1}
        <span className="fweight-600">
          {downgradePlan.downgraded}
        </span>
        {downgradePlan.msg2}
        <span className="fweight-600">{` ${allowedSubaccountsCount} ${downgradePlan.subaccounts} `}</span>
        {downgradePlan.msg3}
      </p>
    </div>
  );

  return (
    <div className="sub-accounts-list">
      {isNotVerified && (
        <div className="mb-3">
          <VerificationsNotifications />
        </div>
      )}
      <div className="page-title pb-4 d-flex  justify-content-between align-items-center">
        <div className="title-count-container d-flex justify-content-center align-items-center">
          <div>
            <Typography variant="h5" className="title-color">
              {subAccounts}
            </Typography>
          </div>
        </div>
        <div className="title-buttons-container d-flex justify-content-center gap-3 align-items-center">
          <Filters
            handleClearFilters={setClearFilters}
            handleApplyFilters={setIsApplyFilters}
            FilterContent={renderFilterContent()}
            setIsModalOpen={(isOpen) => setFilterModalOpen(isOpen)}
          />
          <Button
            onClick={() => navigate(ROUTE_PATHS.addSubAccount)}
            labelClass="text-white"
            label={
              <span className="d-flex align-items-center">
                <Add fontSize="medium" />
                <span className="ps-1 ">{addMoreBtn}</span>
              </span>
            }
            disabled={
              userStatusId !== USER_STATUS_IDS.subscribed ||
              activeSubAccountsCount === allowedSubaccountsCount
            }
          />
        </div>
      </div>
      <div className="banners-section mb-3">
        {![
          USER_STATUS_IDS.subscribed,
          USER_STATUS_IDS.subscriptionExpired
        ].includes(userStatusId) &&
          !isNotVerified && (
            <Alert
              type="warning"
              content={renderSubscribeBanner()}
              icon={
                <ChipInfoIcon
                  color={BANNER_COLORS.warning}
                  style={{ width: "1.5rem" }}
                  className="ChipInfoIcon"
                />
              }
            />
          )}
        {userStatusId === USER_STATUS_IDS.subscriptionExpired && (
          <Alert
            type="warning"
            content={renderExpiredSubscribeBanner()}
            icon={
              <ChipInfoIcon
                color={BANNER_COLORS.warning}
                style={{ width: "1.5rem" }}
                className="ChipInfoIcon"
              />
            }
          />
        )}
        {userStatusId === USER_STATUS_IDS.subscribed &&
          activeSubAccountsCount === allowedSubaccountsCount &&
          !subAccountsNeedEdit && (
            <Alert
              type="info"
              content={renderMaxAccountBanner()}
              icon={
                <ChipInfoIcon
                  color={BANNER_COLORS.info}
                  style={{ width: "1.5rem" }}
                  className="ChipInfoIcon"
                />
              }
            />
          )}
        {subAccountsNeedEdit && (
          <Alert
            type="info"
            content={renderSubaccountsNeedEditBanner()}
            icon={
              <ChipInfoIcon
                color={BANNER_COLORS.info}
                style={{ width: "1.5rem" }}
                className="ChipInfoIcon"
              />
            }
          />
        )}
      </div>

      <div className="sub-accounts-table-container bg-white d-flex flex-column w-100 p-4 pb-0 px-0 bg-white border-radius-8">
        <div className="table-header d-flex px-4 align-items-center w-100 mb-4">
          <SearchInput
            name="SubAccountsSearch"
            id="SubAccountsSearch"
            value={searchValue}
            inputClass="border-radius-8"
            inputWrapperClass="search-input-wrapper search-input-wrapper-width large-placeholder-search"
            placeholder={searchPlaceholder}
            onChange={(value) => {
              setSearchValue(value);
            }}
          />
        </div>
        <div className="table-content">
          <div>
            {subAccountsList?.length ? (
              <Table
                className={"px-0"}
                headlines={[name, email, role, edit, activate]}
                rows={
                  !isLoading &&
                  subAccountsList.map(renderSubAccountsTableRow)
                }
              />
            ) : (
              <div className="border-top">
                {!isLoading && (
                  <EmptyState
                    subTitle={
                      (!!searchValue ||
                        paramsObject?.isActive !== null ||
                        !!paramsObject?.role_ids?.length) &&
                      !subAccountsList.length
                        ? noSearchFilterResults
                        : noSubAccountsPlaceholder
                    }
                    showActionButton={
                      userStatusId === USER_STATUS_IDS.subscribed &&
                      !searchValue &&
                      paramsObject?.isActive === null &&
                      !paramsObject?.role_ids?.length &&
                      subAccountsList.length === 0
                    }
                    buttonLabel={
                      <span className="d-flex align-items-center">
                        <Add fontSize="medium" />
                        <span className="ps-1 ">{addMoreBtn}</span>
                      </span>
                    }
                    buttonClassName="empty-state-create-button border-radius-8"
                    imgSrc={noSubAccountsImage}
                    onButtonClick={() =>
                      navigate(ROUTE_PATHS.addSubAccount)
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Pagination-container d-flex justify-content-center py-3 mt-2">
        {!isLoading && subAccountsList.length > 0 && (
          <Pagination
            count={meta?.pages}
            page={page || meta.currentPage}
            handleChange={(page) => {
              setPage(page);
            }}
            defaultPage={1}
          />
        )}
      </div>
      {deactivateSubAccountModalOpen && (
        <DeactivateSubAccount
          open={deactivateSubAccountModalOpen}
          closeModal={handleCloseModal}
          subAccountInfo={subAccountDetails}
          params={{
            page,
            search_value: searchValue,
            ...paramsObject
          }}
        />
      )}
      {activateSubAccountModalOpen && (
        <ActivateSubAccount
          open={activateSubAccountModalOpen}
          closeModal={handleCloseModal}
          subAccountInfo={subAccountDetails}
          params={{
            page,
            search_value: searchValue,
            ...paramsObject
          }}
        />
      )}
    </div>
  );
};

export default SubAccounts;
