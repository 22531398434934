import {
  axiosInstanceAdmin,
  axiosInstanceExporters,
  axiosInstanceImporters
} from ".";
import { USER_TYPES_IDS } from "../../utils/Constants";

const changePassword = async ({ data }) =>
  await axiosInstanceAdmin.put(
    "/api/Authenticate/ChangePassword",
    data
  );

const deactivateExporterAccount = async () =>
  await axiosInstanceExporters.get("/api/User/DeactivateUserProfile");

const deleteExporterAccount = async () =>
  await axiosInstanceExporters.get("/api/User/DeleteUserProfile");

const getSubscriptionEndDate = async ({ userId }) =>
  await axiosInstanceExporters.get(
    `/api/User/GetSubscriptionPlanEndDate/${userId}`
  );

const deleteImporterAccount = async ({ params }) =>
  await axiosInstanceImporters.get("/api/User/DeleteUserProfile", {
    params,
    paramsSerializer: { indexes: null }
  });

const deactivateImporterAccount = async ({ params }) =>
  await axiosInstanceImporters.get(
    `/api/User/DeactivateUserProfile`,
    {
      params
    }
  );

const changeCurrency = async ({ params, userType }) =>
  await (userType === USER_TYPES_IDS.importer
    ? axiosInstanceImporters.put(
        "/api/Currency/ChangeCurrency",
        {},
        { params }
      )
    : axiosInstanceExporters.put(
        "/api/Currency/ChangeCurrency",
        {},
        { params }
      ));

const disableEnableProductRequestNotification = async ({ params }) =>
  await axiosInstanceExporters.put(
    "/api/User/DisableEnableProductRequestNotification",
    null,
    { params }
  );

export {
  changePassword,
  deleteExporterAccount,
  getSubscriptionEndDate,
  deleteImporterAccount,
  deactivateExporterAccount,
  deactivateImporterAccount,
  changeCurrency,
  disableEnableProductRequestNotification
};
