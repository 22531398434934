export const SnackbarSuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    {...props}>
    <g clipPath="url(#clip0_5629_4756)">
      <path
        fill="#22B573"
        fillRule="evenodd"
        d="M0 12.972c0-6.626 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12zm2.036 0c0 5.502 4.462 9.965 9.964 9.965 5.502 0 9.964-4.463 9.964-9.965 0-5.501-4.462-9.964-9.964-9.964-5.502 0-9.964 4.463-9.964 9.964zm13.717-4.26h1.256c.174 0 .276.199.171.34l-5.64 7.822a.852.852 0 01-1.386 0l-3.337-4.628a.215.215 0 01.174-.34h1.256c.276 0 .533.133.694.356l1.907 2.646 4.21-5.84a.853.853 0 01.695-.355z"
        clipRule="evenodd"></path>
    </g>
    <defs>
      <clipPath id="clip0_5629_4756">
        <path
          fill="#fff"
          d="M0 0H24V24H0z"
          transform="translate(0 .972)"></path>
      </clipPath>
    </defs>
  </svg>
);
