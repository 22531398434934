import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { PublishModalIcon } from "../../../../utils/Icons";

const EditCompanyProfileModal = ({
  open,
  handleClose,
  handleConfirm
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared,
    company: {
      editProfileModal: { title, content }
    }
  } = messages[lang];

  const EditProfileContent = () => {
    return (
      <div className="edit-profile-container container d-flex flex-column justify-content-center align-items-center">
        <PublishModalIcon />
        <div className="edit-profile-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {content}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"edit-profile-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirm();
        handleClose();
      }}
      handleCancel={handleClose}
      handleClose={handleClose}
      isContentText={false}
      content={EditProfileContent()}
      hasActions={true}
      confirmBtnTxt={shared.confirm}
      cancelBtnTxt={shared.cancel}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default EditCompanyProfileModal;

EditCompanyProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func
};
