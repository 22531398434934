import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";
import messages from "../../assets/locale/messages";
import noImgPlaceholder from "../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import { getNotificationsRequest } from "../../store/Notifications/actions";
import { formatDate, showSuccessMsg } from "../../utils/Helpers";
import {
  handleNotificationClick,
  markAllNotificationsAsRead
} from "../../notifications/helpers";
import defaultNotificationIcon from "../../assets/images/vectors/info-icon.svg";
import { USER_TYPES_IDS } from "../../utils/Constants";
import { clickActions } from "../../notifications/constants";
import { CopyIcon } from "../../utils/Icons";

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.loader);
  const lang = useSelector((state) => state.locale.lang);
  const { notifications } = messages[lang];
  const notificationsList = useSelector(
    (state) => state.notifications.notificationsList
  );
  const notificationsMeta = useSelector(
    (state) => state.notifications.notificationsMeta
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        params: {
          page,
          userTypeId:
            userType === USER_TYPES_IDS.both
              ? userCurrentRole
              : userType
        }
      })
    );
  }, [page]);

  const onClickNotification = ({
    Id,
    ActionId,
    ClickActionName,
    IsRead
  }) => {
    handleNotificationClick({
      id: Id,
      clickAction: ClickActionName,
      actionId: ActionId,
      isRead: IsRead,
      navigate
    });
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead({ request: "complete" });
  };

  return (
    <div className="notifications-page-container">
      <div className="page-title pb-4 d-md-flex justify-content-between align-items-center">
        <h4 className="mb-0 title-color text-md-start text-center mb-md-0 mb-3 fsize-22 fweight-700">
          {notifications.notifications}
        </h4>
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="light-faint-blue-bg px-4 py-3 border-radius-8 box-shadow-hover pointer"
            onClick={handleMarkAllAsRead}
            style={{ width: "fit-content" }}>
            <span className="text-primary  fweight-500">
              {notifications.markAllAsRead}
            </span>
          </div>
        </div>
      </div>
      <div className="notifications-container bg-white border-radius-8 py-3">
        {notificationsList?.length
          ? notificationsList.map(
              (
                {
                  Id,
                  Title,
                  Body,
                  ClickActionName,
                  ActionId,
                  CreatedDate,
                  IsRead,
                  Image
                },
                i
              ) => (
                <div
                  className={`notification-item pointer px-4 ${!IsRead && "faint-grey-bg"}`}
                  key={`list-notification-item-${i}`}
                  onClick={() =>
                    onClickNotification({
                      Id,
                      ActionId,
                      ClickActionName,
                      IsRead
                    })
                  }>
                  <div
                    className={`d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row py-3 ${i < notificationsList.length - 1 && "light-border-bottom"}`}>
                    <Box
                      className="content d-flex align-items-center justify-content-start"
                      sx={{
                        maxWidth: {
                          xs: "100%",
                          sm: "80%",
                          lg: "85%"
                        }
                      }}>
                      <div className="me-2">
                        <img
                          src={
                            Image
                              ? `data:image/svg+xml;utf8,${encodeURIComponent(Image)}`
                              : defaultNotificationIcon
                          }
                          style={{ width: "2.8rem" }}
                        />
                      </div>
                      <div className="text-copy-btn-container d-xl-flex gap-xl-4 gap-3 align-items-center">
                        <div className="text">
                          <p className="mb-0 fsize-15 fweight-600 text-color">
                            {Title}
                          </p>
                          <div
                            className={
                              ClickActionName ===
                                clickActions.voucherCodeSent &&
                              "d-flex align-items-center gap-3"
                            }>
                            <p className="mb-0 fsize-12 text-light-grey">
                              {Body}
                            </p>
                          </div>
                        </div>
                        {ClickActionName ===
                          clickActions.voucherCodeSent && (
                          <div>
                            <Button
                              className={`bg-white px-0 ps-3 pe-3 mt-xl-0 mt-3`}
                              outlined
                              label={
                                <span className="d-flex align-items-center">
                                  <CopyIcon fontSize="medium" />
                                  <span className="fsize-12 ps-2">
                                    {notifications.copyCode}
                                  </span>
                                </span>
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(
                                  Body.split(":")[1]
                                    .trim()
                                    .substring(0, 6)
                                );
                                showSuccessMsg("copySuccess");
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Box>
                    <div className="date mt-3 me-0 ms-auto m-md-0">
                      <span className="fsize-12 fweight-300 text-light-grey">
                        {formatDate({
                          date: CreatedDate,
                          format: "DD MMM YYYY | hh:mm a"
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              )
            )
          : !isLoading && (
              <div>
                <EmptyState
                  subTitle={notifications.noNotifications}
                  imgSrc={noImgPlaceholder}
                />
              </div>
            )}
      </div>
      {notificationsList?.length < notificationsMeta?.count && (
        <div className="load-more-section w-100 d-flex justify-content-center my-4">
          <Button
            label={notifications.loadMore}
            outlined
            onClick={() => setPage(page + 1)}
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
