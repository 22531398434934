import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";

import messages from "../../../../assets/locale/messages";

const KeywordsSection = ({ keywordsArray }) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    products: {
      addEditProduct: {
        blockLabels: { searchingKeywordsBlock }
      }
    }
  } = messages[lang];

  return (
    <div className={`keyword-section p-5 pb-3 border-radius-10`}>
      <h6 className="fsize-20 fweight-600 title-color mb-4">
        {searchingKeywordsBlock}
      </h6>
      <div className="keywords-container d-flex flex-wrap justify-content-start gap-3 ">
        {keywordsArray &&
          keywordsArray.map((keyword) => (
            <div key={keyword}>
              <Chip
                label={
                  <div className=" d-flex justify-content-center align-items-center gap-2 p-2">
                    <span>{keyword}</span>
                  </div>
                }
                className={`grey-chip border-radius-8`}
                sx={{
                  fontWeight: "semibold"
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default KeywordsSection;

KeywordsSection.propTypes = {
  keywordsArray: PropTypes.array.isRequired
};
