import { setUnseenNotificationsCount } from "../../notifications/helpers";
import {
  DECREMENT_NOTIFICATIONS_COUNT,
  GET_INITIAL_NOTIFICATIONS_RESPONSE,
  GET_NOTIFICATIONS_RESPONSE,
  INCREMENT_NOTIFICATIONS_COUNT,
  SET_PUSH_NOTIFICATION,
  SET_UNSEEN_NOTIFICATIONS_COUNT
} from "./types";

const INITIAL_STATE = {
  pushNotification: {
    show: false,
    id: null,
    title: "",
    body: "",
    clickAction: null,
    actionId: null,
    userTypeId: null
  },
  dropdownList: [],
  notificationsList: [],
  notificationsMeta: {},
  unseenNotificationsCount: localStorage.getItem(
    "unseen-notification-count"
  )
    ? +localStorage.getItem("unseen-notification-count")
    : 0
};

const notifications = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_PUSH_NOTIFICATION:
      return { ...state, pushNotification: { ...payload } };
    case GET_INITIAL_NOTIFICATIONS_RESPONSE:
      return { ...state, dropdownList: [...payload] };
    case GET_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        notificationsList:
          payload.meta.currentPage === 1
            ? [...payload.list]
            : [...state.notificationsList, ...payload.list],
        notificationsMeta: payload.meta
      };
    case SET_UNSEEN_NOTIFICATIONS_COUNT:
      setUnseenNotificationsCount(+payload);
      return { ...state, unseenNotificationsCount: +payload };
    case INCREMENT_NOTIFICATIONS_COUNT:
      setUnseenNotificationsCount(state.unseenNotificationsCount + 1);
      return {
        ...state,
        unseenNotificationsCount: state.unseenNotificationsCount + 1
      };
    case DECREMENT_NOTIFICATIONS_COUNT:
      if (state.unseenNotificationsCount > 0) {
        setUnseenNotificationsCount(
          state.unseenNotificationsCount - 1
        );
        return {
          ...state,
          unseenNotificationsCount: state.unseenNotificationsCount - 1
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default notifications;
