import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem, OutlinedInput } from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  Language,
  Search
} from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import Button from "../../Button";
import CountryFlag from "../../CountryFlag";
import { getCurrenciesListRequest } from "../../../store/Lookups/actions";
import { setWebsiteCurrency } from "../../../store/Home/actions";
import "./ChangeCurrency.scss";

const ChangeCurrency = () => {
  const dispatch = useDispatch();

  const currencies = useSelector(
    (state) => state.lookups.currenciesList
  );
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );
  const lang = useSelector((state) => state.locale.lang);
  const {
    home: { navbar }
  } = messages[lang];

  const [searchQuery, setSearchQuery] = useState("");
  const [currenciesList, setCurrenciesList] = useState([]);
  const [anchorEl, setAnchorEl] = useState("");

  const open = anchorEl ? true : false;

  useEffect(() => {
    dispatch(getCurrenciesListRequest());
  }, []);

  useEffect(() => {
    if (!websiteCurrency && currencies?.length) {
      dispatch(
        setWebsiteCurrency(
          currencies?.find((currency) => currency.Code === "USD")
        )
      );
      sessionStorage.setItem(
        "website-currency",
        JSON.stringify(
          currencies?.find((currency) => currency.Code === "USD")
        )
      );
    }
  }, [websiteCurrency, currencies]);

  useEffect(() => {
    if (searchQuery?.length) {
      setCurrenciesList(
        currencies?.filter(
          (item) =>
            item?.Name?.toLowerCase()?.includes(
              searchQuery.toLowerCase()
            ) ||
            item?.Code?.toLowerCase()?.includes(
              searchQuery.toLowerCase()
            )
        )
      );
    } else {
      setCurrenciesList([...currencies]);
    }
  }, [searchQuery, currencies]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const handleItemClick = (currency) => {
    dispatch(setWebsiteCurrency(currency));
    sessionStorage.setItem(
      "website-currency",
      JSON.stringify(currency)
    );
    handleClose();
  };

  return (
    <div className={`change-currency-menu-wrapper`}>
      <Button
        label={
          <div>
            <Language className="me-2" />
            <span className="">{websiteCurrency?.Code}</span>
            <KeyboardArrowDownOutlined />
          </div>
        }
        className="change-currency-btn h-100"
        id="change-currency-btn"
        aria-controls={open ? "change-currency-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        customSpacing={"px-2 px-md-2 py-2 py-md-2"}
      />
      <Menu
        id="change-currency-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "change-currency-btn"
        }}
        className={`change-currency-menu scroll-within`}>
        <MenuItem className="no-hover">
          <span className="fweight-500 fsize-16 title-color">
            {navbar.selectCurrency}
          </span>
        </MenuItem>
        <MenuItem className="no-hover">
          <OutlinedInput
            id={"search-cuurency"}
            name={"search-cuurency"}
            className="filter-search-input border-radius-8 w-100"
            placeholder={navbar.searchCurrency}
            variant="outlined"
            value={searchQuery}
            startAdornment={<Search fontSize="medium" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </MenuItem>
        {currenciesList?.map(
          ({ Id, Name, Code, Symbol, CountryCode }) => (
            <MenuItem
              key={`currency-option-${Id}`}
              onClick={() =>
                handleItemClick({ Id, Name, Code, Symbol })
              }>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex align-items-center justify-content-start ">
                  <CountryFlag
                    countryCode={CountryCode}
                    size={"1.5em"}
                  />
                  <div className="ms-3">
                    <p className="text-color mb-0">{Code}</p>
                    <p className="text-color mb-0">{Name}</p>
                  </div>
                </div>
                <span className="fweight-500 fsize-16 title-color">
                  {Symbol}
                </span>
              </div>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default ChangeCurrency;
