import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import messages from "../../assets/locale/messages";
import {
  isExporterRole,
  setCurrentRole,
  showErrorMsg
} from "../../utils/Helpers";
import { bothPaths, formPaths } from "../../utils/Constants";
import "./SwitchUserCurrentRoleButton.scss";

const SwitchUserCurrentRoleButton = () => {
  const lang = useSelector((state) => state.locale.lang);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    shared,
    myAccount: {
      switchCurrentRole: { exporter, importer, message }
    }
  } = messages[lang];

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );

  return (
    <div
      className={`switch-role-btn text-center`}
      onClick={() => {
        const isFormPath = formPaths.some((regex) =>
          regex.test(location.pathname)
        );
        const isBothPath = bothPaths.includes(location.pathname);
        if (isFormPath) {
          showErrorMsg(message);
        } else {
          setCurrentRole(!isBothPath && navigate);
        }
      }}>
      <Typography className="fweight-500 fsize-14">
        {`${shared.switchTo} 
        ${isExporterRole(userCurrentRole, userType) ? importer : exporter}`}
      </Typography>
    </div>
  );
};

export default SwitchUserCurrentRoleButton;
