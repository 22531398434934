import PropTypes from "prop-types";
import { Alert as MuiAlert } from "@mui/material";

import "./Alert.scss";
import { Close } from "@mui/icons-material";

const Alert = ({
  type,
  content,
  variant,
  icon,
  className,
  isClosable,
  onClose,
  isCustomBackground,
  customBackground
}) => {
  return (
    <div className="alert-container border-radius-8">
      <MuiAlert
        style={{
          background: isCustomBackground
            ? `url(${customBackground})`
            : "",
          backgroundRepeat: isCustomBackground ? "no-repeat" : "",
          backgroundSize: isCustomBackground ? "cover" : ""
        }}
        variant={variant}
        severity={type}
        icon={icon || false}
        className={`${className} ${isClosable && "is-closable-alert"} ${isCustomBackground && isClosable && "is-filled-closable-alert"}`}>
        {isClosable ? (
          <span
            className={`is-closable-alert-container d-flex justify-content-between  align-items-start ${isCustomBackground ? "" : "align-items-md-center"}`}>
            {content}
            <span
              className="pointer close-icon ms-2"
              onClick={onClose}>
              <Close />
            </span>
          </span>
        ) : (
          content
        )}
      </MuiAlert>
    </div>
  );
};

export default Alert;

Alert.propTypes = {
  type: PropTypes.oneOf(["error", "info", "success", "warning"])
    .isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  variant: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
  isCustomBackground: PropTypes.bool,
  customBackground: PropTypes.string
};

Alert.defaultProps = {
  variant: "outlined"
};
