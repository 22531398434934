import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import messages from "../../../../../assets/locale/messages";
import {
  requestOffplatformRequestDataRequest,
  requestOffplatformRequiredDocsRequest
} from "../../../../../store/Verification/actions";
import Alert from "../../../../../components/Alert";
import UploadDocumentsForm from "./UploadDocumentsForm";
import RequiredDocuments from "./RequiredDocuments";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import {
  BANNER_COLORS,
  COMPANY_VERIFICATION_STATUS
} from "../../../../../utils/Constants";
import { checkForMultiline } from "../../../../../utils/Helpers";

const UploadDocuments = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      offPlatform: { requiredDocuments }
    }
  } = messages[lang];

  const requestData = useSelector(
    (state) => state.verification.offplatformRequestData
  );

  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );

  useEffect(() => {
    dispatch(requestOffplatformRequiredDocsRequest());
  }, []);

  useEffect(() => {
    if (
      companyVerificationStatus ===
      COMPANY_VERIFICATION_STATUS.docsNeedUpdates
    ) {
      dispatch(requestOffplatformRequestDataRequest());
    }
  }, [companyVerificationStatus]);

  return (
    <div className="upload-documents-container">
      {companyVerificationStatus ===
        COMPANY_VERIFICATION_STATUS.docsNeedUpdates && (
        <div className="required-docs-edits mb-3">
          <Alert
            type="warning"
            icon={<ChipInfoIcon color={BANNER_COLORS.warning} />}
            content={
              <div>
                <p className="mb-0 fsize-16">
                  {requiredDocuments.docsNeedUpdates}
                </p>
                <p className="mb-0 fsize-14 light-text-color">
                  {checkForMultiline(requestData?.EditNotes)}
                </p>
              </div>
            }
          />
        </div>
      )}
      <h5 className="fsize-18 fweight-600 title-color">
        {requiredDocuments.uploadDocuments}
      </h5>
      <p className="fsize-16 text-color">
        {requiredDocuments.documentsNote}
      </p>
      <div className="mb-4">
        <RequiredDocuments />
      </div>
      <UploadDocumentsForm />
    </div>
  );
};

export default UploadDocuments;
