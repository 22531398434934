import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import messages from "../../../assets/locale/messages";

const CopyrightsFooter = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    <Box
      className="copyrights-footer-container"
      sx={{ height: "40px" }}>
      <div className="title-color h-100 fsize-14 fweight-500 footer-container medium-blue-bg d-flex justify-content-center align-items-center py-3">
        {shared.copyrights}
      </div>
    </Box>
  );
};

export default CopyrightsFooter;
