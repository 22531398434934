export const GET_LOGGED_IN_EXPORTER_DETAILS_REQUEST =
  "GET_LOGGED_IN_EXPORTER_DETAILS_REQUEST";
export const GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE =
  "GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE";
export const EDIT_LOGGED_IN_EXPORTER_DETAILS_REQUEST =
  "EDIT_LOGGED_IN_EXPORTER_DETAILS_REQUEST";
export const SUBCATEGORY_PRODUCTS_LIST_REQUEST =
  "SUBCATEGORY_PRODUCTS_LIST_REQUEST";
export const SUBCATEGORY_PRODUCTS_LIST_RESPONSE =
  "SUBCATEGORY_PRODUCTS_LIST_RESPONSE";
export const ADD_SHOWCASE_REQUEST = "ADD_SHOWCASE_REQUEST";
export const PUBLISH_EXPORTER_REQUEST = "PUBLISH_EXPORTER_REQUEST";
export const EDIT_SHOWCASE_REQUEST = "EDIT_SHOWCASE_REQUEST";
export const DELETE_SHOWCASE_REQUEST = "DELETE_SHOWCASE_REQUEST";

export const GET_USER_STATUS_REQUEST = "GET_USER_STATUS_REQUEST";

export const HIDE_INSTRUCTIONS_BANNER_REQUEST =
  "HIDE_INSTRUCTIONS_BANNER_REQUEST";
