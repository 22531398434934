import PropTypes from "prop-types";

export const CompanyIcon = ({ active, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      {...props}>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M10.768 18.573H3.751c-.966 0-1.758-.775-1.758-1.725V4.48c0-2.183 1.625-3.175 3.617-2.2l3.7 1.817c.8.391 1.458 1.433 1.458 2.316v12.159zM18.66 12.79v3.15c0 1.8-.833 2.633-2.633 2.633h-5.259v-9.65l.392.083 3.75.842 1.692.375c1.1.241 2 .808 2.05 2.408.008.05.008.1.008.159zM4.935 7.74h2.891M4.935 11.073h2.891"></path>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M14.91 9.848v2.683a1.878 1.878 0 01-1.875 1.875 1.878 1.878 0 01-1.875-1.875V9.006l3.75.842zM18.652 12.631a1.874 1.874 0 01-1.867 1.775 1.878 1.878 0 01-1.875-1.875V9.848l1.692.375c1.1.241 2 .808 2.05 2.408z"></path>
    </svg>
  );
};

CompanyIcon.propTypes = {
  active: PropTypes.bool
};
