import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  OutlinedInput,
  Typography
} from "@mui/material";
import { ExpandMore, Search } from "@mui/icons-material";

import Checkbox from "../../Checkbox";
import messages from "../../../assets/locale/messages";
import noSearch from "../../../assets/images/vectors/placeholders/noSearchFilter.png";
import "./ListFilter.scss";

const ListFilter = ({
  list,
  name,
  label,
  isManualSearch,
  searchPlaceholder,
  handleServerSideSearch,
  selectedOptionsIds,
  setSelectedOptionsIds,
  showSearchBar,
  clearFilters,
  collabsible,
  isApplyFilters,
  filterModalOpen,
  placeholder
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, search } = messages[lang];
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (clearFilters) {
      setSearchQuery("");
      setSelectedOptionsIds([]);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (filterModalOpen === false) {
      setSearchQuery("");
    }
  }, [filterModalOpen]);

  useEffect(() => {
    setSearchQuery("");
  }, [isApplyFilters]);

  useEffect(() => {
    setFilterOptions([...list]);
  }, [list, list.length]);
  useEffect(() => {
    if (isManualSearch) {
      if (searchQuery) {
        setFilterOptions(
          list?.filter((item) =>
            item?.Name?.toLowerCase()?.includes(
              searchQuery.toLowerCase()
            )
          )
        );
      } else {
        setFilterOptions([...list]);
      }
    } else {
      handleServerSideSearch(searchQuery);
    }
  }, [searchQuery]);

  const handleSelectOption = (id, isChecked) => {
    setSelectedOptionsIds(
      isChecked
        ? [...selectedOptionsIds, id]
        : selectedOptionsIds.filter((optionsId) => optionsId !== id)
    );
  };

  const renderSelectOption = (id, name, disabled) => (
    <div className="filter-option" key={`filter-option-${id}`}>
      <Checkbox
        label={name}
        name={name}
        checked={selectedOptionsIds?.includes(id)}
        disabled={disabled}
        onChange={(isChecked) => handleSelectOption(id, isChecked)}
      />
    </div>
  );

  const renderFilterContent = () => (
    <>
      {showSearchBar && (
        <div className={`search-input mb-3 ${collabsible && "mt-2"}`}>
          <OutlinedInput
            id={name}
            name={name}
            className="filter-search-input border-radius-8 w-100"
            placeholder={searchPlaceholder}
            variant="outlined"
            value={searchQuery}
            onChange={(e) =>
              setSearchQuery(
                e.target.value.replace(/\s\s+/g, " ")?.trimStart()
              )
            }
            startAdornment={<Search fontSize="medium" />}
          />
        </div>
      )}
      {filterOptions.length ? (
        <div
          className={`filter-options-outer-container ${filterOptions.length > 6 && viewMore && "fixed-height"}`}>
          {filterOptions?.map(
            ({ Id, Name, disabled }, index) =>
              (filterOptions.length < 6 || viewMore || index < 6) &&
              renderSelectOption(Id, Name, disabled)
          )}
        </div>
      ) : (
        <div className="text-center">
          <img src={noSearch} alt="no data found" />
          {placeholder && !searchQuery ? (
            <p className="fsize-12 light-text-color mt-3 mb-0">
              {placeholder}
            </p>
          ) : (
            <p className="fsize-12 light-text-color mt-3 mb-0">{`No ${label} found`}</p>
          )}
        </div>
      )}

      {filterOptions.length > 6 && (
        <Typography
          className="text-underline pointer fsize-12 fweight-700 text-color my-3"
          onClick={() => {
            setViewMore(!viewMore);
          }}>
          {viewMore
            ? shared.filtersDrawerLabels.viewLess
            : shared.filtersDrawerLabels.viewMore}
        </Typography>
      )}
    </>
  );

  return (
    <div className="list-filter-container w-100">
      {collabsible ? (
        <div className="collabsible-filter">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-${name}-content`}
              id={`panel-${name}-header`}>
              <h6 className="fweight-600 fsize-16 mb-0 title-color">
                {label}
              </h6>
            </AccordionSummary>
            <AccordionDetails>
              {renderFilterContent()}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className="non-collapsible-filter">
          <h6 className="fweight-600 fsize-16 mb-3 title-color">
            {label}
          </h6>
          {renderFilterContent()}
        </div>
      )}
    </div>
  );
};

export default ListFilter;

ListFilter.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectedOptionsIds: PropTypes.array.isRequired,
  setSelectedOptionsIds: PropTypes.func.isRequired,
  isManualSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  handleServerSideSearch: PropTypes.func,
  showSearchBar: PropTypes.bool,
  clearFilters: PropTypes.bool,
  collabsible: PropTypes.bool,
  isApplyFilters: PropTypes.bool,
  filterModalOpen: PropTypes.bool,
  placeholder: PropTypes.string
};

ListFilter.defaultProps = {
  isManualSearch: true
};
