import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import { DownloadWhiteIcon } from "../../../../utils/Icons";
import "./ViewImageModal.scss";

const ViewImageModal = ({ open, imgSrc, imgName, onClose }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    open && (
      <div
        className={`view-image-modal-bg d-flex align-items-center justify-content-center`}>
        <div className="close-icon pointer" onClick={onClose}>
          <Close className="text-white" />
        </div>
        <img className="img-container" src={imgSrc} />
        <div className="footer px-md-5 px-3 py-md-3 py-2 d-flex justify-content-between align-items-center">
          <p className="mb-0 text-white">{imgName}</p>
          <a
            className="mb-0 text-white pointer d-flex align-items-center"
            href={imgSrc}
            download={imgName}>
            <DownloadWhiteIcon />
            <span className="ms-2 d-none d-sm-block">
              {shared.downloadImage}
            </span>
          </a>
        </div>
      </div>
    )
  );
};

export default ViewImageModal;

ViewImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  imgSrc: PropTypes.any.isRequired,
  imgName: PropTypes.string.isRequired,
  onClose: PropTypes.any.isRequired
};
