const productRequests = {
  importerProductRequests: {
    addEditProductRequest: {
      addPageTitle: "Request Product Form",
      editPageTitle: "Edit Request",
      optional: "(Optional)",
      blockLabels: {
        requestDetails: "Request Details:",
        requiredQuantity: "Required Quantity:",
        budgetRange: "Budget Range:"
      },
      inputLabels: {
        requestTitle: "Request Title",
        requestDescription: "Request Description",
        categories: "Categories",
        from: "From",
        to: "To",
        unit: "Unit",
        value: "value"
      },
      placeholders: {
        requestTitlePlaceholder: "Enter title",
        requestDescriptionPlaceholder: "Enter Description",
        categoriesPlaceholder: "Select related categories",
        requiredQuantityPlaceholder: "Enter quantity",
        budgetRangeUnitPlaceholder: "Enter unit",
        budgetRangePlaceholder: "Enter price"
      },
      guideSection: {
        title: "How it Works?",
        subtitle:
          "Save time and effort by letting our system connect you directly with",
        firstStepTitle: "Submit Your Product Request",
        firstStepSubtitle: "Provide as much detail as possible.",
        secondStepTitle: "Your Request Will Be Published",
        secondStepSubtitle:
          "Your request will be visible to the potential exporters.",
        thirdStepTitle: "Receive Offline Contact from Exporters",
        thirdStepSubtitle: "Exporters will reach out to you directly"
      },
      ProductRequestCurrencyChangeModal: {
        content: "Change Product Request Currency",
        confirmationContent:
          "Editing this request will update the currency to your current selection",
        proceedBtn: "Proceed",
        keepOldCurrencyBtn: "Keep old currency"
      }
    },
    list: {
      pageTitle: "Product Requests ",
      request: "Request",
      addMoreButton: "Add New Request",
      tableHeader: {
        requestTitle: "Request Title",
        requestQuantity: "Required Quantity",
        submissionDate: "Submission Date"
      },
      notProductRequestFound: "You haven’t added any requests yet.",
      noSearchResults:
        "There is no search result that matches the entered data.",
      searchPlaceholder: "Search by request title",
      filters: {
        status: "Product Request Status"
      },
      published: "Published",
      closed: "Closed",
      tabs: { all: "All", published: "Published", closed: "Closed" }
    },
    viewProductRequest: {
      pageTitle: "Request Details",
      closeRequestButton: "Close Request",
      editButton: "Edit",
      requestInfoSection: {
        requestInfo: "Request Info",
        requestStatus: "Request Status :",
        postDate: "Post Date :",
        closureDate: "Closure Date :",
        lastUpdatedAt: "Last updated at :",
        country: "Country :",
        categories: "Categories"
      },
      requestDetailsSection: {
        budgetRange: "Budget Range :",
        requiredQuantity: "Required Quantity :"
      }
    },
    closeProductRequest: {
      close: "Close",
      content:
        "By closing the request, it will be removed from the website",
      confirmationContent: " are you sure you want to close it?"
    }
  },
  websiteProductRequests: {
    list: {
      breadcrumbs: {
        home: "Home"
      },
      requestProduct: "Request Product",
      searchPlaceholder: "What are you looking for",
      noProductRequestsEmptyPlaceholder: "No Product Requests Found",
      filter: {
        countrySearchPLaceholder: "Search by country Name",
        country: "Country"
      }
    },
    productRequestCard: {
      budgetRange: "Budget Range :",
      requiredQuantity: "Required Quantity :"
    },
    viewProductRequest: {
      budgetRange: "Budget Range :",
      postDate: "Post Date :",
      country: "Country :",
      requiredQuantity: "Required Quantity :",
      closedRequest: {
        title:
          "It Looks Like the Request Was Removed by the Importer",
        subtitle:
          "It appears this request is no longer available, as the importer may have removed it. Don’t worry though, you can still check out similar requests",
        browseRequestsBtn: "Browse Requests"
      }
    }
  }
};

export default productRequests;
