import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import Button from "../../../../../components/Button";
import messages from "../../../../../assets/locale/messages";
import { ClockPendingIcon } from "../../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import { confirmPaymentIntentRequest } from "../../../../../store/Verification/actions";

const SuccessPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { actions, onlinePayment }
  } = messages[lang];

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );
  const redirectStatus = searchParams.get("redirect_status");
  const isPaymentConfirmed = searchParams.get("is_payment_confirmed");

  // This is used to prevent confirmPaymentIntentRequest from being called twice
  const isRequestSentRef = useRef(false);

  useEffect(() => {
    if (
      paymentIntent &&
      !isRequestSentRef.current &&
      !isPaymentConfirmed
    ) {
      dispatch(
        confirmPaymentIntentRequest({
          paymentIntentId: paymentIntent,
          setSearchParams
        })
      );
      isRequestSentRef.current = true;
    }
  }, [dispatch, paymentIntent, isPaymentConfirmed]);

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${40}px  - ${70}px)`,
        marginTop: "5rem"
      }}
      className="payment-success-page w-100 d-flex align-items-center justify-content-center">
      {isPaymentConfirmed && (
        <Box
          className="text-center faint-grey-bg border-radius-10 px-3 px-md-5 py-5 position-relative"
          sx={{
            width: {
              xs: "95%",
              sm: "90%",
              md: "80%",
              lg: "50%"
            },
            margin: "0 auto"
          }}>
          <Box
            className="vector-section mb-3"
            sx={{
              position: "absolute",
              top: {
                xs: "-20%",
                sm: "-20%"
              },
              left: {
                xs: "40%",
                sm: "44%",
                xl: "46%"
              },
              width: {
                xs: "5rem",
                sm: "auto"
              }
            }}>
            <ClockPendingIcon className="mb-4 w-100" />
          </Box>
          <Box
            className="content-section mt-4"
            sx={{
              margin: "0 auto",
              width: {
                xs: "100%",
                sm: "85%",
                md: "70%",
                lg: "90%",
                xl: "75%"
              }
            }}>
            <h5 className="fsize-24 fweight-700 title-color mb-3">
              {onlinePayment.successTransfer}
            </h5>
            <p className="fsize-16 text-color mb-1">
              {onlinePayment.successMsg1}
            </p>
            <p className="fsize-16 text-color mb-4">
              {onlinePayment.successMsg2}
            </p>
            <Button
              label={actions.continueBrowsing}
              onClick={() => {
                navigate(ROUTE_PATHS.home);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SuccessPayment;
