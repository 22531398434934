import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import Navbar from "./NavBar";
import Footer from "./Footer";
import CopyrightsFooter from "./CopyrightsFooter";
import NotificationSnackbar from "../NotificationSnackbar";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { setBasicLayoutContainerRef } from "../../store/Navigation/actions";

const BasicLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef();

  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (
      [
        ROUTE_PATHS.registerSuccess,
        ROUTE_PATHS.registerFailure
      ].includes(location.pathname)
    ) {
      setShowFooter(false);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setBasicLayoutContainerRef(containerRef));
  }, [containerRef]);

  return (
    <Box
      className="basic-layout-container"
      sx={{
        height: "100vh"
      }}>
      <div
        ref={containerRef}
        className="basic-layout-main-section scroll-within"
        style={{ height: "100%" }}>
        <Navbar />
        <Box>
          <Outlet />
        </Box>
        {showFooter && <Footer />}
        <CopyrightsFooter />
        <NotificationSnackbar />
      </div>
    </Box>
  );
};

export default BasicLayout;
