import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { showHideLoader } from "../../../store/Loader/actions";
import { getCountriesListRequest } from "../../../store/Lookups/actions";

const CheckoutForm = ({
  clientSecret,
  redirectUrl,
  setPaymentIntentFunc,
  setPaymentFailureMsgFunc
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const userCountry = useSelector(
    (state) => state.auth.login.CountryId
  );
  // const userName = useSelector((state) => state.auth.login?.UserName);
  // const userEmail = useSelector((state) => state.auth.login?.Email);
  const countriesList = useSelector(
    (state) => state.lookups.countriesList
  );

  const [userCountryStripeCode, setUserCountryStripeCode] =
    useState(null);

  useEffect(() => {
    dispatch(getCountriesListRequest());
  }, []);

  useEffect(() => {
    if (userCountry && countriesList?.length) {
      const stripeCode = countriesList.find(
        (country) => country.Id === userCountry
      )?.CountryNameShort;
      setUserCountryStripeCode(stripeCode);
    }
  }, [userCountry, countriesList]);

  // This useEffect is commented in case needed in future
  // useEffect(() => {
  //   if (!stripe || !clientSecret) {
  //     return;
  //   }

  //   stripe
  //     .retrievePaymentIntent(clientSecret)
  //     .then(({ paymentIntent }) => {
  //       setMessage(
  //         paymentIntent.status === "succeeded"
  //           ? "Your payment succeeded"
  //           : paymentIntent.status === "requires_payment_method"
  //             ? "Payment method required"
  //             : "Unexpected error occurred"
  //       );
  //     });
  // }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    dispatch(showHideLoader(true));

    // This validates on the card info if missing or wrong
    const { error: submitError } = await elements.submit();

    if (submitError?.message) {
      dispatch(showHideLoader(false));
      return;
    }

    // This confirms the payment process with the issueing bank
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}${redirectUrl}`,
        payment_method_data: {
          billing_details: {
            // name: userName,
            // email: userEmail,
            address: {
              country: userCountryStripeCode
            }
          }
        }
      }
    });

    if (error) {
      dispatch(setPaymentFailureMsgFunc(error.message));
      if (error?.payment_intent?.id) {
        dispatch(setPaymentIntentFunc(error.payment_intent.id));
      }
    }

    dispatch(showHideLoader(false));
  };

  return (
    <form onSubmit={handleSubmit} id="online-payment-form">
      <PaymentElement
        options={{
          allowedPaymentMethods: ["card"],
          fields: {
            billingDetails: { address: { country: "never" } }
          }
        }}
      />
    </form>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  setPaymentIntentFunc: PropTypes.func.isRequired,
  setPaymentFailureMsgFunc: PropTypes.func.isRequired
};
