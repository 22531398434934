import { put, call, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/search";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getSearchSuggestionsSaga({ payload }) {
  try {
    yield put(actions.getSearchSuggestionsResponse([]));
    const response = yield call(apis.getSearchSuggestions, payload);
    const responseData = response.data.Data;
    yield put(
      actions.getSearchSuggestionsResponse(responseData || [])
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getAllSearchResultsSaga({ payload }) {
  try {
    yield put(
      actions.getAllSearchResultsResponse({
        ProductDetails: [],
        SubCategoryDetails: [],
        ExporterDetails: []
      })
    );
    const response = yield call(apis.getAllSearchResults, payload);
    const responseData = response.data.Data;
    const {
      TotalSubCategoriesCount,
      TotalExportersCount,
      TotalProductsCount
    } = responseData;

    yield put(actions.getAllSearchResultsResponse(responseData));
    yield put(
      actions.setTotalSearchCount(
        TotalSubCategoriesCount +
          TotalExportersCount +
          TotalProductsCount
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSearchProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getSearchProducts, payload);
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getSearchProductsResponse({
        list: Items,
        meta: {
          currentPage: CurrentPage,
          count: ItemCount,
          pages: TotalPages
        }
      })
    );
    yield put(actions.setTotalSearchCount(ItemCount));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSearchSubCategoriesSaga({ payload }) {
  try {
    const response = yield call(apis.getSearchSubCategories, payload);
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getSearchSubCategoriesResponse({
        list: Items,
        meta: {
          currentPage: CurrentPage,
          count: ItemCount,
          pages: TotalPages
        }
      })
    );
    yield put(actions.setTotalSearchCount(ItemCount));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSearchExportersSaga({ payload }) {
  try {
    const response = yield call(apis.getSearchExporters, payload);
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getSearchExportersResponse({
        list: Items,
        meta: {
          currentPage: CurrentPage,
          count: ItemCount,
          pages: TotalPages
        }
      })
    );
    yield put(actions.setTotalSearchCount(ItemCount));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* SearchSagas() {
  yield takeLatest(
    types.GET_SEARCH_SUGGESTIONS_REQUEST,
    getSearchSuggestionsSaga
  );
  yield takeLatest(
    types.GET_ALL_SEARCH_RESULTS_REQUEST,
    getAllSearchResultsSaga
  );
  yield takeLatest(
    types.GET_SEARCH_PRODUCTS_REQUEST,
    getSearchProductsSaga
  );
  yield takeLatest(
    types.GET_SEARCH_SUB_CATEGORIES_REQUEST,
    getSearchSubCategoriesSaga
  );
  yield takeLatest(
    types.GET_SEARCH_EXPORTERS_REQUEST,
    getSearchExportersSaga
  );
}
