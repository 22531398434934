import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import messages from "../../../../../assets/locale/messages";
import { CloseBgIcon } from "../../../../../utils/Icons";

const CancelRequestModal = ({ open, onClose, onConfirm }) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      offPlatform: { cancelRequest }
    },
    shared
  } = messages[lang];

  const renderModalContent = () => (
    <div className="text-center">
      <CloseBgIcon />
      <p className="fsize-24 fweight-700 title-color">
        {cancelRequest.cancelVerificationRequest}
      </p>
      <p className="fsize-16 text-color">
        {cancelRequest.confirmMsg}
      </p>
    </div>
  );

  return (
    open && (
      <Modal
        open={open}
        isContentText={false}
        content={renderModalContent()}
        confirmBtnTxt={shared.confirm}
        cancelBtnTxt={shared.cancel}
        handleClose={onClose}
        handleCancel={onClose}
        handleConfirm={onConfirm}
        title
      />
    )
  );
};

export default CancelRequestModal;

CancelRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
