import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import { DeleteModalIcon } from "../../../utils/Icons";
import { getDeviceId } from "../../../utils/Auth";
import { deleteExporterAccountRequest } from "../../../store/Settings/actions";
import { formatDate } from "../../../utils/Helpers";
import "../DeleteProfile/DeleteProfile.scss";

const DeleteProfileConfirm = ({ open, closeModal }) => {
  const lang = useSelector((state) => state.locale.lang);
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    settings: {
      deleteProfileConfirm: {
        title,
        contentFirstPart,
        contentSecondPart,
        confirmationContent
      }
    },
    shared
  } = messages[lang];

  const handleConfirmDelete = () => {
    dispatch(
      deleteExporterAccountRequest({
        logoutParams: {
          device_id: getDeviceId(),
          navigate
        }
      })
    );
    closeModal();
  };

  const deleteProfileConfirmContent = () => {
    return (
      <div className="delete-profile-confirm-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-profile-confirm-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {`${contentFirstPart} ${formatDate({
              date: userPlan?.RenewalDate,
              stringInterpolation: true
            })}. ${contentSecondPart}`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"delete-account-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirmDelete();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={deleteProfileConfirmContent()}
      hasActions={true}
      confirmBtnTxt={shared.confirm}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default DeleteProfileConfirm;

DeleteProfileConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
