import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { KeyboardArrowLeft } from "@mui/icons-material";

import Input from "../../../components/Input";
import messages from "../../../assets/locale/messages";
import PasswordValidation from "../../../components/PasswordValidation";
import { showHideLoader } from "../../../store/Loader/actions";
import { PASSWORD_REGEX } from "../../../utils/Patterns";
import Button from "../../../components/Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { changePasswordRequest } from "../../../store/Settings/actions";
import ChangePasswordSuccess from "../ChangePasswordSuccessModal";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showChangeSuccessModal = useSelector(
    (state) => state.settings.showChangeSuccessModal
  );
  const [focused, setFocused] = useState(false);

  const {
    shared,
    settings: {
      changePassword: { pageTitle, inputLabels, placeholders }
    }
  } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(
        "currentPasswordRequired"
      ),
      newPassword: Yup.string()
        .required("newPasswordRequired")
        .matches(PASSWORD_REGEX, "invalidPassword"),
      confirmPassword: Yup.string()
        .required("confirmNewPasswordRequired")
        .test(
          "are-passwords-matched",
          "passwordIsNotMatched",
          function (value) {
            const { newPassword } = this.options.context;

            if (value && newPassword) {
              return value === newPassword;
            }
            return true;
          }
        )
    }),
    onSubmit: ({ currentPassword, newPassword, confirmPassword }) => {
      dispatch(showHideLoader(true));
      const exporterPasswordData = {
        OldPassword: currentPassword,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword
      };

      dispatch(
        changePasswordRequest({
          data: exporterPasswordData
        })
      );
      dispatch(showHideLoader(false));
    }
  });

  return (
    <div className="change-password-container">
      <div className="page-title mb-4">
        <div className="d-flex align-items-center title-color gap-2">
          <KeyboardArrowLeft
            className="pointer"
            onClick={() => {
              navigate(ROUTE_PATHS.settings);
            }}
          />
          <Typography variant="h5" className="title-color">
            {pageTitle}
          </Typography>
        </div>
      </div>
      <form
        className="change-password-form"
        onSubmit={handleSubmit}
        noValidate>
        <div className="passwords-container bg-white border-radius-10 col-12 p-md-5px-md-4 pb-md-3 p-4 pt-5">
          <div className="col-lg-6 col-md-8 col-12">
            <Input
              id="currentPassword"
              label={inputLabels.currentPassword}
              name="currentPassword"
              type="password"
              placeholder={placeholders.currentPassword}
              required
              onChange={(value) => {
                if (!value || value?.trim()) {
                  const modifiedValue = value.replace(/\s\s+/g, " ");
                  setFieldTouched("currentPassword");
                  setFieldValue("currentPassword", modifiedValue);
                }
              }}
              value={values.currentPassword}
              inputClass="mb-1 pass-container"
              inputWrapperClass="mb-4"
              isInputHasErr={
                !!(touched.currentPassword && errors.currentPassword)
              }
              errMsg={errors.currentPassword}
            />
          </div>
          <div className="col-lg-6 col-md-8 col-12">
            <Input
              id="newPassword"
              label={inputLabels.newPassword}
              name="newPassword"
              type="password"
              placeholder={placeholders.newPassword}
              required
              onChange={(value) => {
                if (!value || value?.trim()) {
                  const modifiedValue = value.replace(/\s\s+/g, " ");
                  setFieldTouched("newPassword");
                  setFieldValue("newPassword", modifiedValue);
                }
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              value={values.newPassword}
              inputClass="mb-1 pass-container"
              inputWrapperClass="mb-4"
              isInputHasErr={
                !!(touched.newPassword && errors.newPassword)
              }
              errMsg={errors.newPassword}
            />
          </div>
          <div className="col-lg-6 col-md-8 col-12">
            <Input
              id="confirmPassword"
              label={inputLabels.confirmPassword}
              name="confirmPassword"
              type="password"
              placeholder={placeholders.confirmPassword}
              required
              onChange={(value) => {
                if (!value || value?.trim()) {
                  const modifiedValue = value.replace(/\s\s+/g, " ");
                  setFieldTouched("confirmPassword");
                  setFieldValue("confirmPassword", modifiedValue);
                }
              }}
              value={values.confirmPassword}
              inputClass="mb-1 pass-container"
              inputWrapperClass="mb-4"
              isInputHasErr={
                !!(touched.confirmPassword && errors.confirmPassword)
              }
              errMsg={errors.confirmPassword}
            />
          </div>
          {focused && (
            <PasswordValidation
              passwordValue={values.newPassword}
              wrapperClass="my-2"
            />
          )}
        </div>
        <div
          className={`form-buttons-container bg-white d-flex justify-content-between flex-wrap w-100 mt-4  p-4 bg-white border-radius-8`}>
          <Button
            key={shared.cancel}
            outlined
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={shared.cancel}
            onClick={() => navigate(ROUTE_PATHS.settings)}
          />
          <Button
            key={shared.change}
            disabled={!dirty || !isValid}
            type="button"
            className={"w-auto"}
            labelClass={`py-1 ps-5 pe-5`}
            label={shared.change}
            onClick={handleSubmit}
          />
        </div>
      </form>
      {showChangeSuccessModal && (
        <ChangePasswordSuccess open={showChangeSuccessModal} />
      )}
    </div>
  );
};

export default ChangePassword;
