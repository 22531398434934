import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import supportImg from "../../../../assets/images/home/get-support-img.png";
import Button from "../../../../components/Button";
import { sendSupportEmailRequest } from "../../../../store/Home/actions";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const GetSupportSection = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];

  const isAuth = useSelector((state) => state.auth.login?.Token);
  const isSupportEmailSent = useSelector(
    (state) => state.home.isSupportEmailSent
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendSupportEmail = () => {
    isAuth
      ? dispatch(sendSupportEmailRequest())
      : navigate(ROUTE_PATHS.login);
  };

  return (
    <div className="get-support-section-container ">
      <div className="gradient-bg border-radius-10 py-md-3 py-3 px-md-0 px-3 d-flex align-items-center justify-content-center gap-5">
        <div className="d-md-block d-none">
          <img className="support-img" src={supportImg} />
        </div>
        <Box
          className="support-content"
          sx={{
            width: {
              xs: "100%",
              md: "40%"
            }
          }}>
          <h3 className="text-dark-blue fsize-30 fweight-700">
            {home.getSupport.title}
          </h3>
          <p className="text-light-grey fsize-18">
            {home.getSupport.content}
          </p>
          {isSupportEmailSent ? (
            <div className="support-sent-success border-radius-10 medium-dark-blue-bg py-3 px-5 width-max-content">
              <p className="text-primary mb-0">
                {home.getSupport.supportRequestSuccessMsg}
              </p>
            </div>
          ) : (
            <Button
              label={home.getSupport.button}
              onClick={sendSupportEmail}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default GetSupportSection;
