import {
  GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE,
  GET_PRODUCTS_RECOMMENDATIONS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  subCategoriesProductsList: [],
  subCategoriesAllProductsList: [],
  subCategoriesAllProductsMeta: {},
  productsRecommendations: []
};

const subCategoriesProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE:
      return {
        ...state,
        subCategoriesProductsList: action.payload.list
      };
    case GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE:
      return {
        ...state,
        subCategoriesAllProductsList:
          action.payload.meta.currentPage === 1
            ? [...action.payload.list]
            : [
                ...state.subCategoriesAllProductsList,
                ...action.payload.list
              ],
        subCategoriesAllProductsMeta: action.payload.meta
      };
    case GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE:
      return {
        ...state,
        subCategoryProductDetails: action.payload
      };
    case GET_PRODUCTS_RECOMMENDATIONS_RESPONSE:
      return {
        ...state,
        productsRecommendations: action.payload
      };
    default:
      return state;
  }
};

export default subCategoriesProducts;
