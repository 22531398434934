import {
  GET_CATEGORY_DETAILS_RESPONSE,
  GET_CATEGORY_DROPDOWN_RESPONSE,
  SET_CATEGORY_SUGGESTION_MODAL_OPEN,
  SET_SUGGESTED_CATEGORY_DATA,
  SET_SUGGESTED_CATEGORY_TEMP_ID
} from "./types";

const INITIAL_STATE = {
  categorySuggestionModalOpen: false,
  suggestedCategoryTempId: null,
  categoryDetails: {
    categoryData: {},
    popularSubcategories: [],
    popularExporters: [],
    latestProducts: []
  },
  suggestedCategoryData: null,
  categoryDropdown: null
};

const categories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CATEGORY_SUGGESTION_MODAL_OPEN:
      return {
        ...state,
        categorySuggestionModalOpen: action.payload
      };
    case SET_SUGGESTED_CATEGORY_TEMP_ID:
      return {
        ...state,
        suggestedCategoryTempId: action.payload
      };
    case GET_CATEGORY_DETAILS_RESPONSE:
      return {
        ...state,
        categoryDetails: {
          categoryData: action.payload.CategoryData,
          popularSubcategories: action.payload.PopularSubcategories,
          popularExporters: action.payload.PopularExporters,
          latestProducts: action.payload.LatestProducts
        }
      };
    case SET_SUGGESTED_CATEGORY_DATA:
      return {
        ...state,
        suggestedCategoryData: action.payload
      };
    case GET_CATEGORY_DROPDOWN_RESPONSE:
      return {
        ...state,
        categoryDropdown: action.payload
      };
    default:
      return state;
  }
};

export default categories;
