import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const MainSection = ({
  children,
  drawerWidth,
  navbarHeight,
  footerHeight,
  reference
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const customStyle = {
    backgroundColor: "#f6f6f6",
    mt: `${navbarHeight}px `,
    flexGrow: 1,
    pl: {
      xs: 2,
      md: 3
    },
    pr: {
      xs: 2,
      md: 3
    },
    pt: 2,
    pb: 3,
    minHeight: `calc(100vh - ${footerHeight}px  - ${navbarHeight}px)`,
    height: `calc(100vh - ${footerHeight}px  - ${navbarHeight}px)`,
    ml: {
      md: lang === "en" && `${drawerWidth}px`
    },
    mr: { md: lang === "ar" && `${drawerWidth}px` },
    width: {
      xs: "100%",
      sm: `100%`,
      md: `calc(100% - ${drawerWidth}px)!important`
    },
    transition: (theme) =>
      theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
  };
  return (
    <Box
      component="main"
      ref={reference}
      className="main-section-container scroll-within"
      sx={customStyle}>
      {children}
    </Box>
  );
};

MainSection.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  navbarHeight: PropTypes.number,
  footerHeight: PropTypes.number,
  children: PropTypes.element,
  reference: PropTypes.any
};

export default MainSection;
