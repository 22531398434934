import { axiosInstanceImporters } from ".";

const websiteProductRequestsList = async ({ params }) =>
  await axiosInstanceImporters.get(
    "/api/ProductRequest/GetWebsiteList",
    {
      params,
      paramsSerializer: { indexes: null }
    }
  );

const addImporterProductRequest = async ({ data }) =>
  await axiosInstanceImporters.post("/api/ProductRequest/Add", data);

const editImporterProductRequest = async ({ data }) =>
  await axiosInstanceImporters.post("/api/ProductRequest/Edit", data);

const getImporterProductRequestDetails = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/ProductRequest/View`, {
    params
  });

const importerProductRequestsList = async ({ params }) =>
  await axiosInstanceImporters.get("/api/ProductRequest/Get", {
    params,
    paramsSerializer: { indexes: null }
  });

const getProductRequestDetails = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/ProductRequest/View`, {
    params
  });

const getImporterContactInfo = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/User/GetById`, { params });

const closeProductRequest = async ({ id }) =>
  await axiosInstanceImporters.get(
    `/api/ProductRequest/CloseRequest/${id}`
  );

export {
  websiteProductRequestsList,
  addImporterProductRequest,
  editImporterProductRequest,
  importerProductRequestsList,
  getProductRequestDetails,
  getImporterContactInfo,
  getImporterProductRequestDetails,
  closeProductRequest
};
