import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText
} from "@mui/material";

import messages from "../../assets/locale/messages";

const RadioButtonGroup = ({
  value,
  onChange,
  name,
  id,
  label,
  options,
  wrapperClassName,
  radioBtnClassname,
  disabled,
  row,
  hasError,
  helperText,
  helperTextClass,
  errMsg,
  required
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  return (
    <div className={`radio-group-wrapper ${wrapperClassName}`}>
      <FormControl disabled={disabled} required={required}>
        {label && <FormLabel id={id || name}>{label}</FormLabel>}
        <RadioGroup
          aria-labelledby={id || name}
          name={name}
          value={value}
          row={row}
          onChange={(e) => onChange(e.target.value)}>
          {options.map(({ label, value, disable }) => (
            <div
              className={radioBtnClassname}
              key={`radio-btn-${value}`}>
              <FormControlLabel
                value={value}
                label={label}
                control={<Radio />}
                disabled={disable}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText
        error={hasError}
        className={`${helperTextClass || "fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {errMsg?.trim() && hasError ? (
          <>{inputsValidations[errMsg]}</>
        ) : (
          helperText
        )}
      </FormHelperText>
    </div>
  );
};

export default RadioButtonGroup;

RadioButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disable: PropTypes.bool
    })
  ).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  wrapperClassName: PropTypes.string,
  radioBtnClassname: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  row: PropTypes.bool,
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  helperTextClass: PropTypes.string,
  errMsg: PropTypes.string
};
