import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../assets/locale/messages";
import Tabs from "../../../../components/Tabs";
import {
  checkForMultiline,
  isRichText,
  RichTextToHTML
} from "../../../../utils/Helpers";

const OverviewSection = ({
  description,
  specifications,
  fromExporter
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { subCategories } = messages[lang];

  const [tabValue, setTabValue] = useState(0);

  const renderSpecifications = () => (
    <div className="specs-container">
      {specifications?.map(
        (
          { SpecificationOtherName, OriginalMediaValue, Value },
          i
        ) => (
          <div
            className={`row py-3 px-2 ${i % 2 !== 0 && "faint-blue-bg"}`}
            key={`specs-${i}`}>
            <div
              className={`${fromExporter && "spec-name"} col-xl-3 col-lg-4 col-md-5 col-6`}>
              <p className="text-color mb-0">
                {SpecificationOtherName}
              </p>
            </div>
            <div
              className={`${"spec-value col-xl-9 col-lg-8 col-md-7 col-6 text-break"} `}>
              {OriginalMediaValue ? (
                <a
                  className="primary-link"
                  href={Value}
                  download={"product catalogue"}
                  target="_blank"
                  rel="noreferrer">
                  {subCategories.productDetails.downloadTheCatalogue}
                </a>
              ) : (
                <p className="title-color fweight-500 mb-0">
                  {Value}
                </p>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );

  const dynamicTabsArray = [];

  description?.length
    ? dynamicTabsArray.push({
        label: subCategories.productDetails.description,
        // to do : remove if em agrees to only show quill text , disregard old products
        content: isRichText(description) ? (
          <p
            className="mb-0 fsize-16 text-color pt-4"
            dangerouslySetInnerHTML={
              description
                ? RichTextToHTML(checkForMultiline(description))
                : ""
            }></p>
        ) : (
          <p className="mb-0 fsize-16 text-color pt-4">
            {description ? checkForMultiline(description) : ""}
          </p>
        )
      })
    : "";

  specifications?.length
    ? dynamicTabsArray.push({
        label: subCategories.productDetails.specifications,
        content: <div>{renderSpecifications()}</div>
      })
    : "";

  return (
    <div className="overview-section mb-4 p-4 border-radius-10 light-border">
      <h6 className="fsize-20 fweight-600 title-color mb-4">
        {subCategories.productDetails.overview}
      </h6>
      <Tabs
        tabs={dynamicTabsArray}
        value={tabValue}
        handleChange={setTabValue}
      />
    </div>
  );
};

export default OverviewSection;

OverviewSection.propTypes = {
  description: PropTypes.string,
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  fromExporter: PropTypes.bool
};
