export const StayTunedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    fill="none"
    viewBox="0 0 26 24"
    {...props}>
    <mask
      id="mask0_5950_36335"
      style={{ maskType: "alpha" }}
      width="26"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse">
      <path fill="#D9D9D9" d="M0.145 0H25.653V23.5H0.145z"></path>
    </mask>
    <g mask="url(#mask0_5950_36335)">
      <path
        fill="#21A1F6"
        d="M4.139 12.811a.529.529 0 101.006-.326l-1.842-5.68a.53.53 0 10-1.007.327l1.843 5.68zm3.829.025a.53.53 0 00.554-.503l.44-9.149a.529.529 0 10-1.057-.05l-.44 9.148a.529.529 0 00.503.554zm15.196 1.227l-8.742 3.157a.529.529 0 10.36.995l8.741-3.157a.529.529 0 00-.359-.995zm-1.859 7.391a.53.53 0 10-.09-1.054l-6.016.512a.529.529 0 10.09 1.054l6.016-.512z"></path>
      <path
        fill="#0071BC"
        d="M3.347 12.124c.17.492.908.254.755-.245l-1.843-5.68c-.168-.491-.908-.256-.754.245l1.842 5.68zm3.71-.068a.398.398 0 00.415-.377l.44-9.148a.397.397 0 10-.792-.039l-.44 9.15a.397.397 0 00.377.415zm6.694 5.406c.08-.01 8.732-3.154 8.81-3.175.484-.183.227-.917-.27-.747l-8.741 3.158c-.449.165-.27.843.201.764zm.542 2.934c-.519.056-.454.826.067.791l6.016-.512c.516-.05.457-.825-.067-.79l-6.017.511z"></path>
    </g>
  </svg>
);
