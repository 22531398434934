import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import { getCurrenciesListRequest } from "../../../store/Lookups/actions";
import { changeCurrencyRequest } from "../../../store/Settings/actions";
import { SUBSCRIPTION_PLANS_IDS } from "../../../utils/Constants";
import { CurrencyBgIcon } from "../../../utils/Icons";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  getUserCurrency,
  isExporterRole
} from "../../../utils/Helpers";
import "./ChangeCurrency.scss";

const ChangeCurrency = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const currencies = useSelector(
    (state) => state.lookups.currenciesList
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared,
    settings: { changeCurrency }
  } = messages[lang];

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedCurrencyTouched, setSelectedCurrencyTouched] =
    useState(false);

  useEffect(() => {
    dispatch(getCurrenciesListRequest());
  }, []);

  useEffect(() => {
    if (open) {
      if (isExporterRole(userCurrentRole, userType)) {
        if (
          userPlan?.Id &&
          userPlan?.Id === SUBSCRIPTION_PLANS_IDS.premium
        ) {
          setSelectModalOpen(true);
        } else {
          setConfirmModalOpen(true);
        }
      } else {
        setSelectModalOpen(true);
      }
    }
  }, [userPlan, open]);

  useEffect(() => {
    if (currencies && currencies?.length) {
      setSelectedCurrency(
        currencies
          .map(({ Id, Code, Name }) => ({
            id: Id,
            label: `${Name} (${Code})`
          }))
          .find(({ id }) => getUserCurrency()?.id === id)
      );
    }
  }, [currencies]);

  const handleChangeCurrency = () => {
    dispatch(
      changeCurrencyRequest({
        params: { currencyId: selectedCurrency.id },
        currency: currencies.find(
          ({ Id }) => selectedCurrency.id === Id
        ),
        userType
      })
    );
    setSelectModalOpen(false);
    onClose();
  };

  const renderConfirmModalContent = () => (
    <div className="text-center">
      <div className="avatar-section mb-3">
        <CurrencyBgIcon />
      </div>
      <p className="title-color fsize-18 fweight-600">
        {changeCurrency.changeCurrencyConfirm}
      </p>
      <p className="text-color">{changeCurrency.confirmationMsg}</p>
    </div>
  );

  const renderSelectModalContent = () => (
    <div className="px-4">
      <h6 className="fsize-20 fweight-600 title-color">
        {changeCurrency.changeCurrency}
      </h6>
      <p className="text-color mb-4">
        {changeCurrency.selectCurrencyStatement}
      </p>
      <Select
        required
        label={changeCurrency.currencyLabel}
        placeholder={changeCurrency.currencyPlaceholder}
        options={currencies?.map(({ Id, Code, Name }) => ({
          id: Id,
          label: `${Name} (${Code})`
        }))}
        onChange={(value) => {
          setSelectedCurrencyTouched(true);
          setSelectedCurrency(value);
        }}
        value={selectedCurrency}
        hasError={!!(selectedCurrencyTouched && !selectedCurrency)}
        errMsg={"changeCurrencyFieldRequired"}
        id="currency"
        name="currency"
      />
    </div>
  );

  return (
    <div>
      {confirmModalOpen && (
        <Modal
          open={confirmModalOpen}
          title
          isContentText={false}
          confirmBtnTxt={shared.upgrade}
          cancelBtnTxt={shared.continue}
          content={renderConfirmModalContent()}
          modalClass={"change-currency-modal"}
          cancelBtnClass={"continue-primary-btn"}
          handleClose={() => {
            setConfirmModalOpen(false);
            onClose();
          }}
          handleCancel={() => {
            setSelectModalOpen(true);
            setConfirmModalOpen(false);
          }}
          handleConfirm={() => {
            navigate(ROUTE_PATHS.pricingDetails, {
              state: {
                isUpgrade: true
              }
            });
          }}
        />
      )}
      {selectModalOpen && (
        <Modal
          open={selectModalOpen}
          title
          isContentText={false}
          confirmBtnTxt={shared.change}
          cancelBtnTxt={shared.cancel}
          content={renderSelectModalContent()}
          handleClose={() => {
            setSelectModalOpen(false);
            onClose();
          }}
          handleCancel={() => {
            setSelectModalOpen(false);
            onClose();
          }}
          handleConfirm={handleChangeCurrency}
          disableConfirmBtn={!selectedCurrency}
        />
      )}
    </div>
  );
};

export default ChangeCurrency;

ChangeCurrency.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
