import PropTypes from "prop-types";
import ReactInfiniteScroll from "react-infinite-scroll-component";

const InfiniteScroll = ({
  data,
  dataLength,
  nextFunction,
  hasMore,
  className,
  endMessage,
  containerHeight
}) => (
  <div
    className={`infinite-scroll-container ${className} scroll-within row px-2`}
    style={{
      height: containerHeight || "100%"
    }}
    id="scrollableModal">
    <ReactInfiniteScroll
      className="row"
      scrollableTarget="scrollableModal"
      dataLength={dataLength}
      next={nextFunction}
      hasMore={hasMore}
      endMessage={endMessage}
      style={{ overflow: "inherit" }}>
      {data}
    </ReactInfiniteScroll>
  </div>
);

export default InfiniteScroll;

InfiniteScroll.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
  dataLength: PropTypes.number.isRequired,
  nextFunction: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  endMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  containerHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};
