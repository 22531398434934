import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import { DeactivateAccountIcon } from "../../../utils/Icons";
import {
  deactivateExporterAccountRequest,
  deactivateImporterAccountRequest
} from "../../../store/Settings/actions";
import { isExporterRole } from "../../../utils/Helpers";
import { getDeviceId } from "../../../utils/Auth";
import { USER_STATUS_IDS } from "../../../utils/Constants";

const DeactivateAccount = ({ open, closeModal }) => {
  const lang = useSelector((state) => state.locale.lang);
  const loggedInUserId = useSelector((state) => state.auth.login.Id);
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    settings: {
      deactivateExporterAccount,
      deactivateImporterAccount
    },
    shared
  } = messages[lang];

  const handleConfirmDeactivate = () => {
    dispatch(
      isExporterRole()
        ? deactivateExporterAccountRequest({
            logoutParams: {
              device_id: getDeviceId(),
              navigate
            }
          })
        : deactivateImporterAccountRequest({
            params: { userId: loggedInUserId },
            logoutParams: {
              device_id: getDeviceId(),
              navigate
            }
          })
    );
    closeModal();
  };

  const deactivateExporterAccountContent = () => {
    return (
      <div className="deactivate-account-container container d-flex flex-column justify-content-center align-items-center">
        <DeactivateAccountIcon />
        <div className="deactivate-account-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {deactivateExporterAccount.title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {isExporterRole(userCurrentRole, userType) &&
            userStatusId === USER_STATUS_IDS.subscribed
              ? deactivateExporterAccount.subscribedExporterContent
              : deactivateExporterAccount.content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deactivateExporterAccount.confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };
  const deactivateImporterAccountContent = () => {
    return (
      <div className="deactivate-account-container container d-flex flex-column justify-content-center align-items-center">
        <DeactivateAccountIcon />
        <div className="deactivate-account-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {deactivateImporterAccount.title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deactivateImporterAccount.content}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmDeactivate();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={
        isExporterRole()
          ? deactivateExporterAccountContent()
          : deactivateImporterAccountContent()
      }
      hasActions={true}
      confirmBtnTxt={shared.deactivate}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"sm"}
    />
  );
};

export default DeactivateAccount;

DeactivateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
