import { put, call, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/categories";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getCategoryDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getCategoryDetails, payload);
    yield put(actions.getCategoryDetailsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* suggestNewCategorySaga({ payload }) {
  try {
    const response = yield call(
      apis.suggestNewCategory,
      payload.data
    );
    yield put(showSuccessMsg("suggestNewCategorySuccess"));
    yield put(
      actions.setSuggestedCategoryTempId(response.data.Data.Id)
    );
    yield put(actions.setCategorySuggestionModalOpen(false));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* categoriesDropdownSaga() {
  try {
    const response = yield call(apis.categoryDropdown);
    yield put(
      actions.getCategoryDropdownResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* CategoriesSagas() {
  yield takeLatest(
    types.GET_CATEGORY_DETAILS_REQUEST,
    getCategoryDetailsSaga
  );
  yield takeLatest(
    types.SUGGEST_NEW_CATEGORY_REQUEST,
    suggestNewCategorySaga
  );
  yield takeLatest(
    types.GET_CATEGORY_DROPDOWN_REQUEST,
    categoriesDropdownSaga
  );
}
