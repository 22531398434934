export const RecordVoiceActiveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    fill="none"
    viewBox="0 0 34 35"
    {...props}>
    <circle cx="17" cy="17.829" r="17" fill="#21A1F6"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17 20.745a3.332 3.332 0 003.334-3.333v-4.583a3.332 3.332 0 10-6.667 0v4.583A3.332 3.332 0 0017 20.745z"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.625 15.87v1.417A6.38 6.38 0 0017 23.662a6.38 6.38 0 006.375-6.375V15.87M15.842 13.187a3.356 3.356 0 012.316 0M16.333 14.954c.442-.117.9-.117 1.342 0M17 23.662v2.5"></path>
  </svg>
);
