const exporters = {
  breadCrumbs: {
    home: "Home",
    exportersList: "Exporters"
  },
  mainInfo: {
    companyInfo: "Company Info",
    country: "Country",
    categories: "Categories",
    businessType: "Business Type",
    yearEstablished: "Year Established",
    yearsOfExperience: "Years of exporting experience",
    notProvided: "Not Provided",
    subcategories: "Subcategories"
  },
  contactDetails: {
    contactInfo: "Contact Info",
    exporterName: "Exporter Name",
    mobileNumber: "Mobile Number",
    emailAddress: "Email Address",
    website: "Website",
    sorry: "Sorry !",
    needToSignin:
      "You need to sign in to see exporter contact details",
    signin: "Sign In"
  },
  showcases: "Showcases",
  piece: "Piece",
  seeAllProducts: "See All Products",
  list: {
    filters: "Filters",
    breadcrumbs: {
      home: "Home"
    },
    exporters: "Exporters",
    exportersFound: "Exporters Found",
    items: "Items",
    noExportersEmptyPlaceholder: "No Exporters Found",
    filter: {
      countrySearchPLaceholder: "Search by country Name",
      country: "Country",
      categorySearchPLaceholder: "Search by category Name",
      categories: "Categories",
      sortBy: "Sort by:",
      mostPopular: "Most Popular",
      alphabetically: "Alphabetically",
      verificationStatus: "Verification Status",
      verified: "Verified",
      unverified: "Unverified",
      both: "Both"
    }
  }
};

export default exporters;
