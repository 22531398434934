export const BankSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25">
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M12.37 2.33l9 3.6c.35.14.63.56.63.93v3.32c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V6.86c0-.37.28-.79.63-.93l9-3.6c.2-.08.54-.08.74 0zM22 22.18H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v3zM4 18.18v-7M8 18.18v-7M12 18.18v-7M16 18.18v-7M20 18.18v-7M1 22.18h22"></path>
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M12 8.68a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
  </svg>
);
