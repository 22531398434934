import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { MaxShowcasesIcon } from "../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const MaxShowcasesAvailableModal = ({ open, closeModal }) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    company: {
      maxShowcasesCountModal: { content, confirmationContent }
    },
    shared
  } = messages[lang];

  const navigate = useNavigate();

  const handleConfirmUpgrade = () => {
    navigate(ROUTE_PATHS.pricingDetails, {
      state: {
        isUpgrade: true
      }
    });
    closeModal();
  };

  const maxShowcasesCountContent = () => {
    return (
      <div className="publish-product-container container d-flex flex-column justify-content-center align-items-center">
        <MaxShowcasesIcon />
        <div className="publish-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmUpgrade();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={maxShowcasesCountContent()}
      hasActions={true}
      confirmBtnTxt={shared.upgrade}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default MaxShowcasesAvailableModal;

MaxShowcasesAvailableModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
