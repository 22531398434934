export const GET_EXPORTER_PROFILE_REQUEST =
  "GET_EXPORTER_PROFILE_REQUEST";
export const GET_EXPORTER_PROFILE_RESPONSE =
  "GET_EXPORTER_PROFILE_RESPONSE";
export const GET_EXPORTER_PROFILE_TRANSACTIONS_REQUEST =
  "GET_EXPORTER_PROFILE_TRANSACTIONS_REQUEST";
export const GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE =
  "GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE";
export const EDIT_EXPORTER_PROFILE_DETAILS_REQUEST =
  "EDIT_EXPORTER_PROFILE_DETAILS_REQUEST";
export const EDIT_IMPORTER_PROFILE_DETAILS_REQUEST =
  "EDIT_IMPORTER_PROFILE_DETAILS_REQUEST";
