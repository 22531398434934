import { axiosInstanceExporters, axiosInstanceImporters } from ".";

const getSubCategoriesList = async (params) =>
  await axiosInstanceExporters.get(`/api/SubCategory/Get`, {
    params,
    paramsSerializer: { indexes: null }
  });

const getCategoriesList = async () =>
  await axiosInstanceExporters.get("/api/Category/Get");

const getMoqList = async () =>
  await axiosInstanceExporters.get("/api/MoqUnit/Get");

const getSpecificationsList = async ({ id }) =>
  await axiosInstanceExporters.get(
    `/api/SubCategorySpecification/Get/${id}`
  );

const getCountriesList = async () =>
  await axiosInstanceImporters.get(`/api/Country/Get`);

const getCurrenciesList = async () =>
  await axiosInstanceExporters.get(`/api/Currency/Get`);

const getProductRequestStatusList = async () =>
  await axiosInstanceImporters.get(`/api/ProductRequest/GetStatus`);

const getSubAccountsList = async () =>
  await axiosInstanceExporters.get("/api/sub-accounts/Get");

export {
  getSubCategoriesList,
  getCategoriesList,
  getMoqList,
  getSpecificationsList,
  getCountriesList,
  getCurrenciesList,
  getProductRequestStatusList,
  getSubAccountsList
};
