import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import Button from "../../Button";

const SortList = ({
  options,
  onSelectOption,
  defaultOptionIndex,
  selectedOption
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    defaultOptionIndex || 0
  );

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedOption) {
      const index = options.findIndex(
        (option) => option.id === selectedOption.id
      );
      setSelectedIndex(index);
    }
  }, [selectedOption]);

  const handleClickSortButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onSelectOption(options[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="sort-list-container">
      <div className="sort-button d-flex align-items-center">
        <p className="fsize-16 mb-0 me-2">{shared.sortBy}:</p>
        <Button
          label={
            <>
              <span className="fsize-14">
                {options[selectedIndex].label}
              </span>
              <ExpandMore />
            </>
          }
          onClick={handleClickSortButton}
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
        />
      </div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox"
        }}>
        {options.map((option, index) => (
          <MenuItem
            key={`sort-item-${index}`}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SortList;

SortList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  defaultOptionIndex: PropTypes.number,
  selectedOption: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  })
};
