import { useState } from "react";
import { useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import { HashLink } from "react-router-hash-link";

import messages from "../../../assets/locale/messages";
import whiteLogo from "../../../assets/images/white-logo.svg";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  WhitePhoneIcon,
  WhiteEnvelopeIcon
} from "../../../utils/Icons";
import {
  EXGATES_CONTACT_EMAIL,
  EXGATES_CONTACT_PHONE,
  USER_TYPES_IDS
} from "../../../utils/Constants";
import Button from "../../Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./Footer.scss";

const Footer = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    home: { footer }
  } = messages[lang];

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const [email, setEmail] = useState(null);

  const subCategories = [
    "Building & Construction",
    "Food & Beverages",
    "Machinery",
    "Minerals & Metallurgy",
    "Rubber & Plastics",
    "Packaging & Printing"
  ];

  const quickLinks = [
    { label: footer.exploreProducts, link: "" },
    { label: footer.exporters, link: ROUTE_PATHS.exporters },
    userType !== USER_TYPES_IDS.importer
      ? { label: footer.pricing, link: ROUTE_PATHS.pricingDetails }
      : null,
    {
      label: footer.productRequests,
      link: ROUTE_PATHS.websiteProductRequests
    }
  ];
  return (
    <div className="footer-section-container dark-blue-bg p-lg-5 p-3">
      <div className="row">
        <div className="col-xl-3 col-lg-6 mb-lg-0 mb-5">
          <div className="contact">
            <img src={whiteLogo} className="mb-2" />
            <p className="text-white">{footer.description}</p>
            <div className="social-media d-flex justify-content-between w-40">
              <FacebookIcon />
              <InstagramIcon />
              <TwitterIcon />
              <LinkedinIcon />
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 mb-xl-0 mb-lg-4 mb-md-0 mb-4">
          <div className="top-sub-categories px-2">
            <h6 className="text-white fweight-700 fsize-16 mb-3">
              {footer.topSubCategories}
            </h6>
            {subCategories?.map((subCategory, i) => (
              <p className="text-white" key={`sub-category-${i}`}>
                {subCategory}
              </p>
            ))}
          </div>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 mb-md-0 mb-4">
          <div className="quick-links px-2">
            <h6 className="text-white fweight-700 fsize-16 mb-3">
              {footer.quickLinks}
            </h6>
            {quickLinks?.map(
              (quickLink, i) =>
                quickLink && (
                  <HashLink
                    key={`quick-link-${i}`}
                    to={quickLink.link}
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "smooth"
                      })
                    }>
                    <p className="text-white">{quickLink.label}</p>
                  </HashLink>
                )
            )}
          </div>
        </div>
        <div className="col-xl-2 col-lg-6 col-md-4 mb-md-0 mb-4">
          <div className="contact-us px-2">
            <h6 className="text-white fweight-700 fsize-16 mb-3">
              {footer.contactUs}
            </h6>
            <p className="text-white">
              <WhitePhoneIcon />
              <span className="mx-1">{EXGATES_CONTACT_PHONE}</span>
            </p>
            <p className="text-white">
              <WhiteEnvelopeIcon />
              <span className="mx-1">{EXGATES_CONTACT_EMAIL}</span>
            </p>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 mt-lg-0 mt-md-4 mt-0">
          <h6 className="text-white fweight-700 fsize-16 mb-3">
            {footer.subscribe}
          </h6>
          <p className="text-white">{footer.subscribeSubtitle}</p>
          <div className="subscribe-input-container d-flex justify-content-lg-center">
            <OutlinedInput
              id="subscribe-email"
              name="subscribe-email"
              placeholder={footer.inputPlaceholder}
              variant="outlined"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              label={footer.subscribe}
              className="subscribe-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
