export const AddShowcaseProductIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    fill="none"
    viewBox="0 0 39 39">
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.735 19.314h19M19.235 28.814v-19"></path>
  </svg>
);
