import { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import Tooltip from "../Tooltip";

const TruncateText = ({
  text,
  length,
  showTooltip,
  textClassName,
  linesNo
}) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkIfTruncated = () => {
      if (textRef.current) {
        const { scrollHeight, offsetHeight } = textRef.current;
        setIsTruncated(scrollHeight > offsetHeight);
      }
    };

    checkIfTruncated();
    window.addEventListener("resize", checkIfTruncated);

    return () => {
      window.removeEventListener("resize", checkIfTruncated);
    };
  }, [text]);

  const truncatedText = () => `${text?.slice(0, length - 1)}…`;

  return (
    <Box>
      {linesNo ? (
        showTooltip ? (
          <Tooltip
            title={isTruncated ? text : ""}
            content={
              <span
                className={`${textClassName} truncate-lines-${linesNo}`}
                ref={textRef}>
                {text}
              </span>
            }
          />
        ) : (
          <span
            className={`${textClassName} truncate-lines-${linesNo}`}>
            {text}
          </span>
        )
      ) : text?.length > length ? (
        showTooltip ? (
          <Tooltip
            title={text}
            content={
              <span className={`${textClassName}`}>
                {truncatedText()}
              </span>
            }
          />
        ) : (
          <span className={`${textClassName}`}>
            {truncatedText()}
          </span>
        )
      ) : (
        <span className={`${textClassName}`}>{text}</span>
      )}
    </Box>
  );
};

TruncateText.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
  showTooltip: PropTypes.bool,
  textClassName: PropTypes.string,
  linesNo: PropTypes.number
};

TruncateText.defaultProps = {
  length: 25,
  showTooltip: true
};
export default TruncateText;
