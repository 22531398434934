import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TextField, FormHelperText, InputLabel } from "@mui/material";

import messages from "../../../assets/locale/messages";
import {
  acceptedDocumentFilesTypes,
  acceptedImagesFilesTypes,
  acceptedPdfMaxSize,
  acceptedImagesFilesMaxSize
} from "../../../utils/Constants";
import ViewUploadedFile from "./ViewUploadedFile";
import "./FileUpload.scss";

const FileUpload = ({
  name,
  value,
  onChange,
  onKeyDown,
  id,
  label,
  fileUploadLabel,
  placeholder,
  required,
  isInputHasErr,
  errMsg,
  disabled,
  fullWidth,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  acceptsImgs,
  fileInfo,
  multiple,
  onDeleteFile,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [fileErrMsg, setFileErrMsg] = useState(null);

  useEffect(() => {
    if (
      multiple &&
      document.getElementById(id || "upload-file-button-id")
    ) {
      document.getElementById(
        id || "upload-file-button-id"
      ).multiple = true;
    }
  }, [multiple]);

  const validateFile = (file) => {
    let { type, size } = file;
    if (acceptsImgs) {
      if (
        ![
          ...acceptedDocumentFilesTypes,
          ...acceptedImagesFilesTypes
        ].includes(type)
      ) {
        setFileErrMsg(inputsValidations.pdfAndImgsAllowed);
        return false;
      } else if (
        size > acceptedPdfMaxSize &&
        size > acceptedImagesFilesMaxSize
      ) {
        setFileErrMsg(inputsValidations.uploadFileSizeError);
        return false;
      } else {
        setFileErrMsg(null);
      }
    } else {
      if (!acceptedDocumentFilesTypes.includes(type)) {
        setFileErrMsg(inputsValidations.invalidFileType);
        return false;
      } else if (size > acceptedPdfMaxSize) {
        setFileErrMsg(inputsValidations.uploadFileSizeError);
        return false;
      } else {
        setFileErrMsg(null);
      }
    }
    return true;
  };

  const validateFiles = (files) => {
    for (const file of files) {
      return validateFile(file);
    }
  };

  return (
    <div className={`file-input-wrapper ${inputWrapperClass}`}>
      <div className="d-none">
        <TextField
          id={id || "upload-file-button-id"}
          name={name}
          sx={{
            "& fieldset": { border: "none" }
          }}
          type={"file"}
          onChange={(e) => {
            if (multiple) {
              const validFiles = validateFiles(e.target.files);
              if (validFiles) {
                onChange(e.target.files);
              }
            } else {
              const validFile = validateFile(e.target.files[0]);
              if (validFile) {
                onChange(e.target.files[0]);
              }
            }
            setTimeout(() => {
              e.target.value = null;
            }, 100);
          }}
          required={required}
          disabled={disabled}
          error={isInputHasErr}
          fullWidth={fullWidth}
          className={`${inputClass} file-input`}
          disableunderline="true"
          autoComplete="off"
          {...props}
        />
      </div>
      {multiple ? (
        <>
          <div className="upload-file-button-container">
            <p
              className={`fsize-13 fweight-300 title-color mb-2 ${labelClassName}`}>
              {label}
            </p>
            <div className="d-flex align-items-center">
              <InputLabel
                className={`upload-file-button ${disabled && "disabled"}`}
                htmlFor={id || "upload-file-button-id"}>
                <span className="fsize-14 fweight-500 text-light-grey">
                  {fileUploadLabel}
                </span>
              </InputLabel>
              <p className="mb-0 ms-2 medium-text-color fsize-13">
                {placeholder}
              </p>
            </div>
          </div>
          <FormHelperText
            error={true}
            className={`${helperTextClass || "fsize-12"} ${
              lang === "en" ? "" : "text-end"
            } ${fileErrMsg && "ps-3"}`}>
            {fileErrMsg
              ? fileErrMsg
              : isInputHasErr
                ? inputsValidations[errMsg]
                : helperText}
          </FormHelperText>
          <div className="viewed-files row mt-3">
            {value?.map((singleFile, index) => (
              <div
                className="col-12 col-xl-6 mb-3"
                key={`view-upload-file-${index}`}>
                <ViewUploadedFile
                  file={singleFile}
                  type={fileInfo[index]?.type}
                  name={fileInfo[index]?.name}
                  onDelete={() => onDeleteFile(index)}
                  isFileLink={typeof singleFile === "string"}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {value ? (
            <ViewUploadedFile
              file={value}
              type={fileInfo?.type}
              name={fileInfo?.name}
              onDelete={() => onChange(null)}
              isFileLink={typeof value === "string"}
            />
          ) : (
            <>
              <div className="upload-file-button-container">
                <p
                  className={`fsize-13 fweight-300 title-color mb-2 ${labelClassName}`}>
                  {label}
                </p>
                <div className="d-flex align-items-center">
                  <InputLabel
                    className={`upload-file-button ${disabled && "disabled"}`}
                    htmlFor={id || "upload-file-button-id"}>
                    <span className="fsize-14 fweight-500 text-light-grey">
                      {fileUploadLabel}
                    </span>
                  </InputLabel>
                  <p className="mb-0 ms-2 medium-text-color fsize-13">
                    {placeholder}
                  </p>
                </div>
              </div>
              <FormHelperText
                error={true}
                className={`${helperTextClass || "fsize-12"} ${
                  lang === "en" ? "" : "text-end"
                } ${fileErrMsg && "ps-3"}`}>
                {fileErrMsg
                  ? fileErrMsg
                  : isInputHasErr
                    ? inputsValidations[errMsg]
                    : helperText}
              </FormHelperText>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  fileInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fileUploadLabel: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  acceptsImgs: PropTypes.bool,
  multiple: PropTypes.bool,
  onDeleteFile: PropTypes.func
};

FileUpload.defaultProps = {
  fullWidth: true,
  value: ""
};
