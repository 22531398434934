const upload = {
  mainText: "drag and drop an image, or ",
  mainVideoText: "drag and drop a video, or ",
  mainTextHighlighted: "Browse",
  uploadMore: "Upload More Images",
  uploadCatalogue: "Upload Catalogue",
  browseCatalogue: "Browse Catalogue",
  validationText: "Bmp,PNG,JPG or JPEG file size no more than 2MB",
  cropImg: "Crop Image",
  uploadImgText: "Click here to upload an image",
  uploadImg: "Upload an image"
};

export default upload;
