import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import giftImg from "../../../../assets/images/home/deals/gift.svg";
import Button from "../../../../components/Button";
import {
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS,
  SUBSCRIPTION_PLANS_IDS
} from "../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const PackageCard = ({
  img,
  title,
  description,
  price,
  currency,
  isPopular,
  isCurrentPlan
}) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];

  return (
    <div
      onClick={() => {
        navigate(ROUTE_PATHS.pricingDetails);
      }}
      className={`package-card pointer faint-grey-bg border-radius-10 p-xl-5 p-lg-3 p-4 mb-lg-0 mb-4 position-relative box-shadow-hover ${isPopular && "popular"}`}>
      {isPopular && (
        <div className="most-popular fsize-10 fweight-700 dark-blue-bg text-white border-radius-4 py-2 px-3 position-absolute">
          {isCurrentPlan
            ? home.packages.currentPlan
            : home.packages.mostPopular}
        </div>
      )}
      <div className="mb-2">
        <img
          src={`data:image/svg+xml;utf8,${encodeURIComponent(img)}`}
        />
      </div>
      <h3 className="package-text text-dark-blue mb-2 fweight-700 fsize-20">
        {title}
      </h3>
      <p className="package-text description mb-3 text-grey">
        {description}
      </p>
      <p className="package-text mb-2 title-color">
        <span className="fweight-700 fsize-24">
          {currency === CURRENCIES_IDS.euro
            ? CURRENCIES_SYMBOLS.euro
            : CURRENCIES_SYMBOLS.usd}
          {price}
        </span>
        <span className="fweight-500 fsize-17">
          / {home.packages.year}
        </span>
      </p>
    </div>
  );
};

PackageCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  currency: PropTypes.number.isRequired,
  isPopular: PropTypes.bool,
  isCurrentPlan: PropTypes.bool
};

const PackagesSection = ({ plans }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  return (
    <div className="packages-section-container">
      <div
        className="big-news-section gradient-bg pt-3 pb-5 d-flex justify-content-center"
        id="big-news-section">
        <div className="pb-5 d-flex align-items-center justify-content-center w-lg-50 w-md-75 w-100 px-lg-0 px-4">
          <img src={giftImg} className="d-none d-md-block" />
          <div className="title-section">
            <h3 className="fsize-28 fweight-700 text-dark-blue">
              {home.packages.bigNews}
            </h3>
            <p className="fsize-18 fweight-400 text-grey">
              {home.packages.subtitle}
            </p>
            <Link to={ROUTE_PATHS.pricingDetails}>
              <Button label={home.packages.exploreNow} outlined />
            </Link>
          </div>
        </div>
      </div>
      <Box
        className="packages-section d-flex align-items-center justify-content-md-around justify-content-center flex-wrap"
        sx={{
          width: {
            xs: "100%",
            md: "80%",
            margin: "0 auto"
          }
        }}>
        {plans?.map(
          ({ Id, Icon, Name, Description, Price, CurrencyId }, i) => (
            <Box
              key={`package-${i}`}
              className=" mb-3"
              sx={{
                width: {
                  xs: "80%",
                  sm: "32%"
                }
              }}>
              <PackageCard
                img={Icon}
                title={Name}
                description={Description}
                price={Price}
                currency={CurrencyId}
                isPopular={
                  userPlan?.Id
                    ? Id === userPlan?.Id
                    : Id === SUBSCRIPTION_PLANS_IDS.standard
                }
                isCurrentPlan={!!userPlan?.Id}
              />
            </Box>
          )
        )}
      </Box>
    </div>
  );
};

export default PackagesSection;

PackagesSection.propTypes = {
  plans: PropTypes.array
};
