const successMsgs = {
  changePassword: "Your password has been changed successfully",
  resetPassword: "Password updated successfully",
  emailResendSuccessfully: "The email has been sent successfully",
  deletedSuccessfully: "Successfully deleted",
  addedSuccessfully: "Added successfully",
  publishProductSuccess:
    "The product has been published successfully",
  publishPendingProductSuccess:
    "The product is pending and is currently under review",
  publishEditedProductSuccess:
    "The product is edited and is currently under review",
  unpublishProductSuccess:
    "The product has been unpublished successfully",
  deleteProductSuccess: "The product has been deleted successfully",
  revertEditSuccess:
    "The product edits have been reverted successfully",
  editCompanyDetailsSuccess:
    "The Company details have been edited successfully",
  loginSuccessful: "Logged in successfully.",
  firstTimePublish:
    "Your ExGates profile is live. Let's start your journey to global success together.",
  companyWithShowcasePublish:
    "Your company profile is pending showcase products approval, which regularly takes up to 48 hours",
  deleteShowcaseSuccess: "Showcase has been deleted successfully",
  suggestNewCategorySuccess:
    "The category request has been sent to the admin",
  editProfileDetailsSuccess:
    "The profile Details have been edited successfully",
  deactivateAccountSuccess:
    "The account has been deactivated successfully",
  addProductRequestSuccess:
    "The product request has been added successfully",
  currencyChangedSuccessfully: "Currency updated successfully",
  closeProductRequestSuccess:
    "The product request has been closed successfully",
  productRequestNotificationsUpdated:
    "Your notification setting have been updated.",
  subAccountAddedSuccessfully:
    "Subaccount has been added successfully",
  editSubAccountSuccess: "Sub account has been edited successfully",
  reactivateSubAccountSuccess:
    "Sub account has been reactivated successfully",
  deactivateSubAccountSuccess:
    "Sub account has been deactivated successfully",
  copySuccess: "Voucher code copied successfully",
  automaticRenewalEnabled:
    "Automatic renewal has been enabled successfully",
  automaticRenewalCancelled:
    "Automatic renewal has been cancelled successfully",
  messageSentSuccessfully: "Message sent successfully",
  hideInstructionsBannerSuccess:
    "The instructions PDF is available under Settings. You can download it from there."
};

export default successMsgs;
