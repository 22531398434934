import {
  CHECK_CAN_USER_RESEND_VERIFY_EMAIL_REQUEST,
  CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE,
  FORGET_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_REQUEST,
  REGSITERATION_REQUEST,
  RESEND_VERIFY_EMAIL_REQUEST,
  RESET_PASSWORD_REQUEST,
  SET_DISABLE_RESEND_EMAIL,
  SET_REGISTERATION_FORM_DATA,
  SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL,
  CHANGE_CURRENT_USER_ROLE,
  CHECK_EMAIL_UNIQUE,
  DISABLE_SUB_ACCOUNTS_WARNING_BANNER,
  IS_USER_FORCED_LOGOUT_REQUEST,
  IS_USER_FORCED_LOGOUT_RESPONSE,
  FORCE_LOGOUT_REQUEST,
  CONTACT_US_REQUEST
} from "./types";

export const loginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload
});

export const loginResponse = (payload) => ({
  type: LOGIN_RESPONSE,
  payload
});

export const logoutRequest = (payload) => ({
  type: LOGOUT_REQUEST,
  payload
});

export const forgetPasswordRequest = (payload) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload
});

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload
});

export const setDisableForgetPasswordResendEmail = (payload) => ({
  type: SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL,
  payload
});

export const setRegisterationFormData = (payload) => ({
  type: SET_REGISTERATION_FORM_DATA,
  payload
});

export const registerationRequest = (payload) => ({
  type: REGSITERATION_REQUEST,
  payload
});

export const checkCanUserResendVerifyEmailRequest = (payload) => ({
  type: CHECK_CAN_USER_RESEND_VERIFY_EMAIL_REQUEST,
  payload
});

export const checkCanUserResendVerifyEmailResponse = (payload) => ({
  type: CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE,
  payload
});

export const resendVerifyEmailRequest = (payload) => ({
  type: RESEND_VERIFY_EMAIL_REQUEST,
  payload
});

export const setDisableResendEmail = (payload) => ({
  type: SET_DISABLE_RESEND_EMAIL,
  payload
});

export const changeUserCurrentRoleRequest = (payload) => ({
  type: CHANGE_CURRENT_USER_ROLE,
  payload
});

export const checkEmailUnique = (payload) => ({
  type: CHECK_EMAIL_UNIQUE,
  payload
});

export const disableSubAccountsWarningBanner = () => ({
  type: DISABLE_SUB_ACCOUNTS_WARNING_BANNER
});

export const isUserForcedLogoutRequest = (payload) => ({
  type: IS_USER_FORCED_LOGOUT_REQUEST,
  payload
});

export const isUserForcedLogoutResponse = (payload) => ({
  type: IS_USER_FORCED_LOGOUT_RESPONSE,
  payload
});

export const forceLogoutRequest = (payload) => ({
  type: FORCE_LOGOUT_REQUEST,
  payload
});

export const contactUsRequest = (payload) => ({
  type: CONTACT_US_REQUEST,
  payload
});
