import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import SideMenu from "./SideMenu";
import NavBar from "./NavBar";
import MainSection from "./MainSection";
import Footer from "./Footer";
import { EXPORTER_LAYOUT_DIMENSIONS } from "../../utils/Constants";
import NotificationSnackbar from "../NotificationSnackbar";
import { setExporterLayoutContainerRef } from "../../store/Navigation/actions";
import { getUserStatusRequest } from "../../store/User/actions";
import "./ExporterLayout.scss";

const ExporterLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef();
  const isAuth = useSelector((state) => state.auth.login?.Token);

  const [mobileSideMenuOpen, setMobileSideMenuOpen] = useState(false);

  useEffect(() => {
    containerRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (isAuth) dispatch(getUserStatusRequest());
  }, [location, containerRef]);

  useEffect(() => {
    dispatch(setExporterLayoutContainerRef(containerRef));
  }, [containerRef]);

  const detectMobileSideMenuOpen = (isOpen) => {
    setMobileSideMenuOpen(isOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f6f6f6"
      }}>
      <NavBar
        drawerWidth={EXPORTER_LAYOUT_DIMENSIONS.drawerWidth}
        openMobileSideMenu={detectMobileSideMenuOpen}
        navbarHeight={EXPORTER_LAYOUT_DIMENSIONS.navbarHeight}
      />
      <SideMenu
        closeMobileSideMenu={() => detectMobileSideMenuOpen(false)}
        drawerWidth={EXPORTER_LAYOUT_DIMENSIONS.drawerWidth}
        navbarHeight={EXPORTER_LAYOUT_DIMENSIONS.navbarHeight}
        footerHeight={EXPORTER_LAYOUT_DIMENSIONS.footerHeight}
        mobileSideMenuOpen={mobileSideMenuOpen}
      />
      <MainSection
        drawerWidth={EXPORTER_LAYOUT_DIMENSIONS.drawerWidth}
        navbarHeight={EXPORTER_LAYOUT_DIMENSIONS.navbarHeight}
        footerHeight={EXPORTER_LAYOUT_DIMENSIONS.footerHeight}
        reference={containerRef}>
        <Outlet />
      </MainSection>
      <Footer
        footerHeight={EXPORTER_LAYOUT_DIMENSIONS.footerHeight}
      />
      <NotificationSnackbar />
    </Box>
  );
};

export default ExporterLayout;
