export const WEBSITE_PRODUCT_REQUESTS_LIST_REQUEST =
  "WEBSITE_PRODUCT_REQUESTS_LIST_REQUEST";
export const WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE =
  "WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE";
export const ADD_IMPORTER_PRODUCT_REQUEST_REQUEST =
  "ADD_IMPORTER_PRODUCT_REQUEST_REQUEST";

export const EDIT_IMPORTER_PRODUCT_REQUEST_REQUEST =
  "EDIT_IMPORTER_PRODUCT_REQUEST_REQUEST";

export const GET_IMPORTER_PRODUCT_REQUEST_DETAILS_REQUEST =
  "GET_IMPORTER_PRODUCT_REQUEST_DETAILS_REQUEST";
export const GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE =
  "GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE";

export const IMPORTER_PRODUCT_REQUESTS_LIST_REQUEST =
  "IMPORTER_PRODUCT_REQUESTS_LIST_REQUEST";
export const IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE =
  "IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE";
export const GET_IMPORTER_CONTACT_INFO_REQUEST =
  "GET_IMPORTER_CONTACT_INFO_REQUEST";
export const GET_IMPORTER_CONTACT_INFO_RESPONSE =
  "GET_IMPORTER_CONTACT_INFO_RESPONSE";
export const CLOSE_PRODUCT_REQUEST_REQUEST =
  "CLOSE_PRODUCT_REQUEST_REQUEST";
