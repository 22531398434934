export const WhitePhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    fill="none"
    viewBox="0 0 16 17"
    {...props}>
    <path
      fill="#F1FAFF"
      d="M11.644 16.09c-.754 0-1.547-.18-2.367-.527-.8-.34-1.607-.807-2.393-1.374a20.806 20.806 0 01-2.247-1.913 19.927 19.927 0 01-1.913-2.24c-.574-.8-1.034-1.6-1.36-2.373-.347-.827-.52-1.627-.52-2.38 0-.52.093-1.014.273-1.474a3.56 3.56 0 01.893-1.293c.514-.507 1.1-.76 1.727-.76.26 0 .527.06.753.167.26.12.48.3.64.54l1.547 2.18c.14.193.247.38.32.566.087.2.133.4.133.594 0 .253-.073.5-.213.733-.1.18-.253.373-.447.567l-.453.473c.007.02.013.033.02.047.08.14.24.38.547.74.326.373.633.713.94 1.026.393.387.72.694 1.026.947.38.32.627.48.774.553l-.014.034.487-.48c.207-.207.407-.36.6-.46.367-.227.833-.267 1.3-.074.173.074.36.173.56.313l2.213 1.574c.247.167.427.38.534.633.1.253.146.487.146.72 0 .32-.073.64-.213.94-.14.3-.313.56-.533.8-.38.42-.794.72-1.274.914-.46.186-.96.286-1.486.286zM3.737 2.755c-.367 0-.707.16-1.033.48-.307.287-.52.6-.654.94-.14.347-.206.713-.206 1.107 0 .62.146 1.293.44 1.986.3.707.72 1.44 1.253 2.174.533.733 1.14 1.446 1.807 2.12.666.66 1.386 1.273 2.126 1.813.72.527 1.46.953 2.194 1.26 1.14.487 2.206.6 3.086.233.34-.14.64-.353.914-.66.153-.166.273-.346.373-.56.08-.166.12-.34.12-.513a.796.796 0 00-.073-.333.505.505 0 00-.187-.2l-2.213-1.574a1.918 1.918 0 00-.367-.207c-.147-.06-.207-.12-.433.02a1.521 1.521 0 00-.387.3l-.507.5a.95.95 0 01-.966.2l-.18-.08a5.68 5.68 0 01-.947-.673c-.32-.273-.667-.593-1.087-1.007a23.274 23.274 0 01-.993-1.08c-.313-.366-.54-.68-.68-.94l-.08-.2a1.244 1.244 0 01-.053-.333c0-.24.086-.453.253-.62l.5-.52a1.93 1.93 0 00.3-.373.428.428 0 00.073-.227.62.62 0 00-.053-.213 1.912 1.912 0 00-.213-.353L4.317 3.036a.614.614 0 00-.247-.207.835.835 0 00-.333-.073zm5.573 8.173l-.106.454.18-.467c-.034-.007-.06 0-.074.013z"></path>
  </svg>
);
