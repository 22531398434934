import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";

import { hasPermission } from "../utils/Permissions";
import { ROUTE_PATHS } from "../utils/RoutesPaths";

const AuthorizedRoute = ({ pemissionIds }) => {
  let isAllowed = false;
  pemissionIds.forEach((id) => {
    if (hasPermission(id)) {
      isAllowed = true;
    }
  });
  return isAllowed ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE_PATHS.notFound} />
  );
};

export default AuthorizedRoute;

AuthorizedRoute.propTypes = {
  pemissionIds: PropTypes.array.isRequired
};
