export const IdCardSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="21"
    fill="none"
    viewBox="0 0 25 21">
    <path
      fill="#8F8F8F"
      d="M8.535 10.789c-1.654 0-2.999-1.306-2.999-2.91s1.345-2.91 2.999-2.91c1.654 0 3 1.306 3 2.91s-1.346 2.91-3 2.91zm0-4.001c-.62 0-1.124.49-1.124 1.091S7.915 8.97 8.535 8.97c.62 0 1.125-.49 1.125-1.09 0-.602-.505-1.092-1.125-1.092zm3.742 9.698c-.517 0-.937-.407-.937-.909v-.367c0-.873-.621-1.754-2.01-1.754H7.726c-1.389 0-2.01.88-2.01 1.754v.367c0 .502-.42.91-.937.91-.518 0-.938-.408-.938-.91v-.367c0-1.775 1.334-3.573 3.885-3.573H9.33c2.551 0 3.885 1.797 3.885 3.573v.367c0 .502-.42.91-.938.91zm7.5 4.243h-15C1.755 20.73.09 19.115.09 16.183V5.273C.09 2.34 1.755.727 4.777.727h15c3.023 0 4.688 1.614 4.688 4.546v10.91c0 2.931-1.665 4.546-4.688 4.546zm-15-18.184c-1.97 0-2.812.816-2.812 2.728v10.91c0 1.912.841 2.728 2.812 2.728h15c1.972 0 2.813-.816 2.813-2.728V5.273c0-1.912-.841-2.728-2.813-2.728h-15zm15.938 5.683c0-.502-.42-.909-.938-.909h-5c-.517 0-.937.407-.937.91 0 .501.42.908.937.908h5c.518 0 .938-.407.938-.909zm-.063 4.925c0-.502-.42-.91-.937-.91h-3.75c-.518 0-.938.408-.938.91 0 .501.42.909.938.909h3.75c.517 0 .937-.408.937-.91z"></path>
  </svg>
);
