export const RecordVoiceSendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    fill="none"
    viewBox="0 0 35 35"
    {...props}>
    <circle cx="17.566" cy="17.329" r="17" fill="#21A1F6"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.733 12.595l7.075-2.358c3.175-1.058 4.9.675 3.85 3.85l-2.359 7.075c-1.583 4.758-4.183 4.758-5.766 0l-.7-2.1-2.1-.7c-4.759-1.583-4.759-4.175 0-5.767zM15.991 18.704l2.983-2.992"></path>
  </svg>
);
