import { initializeApp } from "@firebase/app";
import {
  getMessaging,
  getToken,
  onMessage
} from "@firebase/messaging";

import { firebaseConfig } from "./firebaseConfig";
import store from "../store";
import {
  incrementNotificationsCount,
  setPushNotification
} from "../store/Notifications/actions";
import { setFcmToken } from "./helpers";
import { showErrorMsg } from "../utils/Helpers";
import messages from "../assets/locale/messages";
import { showHideSnackbar } from "../store/Snackbar/actions";
import { USER_TYPES_IDS } from "../utils/Constants";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async (userCurrentRole, userType) => {
  const lang = localStorage.getItem("lang");
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Get the FCM token
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      });
      setFcmToken(token);
    } else {
      // To handle requesting permission from the user
      store.dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "info",
          message: messages[lang].shared.askForNotificationsPermission
        })
      );
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      const { notification, data } = payload;
      // if (body || title) {
      store.dispatch(
        setPushNotification({
          show: true,
          title: data?.Title || notification?.title,
          body: data?.Body || notification?.body,
          id: data?.Id,
          clickAction: data?.ClickAction,
          actionId: data?.ActionId,
          userTypeId: data?.UserTypeId
        })
      );
      if (
        userCurrentRole &&
        userType &&
        userType === USER_TYPES_IDS.both
      ) {
        userCurrentRole === data?.UserTypeId
          ? store.dispatch(incrementNotificationsCount())
          : "";
      } else {
        store.dispatch(incrementNotificationsCount());
      }
      // }
    });
  } catch (error) {
    console.log("Firebase setup error", error);
    // To handle informing the user notificaitons are not going to work
    // showErrorMsg(messages[lang].shared.errSettingNotifications);
  }
};
export { messaging, setupNotifications };
