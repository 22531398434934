import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../../../assets/locale/messages";
import {
  EMAIL_REGEX,
  ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
  PASSWORD_REGEX
} from "../../../../../utils/Patterns";
import { USER_TYPES_IDS } from "../../../../../utils/Constants";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import RadioButtonGroup from "../../../../../components/RadioButtonGroup";
import PasswordValidation from "../../../../../components/PasswordValidation";
import {
  checkEmailUnique,
  setRegisterationFormData
} from "../../../../../store/Auth/actions";

const ContactInfo = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [focused, setFocused] = useState(false);

  const lang = useSelector((state) => state.locale.lang);
  const { registeration, shared } = messages[lang];

  const contactFormData = useSelector(
    (state) => state.auth.registeration.formData
  );

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      UserTypeId: USER_TYPES_IDS.exporter,
      Name: "",
      Email: "",
      Password: ""
    },
    validationSchema: Yup.object({
      UserTypeId: Yup.number().required("userTypeRequired"),
      Name: Yup.string()
        .required("fullNameRequired")
        .max(50, "fullNameMaxLength")
        .matches(
          ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
          "fullNameNoSpecialChars"
        ),
      Email: Yup.string()
        .matches(EMAIL_REGEX, "validEmailFormat")
        .required("emailRequired"),
      Password: Yup.string()
        .required("passwordRequired")
        .matches(PASSWORD_REGEX, "invalidPassword")
    }),
    onSubmit: ({ UserTypeId, Name, Email, Password }) => {
      dispatch(
        setRegisterationFormData({
          UserTypeId,
          Name,
          Email,
          Password
        })
      );
      dispatch(
        checkEmailUnique({
          params: { email: Email },
          onSubmit
        })
      );
    }
  });

  useEffect(() => {
    if (contactFormData && Object.keys(contactFormData).length) {
      const { UserTypeId, Name, Email, Password } = contactFormData;
      setValues({ UserTypeId, Name, Email, Password });
    }
  }, [contactFormData]);

  const userTypesOptions = [
    {
      label: registeration.userTypes.exporter,
      value: USER_TYPES_IDS.exporter
    },
    {
      label: registeration.userTypes.importer,
      value: USER_TYPES_IDS.importer
    },
    {
      label: registeration.userTypes.both,
      value: USER_TYPES_IDS.both
    }
  ];

  return (
    <div className="contact-info-section">
      <h3 className="fsize-16 fweight-700 title-color mb-3">
        {registeration.steps.contactInfo}
      </h3>
      <form
        className="register-contact-info-form"
        onSubmit={handleSubmit}
        noValidate>
        <RadioButtonGroup
          name="UserTypeId"
          id="UserTypeId"
          value={values.UserTypeId}
          onChange={(value) => {
            setFieldTouched("UserTypeId");
            setFieldValue("UserTypeId", value);
          }}
          options={userTypesOptions}
          isInputHasErr={!!(touched.UserTypeId && errors.UserTypeId)}
          errMsg={errors.UserTypeId}
          row
          wrapperClassName="mb-4"
          required
        />
        <Input
          required
          label={registeration.contactInfo.labels.fullName}
          placeholder={
            registeration.contactInfo.placeholders.fullName
          }
          onChange={(value) => {
            if (!value || value?.trim()) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("Name");
              setFieldValue("Name", modifiedValue);
            }
          }}
          value={values.Name}
          isInputHasErr={!!(touched.Name && errors.Name)}
          errMsg={errors.Name}
          name="Name"
          id="Name"
          inputClass="mb-1"
          inputWrapperClass="mb-4"
        />
        <Input
          id="Email"
          label={registeration.contactInfo.labels.email}
          name="Email"
          type="Email"
          placeholder={registeration.contactInfo.placeholders.email}
          required
          onChange={(value) => {
            if (!value || value?.trim()) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("Email");
              setFieldValue("Email", modifiedValue.trim());
            }
          }}
          value={values.Email}
          inputClass="mb-1"
          inputWrapperClass="mb-4"
          isInputHasErr={!!(touched.Email && errors.Email)}
          errMsg={errors.Email}
        />
        <Input
          id="Password"
          label={registeration.contactInfo.labels.password}
          name="Password"
          type="password"
          placeholder={
            registeration.contactInfo.placeholders.password
          }
          required
          onChange={(value) => {
            if (!value || value?.trim()) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("Password");
              setFieldValue("Password", modifiedValue);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={values.Password}
          inputClass="mb-1"
          inputWrapperClass="mb-4"
          isInputHasErr={!!(touched.Password && errors.Password)}
          errMsg={errors.Password}
        />
        {focused && (
          <PasswordValidation
            passwordValue={values.Password}
            wrapperClass="my-2"
          />
        )}
        <div className="actions-section text-end">
          <Button
            label={shared.next}
            onClick={handleSubmit}
            disabled={!dirty || !isValid}
            customSpacing={"px-5 py-2"}
            onMouseDown={(e) => e.preventDefault()}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactInfo;

ContactInfo.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
