import { put, call, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/subCategories";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getSubCategoriesProductsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSubCategoriesProductsList,
      payload
    );
    const responseData = response.data.Data;
    yield put(
      actions.getSubCategoriesProductsListResponse({
        list: responseData
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* getAllSubCategoriesProductsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.getAllSubCategoriesProductsList,
      payload
    );
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getAllSubCategoriesProductsListResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
    if (
      +CurrentPage !== 0 &&
      +TotalPages !== 0 &&
      +CurrentPage > +TotalPages
    ) {
      yield put(
        actions.getAllSubCategoriesProductsListRequest({
          ...payload,
          params: {
            ...payload.params,
            page: +TotalPages
          }
        })
      );
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSubCategoryProductDetailsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSubCategoryProductDetails,
      payload
    );
    yield put(
      actions.getSubCategoryProductDetailsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getProductsRecommendationsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getProductsRecommendations,
      payload
    );
    yield put(
      actions.getProductsRecommendationsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* SubCategoriesProductsSagas() {
  yield takeLatest(
    types.GET_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
    getSubCategoriesProductsListSaga
  );
  yield takeLatest(
    types.GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
    getAllSubCategoriesProductsListSaga
  );
  yield takeLatest(
    types.GET_SUB_CATEGORY_PRODUCT_DETAILS_REQUEST,
    getSubCategoryProductDetailsSaga
  );
  yield takeLatest(
    types.GET_PRODUCTS_RECOMMENDATIONS_REQUEST,
    getProductsRecommendationsSaga
  );
}
