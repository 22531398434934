import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from "@mui/material";
import { Add, Check, ExpandMore, Remove } from "@mui/icons-material";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import messages from "../../../assets/locale/messages";
import verifyImg from "../../../assets/images/vectors/verify.png";
import Breadcrumbs from "../../../components/Breadcrumbs";
import visaImg from "../../../assets/images/vectors/visa.png";
import masterImg from "../../../assets/images/vectors/mastercard.png";
import amexImg from "../../../assets/images/vectors/amex.png";
import discoverImg from "../../../assets/images/vectors/discover.png";
import jcbImg from "../../../assets/images/vectors/jcb.png";
import dinersImg from "../../../assets/images/vectors/diners.png";
import unionpayImg from "../../../assets/images/vectors/unionpay.png";
import unknowncardImg from "../../../assets/images/vectors/unknowncard.png";
import Button from "../../../components/Button";
import StripeCheckout from "../../../components/Payment/StripeCheckout";
import RequestVoucher from "../../../components/RequestVoucher";
import Switch from "../../../components/Switch";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import FreeSubscription from "./FreeSubscription";
import SubscriptionFailed from "./SubscriptionFailed";
import {
  applyVoucherRequest,
  applyVoucherResponse,
  checkCanRenewSubscriptionRequest,
  createAutomaticRenewalSubscriptionPaymentIntentRequest,
  createAutomaticRenewalSubscriptionPaymentIntentResponse,
  createSubscriptionPaymentIntentRequest,
  createSubscriptionPaymentIntentResponse,
  getSubscriptionFinalFeesRequest,
  getSubscriptionFinalFeesResponse,
  getSubscriptionPlanDetailsRequest,
  getSubscriptionPlanDetailsResponse,
  getUserSavedCardsRequest,
  setSubscriptionPaymentFailureMsg,
  setSubscriptionPaymentIntentId
} from "../../../store/Subscription/actions";
import {
  CURRENCIES_IDS,
  STRIPE_CURRENCIES_NAMES,
  CURRENCIES_SYMBOLS,
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  USER_STATUS_IDS,
  generalImporterPagesPadding,
  SUBSCRIPTION_PAYMENT_TYPE
} from "../../../utils/Constants";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { isExporterRole } from "../../../utils/Helpers";
import "./SubscriptionPayment.scss";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

const SubscriptionPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const stripe = useStripe();

  const lang = useSelector((state) => state.locale.lang);

  const {
    subscription: { breadcrumbs, paymentPage },
    shared
  } = messages[lang];

  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const planDetails = useSelector(
    (state) => state.subscription.planDetails
  );
  const stripeClientSecret = useSelector(
    (state) => state.subscription.stripeClientSecret
  );
  const paymentFailureMsg = useSelector(
    (state) => state.subscription.subscriptionFailureMsg
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const voucherData = useSelector(
    (state) => state.subscription.voucherData
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const finalFees = useSelector(
    (state) => state.subscription.subscriptionFinalFees
  );
  const canRenew = useSelector(
    (state) => state.subscription.checkCanRenewSuscription.CanRenew
  );
  const stripeClientSecretAutomaticRenewal = useSelector(
    (state) =>
      state.subscription.automaticRenewalSubscriptionPaymentIntent
        .ClientSecret
  );
  const userSavedCards = useSelector(
    (state) => state.subscription.userSavedCards
  );

  const [planPrice, setPlanPrice] = useState(null);
  const [voucherCode, setVoucherCode] = useState("");
  const [isAutomaticSubscription, setIsAutomaticSubscription] =
    useState(false);
  const [userSelectedCard, setUserSelectedCard] = useState(null);
  const [payWithNewCardOpen, setPayWithNewCardOpen] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getSubscriptionPlanDetailsRequest({ id }));
      if (
        userStatusId === USER_STATUS_IDS.subscribed
          ? userPlan && Object.keys(userPlan).length
          : true
      ) {
        dispatch(
          checkCanRenewSubscriptionRequest({
            params: {
              subscriptionTypeId: id
            },
            navigate,
            route: ROUTE_PATHS.pricingDetails,
            fromPaymentScreen: true
          })
        );
      }
    }
  }, [id, userStatusId, userPlan]);

  useEffect(() => {
    if (id && userPlan && planDetails?.Id > userPlan?.Id) {
      dispatch(
        getSubscriptionFinalFeesRequest({ subscriptionTypeId: id })
      );
    }
  }, [id, userPlan, planDetails]);

  useEffect(() => {
    if (
      [
        USER_STATUS_IDS.businessVerified,
        USER_STATUS_IDS.subscribed,
        USER_STATUS_IDS.subscriptionExpired
      ].includes(userStatusId)
    ) {
      dispatch(getUserSavedCardsRequest());
    }
  }, [userStatusId]);

  useEffect(() => {
    if (planDetails && Object.keys(planDetails)?.length) {
      setPlanPrice(
        planDetails?.Features?.find(
          (feature) =>
            feature.Id === SUBSCRIPTION_PLAN_FEATURES_IDS.price
        )
      );
      if (
        [
          USER_STATUS_IDS.businessVerified,
          USER_STATUS_IDS.subscribed,
          USER_STATUS_IDS.subscriptionExpired
        ].includes(userStatusId) &&
        canRenew
      ) {
        if (isAutomaticSubscription) {
          dispatch(
            createAutomaticRenewalSubscriptionPaymentIntentRequest({
              PriceId: planDetails?.PriceId,
              SubscriptionTypeId: planDetails?.Id,
              Code:
                voucherData && !!voucherData.voucherPercent
                  ? voucherCode
                  : null
            })
          );
          dispatch(createSubscriptionPaymentIntentResponse(null));
        } else {
          dispatch(
            createSubscriptionPaymentIntentRequest({
              subscriptionTypeId: planDetails?.Id,
              code:
                voucherData && !!voucherData.voucherPercent
                  ? voucherCode
                  : null
            })
          );
          dispatch(
            createAutomaticRenewalSubscriptionPaymentIntentResponse({
              ClientSecret: null,
              SubscriptionId: null
            })
          );
        }
      }
    }
  }, [
    planDetails,
    userStatusId,
    voucherCode,
    canRenew,
    voucherData,
    isAutomaticSubscription,
    userPlan
  ]);

  useEffect(() => {
    if (userPlan?.IsAutomaticSubscription) {
      setIsAutomaticSubscription(true);
    }
  }, [userPlan?.IsAutomaticSubscription]);

  useEffect(() => {
    if (userType && userCurrentRole) {
      !isExporterRole(userCurrentRole, userType) &&
        navigate(ROUTE_PATHS.home);
    }
  }, [userType, userCurrentRole]);

  useEffect(() => {
    if (userSavedCards && userSavedCards?.length) {
      setUserSelectedCard(
        userSavedCards?.find((card) => card?.IsDefault)
          ?.PaymentMethodId
      );
    }
  }, [userSavedCards]);

  const handleCloseModal = () => {
    dispatch(setSubscriptionPaymentFailureMsg(null));
  };

  useEffect(() => {
    return () => {
      dispatch(getSubscriptionPlanDetailsResponse({}));
      dispatch(getSubscriptionFinalFeesResponse({}));
      dispatch(
        applyVoucherResponse({
          totalAfterDiscount: 0,
          voucherPercent: 0
        })
      );
    };
  }, []);

  const cardsLogos = {
    visa: visaImg,
    mastercard: masterImg,
    amex: amexImg,
    discover: discoverImg,
    jcb: jcbImg,
    diners: dinersImg,
    unionpay: unionpayImg,
    unknown: unknowncardImg
  };

  const handlePaymentWithSavedCard = async () => {
    if (
      !userSelectedCard ||
      (!stripeClientSecretAutomaticRenewal && !stripeClientSecret)
    )
      return;
    try {
      const { error, paymentIntent } =
        await stripe.confirmCardPayment(
          isAutomaticSubscription
            ? stripeClientSecretAutomaticRenewal
            : stripeClientSecret,
          {
            payment_method: userSelectedCard
          }
        );
      if (error) {
        dispatch(setSubscriptionPaymentFailureMsg(error.message));
      } else if (paymentIntent.status === "succeeded") {
        const cardInfo = userSavedCards?.find(
          (card) => card?.PaymentMethodId === userSelectedCard
        )?.Last4;
        navigate(
          `${ROUTE_PATHS.subscriptionSuccess
            .replace(":id", planDetails?.Id)
            .replace(
              ":type",
              isAutomaticSubscription
                ? SUBSCRIPTION_PAYMENT_TYPE.automatic
                : SUBSCRIPTION_PAYMENT_TYPE.manual
            )}?card_info=${cardInfo}&price_id=${planDetails?.PriceId}&payment_intent=${paymentIntent?.id}`
        );
      }
    } catch (error) {
      dispatch(setSubscriptionPaymentFailureMsg(error.message));
    }
  };

  const renderSavedCard = (card) => (
    <div className="light-border border-radius-10 py-3 px-4">
      <div className="d-flex align-items-center flex-column flex-md-row">
        <img
          src={cardsLogos[card?.Brand] || unknowncardImg}
          className="me-md-3 me-0 mb-3 mb-md-0 "
        />
        <div>
          <p className="text-color fsize-15 mb-0">{`**** **** **** ${card?.Last4}`}</p>
          <p className="medium-text-color fsize-13 mb-0">
            {`${paymentPage.savedCards.cardExpiresAt} ${card?.Expr}`}
          </p>
        </div>
      </div>
    </div>
  );

  const renderPayWithNewCardSection = () => (
    <div className="pay-with-new-card-content mt-4">
      <p
        className="fsize-16 fweight-600 text-primary pointer mb-1"
        onClick={() => {
          if (payWithNewCardOpen) {
            setPayWithNewCardOpen(false);
            setUserSelectedCard(
              userSavedCards?.find((card) => card?.IsDefault)
                ?.PaymentMethodId
            );
          } else {
            setPayWithNewCardOpen(true);
            setUserSelectedCard(null);
          }
        }}>
        {payWithNewCardOpen ? (
          <Remove color="primary" className="me-3" />
        ) : (
          <Add color="primary" className="me-3" />
        )}
        {paymentPage.savedCards.payWithNewCard}
      </p>
      {payWithNewCardOpen && (
        <p className="text-color">
          {paymentPage.savedCards.payWithNewCardDescription}
        </p>
      )}
      {payWithNewCardOpen && (
        <StripeCheckout
          isAutomaticRenewal={isAutomaticSubscription}
          isSubscription={true}
          clientSecret={
            isAutomaticSubscription
              ? stripeClientSecretAutomaticRenewal
              : stripeClientSecret
          }
          setPaymentIntentFunc={setSubscriptionPaymentIntentId}
          setPaymentFailureMsgFunc={setSubscriptionPaymentFailureMsg}
          redirectUrl={ROUTE_PATHS.subscriptionSuccess
            .replace(":id", planDetails?.Id)
            .replace(
              ":type",
              isAutomaticSubscription
                ? SUBSCRIPTION_PAYMENT_TYPE.automatic
                : SUBSCRIPTION_PAYMENT_TYPE.manual
            )}
          paymentInfo={{
            amount:
              voucherData && !!voucherData.totalAfterDiscount
                ? voucherData.totalAfterDiscount
                : finalFees?.FinalFees || planPrice?.Value,
            currency:
              planPrice?.CurrencyId === CURRENCIES_IDS.usd
                ? STRIPE_CURRENCIES_NAMES.usd
                : STRIPE_CURRENCIES_NAMES.euro
          }}
        />
      )}
    </div>
  );

  return (
    <div
      className={`pricing-details-page container-xxl ${generalImporterPagesPadding}`}>
      <Breadcrumbs
        list={[
          {
            label: breadcrumbs.home,
            link: ROUTE_PATHS.home
          },
          {
            label: breadcrumbs.pricing,
            link: ROUTE_PATHS.pricingDetails
          },
          {
            label: breadcrumbs.payment
          }
        ]}
      />
      <h3 className="title-color fsize-22 fweight-700 mb-3">
        {paymentPage.paymentInfo}
      </h3>
      <Box
        className="payment-container"
        sx={{
          width: {
            xs: "100%",
            lg: "80%"
          },
          margin: "0 auto"
        }}>
        <div className="plan-details mb-3">
          <Accordion className="light-primary-bg border-radius-10">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-plan-details-content`}
              id={`panel-plan-details-header`}
              sx={{
                alignItems: {
                  xs: "start",
                  md: "center"
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  marginTop: {
                    xs: "2rem",
                    md: 0
                  }
                }
              }}>
              <div className="plan-header d-flex align-items-start align-items-md-center flex-column flex-md-row">
                <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row">
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(planDetails?.Icon)}`}
                    className="me-3 mb-md-0 mb-3"
                    style={{ width: "3rem" }}
                  />
                  <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-md-0 mb-3">
                    <p className="fsize-16 fweight-600 title-color mb-0 me-2">
                      {paymentPage.selectedPricing}
                    </p>
                    <p className="fsize-16 fweight-600 title-color mb-0">{`${planDetails?.Name} ${paymentPage.plan}`}</p>
                  </div>
                  <Box
                    sx={{
                      width: "2px",
                      height: "25px",
                      backgroundColor: "#C3C3C3",
                      margin: "0 2rem",
                      display: {
                        xs: "none",
                        md: "block"
                      }
                    }}
                  />
                </div>
                <p className="fsize-16 fweight-600 title-color mb-0">
                  <span className="me-2">{paymentPage.price}</span>
                  <span className="text-dark-blue fweight-700">
                    {planPrice?.CurrencyId === CURRENCIES_IDS.euro
                      ? CURRENCIES_SYMBOLS.euro
                      : CURRENCIES_SYMBOLS.usd}
                    {planPrice?.Value}
                  </span>
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p className="fweight-600 text-color">
                  {paymentPage.features}
                </p>
                <div className="row">
                  <div className="col-12 col-md-9">
                    <div className="row">
                      {planDetails?.Features?.filter(
                        (feature) =>
                          feature.Id !==
                          SUBSCRIPTION_PLAN_FEATURES_IDS.price
                      ).map(({ Name, Value }, i) => (
                        <div
                          key={`plan-feature-${i}`}
                          className="col-12 col-md-6">
                          <p className="light-text-color fsize-12 plan-text d-flex">
                            <Check
                              className="me-2 plan-text"
                              color="success"
                            />
                            <span>{`${Value} ${Name}`}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          {[
            USER_STATUS_IDS.registered,
            USER_STATUS_IDS.verified,
            USER_STATUS_IDS.pendingBusinessVerified
          ].includes(userStatusId) ? (
            <div className="verify-first light-border border-radius-10 py-5">
              <div className="text-center">
                <img src={verifyImg} className="mb-3" />
                <p className="fsize-22 fweight-600 title-color">
                  {paymentPage.verifyAccount.title}
                </p>
                <p className="text-color">
                  {paymentPage.verifyAccount.message}
                </p>
                <Button
                  label={paymentPage.verifyAccount.verifyNow}
                  onClick={() =>
                    navigate(ROUTE_PATHS.businessVerification)
                  }
                  customSpacing={"px-5 py-2"}
                />
              </div>
            </div>
          ) : (
            <>
              {userPlan &&
                planDetails?.Id > userPlan?.Id &&
                finalFees?.DeductedAmount > 0 && (
                  <div className="light-border border-radius-10 p-4 my-3">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h6
                          className={`fsize-16 fweight-600 title-color mb-4`}>
                          {
                            paymentPage.paymentDetails
                              .upgradePaymentDetails
                          }
                        </h6>
                        <p className="fsize-15 text-color">
                          {paymentPage.paymentDetails.upgradePolicy}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="border-radius-10 grey-bg p-3">
                          <p className="d-flex align-items-center justify-content-between mb-0 pb-3">
                            <span className="text-grey-disabled">{`${planDetails?.Name} ${paymentPage.paymentDetails.planCost}`}</span>
                            <span className="fweight-600 title-color">{`${
                              planPrice?.CurrencyId ===
                              CURRENCIES_IDS.euro
                                ? CURRENCIES_SYMBOLS.euro
                                : CURRENCIES_SYMBOLS.usd
                            }${planPrice?.Value}`}</span>
                          </p>
                          <p className="d-flex align-items-center justify-content-between mb-3 pb-3 light-border-bottom">
                            <span className="text-grey-disabled">{`${userPlan?.Name} ${paymentPage.paymentDetails.remainingBalance}`}</span>
                            <span className="fweight-600 text-green">{`-${
                              planPrice?.CurrencyId ===
                              CURRENCIES_IDS.euro
                                ? CURRENCIES_SYMBOLS.euro
                                : CURRENCIES_SYMBOLS.usd
                            }${finalFees?.DeductedAmount}`}</span>
                          </p>
                          <p className="d-flex align-items-center justify-content-between mb-0">
                            <span className="text-grey-disabled">{`${paymentPage.paymentDetails.total}`}</span>
                            <span className="fweight-600 title-color">{`${
                              planPrice?.CurrencyId ===
                              CURRENCIES_IDS.euro
                                ? CURRENCIES_SYMBOLS.euro
                                : CURRENCIES_SYMBOLS.usd
                            }${finalFees?.FinalFees}`}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <div className="voucher-section light-border border-radius-10 p-4 my-3">
                <RequestVoucher
                  applyVoucher={(voucherCodeProp) => {
                    setVoucherCode(voucherCodeProp);
                    dispatch(
                      applyVoucherRequest({
                        params: {
                          code: voucherCodeProp,
                          subscriptionTypeId: planDetails?.Id
                        }
                      })
                    );
                  }}
                  originalPrice={
                    finalFees?.FinalFees
                      ? { ...planPrice, Value: finalFees?.FinalFees }
                      : planPrice
                  }
                />
              </div>
              {(voucherData && !!voucherData.totalAfterDiscount
                ? voucherData.totalAfterDiscount
                : finalFees?.FinalFees || planPrice?.Value) && (
                <>
                  {voucherData &&
                  !!voucherData.voucherPercent &&
                  voucherData.totalAfterDiscount == 0 ? (
                    <div className="payment-info light-border border-radius-10 p-4 mb-4">
                      <FreeSubscription />
                    </div>
                  ) : userSavedCards?.length ? (
                    <div className="payment-info light-border border-radius-10 p-4 mb-4">
                      <div className="w-100 d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row mb-4">
                        <h6
                          className={`fsize-16 fweight-600 title-color mb-0`}>
                          {paymentPage.savedCards.selectCard}
                        </h6>
                        <div className="automatic-renewal-toggle d-flex align-items-center">
                          <p className="mb-0 text-color me-3">
                            {paymentPage.automaticRenewal}
                          </p>
                          <Switch
                            checked={isAutomaticSubscription}
                            handleChange={(isChecked) => {
                              setIsAutomaticSubscription(isChecked);
                            }}
                          />
                        </div>
                      </div>
                      <RadioButtonGroup
                        name="userCards"
                        id="userCards"
                        value={userSelectedCard}
                        onChange={(value) => {
                          setUserSelectedCard(value);
                          setPayWithNewCardOpen(false);
                        }}
                        options={userSavedCards?.map((card) => ({
                          label: renderSavedCard(card),
                          value: card?.PaymentMethodId
                        }))}
                        row
                        radioBtnClassname={
                          "user-saved-card mb-3 mb-md-0"
                        }
                        wrapperClassName={"user-saved-cards"}
                      />
                      {renderPayWithNewCardSection()}
                    </div>
                  ) : (
                    !userPlan?.IsAutomaticSubscription &&
                    (isAutomaticSubscription
                      ? stripeClientSecretAutomaticRenewal
                      : stripeClientSecret) && (
                      <div className="payment-info light-border border-radius-10 p-4 mb-4">
                        <div className="w-100 d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row mb-4">
                          <h6
                            className={`fsize-16 fweight-600 title-color mb-0`}>
                            {paymentPage.requiredInfo}
                          </h6>
                          <div className="automatic-renewal-toggle d-flex align-items-center">
                            <p className="mb-0 text-color me-3">
                              {paymentPage.automaticRenewal}
                            </p>
                            <Switch
                              checked={isAutomaticSubscription}
                              handleChange={(isChecked) => {
                                setIsAutomaticSubscription(isChecked);
                              }}
                            />
                          </div>
                        </div>
                        <StripeCheckout
                          isAutomaticRenewal={isAutomaticSubscription}
                          isSubscription={true}
                          clientSecret={
                            isAutomaticSubscription
                              ? stripeClientSecretAutomaticRenewal
                              : stripeClientSecret
                          }
                          setPaymentIntentFunc={
                            setSubscriptionPaymentIntentId
                          }
                          setPaymentFailureMsgFunc={
                            setSubscriptionPaymentFailureMsg
                          }
                          redirectUrl={ROUTE_PATHS.subscriptionSuccess
                            .replace(":id", planDetails?.Id)
                            .replace(
                              ":type",
                              isAutomaticSubscription
                                ? SUBSCRIPTION_PAYMENT_TYPE.automatic
                                : SUBSCRIPTION_PAYMENT_TYPE.manual
                            )}
                          paymentInfo={{
                            amount:
                              voucherData &&
                              !!voucherData.totalAfterDiscount
                                ? voucherData.totalAfterDiscount
                                : finalFees?.FinalFees ||
                                  planPrice?.Value,
                            currency:
                              planPrice?.CurrencyId ===
                              CURRENCIES_IDS.usd
                                ? STRIPE_CURRENCIES_NAMES.usd
                                : STRIPE_CURRENCIES_NAMES.euro
                          }}
                        />
                      </div>
                    )
                  )}
                </>
              )}

              <div className="w-100 text-end">
                <Button
                  label={shared.back}
                  outlined
                  customSpacing={"px-5 py-2"}
                  onClick={() => navigate(-1)}
                  className={"me-4"}
                />
                <Button
                  label={
                    voucherData &&
                    !!voucherData.voucherPercent &&
                    voucherData.totalAfterDiscount == 0
                      ? shared.submit
                      : paymentPage.pay
                  }
                  type={"submit"}
                  customSpacing={"px-5 py-2"}
                  form={
                    (voucherData &&
                      !!voucherData.voucherPercent &&
                      voucherData.totalAfterDiscount == 0) ||
                    userSelectedCard
                      ? null
                      : "online-payment-form"
                  }
                  onClick={() => {
                    if (userSelectedCard) {
                      handlePaymentWithSavedCard();
                    } else if (
                      voucherData &&
                      !!voucherData.voucherPercent &&
                      voucherData.totalAfterDiscount == 0
                    ) {
                      navigate(
                        ROUTE_PATHS.subscriptionSuccess
                          .replace(":id", planDetails?.Id)
                          .replace(
                            ":type",
                            userPlan?.IsAutomaticSubscription ||
                              isAutomaticSubscription
                              ? SUBSCRIPTION_PAYMENT_TYPE.automatic
                              : SUBSCRIPTION_PAYMENT_TYPE.manual
                          )
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Box>
      {!!paymentFailureMsg && (
        <SubscriptionFailed
          open={!!paymentFailureMsg}
          onClose={handleCloseModal}
          subscriptionPaymentType={
            userPlan?.IsAutomaticSubscription ||
            isAutomaticSubscription
              ? SUBSCRIPTION_PAYMENT_TYPE.automatic
              : SUBSCRIPTION_PAYMENT_TYPE.manual
          }
        />
      )}
    </div>
  );
};

export default function WrappedSubscriptionPayment() {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionPayment />
    </Elements>
  );
}
