import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../utils/blobStorageServices";
import {
  setCategorySuggestionModalOpen,
  setSuggestedCategoryData,
  suggestNewCategoryRequest
} from "../../store/Categories/actions";
import messages from "../../assets/locale/messages";
import { showHideLoader } from "../../store/Loader/actions";
import Modal from "../Modal";
import Upload from "../Upload";
import Input from "../Input";
import "./SuggestCategoryModal.scss";

const SuggestCategoryModal = ({ onClose, isDirectSubmit }) => {
  const uploadElementRef = useRef();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const open = useSelector(
    (state) => state.categories.categorySuggestionModalOpen
  );

  const {
    categories: { suggestCategory },
    shared,
    upload
  } = messages[lang];

  const initialValues = {
    Name: "",
    Description: "",
    AdminCoverImage: null,
    AdminCoverImageInfo: {}
  };

  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    dirty,
    isValid,
    setValues,
    setTouched,
    setErrors
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      Name: Yup.string()
        .max(50, "categoryNameLong")
        .required("CategoryNameRequired"),
      Description: Yup.string()
        .max(500, "categoryDescriptionLong")
        .required("CategoryDescriptionRequired"),
      AdminCoverImage: Yup.mixed().nullable()
    }),
    onSubmit: async ({
      Name,
      Description,
      AdminCoverImage,
      AdminCoverImageInfo
    }) => {
      dispatch(showHideLoader(true));
      let categoryFileName = "";
      if (AdminCoverImage) {
        categoryFileName = await UploadBlobToContainer(
          { blob: AdminCoverImage, ...AdminCoverImageInfo },
          BLOB_CONTAINERS.images
        );
      }
      const categoryData = {
        Id: 0,
        Name,
        Description,
        AdminCoverImage: categoryFileName,
        OriginalAdminCoverImageName: AdminCoverImageInfo.name
      };
      if (isDirectSubmit) {
        dispatch(
          suggestNewCategoryRequest({
            data: { ...categoryData }
          })
        );
      } else {
        dispatch(setSuggestedCategoryData({ ...categoryData }));
        dispatch(setCategorySuggestionModalOpen(false));
        dispatch(showHideLoader(false));
      }
    }
  });

  const renderSuggestCategoryForm = () => (
    <div className="suggest-category-form">
      <form noValidate onSubmit={handleSubmit}>
        <div className="w-100 d-flex justify-content-center">
          <div className="upload-image-section mb-4">
            <Upload
              showValidationMsg
              required
              label={<div ref={uploadElementRef}></div>}
              onChange={(img) => {
                setFieldTouched("AdminCoverImage");
                setValues({
                  ...values,
                  AdminCoverImage: img.blob,
                  AdminCoverImageInfo: img?.imageInfo
                });
              }}
              userImage={values.AdminCoverImage}
              onClear={() => {
                setFieldTouched("AdminCoverImage");
                setValues({
                  ...values,
                  AdminCoverImage: null,
                  AdminCoverImageInfo: {}
                });
              }}
              name="AdminCoverImage"
              isInputHasErr={
                !!(touched.AdminCoverImage && errors.AdminCoverImage)
              }
              errMsg={errors.AdminCoverImage}
              isCropMode={true}
              cropAspect={1 / 1}
              customPlaceholder
              placeholderText={
                <span className="fsize-12 text-primary fweight-700">
                  {upload.uploadImg}
                </span>
              }
            />
          </div>
        </div>
        <Input
          required
          label={suggestCategory.categoryName}
          placeholder={suggestCategory.enterName}
          onChange={(value) => {
            if (value?.trim() || !value) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("Name");
              setFieldValue("Name", modifiedValue);
            }
          }}
          value={values.Name}
          isInputHasErr={!!(touched.Name && errors.Name)}
          errMsg={errors.Name}
          name="Name"
          id="Name"
          inputWrapperClass={"mb-4 mt-3 mt-md-0"}
        />
        <Input
          required
          label={suggestCategory.categoryDescription}
          placeholder={suggestCategory.enterDescription}
          rows={3}
          multiline
          onChange={(value) => {
            if (value?.trim() || !value) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("Description");
              setFieldValue("Description", modifiedValue);
            }
          }}
          value={values.Description}
          isInputHasErr={
            !!(touched.Description && errors.Description)
          }
          errMsg={errors.Description}
          name="Description"
          id="Description"
          inputWrapperClass={"mb-4"}
        />
      </form>
    </div>
  );

  const handleCloseModal = () => {
    setValues({ ...initialValues });
    setTouched({});
    setErrors({});
    onClose();
  };

  return (
    <Modal
      modalClass={"suggest-category-modal"}
      open={open}
      handleClose={handleCloseModal}
      title={suggestCategory.suggestNewCategory}
      showCloseIcon
      isContentText={false}
      content={renderSuggestCategoryForm()}
      confirmBtnTxt={shared.save}
      cancelBtnTxt={shared.cancel}
      handleConfirm={handleSubmit}
      handleCancel={handleCloseModal}
      disableEscapeKeyDown
      disableBackdropClick
      disableConfirmBtn={!dirty || !isValid}
      contentClass={"scroll-within"}
    />
  );
};

export default SuggestCategoryModal;

SuggestCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isDirectSubmit: PropTypes.func.isRequired
};
