export const DirectBoxSendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M10.288 6.594v-4.75L8.705 3.427M10.29 1.844l1.582 1.583"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      d="M6.33 9.762c-3.166 0-3.166 1.417-3.166 3.166v.792c0 2.185 0 3.958 3.958 3.958h6.334c3.166 0 3.958-1.773 3.958-3.958v-.792c0-1.75 0-3.166-3.167-3.166-.791 0-1.013.166-1.425.475l-.807.855a2.374 2.374 0 01-3.46 0l-.8-.855c-.41-.309-.633-.475-1.424-.475zM4.748 9.761V8.178c0-1.591 0-2.905 2.375-3.135M15.83 9.761V8.178c0-1.591 0-2.905-2.375-3.135"></path>
  </svg>
);
