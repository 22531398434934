import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Verified } from "@mui/icons-material";
import { Chip } from "@mui/material";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  generalImporterPagesPadding,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import {
  GreyBriefCaseIcon,
  GreyCalendarIcon,
  GreyConvertCubeIcon,
  GreyLocationIcon
} from "../../../../utils/Icons";
import messages from "../../../../assets/locale/messages";
import noImgPlaceholder from "../../../../assets/images/exporter-profile-cover.png";
import noCoverImgPlaceholder from "../../../../assets/images/noCoverImgPlaceholder.png";
import ShowCase from "./ShowCase";
import {
  getExporterContactInfoRequest,
  getExporterContactInfoResponse,
  getExporterDetailsRequest,
  getExporterDetailsResponse
} from "../../../../store/Exporters/actions";
import {
  checkBackgroundImageValidity,
  isExporterRole
} from "../../../../utils/Helpers";
import ContactInfoCard from "../../../../components/ContactInfoCard";
import "./ExporterProfile.scss";

const ExporterProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const { exporters } = messages[lang];

  const isAuth = useSelector((state) => state.auth.login?.Token);
  const { exporterDetails, contactInfo } = useSelector(
    (state) => state.exporters
  );
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getExporterDetailsRequest({
          params: {
            id,
            currencyId: websiteCurrency?.Id
          }
        })
      );
    }
  }, [id, websiteCurrency]);

  useEffect(() => {
    if (isAuth && exporterDetails && exporterDetails?.ExporterId) {
      dispatch(
        getExporterContactInfoRequest({
          id
        })
      );
    }
  }, [exporterDetails, isAuth]);

  useEffect(() => {
    return () => {
      dispatch(getExporterDetailsResponse({}));
      dispatch(getExporterContactInfoResponse({}));
    };
  }, []);

  const breadcrumbs = [
    {
      label: exporters.breadCrumbs.home,
      link: ROUTE_PATHS.home
    },
    {
      label: exporters.breadCrumbs.exportersList,
      link: ROUTE_PATHS.exporters
    }
  ];

  const [isCompanyCoverImgValid, setIsCompanyCoverImgValid] =
    useState(true);
  const [isCompanyLogoImgValid, setIsCompanyLogoImgValid] =
    useState(true);

  useEffect(() => {
    checkBackgroundImageValidity(
      exporterDetails?.LogoImage,
      (isLoaded) => {
        setIsCompanyLogoImgValid(isLoaded);
      }
    );
    checkBackgroundImageValidity(
      exporterDetails?.CoverImage,
      (isLoaded) => {
        setIsCompanyCoverImgValid(isLoaded);
      }
    );
  }, [
    exporterDetails,
    exporterDetails?.LogoImage,
    exporterDetails?.CoverImage
  ]);

  const renderHeaderSection = () => (
    <div
      className={`header-section text-white `}
      style={{
        backgroundImage: `url(${isCompanyCoverImgValid ? exporterDetails?.CoverImage : noCoverImgPlaceholder})`
      }}>
      <div
        className={`overlay-container ${generalImporterPagesPadding}`}>
        <div className="container-xxl">
          <Breadcrumbs
            list={[
              ...breadcrumbs,
              {
                label: exporterDetails?.TradeName
              }
            ]}
          />
        </div>
      </div>
      <div className="container-xxl">
        <div
          className="profile-img circular-border-radius white-bg light-border ms-md-5 ms-4 py-3 "
          style={{
            backgroundImage: `url(${isCompanyLogoImgValid ? exporterDetails?.LogoImage : noImgPlaceholder})`
          }}></div>
      </div>
    </div>
  );

  const renderLabelValue = (label, value, icon) => (
    <div className="d-flex align-items-center text-break">
      <div>{icon}</div>
      <div className="ms-2">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>
        <p className="text-color fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  const renderExporterInfoSection = () => (
    <div className="exporter-info-section mb-4 row">
      <div className="col-md-6 col-12">
        <div className="description-section mb-4">
          <h3 className="fsize-24 fweight-700 title-color mb-3">
            {exporterDetails?.TradeName}
            {exporterDetails?.IsVerified && (
              <Verified color="primary" className="ms-1" />
            )}
          </h3>
          {exporterDetails?.Introduction}
        </div>
        <div className="categories-section mb-4 mb-md-0">
          <h4 className="fsize-16 fweight-500 title-color mb-3">
            {exporters.mainInfo.categories}
          </h4>
          <div className="categories-chips d-flex justify-content-start flex-wrap">
            {exporterDetails?.Categories?.map(({ Name, Id }) => (
              <Chip
                key={`category-${Id}`}
                label={Name}
                className={`blue-chip border-radius-5 fsize-14 fweight-400 mx-2 my-2`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <ContactInfoCard
          name={contactInfo?.ExporterName}
          mobileNumber={contactInfo?.MobileNumber}
          emailAddress={contactInfo?.Email}
          isFullWidth
          website={contactInfo?.Website}
          isWebsiteCard={
            isAuth
              ? isExporterRole(userCurrentRole, userType)
                ? userStatusId !== USER_STATUS_IDS.subscribed
                : true
              : true
          }
        />
      </div>
    </div>
  );

  const renderCompanyInfoSection = () => (
    <div className="company-info-section mb-4">
      <h4 className="fsize-20 fweight-600 title-color mb-4">
        {exporters.mainInfo.companyInfo}
      </h4>
      <div className="row">
        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
          {renderLabelValue(
            exporters.mainInfo.yearEstablished,
            exporterDetails?.EstablishedYear ||
              exporters.mainInfo.notProvided,
            <GreyCalendarIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
          {renderLabelValue(
            exporters.mainInfo.yearsOfExperience,
            exporterDetails?.YearsOfExperience?.toString() ||
              exporters.mainInfo.notProvided,
            <GreyConvertCubeIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6  mb-4 mb-md-0">
          {renderLabelValue(
            exporters.mainInfo.country,
            exporterDetails?.CountryName ||
              exporters.mainInfo.notProvided,
            <GreyLocationIcon />
          )}
        </div>
        <div className="col-lg-3 col-md-6">
          {renderLabelValue(
            exporters.mainInfo.businessType,
            exporterDetails?.BusinessTypeName ||
              exporters.mainInfo.notProvided,
            <GreyBriefCaseIcon />
          )}
        </div>
      </div>
    </div>
  );

  const renderShowCasesSection = () => (
    <div className="showcases-section pb-5">
      <h4 className="fsize-20 fweight-600 title-color mb-4">
        {exporters.showcases}
      </h4>
      {exporterDetails &&
        !!Object.keys(exporterDetails).length &&
        exporterDetails?.ShowCases?.map((showCase, i) => (
          <div key={`showcase-${i}`} className="mb-5">
            <ShowCase
              exporterId={exporterDetails.ExporterId}
              subCategoryId={showCase?.SubcategoryId}
              products={showCase.Products.map((showcaseProduct) => {
                return {
                  src: showcaseProduct.DefaultImage,
                  label: showcaseProduct.Name,
                  country: showcaseProduct.Country,
                  count: showcaseProduct.MinOrderQuantity,
                  minPrice: showcaseProduct.MinPriceRange,
                  unit: showcaseProduct.MoqUnitName,
                  maxPrice: showcaseProduct.MaxPriceRange,
                  link: ROUTE_PATHS.productDetails.replace(
                    ":id",
                    showcaseProduct.Id
                  ),
                  currency: websiteCurrency?.Symbol
                };
              })}
              info={{
                name: showCase.Name,
                description: showCase.Description
              }}
              showcaseImages={[
                showCase.Image,
                ...showCase.ProductImages.map(
                  (productImg) => productImg.DefaultImage
                )
              ]}
              showcaseCoverImg={showCase.WebsiteCoverImage}
            />
          </div>
        ))}
    </div>
  );

  return (
    <div className="exporter-profile-container">
      {renderHeaderSection()}
      <div className="px-md-5 px-4 container-xxl">
        {renderExporterInfoSection()}
        {renderCompanyInfoSection()}
        {exporterDetails &&
          !!Object.keys(exporterDetails).length &&
          !!exporterDetails?.ShowCases?.length &&
          renderShowCasesSection()}
      </div>
    </div>
  );
};

export default ExporterProfile;
