import {
  GET_HOME_DETAILS_REQUEST,
  GET_HOME_DETAILS_RESPONSE,
  SEND_SUPPORT_EMAIL_REQUEST,
  SEND_SUPPORT_EMAIL_RESPONSE,
  SET_WEBSITE_CURRENCY
} from "./types";

export const getHomeDetailsRequest = (payload) => ({
  type: GET_HOME_DETAILS_REQUEST,
  payload
});

export const getHomeDetailsResponse = (payload) => ({
  type: GET_HOME_DETAILS_RESPONSE,
  payload
});

export const sendSupportEmailRequest = () => ({
  type: SEND_SUPPORT_EMAIL_REQUEST
});

export const sendSupportEmailResponse = (payload) => ({
  type: SEND_SUPPORT_EMAIL_RESPONSE,
  payload
});

export const setWebsiteCurrency = (payload) => ({
  type: SET_WEBSITE_CURRENCY,
  payload
});
