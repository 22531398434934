export const EmptyCheckBox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
    {...props}>
    <rect
      width="19"
      height="19"
      x="0.715"
      y="0.5"
      fill="#fff"
      stroke="#E8E8E8"
      rx="5.5"></rect>
  </svg>
);
