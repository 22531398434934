export const SET_PAGE_TITLE = "SET_PAGE_TITLE";

export const SET_EXPORTER_LAYOUT_CONTAINER_REF =
  "SET_EXPORTER_LAYOUT_CONTAINER_REF";

export const SET_IMPORTER_LAYOUT_CONTAINER_REF =
  "SET_IMPORTER_LAYOUT_CONTAINER_REF";

export const SET_BASIC_LAYOUT_CONTAINER_REF =
  "SET_BASIC_LAYOUT_CONTAINER_REF";
