import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar as MuiSnackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import { InfoNotificationIcon } from "../../utils/Icons";
import { setPushNotification } from "../../store/Notifications/actions";
import { handleNotificationClick } from "../../notifications/helpers";
import messages from "../../assets/locale/messages";
import { USER_TYPES_IDS } from "../../utils/Constants";
import { clickActions } from "../../notifications/constants";
import { logoutRequest } from "../../store/Auth/actions";
import { getDeviceId } from "../../utils/Auth";
import "./NotificationSnackbar.scss";

const NotificationSnackbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { show, title, body, clickAction, actionId, id, userTypeId } =
    useSelector((state) => state.notifications.pushNotification);
  const lang = useSelector((state) => state.locale.lang);
  const {
    myAccount: {
      switchCurrentRole: { message }
    }
  } = messages[lang];
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userId = useSelector((state) => state.auth.login?.Id);

  useEffect(() => {
    if (
      show &&
      [
        clickActions.permissionsChanged,
        clickActions.accountDeactivated
      ].includes(clickAction)
    ) {
      setTimeout(() => {
        dispatch(
          logoutRequest({
            device_id: getDeviceId(),
            navigate,
            forceLogoutParams: { id: userId, isEmployee: false }
          })
        );
      }, 5000);
    }
    if (
      show &&
      [
        clickActions.automaticSubscriptionSuccess,
        clickActions.automaticSubscriptionFailure
      ].includes(clickAction)
    ) {
      localStorage.removeItem(
        `${userId}-delayed-automatic-subscription`
      );
    }
  }, [show, clickAction, userId]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setPushNotification({
        show: false,
        id: null,
        title: "",
        body: "",
        clickAction: null,
        actionId: null
      })
    );
  };

  return (
    <div className="notification-snackbar-container">
      {show && (
        <MuiSnackbar
          open={show}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          className={`notification-snackbar pointer`}
          onClick={() =>
            handleNotificationClick({
              id,
              clickAction,
              actionId,
              navigate,
              location,
              message,
              userTypeId,
              userCurrentRole,
              isCombinedUser: userType === USER_TYPES_IDS.both
            })
          }
          message={
            <div className="snackbar-msg-icon-container d-flex align-items-start justify-content-between mx-2">
              <div className="d-flex message-container">
                <InfoNotificationIcon className="me-3" />
                <div className="width-fit-content">
                  <p className="mb-0 title fsize-16 fweight-600">
                    {title}
                  </p>
                  <p className="mb-0 body fsize-16">{body}</p>
                </div>
              </div>
              <div
                className="close-icon mx-2 pointer"
                onClick={(e, reason) => {
                  e.stopPropagation();
                  handleClose(e, reason);
                }}>
                <Close />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
export default NotificationSnackbar;
