import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "../../components/Button";
import Input from "../../components/Input";
import messages from "../../assets/locale/messages";
import {
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS
} from "../../utils/Constants";
import {
  applyVoucherResponse,
  requestVoucherRequest
} from "../../store/Subscription/actions";

const RequestVoucher = ({ applyVoucher, originalPrice }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const userEmail = useSelector((state) => state.auth.login?.Email);
  const isVoucherRequested = useSelector(
    (state) => state.subscription.isVoucherRequested
  );
  const voucherData = useSelector(
    (state) => state.subscription.voucherData
  );

  const { shared } = messages[lang];

  const [voucherCode, setVoucherCode] = useState("");

  const isVoucherRecieved =
    !!voucherData && !!voucherData?.voucherPercent;

  const handleClearVoucher = () => {
    dispatch(
      applyVoucherResponse({
        totalAfterDiscount: 0,
        voucherPercent: 0
      })
    );
  };

  return (
    <div>
      <h6
        className={`fsize-16 fweight-600 title-color ${!isVoucherRequested && "mb-4"}`}>
        {shared.voucher.voucherDetails}
      </h6>
      <div className="voucher-input-recipt-container d-md-flex">
        <div
          className={`${!isVoucherRecieved ? "col-lg-9 col-md-10 col-12" : "col-md-6 col-12 mb-md-0 mb-3"} voucher-input-container`}>
          {isVoucherRequested && (
            <div className="voucher-input-text pe-md-2">
              <span>
                {`${shared.voucher.voucherRequestedText} `}
                <span className="title-text fweight-600">{`${userEmail} `}</span>
              </span>
              <span>{shared.voucher.shortly}</span>
            </div>
          )}
          <div
            className={`${isVoucherRecieved ? "pe-md-4" : " d-md-flex "} align-items-center gap-3 ${isVoucherRequested && "mt-4"}`}>
            <Input
              endAdornment={
                <div>
                  {!!voucherCode.length && isVoucherRecieved && (
                    <Button
                      outlined
                      label={shared.voucher.clearButton}
                      onClick={() => {
                        setVoucherCode("");
                        handleClearVoucher();
                      }}
                      labelClass={"fsize-14"}
                      customSpacing={" py-2"}
                    />
                  )}
                  {!(!!voucherCode.length && isVoucherRecieved) && (
                    <Button
                      disabled={voucherCode.length !== 6}
                      label={shared.voucher.applyButton}
                      onClick={() => {
                        if (!!voucherCode.length) {
                          applyVoucher(voucherCode);
                        }
                      }}
                      labelClass={"fsize-14"}
                      customSpacing={" py-2"}
                    />
                  )}
                </div>
              }
              required
              key={shared.voucher.voucher}
              label={shared.voucher.voucherCode}
              labelClassName="pb-2 font-medium  main-text-color"
              placeholder={shared.voucher.voucherCodePlaceholder}
              onChange={(value) => {
                if (value.length === 0) {
                  handleClearVoucher();
                  setVoucherCode(value);
                } else {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setVoucherCode(modifiedValue);
                  }
                }
              }}
              value={voucherCode}
              name="voucher"
              id="voucher"
              inputWrapperClass={`${isVoucherRecieved ? "" : "col-xl-7 col-lg-8"}`}
            />
            <Button
              label={shared.voucher.requestVoucherButton}
              outlined
              customSpacing={"px-4 py-2"}
              className={`${!isVoucherRecieved && "mt-md-0 mb-m-0"} mt-3 mb-2`}
              onClick={() => {
                dispatch(requestVoucherRequest());
              }}
            />
          </div>
        </div>
        {isVoucherRecieved && (
          <div
            className="voucher-recipt-container border-radius-10 p-4 px-md-5 px-4 col-md-6 col-12"
            style={{ backgroundColor: "#F9FAFB" }}>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-light-grey mb-1 fsize-12">
                {shared.voucher.subTotal}
              </p>
              <p className="text-color fsize-14 fweight-500 mb-0">
                {originalPrice?.CurrencyId === CURRENCIES_IDS.euro
                  ? CURRENCIES_SYMBOLS.euro
                  : CURRENCIES_SYMBOLS.usd}
                {originalPrice?.Value}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-light-grey mb-1 fsize-12">
                {shared.voucher.voucher}
              </p>
              <p className=" success fsize-14 fweight-500 mb-0">
                {`-${voucherData.voucherPercent}%`}
              </p>
            </div>
            <div className="d-flex justify-content-between pt-3 border-top">
              <p className="text-light-grey mb-1 fsize-12">
                {shared.voucher.total}
              </p>
              <p className="text-color fsize-16 fweight-600 mb-0">
                {`${
                  originalPrice?.CurrencyId === CURRENCIES_IDS.euro
                    ? CURRENCIES_SYMBOLS.euro
                    : CURRENCIES_SYMBOLS.usd
                } ${voucherData.totalAfterDiscount}`}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestVoucher;

RequestVoucher.propTypes = {
  applyVoucher: PropTypes.func,
  originalPrice: PropTypes.object
};
