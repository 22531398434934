import {
  GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE,
  SUBCATEGORY_PRODUCTS_LIST_RESPONSE
} from "./types";

const INITIAL_STATE = {
  accountDetails: {},
  subcategoryProductsList: [],
  subcategoryProductsListMeta: {}
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE:
      return {
        ...state,
        accountDetails: action.payload
      };
    case SUBCATEGORY_PRODUCTS_LIST_RESPONSE:
      return {
        ...state,
        subcategoryProductsList:
          action.payload.meta.currentPage > 1
            ? [
                ...state.subcategoryProductsList,
                ...action.payload.list
              ]
            : action.payload.list,
        subcategoryProductsListMeta: action.payload.meta
      };
    default:
      return state;
  }
};

export default user;
