import React from "react";
import Lottie from "react-lottie-player";
import PropTypes from "prop-types";

const JsonViewer = ({ loop, animationData, play, style }) => {
  return (
    <Lottie
      loop={loop}
      animationData={animationData}
      style={style}
      play={play}
    />
  );
};
JsonViewer.propTypes = {
  loop: PropTypes.bool,
  animationData: PropTypes.object,
  play: PropTypes.bool,
  style: PropTypes.object
};

JsonViewer.defaultProps = {
  loop: true,
  play: true,
  style: { width: 75, height: 75 }
};
export default JsonViewer;
