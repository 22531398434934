import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../../assets/locale/messages";
import Modal from "../../../../../components/Modal";
import { AddProductSuccessIcon } from "../../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import { showAddSuccessModal } from "../../../../../store/Products/actions";

const AddProductSuccess = ({ open }) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    products: {
      addEditProduct: {
        addSuccessModal: {
          title,
          firstSubtitle,
          secondSubtitle,
          goToList,
          addMoreProducts
        }
      }
    }
  } = messages[lang];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateList = () => {
    dispatch(showAddSuccessModal(false));
    navigate(ROUTE_PATHS.products);
  };

  const handleAddMoreProducts = () => {
    location.reload();
    dispatch(showAddSuccessModal(false));
  };

  const addProductSuccessContent = () => {
    return (
      <div className="add-product-success-container container d-flex flex-column justify-content-center align-items-center">
        <AddProductSuccessIcon />
        <div className="add-product-success-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {firstSubtitle}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {secondSubtitle}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"add-product-success-modal"}
      open={open}
      handleConfirm={() => {
        handleAddMoreProducts();
      }}
      handleCancel={handleNavigateList}
      handleClose={handleNavigateList}
      isContentText={false}
      content={addProductSuccessContent()}
      hasActions={true}
      confirmBtnTxt={addMoreProducts}
      cancelBtnTxt={goToList}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 col-6 product-success-confirm"
      cancelBtnClass="text-secondary border-radius-12 col-6 product-success-cancel"
    />
  );
};

export default AddProductSuccess;

AddProductSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired
};
