import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import messages from "../../../../assets/locale/messages";
import { CloseBgIcon } from "../../../../utils/Icons";
import { setVerificationStep } from "../../../../store/Verification/actions";
import { confirmSubscriptionPayment } from "../../../../store/Subscription/actions";

const SubscriptionFailed = ({
  open,
  onClose,
  planId,
  subscriptionPaymentType
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const {
    subscription: {
      paymentPage: { failedPayment }
    }
  } = messages[lang];

  const paymentFailureMsg = useSelector(
    (state) => state.subscription.subscriptionFailureMsg
  );
  const paymentIntentId = useSelector(
    (state) => state.subscription.subscriptionPaymentIntentId
  );

  useEffect(() => {
    if (open && paymentIntentId) {
      dispatch(
        confirmSubscriptionPayment({
          paymentIntentId,
          subscriptionTypeId: id || planId,
          subscriptionPaymentType
        })
      );
    }
  }, [open, paymentIntentId]);

  const renderModalContent = () => (
    <div className="text-center">
      <CloseBgIcon className="mb-4" />
      <h5 className="fsize-24 fweight-700 title-color mb-3">
        {failedPayment.failedTransfer}
      </h5>
      <p className="fsize-16 text-color mb-4">
        {`${paymentFailureMsg || failedPayment.failureMsg1} ${failedPayment.failureMsg2}`}
      </p>
      <Button
        label={failedPayment.tryAgain}
        onClick={() => {
          onClose();
          dispatch(setVerificationStep(1));
        }}
      />
    </div>
  );

  return (
    <Modal
      open={open}
      title
      modalClass="online-payment-failure-modal"
      content={renderModalContent()}
      handleClose={onClose}
      isContentText={false}
    />
  );
};

export default SubscriptionFailed;

SubscriptionFailed.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subscriptionPaymentType: PropTypes.string.isRequired,
  planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
