const forgetPassword = {
  forgetPassword: "Forget Your Password ?",
  instructions:
    "Please enter your email address and we'll send a verification link if it matches an existing ExGates account",
  labels: {
    email: "Email Adress"
  },
  placeholders: {
    email: "Enter your email address"
  },
  actionBTN: "Send",
  confirmScreen: {
    forgetPassword: "Forget Your Password ?",
    confirmMsg: "We've sent a password reset link to your Email",
    continue: "Continue",
    backToSignin: "Back to sign in",
    didntReceiveEmail: "Didn't receive the Email?",
    clickToResend: "Click to resend"
  }
};

export default forgetPassword;
