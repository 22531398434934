import PropTypes from "prop-types";
import { GridDisplayIcon, ListDisplayIcon } from "../../utils/Icons";
import { DISPLAY_STYLE } from "../../utils/Constants";
import "./DisplayStyleButton.scss";

const DisplayStyleButton = ({
  onDisplayChange,
  displayStyle,
  className
}) => {
  return (
    <div
      className={`display-style-btn d-flex justify-content-center align-items-center gap-2 ${className}`}>
      <div>
        <GridDisplayIcon
          active={displayStyle === DISPLAY_STYLE.grid ? "true" : ""}
          onClick={() => {
            onDisplayChange(DISPLAY_STYLE.grid);
          }}
          className={`display-style-icon pointer`}
        />
      </div>
      <div>
        <ListDisplayIcon
          active={displayStyle === DISPLAY_STYLE.list ? "true" : ""}
          onClick={() => {
            onDisplayChange(DISPLAY_STYLE.list);
          }}
          className={"display-style-icon pointer"}
        />
      </div>
    </div>
  );
};

DisplayStyleButton.propTypes = {
  onDisplayChange: PropTypes.func,
  displayStyle: PropTypes.string,
  className: PropTypes.string
};

export default DisplayStyleButton;
