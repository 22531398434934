import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, FieldArray, FormikProvider } from "formik";

import * as Yup from "yup";
import {
  Chip,
  FormHelperText,
  InputLabel,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Add,
  CloseOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUnchecked,
  InfoOutlined
} from "@mui/icons-material";

import {
  showHideLoader,
  showHideLoaderText
} from "../../../../store/Loader/actions";
import messages from "../../../../assets/locale/messages";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Upload from "../../../../components/Upload";
import TruncateText from "../../../../components/TruncateText";
import AddProductSuccess from "./AddProductSuccesModal";
import {
  DIGITS_ONLY,
  NAME_REGEX_START_WITH_CHARACTERS_ONLY,
  ONLY_POSITIVE_NUMBERS,
  PRICE_REGEX,
  TWO_WORDS_ONLY
} from "../../../../utils/Patterns";
import {
  addProductRequest,
  editProductRequest,
  getProductVersionDetailsRequest,
  getProductVersionDetailsResponse,
  getTranscribedAudioIdResponse,
  getTranscribedAudioTextResponse,
  isAudioTranscribedRequest,
  sendAddProductSupportEmailRequest,
  sendAddProductSupportEmailResponse
} from "../../../../store/Products/actions";
import {
  getSpecificationsListRequest,
  getCategoriesListRequest,
  getMOQListRequest,
  getSubCategoriesListRequest
} from "../../../../store/Lookups/actions";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer,
  deleteBlob
} from "../../../../utils/blobStorageServices";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  PRODUCT_SPECIFICATION_TYPES,
  PRODUCT_STATUS_WITH_ID,
  SUBSCRIPTION_PLAN_FEATURES_IDS,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import EditPublishedEditedProduct from "./EditPublishedEditedProductModal";
import ScrollToFieldError from "../../../../components/ScrollToError";
import {
  convertRichTextToPlainText,
  getUserCurrency,
  showErrorMsg
} from "../../../../utils/Helpers";
import RichTextInput from "../../../../components/RichTextInput";
import ChangeShowcaseProductSubcategory from "../changeShowcaseProductSubcategoryModal";
import AddProductImageGuide from "../AddProductImageGuide";
import "./AddEditProduct.scss";

const AddEditProduct = () => {
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const moqList = useSelector((state) => state.lookups.moqList);

  const subCategoriesList = useSelector(
    (state) => state.lookups.subCategoriesList
  );
  const specificationsList = useSelector(
    (state) => state.lookups.specificationsList
  );

  const productDetails = useSelector(
    (state) => state.products.productVersionDetails
  );
  const showAddModal = useSelector(
    (state) => state.products.showAddModal
  );
  const isSupportEmailSent = useSelector(
    (state) => state.products.isSupportEmailSent
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deletedImgsArray, setDeletedImgsArray] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [imgUploadError, setImgUploadError] = useState(null);
  const [keywordState, setKeywordState] = useState({});

  const [moqUnitLookupListState, setMoqUnitLookupListState] =
    useState([]);
  const [categoryLookupListState, setCategoryLookupListState] =
    useState([]);
  const [subCategoryLookupListState, setSubCategoryLookupListState] =
    useState([]);

  useState([]);
  const [productStatusIdState, setProductStatusIdState] = useState();
  const [deletedSpecificationIndex, setDeletedSpecificationIndex] =
    useState();
  const [transcribedText, setTranscribedText] = useState("");
  const [writtenDescription, setWrittenDescription] = useState("");
  const [isAudioDescription, setIsAudioDescription] = useState(false);
  const [
    isAudioTranscribeSuccessful,
    setIsAudioTranscribeSuccessful
  ] = useState(true);

  const [isPublishedEditedProduct, setIsPublishedEditedProduct] =
    useState();
  const [
    isPublishedEditedProductModalOpen,
    setIsPublishedEditedProductModalOpen
  ] = useState(false);
  const [PublishEditProductDetails, setPublishEditProductDetails] =
    useState();
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState(false);

  const [isMicroPhoneEnabled, setIsMicroPhoneEnabled] =
    useState(false);
  const [isRecordingClicked, setIsRecordingClicked] = useState(false);
  const [keywordsCountLimit, setKeywordsCountLimit] = useState(2);
  const [
    isChangeShowcaseProductSubcategoryModalOpen,
    setIsChangeShowcaseProductSubcategoryModalOpen
  ] = useState(false);
  const [tempSubcategory, setTempSubcategory] = useState({});
  const [
    confirmChangeSubcategoryChange,
    setConfirmSubcategoryChange
  ] = useState(false);

  const specificationFieldArrayRef = useRef(null);
  const priceRangeFieldArrayRef = useRef(null);
  const uploadElementRef = useRef();
  const cardUploadElementRef = useRef();

  useEffect(() => {
    if (userPlan && !!Object.keys(userPlan).length) {
      setKeywordsCountLimit(
        parseInt(
          userPlan.Features.find((feature) => {
            return (
              feature.Id ===
              SUBSCRIPTION_PLAN_FEATURES_IDS.searchKeywords
            );
          }).Value
        )
      );
    }
  }, [userPlan]);

  useEffect(() => {
    if (!confirmChangeSubcategoryChange) return;
    setTouched({
      ...touched,
      subCategory: true,
      specification: true
    });
    setValues({
      ...values,
      subCategory: tempSubcategory,
      specification: [
        {
          specificationName: "",
          specificationValue: {
            Name: "",
            file: {},
            isNew: true
          },
          SpecificationOtherName: null
        }
      ]
    });

    setConfirmSubcategoryChange(false);
  }, [confirmChangeSubcategoryChange, tempSubcategory]);

  const {
    shared,
    upload,
    inputsValidations,
    products: {
      addEditProduct: {
        addPageTitle,
        editPageTitle,
        optional,
        noCatalogueUploaded,
        loaderAddText,
        loaderEditText,
        blockLabels: {
          categorySubCategoryBlock,
          productDetailsBlock,
          productImagesBlock,
          productVideoBlock,
          minimumOrderQuantityBlock,
          productSpecificationBlock,
          priceRangeBlock,
          searchingKeywordsBlock
        },
        tooltip: { productHsCodeTooltip },
        inputLabels: {
          category,
          subCategory,
          productName,
          productHsCode,
          productDescription,
          minimumOrderQuantity,
          unit,
          specificationName,
          specificationValue,
          minimumPriceRangeQuantity,
          maximumPriceRangeQuantity,
          pricePerUnit,
          keyword
        },
        placeholders: {
          categoryPlaceholder,
          subCategoryPlaceholder,
          productNamePlaceholder,
          productHsCodePlaceholder,
          productDescriptionPlaceholder,
          productImagesBlockPlaceholder,
          addVideo,
          chooseDefaultImageBlockPlaceholder,
          minimumOrderQuantityPlaceholder,
          orderUnitPlaceholder,
          productSpecificationBlockPlaceholder,
          specificationNamePlaceholder,
          specificationValuePlaceholder,
          minimumPriceRangeQuantityPlaceholder,
          maximumPriceRangeQuantityPlaceholder,
          pricePerUnitPlaceholder,
          searchingKeywordsBlockPlaceholder,
          keywordPlaceholder
        },
        defaultImg,
        deleteImg,
        addMore
      }
    }
  } = messages[lang];

  const formik = useFormik({
    initialValues: {
      category: null,
      subCategory: null,
      productName: "",
      productHsCode: "",
      productDescription: { pureText: "", richText: "" },
      productImage: [],
      productVideo: null,
      minimumOrderQuantity: "",
      orderUnit: "",
      orderUnitOther: "",
      specification: [
        {
          specificationName: null,
          specificationValue: {
            Name: "",
            file: {},
            isNew: !!id
          },
          SpecificationOtherName: null
        }
      ],
      priceRange: [
        {
          minimumPriceRangeQuantity: "",
          maximumPriceRangeQuantity: "",
          pricePerUnit: ""
        }
      ],
      keyword: []
    },
    validationSchema: Yup.object({
      category: Yup.object().required("categoryRequired"),
      subCategory: Yup.object().required("subCategoryRequired"),
      productName: Yup.string()
        .matches(
          NAME_REGEX_START_WITH_CHARACTERS_ONLY,
          "invalidProductName"
        )
        .required("productNameRequired")
        .max(100, "productNameLong"),
      productHsCode: Yup.string()
        .matches(DIGITS_ONLY, "invalidProductHsCode")
        .matches(ONLY_POSITIVE_NUMBERS, "zeroStartHsCode")
        .min(6, "productHsCodeLength")
        .max(10, "productHsCodeLength")
        .required("productHsCodeRequired"),
      productDescription: Yup.object().shape({
        pureText: Yup.string()
          .required("productDescriptionRequired")
          .max(1000, "productDescriptionLong"),
        richText: Yup.string()
      }),
      productImage: Yup.array()
        .required("productImageRequired")
        .min(1, "productImageRequired")
        .max(15, "productImageExceeded"),
      productVideo: Yup.mixed().nullable(),
      minimumOrderQuantity: Yup.number()
        .min(1, "MinimumOrderQuantityLow")
        .integer("invalidMinimumOrderQuantity")
        .nullable()
        .test(
          "is-minimum-order-quantity-long",
          "minimumOrderQuantityLong",
          function (value) {
            if (!!value) {
              const valueAsString = value.toString();
              return valueAsString.length <= 6;
            }
            return true;
          }
        ),

      orderUnit: Yup.mixed().when("minimumOrderQuantity", {
        is: (minQuantity) => !!minQuantity,
        then: (schema) => schema.required("orderUnitRequired"),
        otherwise: (schema) => schema.nullable()
      }),
      orderUnitOther: Yup.string()
        .nullable()
        .when("orderUnit", {
          is: (orderUnitOtherValue) =>
            orderUnitOtherValue?.label === "Other",
          then: (schema) => schema.required("orderUnitOtherRequired"),
          otherwise: (schema) => schema
        }),

      specification: Yup.array()
        .of(
          Yup.object().shape({
            specificationName: Yup.mixed().nullable(),
            specificationValue: Yup.object()
              .shape({
                file: Yup.mixed().when("specificationName", {
                  is: PRODUCT_SPECIFICATION_TYPES.catalogue,
                  then: Yup.mixed().required("catalogueRequired")
                }),
                Name: Yup.string().when("specificationName", {
                  is: (value) => !!value,
                  then: Yup.string().required(
                    "specificationValueRequired"
                  )
                })
              })
              .test(
                "is-name-filled",
                "specificationValueRequired",
                function (value) {
                  const { specificationName } = this.parent;
                  if (
                    specificationName &&
                    specificationName.label?.toLowerCase() !==
                      PRODUCT_SPECIFICATION_TYPES.catalogue &&
                    Object.keys(specificationName).length !== 0
                  ) {
                    return value?.Name && value?.Name !== "";
                  }

                  return true;
                }
              )
              .test(
                "is-catalogue-filled",
                "catalogueRequired",
                function (value) {
                  const { specificationName } = this.parent;
                  if (
                    specificationName &&
                    specificationName.label?.toLowerCase() ===
                      PRODUCT_SPECIFICATION_TYPES.catalogue
                  ) {
                    return (
                      (value?.file &&
                        Object.keys(value.file).length !== 0) ||
                      (value?.Name && value?.Name !== "")
                    );
                  }

                  return true;
                }
              )
              .nullable(),
            SpecificationOtherName: Yup.string()
              .test(
                "is-other-name-filled",
                "SpecificationOtherNameRequired",
                function (value) {
                  const { specificationName } = this.parent;
                  if (
                    specificationName &&
                    specificationName.label?.toLowerCase() ===
                      PRODUCT_SPECIFICATION_TYPES.other &&
                    Object.keys(specificationName).length !== 0
                  ) {
                    return value && value !== "";
                  }

                  return true;
                }
              )
              .test(
                "is-other-name-repeated",
                "SpecificationOtherNameRepeated",
                function (value) {
                  const { specification } = this.options.context;
                  const { index } = this.options;
                  let otherCounter = 0;
                  const otherSpecificArrayIndexes = [];
                  specification.forEach(
                    (specific, specificationIndex) => {
                      if (
                        specific?.specificationName?.label === "Other"
                      ) {
                        otherCounter += 1;
                        if (index !== specificationIndex) {
                          otherSpecificArrayIndexes.push(specific);
                        }
                      }
                    }
                  );
                  if (value === null) {
                    return true;
                  }
                  if (otherCounter === 1) {
                    return true;
                  } else {
                    return otherSpecificArrayIndexes.every(
                      (item) =>
                        !!value &&
                        item.SpecificationOtherName !== value
                    );
                  }
                }
              )
              .nullable()
          })
        )
        .max(10, "maxSpecificationsExceeded")
        .nullable(),
      priceRange: Yup.array().of(
        Yup.object().shape({
          minimumPriceRangeQuantity: Yup.number()
            .required("minimumPriceRangeQuantityRequired")
            .min(1, "MinimumPriceRangeQuantityLow")
            .integer("invalidMinimumPriceRangeQuantity")
            .positive("invalidMinimumPriceRangeQuantity")
            .test(
              "is-greater-than-previous-max",
              "overlap",
              function (value) {
                const { priceRange } = this.options.context;
                const { index } = this.options;
                if (
                  index > 0 &&
                  priceRange &&
                  priceRange[index - 1]
                ) {
                  const previousMax =
                    priceRange[index - 1].maximumPriceRangeQuantity;
                  return value > previousMax;
                }
                return true;
              }
            )
            .test(
              "is-first-filled",
              "emptyFirstRange",
              function (value) {
                const { priceRange } = this.options.context;
                const { index } = this.options;
                if (
                  index === 0 &&
                  priceRange[0].maximumPriceRangeQuantity &&
                  priceRange[0].maximumPriceRangeQuantity !== null
                ) {
                  return value && value !== null && value !== "";
                }
                return true;
              }
            )
            .test(
              "is-minimum-price-range-quantity-long",
              "minimumPriceRangeQuantityLong",
              function (value) {
                if (!!value) {
                  const valueAsString = value.toString();
                  return valueAsString.length <= 6;
                }
                return true;
              }
            ),
          maximumPriceRangeQuantity: Yup.number()
            .required("maximumPriceRangeQuantityRequired")
            .min(
              Yup.ref("minimumPriceRangeQuantity"),
              "maximumOrderQuantitySmaller"
            )
            .integer("invalidMaximumPriceRangeQuantity")
            .positive("invalidMaximumPriceRangeQuantity")
            .test(
              "is-smaller",
              "maximumOrderQuantitySmaller",
              function (value) {
                const { minimumPriceRangeQuantity } = this.parent;
                if (
                  minimumPriceRangeQuantity === undefined ||
                  minimumPriceRangeQuantity === null ||
                  minimumPriceRangeQuantity === ""
                ) {
                  return true;
                }
                return value > minimumPriceRangeQuantity;
              }
            )
            .test(
              "is-maximum-price-range-quantity-long",
              "maximumPriceRangeQuantityLong",
              function (value) {
                if (!!value) {
                  const valueAsString = value.toString();
                  return valueAsString.length <= 6;
                }
                return true;
              }
            ),
          pricePerUnit: Yup.string()
            .required("pricePerUnitRequired")
            .matches(PRICE_REGEX, "invalidPricePerUnit")
            .when("minimumPriceRangeQuantity", {
              is: (maxValue) =>
                maxValue !== null && maxValue && maxValue !== "",
              then: (schema) =>
                schema.required("pricePerUnitRequired"),
              otherwise: (schema) => schema
            })
        })
      ),
      keyword: Yup.array()
        .of(
          Yup.object().shape({
            Name: Yup.string().trim(),
            id: Yup.number()
          })
        )
        .max(keywordsCountLimit, "keywordsLimitExceeded")
    }),
    onSubmit: async ({
      category,
      subCategory,
      productName,
      productHsCode,
      productDescription,
      productImage,
      productVideo,
      minimumOrderQuantity,
      orderUnit,
      orderUnitOther,
      specification,
      priceRange,
      keyword
    }) => {
      if (!isPublishedEditedProduct) {
        dispatch(showHideLoader(true));
        dispatch(
          showHideLoaderText({
            loadingText: id ? loaderEditText : loaderAddText,
            showText: true
          })
        );
      }
      const productData = {
        Name: productName.trim(),
        HsCode: productHsCode.trim(),
        Description: productDescription.richText.trim(),
        CategoryId: category.id,
        SubCategoryId: subCategory.id
      };

      const defaultImageFile = productImage.find(
        (image) => image.isDefault === true
      );

      if (Object.keys(priceRange).length) {
        const tempPriceRanges = [
          ...priceRange
            .map((range) => {
              if (range.minimumPriceRangeQuantity !== "") {
                return {
                  From: +range.minimumPriceRangeQuantity,
                  To: +range.maximumPriceRangeQuantity,
                  Price: +range.pricePerUnit,
                  ProductId: range.ProductId ?? id ?? 0,
                  Id: range.Id ?? 0
                };
              } else return "";
            })
            .filter((range) => {
              return range !== "";
            })
        ];
        if (tempPriceRanges.length !== 0) {
          productData.PriceRanges = [...tempPriceRanges];
        } else {
          productData.PriceRanges = [];
        }
      } else {
        productData.PriceRanges = [];
      }

      if (
        +minimumOrderQuantity !== 0 &&
        minimumOrderQuantity !== ""
      ) {
        productData.MinOrderQuantity = +minimumOrderQuantity;
        if (orderUnit !== "" && orderUnit) {
          productData.MoqId = orderUnit.id;
        }
        if (orderUnitOther !== "" && orderUnitOther) {
          productData.MoqOtherUnit = orderUnitOther;
        }
      }

      if (Object.keys(keyword).length) {
        productData.Keywords = [
          ...keyword.map((key) => {
            return {
              Name: key.Name,
              ProductId: key.ProductId ?? id ?? 0,
              Id: key.Id ?? 0
            };
          })
        ];
      } else {
        productData.Keywords = [];
      }

      if (productImage) {
        let uploadedDefaultImgContainerName = "";
        if (deletedImgsArray.length !== 0) {
          await Promise.all(
            deletedImgsArray.map(async (img) => {
              if (img.isDefault === false) {
                try {
                  const imageName = img.URL.split("images/")[1];
                  if (
                    imageName !== undefined &&
                    imageName.length > 0
                  ) {
                    await deleteBlob(imageName, "images");
                  }
                } catch (err) {
                  console.log(err);
                }
              }
            })
          );
        }

        const newUploadsArrayNames = await Promise.all(
          productImage.map(async (img) => {
            let fileName = "";
            if (img.isDefault === true && img.isNew) {
              try {
                fileName = await UploadBlobToContainer(
                  {
                    blob: img,
                    name: img.name,
                    type: img.type
                  },
                  BLOB_CONTAINERS.images
                );
                uploadedDefaultImgContainerName = fileName;
              } catch (err) {
                console.log(err);
              }
            } else {
              try {
                if (img.isNew) {
                  fileName = await UploadBlobToContainer(
                    {
                      blob: img,
                      name: img.name,
                      type: img.type
                    },
                    BLOB_CONTAINERS.images
                  );
                  return {
                    MediaName: fileName,
                    OriginalMediaName: img.name || img.path,
                    AttachmentTypeId: 1,
                    ProductId: 0
                  };
                }
              } catch (err) {
                console.log(err);
              }
            }
            return null;
          })
        );

        const filteredUploadsArrayNames = newUploadsArrayNames.filter(
          (item) => item !== null
        );
        const BEImages = productImage
          .map((item) => {
            if (item.isDefault) {
              const itemName = item.URL.split("/images/")[1];
              productData.DefaultImage = itemName;
              productData.OriginalDefaultImageName =
                item?.OriginalMediaName;
              return "";
            } else if (!item.isNew) {
              const itemName = item.URL.split("/images/")[1];
              item.MediaName = itemName;
              return item;
            } else return "";
          })
          .filter((item) => {
            return item !== "";
          });

        if (uploadedDefaultImgContainerName) {
          productData.DefaultImage = uploadedDefaultImgContainerName;
          productData.OriginalDefaultImageName =
            defaultImageFile.name;
        }
        if (BEImages.length !== 0) {
          productData.Attachments = [...BEImages];
        }
        if (filteredUploadsArrayNames.length >= 1) {
          productData.Attachments = [
            ...BEImages,
            ...filteredUploadsArrayNames
          ];
        } else {
          productData.Attachments = [...BEImages];
        }
      }

      if (productVideo) {
        if (
          productVideo.file &&
          Object.keys(productVideo.file).length
        ) {
          try {
            let fileName = "";
            fileName = await UploadBlobToContainer(
              {
                blob: productVideo.file,
                name: productVideo.file.name,
                type: productVideo.file.type
              },
              BLOB_CONTAINERS.videos
            );
            productData.Attachments = [
              ...productData.Attachments,
              {
                MediaName: fileName,
                OriginalMediaName: productVideo.file.name,
                AttachmentTypeId: 2,
                ProductId: 0
              }
            ];
          } catch (err) {
            console.log(err);
          }
        } else if (productVideo.delete) {
          await deleteBlob(
            productVideo.MediaName.split("/videos/")[1],
            "videos"
          );
        } else {
          let fileName = "";
          fileName = productVideo?.MediaName?.split("/videos/")[1];
          productData.Attachments = [
            ...productData.Attachments,
            {
              MediaName: fileName,
              OriginalMediaName: productVideo.OriginalMediaName,
              AttachmentTypeId: 2,
              ProductId: 0
            }
          ];
        }
      }

      if (Object.keys(specification).length) {
        let tempSpecifications = [];
        tempSpecifications = await Promise.all(
          specification.map(async (specific, index) => {
            if (
              specific.specificationValue.Name &&
              !!Object.keys(specific.specificationValue).length
            ) {
              if (specific.specificationValue.isNew) {
                if (
                  specific.specificationValue.file &&
                  !!specific?.specificationValue?.file.name
                ) {
                  try {
                    let fileName = "";
                    fileName = await UploadBlobToContainer(
                      {
                        blob: specific.specificationValue.file,
                        name: specific.specificationValue.file.name,
                        type: specific.specificationValue.file.type
                      },
                      BLOB_CONTAINERS.specifications
                    );
                    return {
                      Value: fileName,
                      OriginalMediaValue:
                        specific.specificationValue.file.name,
                      SpecificationOtherName:
                        specific.specificationName.label,
                      SubCategorySpecificationID: specificationsList
                        .filter(
                          (listItem) =>
                            listItem.Name ===
                            specific.specificationName.label
                        )
                        .map((listItem) => ({
                          id: listItem.Id,
                          label: listItem.Name
                        }))[0].id,
                      ProductId: 0,
                      Id: 0
                    };
                  } catch (err) {
                    console.log(err);
                  }
                } else {
                  if (specific.SpecificationOtherName) {
                    return {
                      Value: specific.specificationValue.Name?.trim(),
                      SpecificationOtherName:
                        specific.SpecificationOtherName.trim(),
                      SubCategorySpecificationID: specificationsList
                        .filter(
                          (listItem) =>
                            listItem.Name ===
                            specific.specificationName.label
                        )
                        .map((listItem) => ({
                          id: listItem.Id,
                          label: listItem.Name
                        }))[0]?.id,
                      ProductId: 0,
                      Id: 0
                    };
                  } else {
                    return {
                      Value: specific.specificationValue.Name?.trim(),
                      SubCategorySpecificationID: specificationsList
                        .filter(
                          (listItem) =>
                            listItem.Name ===
                            specific.specificationName.label
                        )
                        .map((listItem) => ({
                          id: listItem.Id,
                          label: listItem.Name
                        }))[0]?.id,
                      ProductId: 0,
                      Id: 0
                    };
                  }
                }
              } else {
                if (deletedSpecificationIndex === index) {
                  await deleteBlob(
                    specific.specificationValue.Name,
                    "specifications"
                  );
                }
                return {
                  Value: specific.specificationValue.Name.includes(
                    "/specifications/"
                  )
                    ? specific.specificationValue.Name.split(
                        "/specifications/"
                      )[1]
                    : specific.specificationValue.Name,
                  OriginalMediaValue: specific.OriginalMediaValue,
                  SpecificationOtherName:
                    specific.SpecificationOtherName,
                  SubCategorySpecificationID:
                    +specific.SubCategorySpecificationID,
                  ProductId: specific.ProductId ?? id ?? 0,
                  Id: specific.Id ?? 0
                };
              }
            } else return "";
          })
        );
        tempSpecifications = tempSpecifications.filter((specific) => {
          return specific !== "";
        });
        if (tempSpecifications.length !== 0) {
          productData.Specifications = [...tempSpecifications];
        } else {
          productData.Specifications = [];
        }
      } else {
        productData.Specifications = [];
      }
      if (id) {
        productData.ProductStatusId = productStatusIdState;
        if (isPublishedEditedProduct) {
          setPublishEditProductDetails({
            loaderEditText,
            name: productDetails.Name,
            data: {
              Id: id,
              ...productData
            }
          });
          setIsPublishedEditedProductModalOpen(true);
        } else {
          dispatch(
            editProductRequest({
              data: {
                Id: id,
                ...productData
              },
              navigate
            })
          );
        }
      } else {
        productData.ProductStatusId = productStatusIdState;
        dispatch(
          addProductRequest({
            data: {
              Id: 0,
              ...productData
            },
            navigate
          })
        );
      }
    }
  });
  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    setValues,
    setTouched,
    handleSubmit,
    setFieldError
  } = formik;

  const handleCloseModal = () => {
    setIsPublishedEditedProductModalOpen(false);
    setIsChangeShowcaseProductSubcategoryModalOpen(false);
    setPublishEditProductDetails({});
  };

  const handleDeleteImage = (index) => {
    const isDefaultImg = values.productImage[index].isDefault;
    if (isDefaultImg) {
      values.productImage[index].isDefault = false;
    }

    if (values.productImage[index].id) {
      values.productImage[index]._destroy = true;
    } else if (!values.productImage[index].isNew) {
      setDeletedImgsArray([
        ...deletedImgsArray,
        { URL: values.productImage[index] }
      ]);
    }

    if (values.productImage[index]) {
      const tempArray = [...values.productImage];
      tempArray.splice(index, 1);
      if (tempArray.length && isDefaultImg)
        tempArray[0].isDefault = true;
      setFieldValue("productImage", tempArray);
    }
  };

  const handleDefaultImage = (index) => {
    let productImageCopy = [...values.productImage];
    if (id) {
      productImageCopy = productImageCopy.map((image, arrayIndex) => {
        if (image.isDefault) {
          image.isDefault = false;
          image.AttachmentTypeId = 1;
          image.ProductId = id;
          image.OriginalMediaName = "";
          return image;
        } else if (index === arrayIndex) {
          image.isDefault = true;
          return image;
        } else return image;
      });
    } else {
      productImageCopy.forEach((image) => {
        if (image.isDefault) {
          image.isDefault = false;
          image.AttachmentTypeId = 1;
          image.ProductId = id;
          image.OriginalMediaName = "";
        }
      });
      productImageCopy[index].isDefault = true;
    }
    setFieldValue("productImage", productImageCopy);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      values.keyword.length < keywordsCountLimit &&
      !!keywordState.Name &&
      !!keywordState.Name.trim()
    ) {
      if (keywordState.Name.trim().split(" ").length <= 2) {
        if (TWO_WORDS_ONLY.test(keywordState.Name)) {
          setFieldValue("keyword", [
            ...values.keyword,
            {
              ...keywordState,
              Name: keywordState.Name.trim()
                .split(" ")
                .join()
                .replace(",", " ")
            }
          ]);
          setKeywordState({ Name: "", ProductId: 0 });
        } else {
          setFieldError("keyword", "invalidKeywordCharacter");
        }
      } else {
        setFieldError("keyword", "keywordSizeExceeded");
      }
    }
  };

  const handleDeleteKeyword = (index) => {
    const tempArray = [...values.keyword];
    const modifiedArray = tempArray.filter((key, keyIndex) => {
      return keyIndex !== index;
    });
    setFieldValue("keyword", modifiedArray);
  };

  useEffect(() => {
    return () => {
      setTranscribedText("");
      setWrittenDescription("");
      setIsAudioDescription(false);
      dispatch(getProductVersionDetailsResponse({}));
      dispatch(sendAddProductSupportEmailResponse(false));
      dispatch(getTranscribedAudioIdResponse(null));
      dispatch(getTranscribedAudioTextResponse(null));
      dispatch(isAudioTranscribedRequest(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getCategoriesListRequest());
    dispatch(getMOQListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (values.category && Object.keys(values?.category).length) {
      dispatch(
        getSubCategoriesListRequest({
          id: values.category?.id
        })
      );
    }
  }, [categoriesList, values.category]);

  useEffect(() => {
    if (
      values.subCategory &&
      Object.keys(values?.subCategory).length !== 0
    ) {
      dispatch(
        getSpecificationsListRequest({
          id: values.subCategory?.id
        })
      );
    }
  }, [subCategoriesList, values.subCategory]);

  useEffect(() => {
    if (id) {
      dispatch(
        getProductVersionDetailsRequest({
          id
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (moqList) {
      setMoqUnitLookupListState([
        ...moqList.map((MoqUnit) => {
          return {
            id: MoqUnit.Id,
            label: MoqUnit.Name
          };
        })
      ]);
    }

    if (categoriesList) {
      setCategoryLookupListState([
        ...categoriesList.map((category) => {
          return {
            id: category.Id,
            label: category.Name
          };
        })
      ]);
    }
    if (subCategoriesList) {
      setSubCategoryLookupListState([
        ...subCategoriesList.map((subCategory) => {
          return {
            id: subCategory.Id,
            label: subCategory.Name
          };
        })
      ]);
    }
  }, [
    moqList,
    categoriesList,
    subCategoriesList,
    specificationsList
  ]);

  const filteredOptionsHandler = (index) => {
    if (specificationsList) {
      const tempArray = [...values.specification];
      const tempArrayIds = tempArray.map((specific) => {
        return specific.specificationName?.id;
      });
      const filteredOptions = specificationsList
        .filter(
          (specific) =>
            specific.Name === "Other" ||
            specific.Name ===
              values.specification[index]?.specificationName?.label ||
            !tempArrayIds.includes(specific.Id)
        )
        .map((specific) => ({
          id: specific.Id,
          label: specific.Name
        }));
      return filteredOptions;
    }
  };

  useEffect(() => {
    if (productDetails && Object.keys(productDetails).length) {
      const {
        Name,
        HsCode,
        Description,
        DefaultImage,
        Attachments,
        MinOrderQuantity,
        MoqOtherUnit,
        PriceRanges,
        Keywords,
        ProductStatusId,
        OriginalDefaultImageName,
        CategoryName,
        CategoryId,
        SubCategoryName,
        SubCategoryId,
        MoqUnitName,
        MOQId,
        Specifications
      } = productDetails;

      setIsPublishedEditedProduct(
        ProductStatusId ===
          PRODUCT_STATUS_WITH_ID.pending.ProductStatusId
      );
      setWrittenDescription({
        pureText: convertRichTextToPlainText(Description),
        richText: Description
      });
      setValues({
        ...values,
        category: { label: CategoryName, id: CategoryId },
        subCategory: { label: SubCategoryName, id: SubCategoryId },
        orderUnit:
          MoqUnitName && MOQId
            ? {
                label: MoqUnitName,
                id: MOQId
              }
            : null,
        specification: !!Specifications.length
          ? Specifications.map((specific) => {
              return {
                specificationName: {
                  label: specific.SpecificationOtherName,
                  id: specific.SubCategorySpecificationID
                },
                specificationValue: {
                  Name: specific.Value,
                  file: {},
                  isNew: false
                },
                OriginalMediaValue: specific.OriginalMediaValue,
                SpecificationOtherName:
                  specific.SpecificationOtherName ?? "",
                SubCategorySpecificationID:
                  +specific.SubCategorySpecificationID,
                ProductId: specific.ProductId ?? id ?? 0,
                Id: specific.Id ?? 0
              };
            })
          : [],
        productName: Name,
        productHsCode: HsCode,
        productDescription: {
          pureText: Description,
          richText: `<p>${Description}</p>`
        },
        minimumOrderQuantity: MinOrderQuantity ?? "",
        productImage: [
          {
            OriginalMediaName: OriginalDefaultImageName,
            id,
            URL: DefaultImage,
            isDefault: true,
            AttachmentTypeId: 1,
            ProductId: +id,
            isNew: false
          },
          ...Attachments.filter(
            (attach) => attach.AttachmentTypeId === 1
          ).map((attach) => {
            return {
              OriginalMediaName: attach.OriginalMediaName,
              URL: attach.MediaName,
              AttachmentTypeId: attach.AttachmentTypeId,
              id: attach.id ?? 0,
              ProductId: attach.ProductId,
              isDefault: false,
              isNew: false
            };
          })
        ],
        productVideo: Attachments.filter(
          (attach) => attach.AttachmentTypeId === 2
        ).map((attach) => {
          return {
            OriginalMediaName: attach.OriginalMediaName,
            MediaName: attach.MediaName,
            AttachmentTypeId: attach.AttachmentTypeId,
            id: !!attach?.id,
            ProductId: attach.ProductId,
            isNew: false,
            type: `.${attach.MediaName.split(".")[1]}`
          };
        })[0],
        orderUnitOther: MoqUnitName === "Other" ? MoqOtherUnit : "",
        priceRange: PriceRanges.map((range) => {
          return {
            Id: range.Id,
            minimumPriceRangeQuantity: range.From,
            maximumPriceRangeQuantity: range.To,
            pricePerUnit: range.Price,
            ProductId: range.ProductId
          };
        }),
        keyword: Keywords.map((keyword) => {
          return {
            Id: keyword.Id,
            Name: keyword.Name,
            ProductId: keyword.ProductId
          };
        })
      });
      setProductStatusIdState(ProductStatusId);
    }
  }, [productDetails]);

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone"
        });
        setIsMicroPhoneEnabled(permissionStatus.state === "granted");
      } catch (error) {
        setIsMicroPhoneEnabled(false);
      }
    };

    checkMicrophonePermission();
  }, []);

  useEffect(() => {
    const isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox) {
      const checkMicrophoneAccess = async () => {
        try {
          let stream;
          if (isRecordingClicked) {
            stream = await navigator.mediaDevices.getUserMedia({
              audio: true
            });
          }
          setIsMicroPhoneEnabled(true);
          stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
          setIsMicroPhoneEnabled(false);
        }
      };
      checkMicrophoneAccess();
    }
  }, []);

  useEffect(() => {
    if (!isMicroPhoneEnabled && isRecordingClicked) {
      showErrorMsg(inputsValidations.micDisabled);
      setIsRecordingClicked(false);
    }
  }, [isMicroPhoneEnabled, isRecordingClicked]);

  useEffect(() => {
    if (isAudioDescription) {
      setFieldTouched("productDescription");
      setFieldValue("productDescription", {
        pureText: transcribedText,
        richText: transcribedText
      });
    } else {
      setFieldValue("productDescription", {
        pureText: writtenDescription.pureText,
        richText: writtenDescription.richText
      });
    }
    if (isLoading) {
      dispatch(showHideLoader(false));
      dispatch(
        showHideLoaderText({
          loadingText: "",
          showText: false
        })
      );
    }
  }, [
    isAudioDescription,
    writtenDescription,
    isAudioTranscribeSuccessful,
    productDetails,
    isRecordingClicked
  ]);

  return (
    <div className="add-edit-product-container mb-4 d-flex flex-column">
      <div className="page-title pb-4">
        <Typography variant="h5" className="title-color">
          {id ? editPageTitle : addPageTitle}
        </Typography>
      </div>
      <div>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={handleSubmit}
            className="add-edit-product-form-container d-flex flex-column gap-5">
            <ScrollToFieldError
              formik={formik}
              submitButtonClicked={submitButtonClicked}
              handleSubmitButtonClicked={setSubmitButtonClicked}
            />
            <div
              className={`category-sub-category-container bg-white d-flex flex-column w-100 p-5 bg-white border-radius-8`}>
              <div className="title-placeholder-btn-container d-flex flex-column">
                <div className={`block-title  pb-4 `}>
                  <Typography variant="h6">
                    {productDetailsBlock}
                  </Typography>
                </div>
              </div>

              <div
                className={`product-inputs-container d-flex  flex-column gap-4 justify-content-between flex-wrap`}>
                <div
                  className={`product-inputs-container d-flex  justify-content-between flex-wrap`}>
                  <Select
                    required
                    key={category}
                    wrapperClass="product-form-field"
                    labelClassName="pb-1 font-medium"
                    label={category}
                    placeholder={categoryPlaceholder}
                    options={categoryLookupListState}
                    onChange={(value) => {
                      setTouched({
                        ...touched,
                        category: true,
                        specification: true
                      });
                      setValues({
                        ...values,
                        category: value,
                        subCategory: null,
                        specification: [
                          {
                            specificationName: "",
                            specificationValue: {
                              Name: "",
                              file: {},
                              isNew: true
                            },
                            SpecificationOtherName: null
                          }
                        ]
                      });
                    }}
                    value={values.category}
                    hasError={!!(touched.category && errors.category)}
                    errMsg={errors.category}
                    id="category"
                    name="category"
                  />
                  <Select
                    required
                    disabled={!values.category && !id}
                    key={subCategory}
                    wrapperClass="product-form-field"
                    labelClassName="pb-1 font-medium"
                    label={subCategory}
                    placeholder={subCategoryPlaceholder}
                    options={subCategoryLookupListState}
                    onChange={(value) => {
                      if (
                        values?.subCategory?.label &&
                        !!id &&
                        productDetails &&
                        productDetails.IsInShowcase
                      ) {
                        setIsChangeShowcaseProductSubcategoryModalOpen(
                          true
                        );
                        setTempSubcategory(value);
                      } else {
                        setTouched({
                          ...touched,
                          subCategory: true,
                          specification: true
                        });
                        setValues({
                          ...values,
                          subCategory: value,
                          specification: [
                            {
                              specificationName: "",
                              specificationValue: {
                                Name: "",
                                file: {},
                                isNew: true
                              },
                              SpecificationOtherName: null
                            }
                          ]
                        });
                      }
                    }}
                    value={values.subCategory}
                    hasError={
                      !!(touched.subCategory && errors.subCategory)
                    }
                    errMsg={errors.subCategory}
                    id="subCategory"
                    name="subCategory"
                  />
                </div>
                <div className="product-inputs-container d-flex  justify-content-between flex-wrap">
                  <Input
                    required
                    inputWrapperClass={"product-form-field"}
                    key={productName}
                    label={productName}
                    labelClassName="pb-2 font-medium  main-text-color"
                    placeholder={productNamePlaceholder}
                    onChange={(value) => {
                      if (!value || !!value.trim()) {
                        const modifiedValue = value.replace(
                          /\s\s+/g,
                          " "
                        );
                        setFieldTouched("productName");
                        setFieldValue("productName", modifiedValue);
                      }
                    }}
                    value={values.productName}
                    isInputHasErr={
                      !!(touched.productName && errors.productName)
                    }
                    errMsg={errors.productName}
                    name="productName"
                    id="productName"
                  />
                  <Input
                    endAdornment={
                      <Tooltip
                        classes={{
                          tooltip: "light-blue-tooltip-fill-icon"
                        }}
                        className="text-secondary"
                        title={
                          <div className="d-flex gap-2 w-90">
                            <InfoOutlined />
                            {productHsCodeTooltip}
                          </div>
                        }>
                        <InfoOutlined />
                      </Tooltip>
                    }
                    inputWrapperClass={"product-form-field"}
                    key={productHsCode}
                    label={productHsCode}
                    labelClassName={
                      "pb-2 font-medium  main-text-color"
                    }
                    placeholder={productHsCodePlaceholder}
                    onChange={(value) => {
                      setFieldTouched("productHsCode");
                      setFieldValue("productHsCode", value);
                    }}
                    value={values.productHsCode}
                    isInputHasErr={
                      !!(
                        touched.productHsCode && errors.productHsCode
                      )
                    }
                    errMsg={errors.productHsCode}
                    name="productHsCode"
                    id="productHsCode"
                  />
                </div>
                <RichTextInput
                  voiceInput
                  handleIsRecordingClicked={setIsRecordingClicked}
                  setTranscribedText={setTranscribedText}
                  setIsAudioDescription={setIsAudioDescription}
                  isAudioDescription={isAudioDescription}
                  isAudioTranscribeSuccessful={
                    isAudioTranscribeSuccessful
                  }
                  setIsAudioTranscribeSuccessful={
                    setIsAudioTranscribeSuccessful
                  }
                  required
                  key={productDescription}
                  label={productDescription}
                  placeholder={productDescriptionPlaceholder}
                  labelClassName="font-medium   main-text-color"
                  inputWrapperClass={"product-description"}
                  inputClass={`${
                    touched.productDescription &&
                    errors.productDescription &&
                    "rich-text-error"
                  }`}
                  rows={4}
                  onChange={(value, pureTextValue) => {
                    setFieldTouched("productDescription");
                    setFieldValue("productDescription", {
                      richText: value,
                      pureText: pureTextValue
                    });
                    if (!isAudioDescription)
                      setWrittenDescription({
                        richText: value,
                        pureText: pureTextValue
                      });
                    else if (transcribedText !== "") {
                      setTranscribedText(value);
                    }
                  }}
                  value={values.productDescription.richText}
                  isInputHasErr={
                    !!(
                      touched?.productDescription &&
                      errors?.productDescription?.pureText
                    )
                  }
                  errMsg={errors.productDescription?.pureText}
                  name="productDescription.pureText"
                  id="productDescription.pureText"
                />
              </div>

              {!isAudioTranscribeSuccessful && (
                <div className="block-title error-msg mt-4">
                  <Typography>
                    {inputsValidations.audioFailed}
                  </Typography>
                </div>
              )}
            </div>
            <div
              className={`product-image-container bg-white d-flex flex-column w-100  p-5 bg-white border-radius-8`}>
              <div className="title-placeholder-btn-container d-flex flex-column">
                <div
                  className={`block-title d-flex gap-2 align-items-center pb-2 `}>
                  <Typography variant="h6">
                    {productImagesBlock}
                  </Typography>
                  <Tooltip
                    classes={{
                      tooltip: "light-blue-tooltip-fill-icon"
                    }}
                    className="text-secondary"
                    title={
                      <ul className="mb-0 ps-0">
                        <li>
                          {inputsValidations.productImageExceeded}
                        </li>
                        <li>
                          {inputsValidations.uploadImgSizeError}
                        </li>
                        <li>
                          {inputsValidations.uploadImgResolutionError}
                        </li>
                        <li>
                          {inputsValidations.uploadImgTypeError}
                        </li>
                      </ul>
                    }>
                    <InfoOutlined />
                  </Tooltip>
                </div>
              </div>
              <div
                className={`product-inputs-container`}
                id="productImage">
                <div className="row">
                  <AddProductImageGuide />
                  <div className="block-title align-items-center text-secondary pb-4">
                    <Typography>
                      {`${values.productImage.length === 0 ? productImagesBlockPlaceholder : chooseDefaultImageBlockPlaceholder} `}
                    </Typography>
                  </div>
                  {values.productImage.length === 0 && (
                    <div className="product-inputs-container">
                      <Upload
                        hideErrMsg
                        disabled={values.productImage.length >= 15}
                        touchInput={() => {
                          setFieldTouched("productImage");
                        }}
                        required
                        label={<div ref={uploadElementRef}></div>}
                        onChange={(imgs, files) => {
                          setFieldTouched("productImage");
                          if (values.productImage.length < 15) {
                            const tempFilesArray = [...files];
                            const modifiedFilesArray =
                              tempFilesArray.map((file, index) => {
                                if (index === 0) {
                                  file.URL = imgs[index];
                                  file.isDefault = true;
                                  file.isNew = true;
                                } else {
                                  file.URL = imgs[index];
                                  file.isNew = true;
                                  file.isDefault = false;
                                }
                                return file;
                              });
                            setFieldValue("productImage", [
                              ...values.productImage,
                              ...Array.from(modifiedFilesArray)
                            ]);
                          } else {
                          }
                        }}
                        name="productImage"
                        multiple={true}
                        onChangeError={setImgUploadError}
                        isInputHasErr={
                          !!imgUploadError ||
                          !!(
                            touched.productImage &&
                            errors.productImage
                          )
                        }
                        errMsg={imgUploadError || errors.productImage}
                      />
                    </div>
                  )}
                  <div className="images-container d-flex flex-wrap">
                    {values.productImage.map((image, index) => {
                      return (
                        <div
                          key={index}
                          className={`single-image-container ${values.productImage[index].isDefault && "default-single-image-container"}`}>
                          <div className="container-options px-2 pt-1 d-flex justify-content-between ">
                            {image.isDefault ? (
                              <RadioButtonCheckedOutlined
                                className="pointer text-dark-blue  product-image-icon-active"
                                onClick={() => {
                                  if (
                                    !values.productImage[index]
                                      .isDefault
                                  )
                                    handleDefaultImage(index);
                                }}
                              />
                            ) : (
                              <Tooltip
                                placement="top"
                                classes={{
                                  tooltip:
                                    "light-blue-tooltip-img-icon"
                                }}
                                title={
                                  <span className="fsize-12">
                                    {defaultImg}
                                  </span>
                                }>
                                <div>
                                  <RadioButtonUnchecked
                                    className="pointer product-image-icon"
                                    onClick={() => {
                                      handleDefaultImage(index);
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                            <Tooltip
                              placement="top"
                              classes={{
                                tooltip: "light-blue-tooltip-img-icon"
                              }}
                              title={
                                <span className="fsize-12">
                                  {deleteImg}
                                </span>
                              }>
                              <div>
                                <CloseOutlined
                                  className="pointer product-image-icon"
                                  onClick={() => {
                                    handleDeleteImage(index);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                          <div className="product-image ">
                            <img src={image.URL} alt="" />
                          </div>
                        </div>
                      );
                    })}

                    {values.productImage.length >= 1 && (
                      <div className="single-image-container upload-more-card">
                        <div>
                          <Upload
                            hideErrMsg
                            disabled={
                              values.productImage.length >= 15
                            }
                            touchInput={() => {
                              setFieldTouched("productImage");
                            }}
                            className={`card-upload ${values.productImage.length >= 15 && "disabled"}`}
                            customPlaceholder
                            placeholderText={upload.uploadMore}
                            label={
                              <div ref={cardUploadElementRef}></div>
                            }
                            onChange={(imgs, files) => {
                              setFieldTouched("productImage");

                              if (values.productImage.length < 15) {
                                const tempFilesArray = [...files];
                                const modifiedFilesArray =
                                  tempFilesArray.map(
                                    (file, index) => {
                                      file.URL = imgs[index];
                                      file.isDefault = false;
                                      file.isNew = true;
                                      return file;
                                    }
                                  );
                                setFieldValue("productImage", [
                                  ...values.productImage,
                                  ...Array.from(modifiedFilesArray)
                                ]);
                              } else {
                                setFieldError(
                                  "productImage",
                                  "productImageExceeded"
                                );
                              }
                            }}
                            name="productImage"
                            id={"card-upload"}
                            multiple={true}
                            onChangeError={setImgUploadError}
                            isInputHasErr={
                              imgUploadError ||
                              (touched.productImage &&
                                errors.productImage)
                            }
                            errMsg={
                              imgUploadError || errors.productImage
                            }
                          />
                        </div>
                      </div>
                    )}
                    {(imgUploadError ||
                      (touched.productImage &&
                        inputsValidations[errors.productImage])) && (
                      <div className="w-100">
                        <FormHelperText
                          error={true}
                          className={`${"fsize-12 mt-2"} ${
                            lang === "en" ? "" : "text-end"
                          } `}>
                          {imgUploadError ||
                            (touched.productImage &&
                              inputsValidations[errors.productImage])}
                        </FormHelperText>
                      </div>
                    )}
                    <div className="product-video w-100  mt-4">
                      <div className="video-title mb-4">
                        <Typography
                          variant="span"
                          className="text-secondary ">
                          {addVideo}
                        </Typography>
                        <Typography
                          variant="span"
                          className="text-secondary ps-md-1 ">
                          {optional}
                        </Typography>
                        <Tooltip
                          classes={{
                            tooltip: "light-blue-tooltip-fill-icon"
                          }}
                          className="ms-2 text-secondary mt-md-0 mt-1"
                          title={
                            <ul className="mb-0 ps-0">
                              <li>
                                {
                                  inputsValidations.uploadVideoTypeError
                                }
                              </li>
                              <li>
                                {
                                  inputsValidations.uploadVideoSizeError
                                }
                              </li>
                            </ul>
                          }>
                          <InfoOutlined />
                        </Tooltip>
                      </div>
                      <div className="product-video-container bg-white d-flex flex-column w-100 bg-white border-radius-8">
                        <div className={`product-inputs-container`}>
                          <Upload
                            accept=".mp4"
                            userVideo={
                              values?.productVideo?.MediaName
                            }
                            onChange={(value, file) => {
                              if (file.length > 1) {
                                setFieldError(
                                  "productVideo",
                                  "productVideoExceeded"
                                );
                              } else {
                                setFieldTouched("productVideo");
                                setFieldValue("productVideo", {
                                  value,
                                  file,
                                  isNew: true
                                });
                              }
                            }}
                            onClear={() => {
                              setFieldTouched("productVideo");
                              setFieldValue("productVideo", {
                                ...values.productVideo,
                                delete: true
                              });
                            }}
                            label=""
                            backgroundImg={null}
                            id="productVideo"
                            isVideo={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`minimum-order-quantity-container bg-white d-flex flex-column w-100  p-5 bg-white border-radius-8`}>
              <div className="title-container d-flex  align-items-center pb-4 ">
                <div className="title-optional-container d-flex align-items-center gap-md-0 gap-1">
                  <Typography variant="h6">
                    {minimumOrderQuantityBlock}
                  </Typography>
                  <Typography className="text-secondary ps-md-1">
                    {optional}
                  </Typography>
                </div>
              </div>
              <div
                className={`product-inputs-container d-flex  justify-content-between align-items-start flex-wrap`}>
                <Input
                  inputWrapperClass={`${values.orderUnit?.label === "Other" ? " add-more-product-small-form-field" : "product-form-field"}`}
                  label={minimumOrderQuantity}
                  type={"number"}
                  labelClassName="pb-2 font-medium  main-text-color"
                  placeholder={minimumOrderQuantityPlaceholder}
                  onChange={(value) => {
                    setFieldTouched("minimumOrderQuantity");
                    setFieldValue("minimumOrderQuantity", value);
                    if (value !== "") {
                      setFieldTouched(
                        `priceRange[0].minimumPriceRangeQuantity`
                      );
                      setFieldValue(
                        `priceRange[0].minimumPriceRangeQuantity`,
                        value
                      );
                      if (
                        values.priceRange[0]
                          ?.maximumPriceRangeQuantity
                      ) {
                        setFieldTouched(
                          `priceRange[0].maximumPriceRangeQuantity`
                        );
                      }
                    }
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const pasteData = e.clipboardData.getData("text");
                    if (!/^\d+$/.test(pasteData)) {
                      e.preventDefault();
                    }
                  }}
                  value={values.minimumOrderQuantity}
                  isInputHasErr={
                    !!(
                      touched.minimumOrderQuantity &&
                      errors.minimumOrderQuantity
                    )
                  }
                  errMsg={errors.minimumOrderQuantity}
                  name="minimumOrderQuantity"
                  id="minimumOrderQuantity"
                />
                <Select
                  wrapperClass={`${values.orderUnit?.label === "Other" ? " add-more-product-small-form-field" : "product-form-field"}`}
                  labelClassName="pb-1 font-medium"
                  disabled={values.minimumOrderQuantity === ""}
                  label={unit}
                  placeholder={orderUnitPlaceholder}
                  options={moqUnitLookupListState}
                  onChange={(value) => {
                    setFieldTouched("orderUnit");
                    setValues({
                      ...values,
                      orderUnit: value
                    });
                    if (!value) {
                      setFieldTouched("orderUnitOther", false);
                      setFieldError("orderUnitOther", null);
                      setValues({
                        ...values,
                        orderUnit: null,
                        orderUnitOther: ""
                      });
                    }
                  }}
                  value={values.orderUnit}
                  hasError={!!(touched.orderUnit && errors.orderUnit)}
                  errMsg={errors.orderUnit}
                  id="orderUnit"
                  name="orderUnit"
                />

                {values.orderUnit?.label === "Other" && (
                  <Input
                    label={unit}
                    inputWrapperClass={`add-more-product-small-form-field`}
                    labelClassName="pb-2 font-medium  "
                    placeholder={orderUnitPlaceholder}
                    onChange={(value) => {
                      if (value.length <= 20) {
                        if (!value || !!value.trim()) {
                          const modifiedValue = value.replace(
                            /\s\s+/g,
                            " "
                          );
                          setFieldTouched("orderUnitOther");
                          setFieldValue(
                            "orderUnitOther",
                            modifiedValue
                          );
                        }
                      } else {
                        setFieldError(
                          "orderUnitOther",
                          "orderUnitOtherLong"
                        );
                      }
                    }}
                    value={values.orderUnitOther}
                    isInputHasErr={
                      !!(
                        touched.orderUnitOther &&
                        errors.orderUnitOther
                      )
                    }
                    errMsg={errors.orderUnitOther}
                    id="orderUnitOther"
                    name="orderUnitOther"
                  />
                )}
              </div>
              <div
                className={`keywords-container d-flex flex-column w-100 mt-4`}>
                <div className="title-placeholder-btn-container d-flex flex-column pb-4">
                  <div className="title-container d-flex  align-items-center pb-2 ">
                    <div className="title-tips-container gap-md-0 gap-2  d-flex align-items-md-center align-items-start  ">
                      <div className="d-flex flex-column flex-md-row flex-lg-row align-items-md-center gap-md-0 gap-1">
                        <Typography variant="h6">
                          {searchingKeywordsBlock}
                        </Typography>
                        <Typography className="text-secondary ps-md-1">
                          {optional}
                        </Typography>
                      </div>
                      <Tooltip
                        classes={{
                          tooltip: "light-blue-tooltip-fill-icon"
                        }}
                        className="text-secondary ms-md-2 mt-md-0 mt-1"
                        title={
                          <ul className="mb-0 ps-0">
                            <li>
                              {`${inputsValidations.keywordsNumberExceeded} ${keywordsCountLimit}`}
                            </li>
                            <li>
                              {inputsValidations.keywordSizeExceeded}
                            </li>
                            <li>{inputsValidations.keywordLong}</li>
                            <li>
                              {
                                inputsValidations.invalidKeywordCharacter
                              }
                            </li>
                          </ul>
                        }>
                        <InfoOutlined />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="block-subtitle text-secondary">
                    <Typography>
                      {searchingKeywordsBlockPlaceholder}
                    </Typography>
                  </div>
                </div>
                <div
                  className={`product-inputs-container d-flex flex-column justify-content-between flex-wrap`}>
                  <Input
                    disabled={
                      values.keyword.length === keywordsCountLimit
                    }
                    key={keyword}
                    inputWrapperClass="product-form-field"
                    label={keyword}
                    labelClassName="pb-2 font-medium  main-text-color"
                    placeholder={keywordPlaceholder}
                    onChange={(value) => {
                      if (value.length <= 20) {
                        const modifiedValue = value
                          .replace(/\s\s+/g, " ")
                          .replace("_", "");
                        setFieldTouched("keyword");
                        setKeywordState({
                          Name: modifiedValue,
                          ProductId: 0
                        });
                      } else {
                        setFieldError("keyword", "keywordLong");
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    value={keywordState.Name}
                    isInputHasErr={
                      !!(touched.keyword && errors.keyword)
                    }
                    errMsg={errors.keyword}
                    name="keyword"
                    id="keyword"
                  />
                </div>
                {values.keyword.length > 0 && (
                  <div className="keywords-container d-flex flex-wrap justify-content-start gap-3 py-3">
                    {values.keyword.length &&
                      values.keyword.map((keyword, index) => {
                        return (
                          <div key={index}>
                            <Chip
                              label={
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                  <span>{keyword.Name}</span>
                                  <CloseOutlined
                                    style={{ width: "1rem" }}
                                    className="pointer"
                                    onClick={() => {
                                      handleDeleteKeyword(index);
                                    }}
                                  />
                                </div>
                              }
                              className={` border-radius-8`}
                              sx={{
                                fontWeight: "semibold"
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
                {values.keyword.length >= keywordsCountLimit && (
                  <div className="block-title text-secondary ">
                    <Typography>
                      {`${inputsValidations.keywordsNumberExceeded} ${keywordsCountLimit}`}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`product-specification-container bg-white d-flex flex-column w-100  p-5  bg-white border-radius-8`}>
              <div
                className={`block-title  d-flex flex-column pb-4 `}>
                <div className="pb-2 col-xl-12 col-lg-12 d-flex align-items-center justify-content-between">
                  <div className="title-tips-container  d-flex  gap-lg-0 gap-md-0 gap-2  align-items-center">
                    <div className="title-optional-add-more-container gap-md-0 gap-1">
                      <Typography variant="h6">
                        {productSpecificationBlock}
                      </Typography>
                      <Typography className="text-secondary ps-md-1">
                        {optional}
                      </Typography>
                    </div>
                    <Tooltip
                      classes={{
                        tooltip: "light-blue-tooltip-fill-icon"
                      }}
                      className="text-secondary ms-md-2"
                      title={
                        <ul className="mb-0 ps-0">
                          <li>
                            {
                              inputsValidations.specificationNumberExceeded
                            }
                          </li>
                        </ul>
                      }>
                      <InfoOutlined />
                    </Tooltip>
                  </div>
                  <div className="add-more-btn-container">
                    <Button
                      disabled={values.specification.length >= 10}
                      className={"add-more-btn"}
                      outlined
                      label={
                        <span className="d-flex justify-content-center align-items-center">
                          <Add
                            className="add-icon"
                            fontSize="medium"
                          />
                          <span className="add-more-text ps-2">
                            {addMore}
                          </span>
                        </span>
                      }
                      onClick={() => {
                        if (values.specification.length < 10)
                          specificationFieldArrayRef.current.push({
                            specificationName: "",
                            specificationValue: {
                              Name: "",
                              file: {},
                              isNew: true
                            },
                            SpecificationOtherName: null
                          });
                      }}
                    />
                  </div>
                </div>
                <div className="block-title text-secondary ">
                  <Typography>
                    {productSpecificationBlockPlaceholder}
                  </Typography>
                </div>
              </div>

              <div className="field-array-container d-flex flex-column gap-5">
                <FieldArray
                  name="specification"
                  render={(arrayHelpers) => {
                    specificationFieldArrayRef.current = arrayHelpers;
                    return values.specification?.map(
                      (specific, index) => {
                        return (
                          <div
                            key={index}
                            className={`product-inputs-container d-flex justify-content-between flex-wrap`}>
                            <Select
                              disabled={!values.subCategory && !id}
                              labelClassName="pb-1 font-medium"
                              wrapperClass={`${values?.specification && values?.specification[index].specificationName && values?.specification[index].specificationName?.label === "Other" ? " add-more-product-small-form-field" : "add-more-product-md-form-field"}`}
                              label={specificationName}
                              placeholder={
                                specificationNamePlaceholder
                              }
                              options={filteredOptionsHandler(index)}
                              onChange={(value) => {
                                setFieldTouched(
                                  `specification[${index}].specificationName`
                                );
                                setFieldValue(
                                  `specification[${index}].specificationName`,
                                  value
                                );
                              }}
                              value={
                                values.specification[index]
                                  .specificationName
                              }
                              isInputHasErr={
                                !!(
                                  touched?.specification &&
                                  touched?.specification[index] &&
                                  touched.specification[index]
                                    .specificationName &&
                                  errors.specification &&
                                  errors?.specification[index] &&
                                  errors.specification[index]
                                    .specificationName
                                )
                              }
                              errMsg={
                                errors.specification &&
                                errors?.specification[index] &&
                                errors.specification[index]
                                  .specificationName
                              }
                              id={`specification.${index}.specificationName`}
                              name={`specification.${index}.specificationName`}
                            />
                            {values.specification &&
                            values.specification[index] &&
                            values.specification[
                              index
                            ].specificationName?.label?.toLowerCase() ===
                              PRODUCT_SPECIFICATION_TYPES.catalogue ? (
                              <div className="add-more-product-md-form-field">
                                <Input
                                  disabled={
                                    !values.specification[index]
                                      .specificationName && !id
                                  }
                                  type="file"
                                  label={upload.uploadCatalogue}
                                  labelClassName={`pb-2 font-medium  main-text-color`}
                                  inputWrapperClass={`d-none`}
                                  placeholder={
                                    specificationValuePlaceholder
                                  }
                                  onChange={(value, file) => {
                                    setFieldTouched(
                                      `specification[${index}].specificationValue`
                                    );
                                    setFieldValue(
                                      `specification[${index}].specificationValue`,
                                      {
                                        Name: value,
                                        file,
                                        isNew: true
                                      }
                                    );
                                  }}
                                  isInputHasErr={
                                    !!(
                                      touched?.specification &&
                                      touched?.specification[index] &&
                                      touched.specification[index]
                                        .specificationValue &&
                                      errors.specification &&
                                      errors?.specification[index] &&
                                      errors.specification[index]
                                        .specificationValue
                                    )
                                  }
                                  errMsg={
                                    errors.specification &&
                                    errors?.specification[index] &&
                                    errors?.specification[index]
                                      .specificationValue
                                  }
                                  setError={(errorMsg) => {
                                    setFieldError(
                                      `specification[${index}].specificationValue`
                                    ),
                                      errorMsg;
                                  }}
                                  name={`specification[${index}]specificationValue`}
                                  onChangeError={setFileUploadError}
                                  fileErrMsg={fileUploadError}
                                />

                                <div className="catalogue-specification-container d-flex flex-column ">
                                  <div className="upload-file-button-container gap-lg-2 gap-1  ">
                                    <div className="d-flex flex-column w-auto">
                                      <span className="fsize-10">
                                        {upload.uploadCatalogue}
                                      </span>

                                      <div>
                                        <InputLabel
                                          className="upload-file-button"
                                          htmlFor={`specification[${index}]specificationValue`}
                                          name={`specification.${index}.specificationValue`}
                                          id={`specification.${index}.specificationValue`}>
                                          {upload.browseCatalogue}
                                        </InputLabel>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center pt-xl-3 ps-0">
                                      <TruncateText
                                        text={`${values.specification[index].specificationValue.isNew && !!values.specification[index].specificationValue.Name ? values.specification[index].specificationValue.Name.split(`fakepath\\`)[1] : values.specification[index].OriginalMediaValue || noCatalogueUploaded} `}
                                        length={35}
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <FormHelperText
                                      error={true}
                                      className={`${
                                        lang === "en"
                                          ? ""
                                          : "text-end"
                                      } `}>
                                      {fileUploadError ||
                                        (touched?.specification &&
                                          touched?.specification[
                                            index
                                          ] &&
                                          touched.specification[index]
                                            .specificationValue &&
                                          errors.specification &&
                                          errors?.specification[
                                            index
                                          ] &&
                                          errors.specification[index]
                                            .specificationValue &&
                                          inputsValidations[
                                            errors.specification[
                                              index
                                            ].specificationValue
                                          ])}
                                    </FormHelperText>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {values?.specification &&
                                  values?.specification[index]
                                    .specificationName &&
                                  values?.specification[
                                    index
                                  ].specificationName?.label?.toLowerCase() ===
                                    PRODUCT_SPECIFICATION_TYPES.other && (
                                    <Input
                                      disabled={
                                        !values.specification[index]
                                          .specificationName && !id
                                      }
                                      label={specificationName}
                                      inputWrapperClass={`${values?.specification && values?.specification[index] && values?.specification[index].specificationName?.label === "Other" ? " add-more-product-small-form-field" : " add-more-product-md-form-field"}`}
                                      labelClassName="pb-2 font-medium  main-text-color"
                                      placeholder={
                                        specificationNamePlaceholder
                                      }
                                      onChange={(value) => {
                                        if (value.length <= 100) {
                                          const modifiedValue =
                                            value.replace(
                                              /\s\s+/g,
                                              " "
                                            );
                                          if (
                                            !value ||
                                            !!value.trim()
                                          ) {
                                            setFieldTouched(
                                              `specification[${index}].SpecificationOtherName`
                                            );
                                            setFieldValue(
                                              `specification[${index}].SpecificationOtherName`,
                                              modifiedValue
                                            );
                                          } else {
                                            setFieldValue(
                                              `specification[${index}].SpecificationOtherName`,
                                              modifiedValue.trim()
                                            );
                                          }
                                        } else {
                                          setFieldError(
                                            `specification[${index}].SpecificationOtherName`,
                                            "orderUnitOtherLong"
                                          );
                                        }
                                      }}
                                      value={
                                        values.specification[index]
                                          .SpecificationOtherName
                                      }
                                      isInputHasErr={
                                        !!(
                                          touched?.specification &&
                                          touched?.specification[
                                            index
                                          ] &&
                                          touched.specification[index]
                                            .SpecificationOtherName &&
                                          errors.specification &&
                                          errors?.specification[
                                            index
                                          ] &&
                                          errors.specification[index]
                                            .SpecificationOtherName
                                        )
                                      }
                                      errMsg={
                                        errors.specification &&
                                        errors?.specification[
                                          index
                                        ] &&
                                        errors.specification[index]
                                          .SpecificationOtherName
                                      }
                                      name={`specification.${index}.SpecificationOtherName`}
                                      id={`specification.${index}.SpecificationOtherName`}
                                    />
                                  )}

                                <Input
                                  disabled={
                                    !values.specification[index]
                                      .specificationName
                                  }
                                  label={specificationValue}
                                  inputWrapperClass={`${values?.specification && values?.specification[index].specificationName && values?.specification[index].specificationName?.label === "Other" ? " add-more-product-small-form-field" : " add-more-product-md-form-field"}`}
                                  labelClassName="pb-2 font-medium  main-text-color"
                                  placeholder={
                                    specificationValuePlaceholder
                                  }
                                  onChange={(value) => {
                                    if (value.length <= 500) {
                                      const modifiedValue =
                                        value.replace(/\s\s+/g, " ");
                                      if (!value || !!value.trim()) {
                                        setFieldTouched(
                                          `specification[${index}].specificationValue`
                                        );
                                        setFieldValue(
                                          `specification[${index}].specificationValue.Name`,
                                          modifiedValue
                                        );
                                      } else {
                                        setFieldValue(
                                          `specification[${index}].specificationValue.Name`,
                                          modifiedValue.trim()
                                        );
                                      }
                                    } else {
                                      setFieldError(
                                        `specification[${index}].specificationValue`,
                                        "specificationValueLong"
                                      );
                                    }
                                  }}
                                  value={
                                    values.specification[index]
                                      .specificationValue.Name
                                  }
                                  isInputHasErr={
                                    !!(
                                      touched?.specification &&
                                      touched?.specification[index] &&
                                      touched.specification[index]
                                        .specificationValue &&
                                      errors.specification &&
                                      errors?.specification[index] &&
                                      errors.specification[index]
                                        .specificationValue
                                    )
                                  }
                                  errMsg={
                                    errors.specification &&
                                    errors?.specification[index] &&
                                    errors.specification[index]
                                      .specificationValue
                                  }
                                  name={`specification.${index}.specificationValue`}
                                  id={`specification.${index}.specificationValue`}
                                />
                              </>
                            )}
                            <div
                              className={`remove-add-more-container pt-md-4 d-flex justify-content-end align-items-end ${index === values.specification?.length - 1 && "gap-2 "}`}>
                              <div>
                                {values.specification?.length < 10 &&
                                  values.specification?.length - 1 ===
                                    index && (
                                    <span
                                      className="remove-add-more-component add-component"
                                      onClick={() => {
                                        if (
                                          values.specification
                                            .length < 10
                                        )
                                          specificationFieldArrayRef.current.push(
                                            {
                                              specificationName: "",
                                              specificationValue: {
                                                Name: "",
                                                file: {},
                                                isNew: true
                                              },
                                              SpecificationOtherName:
                                                null
                                            }
                                          );
                                      }}>
                                      <Add
                                        style={{ width: "1rem" }}
                                        className="pointer"
                                      />
                                    </span>
                                  )}
                              </div>
                              <div
                                className="remove-add-more-component"
                                onClick={() => {
                                  specificationFieldArrayRef.current.remove(
                                    index
                                  );
                                  if (
                                    values.specification[
                                      index
                                    ].specificationValue?.Name?.includes(
                                      ".pdf"
                                    )
                                  ) {
                                    setDeletedSpecificationIndex(
                                      index
                                    );
                                  }
                                }}>
                                <CloseOutlined
                                  style={{ width: "1rem" }}
                                  className="pointer"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    );
                  }}
                />
                {values.specification.length >= 10 && (
                  <div className="block-title text-secondary ">
                    <Typography>
                      {inputsValidations.specificationNumberExceeded}
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`price-range-container bg-white d-flex flex-column w-100 p-5 bg-white border-radius-8`}>
              <div className="title-placeholder-btn-container d-flex flex-column">
                <div
                  className={`block-title  d-flex align-items-center justify-content-between pb-4 `}>
                  <div className="title-tips-container d-flex align-items-md-center align-items-start gap-md-0 gap-2">
                    <div className="d-flex flex-column flex-md-row flex-lg-row align-items-md-center gap-md-0 gap-1">
                      <Typography variant="h6">
                        {priceRangeBlock}
                      </Typography>
                    </div>
                    <Tooltip
                      classes={{
                        tooltip: "light-blue-tooltip-fill-icon"
                      }}
                      className="ms-md-2 text-secondary mt-md-0 mt-1"
                      title={
                        <ul className="mb-0 ps-0">
                          <li>
                            {
                              inputsValidations.priceRangeNumberExceeded
                            }
                          </li>
                        </ul>
                      }>
                      <InfoOutlined />
                    </Tooltip>
                  </div>

                  <div className="add-more-btn-container">
                    <Button
                      disabled={values.priceRange.length >= 10}
                      className={"add-more-btn"}
                      outlined
                      label={
                        <span className="d-flex justify-content-center align-items-center">
                          <Add
                            className="add-icon"
                            fontSize="medium"
                          />
                          <span className="add-more-text ps-2">
                            {addMore}
                          </span>
                        </span>
                      }
                      onClick={() => {
                        values.priceRange.length === 0
                          ? priceRangeFieldArrayRef.current.push({
                              minimumPriceRangeQuantity:
                                values.minimumOrderQuantity || "",
                              maximumPriceRangeQuantity: "",
                              pricePerUnit: ""
                            })
                          : priceRangeFieldArrayRef.current.push({
                              minimumPriceRangeQuantity: "",
                              maximumPriceRangeQuantity: "",
                              pricePerUnit: ""
                            });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="field-array-container d-flex flex-column gap-5">
                <FieldArray
                  validateOnChange={true}
                  name="priceRange"
                  render={(arrayHelpers) => {
                    priceRangeFieldArrayRef.current = arrayHelpers;
                    return values.priceRange?.map(
                      (priceRange, index) => {
                        return (
                          <div
                            key={index}
                            className="product-inputs-container d-flex justify-content-between align-items-start flex-wrap">
                            <Input
                              disabled={
                                index === 0 &&
                                !!values.minimumOrderQuantity
                              }
                              type={"number"}
                              key={minimumPriceRangeQuantity}
                              inputWrapperClass="add-more-product-small-form-field"
                              label={minimumPriceRangeQuantity}
                              labelClassName="pb-2 font-medium  main-text-color"
                              placeholder={
                                minimumPriceRangeQuantityPlaceholder
                              }
                              onChange={(value) => {
                                if (value === "") {
                                  setFieldError(
                                    `priceRange[${index}].minimumPriceRangeQuantity`,
                                    null
                                  );
                                }
                                setFieldTouched(
                                  `priceRange[${index}].minimumPriceRangeQuantity`
                                );
                                setFieldValue(
                                  `priceRange[${index}].minimumPriceRangeQuantity`,
                                  value
                                );
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onPaste={(e) => {
                                const pasteData =
                                  e.clipboardData.getData("text");
                                if (!/^\d+$/.test(pasteData)) {
                                  e.preventDefault();
                                }
                              }}
                              value={
                                values.priceRange[index]
                                  .minimumPriceRangeQuantity
                              }
                              isInputHasErr={
                                !!id &&
                                values.priceRange &&
                                values?.priceRange[index] &&
                                values?.priceRange[index]
                                  .minimumPriceRangeQuantity &&
                                errors.priceRange &&
                                errors?.priceRange[index] &&
                                errors.priceRange[index]
                                  .minimumPriceRangeQuantity
                                  ? errors.priceRange &&
                                    errors?.priceRange[index] &&
                                    errors.priceRange[index]
                                      .minimumPriceRangeQuantity
                                  : !!(
                                      touched?.priceRange &&
                                      touched?.priceRange[index] &&
                                      touched.priceRange[index]
                                        .minimumPriceRangeQuantity &&
                                      errors.priceRange &&
                                      errors?.priceRange[index] &&
                                      errors.priceRange[index]
                                        .minimumPriceRangeQuantity
                                    )
                              }
                              errMsg={
                                errors.priceRange &&
                                errors?.priceRange[index] &&
                                errors.priceRange[index]
                                  .minimumPriceRangeQuantity
                              }
                              name={`priceRange.${index}.minimumPriceRangeQuantity`}
                              id={`priceRange.${index}.minimumPriceRangeQuantity`}
                            />
                            <Input
                              disabled={
                                (values.priceRange[index]
                                  .maximumPriceRangeQuantity ===
                                  null ||
                                  values.priceRange[index]
                                    .maximumPriceRangeQuantity ===
                                    "") &&
                                (values.priceRange[index]
                                  .minimumPriceRangeQuantity === "" ||
                                  values.priceRange[index]
                                    .minimumPriceRangeQuantity ===
                                    null)
                              }
                              type={"number"}
                              key={maximumPriceRangeQuantity}
                              inputWrapperClass="add-more-product-small-form-field"
                              label={maximumPriceRangeQuantity}
                              labelClassName="pb-2 font-medium  main-text-color"
                              placeholder={
                                maximumPriceRangeQuantityPlaceholder
                              }
                              onChange={(value) => {
                                if (value === "") {
                                  setFieldError(
                                    `priceRange[${index}].maximumPriceRangeQuantity`,
                                    null
                                  );
                                }
                                setFieldTouched(
                                  `priceRange[${index}].maximumPriceRangeQuantity`
                                );
                                setFieldValue(
                                  `priceRange[${index}].maximumPriceRangeQuantity`,
                                  value
                                );
                                setFieldError(
                                  `priceRange[${index}].maximumPriceRangeQuantity`,
                                  null
                                );
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onPaste={(e) => {
                                const pasteData =
                                  e.clipboardData.getData("text");
                                if (!/^\d+$/.test(pasteData)) {
                                  e.preventDefault();
                                }
                              }}
                              value={
                                values.priceRange[index]
                                  .maximumPriceRangeQuantity
                              }
                              isInputHasErr={
                                !!id &&
                                values.priceRange &&
                                values?.priceRange[index] &&
                                values?.priceRange[index]
                                  .maximumPriceRangeQuantity &&
                                errors.priceRange &&
                                errors?.priceRange[index] &&
                                errors.priceRange[index]
                                  .maximumPriceRangeQuantity
                                  ? errors.priceRange &&
                                    errors?.priceRange[index] &&
                                    errors.priceRange[index]
                                      .maximumPriceRangeQuantity
                                  : !!(
                                      touched?.priceRange &&
                                      touched?.priceRange[index] &&
                                      touched.priceRange[index]
                                        .maximumPriceRangeQuantity &&
                                      errors.priceRange &&
                                      errors?.priceRange[index] &&
                                      errors.priceRange[index]
                                        .maximumPriceRangeQuantity
                                    )
                              }
                              errMsg={
                                errors.priceRange &&
                                errors?.priceRange[index] &&
                                errors.priceRange[index]
                                  .maximumPriceRangeQuantity
                              }
                              name={`priceRange.${index}.maximumPriceRangeQuantity`}
                              id={`priceRange.${index}.maximumPriceRangeQuantity`}
                            />
                            <div className="input-wrapper add-more-product-small-form-field d-flex align-items-start gap-2 currency-input">
                              <Input
                                disabled={
                                  (values.priceRange[index]
                                    .pricePerUnit === null ||
                                    values.priceRange[index]
                                      .pricePerUnit === "") &&
                                  (values.priceRange[index]
                                    .maximumPriceRangeQuantity ===
                                    "" ||
                                    values.priceRange[index]
                                      .maximumPriceRangeQuantity ===
                                      null ||
                                    values.priceRange[index]
                                      .minimumPriceRangeQuantity ===
                                      "" ||
                                    values.priceRange[index]
                                      .minimumPriceRangeQuantity ===
                                      null)
                                }
                                key={pricePerUnit}
                                inputWrapperClass="w-100 "
                                label={pricePerUnit}
                                labelClassName="pb-2 font-medium  main-text-color"
                                placeholder={pricePerUnitPlaceholder}
                                onChange={(value) => {
                                  const isValid =
                                    /^\d*(\.\d*)?$/.test(value);
                                  if (isValid) {
                                    setFieldTouched(
                                      `priceRange[${index}].pricePerUnit`
                                    );
                                    setFieldValue(
                                      `priceRange[${index}].pricePerUnit`,
                                      value
                                    );
                                  }
                                }}
                                value={
                                  values.priceRange[index]
                                    .pricePerUnit
                                }
                                isInputHasErr={
                                  !!(
                                    touched?.priceRange &&
                                    touched?.priceRange[index] &&
                                    touched.priceRange[index]
                                      .pricePerUnit &&
                                    errors.priceRange &&
                                    errors?.priceRange[index] &&
                                    errors.priceRange[index]
                                      .pricePerUnit
                                  )
                                }
                                errMsg={
                                  errors.priceRange &&
                                  errors?.priceRange[index] &&
                                  errors.priceRange[index]
                                    .pricePerUnit
                                }
                                name={`priceRange.${index}.pricePerUnit`}
                                id={`priceRange.${index}.pricePerUnit`}
                              />
                              <span className="fsize-15 fweight-500 title-color pt-4">
                                {getUserCurrency()?.symbol}
                              </span>
                            </div>
                            <div
                              className={`remove-add-more-container pt-md-4 d-flex justify-content-end align-items-end ${index === values.priceRange?.length - 1 && "gap-2 "}`}>
                              <div>
                                {values.priceRange?.length < 10 &&
                                  values.priceRange?.length - 1 ===
                                    index && (
                                    <span
                                      className="remove-add-more-component add-component"
                                      onClick={() => {
                                        if (
                                          values.priceRange.length <
                                          10
                                        )
                                          priceRangeFieldArrayRef.current.push(
                                            {
                                              minimumPriceRangeQuantity:
                                                "",
                                              maximumPriceRangeQuantity:
                                                "",
                                              pricePerUnit: ""
                                            }
                                          );
                                      }}>
                                      <Add
                                        style={{ width: "1rem" }}
                                        className="pointer"
                                      />
                                    </span>
                                  )}
                              </div>
                              {index !== 0 && (
                                <div
                                  className="remove-add-more-component"
                                  onClick={() => {
                                    priceRangeFieldArrayRef.current.remove(
                                      index
                                    );
                                  }}>
                                  <CloseOutlined
                                    style={{ width: "1rem" }}
                                    className="pointer"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    );
                  }}
                />
                {values.priceRange.length >= 10 && (
                  <div className="block-title text-secondary ">
                    <Typography>
                      {inputsValidations.priceRangeNumberExceeded}
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`form-buttons-container bg-white d-flex flex-column w-100  p-4 bg-white border-radius-8`}>
              <div
                className={`product-inputs-container d-flex  justify-content-between flex-wrap`}>
                <Button
                  key={shared.cancel}
                  outlined
                  className={"w-auto"}
                  labelClass={`py-1 ${isPublishedEditedProduct ? "" : "ps-5 pe-5"}`}
                  label={shared.cancel}
                  onClick={() => navigate(ROUTE_PATHS.products)}
                />
                <Button
                  key={shared.submit}
                  type="button"
                  onClick={() => {
                    setSubmitButtonClicked(true);
                    handleSubmit();
                  }}
                  className={"w-auto"}
                  labelClass={`py-1 ${isPublishedEditedProduct ? "" : "ps-5 pe-5"} `}
                  label={
                    id
                      ? isPublishedEditedProduct
                        ? shared.savePublish
                        : shared.save
                      : shared.submit
                  }
                />
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>

      {showAddModal && <AddProductSuccess open={showAddModal} />}
      {isPublishedEditedProductModalOpen && (
        <EditPublishedEditedProduct
          closeModal={handleCloseModal}
          productInfo={PublishEditProductDetails}
          open={isPublishedEditedProductModalOpen}
        />
      )}
      {isChangeShowcaseProductSubcategoryModalOpen && (
        <ChangeShowcaseProductSubcategory
          closeModal={handleCloseModal}
          open={isChangeShowcaseProductSubcategoryModalOpen}
          handleConfirmChange={setConfirmSubcategoryChange}
        />
      )}
    </div>
  );
};

export default AddEditProduct;
