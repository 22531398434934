const contactUs = {
  coverTitle: "Contact Us",
  coverSubtitle:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.",
  infoContainer: {
    title:
      "We are always ready to help you and answer your questions",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text .",
    labels: {
      mobileNumber: "Mobile Number",
      emailAddress: "Email Address"
    }
  },
  formContainer: {
    title: "Get In Touch",
    subtitle:
      "Create your online booth to showcase your products to a global audience without the high costs of physical exhibitions.",
    inputLabels: {
      fullName: "Full Name",
      emailAddress: "Email Address",
      message: "The Message"
    },
    placeholders: {
      fullName: "Enter your name",
      emailAddress: "Enter your email",
      message: "Enter the message"
    },
    sendMessageBtn: "Send Message"
  }
};

export default contactUs;
