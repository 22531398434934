import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Verified } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";

import ImagesGallery from "../../../../components/ImagesGallery";
import ExporterContactDetailsModal from "../../../../components/ExporterContactDetailsModal";
import TruncateText from "../../../../components/TruncateText";
import messages from "../../../../assets/locale/messages";
import emptyProductPlaceholder from "../../../../assets/images/empty_data.png";
import {
  GreyEnvelopeIcon,
  GreyGlobeIcon,
  GreyProfileIcon,
  GreyWhatsappIcon
} from "../../../../utils/Icons";
import {
  PRODUCT_STATUS,
  PRODUCT_STATUS_WITH_ID
} from "../../../../utils/Constants";

const MainInfoSection = ({
  productImages,
  defaultImg,
  pricingRanges,
  productDetails,
  fromExporter,
  isShowPublished,
  handleShowPublished,
  currency
}) => {
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const lang = useSelector((state) => state.locale.lang);
  const { contactInfo } = useSelector((state) => state.exporters);
  const { subCategories, products, shared } = messages[lang];

  const [contactDetailsModalOpen, setContactDetailsModalOpen] =
    useState(false);
  const [productStatusState, setProductStatusState] = useState({});
  const [chipClass, setChipClass] = useState("");
  const [truncateLength, setTruncateLength] = useState(0);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1440) {
      setTruncateLength(17);
    } else if (width >= 1024) {
      setTruncateLength(38);
    } else if (width >= 768) {
      setTruncateLength(24);
    } else if (width >= 425) {
      setTruncateLength(39);
    } else if (width >= 375) {
      setTruncateLength(23);
    } else {
      setTruncateLength(18);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (productDetails && Object.keys(productDetails).length !== 0) {
      const tempStatus = Object.values(PRODUCT_STATUS_WITH_ID).find(
        (status) => {
          return (
            status.ProductStatusId === productDetails?.ProductStatusId
          );
        }
      );
      if (
        tempStatus &&
        !productDetails.original &&
        Object.keys(tempStatus).length !== 0 &&
        productDetails?.IsEdit
      ) {
        if (
          tempStatus?.ProductStatusId === 1 ||
          tempStatus?.ProductStatusId === 7
        ) {
          setProductStatusState(
            PRODUCT_STATUS_WITH_ID.editedPendingApproval
          );
        } else if (tempStatus?.ProductStatusId === 4) {
          setProductStatusState(PRODUCT_STATUS_WITH_ID.edited);
        }
      } else {
        setProductStatusState(tempStatus);
      }
    }
  }, [productDetails]);

  useEffect(() => {
    switch (productStatusState?.ProductStatusId) {
      case PRODUCT_STATUS_WITH_ID.published.ProductStatusId: {
        setChipClass("green-chip");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.edited.ProductStatusId: {
        setChipClass("blue-chip");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.editedPendingApproval
        .ProductStatusId: {
        setChipClass("blue-chip");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.pending.ProductStatusId: {
        setChipClass("orange-chip");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.draft.ProductStatusId: {
        setChipClass("yellow-chip");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.rejected.ProductStatusId: {
        setChipClass("red-chip");
        break;
      }
      default:
        setChipClass("");
    }
  }, [productStatusState]);

  const renderLabelValue = (label, value, chip) => (
    <div className="row mb-3">
      <div
        className={`${fromExporter ? "col-lg-4 col-6" : "col-3"}  `}>
        <p className="text-color mb-0">{label}</p>
      </div>
      <div
        className={`${fromExporter ? "col-lg-8 col-6" : "col-4"} `}>
        <span className="title-color d-block fweight-500 mb-0">
          {chip ?? value}
        </span>
      </div>
    </div>
  );

  const renderExporterCardLabelValue = (label, value, icon) => (
    <div className="d-flex align-items-center text-break">
      <div>{icon}</div>
      <div className="ms-3">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>
        <div className="text-color fsize-15 fweight-500 mb-0">
          <TruncateText text={value} length={truncateLength} />
        </div>
      </div>
    </div>
  );

  const renderPricingRanges = () => (
    <div
      className={`${fromExporter ? "price-ranges-container" : "price-ranges-min-height"} row mb-3`}>
      <div
        className={`${fromExporter ? "col-lg-4 col-md-6 col-4 " : "col-3"}`}>
        <p className="text-color mb-0">
          {subCategories.productDetails.pricing}
        </p>
      </div>
      <div
        className={`${fromExporter ? "col-lg-8  col-md-6 col-12" : "col-7"} `}>
        {pricingRanges?.map(({ from, to, price }, i) => (
          <div key={`price-range-${i}`} className="row mb-2">
            <div
              className={`${fromExporter ? "range-key col-lg-7 col-md-9" : "col-lg-6 col-md-8 col-10"} `}>
              <p className="title-color fweight-500 mb-0">
                {`${from} - ${to} ${
                  productDetails?.MoqOtherUnit ||
                  productDetails?.MoqUnitName ||
                  subCategories.productDetails.items
                }`}
              </p>
            </div>
            <div
              className={`${fromExporter ? "range-value col-lg-5 col-md-3" : "col-lg-6 col-2"}`}>
              <p className="title-color fweight-600 mb-0">
                {currency}
                {price}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderExporterProductDetails = () => {
    return (
      <>
        {renderLabelValue(
          products.viewProduct.productStatus,
          "",
          isShowPublished ? (
            <div className="status-revert-container">
              <Chip
                label={
                  <div className=" d-flex justify-content-center align-items-center gap-2">
                    <span>
                      {fromExporter
                        ? productStatusState.ProductStatusName
                        : PRODUCT_STATUS.unpublished}
                    </span>
                  </div>
                }
                className={` border-radius-8 fw-bold ${chipClass}`}
                sx={{ width: "auto", fontWeight: "semibold" }}
              />
            </div>
          ) : (
            <Chip
              label={
                <div className=" d-flex justify-content-center align-items-center gap-2">
                  <span>
                    {fromExporter
                      ? productStatusState?.ProductStatusName
                      : PRODUCT_STATUS.unpublished}
                  </span>
                </div>
              }
              className={` border-radius-8 fw-bold ${chipClass}`}
              sx={{ width: "105px", fontWeight: "semibold" }}
            />
          )
        )}
        {productDetails?.IsEdit && (
          <div
            onClick={handleShowPublished}
            className="highlighted-text fw-bold w-100 text-md-start text-center py-3 pointer text-underline">
            {productDetails.original
              ? products.revertEdit.cancelRevertButton
              : products.revertEdit.revertButton}
          </div>
        )}
      </>
    );
  };

  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  return (
    <div
      className={`main-info-section ${fromExporter ? "mb-xl-5" : "mb-5"}`}>
      <div className="row">
        <div
          className={`${fromExporter ? "col-xl-6 col-lg-12 col-md-12 col-12" : "col-xl-6 col-lg-6 col-md-6 col-12"}`}>
          <div className="imgs-section">
            <ImagesGallery
              fromExporter={fromExporter}
              imgsList={
                productDetails && productImages && productImages
              }
              defaultImg={productDetails && defaultImg && defaultImg}
            />
          </div>
        </div>
        <div
          className={`${fromExporter ? "col-xl-6 col-lg-12 pt-md-5 col-md-12" : "col-xl-6 col-lg-6 col-md-6"}  col-12 ps-lg-5 product-info-section scroll-within`}>
          <p className="sub-category-name text-light-grey mb-3">
            {`${productDetails?.CategoryName} | ${productDetails?.SubCategoryName}`}
          </p>
          <h3 className="product-name title-color fsize-22 fweight-700 mb-3">
            {`${productDetails?.productName}`}
          </h3>
          {productDetails?.CompanyName && (
            <p className="company-name text-color mb-3">
              {productDetails?.CompanyName}
              {productDetails?.IsVerified && (
                <Verified color="primary" className="ms-1" />
              )}
            </p>
          )}
          {productDetails?.Country &&
            renderLabelValue(
              subCategories.productDetails.country,
              productDetails?.Country
            )}
          {fromExporter && renderExporterProductDetails()}
          {productDetails?.MinOrderQuantity &&
            renderLabelValue(
              subCategories.productDetails.minimumQuantity,
              `${productDetails?.MinOrderQuantity} ${
                productDetails?.MoqOtherUnit ||
                productDetails?.MoqUnitName ||
                subCategories.productDetails.items
              }`
            )}
          {!!pricingRanges?.length && renderPricingRanges()}
          {productDetails?.productHsCode &&
            renderLabelValue(
              products.viewProduct.productCode,
              productDetails?.productHsCode
            )}
          {!fromExporter && (
            <div className="faint-blue-bg w-100 border-radius-10 p-4">
              <div className="exporter-contact-info">
                <div className="mb-4 mb-md-0 d-flex justify-content-between align-items-center">
                  <p className="title-color fsize-16 fweight-600">
                    {
                      subCategories.productDetails
                        .exporterContactDetails
                    }
                  </p>
                  {!isAuth && (
                    <p
                      className="mb-0 fweight-600 text-primary pointer"
                      onClick={() =>
                        setContactDetailsModalOpen(true)
                      }>
                      {shared.exporterContactCard.showContactInfo}
                    </p>
                  )}
                  {contactDetailsModalOpen && (
                    <ExporterContactDetailsModal
                      open={contactDetailsModalOpen}
                      onClose={() =>
                        setContactDetailsModalOpen(false)
                      }
                    />
                  )}
                </div>
                <div className="info-section d-xl-flex">
                  {productDetails?.CompanyLogo && (
                    <div className="contact-img-container white-bg h-100 me-3 p-3 border-radius-8 mb-3 col-2">
                      <img
                        src={
                          productDetails?.CompanyLogo ||
                          emptyProductPlaceholder
                        }
                        onError={onImageError}
                        className="w-100"
                      />
                    </div>
                  )}
                  <div className="row col-xl-10 col-12">
                    <div className="col-xl-6 col-12 mb-4">
                      {renderExporterCardLabelValue(
                        shared.companyName,
                        <span className={`${!isAuth && "mx-1"}`}>
                          {isAuth
                            ? contactInfo?.ExporterName || "__"
                            : "*************"}
                          {isAuth && productDetails?.IsVerified && (
                            <Verified
                              color="primary"
                              className="ms-1"
                            />
                          )}
                        </span>,
                        <GreyProfileIcon dimensions={"25"} />
                      )}
                    </div>
                    <div className="col-xl-6 col-md-12 mb-4">
                      {renderExporterCardLabelValue(
                        shared.emailAddress,
                        isAuth ? contactInfo?.Email : "*************",
                        <GreyEnvelopeIcon dimensions="25" />
                      )}
                    </div>
                    <div className=" col-xl-6 col-md-12 mb-4">
                      {renderExporterCardLabelValue(
                        shared.mobileNumber,
                        isAuth
                          ? contactInfo?.MobileNumber &&
                            contactInfo?.MobileNumber?.length >= 6
                            ? contactInfo?.MobileNumber
                            : "--"
                          : "*************",
                        <GreyWhatsappIcon dimensions="25" />
                      )}
                    </div>
                    <div className="col-xl-6 col-md-12 mb-4">
                      {renderExporterCardLabelValue(
                        shared.website,
                        isAuth
                          ? contactInfo?.Website
                          : "*************",
                        <GreyGlobeIcon />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainInfoSection;

MainInfoSection.propTypes = {
  defaultImg: PropTypes.string,
  productImages: PropTypes.array,
  pricingRanges: PropTypes.array,
  fromExporter: PropTypes.bool,
  productDetails: PropTypes.object,
  isShowPublished: PropTypes.bool,
  handleShowPublished: PropTypes.func,
  currency: PropTypes.any
};
