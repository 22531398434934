import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import noProductsImage from "../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import messages from "../../../assets/locale/messages";
import EmptyState from "../../../components/EmptyState";
import MainInfoSection from "./MainInfoSection";
import OverviewSection from "./OverviewSection";
import VideoSection from "./VideoSection";
import KeywordsSection from "./KeywordsSection";
import "./ViewProduct.scss";

const ViewProduct = ({
  fromExporter,
  productDetails,
  isShowPublished,
  handleShowPublished
}) => {
  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const [keywordsArray, setKeywordsArray] = useState([]);
  const [priceRangesArray, setPriceRangesArray] = useState([]);

  useEffect(() => {
    if (productDetails && Object.keys(productDetails).length) {
      setKeywordsArray(
        productDetails.Keywords.map((keyword) => {
          return keyword.Name;
        })
      );
      setPriceRangesArray(
        productDetails.PriceRanges.map((range) => {
          return {
            from: range.From,
            to: range.To,
            price: range.Price
          };
        })
      );
    }
  }, [productDetails]);

  return (
    <div
      className={`${fromExporter ? "exporter-product-details-container p-4 d-flex flex-column gap-5" : "product-details-container"}`}>
      {!isLoading && (
        <div>
          {productDetails && Object.keys(productDetails).length ? (
            <div>
              <MainInfoSection
                productImages={
                  Object.keys(productDetails).length !== 0 &&
                  productDetails?.productImages?.length !== 0
                    ? productDetails.productImages
                    : []
                }
                defaultImg={productDetails.DefaultImage}
                pricingRanges={priceRangesArray}
                currency={productDetails?.currency}
                productDetails={productDetails}
                fromExporter={fromExporter}
                isShowPublished={isShowPublished}
                handleShowPublished={handleShowPublished}
              />
              <OverviewSection
                fromExporter={fromExporter}
                description={productDetails?.productDescription}
                specifications={
                  productDetails?.Specifications &&
                  productDetails?.Specifications
                }
              />
              {productDetails?.productVideo?.MediaName ? (
                <VideoSection
                  videoSrcFromBackend={
                    productDetails?.productVideo?.MediaName
                  }
                  videoName={
                    productDetails?.productVideo?.OriginalMediaName
                  }
                />
              ) : null}
              {fromExporter && keywordsArray.length !== 0 && (
                <KeywordsSection keywordsArray={keywordsArray} />
              )}
            </div>
          ) : (
            <div>
              <EmptyState
                subTitle={shared.noProductFound}
                imgSrc={noProductsImage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewProduct;

ViewProduct.propTypes = {
  fromExporter: PropTypes.bool,
  productDetails: PropTypes.object,
  isShowPublished: PropTypes.bool,
  handleShowPublished: PropTypes.func
};
