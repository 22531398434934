import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Pagination as MuiPagination,
  PaginationItem
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { LANGUAGES } from "../../utils/Constants";
import "./Pagination.scss";

const Pagination = ({ count, defaultPage, handleChange, page }) => {
  const lang = useSelector((state) => state.locale.lang);

  return (
    <div className="pagination-wrapper">
      <MuiPagination
        onChange={(_, page) => handleChange(page)}
        count={count}
        defaultPage={defaultPage}
        page={page}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous:
                lang === LANGUAGES.en ? ChevronLeft : ChevronRight,
              next: lang === LANGUAGES.en ? ChevronRight : ChevronLeft
            }}
            {...item}
          />
        )}
      />
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func
};

Pagination.defaultProps = {
  count: 0,
  defaultPage: 1,
  page: 1,
  handleChange: () => {}
};
export default Pagination;
