export const MaxShowcasesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="69"
    fill="none"
    viewBox="0 0 68 69">
    <rect
      width="60"
      height="60"
      x="4"
      y="4.563"
      fill="#FEE4E2"
      rx="30"></rect>
    <rect
      width="60"
      height="60"
      x="4"
      y="4.563"
      stroke="#FEF3F2"
      strokeWidth="8"
      rx="30"></rect>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M34 47.063c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5-12.5 5.6-12.5 12.5 5.6 12.5 12.5 12.5zM42.625 25.813l-17.5 17.5"></path>
  </svg>
);
