import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import productRequestsCoverImg from "../../../assets/images/productRequestsCoverImg.svg";
import noExportersImage from "../../../assets/images/vectors/placeholders/noExporterPlaceholder.svg";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getUserCurrency } from "../../../utils/Helpers";
import { getCountriesListRequest } from "../../../store/Lookups/actions";
import { websiteProductRequestsListRequest } from "../../../store/ProductRequests/actions";
import ListFilter from "../../../components/Filters/ListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import Filters from "../../../components/Filters";
import EmptyState from "../../../components/EmptyState";
import {
  CURRENCIES_IDS,
  generalImporterPagesPadding
} from "../../../utils/Constants";
import NumberRangeFilter from "../../../components/Filters/NumberRangeFilter";
import SearchInput from "../../../components/SearchInput";
import ProductRequestCard from "../../../components/ProductRequestCard";
import "./ProductRequestsList.scss";

const ProductRequestsList = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const productRequestsList = useSelector(
    (state) => state.productRequests?.productRequestsList?.list
  );
  const productRequestsMeta = useSelector(
    (state) => state.productRequests?.productRequestsList?.meta
  );
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );
  const userCurrencyId = useSelector(
    (state) => state?.auth?.login?.currency?.CurrencyId
  );
  const { countriesList } = useSelector((state) => state.lookups);
  const {
    productRequests: { websiteProductRequests },
    shared
  } = messages[lang];

  const [countryFilterValue, setCountryFilterValue] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pricesFilterValue, setPricesFilterValue] = useState({
    from: null,
    to: null
  });
  const [priceFilterError, setPriceFilterError] = useState();

  useEffect(() => {
    dispatch(getCountriesListRequest({ page: -1 }));
  }, []);

  const handleShowMore = () => {
    setPage(page + 1);
  };

  const requestProductRequestsList = (isClear) => {
    dispatch(
      websiteProductRequestsListRequest({
        params: {
          currencyId: userCurrencyId || CURRENCIES_IDS.usd,
          page,
          currencyId: websiteCurrency?.Id,
          search_value: searchValue,
          countries_ids: isClear
            ? null
            : countryFilterValue?.length
              ? countryFilterValue
              : null,
          price_from: isClear ? null : pricesFilterValue?.from,
          Price_to: isClear ? null : pricesFilterValue?.to
        }
      })
    );
  };

  useEffect(() => {
    requestProductRequestsList();
  }, [page, searchValue, websiteCurrency]);

  useEffect(() => {
    if (isApplyFilters) {
      page > 1 ? setPage(1) : requestProductRequestsList();
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    if (clearFilters) {
      setCountryFilterValue([]);
      setPricesFilterValue({ from: "", to: "" });
      page > 1 ? setPage(1) : requestProductRequestsList(true);
      setTimeout(() => {
        setClearFilters(false);
      }, [500]);
    }
  }, [clearFilters]);

  const FilterContent = () => {
    return (
      <div className="filters-container">
        <div className="border-bottom pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <ListFilter
            list={countriesList}
            label={websiteProductRequests.list.filter.country}
            name="countries"
            searchPlaceholder={
              websiteProductRequests.list.filter
                .countrySearchPLaceholder
            }
            selectedOptionsIds={countryFilterValue}
            setSelectedOptionsIds={setCountryFilterValue}
            clearFilters={clearFilters}
            collabsible
            showSearchBar
          />
        </div>
        <div className="pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <NumberRangeFilter
            name="price"
            label={shared.filtersDrawerLabels.priceRange}
            endAdorment={getUserCurrency()?.symbol}
            clearFilters={clearFilters}
            hasConfirmBtn={false}
            collabsible
            value={pricesFilterValue}
            setValue={setPricesFilterValue}
            hasError={priceFilterError}
            setHasError={setPriceFilterError}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`product-requests-container container-xxl ${generalImporterPagesPadding}`}>
      <div>
        <div>
          <Breadcrumbs
            list={[
              {
                label: websiteProductRequests.list.breadcrumbs.home,
                link: ROUTE_PATHS.home
              },
              { label: websiteProductRequests.list.requestProduct }
            ]}
          />
          <div className="requests-section">
            <div className="row align-items-start justify-content-center">
              <div
                className="col-lg-3 col-md-4 border-radius-10 filters-col"
                style={{
                  position: "sticky",
                  top: 0
                }}>
                <div className="filters-container">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="title-color fsize-22 font-semibold m-0">
                      Filters
                    </p>
                    <Button
                      label={shared.clear}
                      variant="text"
                      onClick={() => {
                        setClearFilters(true);
                        setTimeout(() => {
                          setClearFilters(false);
                        }, [500]);
                      }}
                      className="fsize-14 btn no-shadow"
                    />
                  </div>
                  <div className="filters-section scroll-within">
                    {FilterContent()}
                  </div>
                  <div className="py-3 px-2 d-flex justify-content-between align-items-center">
                    <Button
                      onClick={() => {
                        setIsApplyFilters(true);
                      }}
                      label={shared.apply}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-12 content-col">
                <div className="ps-3">
                  <div>
                    <div className="product-requests-section">
                      <div
                        className={`product-requests-cover-image w-100 border-radius-10 size-cover mb-4`}
                        style={{
                          backgroundImage: `url(${productRequestsCoverImg})`
                        }}></div>
                      <div className="d-md-flex align-items-center mb-4">
                        <SearchInput
                          name="product-requests-search"
                          id="product-requests-search"
                          value={searchValue}
                          inputClass="border-radius-10"
                          inputWrapperClass="search-input-wrapper col-lg-7 col-md-9 mb-md-0 mb-3"
                          placeholder={
                            websiteProductRequests.list
                              .searchPlaceholder
                          }
                          onChange={(value) => {
                            setPage(1);
                            setSearchValue(value);
                          }}
                        />
                        <div className="d-flex align-items-center w-100 justify-content-end d-lg-none mb-lg-0">
                          <div className="filters-button d-none d-md-block">
                            <Filters
                              handleClearFilters={setClearFilters}
                              handleApplyFilters={setIsApplyFilters}
                              FilterContent={FilterContent()}
                            />
                          </div>
                        </div>
                      </div>
                      {productRequestsList &&
                      productRequestsList.length ? (
                        <div className="products-list">
                          <div className="row products-container mb-3">
                            {productRequestsList?.map(
                              (
                                {
                                  Id,
                                  Title,
                                  Description,
                                  TimeSinceSubmission,
                                  QuantityFrom,
                                  QuantityTo,
                                  QuantityUnit,
                                  OtherQuantityUnit,
                                  PriceFrom,
                                  PriceTo,
                                  ImporterName,
                                  ImporterImage,
                                  Country,
                                  CountryNameShort
                                },
                                i
                              ) => (
                                <div
                                  key={`list-product-${Id}-${i}`}
                                  className="col-12 mb-4">
                                  <ProductRequestCard
                                    importerImg={ImporterImage}
                                    label={Title}
                                    count={`${QuantityFrom} - ${QuantityTo}`}
                                    unit={
                                      QuantityUnit === "Other"
                                        ? OtherQuantityUnit
                                        : QuantityUnit
                                    }
                                    minPrice={PriceFrom}
                                    maxPrice={PriceTo}
                                    // to do : uncomment when view product request done
                                    link={ROUTE_PATHS.viewProductRequest.replace(
                                      ":id",
                                      Id
                                    )}
                                    importerName={ImporterName}
                                    importerCountry={Country}
                                    importerCountyShort={
                                      CountryNameShort
                                    }
                                    description={Description}
                                    time={TimeSinceSubmission}
                                    currency={websiteCurrency?.Symbol}
                                  />
                                </div>
                              )
                            )}
                          </div>
                          {!isLoading &&
                            !!productRequestsList?.length &&
                            productRequestsMeta.pages >= 1 &&
                            productRequestsMeta.currentPage <
                              productRequestsMeta.pages && (
                              <div className="load-more w-100 text-center">
                                <Button
                                  label={shared.loadMore}
                                  onClick={() => {
                                    handleShowMore();
                                  }}
                                  outlined
                                />
                              </div>
                            )}
                        </div>
                      ) : (
                        !isLoading && (
                          <EmptyState
                            subTitle={
                              websiteProductRequests.list
                                .noProductRequestsEmptyPlaceholder
                            }
                            imgSrc={noExportersImage}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRequestsList;
