export const ActivateAccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="69"
      fill="none"
      viewBox="0 0 68 69">
      <rect
        width="60"
        height="60"
        x="4"
        y="4.176"
        fill="#DEF3FF"
        rx="28"></rect>
      <rect
        width="60"
        height="60"
        x="4"
        y="4.176"
        stroke="#F1FAFF"
        strokeWidth="8"
        rx="28"></rect>
      <path
        stroke="#0071BC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M34 46.676c6.875 0 12.5-5.625 12.5-12.5s-5.625-12.5-12.5-12.5-12.5 5.625-12.5 12.5 5.625 12.5 12.5 12.5z"></path>
      <path
        stroke="#0071BC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M28.688 34.176l3.537 3.538 7.087-7.075"></path>
    </svg>
  );
};
