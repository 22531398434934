export const MyAccountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19">
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M9.468 8.207a1.363 1.363 0 00-.248 0 3.315 3.315 0 01-3.202-3.322 3.327 3.327 0 013.33-3.33 3.327 3.327 0 01.12 6.653zM5.717 10.975c-1.815 1.215-1.815 3.195 0 4.402 2.062 1.38 5.445 1.38 7.507 0 1.815-1.215 1.815-3.195 0-4.402-2.055-1.373-5.437-1.373-7.507 0z"></path>
  </svg>
);
