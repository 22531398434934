import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../assets/locale/messages";
import "./Footer.scss";

const Footer = ({ footerHeight }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  return (
    <div
      style={{ height: `${footerHeight}px` }}
      className="footer-container d-flex justify-content-center align-items-center">
      {shared.copyrights}
    </div>
  );
};
Footer.propTypes = {
  footerHeight: PropTypes.number.isRequired
};
export default Footer;
