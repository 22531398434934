import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../../../assets/locale/messages";
import StripeCheckout from "../../../../../../components/Payment/StripeCheckout";
import FailurePayment from "../FailurePayment";
import {
  getStripeClientSecretRequest,
  setPaymentFailureErrMsg,
  setPaymentIntentId
} from "../../../../../../store/Verification/actions";
import { ROUTE_PATHS } from "../../../../../../utils/RoutesPaths";

const CardInfo = ({ paymentInfo }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      offPlatform: { payment }
    }
  } = messages[lang];

  const paymentFailureMsg = useSelector(
    (state) => state.verification.paymentFailureErrMsg
  );
  const clientSecret = useSelector(
    (state) => state.verification.stripeClientSecret
  );

  useEffect(() => {
    if (paymentInfo?.amount)
      dispatch(
        getStripeClientSecretRequest({ amount: paymentInfo?.amount })
      );
  }, [paymentInfo]);

  const handleCloseModal = () => {
    dispatch(setPaymentFailureErrMsg(null));
  };

  return (
    <div className="offplatform-payment-card-info-container">
      <h4 className="fsize-18 fweight-600 title-color">
        {payment.paymentInfo}
      </h4>
      <p className="fsize-16 text-color">
        {payment.paymentNote}
        <span className="fweight-600">{payment.nonrefundable}</span>
      </p>
      <div className="card-info-section border-radius-10 light-border p-4 mb-4">
        <h6 className="fsize-16 fweight-600 title-color mb-4">
          {payment.requiredInfo}
        </h6>
        <div className="card-info-form">
          <StripeCheckout
            clientSecret={clientSecret || ""}
            redirectUrl={ROUTE_PATHS.offPlatformPaymentSuccess}
            setPaymentIntentFunc={setPaymentIntentId}
            setPaymentFailureMsgFunc={setPaymentFailureErrMsg}
            paymentInfo={paymentInfo}
          />
        </div>
      </div>
      {!!paymentFailureMsg && (
        <FailurePayment
          open={!!paymentFailureMsg}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default CardInfo;

CardInfo.propTypes = {
  paymentInfo: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
  }).isRequired
};
