import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../assets/locale/messages";
import SubCategoryCardGrid from "../../../../components/SubCategoryCards/SubCategoryCardGrid";
import Swiper from "../../../../components/Swiper";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const SubCategoriesSection = ({ subCategories, cardsNumber }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];

  const [subCategoriesCards, setSubCategoriesCards] = useState([]);

  useEffect(() => {
    if (subCategories?.length) {
      setSubCategoriesCards(
        subCategories.map(({ Id, Name, DefaultImage }, i) => (
          <div className="mx-3 mb-3" key={`sub-category-${i}`}>
            <SubCategoryCardGrid
              src={DefaultImage}
              label={Name}
              link={ROUTE_PATHS.subCategoryDetails.replace(":id", Id)}
            />
          </div>
        ))
      );
    }
  }, [subCategories]);

  return (
    <div className="sub-categories-section-container">
      <h4 className="fweight-700 mb-3 fsize-22">
        {home.mostPopular}
      </h4>
      <Swiper
        slides={subCategoriesCards}
        slidesToShow={1}
        infinite={false}
        speed={750}
        sliderClassName={"sub-categories-slider"}
        name={"sub-categories-slider"}
        responsiveSettings={{
          480: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: cardsNumber
          }
        }}
      />
    </div>
  );
};

export default SubCategoriesSection;

SubCategoriesSection.propTypes = {
  subCategories: PropTypes.array.isRequired,
  cardsNumber: PropTypes.number
};
SubCategoriesSection.defaultProps = {
  cardsNumber: 6
};
