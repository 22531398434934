const settings = {
  viewSettings: {
    pageTitle: "Settings",
    currentCurrency: "Current Currency",
    deleteWarning:
      "Deleting your account will permanently erase all your data. This action cannot be undone.",
    notificationsWarning:
      "This notification setting will affect only the product request notification.",
    blockLabels: {
      currencySettings: "Currency Settings",
      productRequestsNotifications: "Product Requests Notifications",
      security: "Security",
      accountDeleteAndDeactivate: "Account Deletion And Deactivation",
      instructionsDocument: "Instructions Document"
    },
    buttons: {
      changeCurrency: "Change Currency",
      changePassword: "Change Password",
      deactivateAccount: "Deactivate Account",
      deleteAccount: "Delete Account",
      downloadPdf: "Download The PDF"
    }
  },
  changePassword: {
    pageTitle: "Change Password",
    inputLabels: {
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Password"
    },
    placeholders: {
      currentPassword: "Enter your current password",
      newPassword: "Enter your new password",
      confirmPassword: "Enter your new password again"
    }
  },
  changePasswordSuccessModal: {
    title: "Successful !",
    content: "Your password has been changed successfully!",
    confirmButton: "Go To Login"
  },
  deleteExporterProfile: {
    title: "Delete Account",
    content:
      "Deleting your account will permanently remove your subscription and all associated data. This action can not be undone. ",
    confirmationContent: "Are you sure you want to continue ?",
    deleteAccountButton: "Delete Account"
  },
  deleteImporterProfile: {
    title: "Delete Account",
    content:
      "By deleting your account, you will lose all your saved information, including product requests, & this action is irreversible.",
    confirmationContent: "Are you certain you want to proceed?",
    deleteAccountButton: "Delete Account"
  },
  deleteProfileConfirm: {
    title: "Delete Account",
    contentFirstPart: "Your subscription is active until",
    contentSecondPart:
      "If you delete your account, you'll lose access to your subscription and its benefits",
    confirmationContent: "Are you certain you want to proceed ?"
  },
  deactivateExporterAccount: {
    title: "Deactivate Account ?",
    subscribedExporterContent:
      "Deactivating your account will hide your products from our platform and prevent you from accessing your account information. In addition, all subaccounts will be deactivated. Meanwhile, your subscription will continue to be charged.",
    content:
      "Deactivating this main account will automatically deactivate all linked subaccounts.We are sad to see you go, but hope to see you again!",
    confirmationContent: "Are you sure you want to continue?"
  },
  deactivateImporterAccount: {
    title: "Deactivate Account ?",
    content: "Are you sure you want to deactivate your account?"
  },
  changeCurrency: {
    confirmationMsg:
      "Changing the currency will reflect on the old products without applying currency conversion. Upgrade to our premium plan and let our platform do the upgrade on your behalf!",
    changeCurrencyConfirm: "Change Currency?",
    changeCurrency: "Change Currency",
    selectCurrencyStatement:
      "Please select the currency you want to work with",
    currencyLabel: "Currency",
    currencyPlaceholder: "Select currency"
  }
};
export default settings;
