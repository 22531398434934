import { call, put, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/productRequests";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";

function* productRequestsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.websiteProductRequestsList,
      payload
    );
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.websiteProductRequestsListResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
    if (CurrentPage && TotalPages && +CurrentPage > +TotalPages) {
      yield put(
        actions.websiteProductRequestsListRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (error) {
    yield error.response?.data?.Message &&
      showErrorMsg(error.response?.data?.Message);
  }
}

function* addProductRequestSaga({ payload }) {
  try {
    yield call(apis.addImporterProductRequest, payload);
    yield showSuccessMsg("addProductRequestSuccess");
    yield payload.navigate(ROUTE_PATHS.importerProductRequests);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editProductRequestSaga({ payload }) {
  try {
    const response = yield call(
      apis.editImporterProductRequest,
      payload
    );
    yield showSuccessMsg("", response.data.Data.Message);
    yield payload.navigate(ROUTE_PATHS.importerProductRequests);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getProductRequestDetailsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getImporterProductRequestDetails,
      payload
    );
    yield put(
      actions.getImporterProductRequestDetailsResponse(
        response.data.Data
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* importerProductRequestsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.importerProductRequestsList,
      payload
    );
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.importerProductRequestsListResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
    if (CurrentPage && TotalPages && +CurrentPage > +TotalPages) {
      yield put(
        actions.importerProductRequestsListRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (error) {
    yield error.response?.data?.Message &&
      showErrorMsg(error.response?.data?.Message);
  }
}

function* getImporterContactInfoSaga({ payload }) {
  try {
    const response = yield call(apis.getImporterContactInfo, payload);
    yield put(
      actions.getImporterContactInfoResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* closeProductRequestSaga({ payload }) {
  try {
    yield call(apis.closeProductRequest, payload);
    yield showSuccessMsg("closeProductRequestSuccess");
    yield payload.navigate(ROUTE_PATHS.importerProductRequests);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* ProductRequestsSagas() {
  yield takeEvery(
    types.WEBSITE_PRODUCT_REQUESTS_LIST_REQUEST,
    productRequestsListSaga
  );
  yield takeEvery(
    types.ADD_IMPORTER_PRODUCT_REQUEST_REQUEST,
    addProductRequestSaga
  );
  yield takeEvery(
    types.EDIT_IMPORTER_PRODUCT_REQUEST_REQUEST,
    editProductRequestSaga
  );
  yield takeEvery(
    types.GET_IMPORTER_PRODUCT_REQUEST_DETAILS_REQUEST,
    getProductRequestDetailsSaga
  );
  yield takeEvery(
    types.IMPORTER_PRODUCT_REQUESTS_LIST_REQUEST,
    importerProductRequestsListSaga
  );
  yield takeEvery(
    types.GET_IMPORTER_CONTACT_INFO_REQUEST,
    getImporterContactInfoSaga
  );
  yield takeEvery(
    types.CLOSE_PRODUCT_REQUEST_REQUEST,
    closeProductRequestSaga
  );
}
