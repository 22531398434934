import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { MaxShowcasesIcon } from "../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { USER_STATUS_IDS } from "../../../../utils/Constants";

const SubscribeModal = ({ open, closeModal }) => {
  const lang = useSelector((state) => state.locale.lang);
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );

  const {
    company: {
      subscribeModal: {
        content,
        confirmationContent,
        confirmationContentExpiredSubscription
      }
    },
    shared
  } = messages[lang];

  const navigate = useNavigate();

  const handleConfirmUpgrade = () => {
    navigate(ROUTE_PATHS.pricingDetails);
    closeModal();
  };

  const maxShowcasesCountContent = () => {
    return (
      <div className="publish-product-container container d-flex flex-column justify-content-center align-items-center">
        <MaxShowcasesIcon />
        <div className="publish-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {userStatusId === USER_STATUS_IDS.subscriptionExpired
              ? confirmationContentExpiredSubscription
              : confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmUpgrade();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={maxShowcasesCountContent()}
      hasActions={true}
      confirmBtnTxt={shared.upgrade}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default SubscribeModal;

SubscribeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
