export const FiltersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
    {...props}>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M18.334 5.527h-5M5 5.527H1.667M8.333 8.444a2.917 2.917 0 100-5.834 2.917 2.917 0 000 5.834zM18.333 14.694H15M6.667 14.694h-5M11.667 17.61a2.917 2.917 0 100-5.833 2.917 2.917 0 000 5.833z"></path>
  </svg>
);
