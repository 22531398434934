export const SET_PUSH_NOTIFICATION = "SET_PUSH_NOTIFICATION";

export const GET_INITIAL_NOTIFICATIONS_REQUEST =
  "GET_INITIAL_NOTIFICATIONS_REQUEST";
export const GET_INITIAL_NOTIFICATIONS_RESPONSE =
  "GET_INITIAL_NOTIFICATIONS_RESPONSE";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_RESPONSE =
  "GET_NOTIFICATIONS_RESPONSE";

export const SET_NOTIFICATION_SEEN = "SET_NOTIFICATION_SEEN";
export const SET_ALL_NOTIFICATIONS_SEEN =
  "SET_ALL_NOTIFICATIONS_SEEN";

export const SET_UNSEEN_NOTIFICATIONS_COUNT =
  "SET_UNSEEN_NOTIFICATIONS_COUNT";

export const INCREMENT_NOTIFICATIONS_COUNT =
  "INCREMENT_NOTIFICATIONS_COUNT";

export const DECREMENT_NOTIFICATIONS_COUNT =
  "DECREMENT_NOTIFICATIONS_COUNT";

export const GET_UNSEEN_NOTIFICATIONS_COUNT_REQUEST =
  "GET_UNSEEN_NOTIFICATIONS_COUNT_REQUEST";
