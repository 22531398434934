import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import Button from "../../../components/Button";
import Counter from "../../../components/Counter";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  forgetPasswordRequest,
  setDisableForgetPasswordResendEmail
} from "../../../store/Auth/actions";

const ForgetPsswordConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [restartCount, setRestartCount] = useState(false);

  const lang = useSelector((state) => state.locale.lang);
  const {
    forgetPassword: { confirmScreen }
  } = messages[lang];
  const disableResendEmail = useSelector(
    (state) => state.auth.forgetPassword.disableResendEmail
  );

  useEffect(() => {
    return () => {
      dispatch(setDisableForgetPasswordResendEmail(false));
    };
  }, []);

  const onResendEmail = () => {
    dispatch(
      forgetPasswordRequest({
        data: { email: location?.state?.email },
        navigate,
        resendEmail: true
      })
    );
    setRestartCount(true);
    setTimeout(() => {
      setRestartCount(false);
    }, 2000);
  };

  return (
    <>
      <Box
        className={`text-center mb-3`}
        sx={{
          width: "fit-content",
          margin: "0 auto"
        }}>
        <h3 className="title fweight-700 fsize-32 title-color mb-4 decreased-title-font">
          {confirmScreen.forgetPassword}
        </h3>
        <p className="instructions text-color fsize-16 mb-0">
          {confirmScreen.confirmMsg}
        </p>
        {location?.state?.email && (
          <p className="text-color fweight-700 fsize-16">
            {location?.state?.email}
          </p>
        )}
        {location?.state?.email && (
          <div className="counter">
            <Counter
              initialCount={60}
              onChangeCount={(newCount) => setCount(newCount)}
              restartCount={restartCount}
              disabled={disableResendEmail}
            />
          </div>
        )}
        <div className="text-center mt-4">
          <Button
            label={confirmScreen.backToSignin}
            className="mb-3 w-100 mx-auto back-to-sign-in-btn"
            outlined
            onClick={() => navigate(ROUTE_PATHS.login)}
          />
          {location?.state?.email && (
            <p className="text-color">
              {confirmScreen.didntReceiveEmail}
              <span
                className={`fweight-700 ms-2 text-underline ${!count && !disableResendEmail ? "pointer text-primary" : "text-light-grey"}`}
                onClick={() => {
                  !count && onResendEmail();
                }}>
                {confirmScreen.clickToResend}
              </span>
            </p>
          )}
        </div>
      </Box>
    </>
  );
};

export default ForgetPsswordConfirm;
