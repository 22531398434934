import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import App from "./containers/App";
import store from "./store";
import { register } from "./notifications/serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));
// google analytics code
const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
const scriptTag = document.querySelector(
  'script[src*="googletagmanager.com/gtag/js"]'
);
if (scriptTag) {
  scriptTag.src = scriptTag.src.replace(
    "%GOOGLE_ANALYTICS_MEASUREMENT_ID%",
    gaId
  );
}
window.dataLayer ||= [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", gaId);
// google analytics code
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

register();
