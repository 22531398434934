import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import { ChangePasswordSuccessIcon } from "../../../utils/Icons";
import { showChangePasswordSuccessModal } from "../../../store/Settings/actions";
import store from "../../../store";
import { logoutRequest } from "../../../store/Auth/actions";
import { getDeviceId } from "../../../utils/Auth";

const ChangePasswordSuccess = ({ open }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    settings: {
      changePasswordSuccessModal: { title, content, confirmButton }
    }
  } = messages[lang];

  const handleConfirmLogout = () => {
    store.dispatch(showChangePasswordSuccessModal(false));
    dispatch(
      logoutRequest({
        device_id: getDeviceId(),
        navigate
      })
    );
  };

  const changePasswordSuccessContent = () => {
    return (
      <div className="change-Password-success-container container d-flex flex-column justify-content-center align-items-center">
        <ChangePasswordSuccessIcon />
        <div className="change-Password-success-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {content}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"change-password-success-modal"}
      open={open}
      handleConfirm={handleConfirmLogout}
      handleCancel={handleConfirmLogout}
      handleClose={handleConfirmLogout}
      isContentText={false}
      content={changePasswordSuccessContent()}
      hasActions={true}
      confirmBtnTxt={confirmButton}
      showCloseIcon
      title
      actionsClass="d-flex justify-content-center"
      confirmBtnClass="border-radius-12 pt-2 pb-2 ps-2 pe-2 mb-4"
    />
  );
};

export default ChangePasswordSuccess;

ChangePasswordSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired
};
