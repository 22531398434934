import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText
} from "@mui/material";
import {
  VisibilityOutlined,
  VisibilityOffOutlined
} from "@mui/icons-material";

import messages from "../../assets/locale/messages";
import {
  acceptedDocumentFilesTypes,
  acceptedPdfMaxSize
} from "../../utils/Constants";
import VoiceRecord from "../VoiceRecord";
import "./Input.scss";

const Input = ({
  name,
  value,
  onChange,
  onKeyDown,
  id,
  label,
  type,
  placeholder,
  required,
  isInputHasErr,
  errMsg,
  disabled,
  multiline,
  rows,
  maxRows,
  minRows,
  startAdornment,
  endAdornment,
  fullWidth,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  inputEndAdorment,
  onChangeError,
  fileErrMsg,
  voiceInput,
  setTranscribedText,
  setIsAudioDescription,
  isAudioDescription,
  isAudioTranscribeSuccessful,
  setIsAudioTranscribeSuccessful,
  handleIsRecordingClicked,
  autoComplete,
  onKeyPress,
  onPaste,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [showPassword, setShowPassword] = useState(false);
  const [showVoiceRecord, setShowVoiceRecord] = useState(false);

  const customSX =
    type === "file"
      ? {
          "& fieldset": { border: "none" }
        }
      : {};

  const validateFile = (documentFile) => {
    let { type } = documentFile;
    type = `.${type.split("/")[1]}`;
    if (!acceptedDocumentFilesTypes.includes(type)) {
      onChangeError &&
        onChangeError(inputsValidations.invalidFileType);
      return false;
    }
    if (documentFile.size > acceptedPdfMaxSize) {
      onChangeError &&
        onChangeError(inputsValidations.uploadCatalogueSizeError);
      return false;
    }
    onChangeError && onChangeError(null);
    return true;
  };

  useEffect(() => {
    return () => {
      onChangeError ? onChangeError(null) : null;
    };
  }, []);

  return (
    <div
      className={`input-wrapper ${type === "file" && "file-input-wrapper"} ${inputWrapperClass}`}>
      <>
        {type === "file" ? (
          <TextField
            id={id || name}
            name={name}
            InputLabelProps={{ shrink: true }}
            sx={customSX}
            label={
              <span
                className={`${labelClassName} ${type === "file" && "custom-file-upload"}`}>
                {label}
                {labelAdornment && (
                  <span className="ms-2">{labelAdornment}</span>
                )}
              </span>
            }
            type={showPassword ? "text" : type}
            value={value}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              if (type === "file") {
                const validFile = validateFile(e.target.files[0]);
                if (validFile) {
                  onChange(e.target.value, e.target.files[0]);
                }
              } else return onChange(e.target.value);
            }}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            error={isInputHasErr}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            minRows={minRows}
            className={`${inputClass} ${type === "file" && "file-input"}`}
            disableunderline="true"
            autoComplete="off"
            inputProps={{
              startadornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              endadornment:
                type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : (
                  endAdornment
                )
            }}
            {...props}
          />
        ) : (
          <TextField
            id={id || name}
            name={name}
            label={
              <span
                className={`fsize-13 fweight-300 title-color  ${labelClassName} `}>
                {label}
                {labelAdornment && (
                  <span className="ms-2">{labelAdornment}</span>
                )}
              </span>
            }
            type={showPassword ? "text" : type}
            value={value}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            required={required}
            InputLabelProps={{
              required: false,
              shrink: true,
              variant: "outlined"
            }}
            disabled={disabled}
            error={isInputHasErr}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            minRows={minRows}
            className={`${inputClass} ${type === "file" && "file-input"}`}
            disableunderline="true"
            autoComplete="off"
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment:
                type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? (
                        <VisibilityOutlined fontSize="small" />
                      ) : (
                        <VisibilityOffOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : (
                  endAdornment
                ),
              autoComplete: autoComplete ? "new-password" : "",
              form: {
                autoComplete: "off"
              }
            }}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            {...props}
          />
        )}
        {inputEndAdorment}
      </>
      {voiceInput && (
        <VoiceRecord
          handleIsRecordingClicked={handleIsRecordingClicked}
          showVoiceRecord={showVoiceRecord}
          setShowVoiceRecord={setShowVoiceRecord}
          setTranscribedText={setTranscribedText}
          setIsAudioDescription={setIsAudioDescription}
          isAudioDescription={isAudioDescription}
          isAudioTranscribeSuccessful={isAudioTranscribeSuccessful}
          setIsAudioTranscribeSuccessful={
            setIsAudioTranscribeSuccessful
          }
        />
      )}
      <FormHelperText
        error={true}
        className={`${helperTextClass || "fsize-12"} ${
          lang === "en" ? "" : "text-end"
        } ${voiceInput && "mt-3"} ${fileErrMsg && "ps-3"}`}>
        {isInputHasErr
          ? inputsValidations[errMsg]
          : fileErrMsg || helperText}
      </FormHelperText>
    </div>
  );
};

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  inputEndAdorment: PropTypes.element,
  startAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onChangeError: PropTypes.func,
  fileErrMsg: PropTypes.string,
  voiceInput: PropTypes.any,
  setTranscribedText: PropTypes.func,
  setIsAudioDescription: PropTypes.func,
  isAudioDescription: PropTypes.bool,
  isAudioTranscribeSuccessful: PropTypes.bool,
  setIsAudioTranscribeSuccessful: PropTypes.func,
  handleIsRecordingClicked: PropTypes.func,
  autoComplete: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func
};

Input.defaultProps = {
  type: "text",
  fullWidth: true,
  value: "",
  autoComplete: true
};
