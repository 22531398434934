import { axiosInstanceExporters, axiosInstanceImporters } from ".";

const getCategoryDetails = async ({ id, currencyId }) =>
  await axiosInstanceImporters.get(`/api/Category/GetDetails/${id}`, {
    params: { currencyId }
  });

const suggestNewCategory = async (data) =>
  await axiosInstanceExporters.post(
    `/api/Category/SuggestNewCategory`,
    data
  );

const categoryDropdown = async () =>
  await axiosInstanceExporters.get(
    "/api/Category/GetWithSubcategory"
  );

export { suggestNewCategory, getCategoryDetails, categoryDropdown };
