import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Slider from "../Slider";
import emptyProductPlaceholder from "../../assets/images/empty_data.png";
import { checkBackgroundImageValidity } from "../../utils/Helpers";
import "./ImagesGallery.scss";

const ImagesGallery = ({ imgsList, defaultImg, fromExporter }) => {
  const [imgsDefaultList, setImgsDefaultList] = useState([]);
  const [imgsSlides, setImgsSlides] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [isMainImgChanged, setIsMainImgChanged] = useState(false);
  const [imageValidity, setImageValidity] = useState({});
  const [isMainImgValid, setIsMainImgValid] = useState(true);

  useEffect(() => {
    if (imgsDefaultList.length) {
      imgsDefaultList.forEach((src) => {
        checkBackgroundImageValidity(src, (isLoaded) => {
          setImageValidity((prevValidity) => ({
            ...prevValidity,
            [src]: isLoaded
          }));
        });
      });
    }
  }, [imgsDefaultList]);

  useEffect(() => {
    if (imgsDefaultList?.length) {
      setImgsSlides(
        imgsDefaultList.map((src, i) => (
          <div
            key={`img-gallery-item-${i}`}
            className={`img-slide border-radius-10 ${mainImage === src ? "thick-primary-border" : "light-border"}`}
            style={{
              backgroundImage: `url(${imageValidity[src] ? src : emptyProductPlaceholder})`
            }}></div>
        ))
      );
    }
  }, [imgsDefaultList, imageValidity, mainImage]);

  useEffect(() => {
    setImgsDefaultList([defaultImg, ...imgsList]);
  }, [imgsList]);

  useEffect(() => {
    if (defaultImg) {
      checkBackgroundImageValidity(defaultImg, (isLoaded) => {
        setImageValidity((prevValidity) => ({
          ...prevValidity,
          [defaultImg]: isLoaded
        }));
      });
    }
  }, [defaultImg]);

  useEffect(() => {
    if (defaultImg && !isMainImgChanged) {
      setMainImage(
        imageValidity[defaultImg]
          ? defaultImg
          : emptyProductPlaceholder
      );
    }
  }, [defaultImg, isMainImgChanged, imageValidity]);

  useEffect(() => {
    if (mainImage) {
      checkBackgroundImageValidity(mainImage, (isLoaded) => {
        setIsMainImgValid(isLoaded);
      });
    }
  }, [mainImage]);

  const renderImgsWithVerticalSlider = () => (
    <div className="row vertical-slider">
      {imgsDefaultList?.length > 4 && (
        <div
          className={`${fromExporter ? "col-xl-3 col-2 pe-0" : "col-3 pe-0"} `}>
          <Slider
            handleOnSlideClick={(i) => {
              setIsMainImgChanged(true);
              setMainImage(imgsDefaultList[i]);
            }}
            slides={imgsSlides}
            slidesToShow={4}
            slidesToScroll={1}
            sliderClassName="images-gallery-slider"
            isVertical={true}
            infinite
            vertical
            verticalSwiping
          />
        </div>
      )}
      {imgsDefaultList?.length <= 4 &&
        imgsDefaultList?.length > 1 && (
          <div
            className={`${fromExporter ? "col-xl-3 col-2 pe-0" : "col-3 pe-0"} `}>
            <div className="static-images-container">
              {imgsSlides?.map((img, i) => (
                <div
                  className="mb-2 pointer"
                  key={`img-gallery-item-${i}`}
                  onClick={() => {
                    setIsMainImgChanged(true);
                    setMainImage(imgsDefaultList[i]);
                  }}>
                  {img}
                </div>
              ))}
            </div>
          </div>
        )}
      <div
        className={`pe-0 ${imgsDefaultList?.length > 1 ? (fromExporter ? "col-xl-9 col-10" : "col-9") : "col-12"}`}>
        <div
          className="default-img border-radius-10 light-border"
          style={{
            backgroundImage: `url(${isMainImgValid ? mainImage : emptyProductPlaceholder})`
          }}></div>
      </div>
    </div>
  );

  const renderImgsWithHorizontalSlider = () => (
    <div className="horizontal-slider">
      <div
        className="default-img border-radius-10 mb-2 light-border"
        style={{
          backgroundImage: `url(${isMainImgValid ? mainImage : emptyProductPlaceholder})`
        }}></div>
      {imgsDefaultList?.length > 4 && (
        <Slider
          handleOnSlideClick={(i) => {
            setIsMainImgChanged(true);
            setMainImage(imgsDefaultList[i]);
          }}
          slides={imgsSlides}
          slidesToShow={4}
          slidesToScroll={1}
          sliderClassName="images-gallery-slider"
          infinite
        />
      )}
      {imgsDefaultList?.length <= 4 &&
        imgsDefaultList?.length > 1 && (
          <div className="static-images-container row ms-0">
            {imgsSlides?.map((img, i) => (
              <div
                key={`img-gallery-item-${i}`}
                className="col-3 p-0 pointer"
                onClick={() => {
                  setIsMainImgChanged(true);
                  setMainImage(imgsDefaultList[i]);
                }}>
                {img}
              </div>
            ))}
          </div>
        )}
    </div>
  );

  const renderMobileSlider = () => (
    <div className="mobile-slider mb-3">
      {imgsDefaultList.length > 1 ? (
        <Slider
          handleOnSlideClick={(i) => {
            setIsMainImgChanged(true);
            setMainImage(imgsDefaultList[i]);
          }}
          slides={imgsSlides}
          slidesToShow={1}
          slidesToScroll={1}
          sliderClassName="images-gallery-slider"
          infinite
        />
      ) : (
        <div>
          <div
            className="img-slide border-radius-10 light-border"
            style={{
              backgroundImage: `url(${isMainImgValid ? mainImage : emptyProductPlaceholder})`
            }}></div>
        </div>
      )}
    </div>
  );

  return (
    <div className="images-slider-container">
      {renderImgsWithVerticalSlider()}
      {renderImgsWithHorizontalSlider()}
      {renderMobileSlider()}
    </div>
  );
};

export default ImagesGallery;

ImagesGallery.propTypes = {
  imgsList: PropTypes.arrayOf(PropTypes.string),
  defaultImg: PropTypes.string,
  fromExporter: PropTypes.bool
};
