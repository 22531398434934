import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

const DropdownMenu = ({ menuButton, menuName, menuList }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id={`${menuName}-button`}
        aria-controls={open && `${menuName}-menu`}
        aria-haspopup="true"
        aria-expanded={open && "true"}
        onClick={handleClick}
        className="pointer">
        {menuButton}
      </div>
      <Menu
        id={`${menuName}-menu`}
        aria-labelledby={`${menuName}-button`}
        anchorEl={anchorEl}
        open={open}
        disableAutoFocusItem
        onClose={handleClose}>
        {menuList?.map(
          ({ label, onClick, link }, i) =>
            label &&
            (link ? (
              <Link to={link} key={`menu-item-${i}`}>
                <MenuItem onClick={handleClose}>{label}</MenuItem>
              </Link>
            ) : (
              <MenuItem
                key={`menu-item-${i}`}
                onClick={() => {
                  onClick();
                  handleClose();
                }}>
                {label}
              </MenuItem>
            ))
        )}
      </Menu>
    </>
  );
};

export default DropdownMenu;

DropdownMenu.propTypes = {
  menuButton: PropTypes.element.isRequired,
  menuName: PropTypes.string.isRequired,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]).isRequired,
      onClick: PropTypes.func,
      link: PropTypes.string
    })
  )
};
