import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";

import "./Button.scss";

const Button = ({
  id,
  className,
  label,
  labelClass,
  type,
  outlined,
  disabled,
  onClick,
  block,
  color,
  reversedcolor,
  faintPrimary,
  whiteBtn,
  danger,
  variant,
  whiteBg,
  customSpacing,
  ...props
}) => (
  <MuiButton
    className={`btn ${block ? "btn-block" : ""} border-radius-8 ${customSpacing || "px-4 py-2"} fsize-16 fweight-500 ${reversedcolor && "reversed-color"} ${faintPrimary && "faint-btn"} ${danger && "danger-btn"} ${whiteBtn && outlined && "white-outlined"} ${whiteBg && outlined && "white-bg-outlined"} ${className}`}
    variant={variant ? "text" : outlined ? "outlined" : "contained"}
    type={type}
    disabled={disabled}
    onClick={onClick}
    color={color}
    disableFocusRipple={true}
    disableTouchRipple={true}
    disableRipple={true}
    id={id}
    {...props}>
    <span className={`d-flex align-items-center ${labelClass} `}>
      {label}
    </span>
  </MuiButton>
);

Button.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired
  ]),
  id: PropTypes.string,
  labelClass: PropTypes.string,
  type: PropTypes.string,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  block: PropTypes.bool,
  reversedcolor: PropTypes.bool,
  faintPrimary: PropTypes.bool,
  whiteBtn: PropTypes.bool,
  whiteBg: PropTypes.bool,
  danger: PropTypes.bool,
  color: PropTypes.string,
  customSpacing: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = { type: "button" };

export default Button;
