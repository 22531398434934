import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../../../../components/Alert";
import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import { BANNER_COLORS } from "../../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const AwaitingRequiredDocuments = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      verificationNotifications: { awaitRequiredDocusAlert }
    }
  } = messages[lang];

  return (
    <Alert
      type="info"
      content={
        <div>
          <p className="mb-0 fsize-16 light-text-color">
            {awaitRequiredDocusAlert.msg1}
            <span className="fweight-600 title-color ms-1">
              {awaitRequiredDocusAlert.msg2}
            </span>
            <span className="title-color">
              {`,  "`}
              {awaitRequiredDocusAlert.msg3}
            </span>
            <span className="text-primary text-underline pointer">
              <Link to={ROUTE_PATHS.businessVerification}>
                {awaitRequiredDocusAlert.msg4}
              </Link>
            </span>
            <span className="title-color">{`"`}</span>
          </p>
          <p className="mb-0 fsize-16 light-text-color">
            {awaitRequiredDocusAlert.msg5}
          </p>
        </div>
      }
      icon={<ChipInfoIcon color={BANNER_COLORS.info} />}
    />
  );
};

export default AwaitingRequiredDocuments;
