import { axiosInstanceExporters } from ".";

const getLoggedInExporterDetails = async () =>
  await axiosInstanceExporters.get(`/api/ExporterProfile/GetProfile`);

const editLoggedInExporterDetails = async (data) =>
  await axiosInstanceExporters.put(
    `/api/ExporterProfile/EditProfile`,
    data
  );

const getSubCategoryProductsList = async ({ params }) =>
  await axiosInstanceExporters.get(`/api/Product/Get`, {
    params,
    paramsSerializer: { indexes: null }
  });

const addShowcase = async ({ data }) =>
  await axiosInstanceExporters.post("/api/CompanyShowcase/Add", data);

const publishExporter = async () =>
  await axiosInstanceExporters.get("/api/User/PublishExporter");

const editShowcase = async (data) =>
  await axiosInstanceExporters.put(`/api/CompanyShowcase/Edit`, data);

const deleteShowcase = async ({ data }) =>
  await axiosInstanceExporters.put(
    `/api/CompanyShowcase/Delete`,
    data
  );

const getUserStatus = async () =>
  await axiosInstanceExporters.get("/api/User/GetUserStatus");

const hideInstructionsBanner = async () =>
  await axiosInstanceExporters.get("/api/User/DisplayBanner");

export {
  getLoggedInExporterDetails,
  editLoggedInExporterDetails,
  getSubCategoryProductsList,
  addShowcase,
  publishExporter,
  editShowcase,
  deleteShowcase,
  getUserStatus,
  hideInstructionsBanner
};
