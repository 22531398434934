import { axiosInstanceExporters, axiosInstanceImporters } from ".";

const getExporterProfile = async () =>
  await axiosInstanceExporters.get(`/api/User/GetUserProfile`);

const getExporterProfileTransactionsList = async ({ params }) =>
  await axiosInstanceExporters.get(`/api/Transaction/Get`, {
    params,
    paramsSerializer: { indexes: null }
  });

const editExporterProfileDetails = async (data) =>
  await axiosInstanceExporters.put(`/api/User/EditUserProfile`, data);

const editImporterProfileDetails = async (data) =>
  await axiosInstanceImporters.put(`/api/User/EditImporter`, data);

export {
  getExporterProfile,
  getExporterProfileTransactionsList,
  editExporterProfileDetails,
  editImporterProfileDetails
};
