import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar
} from "@mui/material";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { MyStoreIcon } from "../../../utils/Icons";
import messages from "../../../assets/locale/messages";
import Logo from "../../../assets/images/vectors/Logo.svg";
import { ProfileIcon } from "../../../utils/Icons";
import UserProfileButton from "../../UserDataDropDownBtn";
import NotificationsButton from "../../NotificationsBtn";
import Button from "../../Button";
import {
  PERMISSIONS_IDS,
  hasPermission
} from "../../../utils/Permissions";
import "./NavBar.scss";

const NavBar = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared,
    home: { navbar }
  } = messages[lang];
  const navigate = useNavigate();

  const isAuth = useSelector((state) => state.auth.login?.Token);

  const userMenuList = [
    {
      icon: <MyStoreIcon />,
      label: shared.myStore,
      onClick: () => {
        navigate(
          hasPermission(PERMISSIONS_IDS.company_profile_management)
            ? ROUTE_PATHS.companyProfile
            : ROUTE_PATHS.products
        );
      }
    }
  ];

  return (
    <MuiAppBar
      position="fixed"
      className="basic-layout-navbar pe-xl-3 pe-md-0">
      <Toolbar>
        <div className="navbar-info-container w-100 d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center logo-title-container">
            <div className="logo-container ps-md-3 pe-5">
              <Link to={ROUTE_PATHS.home}>
                <img
                  className="pointer w-100"
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="navbarTitle">
              <Typography sx={{ flexGrow: 1, color: "white" }}>
                {shared.navbarTitle}
              </Typography>
            </div>
          </div>
          <div className="navbarTitle-user-btns d-flex justify-content-between align-items-center ps-lg-5 ps-md-4 ps-2">
            <div className="navbar-btns-container d-flex justify-content-center gap-3 ">
              {isAuth ? (
                <>
                  <NotificationsButton />
                  <UserProfileButton parentMenuList={userMenuList} />
                </>
              ) : (
                <Button
                  label={
                    <>
                      <ProfileIcon />
                      {navbar.login}
                    </>
                  }
                  faintPrimary
                  className="me-lg-2 me-2 my-lg-0 my-3"
                  onClick={() => navigate(ROUTE_PATHS.login)}
                />
              )}
            </div>
          </div>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

export default NavBar;
