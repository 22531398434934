const subCategories = {
  breadCrumbs: {
    home: "Home",
    subCategories: "Sub Categories",
    products: "Products"
  },
  noProducts: "There are no products in this sub Category",
  noSubCategoryFound: "No Sub Category found",
  filters: {
    country: "Country",
    searchCountry: "Search by country name",
    priceRange: "Price Range",
    moq: "MOQ",
    done: "Done"
  },
  sorting: {
    recent: "Recently Added",
    moq: "Minimum Order Quantity",
    priceRange: "Price Range"
  },
  moq: "MOQ",
  productsFound: "Products found",
  productDetails: {
    minimumQuantity: "Minimum Quantity",
    pricing: "Pricing",
    items: "Items",
    country: "Country",
    overview: "Overview",
    description: "Description",
    specifications: "Specifications",
    productVideo: "Product Video",
    exporterContactDetails: "Exporter Contact Details",
    sorry: "Sorry !",
    needToSignin:
      "You need to sign in to see exporter contact details",
    signin: "Sign In",
    downloadTheCatalogue: "Download The Catalogue",
    recommendedForYou: "Recommended For You"
  }
};

export default subCategories;
