import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation
} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { KeyboardArrowDown, Verified } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import SearchInput from "../../SearchInput";
import DropdownMenu from "../../DropdownMenu";
import {
  getSearchSuggestionsRequest,
  getSearchSuggestionsResponse
} from "../../../store/Search/actions";
import { GoToArrowIcon } from "../../../utils/Icons";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { SEARCH_RESULTS_TYPES_IDS } from "../../../utils/Constants";
import "./MainSearch.scss";

const MainSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const suggestionsContainerRef = useRef(null);
  const searchInputRef = useRef(null);

  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const searchSuggestions = useSelector(
    (state) => state.search.searchSuggestions
  );
  const { search } = messages[lang];

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [searchFilter, setSearchFilter] = useState(
    SEARCH_RESULTS_TYPES_IDS.all
  );
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    search.searchPlaceholder
  );

  useEffect(() => {
    const handleResize = () => {
      setSearchPlaceholder(
        window.innerWidth <= 500
          ? search.search
          : search.searchPlaceholder
      );
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const advancedSearchFilters = [
    {
      id: SEARCH_RESULTS_TYPES_IDS.all,
      label: search.advancedSearchFilters.all,
      onClick: () => setSearchFilter(SEARCH_RESULTS_TYPES_IDS.all)
    },
    {
      id: SEARCH_RESULTS_TYPES_IDS.product,
      label: search.advancedSearchFilters.product,
      onClick: () => setSearchFilter(SEARCH_RESULTS_TYPES_IDS.product)
    },
    {
      id: SEARCH_RESULTS_TYPES_IDS.subCategory,
      label: search.advancedSearchFilters.subCategory,
      onClick: () =>
        setSearchFilter(SEARCH_RESULTS_TYPES_IDS.subCategory)
    },
    {
      id: SEARCH_RESULTS_TYPES_IDS.category,
      label: search.advancedSearchFilters.category,
      onClick: () =>
        setSearchFilter(SEARCH_RESULTS_TYPES_IDS.category)
    },
    {
      id: SEARCH_RESULTS_TYPES_IDS.company,
      label: search.advancedSearchFilters.company,
      onClick: () => setSearchFilter(SEARCH_RESULTS_TYPES_IDS.company)
    },
    {
      id: SEARCH_RESULTS_TYPES_IDS.exporter,
      label: search.advancedSearchFilters.exporter,
      onClick: () =>
        setSearchFilter(SEARCH_RESULTS_TYPES_IDS.exporter)
    }
  ];

  const handleNavigateToSearchPage = (event, typeId, itemId) => {
    event.stopPropagation();
    const searchParamsObj = { typeId, searchQuery: searchValue };
    if (itemId) searchParamsObj.itemId = itemId;
    navigate({
      pathname: ROUTE_PATHS.searchPage,
      search: createSearchParams(searchParamsObj).toString()
    });
    setIsSearchFocused(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        document
          .getElementById("main-search-suggestions-container")
          ?.contains(e?.target) ||
        document
          .getElementById("advanced-search-menu")
          ?.contains(e?.target)
      ) {
        // Clicked in box
        setIsSearchFocused(true);
      } else {
        // Clicked outside the box
        setIsSearchFocused(false);
        if (
          location.pathname !== ROUTE_PATHS.searchPage &&
          !!searchValue
        ) {
          setHighlightedIndex(-1);
          setSearchValue("");
          setSearchFilter(SEARCH_RESULTS_TYPES_IDS.all);
        }
      }
    };

    const handleKeyDown = (e) => {
      if (isSearchFocused && searchSuggestions.length > 0) {
        if (e?.key === "ArrowDown") {
          setHighlightedIndex((prevIndex) =>
            prevIndex < searchSuggestions.length - 1
              ? prevIndex + 1
              : 0
          );
        } else if (e?.key === "ArrowUp") {
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0
              ? prevIndex - 1
              : searchSuggestions.length - 1
          );
        } else if (e?.key === "Enter") {
          if (
            highlightedIndex >= 0 &&
            highlightedIndex < searchSuggestions.length
          ) {
            const suggestion = searchSuggestions[highlightedIndex];
            handleNavigateToSearchPage(
              e,
              suggestion.TypeId,
              suggestion.Id
            );
          } else {
            handleNavigateToSearchPage(e, searchFilter);
          }
        }
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchFocused, searchSuggestions, highlightedIndex]);

  useEffect(() => {
    if (isSearchFocused) {
      searchInputRef?.current?.focus();
    } else {
      searchInputRef?.current?.blur();
    }
  }, [isSearchFocused, searchInputRef]);

  useEffect(() => {
    if (searchParams) {
      setSearchValue(searchParams.get("searchQuery"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchValue) {
      dispatch(
        getSearchSuggestionsRequest({
          Keyword: searchValue,
          AdancedSearchId: searchFilter
        })
      );
    } else {
      dispatch(getSearchSuggestionsResponse([]));
    }
  }, [searchValue, searchFilter]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  const highlightText = (text, searchText) => {
    if (!searchText.trim()) {
      return text;
    }
    // Escape special characters in the searchText
    const escapedSearchText = escapeRegExp(searchText);

    const regex = new RegExp(`(${escapedSearchText})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <b key={index}>{part}</b> : part
    );
  };

  const renderSuggestion = ({ Id, Name, TypeId, IsVerified }, i) => (
    <div
      className={`suggestion d-flex justify-content-between py-2 px-4 pointer ${
        highlightedIndex === i ? "highlighted" : ""
      }`}
      key={`search-suggestion-${i}`}
      onClick={(event) => {
        handleNavigateToSearchPage(event, TypeId, Id);
      }}>
      <p className="suggestions-name title-color mb-0 ">
        {highlightText(Name, searchValue)}
        {[
          SEARCH_RESULTS_TYPES_IDS.exporter,
          SEARCH_RESULTS_TYPES_IDS.company
        ].includes(TypeId) &&
          IsVerified && <Verified color="primary" className="ms-1" />}
      </p>
      <GoToArrowIcon />
    </div>
  );

  const advancedSearchButton = () => (
    <div className="advanced-search-button">
      <div className="menu-button light-primary-bg d-flex align-items-center justify-content-between">
        <p className="text-primary mb-0">
          {
            advancedSearchFilters.find(
              (filter) => filter.id === searchFilter
            )?.label
          }
        </p>
        <KeyboardArrowDown color="primary" />
      </div>
    </div>
  );

  return (
    <div
      className="search-website-container ms-0 ms-lg-3 ms-xl-0"
      id="main-search-suggestions-container">
      <div className="d-flex align-items-center">
        <DropdownMenu
          menuButton={advancedSearchButton()}
          menuList={advancedSearchFilters}
          menuName="advanced-search"
        />
        <SearchInput
          reference={searchInputRef}
          id="general-website-search"
          name="general-website-search"
          value={searchValue || ""}
          onChange={(value) => setSearchValue(value)}
          placeholder={searchPlaceholder}
          onFocus={() => setIsSearchFocused(true)}
          endAdornment={
            isLoading && isSearchFocused ? (
              <CircularProgress size={20} />
            ) : (
              <></>
            )
          }
        />
      </div>
      {isSearchFocused && !!searchValue && (
        <div
          className="suggestions-container white-bg light-border py-4"
          ref={suggestionsContainerRef}>
          {!!searchSuggestions?.length && (
            <div className="suggestions-header d-flex justify-content-between mx-4">
              <p className="text-light-grey">
                {search.suggestedResults}
              </p>
              <p
                className="text-primary pointer fweight-600"
                onClick={(event) => {
                  handleNavigateToSearchPage(event, searchFilter);
                }}>
                {search.showAll}
              </p>
            </div>
          )}
          <div className="suggestions">
            {searchSuggestions?.length ? (
              <>
                {searchSuggestions?.map((suggestion, i) =>
                  renderSuggestion(suggestion, i)
                )}
              </>
            ) : (
              <p className="suggestions-name title-color mb-0 px-4 ">
                {search.noSuggestions}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainSearch;
