import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import noImgPlaceholder from "../../../../assets/images/empty_data.png";
import ExportersSection from "../ExportersSection";
import messages from "../../../../assets/locale/messages";
import ProductsSection from "../../Home/ProductsSection";
import ContactSection from "../../Home/ContactSection";
import SubCategoriesSection from "../../Home/SubCategoriesSection";
import { getCategoryDetailsRequest } from "../../../../store/Categories/actions";
import { checkBackgroundImageValidity } from "../../../../utils/Helpers";
import SubscribeSection from "../SubscribeSection";
import {
  USER_STATUS_IDS,
  USER_TYPES_IDS
} from "../../../../utils/Constants";
import EmptyState from "../../../../components/EmptyState";
import noCategoryImagePlaceholder from "../../../../assets/images/vectors/placeholders/noCategoryImagePlaceholder.svg";
import "./CategoryDetails.scss";

const CategoryDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userTypeId = useSelector(
    (state) => state.auth.login?.UserTypeId
  );
  const categoryData = useSelector(
    (state) => state.categories.categoryDetails.categoryData
  );
  const popularSubcategories = useSelector(
    (state) => state.categories.categoryDetails.popularSubcategories
  );
  const popularExporters = useSelector(
    (state) => state.categories.categoryDetails.popularExporters
  );
  const latestProducts = useSelector(
    (state) => state.categories.categoryDetails.latestProducts
  );
  const isLoading = useSelector((state) => state.loader.showLoader);
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  const { home } = messages[lang];
  const [isCategoryImgvalid, setIsCategoryImgvalid] = useState(false);

  useEffect(() => {
    if (categoryData?.CategoryCoverImage) {
      checkBackgroundImageValidity(
        categoryData?.CategoryCoverImage,
        (isLoaded) => {
          setIsCategoryImgvalid(isLoaded);
        }
      );
    }
  }, [categoryData?.CategoryCoverImage]);

  useEffect(() => {
    if (id) {
      dispatch(
        getCategoryDetailsRequest({
          id,
          currencyId: websiteCurrency?.Id
        })
      );
    }
  }, [id, websiteCurrency]);

  const sectionsResponsiveSpacing =
    "px-xl-5 px-md-4 px-3 py-3 mb-xl-3 mb-lg-3 mb-md-2 mb-0";

  return categoryData && Object.keys(categoryData).length ? (
    <div className="category-details-container w-100">
      <div
        className={`category-image cover-image-container d-flex flex align-items-end  w-100 size-cover`}
        style={{
          backgroundImage: `url(${isCategoryImgvalid ? categoryData?.CategoryCoverImage : noImgPlaceholder})`
        }}>
        <Box className="text-start d-flex flex-column justify-content-end  ps-5 pb-5 category-info">
          <div className="container-xxl">
            <Typography
              className="text-white fweight-700 mb-2"
              sx={{
                fontSize: {
                  sx: "1.3rem",
                  sm: "2.3rem"
                }
              }}>
              {categoryData.Name}
            </Typography>
            <p className="mb-0 text-white fweight-500">
              {categoryData.Description}
            </p>
          </div>
        </Box>
      </div>
      <div className="container-xxl">
        {!!popularSubcategories?.length && (
          <div className={`${sectionsResponsiveSpacing} mt-3`}>
            <SubCategoriesSection
              subCategories={popularSubcategories}
            />
          </div>
        )}
        {!!popularExporters?.length && (
          <div className={sectionsResponsiveSpacing}>
            <ExportersSection exporters={popularExporters} />
          </div>
        )}
        <div className={sectionsResponsiveSpacing}>
          <SubscribeSection
            isUserSubscribed={
              userStatusId === USER_STATUS_IDS.subscribed
            }
          />
        </div>
        {!!latestProducts?.length && (
          <div className={sectionsResponsiveSpacing}>
            <ProductsSection
              sectionTitle={home.latestProducts}
              products={latestProducts}
              sectionKey="latest"
            />
          </div>
        )}
        {(!isAuth ||
          (isAuth && userTypeId !== USER_TYPES_IDS.exporter)) && (
          <div className={sectionsResponsiveSpacing}>
            <ContactSection hideAvatar={true} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div>
      {!isLoading && (
        <EmptyState
          subTitle={home.noCategoryFoundPlaceholder}
          imgSrc={noCategoryImagePlaceholder}
        />
      )}
    </div>
  );
};

export default CategoryDetails;
