export const CloseMediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    fill="none"
    viewBox="0 0 29 29">
    <rect
      width="28"
      height="28"
      x="0.924"
      y="0.211"
      fill="#F8F8F8"
      rx="14"></rect>
    <path
      fill="#455A64"
      d="M11.092 10.38a.57.57 0 01.809 0l3.023 3.024 3.023-3.025a.571.571 0 11.81.81l-3.025 3.023 3.024 3.023a.573.573 0 01-.809.809l-3.023-3.025-3.023 3.025a.573.573 0 01-.81-.81l3.025-3.022-3.024-3.024a.57.57 0 010-.809z"></path>
  </svg>
);
