import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import {
  getImporterProductRequestDetailsRequest,
  getImporterProductRequestDetailsResponse
} from "../../../../store/ProductRequests/actions";
import {
  CHIPS_CLASSES,
  PRODUCT_REQUEST_STATUS_ID
} from "../../../../utils/Constants";
import {
  checkForMultiline,
  formatDate,
  isRichText,
  RichTextToHTML
} from "../../../../utils/Helpers";
import Button from "../../../../components/Button";
import { ViewEditIcon } from "../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import CloseProductRequest from "../CloseProductRequest";

const ViewProductRequestDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const importerProductRequestDetails = useSelector(
    (state) => state.productRequests?.importerProductRequestDetails
  );
  const {
    shared,
    productRequests: {
      importerProductRequests: {
        viewProductRequest: {
          pageTitle,
          closeRequestButton,
          editButton,
          requestInfoSection,
          requestDetailsSection
        }
      }
    }
  } = messages[lang];

  const [
    closedProductRequestDetails,
    setClosedProductRequestDetails
  ] = useState({});
  const [
    closeProductRequestModalOpen,
    setCloseProductRequestModalOpen
  ] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const sectionFormatting =
    "bg-white p-5 bg-white light-border border-radius-8";

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 425) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  };

  const handleCloseProductRequest = () => {
    if (
      importerProductRequestDetails &&
      Object.keys(importerProductRequestDetails).length
    ) {
      setClosedProductRequestDetails({
        id,
        name: importerProductRequestDetails.Title
      });
      setCloseProductRequestModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setCloseProductRequestModalOpen(false);
    setClosedProductRequestDetails({});
  };

  const renderLabelValue = (label, value) => (
    <div className="d-md-flex align-items-center mb-md-0">
      <span className="text-light-grey fsize-12 fweight-400 col-xl-6 col-md-4">
        {label}
      </span>
      <span className="d-block mb-3 text-color fsize-16 fweight-400 col-xl-6 col-md-8 mt-md-0 mt-2 mb-md-0 text-start">
        {value}
      </span>
    </div>
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getImporterProductRequestDetailsRequest({
          params: {
            id
          }
        })
      );
    }
    return () => {
      dispatch(getImporterProductRequestDetailsResponse({}));
    };
  }, [id]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="view-product-request-container">
      <div className="page-title d-md-flex align-items-center justify-content-md-between justify-content-center pb-4">
        <Typography
          variant="h5"
          className="title-color text-break text-md-start text-center">
          {pageTitle}
        </Typography>
        {importerProductRequestDetails &&
          !!Object.keys(importerProductRequestDetails).length &&
          importerProductRequestDetails.StatusId ===
            PRODUCT_REQUEST_STATUS_ID.published && (
            <div className="title-buttons-container d-flex mt-md-0 mt-2 justify-content-center gap-3 align-items-center">
              <Button
                outlined
                danger
                label={
                  <span
                    className="d-flex align-items-center"
                    style={{
                      fontSize: `${isMobileScreen ? "0.8rem" : ""}`
                    }}>
                    <span className="ps-1 ">
                      {closeRequestButton}
                    </span>
                  </span>
                }
                onClick={handleCloseProductRequest}
              />
              <Button
                className={`bg-white px-0 `}
                outlined
                label={
                  <span className="d-flex align-items-center">
                    <ViewEditIcon
                      width={isMobileScreen ? 15 : 21}
                      height={isMobileScreen ? 15 : 21}
                    />
                    <span
                      className="ps-md-3 ps-2 d-md-block"
                      style={{
                        fontSize: `${isMobileScreen ? "0.8rem" : ""}`
                      }}>
                      {editButton}
                    </span>
                  </span>
                }
                onClick={() => {
                  navigate(
                    ROUTE_PATHS.editImporterProductRequests.replace(
                      ":id",
                      id
                    )
                  );
                }}
              />
            </div>
          )}
      </div>
      {importerProductRequestDetails &&
        !!Object.keys(importerProductRequestDetails).length && (
          <div className="data-container d-xl-flex">
            <div className={`col-xl-8 col-12 pe-xl-4 pb-xl-0 pb-4`}>
              <div
                className={`product-request-info-section ${sectionFormatting}`}
                style={{
                  height: "100%"
                }}>
                <div className="card-label mb-3">
                  <Typography className="fweight-500 fsize-22 title-color">
                    {importerProductRequestDetails.Title}
                  </Typography>
                </div>
                <div className="mb-3 w-100 text-break">
                  {isRichText(
                    importerProductRequestDetails.Details
                  ) ? (
                    <p
                      className="text-break"
                      dangerouslySetInnerHTML={
                        importerProductRequestDetails.Details
                          ? RichTextToHTML(
                              checkForMultiline(
                                importerProductRequestDetails.Details
                              )
                            )
                          : ""
                      }></p>
                  ) : (
                    <p className="text-break">
                      {importerProductRequestDetails.Details
                        ? checkForMultiline(
                            importerProductRequestDetails.Details
                          )
                        : ""}
                    </p>
                  )}
                </div>
                <div className="categories-section mb-4 mb-md-0">
                  <h4 className="fsize-16 fweight-500 title-color mb-3">
                    {requestDetailsSection.categories}
                  </h4>
                  {importerProductRequestDetails.Categories &&
                    !!importerProductRequestDetails.Categories
                      .length && (
                      <div className="categories-section light-border-bottom pb-4">
                        <div className="categories-chips d-flex justify-content-start flex-wrap">
                          {importerProductRequestDetails?.Categories &&
                          !!importerProductRequestDetails.Categories
                            .length
                            ? importerProductRequestDetails?.Categories.map(
                                ({ Name, Id }, i) => (
                                  <Chip
                                    key={`category-${Id}`}
                                    label={Name}
                                    className={`blue-chip border-radius-5 fsize-14 fweight-400 ${i === importerProductRequestDetails?.Categories?.length - 1 ? "" : "me-2"}  my-2`}
                                  />
                                )
                              )
                            : "--"}
                        </div>
                      </div>
                    )}
                </div>
                {(importerProductRequestDetails.PriceFrom ||
                  importerProductRequestDetails.PriceTo) && (
                  <div className="price-section d-md-flex align-items-center mb-2 mt-4">
                    <span className="text-light-grey fsize-12 fweight-400 col-xl-3 col-md-4">
                      {requestDetailsSection.budgetRange}
                    </span>
                    <div className="price mt-md-0 mt-2 text-md-start col-xl-9 col-md-8 mt-md-0 mt-2">
                      <p className="fsize-16 text-dark-blue fweight-600 mb-0">
                        {!!importerProductRequestDetails.PriceFrom && (
                          <span>
                            {
                              importerProductRequestDetails.CurrencySymbol
                            }
                            {importerProductRequestDetails.PriceFrom}
                          </span>
                        )}
                        {!!importerProductRequestDetails.PriceFrom &&
                          !!importerProductRequestDetails.PriceTo && (
                            <span> - </span>
                          )}
                        {!!importerProductRequestDetails.PriceTo && (
                          <span>
                            {
                              importerProductRequestDetails.CurrencySymbol
                            }
                            {importerProductRequestDetails.PriceTo}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="budget-range-section d-md-flex align-items-center mb-md-0">
                  <span className="text-light-grey fsize-12 fweight-400 col-xl-3 col-md-4">
                    {requestDetailsSection.requiredQuantity}
                  </span>
                  <p className="budget text-color fsize-16 col-xl-9 col-md-8 mt-md-0 mt-2 mb-md-0 text-start">
                    {`${
                      importerProductRequestDetails.QuantityFrom ===
                      null
                        ? shared.any
                        : importerProductRequestDetails.QuantityFrom
                    } - ${
                      importerProductRequestDetails.QuantityTo ===
                      null
                        ? shared.any
                        : importerProductRequestDetails.QuantityTo
                    } ${
                      importerProductRequestDetails.QuantityUnit ===
                      "Other"
                        ? importerProductRequestDetails.OtherQuantityUnit
                        : importerProductRequestDetails.QuantityUnit ||
                          ""
                    }`}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`request-info-section col-xl-4 col-12 ${sectionFormatting}`}>
              <div className="card-label mb-3">
                <Typography className="fweight-600 fsize-16 title-color">
                  {requestInfoSection.requestInfo}
                </Typography>
              </div>
              <div className="request-status mb-3">
                {renderLabelValue(
                  requestInfoSection.requestStatus,
                  <Chip
                    label={importerProductRequestDetails.StatusName}
                    className={`border-radius-5 fsize-14 fweight-400 ${importerProductRequestDetails && importerProductRequestDetails.StatusId === PRODUCT_REQUEST_STATUS_ID.published ? CHIPS_CLASSES.green : CHIPS_CLASSES.red}`}
                  />
                )}
              </div>
              <div className="post-date mb-3">
                {renderLabelValue(
                  requestInfoSection.postDate,
                  importerProductRequestDetails.PostDate
                    ? formatDate({
                        date: importerProductRequestDetails.PostDate
                      })
                    : "--"
                )}
              </div>
              <div className="closed-date mb-3">
                {renderLabelValue(
                  requestInfoSection.closureDate,
                  importerProductRequestDetails.ClosedDate
                    ? formatDate({
                        date: importerProductRequestDetails.ClosedDate
                      })
                    : "--"
                )}
              </div>
              <div className="closed-date mb-3">
                {renderLabelValue(
                  requestInfoSection.lastUpdatedAt,
                  importerProductRequestDetails.LastUpdatedDate
                    ? formatDate({
                        date: importerProductRequestDetails.LastUpdatedDate
                      })
                    : "--"
                )}
              </div>
            </div>
          </div>
        )}
      {closeProductRequestModalOpen && (
        <CloseProductRequest
          open={closeProductRequestModalOpen}
          closeModal={handleCloseModal}
          productRequestInfo={closedProductRequestDetails}
        />
      )}
    </div>
  );
};

export default ViewProductRequestDetails;
