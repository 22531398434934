import PropTypes from "prop-types";
import { TextField, InputLabel, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

import "./SearchInput.scss";

const SearchInput = ({
  name,
  value,
  onChange,
  id,
  label,
  placeholder,
  defaultValue,
  disabled,
  fullWidth,
  inputClass,
  inputWrapperClass,
  labelClassName,
  onFocus,
  onBlur,
  endAdornment,
  reference
}) => (
  <div className={`search-input-wrapper ${inputWrapperClass}`}>
    <InputLabel htmlFor={id || name} className={labelClassName}>
      <>{label}</>
    </InputLabel>
    <TextField
      inputRef={reference}
      id={id || name}
      name={name}
      type="text"
      autoFocus={!!value}
      value={value}
      defaultValue={defaultValue}
      onChange={(e) =>
        onChange(e.target.value?.replace(/\s\s+/g, " ")?.trimStart())
      }
      placeholder={placeholder}
      disabled={disabled}
      fullWidth={fullWidth}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`${inputClass}`}
      disableunderline="true"
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search fontSize="medium" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {endAdornment}
          </InputAdornment>
        )
      }}
    />
  </div>
);

export default SearchInput;

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  endAdornment: PropTypes.element,
  reference: PropTypes.any
};

SearchInput.defaultProps = {
  fullWidth: true
};
