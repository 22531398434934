import {
  SET_BASIC_LAYOUT_CONTAINER_REF,
  SET_EXPORTER_LAYOUT_CONTAINER_REF,
  SET_IMPORTER_LAYOUT_CONTAINER_REF,
  SET_PAGE_TITLE
} from "./types";

const INITIAL_STATE = {
  pageTitle: "",
  exporterLayoutContainerRef: null,
  importerLayoutContainerRef: null,
  basicLayoutContainerRef: null
};

const navigation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return { ...state, pageTitle: action.payload };
    case SET_EXPORTER_LAYOUT_CONTAINER_REF:
      return { ...state, exporterLayoutContainerRef: action.payload };
    case SET_IMPORTER_LAYOUT_CONTAINER_REF:
      return { ...state, importerLayoutContainerRef: action.payload };
    case SET_BASIC_LAYOUT_CONTAINER_REF:
      return { ...state, basicLayoutContainerRef: action.payload };
    default:
      return state;
  }
};

export default navigation;
