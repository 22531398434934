import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import messages from "../../../../assets/locale/messages";
import img from "../../../../assets/images/home/image9.png";
import bgImg from "../../../../assets/images/home/background.svg";
import Button from "../../../../components/Button";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { USER_TYPES_IDS } from "../../../../utils/Constants";

const ContactSection = ({ hideAvatar }) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const { home } = messages[lang];

  return (
    <div
      className={`contact-section-container ${hideAvatar ? "p-md-0 p-2" : "py-md-0 py-3"} `}>
      <div
        className="green-bg border-radius-10 w-100"
        style={{ backgroundImage: `url(${bgImg})` }}></div>
      <div className={"px-md-5 px-3 row "}>
        <div
          className={`${hideAvatar ? "col-12 d-flex justify-content-center align-items-center" : "col-md-6 col-lg-7 d-flex align-items-end"}`}>
          <div
            className={`${hideAvatar ? "text-center col-xl-5 col-md-7 col-12" : "py-0 py-md-3 py-lg-5 mb-4"} content `}>
            <h3 className="text-white section-title fsize-30 fweight-700 pt-0 pt-md-5">
              {home.contact.title}
            </h3>
            <p className="text-white section-content fsize-18 px-xl-3 px-2">
              {home.contact.content}
            </p>
            <Button
              onClick={() =>
                userType !== USER_TYPES_IDS.exporter &&
                navigate(ROUTE_PATHS.addImporterProductRequests)
              }
              reversedcolor
              label={home.footer.requestProduct}
            />
          </div>
        </div>
        <div
          className={`col-md-6 col-lg-5 d-none  ${hideAvatar ? "" : " d-md-block"}`}>
          <div className="image w-100 h-100 d-flex align-items-end">
            <img src={img} className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

ContactSection.propTypes = {
  hideAvatar: PropTypes.bool
};
export default ContactSection;
