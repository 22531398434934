import React from "react";
import PropTypes from "prop-types";
import { ViewEditIcon } from "../../utils/Icons";
import "./ViewEditButton.scss";

const ViewEditButton = ({
  containerClassName,
  className,
  onClick
}) => {
  return (
    <div
      className={`${containerClassName} edit-btn`}
      onClick={onClick}>
      <ViewEditIcon
        className={`table-icon action-icon  ${className}`}
      />
    </div>
  );
};

ViewEditButton.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ViewEditButton;
