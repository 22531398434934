import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { reactivateSubAccountProfileRequest } from "../../../../store/SubAccounts/actions";
import { ActivateAccountIcon } from "../../../../utils/Icons/ActivateAccount";

const activateSubAccount = ({
  open,
  closeModal,
  subAccountInfo,
  params
}) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);

  const {
    subAccounts: {
      activateSubAccount: { content, confirmationContent, reactivate }
    },
    shared
  } = messages[lang];

  const handleConfirmActivation = () => {
    dispatch(
      reactivateSubAccountProfileRequest({
        data: { id: subAccountInfo?.id },
        params
      })
    );
    closeModal();
  };

  const activationContent = () => {
    return (
      <div className="activate-sub-account-container container d-flex flex-column justify-content-center align-items-center">
        <ActivateAccountIcon />
        <div className="activate-sub-account-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${subAccountInfo.name}" ${shared.account} `}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmActivation();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={activationContent()}
      hasActions={true}
      confirmBtnTxt={reactivate}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default activateSubAccount;

activateSubAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmActivation: PropTypes.func.isRequired,
  subAccountInfo: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};
