export const CardBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    fill="none"
    viewBox="0 0 45 45"
    {...props}>
    <rect
      width="44"
      height="44"
      x="0.266"
      y="0.309"
      fill="#F1FAFF"
      rx="10"></rect>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M12.928 19.04h11.674M16.662 26.512h1.868M20.865 26.512h3.736"></path>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M31.607 24.206v1.942c0 3.279-.831 4.1-4.147 4.1H17.074c-3.315 0-4.146-.822-4.146-4.1v-7.677c0-3.278.831-4.1 4.146-4.1h7.528M29.738 19.975V14.37l1.868 1.868M29.737 14.371L27.87 16.24"></path>
  </svg>
);
