import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import messages from "../../../../../assets/locale/messages";
import emptyProductPlaceholder from "../../../../../assets/images/empty_data.png";
import Slider from "../../../../../components/Slider";
import Button from "../../../../../components/Button";
import ProductCardGrid from "../../../../../components/ProductCards/ProductCardGrid";
import {
  checkBackgroundImageValidity,
  checkForMultiline
} from "../../../../../utils/Helpers";
import showcaseInsufficientProductsCardImg from "../../../../../assets/images/showcaseInsufficientProductsCardImg.svg";
import "./ShowCase.scss";

const ShowCase = ({
  products,
  info,
  showcaseImages,
  exporterId,
  subCategoryId,
  collapseOnLargeSize,
  noProductsPlaceholder,
  showcaseCoverImg
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const navigate = useNavigate();

  const [imgsSlides, setImgsSlides] = useState([]);
  const [imageValidity, setImageValidity] = useState({});

  useEffect(() => {
    if (showcaseImages.length) {
      showcaseImages.forEach((src) => {
        checkBackgroundImageValidity(src, (isLoaded) => {
          setImageValidity((prevValidity) => ({
            ...prevValidity,
            [src]: isLoaded
          }));
        });
      });
    }
  }, [showcaseImages]);

  useEffect(() => {
    if (showcaseImages?.length) {
      setImgsSlides(
        showcaseImages.map((src, i) => (
          <div
            key={`showcase-slide-${i}`}
            className="single-show-case-slide border-radius-10"
            style={{
              backgroundImage: `url(${imageValidity[src] ? src : emptyProductPlaceholder})`
            }}></div>
        ))
      );
    }
  }, [showcaseImages, imageValidity]);

  return (
    <div className="show-case-container">
      <div className="row">
        <div
          className={`${collapseOnLargeSize ? "col-lg-6" : "col-xl-6 col-lg-12"} col-md-12 col-12 mb-4 mb-md-0`}>
          <div className="slider-info-container border-radius-10 h-100">
            <div className="slider h-100 border-radius-10">
              <Slider
                slides={imgsSlides}
                slidesToShow={1}
                slidesToScroll={1}
                sliderClassName="product-showcase-slider h-100 border-radius-10"
                slideClassName={"border-radius-10"}
                infinite
                autoplay
                fade
              />
            </div>
            <div className="description w-100 border-radius-10 d-flex flex-column justify-content-center align-items-center">
              <div className="showcase-info d-flex justify-content-center align-items-center text-center mb-3">
                <div>
                  <Typography className="showcase-name fweight-700 fsize-28 px-md-5 px-3 text-white">
                    {info.name}
                  </Typography>
                  <Typography
                    variant="span"
                    className="mb-3 showcase-description px-md-5 px-3 text-white">
                    {checkForMultiline(
                      info.description,
                      "fweight-500 fsize-16 text-white"
                    )}
                  </Typography>
                </div>
              </div>
              {subCategoryId && exporterId && (
                <Button
                  label={shared.seeAllProducts}
                  outlined
                  whiteBtn
                  onClick={() => {
                    navigate(
                      ROUTE_PATHS.subCategoryDetails.replace(
                        ":id",
                        subCategoryId
                      ),
                      {
                        state: {
                          exporterId,
                          showcaseCoverImg
                        }
                      }
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={`${collapseOnLargeSize ? "col-lg-6" : "col-xl-6 col-lg-12"} col-md-12 col-12 d-lg-flex flex-wrap`}>
          {noProductsPlaceholder && !products?.length ? (
            noProductsPlaceholder
          ) : (
            <div className="w-100 d-flex justify-content-start flex-wrap products-container mt-xl-0 mt-md-4 mt-0 gap-3 px-lg-3">
              {products.map(
                (product, i) =>
                  i < 6 && (
                    <div
                      className="single-product filled-product border-radius-10 editable-product-card"
                      key={`product-${i}`}>
                      <ProductCardGrid {...product} />
                    </div>
                  )
              )}
              {products.length <= 3 && (
                <div className="showcase-products-card d-md-flex p-md-5 p-3 py-4 border-radius-10 mt-xl-0 mt-4">
                  <div className="card-img-container mb-md-0 mb-3 d-flex justify-content-md-start justify-content-center col-xxl-6  col-xl-4 col-lg-3 col-md-4 col-12 ">
                    <img
                      style={{ width: "10rem", height: "10rem" }}
                      src={showcaseInsufficientProductsCardImg}
                    />
                  </div>
                  <div className="card-content-container col-xxl-6 col-xl-8 col-lg-9 col-md-8 col-12">
                    <p className="text-break fweight-500 title-color fsize-18 ">
                      {shared.showcaseInsufficientProductsCard}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowCase;

ShowCase.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minPrice: PropTypes.number,
      maxPrice: PropTypes.number,
      count: PropTypes.number
    })
  ).isRequired,
  info: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  showcaseImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  exporterId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  subCategoryId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  collapseOnLargeSize: PropTypes.bool,
  showcaseCoverImg: PropTypes.string,
  noProductsPlaceholder: PropTypes.element
};
