import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Verified } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import Swiper from "../../../../components/Swiper";
import ProductCardGrid from "../../../../components/ProductCards/ProductCardGrid";
import { LocationIcon } from "../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import "./ExportersSection.scss";

const ExportersSection = ({ exporters }) => {
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );
  const lang = useSelector((state) => state.locale.lang);
  const navigate = useNavigate();
  const { categories } = messages[lang];

  const [exportersCards, setExportersCards] = useState([]);

  useEffect(() => {
    if (exporters?.length) {
      setExportersCards(
        exporters.map(
          (
            {
              CompanyId,
              TradeName,
              LogoImage,
              Introduction,
              CountryName,
              Products,
              IsVerified
            },
            i
          ) => {
            // This is used to show only 4 products in a row
            const splicedProductsArray = Products?.length
              ? [...Products]
              : [];
            splicedProductsArray.splice(4, Products?.length);
            return (
              <div
                className="p-5 exporter-card-container gap-md-3 pointer"
                key={`exporter-${i} `}>
                <Link
                  to={ROUTE_PATHS.exporterProfile.replace(
                    ":id",
                    CompanyId
                  )}>
                  <div className="exporter-details d-flex w-100 mb-3 ">
                    <div className="w-auto">
                      <div
                        className="profile-img circular-border-radius white-bg light-border"
                        style={{
                          backgroundImage: `url(${LogoImage})`
                        }}
                      />
                    </div>
                    <div className="w-100 info-section">
                      <h3 className="fsize-20 fweight-700 title-color mb-2">
                        {TradeName}
                        {IsVerified && (
                          <Verified
                            color="primary"
                            className="ms-1"
                          />
                        )}
                      </h3>
                      <div className="country d-flex mb-2">
                        <LocationIcon />
                        <span className="text-grey fsize-12 mx-1">
                          {CountryName}
                        </span>
                      </div>
                      <div className="description-section mb-4">
                        {Introduction}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="w-100">
                  <h4 className="fsize-16 fweight-600 mb-3">
                    {`${TradeName} ${categories.categoryDetails.popularProducts}`}
                  </h4>

                  <div className=" exporter-products-container row">
                    {splicedProductsArray.map(
                      (
                        {
                          Id,
                          Name,
                          DefaultImage,
                          ExporterCountry,
                          MinOrderQuantity,
                          MoqUnitName,
                          MinPriceRange,
                          MaxPriceRange
                        },
                        i
                      ) => (
                        <div
                          onClick={(e) => {
                            navigate(
                              ROUTE_PATHS.productDetails.replace(
                                ":id",
                                Id
                              )
                            );
                            e.stopPropagation();
                          }}
                          className="exporter-product-card my-3 border-radius-8 col-xl-3 col-lg-3 col-md-4"
                          key={`product-${Id}`}>
                          <ProductCardGrid
                            greyBg={false}
                            src={DefaultImage}
                            label={Name}
                            unit={MoqUnitName}
                            count={MinOrderQuantity}
                            minPrice={MinPriceRange}
                            maxPrice={MaxPriceRange}
                            link={ROUTE_PATHS.productDetails.replace(
                              ":id",
                              Id
                            )}
                            currency={websiteCurrency?.Symbol}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          }
        )
      );
    }
  }, [exporters, websiteCurrency]);

  return (
    <div className="category-exporters-section-container">
      <h4 className="fweight-700 mb-4 fsize-22">
        {categories.categoryDetails.popularExporters}
      </h4>
      {exportersCards.length > 1 ? (
        <Swiper
          slides={exportersCards}
          slidesToShow={1}
          infinite={false}
          speed={750}
          sliderClassName={
            "category-exporters-slider faint-grey-bg border-radius-20"
          }
          sliderContainerClassName={
            "category-exporters-slider-container"
          }
          name={"category-exporters-slider"}
          dots
        />
      ) : (
        <div className="faint-grey-bg border-radius-20">
          {exportersCards[0]}
        </div>
      )}
    </div>
  );
};

export default ExportersSection;

ExportersSection.propTypes = {
  exporters: PropTypes.array.isRequired
};
