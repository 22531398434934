import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar,
  IconButton
} from "@mui/material";
import { Menu } from "@mui/icons-material";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { MyAccountIcon } from "../../../utils/Icons";
import messages from "../../../assets/locale/messages";
import Logo from "../../../assets/images/vectors/Logo.svg";
import UserProfileButton from "../../UserDataDropDownBtn";
import NotificationsButton from "../../NotificationsBtn";
import SwitchUserCurrentRoleButton from "../../SwitchUserCurrentRoleButton";
import { USER_TYPES_IDS } from "../../../utils/Constants";
import { isExporterRole } from "../../../utils/Helpers";
import { getDeviceId } from "../../../utils/Auth";
import { isUserForcedLogoutRequest } from "../../../store/Auth/actions";
import "./NavBar.scss";

const NavBar = ({
  drawerWidth,
  openMobileSideMenu,
  navbarHeight
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const { shared } = messages[lang];

  const isAuth = useSelector((state) => state.auth.login?.Token);
  const id = useSelector((state) => state.auth.login?.Id);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      dispatch(
        isUserForcedLogoutRequest({
          device_id: getDeviceId(),
          navigate,
          params: {
            id,
            isEmployee: false
          }
        })
      );
    }
  }, [location]);

  const customStyle = {
    backgroundColor: "white",
    transition: (theme) =>
      theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
  };

  const userMenuList = [
    {
      icon: <MyAccountIcon />,
      label: isExporterRole(userCurrentRole, userType)
        ? shared.myStore
        : shared.myDashboard
    }
  ];

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        ...customStyle
      }}
      className="nav-bar pe-xl-3 pe-md-0">
      <Toolbar>
        <div>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={() => openMobileSideMenu(true)}
            sx={{
              color: "white",
              display: {
                md: "none"
              }
            }}
            className="mobile-toggle-icon">
            <Menu />
          </IconButton>
        </div>
        <div className="navbar-info-container w-100 d-flex justify-content-between align-items-center">
          <div
            className="logo-container ps-md-3"
            style={{ width: `calc(${drawerWidth}px - 24px)` }}>
            <Link to={ROUTE_PATHS.home}>
              <img className="pointer" src={Logo} alt="Logo" />
            </Link>
          </div>
          <div
            className="navbarTitle-user-btns d-flex justify-content-between align-items-center ps-xl-5 ps-md-4 ps-2"
            style={{ width: `calc( 100% - ${drawerWidth}px + 24px` }}>
            <div
              className={`navbarTitle ${userType === USER_TYPES_IDS.both && "combined-user"}`}>
              <Typography sx={{ flexGrow: 1, color: "white" }}>
                {shared.navbarTitle}
              </Typography>
            </div>

            <div
              className={`navbar-btns-container d-flex justify-content-center gap-3 ${userType === USER_TYPES_IDS.both && "align-items-center"} `}>
              {userType === USER_TYPES_IDS.both && (
                <SwitchUserCurrentRoleButton />
              )}
              <NotificationsButton />
              <UserProfileButton parentMenuList={userMenuList} />
            </div>
          </div>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

NavBar.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  navbarHeight: PropTypes.number.isRequired,
  openMobileSideMenu: PropTypes.func.isRequired
};

export default NavBar;
