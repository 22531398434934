const products = {
  addEditProduct: {
    addPageTitle: "Add Product",
    editPageTitle: "Edit Product",
    optional: "(Optional)",
    blockLabels: {
      categorySubCategoryBlock: "Category & Sub Category",
      productDetailsBlock: "Product Basic Info",
      productImagesBlock: "Product Images & Video",
      productVideoBlock: "Product Video",
      minimumOrderQuantityBlock: "Minimum Order Quantity",
      productSpecificationBlock: "Product Specification",
      priceRangeBlock: "Price Range",
      searchingKeywordsBlock: "Searching Keywords"
    },
    inputLabels: {
      category: "Category",
      subCategory: "Sub Category",
      productName: "Product Name",
      productHsCode: "Product HS Code",
      productDescription: "Product Description",
      minimumOrderQuantity: "Minimum Order",
      unit: "Unit",
      specificationName: "Specification Name",
      specificationValue: "Value",
      minimumPriceRangeQuantity: "From",
      maximumPriceRangeQuantity: "To",
      pricePerUnit: "Price / Unit",
      keyword: "Keyword"
    },
    tooltip: {
      productHsCodeTooltip:
        "HS code is a unique global number for each product consisting of 6 to 10 digits."
    },
    placeholders: {
      categoryPlaceholder: "Select the Category",
      subCategoryPlaceholder: "Select the sub category",
      productNamePlaceholder: "Enter the name",
      productHsCodePlaceholder: "Enter the code",
      productDescriptionPlaceholder: "Enter the description",
      productImagesBlockPlaceholder:
        "You can select more than one image",
      addVideo: "Add Video",
      chooseDefaultImageBlockPlaceholder:
        "Kindly select one image to be the main image",
      productImagesPlaceholder:
        "Need Help ? You can ask for Support from our team Send Message",
      productVideoPlaceholder:
        "You have contacted our support team and will respond as soon as possible",
      minimumOrderQuantityPlaceholder: "Enter minimum order quantity",
      orderUnitPlaceholder: "Enter unit",
      productSpecificationBlockPlaceholder:
        "Please enter specification about the product ex. Color : Red",
      specificationNamePlaceholder: "Select a specification",
      specificationValuePlaceholder: "Enter the value",
      minimumPriceRangeQuantityPlaceholder: "Enter minimum quantity",
      maximumPriceRangeQuantityPlaceholder: "Enter maximum quantity",
      pricePerUnitPlaceholder: "Enter price / Unit",
      searchingKeywordsBlockPlaceholder:
        "To add keywords , kindly type them in the field and press enter after each one.",
      keywordPlaceholder: "Enter keyword",
      productVideoBlockPlaceholder: "(Optional)"
    },
    defaultImg: "Make Default",
    deleteImg: "Delete",
    addMore: "Add More",
    addSuccessModal: {
      title: "Successful!",
      firstSubtitle: "Your product is under review",
      secondSubtitle:
        "Your product is under review, we'll get you notified once being approved",
      goToList: "Go To Products List",
      addMoreProducts: "Add More Products"
    },
    noCatalogueUploaded: "No Catalogue chosen",
    audioDescription: {
      loadingTranscribedText:
        "Please wait while the audio is being transcribed into text",
      voiceRecordOption:
        "Say it, and we'll write it! You can now record your voice, and we'll transcribe it for you. Give it a try!",
      recordNow: "Record Now",
      recording: "Recording",
      showWrittenDescription: "Show written description",
      showAudioDescription: "Show audio description",
      audioMaxLength:
        "The audio accepts 2 minutes only and will stop automatically if max length reached"
    },
    loaderAddText: "Please wait...\nThe product is being added",
    loaderEditText: "Please wait...\nThe product is being edited",
    guideInstruction: {
      guideTitle:
        "Important instructions to help you upload the highest quality images to gain the trust of importers.",
      textInstructions: {
        whiteBackground: "Upload images with a white background.",
        wellLit:
          "Ensure the product is well-lit and clearly visible.",
        highRes:
          "Use a high-resolution camera for sharp and detailed images.",
        multipleAngles:
          "Capture images from multiple angles (front, back, side, and close-ups).",
        avoidFilters:
          "Avoid using filters or editing tools that alter the product's appearance.",
        ensureClean:
          "Ensure the product is clean and free from any visible defects before taking pictures.",
        tripod:
          "Ensure the product is clean and free from any visible defects before taking pictures.",
        checkQuality:
          "Check the image quality before uploading to ensure it meets the standards.",
        includeLabels:
          "Include any important labels or branding in the images."
      },
      imageInstructions: {
        goodBackground: "Good background",
        badBackground: "Bad background",
        goodAngle: "Good angle",
        badAngle: "Bad angle",
        goodExample: "Good example",
        badExample: "Bad example"
      }
    }
  },
  listProducts: {
    listPageTitle: "My Products",
    productsCount: "Products",
    addMoreBtn: "Add Products",
    searchPlaceholder: "Search using Product Name",
    noSearchFilterResults:
      "There is no search result or filter result that matches the entered data.",
    noProductsPlaceholder: "There is no products found",
    subscribeWarningText:
      "Get your products seen! Subscribe now and take them off the shelf and into the spotlight",
    subscribeWarningHighlightedText: "Subscribe Now",
    noSubcategoriesPlaceholder:
      "The selected category doesn't have any subcategories.",
    list: {
      product: "Product",
      productName: "Product Name",
      category: "Category",
      subCategory: "Sub Category",
      publishDate: "Publishing/Unpublishing Date",
      status: "Status",
      action: "Publishing Action"
    }
  },
  viewProduct: {
    viewPageTitle: "Product Details",
    publishProduct: "Publish Product",
    unpublishProduct: "Unpublish Product",
    productStatus: "Product Status",
    productCode: "Product Code",
    rejectedProductTitle:
      "The product has been rejected because of these reasons :",
    cancelEdits: "Revert Edits",
    disabledProductTooltipWarning:
      "Rejected products can’t be published without editing them."
  },
  publishProduct: {
    content: "Publish",
    confirmationContent:
      "Are you sure you want to publish this product ?"
  },
  publishedEditedProduct: {
    content: "Save & Publish",
    confirmationContent:
      "Please note that any change in the product will be reflected in the edit request on the admin side."
  },
  unpublishProduct: {
    content: "Unpublish",
    confirmationContent:
      "Are you sure you want to unpublish this product ?",
    editedProductConfirmationContent:
      "Kindly note that this action will not affect the version that is still pending approval. If you would like to cancel this version as well, please use “Revert Edits"
  },
  deleteProduct: {
    content: "Delete",
    confirmationContent:
      "Are you sure you want to delete this product ?",
    showcaseProductConfirmationContent:
      "This product is linked to a showcase and deleting it will affect the showcase. Are you sure you want to delete this product ?",
    subConfirmationContent: " This action cannot be undone."
  },
  cancelProductEdits: {
    content: "Cancel",
    contentAction: "Edits",
    confirmationContent:
      "Are you sure you want to cancel this product Edit ?",
    subConfirmationContent: " This action cannot be undone."
  },
  revertEdit: {
    revertButton: "Show unpublished/published version",
    cancelRevertButton: "Show Edited Version"
  },
  changeShowcaseProductSubcategory: {
    content: "Change Subcategory",
    confirmationContent:
      "Kindly note that changing the subcategory will affect the linked showcase"
  }
};
export default products;
