import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { PublishModalIcon } from "../../../../utils/Icons";
import { publishProductRequest } from "../../../../store/Products/actions";

const PublishProduct = ({ open, closeModal, productInfo }) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    products: {
      publishProduct: { content, confirmationContent }
    },
    shared
  } = messages[lang];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmPublish = () => {
    dispatch(
      publishProductRequest({
        data: {
          id: productInfo.id
        },
        status: productInfo.status,
        src: productInfo.src,
        params: productInfo.params,
        navigate
      })
    );
    closeModal();
  };

  const publishProductContent = () => {
    return (
      <div className="publish-product-container container d-flex flex-column justify-content-center align-items-center">
        <PublishModalIcon />
        <div className="publish-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${productInfo.name}"`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmPublish();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={publishProductContent()}
      hasActions={true}
      confirmBtnTxt={shared.publish}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default PublishProduct;

PublishProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired,
  updateList: PropTypes.func,
  detailsFlag: PropTypes.bool
};
