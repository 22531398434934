import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

import aboutUsCoverImg from "../../../assets/images/aboutUsCoverImg.svg";
import aboutLogo from "../../../assets/images/largeLogo.svg";
import messages from "../../../assets/locale/messages";
import "./AboutUs.scss";

const AboutUs = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    aboutUs: {
      coverTitle,
      coverSubtitle,
      logoAboutContainer,
      servicesContainer,
      servicesContainer: { servicesTitles, servicesDescription },
      questionsContainer,
      questionsContainer: { questionsTitles, questionsDescription }
    }
  } = messages[lang];

  const [expandedArray, setExpandedArray] = useState([]);

  const services = [
    {
      title: servicesTitles.virtualExhibitionBooths,
      description: servicesDescription.virtualExhibitionBooths,
      icon: ""
    },
    {
      title: servicesTitles.productListingAndManagement,
      description: servicesDescription.productListingAndManagement,
      icon: ""
    },
    {
      title: servicesTitles.advancedSearchAndFiltering,
      description: servicesDescription.advancedSearchAndFiltering,
      icon: ""
    },
    {
      title: servicesTitles.tradeLeads,
      description: servicesDescription.tradeLeads,
      icon: ""
    },
    {
      title: servicesTitles.multiCurrencySupport,
      description: servicesDescription.multiCurrencySupport,
      icon: ""
    }
  ];
  const questions = [
    {
      title: questionsTitles.register,
      description: questionsDescription.register
    },
    {
      title: questionsTitles.showcases,
      description: questionsDescription.showcases
    },
    {
      title: questionsTitles.contactSellerBuyer,
      description: questionsDescription.contactSellerBuyer
    },
    {
      title: questionsTitles.upgradePlan,
      description: questionsDescription.upgradePlan
    }
  ];

  const renderService = (title, description, icon, id) => (
    <div
      className="col-lg-4 col-md-6 col-12 p-md-2 py-2 border-radius-10"
      key={`service-${id}`}>
      <div className="text-break single-service-container p-5 px-4 border-radius-10 h-100 bg-white">
        <div className="light-primary-bg border-radius-8 service-icon-container">
          {icon}
        </div>
        <div className="mt-3">
          <p className="title-color mb-2 fsize-18">{title}</p>
          <p className="text-light-grey fsize-15 fweight-500 mb-0">
            {description}
          </p>
        </div>
      </div>
    </div>
  );

  const handleExpand = (id) => {
    if (expandedArray.includes(id)) {
      setExpandedArray((prev) => prev.filter((item) => item !== id));
    } else {
      setExpandedArray((prev) => [...prev, id]);
    }
  };

  const renderQuestion = (title, description, id) => (
    <div key={`question-${id}`}>
      <Accordion
        className={`${id !== questions.length - 1 ? "light-grey-border " : ""}  ${
          id !== 0 ? "my-1" : ""
        } border-radius-0`}
        sx={{
          borderBottomLeftRadius: "0 !important",
          borderBottomRightRadius: "0 !important"
        }}>
        <AccordionSummary
          onClick={() => handleExpand(id)}
          expandIcon={
            expandedArray.includes(id) ? <Remove /> : <Add />
          }
          aria-controls={`panel-${title}-content`}
          id={`panel-${title}-header`}
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper": {
              "&.Mui-expanded": {
                transform: "rotate(0deg)"
              }
            },
            minHeight: "auto !important",
            "& .MuiAccordionSummary-content": {
              margin: "15px 0 !important"
            },
            paddingLeft: 0,
            paddingRIght: 0
          }}>
          <Typography className="title-color fweight-600 mb-0 fsize-18">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingRight: "40px !important",
            paddingLeft: 0
          }}>
          {
            <span className="text-light-grey fsize-14 fweight-400">
              {description}
            </span>
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <div className="about-us-container w-100">
      <div
        className={`about-us-cover-image d-flex flex align-items-end  w-100 size-cover mb-4`}
        style={{
          backgroundImage: `url(${aboutUsCoverImg})`
        }}>
        <Box className="text-start d-flex flex-column justify-content-end pb-5 category-info w-100 ">
          <div className="container-xxl px-md-4 px-3">
            <Typography
              className="text-white fweight-700 mb-3"
              sx={{
                fontSize: {
                  sx: "1.3rem",
                  sm: "2.3rem"
                }
              }}>
              {coverTitle}
            </Typography>
            <p className="mb-0 text-white fweight-500 col-lg-6 col-md-8 col-12">
              {coverSubtitle}
            </p>
          </div>
        </Box>
      </div>
      <div className="container-xxl logo-about-container d-md-flex justify-content-center py-5 my-4 px-lg-4 px-md-3 px-3">
        <div className="logo-container col-xl-6 col-lg-6 col-md-6 col-12 mb-lg-0 mb-5 d-md-block d-flex justify-content-center ps-lg-2">
          <img className="about-logo" src={aboutLogo} alt="logo" />
        </div>
        <div className="about-container col-xl-5 col-lg-6 col-md-6 col-12">
          <Typography className="fweight-700 fsize-28 mb-3 title-color">
            {logoAboutContainer.title}
          </Typography>
          <p className="mb-0 light-text-color fweight-400 fsize-16 mb-4">
            {logoAboutContainer.firstSubtitle}
          </p>
          <p className="mb-0 light-text-color fweight-400 fsize-16">
            {logoAboutContainer.secondSubtitle}
          </p>
        </div>
      </div>

      <div className="services-container w-100 py-5 px-md-3 px-3">
        <div className="container-xxl w-100 d-flex flex-wrap">
          <div className="services-title-container col-lg-4 col-md-6 col-12 px-md-2 py-2">
            <Typography className="fweight-700 fsize-28 mb-4 title-color">
              {servicesContainer.title}
            </Typography>
            <p className="mb-0 light-text-color fweight-400 fsize-16 mb-4">
              {servicesContainer.subtitle}
            </p>
          </div>
          {services.map((service, index) =>
            renderService(
              service.title,
              service.description,
              service.icon,
              index
            )
          )}
        </div>
      </div>
      <div className="questions-container w-100 py-5 my-4 px-md-3 px-3">
        <div className="container-xxl d-md-flex">
          <div className="questions-title-container col-lg-6 col-md-6 col-12 px-md-2 py-2">
            <div className="mb-4">
              <Typography className="fweight-700 fsize-50 mb-0 h-100 title-color">
                {questionsContainer.title}
              </Typography>
              <Typography className="fweight-700 fsize-50 title-color">
                {questionsContainer.secondTitle}
              </Typography>
            </div>
            <p className="mb-0 light-text-color fweight-400 fsize-16 mb-4 col-md-10 col-12">
              {questionsContainer.subtitle}
            </p>
          </div>
          <div className="questions-content-container col-lg-5 col-md-6 col-12 px-md-2 py-2">
            {questions.map((question, index) =>
              renderQuestion(
                question.title,
                question.description,
                index
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
