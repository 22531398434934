import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../../../../components/Alert";
import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import { BANNER_COLORS } from "../../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const DocumentsNeedUpdate = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      verificationNotifications: { docsNeedUpdate }
    }
  } = messages[lang];

  return (
    <Alert
      type="warning"
      content={
        <div>
          <p className="mb-0 fsize-16 light-text-color">
            {docsNeedUpdate.msg1}
          </p>
          <p className="mb-0 fsize-16 light-text-color">
            <span className="text-primary text-underline pointer me-2 fweight-600">
              <Link to={ROUTE_PATHS.businessVerification}>
                {docsNeedUpdate.msg2}
              </Link>
            </span>
            {docsNeedUpdate.msg3}
          </p>
        </div>
      }
      icon={<ChipInfoIcon color={BANNER_COLORS.warning} />}
    />
  );
};

export default DocumentsNeedUpdate;
