export const CurrencyBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    fill="none"
    viewBox="0 0 69 69">
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.109"
      fill="#F1FAFF"
      rx="28"></rect>
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.109"
      stroke="#F8FDFF"
      strokeWidth="8"
      rx="28"></rect>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M30.34 37.023c0 1.612 1.237 2.912 2.775 2.912h3.137c1.338 0 2.425-1.137 2.425-2.537 0-1.525-.662-2.063-1.65-2.413l-5.037-1.75c-.988-.35-1.65-.887-1.65-2.412 0-1.4 1.087-2.538 2.425-2.538h3.137c1.538 0 2.775 1.3 2.775 2.913M34.5 26.61v15"></path>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M38.25 46.61h-7.5c-6.25 0-8.75-2.5-8.75-8.75v-7.5c0-6.25 2.5-8.75 8.75-8.75h7.5c6.25 0 8.75 2.5 8.75 8.75v7.5c0 6.25-2.5 8.75-8.75 8.75z"></path>
  </svg>
);
