import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import messages from "../../../assets/locale/messages";
import { LocationIcon } from "../../../utils/Icons";
import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import TruncateText from "../../TruncateText";

const ProductCardList = ({
  src,
  label,
  country,
  count,
  minPrice,
  maxPrice,
  link,
  unit,
  currency
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  const renderCardContent = () => (
    <div
      className={`product-card border-radius-10 p-3 grey-bg position-relative ${link && "pointer box-shadow-hover"}`}>
      <div className="info-section d-flex align-items-center">
        <div
          className="card-img-container me-3 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
          style={{
            width: "11rem",
            height: "8rem"
          }}>
          <img
            src={src || emptyProductPlaceholder}
            onError={onImageError}
            className="w-100 item-cards-img"
          />
        </div>
        <div className="info-container w-100">
          <div className="card-label mb-2">
            <TruncateText
              text={label}
              linesNo={1}
              textClassName={"mb-0 title-color"}
            />
          </div>
          {country && (
            <div className="country d-flex mb-2">
              <LocationIcon />
              <span className="text-grey fsize-12 mx-1">
                {country}
              </span>
            </div>
          )}
          <div className="moq d-flex mb-2">
            <span className="text-grey fsize-12">{shared.moq}</span>
            <span className="fsize-12 mx-1">
              {count === null ? shared.any : count} {unit || ""}
            </span>
          </div>
        </div>
      </div>
      {(minPrice || maxPrice) && (
        <div
          className="price-section d-flex align-items-end p-3"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0
          }}>
          <div className="price">
            <p className="fsize-18 fweight-600 mb-0">
              {!!minPrice && (
                <span>
                  {currency}
                  {minPrice}
                </span>
              )}
              {!!minPrice && !!maxPrice && <span>-</span>}
              {!!maxPrice && (
                <span>
                  {currency}
                  {maxPrice}
                </span>
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {link ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default ProductCardList;

ProductCardList.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  count: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  link: PropTypes.string,
  unit: PropTypes.string,
  currency: PropTypes.any
};
