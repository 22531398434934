import {
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_RESPONSE,
  PUBLISH_PRODUCT_REQUEST,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_RESPONSE,
  EDIT_PRODUCT_REQUEST,
  UNPUBLISH_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  ADD_PRODUCT_REQUEST,
  REVERT_EDIT_REQUEST,
  SHOW_ADD_SUCCESS_MODAL,
  GET_PRODUCT_VERSION_DETAILS_RESPONSE,
  GET_PRODUCT_VERSION_DETAILS_REQUEST,
  SEND_ADD_PRODUCT_SUPPORT_EMAIL_REQUEST,
  SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE,
  ADD_PRODUCT_VOICE_DESCRIPTION,
  GET_TRANSCRIBED_AUDIO_ID_REQUEST,
  GET_TRANSCRIBED_AUDIO_ID_RESPONSE,
  GET_TRANSCRIBED_AUDIO_TEXT_REQUEST,
  GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE,
  AUDIO_TRANSCRIBED
} from "./types";

export const getProductsListRequest = (payload) => ({
  type: GET_PRODUCTS_LIST_REQUEST,
  payload
});

export const getProductsListResponse = (payload) => ({
  type: GET_PRODUCTS_LIST_RESPONSE,
  payload
});

export const addProductRequest = (payload) => ({
  type: ADD_PRODUCT_REQUEST,
  payload
});

export const getProductDetailsRequest = (payload) => ({
  type: GET_PRODUCT_DETAILS_REQUEST,
  payload
});

export const getProductDetailsResponse = (payload) => ({
  type: GET_PRODUCT_DETAILS_RESPONSE,
  payload
});

export const getProductVersionDetailsRequest = (payload) => ({
  type: GET_PRODUCT_VERSION_DETAILS_REQUEST,
  payload
});

export const getProductVersionDetailsResponse = (payload) => ({
  type: GET_PRODUCT_VERSION_DETAILS_RESPONSE,
  payload
});

export const editProductRequest = (payload) => ({
  type: EDIT_PRODUCT_REQUEST,
  payload
});

export const publishProductRequest = (payload) => ({
  type: PUBLISH_PRODUCT_REQUEST,
  payload
});

export const unPublishProductRequest = (payload) => ({
  type: UNPUBLISH_PRODUCT_REQUEST,
  payload
});

export const revertEditRequest = (payload) => ({
  type: REVERT_EDIT_REQUEST,
  payload
});

export const deleteProductRequest = (payload) => ({
  type: DELETE_PRODUCT_REQUEST,
  payload
});

export const showAddSuccessModal = (payload) => ({
  type: SHOW_ADD_SUCCESS_MODAL,
  payload
});

export const sendAddProductSupportEmailRequest = () => ({
  type: SEND_ADD_PRODUCT_SUPPORT_EMAIL_REQUEST
});

export const sendAddProductSupportEmailResponse = (payload) => ({
  type: SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE,
  payload
});

export const addProductVoiceDescriptionRequest = (payload) => ({
  type: ADD_PRODUCT_VOICE_DESCRIPTION,
  payload
});

export const getTranscribedAudioIdRequest = (payload) => ({
  type: GET_TRANSCRIBED_AUDIO_ID_REQUEST,
  payload
});

export const getTranscribedAudioIdResponse = (payload) => ({
  type: GET_TRANSCRIBED_AUDIO_ID_RESPONSE,
  payload
});
export const getTranscribedAudioTextRequest = (payload) => ({
  type: GET_TRANSCRIBED_AUDIO_TEXT_REQUEST,
  payload
});

export const getTranscribedAudioTextResponse = (payload) => ({
  type: GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE,
  payload
});
export const isAudioTranscribedRequest = (payload) => ({
  type: AUDIO_TRANSCRIBED,
  payload
});
