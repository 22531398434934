import { axiosInstanceExporters, axiosInstanceImporters } from ".";

const getExporterDetails = async ({ params }) =>
  await axiosInstanceExporters.get(`/api/Company/GetById`, {
    params
  });

const getExporterContactInfo = async ({ id }) =>
  await axiosInstanceExporters.get(
    `/api/Company/GetCompanyContactInfo/${id}`
  );

const getExporterList = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/User/GetExporters`, {
    params,
    paramsSerializer: { indexes: null }
  });

export {
  getExporterDetails,
  getExporterContactInfo,
  getExporterList
};
