import { SET_CURRENT_LANG } from "./types";

const INITIAL_STATE = {
  lang: localStorage.getItem("lang") || "en"
};

const locale = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_LANG:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
};

export default locale;
