import PropTypes from "prop-types";

export const GreyEnvelopeIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "17"}
    height={dimensions || "17"}
    fill="none"
    viewBox="0 0 17 17"
    {...props}>
    <path
      fill="#455A64"
      d="M11.75 14.945H5.083c-2.433 0-3.833-1.4-3.833-3.834V6.445c0-2.434 1.4-3.834 3.833-3.834h6.667c2.433 0 3.833 1.4 3.833 3.834v4.666c0 2.434-1.4 3.834-3.833 3.834zM5.083 3.61c-1.906 0-2.833.927-2.833 2.834v4.666c0 1.907.927 2.834 2.833 2.834h6.667c1.907 0 2.833-.927 2.833-2.834V6.445c0-1.907-.926-2.834-2.833-2.834H5.083z"></path>
    <path
      fill="#455A64"
      d="M8.415 9.358c-.56 0-1.126-.173-1.56-.526L4.77 7.165a.499.499 0 01.62-.78l2.086 1.667c.507.406 1.367.406 1.874 0l2.086-1.667a.492.492 0 01.7.08.492.492 0 01-.08.7L9.97 8.832c-.427.353-.994.526-1.554.526z"></path>
  </svg>
);

GreyEnvelopeIcon.propTypes = {
  dimensions: PropTypes.string
};
