import {
  GET_EXPORTER_CONTACT_INFO_REQUEST,
  GET_EXPORTER_CONTACT_INFO_RESPONSE,
  GET_EXPORTER_DETAILS_REQUEST,
  GET_EXPORTER_DETAILS_RESPONSE,
  GET_EXPORTERS_LIST_REQUEST,
  GET_EXPORTERS_LIST_RESPONSE
} from "./types";

export const getExporterDetailsRequest = (payload) => ({
  type: GET_EXPORTER_DETAILS_REQUEST,
  payload
});

export const getExporterDetailsResponse = (payload) => ({
  type: GET_EXPORTER_DETAILS_RESPONSE,
  payload
});

export const getExporterContactInfoRequest = (payload) => ({
  type: GET_EXPORTER_CONTACT_INFO_REQUEST,
  payload
});

export const getExporterContactInfoResponse = (payload) => ({
  type: GET_EXPORTER_CONTACT_INFO_RESPONSE,
  payload
});

export const getExportersListRequest = (payload) => ({
  type: GET_EXPORTERS_LIST_REQUEST,
  payload
});

export const getExportersListResponse = (payload) => ({
  type: GET_EXPORTERS_LIST_RESPONSE,
  payload
});
