import {
  SET_BASIC_LAYOUT_CONTAINER_REF,
  SET_EXPORTER_LAYOUT_CONTAINER_REF,
  SET_IMPORTER_LAYOUT_CONTAINER_REF,
  SET_PAGE_TITLE
} from "./types";

export const setPageTitle = (payload) => ({
  type: SET_PAGE_TITLE,
  payload
});

export const setExporterLayoutContainerRef = (payload) => ({
  type: SET_EXPORTER_LAYOUT_CONTAINER_REF,
  payload
});

export const setImporterLayoutContainerRef = (payload) => ({
  type: SET_IMPORTER_LAYOUT_CONTAINER_REF,
  payload
});

export const setBasicLayoutContainerRef = (payload) => ({
  type: SET_BASIC_LAYOUT_CONTAINER_REF,
  payload
});
