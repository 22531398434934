import PropTypes from "prop-types";

export const GlobalSmallIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "17"}
    height={dimensions || "17"}
    fill="none"
    viewBox="0 0 25 25">
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.277 22.273c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10-5.522 0-10 4.478-10 10 0 5.523 4.478 10 10 10z"></path>
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.277 3.273h1a28.423 28.423 0 000 18h-1M15.277 3.273a28.423 28.423 0 010 18"></path>
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3.277 16.273v-1a28.423 28.423 0 0018 0v1M3.277 9.273a28.423 28.423 0 0118 0"></path>
  </svg>
);

GlobalSmallIcon.propTypes = {
  dimensions: PropTypes.string
};
