import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiberManualRecord, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";

import messages from "../../../../../../assets/locale/messages";
import { OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES } from "../../../../../../utils/Constants";
import ViewUploadedFile from "../../../../../../components/Upload/FileUpload/ViewUploadedFile";

const RequiredDocuments = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { offPlatform }
  } = messages[lang];

  const requiredDocuments = useSelector(
    (state) => state.verification.offplatformRequiredDocs
  );

  const [textDocuments, setTextDocuments] = useState([]);
  const [fileDocuments, setFileDocuments] = useState([]);

  useEffect(() => {
    if (
      requiredDocuments &&
      requiredDocuments?.RequiredDocument?.length
    ) {
      setTextDocuments(
        requiredDocuments?.RequiredDocument.filter(
          (doc) =>
            doc.DocumentTypeId ===
            OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.text
        )
      );
      setFileDocuments(
        requiredDocuments?.RequiredDocument.filter(
          (doc) =>
            doc.DocumentTypeId !==
            OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.text
        )
      );
    }
  }, [requiredDocuments]);

  return (
    <div
      className={`required-documents-container light-primary-bg border-radius-10 p-4 ${!fileDocuments?.length && "pb-2"}`}>
      <Accordion defaultExpanded className="light-primary-bg">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel-required-docs-content`}
          id={`panel-required-docs-header`}
          sx={{
            minHeight: "auto !important",
            "& .MuiAccordionSummary-content": {
              margin: "0 !important"
            }
          }}>
          <h6 className="fsize-16 fweight-600 title-color">
            {offPlatform.requiredDocuments.requiredDocuments}
          </h6>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: 0 }}>
          <div className="text-documents">
            <div
              className={`row m-0 ${!!textDocuments?.length && !!fileDocuments?.length && "light-border-bottom"}`}>
              <div className="col-12 col-lg-9 p-0 ">
                <div className="row">
                  {textDocuments.map(({ Id, DocumentName }) => (
                    <div
                      key={`text-document-${Id}`}
                      className="col-12 col-md-6 mb-3">
                      <p className="mb-0 d-flex justify-content-start align-items-start w-90">
                        <FiberManualRecord
                          sx={{
                            fontSize: "0.4rem",
                            marginTop: "0.5rem"
                          }}
                        />
                        <span className="ms-2 title-color">
                          {DocumentName}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {!!fileDocuments?.length && (
            <div
              className={`file-document ${!!textDocuments?.length && "mt-3"} `}>
              <p className="light-text-color fsize-13">
                {offPlatform.requiredDocuments.attachedFiles}
              </p>
              <div className="row">
                {fileDocuments.map(
                  ({
                    Id,
                    DefaultDocumentName,
                    OriginalDocumentName,
                    DocumentTypeId
                  }) => (
                    <div
                      className="col-12 col-xl-6"
                      key={`document-${Id}`}>
                      <ViewUploadedFile
                        file={DefaultDocumentName}
                        name={OriginalDocumentName}
                        type={
                          DocumentTypeId ===
                          OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.pdf
                            ? "pdf"
                            : "image"
                        }
                        viewMode
                        isFileLink
                        lightTheme
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RequiredDocuments;
