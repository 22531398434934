import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Chip } from "@mui/material";

import noProductsImage from "../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import messages from "../../../assets/locale/messages";

import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DisplayStyleButton from "../../../components/DisplayStyleButton";
import EmptyState from "../../../components/EmptyState";
import ExporterCardGrid from "../../../components/ExporterCards/ExporterCardGrid";
import ExporterCardList from "../../../components/ExporterCards/ExporterCardList";
import Filters from "../../../components/Filters";
import ListFilter from "../../../components/Filters/ListFilter";
import NumberRangeFilter from "../../../components/Filters/NumberRangeFilter";
import ProductCardGrid from "../../../components/ProductCards/ProductCardGrid";
import ProductCardList from "../../../components/ProductCards/ProductCardList";
import SubCategoryCardGrid from "../../../components/SubCategoryCards/SubCategoryCardGrid";
import SubCategoryCardList from "../../../components/SubCategoryCards/SubCategoryCardList";
import {
  getCountriesListRequest,
  getSubCategoriesListRequest
} from "../../../store/Lookups/actions";
import {
  getAllSearchResultsRequest,
  getAllSearchResultsResponse,
  getSearchExportersRequest,
  getSearchExportersResponse,
  getSearchProductsRequest,
  getSearchProductsResponse,
  getSearchSubCategoriesRequest,
  getSearchSubCategoriesResponse,
  getSearchSuggestionsResponse,
  setTotalSearchCount
} from "../../../store/Search/actions";
import {
  DISPLAY_STYLE,
  generalImporterPagesPadding,
  SEARCH_RESULTS_TYPES_IDS,
  SORT_PRODUCTS
} from "../../../utils/Constants";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./SearchPage.scss";

const SearchPage = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { search, shared } = messages[lang];
  const {
    searchResults: {
      ProductDetails,
      SubCategoryDetails,
      ExporterDetails
    }
  } = useSelector((state) => state.search);
  const { list: productsList, meta: productsMeta } = useSelector(
    (state) => state.search.products
  );
  const { list: subCategoriesList, meta: subCategoriesMeta } =
    useSelector((state) => state.search.subCategories);
  const { list: exportersList, meta: exportersMeta } = useSelector(
    (state) => state.search.exporters
  );
  const searchResultsCount = useSelector(
    (state) => state.search.totalSearchCount
  );
  const {
    subCategoriesList: subCategoriesOptions,
    countriesList: countriesOptions
  } = useSelector((state) => state.lookups);
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchItemId, setSearchItemId] = useState(null);
  const [searchTypeId, setSearchTypeId] = useState(
    SEARCH_RESULTS_TYPES_IDS.all
  );
  const [displayStyle, setDisplayStyle] = useState(
    DISPLAY_STYLE.grid
  );
  const [clearFilters, setClearFilters] = useState(false);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [countryFilterValue, setCountryFilterValue] = useState([]);
  const [subCategoryFilterValue, setSubCategoryFilterValue] =
    useState([]);
  const [priceFilterValue, setPriceFilterValue] = useState({
    from: null,
    to: null
  });
  const [priceFilterError, setPriceFilterError] = useState();
  const [moqFilterValue, setMoqFilterValue] = useState({
    from: null,
    to: null
  });
  const [moqFilterError, setMoqFilterError] = useState();

  useEffect(() => {
    dispatch(getCountriesListRequest());
    dispatch(getSubCategoriesListRequest());
    return () => {
      dispatch(setTotalSearchCount(0));
      dispatch(getSearchSuggestionsResponse([]));
      dispatch(
        getAllSearchResultsResponse({
          ProductDetails: [],
          SubCategoryDetails: [],
          ExporterDetails: []
        })
      );
      dispatch(
        getSearchProductsResponse({
          list: [],
          meta: {}
        })
      );
      dispatch(
        getSearchSubCategoriesResponse({
          list: [],
          meta: {}
        })
      );
      dispatch(
        getSearchExportersResponse({
          list: [],
          meta: {}
        })
      );
    };
  }, []);

  useEffect(() => {
    if (searchParams) {
      setSearchQuery(searchParams.get("searchQuery"));
      setSearchItemId(searchParams.get("itemId"));
      setSearchTypeId(
        searchParams.get("typeId")
          ? +searchParams.get("typeId")
          : SEARCH_RESULTS_TYPES_IDS.all
      );
    }
  }, [searchParams]);

  const searchInAllResults = (isClear) => {
    if (searchQuery) {
      dispatch(
        getAllSearchResultsRequest({
          params: {
            keyword: searchQuery || "",
            currencyId: websiteCurrency?.Id,
            country_ids: isClear ? null : countryFilterValue,
            subCategory_ids: isClear ? null : subCategoryFilterValue,
            MOQ_from: isClear ? null : moqFilterValue.from || null,
            MOQ_to: isClear ? null : moqFilterValue.to || null,
            price_range_from: isClear
              ? null
              : priceFilterValue.from || null,
            price_range_to: isClear
              ? null
              : priceFilterValue.to || null
          }
        })
      );
    }
  };

  const searchInProducts = (isClear) => {
    dispatch(
      getSearchProductsRequest({
        params: {
          page,
          keyword: searchQuery,
          currencyId: websiteCurrency?.Id,
          id: searchItemId || undefined,
          country_ids: isClear ? null : countryFilterValue,
          subCategory_ids: isClear ? null : subCategoryFilterValue,
          MOQ_from: isClear ? null : moqFilterValue.from || null,
          MOQ_to: isClear ? null : moqFilterValue.to || null,
          price_range_from: isClear
            ? null
            : priceFilterValue.from || null,
          price_range_to: isClear ? null : priceFilterValue.to || null
        }
      })
    );
  };

  const searchInSubCategories = () => {
    dispatch(
      getSearchSubCategoriesRequest({
        params: {
          page,
          keyword: searchQuery,
          id:
            searchTypeId === SEARCH_RESULTS_TYPES_IDS.subCategory &&
            searchItemId
              ? searchItemId
              : undefined,
          category_id:
            searchTypeId === SEARCH_RESULTS_TYPES_IDS.category &&
            searchItemId
              ? searchItemId
              : undefined
        }
      })
    );
  };

  const searchInExporters = (isClear) => {
    dispatch(
      getSearchExportersRequest({
        params: {
          page,
          keyword: searchQuery,
          id:
            searchTypeId === SEARCH_RESULTS_TYPES_IDS.exporter &&
            searchItemId
              ? searchItemId
              : undefined,
          company_id: isClear
            ? null
            : searchTypeId === SEARCH_RESULTS_TYPES_IDS.company &&
                searchItemId
              ? searchItemId
              : undefined,
          country_ids: isClear ? null : countryFilterValue
        }
      })
    );
  };

  const handleSearchMechanism = (isClear) => {
    if (searchTypeId === SEARCH_RESULTS_TYPES_IDS.product) {
      searchInProducts(isClear);
    } else if (
      [
        SEARCH_RESULTS_TYPES_IDS.subCategory,
        SEARCH_RESULTS_TYPES_IDS.category
      ].includes(searchTypeId)
    ) {
      searchInSubCategories();
    } else if (
      [
        SEARCH_RESULTS_TYPES_IDS.company,
        SEARCH_RESULTS_TYPES_IDS.exporter
      ].includes(searchTypeId)
    ) {
      searchInExporters(isClear);
    } else {
      searchInAllResults(isClear);
    }
  };

  useEffect(() => {
    handleSearchMechanism();
  }, [
    searchQuery,
    searchTypeId,
    searchItemId,
    page,
    websiteCurrency
  ]);

  useEffect(() => {
    if (isApplyFilters) {
      page > 1 ? setPage(1) : handleSearchMechanism();
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    if (clearFilters) {
      setCountryFilterValue([]);
      setSubCategoryFilterValue([]);
      setPriceFilterValue({
        from: null,
        to: null
      });
      setMoqFilterValue({
        from: null,
        to: null
      });
      setPriceFilterError();
      setMoqFilterError();
      page > 1 ? setPage(1) : handleSearchMechanism(true);
      setTimeout(() => {
        setClearFilters(false);
      }, [500]);
    }
  }, [clearFilters]);

  const breadcrumbs = [
    { label: search.breadcrumbs.home, link: ROUTE_PATHS.home },
    {
      label: search.breadcrumbs.search
    }
  ];

  const onClickSearchTag = (id) => {
    setSearchParams((searchParams) => {
      setClearFilters(true);
      searchParams.set("typeId", id);
      searchParams.delete("itemId");
      return searchParams;
    });
  };

  const renderSectionsTags = () => (
    <div className="section-tag-container">
      <Chip
        label={search.tags.all}
        className={`tag-chip pointer me-3 mb-3 ${searchTypeId === SEARCH_RESULTS_TYPES_IDS.all && "active-tag"}`}
        onClick={() => onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.all)}
      />
      <Chip
        label={search.tags.products}
        className={`tag-chip pointer me-3 mb-3 ${searchTypeId === SEARCH_RESULTS_TYPES_IDS.product && "active-tag"}`}
        onClick={() =>
          onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.product)
        }
      />
      <Chip
        label={search.tags.subCategories}
        className={`tag-chip pointer me-3 mb-3 ${[SEARCH_RESULTS_TYPES_IDS.subCategory, SEARCH_RESULTS_TYPES_IDS.category].includes(searchTypeId) && "active-tag"}`}
        onClick={() =>
          onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.subCategory)
        }
      />
      <Chip
        label={search.tags.exporters}
        className={`tag-chip pointer me-3 mb-3 ${[SEARCH_RESULTS_TYPES_IDS.company, SEARCH_RESULTS_TYPES_IDS.exporter].includes(searchTypeId) && "active-tag"}`}
        onClick={() =>
          onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.exporter)
        }
      />
    </div>
  );

  const renderProductsSection = () => {
    const productsData =
      searchTypeId === SEARCH_RESULTS_TYPES_IDS.all
        ? [...ProductDetails]
        : [...productsList];

    return (
      ((searchTypeId === SEARCH_RESULTS_TYPES_IDS.all &&
        !!ProductDetails?.length) ||
        searchTypeId === SEARCH_RESULTS_TYPES_IDS.product) && (
        <div className="products-section">
          <div className="header-section d-flex justify-content-between align-items-center mb-3">
            <h4 className="fsize-22 fweight-700 title-color mb-0">
              {search.tags.products}
            </h4>
            {searchTypeId === SEARCH_RESULTS_TYPES_IDS.all && (
              <span
                className="see-all text-underline text-primary fsize-16 fweight-600 pointer"
                onClick={() =>
                  onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.product)
                }>
                {search.seeAll}
              </span>
            )}
          </div>
          <div className="row products-container mb-3">
            {productsData?.length ? (
              productsData?.map(
                (
                  {
                    Id,
                    DefaultImage,
                    Name,
                    ExporterCountry,
                    MinOrderQuantity,
                    MoqUnitName,
                    MinPriceRange,
                    MaxPriceRange
                  },
                  i
                ) =>
                  displayStyle === DISPLAY_STYLE.grid ? (
                    <div
                      className="col-lg-3 col-sm-6 col-12 mb-4"
                      key={`grid-product-${i}`}>
                      <ProductCardGrid
                        src={DefaultImage}
                        label={Name}
                        country={ExporterCountry}
                        count={MinOrderQuantity}
                        unit={MoqUnitName}
                        minPrice={MinPriceRange}
                        maxPrice={MaxPriceRange}
                        link={ROUTE_PATHS.productDetails.replace(
                          ":id",
                          Id
                        )}
                        currency={websiteCurrency?.Symbol}
                      />
                    </div>
                  ) : (
                    <div
                      key={`list-product-${i}`}
                      className="col-12 mb-4">
                      <ProductCardList
                        src={DefaultImage}
                        label={Name}
                        country={ExporterCountry}
                        count={MinOrderQuantity}
                        minPrice={MinPriceRange}
                        maxPrice={MaxPriceRange}
                        link={ROUTE_PATHS.productDetails.replace(
                          ":id",
                          Id
                        )}
                        currency={websiteCurrency?.Symbol}
                      />
                    </div>
                  )
              )
            ) : (
              <EmptyState
                subTitle={search.noresultsFound}
                imgSrc={noProductsImage}
              />
            )}
          </div>
          {searchTypeId === SEARCH_RESULTS_TYPES_IDS.product &&
            productsMeta?.pages > 1 &&
            productsMeta?.currentPage < productsMeta?.pages && (
              <div className="actions-section w-100 text-center mb-3">
                <Button
                  label={search.loadMore}
                  outlined
                  onClick={() => setPage(page + 1)}
                />
              </div>
            )}
        </div>
      )
    );
  };

  const renderSubCategoriesSection = () => {
    const subCategoriesData =
      searchTypeId === SEARCH_RESULTS_TYPES_IDS.all
        ? [...SubCategoryDetails]
        : [...subCategoriesList];
    return (
      ((searchTypeId === SEARCH_RESULTS_TYPES_IDS.all &&
        !!SubCategoryDetails?.length) ||
        [
          SEARCH_RESULTS_TYPES_IDS.subCategory,
          SEARCH_RESULTS_TYPES_IDS.category
        ].includes(searchTypeId)) && (
        <div className="sub-categories-section">
          <div className="header-section d-flex justify-content-between align-items-center mb-3">
            <h4 className="fsize-22 fweight-700 title-color mb-0">
              {search.tags.subCategories}
            </h4>
            {searchTypeId === SEARCH_RESULTS_TYPES_IDS.all && (
              <span
                className="see-all text-underline text-primary fsize-16 fweight-600 pointer"
                onClick={() =>
                  onClickSearchTag(
                    SEARCH_RESULTS_TYPES_IDS.subCategory
                  )
                }>
                {search.seeAll}
              </span>
            )}
          </div>
          <div className="row products-container mb-3">
            {subCategoriesData?.length ? (
              subCategoriesData?.map(
                ({ Id, DefaultImage, Name }, i) =>
                  displayStyle === DISPLAY_STYLE.grid ? (
                    <div
                      className={`col-lg-3 col-sm-6 col-12 mb-4`}
                      key={`grid-sub-category-${i}`}>
                      <SubCategoryCardGrid
                        src={DefaultImage}
                        label={Name}
                        link={ROUTE_PATHS.subCategoryDetails.replace(
                          ":id",
                          Id
                        )}
                      />
                    </div>
                  ) : (
                    <div
                      key={`list-sub-category-${i}`}
                      className="col-12 mb-4">
                      <SubCategoryCardList
                        src={DefaultImage}
                        label={Name}
                        link={ROUTE_PATHS.subCategoryDetails.replace(
                          ":id",
                          Id
                        )}
                      />
                    </div>
                  )
              )
            ) : (
              <EmptyState
                subTitle={search.noresultsFound}
                imgSrc={noProductsImage}
              />
            )}
          </div>
          {[
            SEARCH_RESULTS_TYPES_IDS.subCategory,
            SEARCH_RESULTS_TYPES_IDS.category
          ].includes(searchTypeId) &&
            subCategoriesMeta?.pages > 1 &&
            subCategoriesMeta?.currentPage <
              subCategoriesMeta?.pages && (
              <div className="actions-section w-100 text-center mb-3">
                <Button
                  label={search.loadMore}
                  outlined
                  onClick={() => setPage(page + 1)}
                />
              </div>
            )}
        </div>
      )
    );
  };

  const renderExportersSection = () => {
    const exportersData =
      searchTypeId === SEARCH_RESULTS_TYPES_IDS.all
        ? [...ExporterDetails]
        : [...exportersList];
    return (
      ((searchTypeId === SEARCH_RESULTS_TYPES_IDS.all &&
        !!ExporterDetails?.length) ||
        [
          SEARCH_RESULTS_TYPES_IDS.company,
          SEARCH_RESULTS_TYPES_IDS.exporter
        ].includes(searchTypeId)) && (
        <div className="exporters-section">
          <div className="header-section d-flex justify-content-between align-items-center mb-3">
            <h4 className="fsize-22 fweight-700 title-color mb-0">
              {search.tags.exporters}
            </h4>
            {searchTypeId === SEARCH_RESULTS_TYPES_IDS.all && (
              <span
                className="see-all text-underline text-primary fsize-16 fweight-600 pointer"
                onClick={() =>
                  onClickSearchTag(SEARCH_RESULTS_TYPES_IDS.exporter)
                }>
                {search.seeAll}
              </span>
            )}
          </div>
          <div className="row products-container mb-3">
            {exportersData?.length ? (
              exportersData?.map(
                (
                  {
                    CompanyId,
                    LogoImage,
                    TradeName,
                    CountryName,
                    Introduction,
                    IsVerified
                  },
                  i
                ) =>
                  displayStyle === DISPLAY_STYLE.grid ? (
                    <div
                      className="col-lg-3 col-sm-6 col-12 mb-4"
                      key={`grid-exporter-${i}`}>
                      <ExporterCardGrid
                        src={LogoImage}
                        label={TradeName}
                        country={CountryName}
                        description={Introduction}
                        link={ROUTE_PATHS.exporterProfile.replace(
                          ":id",
                          CompanyId
                        )}
                        isVerified={IsVerified}
                      />
                    </div>
                  ) : (
                    <div
                      key={`list-exporter-${i}`}
                      className="col-12 mb-4">
                      <ExporterCardList
                        src={LogoImage}
                        label={TradeName}
                        country={CountryName}
                        description={Introduction}
                        link={ROUTE_PATHS.exporterProfile.replace(
                          ":id",
                          CompanyId
                        )}
                        isVerified={IsVerified}
                      />
                    </div>
                  )
              )
            ) : (
              <EmptyState
                subTitle={search.noresultsFound}
                imgSrc={noProductsImage}
              />
            )}
          </div>
          {[
            SEARCH_RESULTS_TYPES_IDS.company,
            SEARCH_RESULTS_TYPES_IDS.exporter
          ].includes(searchTypeId) &&
            exportersMeta?.pages > 1 &&
            exportersMeta?.currentPage < exportersMeta?.pages && (
              <div className="actions-section w-100 text-center mb-3">
                <Button
                  label={search.loadMore}
                  outlined
                  onClick={() => setPage(page + 1)}
                />
              </div>
            )}
        </div>
      )
    );
  };

  const renderFilterContent = (isDesktop) => (
    <div
      className={`filters-section px-4 px-md-0 ${isDesktop && "scroll-within"}`}
      style={
        isDesktop &&
        [
          SEARCH_RESULTS_TYPES_IDS.all,
          SEARCH_RESULTS_TYPES_IDS.product
        ].includes(searchTypeId)
          ? {
              overflowX: "hidden"
            }
          : {}
      }>
      <div
        className={`${
          [
            SEARCH_RESULTS_TYPES_IDS.all,
            SEARCH_RESULTS_TYPES_IDS.product
          ].includes(searchTypeId) && "border-bottom pb-4"
        } px-2`}>
        {[
          SEARCH_RESULTS_TYPES_IDS.all,
          SEARCH_RESULTS_TYPES_IDS.product,
          SEARCH_RESULTS_TYPES_IDS.company,
          SEARCH_RESULTS_TYPES_IDS.exporter
        ].includes(searchTypeId) && (
          <ListFilter
            list={countriesOptions}
            label={search.filters.country}
            name="country"
            searchPlaceholder={search.filters.searchCountry}
            selectedOptionsIds={countryFilterValue}
            setSelectedOptionsIds={setCountryFilterValue}
            collabsible
            showSearchBar
            clearFilters={clearFilters}
            isApplyFilters={isApplyFilters}
          />
        )}
      </div>
      {[
        SEARCH_RESULTS_TYPES_IDS.all,
        SEARCH_RESULTS_TYPES_IDS.product
      ].includes(searchTypeId) && (
        <div className="border-bottom pt-3 pb-4 px-2">
          <ListFilter
            list={subCategoriesOptions}
            label={search.filters.subCategory}
            name="sub-category"
            searchPlaceholder={search.filters.searchSubCategory}
            selectedOptionsIds={subCategoryFilterValue}
            setSelectedOptionsIds={setSubCategoryFilterValue}
            collabsible
            showSearchBar
            clearFilters={clearFilters}
            isApplyFilters={isApplyFilters}
          />
        </div>
      )}
      {[
        SEARCH_RESULTS_TYPES_IDS.all,
        SEARCH_RESULTS_TYPES_IDS.product
      ].includes(searchTypeId) && (
        <div className="border-bottom pt-3 pb-4 px-2">
          <NumberRangeFilter
            name="price"
            label={search.filters.priceRange}
            endAdorment={websiteCurrency?.Symbol}
            collabsible
            clearFilters={clearFilters}
            value={priceFilterValue}
            setValue={setPriceFilterValue}
            hasError={priceFilterError}
            setHasError={setPriceFilterError}
            filterClass={`search-page-range-filters ${isDesktop ? "desktop-version" : "mobile-version"}`}
          />
        </div>
      )}
      {[
        SEARCH_RESULTS_TYPES_IDS.all,
        SEARCH_RESULTS_TYPES_IDS.product
      ].includes(searchTypeId) && (
        <div className="pt-3 px-2">
          <NumberRangeFilter
            name="moq"
            label={search.filters.moq}
            collabsible
            clearFilters={clearFilters}
            value={moqFilterValue}
            setValue={setMoqFilterValue}
            hasError={moqFilterError}
            setHasError={setMoqFilterError}
            filterClass={`search-page-range-filters ${isDesktop ? "desktop-version" : "mobile-version"}`}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`search-page-container container-xxl ${generalImporterPagesPadding}`}>
      <div className="row align-items-start">
        {[
          SEARCH_RESULTS_TYPES_IDS.all,
          SEARCH_RESULTS_TYPES_IDS.product,
          SEARCH_RESULTS_TYPES_IDS.company,
          SEARCH_RESULTS_TYPES_IDS.exporter
        ].includes(searchTypeId) && (
          <div
            className="col-lg-3 col-md-4 border-radius-10 filters-col d-none d-md-block"
            style={{
              position: "sticky",
              top: 0
            }}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="title-color fsize-22 font-semibold mb-0">
                {shared.filters}
              </p>
              <Button
                label={shared.clear}
                variant={"text"}
                onClick={() => {
                  setClearFilters(true);
                  setTimeout(() => {
                    setClearFilters(false);
                  }, [500]);
                }}
                className="fsize-14 btn no-shadow"
              />
            </div>
            {renderFilterContent(true)}
            <div className="py-3 d-flex justify-content-between">
              <Button
                label={shared.apply}
                onClick={() => {
                  setIsApplyFilters(true);
                }}
                className="my-3 w-100"
                disabled={priceFilterError || moqFilterError}
              />
            </div>
          </div>
        )}
        <div
          className={`${
            [
              SEARCH_RESULTS_TYPES_IDS.all,
              SEARCH_RESULTS_TYPES_IDS.product,
              SEARCH_RESULTS_TYPES_IDS.company,
              SEARCH_RESULTS_TYPES_IDS.exporter
            ].includes(searchTypeId) &&
            "col-lg-9 col-md-8 col-12 content-col"
          }`}>
          <div className="ps-3">
            <div className="search-results-section">
              <div className="fixed-section">
                <Breadcrumbs list={breadcrumbs} />
                <div className="header-section d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">
                    <span className="text-color fweight-300 fsize-20">{`${searchResultsCount || 0} ${search.resultsFor} `}</span>
                    <span className="text-color fweight-600 fsize-20">{`"${searchQuery}"`}</span>
                  </h3>
                  {[
                    SEARCH_RESULTS_TYPES_IDS.all,
                    SEARCH_RESULTS_TYPES_IDS.product,
                    SEARCH_RESULTS_TYPES_IDS.company,
                    SEARCH_RESULTS_TYPES_IDS.exporter
                  ].includes(searchTypeId) && (
                    <div className="filters-button d-block d-md-none">
                      <Filters
                        filterButtonClassName={
                          "sub-categories-filter-button "
                        }
                        handleClearFilters={setClearFilters}
                        handleApplyFilters={setIsApplyFilters}
                        FilterContent={renderFilterContent()}
                        disableApplyBtn={
                          priceFilterError || moqFilterError
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="actions-section d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                  {renderSectionsTags()}
                  <DisplayStyleButton
                    displayStyle={displayStyle}
                    onDisplayChange={(newDisplay) =>
                      setDisplayStyle(newDisplay)
                    }
                    className={"mb-3"}
                  />
                </div>
              </div>
              <div className="search-results-container">
                {[
                  SEARCH_RESULTS_TYPES_IDS.all,
                  SEARCH_RESULTS_TYPES_IDS.product
                ].includes(searchTypeId) && renderProductsSection()}
                {[
                  SEARCH_RESULTS_TYPES_IDS.all,
                  SEARCH_RESULTS_TYPES_IDS.subCategory,
                  SEARCH_RESULTS_TYPES_IDS.category
                ].includes(searchTypeId) &&
                  renderSubCategoriesSection()}
                {[
                  SEARCH_RESULTS_TYPES_IDS.all,
                  SEARCH_RESULTS_TYPES_IDS.company,
                  SEARCH_RESULTS_TYPES_IDS.exporter
                ].includes(searchTypeId) && renderExportersSection()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
