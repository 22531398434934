import { useEffect } from "react";
import PropTypes from "prop-types";

const ScrollToFieldError = ({
  formik,
  submitButtonClicked,
  handleSubmitButtonClicked
}) => {
  const { submitCount, isValid, errors } = formik;

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (
      obj,
      prefix = "",
      result = []
    ) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;
        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });
      return result;
    };
    return transformObjectToDotNotation(formikErrors);
  };

  useEffect(() => {
    if (isValid || !submitButtonClicked) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (submitCount >= 1 && fieldErrorNames.length > 0) {
      const firstErrorFieldName = fieldErrorNames[0];
      const escapedSelector = `#${firstErrorFieldName.replace(/\./g, "\\.")}`;
      const element = document.querySelector(escapedSelector);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        handleSubmitButtonClicked(false);
      }
    }
  }, [submitButtonClicked, submitCount, isValid, errors]);

  return null;
};

ScrollToFieldError.propTypes = {
  formik: PropTypes.object.isRequired,
  submitButtonClicked: PropTypes.bool.isRequired,
  handleSubmitButtonClicked: PropTypes.func.isRequired
};

export default ScrollToFieldError;
