import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { COMPANY_VERIFICATION_STATUS } from "../../../../utils/Constants";
import { getCompanyVerificationStatusRequest } from "../../../../store/Verification/actions";
import VerifyNow from "./VerifyNow";
import AwaitingRequiredDocuments from "./AwaitingRequiredDocuments";
import PendingDocumentsUpload from "./PendingDocumentsUpload";
import DocumentsUnderReview from "./DocumentsUnderReview";
import PendingPayment from "./PendingPayment";
import DocumentsNeedUpdate from "./DocumentsNeedUpdate";
import AccountUnderReview from "./AccountUnderReview";

const VerificationsNotifications = () => {
  const dispatch = useDispatch();

  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );
  const isLoading = useSelector((state) => state.loader.showLoader);

  useEffect(() => {
    dispatch(getCompanyVerificationStatusRequest());
  }, []);

  return (
    <div className="verifications-notificaitons-container">
      {!isLoading && (
        <>
          {[
            COMPANY_VERIFICATION_STATUS.beforeVerify,
            COMPANY_VERIFICATION_STATUS.rejected
          ].includes(companyVerificationStatus) && <VerifyNow />}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments && (
            <AwaitingRequiredDocuments />
          )}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.pendingDocumentsUpload && (
            <PendingDocumentsUpload />
          )}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.documentsUploaded && (
            <DocumentsUnderReview />
          )}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.pendingPayment && (
            <PendingPayment />
          )}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.docsNeedUpdates && (
            <DocumentsNeedUpdate />
          )}
          {companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.pendingFinalApproval && (
            <AccountUnderReview />
          )}
        </>
      )}
    </div>
  );
};

export default VerificationsNotifications;
