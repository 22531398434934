import { axiosInstanceAdmin, axiosInstanceExporters } from ".";

const getSubscriptionPlansDetails = async () =>
  await axiosInstanceExporters.get(`/api/Subscription/Get`);

const getSubscriptionPlanDetails = async (params) =>
  await axiosInstanceExporters.get(
    `/api/Subscription/GetSubscriptionTypeById`,
    {
      params
    }
  );

const createSubscriptionPaymentIntent = async (params) =>
  await axiosInstanceExporters.get(
    `/api/Subscription/CreateSubscriptionPaymentIntent`,
    {
      params
    }
  );

const confirmSubscriptionPayment = async (params) =>
  await axiosInstanceExporters.post(
    `/api/Subscription/AddUserSubscriptionType`,
    {},
    { params }
  );

const getUserSubscriptionPlan = async () =>
  await axiosInstanceExporters.get(
    `/api/Subscription/GetUserSubscriptionType`
  );

const getPreviousSubsctriptionType = async () =>
  await axiosInstanceExporters.get(
    `/api/Subscription/GetPreviousSubscriptionType`
  );

const deleteExtraShowcases = async (params) =>
  await axiosInstanceExporters.delete(
    `/api/Subscription/DeleteExtraShowCases`,
    {
      params,
      paramsSerializer: { indexes: null }
    }
  );

const requestVoucher = async () =>
  await axiosInstanceAdmin.get(`/api/Voucher/RequestVoucher`);

const applyVoucher = async ({ params }) =>
  await axiosInstanceExporters.get(`/api/Voucher/ApplyVoucher`, {
    params
  });

const checkCanRenewSubscription = async (params) =>
  await axiosInstanceExporters.get(
    `/api/Subscription/CheckIfUserCanRenewSubscription`,
    { params }
  );

const getSubscriptionFinalFees = async (params) =>
  await axiosInstanceExporters.get(
    `/api/Subscription/CheckFinalFees`,
    { params }
  );

const createAutomaticRenewalSubscriptionPaymentIntent = async (
  data
) =>
  await axiosInstanceExporters.put(
    `/api/Subscription/CreateAutomaticSubscriptionPaymentIntent`,
    data
  );

const enableAutomaticRenewalSubscription = async () =>
  await axiosInstanceExporters.put(
    `/api/Subscription/EnableAutomaticSubscription`,
    {}
  );

const cancelAutomaticRenewalSubscription = async () =>
  await axiosInstanceExporters.put(
    `/api/Subscription/CancelAutomaticSubscription`,
    {}
  );

const updateAutomaticRenewalSubscriptionPaymentIntent = async (
  data
) =>
  await axiosInstanceExporters.put(
    `/api/Subscription/UpdateAutomaticSubscriptionType`,
    data
  );

const getUserSavedCards = async () =>
  await axiosInstanceExporters.get(`/api/Subscription/GetUserCards`);

export {
  getSubscriptionPlansDetails,
  getSubscriptionPlanDetails,
  createSubscriptionPaymentIntent,
  confirmSubscriptionPayment,
  getUserSubscriptionPlan,
  getPreviousSubsctriptionType,
  deleteExtraShowcases,
  requestVoucher,
  applyVoucher,
  checkCanRenewSubscription,
  getSubscriptionFinalFees,
  createAutomaticRenewalSubscriptionPaymentIntent,
  enableAutomaticRenewalSubscription,
  cancelAutomaticRenewalSubscription,
  updateAutomaticRenewalSubscriptionPaymentIntent,
  getUserSavedCards
};
