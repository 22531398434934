import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/auth";
import * as types from "./types";
import * as actions from "./actions";
import * as homeActions from "../Home/actions";
import * as productActions from "../Products/actions";
import {
  clearUserData,
  setUser,
  setDeviceId
} from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import { setUnseenNotificationsCountRequest } from "../Notifications/actions";
import { USER_TYPES_IDS } from "../../utils/Constants";
import { getUserPermissions } from "../../utils/Permissions";
import store from "..";

function* loginSaga({ payload }) {
  try {
    const { prevLocation, data, rememberMe, navigate } = payload;
    const response = yield call(apis.login, data);
    const { device_id } = data;
    const Permissions = getUserPermissions(response.data.Data.RoleId);
    const userData = {
      ...response.data.Data,
      Permissions,
      RoleId:
        response.data.Data?.RoleId === null
          ? 1
          : response.data.Data?.RoleId,
      IsPasswordReset:
        response.data.Data?.IsPasswordReset === true ||
        response.data.Data?.IsPasswordReset === null
    };
    yield put(actions.loginResponse(userData));
    yield rememberMe
      ? setUser(userData, "local")
      : setUser(userData, "session");
    yield put(
      setUnseenNotificationsCountRequest(
        userData.UnReadNotificationsCount
      )
    );
    const { currency } = userData;
    const websiteCurrency = {
      Id: currency?.CurrencyId,
      Name: currency?.CurrencyName,
      Code: currency?.CurrencyCode,
      Symbol: currency?.CurrencySymbol
    };
    yield put(homeActions.setWebsiteCurrency(websiteCurrency));
    if (!userData?.IsPasswordReset) {
      sessionStorage.setItem("tempPassword", payload.data.password);
    }
    yield sessionStorage.setItem(
      "website-currency",
      JSON.stringify(websiteCurrency)
    );
    yield setDeviceId(device_id);
    yield prevLocation
      ? setTimeout(() => {
          navigate(
            userData?.IsPasswordReset
              ? prevLocation
              : ROUTE_PATHS.setNewPassword
          );
        }, [])
      : navigate(
          userData?.IsPasswordReset
            ? ROUTE_PATHS.home
            : ROUTE_PATHS.setNewPassword
        );
    yield put(
      actions.changeUserCurrentRoleRequest(USER_TYPES_IDS.exporter)
    );
    yield rememberMe
      ? localStorage.setItem("userType", USER_TYPES_IDS.exporter)
      : sessionStorage.setItem("userType", USER_TYPES_IDS.exporter);
    yield showSuccessMsg("", response.data.Data.Message);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* logoutSaga({ payload }) {
  try {
    // yield call(apis.logout, payload);

    if (!!payload?.forceLogoutParams) {
      yield put(
        actions.forceLogoutRequest({
          navigate: payload.navigate,
          params: payload.forceLogoutParams
        })
      );
    } else {
      yield put(actions.loginResponse({}));
      clearUserData();
    }
    yield put(homeActions.sendSupportEmailResponse(false));
    yield put(
      productActions.sendAddProductSupportEmailResponse(false)
    );
    yield payload.navigate(ROUTE_PATHS.login);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* forgetPasswordSaga({ payload }) {
  try {
    yield call(apis.forgetPassword, payload);
    yield payload.navigate(ROUTE_PATHS.forgetPasswordConfirm, {
      state: {
        email: payload.data.email
      }
    });
    yield showSuccessMsg("emailResendSuccessfully");
  } catch (error) {
    if (error?.response?.status === 401 && payload.resendEmail) {
      yield put(actions.setDisableForgetPasswordResendEmail(true));
    }
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const { data, navigate, isFirstTimePassword } = payload;
    yield call(
      isFirstTimePassword ? apis.setNewPassword : apis.resetPassword,
      data
    );
    yield showSuccessMsg(
      isFirstTimePassword ? "resetPassword" : "changePassword"
    );
    if (isFirstTimePassword) {
      const userData = store.getState().auth.login;
      const updatedUserDataResetPassword = {
        ...userData,
        IsPasswordReset: true
      };
      const rememberMe = !!localStorage.getItem("user");
      yield put(actions.loginResponse(updatedUserDataResetPassword));
      yield rememberMe
        ? setUser(updatedUserDataResetPassword, "local")
        : setUser(updatedUserDataResetPassword, "session");
    }
    yield navigate(
      isFirstTimePassword ? ROUTE_PATHS.home : ROUTE_PATHS.login
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* registerationSaga({ payload }) {
  try {
    const response = yield call(apis.registeration, payload.data);
    yield sessionStorage.setItem(
      "resend_email",
      response.data.Data.Email
    );
    yield localStorage.setItem("is_frist_verify_trial", true);
    yield payload.navigate(ROUTE_PATHS.verifyEmail);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* checkCanUserVerifyEmailSaga({ payload }) {
  try {
    const response = yield call(
      apis.checkCanUserResendVerifyEmail,
      payload.data
    );
    yield put(
      actions.checkCanUserResendVerifyEmailResponse(
        response.data.Data
      )
    );
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 404
    ) {
      yield put(actions.setDisableResendEmail(true));
      yield payload.navigate(ROUTE_PATHS.login);
    }

    const is_first_trial = localStorage.getItem(
      "is_frist_verify_trial"
    )
      ? JSON.parse(localStorage.getItem("is_frist_verify_trial"))
      : null;
    yield is_first_trial
      ? setTimeout(() => {
          localStorage.removeItem("is_frist_verify_trial");
        }, 5000)
      : error?.response?.data?.Message &&
        showErrorMsg(error.response.data?.Message);
  }
}

function* resendVerifyEmailSaga({ payload }) {
  try {
    const response = yield call(
      apis.resendVerifyEmail,
      payload.params
    );
    yield put(
      actions.checkCanUserResendVerifyEmailResponse(
        response.data.Data
      )
    );
    if (payload.fromExpiredLink) {
      yield sessionStorage.setItem(
        "resend_email",
        payload.params.email
      );
      yield localStorage.setItem("is_frist_verify_trial", true);
      yield payload.navigate(ROUTE_PATHS.verifyEmail);
    }
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 404
    ) {
      yield put(actions.setDisableResendEmail(true));
      yield payload.navigate(ROUTE_PATHS.login);
    }

    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
    if (payload.navigateWithError) {
      yield payload.navigate(ROUTE_PATHS.verifyEmail);
    }
  }
}
function* checkEmailUniqueSaga({ payload }) {
  try {
    yield call(apis.checkEmailUnique, payload.params);
    yield put(payload.onSubmit());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* disableSubAccountsWarningBannerSaga() {
  const userData = store.getState().auth.login;
  const updatedUserDataWithReactivatedExporter = {
    ...userData,
    ReactivatedExporter: false
  };
  const rememberMe = !!localStorage.getItem("user");
  yield put(
    actions.loginResponse(updatedUserDataWithReactivatedExporter)
  );
  yield rememberMe
    ? setUser(updatedUserDataWithReactivatedExporter, "local")
    : setUser(updatedUserDataWithReactivatedExporter, "session");
}

function* isUserForcedLogoutSaga({ payload }) {
  try {
    const response = yield call(
      apis.isUserForcedLogout,
      payload.params
    );
    if (!!response.data.Data.Data) {
      yield put(
        actions.logoutRequest({
          ...payload,
          forceLogoutParams: payload.params
        })
      );
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* forceLogoutSaga({ payload }) {
  try {
    yield call(apis.modifyIsForceLogout, payload.params);
    yield put(actions.loginResponse({}));
    clearUserData();
    yield payload.navigate(ROUTE_PATHS.login);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* contactUsSaga({ payload }) {
  yield call(apis.contactUs, payload.data);
  yield showSuccessMsg("messageSentSuccessfully");
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export default function* AuthSagas() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(types.FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeEvery(types.REGSITERATION_REQUEST, registerationSaga);
  yield takeEvery(
    types.CHECK_CAN_USER_RESEND_VERIFY_EMAIL_REQUEST,
    checkCanUserVerifyEmailSaga
  );
  yield takeEvery(
    types.RESEND_VERIFY_EMAIL_REQUEST,
    resendVerifyEmailSaga
  );
  yield takeEvery(types.CHECK_EMAIL_UNIQUE, checkEmailUniqueSaga);
  yield takeEvery(
    types.DISABLE_SUB_ACCOUNTS_WARNING_BANNER,
    disableSubAccountsWarningBannerSaga
  );
  yield takeEvery(
    types.IS_USER_FORCED_LOGOUT_REQUEST,
    isUserForcedLogoutSaga
  );
  yield takeEvery(types.FORCE_LOGOUT_REQUEST, forceLogoutSaga);
  yield takeEvery(types.CONTACT_US_REQUEST, contactUsSaga);
}
