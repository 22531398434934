import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import { ViewDeleteIcon } from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import "./ViewDeleteButton.scss";

const ViewDeleteButton = ({
  containerClassName,
  className,
  onClick,
  hasLabel
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const { shared } = messages[lang];
  return (
    <div
      className={`${containerClassName} delete-btn ${hasLabel && "d-flex align-items-center justify-content-center gap-2"}`}
      onClick={onClick}>
      <ViewDeleteIcon
        className={`table-icon action-icon  ${className}`}
      />
      {hasLabel && (
        <Typography className="fweight-500 fsize-14">
          {shared.delete}
        </Typography>
      )}
    </div>
  );
};

ViewDeleteButton.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasLabel: PropTypes.bool
};

export default ViewDeleteButton;
