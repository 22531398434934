import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/notifications";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getInitialNotificationsSaga({ payload }) {
  try {
    const response = yield call(apis.getNotificationsList, payload);
    yield put(
      actions.getInitialNotificationsResponse(
        response.data.Data.Items.splice(0, 5)
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getNotificationsSaga({ payload }) {
  try {
    const response = yield call(apis.getNotificationsList, payload);
    const { Items, CurrentPage, ItemCount, TotalPages } =
      response.data.Data;
    yield put(
      actions.getNotificationsResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* setNotificationSeenSaga({ payload }) {
  try {
    yield call(apis.setNotificationSeen, payload.data);
    yield put(actions.decrementNotificationsCount());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* setAllNotificationsSeenSaga({ payload }) {
  try {
    yield call(apis.setAllNotificationsSeen);
    if (payload.request === "initial") {
      yield put(
        actions.getInitialNotificationsRequest({
          params: {
            page: 1
          }
        })
      );
    } else {
      yield put(
        actions.getNotificationsRequest({
          params: {
            page: 1
          }
        })
      );
    }
    yield put(actions.setUnseenNotificationsCountRequest(0));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getUnseenNotificationsCountSaga({ payload }) {
  try {
    const response = yield call(
      apis.getUnseenNotificationsCount,
      payload
    );
    const { Count } = response.data.Data;
    yield put(actions.setUnseenNotificationsCountRequest(Count));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* NotificationsSagas() {
  yield takeEvery(
    types.GET_INITIAL_NOTIFICATIONS_REQUEST,
    getInitialNotificationsSaga
  );
  yield takeEvery(
    types.GET_NOTIFICATIONS_REQUEST,
    getNotificationsSaga
  );
  yield takeEvery(
    types.SET_NOTIFICATION_SEEN,
    setNotificationSeenSaga
  );
  yield takeEvery(
    types.SET_ALL_NOTIFICATIONS_SEEN,
    setAllNotificationsSeenSaga
  );
  yield takeEvery(
    types.GET_UNSEEN_NOTIFICATIONS_COUNT_REQUEST,
    getUnseenNotificationsCountSaga
  );
}
