import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { InfoOutlined, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip
} from "@mui/material";

import {
  GuideCheckGreenBgIcon,
  GuideCrossRedBgIcon
} from "../../../../utils/Icons";
import messages from "../../../../assets/locale/messages";
import goodBackgroundImg from "../../../../assets/images/addProductGuideImages/goodBackgroundImgGuide.svg";
import badBackgroundImg from "../../../../assets/images/addProductGuideImages/badBackgroundImgGuide.svg";
import goodAngleImgGuide from "../../../../assets/images/addProductGuideImages/goodAngleImgGuide.svg";
import { sendAddProductSupportEmailRequest } from "../../../../store/Products/actions";
import "./AddProductImageGuide.scss";

const AddProductImageGuide = () => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const isSupportEmailSent = useSelector(
    (state) => state.products.isSupportEmailSent
  );
  const {
    shared,
    products: {
      addEditProduct: {
        guideInstruction: {
          guideTitle,
          textInstructions: {
            whiteBackground,
            wellLit,
            highRes,
            multipleAngles,
            avoidFilters,
            ensureClean,
            tripod,
            checkQuality,
            includeLabels
          },
          imageInstructions: {
            goodBackground,
            badBackground,
            goodAngle,
            badAngle,
            goodExample,
            badExample
          }
        }
      }
    }
  } = messages[lang];
  const instructionsList = [
    whiteBackground,
    wellLit,
    highRes,
    multipleAngles,
    avoidFilters,
    ensureClean,
    tripod,
    checkQuality,
    includeLabels
  ];
  const examplesList = [
    {
      correctExample: {
        img: goodBackgroundImg,
        text: goodBackground
      },
      wrongExample: { img: badBackgroundImg, text: badBackground }
    },
    {
      correctExample: { img: goodAngleImgGuide, text: goodAngle },
      wrongExample: { img: badBackgroundImg, text: badAngle }
    },
    {
      correctExample: { img: goodBackgroundImg, text: goodExample },
      wrongExample: { img: badBackgroundImg, text: badExample }
    }
  ];
  const renderAddGuideContent = () => (
    <div className={`row`}>
      <div className="instructions-section ps-5">
        <ul className="mb-0 ps-0">
          {instructionsList.map((instruction, i) => {
            return (
              <li
                className="light-text-color fsize-13 mb-2"
                key={`instruction-${i}`}>
                {instruction}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="images-section mt-4 d-flex flex-wrap">
        {examplesList.map((example, i) => {
          return (
            <div
              key={`guide-img-${i}`}
              className={`${i !== examplesList.length - 1 && "responsive-border guide-border"} ${i % 2 !== 0 ? "responsive-md-border" : ""} d-flex    
              flex-wrap flex-md-nowrap col-xl-4 col-md-6 col-12 mt-xl-0 mt-md-4 ps-md-5 pe-md-5 ${i !== examplesList.length - 1 && "pt-md-0 pt-3"}`}>
              <div
                className={`correct-example col-md-6 col-12 ${i !== 0 && "mt-md-0 mt-5"}`}>
                <div style={{ position: "relative", width: "100%" }}>
                  <div className="icon-container">
                    <GuideCheckGreenBgIcon />
                  </div>
                  <div className="d-flex justify-content-center img-container">
                    <img src={example.correctExample.img} />
                  </div>
                </div>
                <p className="mt-2 text-center">
                  {example.correctExample.text}
                </p>
              </div>
              <div
                className="wrong-example col-md-6 col-12 mt-md-0 mt-3"
                style={{ position: "relative" }}>
                <div className="icon-container">
                  <GuideCrossRedBgIcon />
                </div>
                <div className="d-flex justify-content-center img-container">
                  <img src={example.wrongExample.img} />
                </div>
                <p className="mt-2 mb-md-0 mb-4 text-center">
                  {example.wrongExample.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-100 d-flex justify-content-start align-items-center mt-4">
        <div
          className={` ${isSupportEmailSent ? "helper-text-needed-container" : "helper-text-container"} fw-bold fsize-12  `}>
          {isSupportEmailSent
            ? shared.supportTextClicked
            : shared.supportText}
          {!isSupportEmailSent && (
            <span
              onClick={() => {
                dispatch(sendAddProductSupportEmailRequest());
              }}
              className="highlighted-text fw-bold fw-bold fsize-12">
              {shared.supportTextHighlighted}
            </span>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <div className="add-product-image-guide mb-4">
      <Accordion
        defaultExpanded
        className="light-primary-bg mb-3 border-radius-10">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel-${name}-content`}
          id={`panel-${name}-header`}
          sx={{
            alignItems: {
              xs: "start",
              md: "center"
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              marginTop: {
                xs: "2rem",
                md: 0
              }
            },
            minHeight: "auto !important",
            "& .MuiAccordionSummary-content": {
              margin: "15px 0 !important"
            }
          }}>
          <h6 className="fweight-600 fsize-16 mb-0 title-color">
            <Tooltip
              classes={{
                tooltip: "light-blue-tooltip-fill-icon"
              }}
              className="text-secondary">
              <div className="d-flex align-items-center">
                <InfoOutlined />
                <div className="ms-2 "></div>
                <span className="text-grey fweight-500 fsize-14">
                  {guideTitle}
                </span>
              </div>
            </Tooltip>
          </h6>
        </AccordionSummary>
        <AccordionDetails>{renderAddGuideContent()}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddProductImageGuide;

AddProductImageGuide.propTypes = {
  instructionsList: PropTypes.array,
  examplesList: PropTypes.array
};
