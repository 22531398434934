import {
  APPLY_VOUCHER_RESPONSE,
  CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
  GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE,
  GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE,
  GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE,
  GET_USER_SUBSCRIPTION_PLAN_RESPONSE,
  SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG,
  SET_SUBSCRIPTION_PAYMENT_INTENT_ID,
  REQUEST_VOUCHER_RESPONSE,
  CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE,
  SET_SUBSCRIPTION_SUCCESS_MSG,
  GET_SUBSCRIPTION_FINAL_FEES_RESPONSE,
  CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
  GET_USER_SAVED_CARDS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  plansDetails: [],
  planDetails: {},
  stripeClientSecret: null,
  subscriptionFailureMsg: null,
  subscriptionPaymentIntentId: null,
  subscriptionSuccessMsg:
    sessionStorage.getItem("subscription-success-msg") || "",
  userPlan: {},
  userPrevShowcases: {},
  isVoucherRequested: false,
  voucherData: {
    voucherPercent: "",
    totalAfterDiscount: ""
  },
  checkCanRenewSuscription: {},
  subscriptionFinalFees: {},
  automaticRenewalSubscriptionPaymentIntent: {
    ClientSecret: null,
    SubscriptionId: null
  },
  userSavedCards: []
};

const subscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE:
      return {
        ...state,
        plansDetails: action.payload
      };
    case GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE:
      return {
        ...state,
        planDetails: action.payload
      };
    case CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE:
      return {
        ...state,
        stripeClientSecret: action.payload
      };
    case SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG:
      return {
        ...state,
        subscriptionFailureMsg: action.payload
      };
    case SET_SUBSCRIPTION_PAYMENT_INTENT_ID:
      return {
        ...state,
        subscriptionPaymentIntentId: action.payload
      };
    case SET_SUBSCRIPTION_SUCCESS_MSG:
      return {
        ...state,
        subscriptionSuccessMsg: action.payload
      };
    case GET_USER_SUBSCRIPTION_PLAN_RESPONSE:
      return {
        ...state,
        userPlan: action.payload
      };
    case GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE:
      return {
        ...state,
        userPrevShowcases: action.payload
      };
    case REQUEST_VOUCHER_RESPONSE:
      return {
        ...state,
        isVoucherRequested: action.payload
      };
    case APPLY_VOUCHER_RESPONSE:
      return {
        ...state,
        voucherData: {
          voucherPercent: action.payload.voucherPercent,
          totalAfterDiscount: action.payload.totalAfterDiscount
        }
      };
    case CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        checkCanRenewSuscription: action.payload
      };
    case GET_SUBSCRIPTION_FINAL_FEES_RESPONSE:
      return {
        ...state,
        subscriptionFinalFees: action.payload
      };
    case CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE:
      return {
        ...state,
        automaticRenewalSubscriptionPaymentIntent: action.payload
      };
    case GET_USER_SAVED_CARDS_RESPONSE:
      return {
        ...state,
        userSavedCards: action.payload
      };
    default:
      return state;
  }
};

export default subscription;
