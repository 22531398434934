import PropTypes from "prop-types";
import { useState } from "react";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp
} from "@mui/icons-material";
import { Swiper as CustomSwiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay
} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./Swiper.scss";

const Swiper = ({
  name,
  slides,
  slidesToShow,
  dots,
  infinite,
  speed,
  sliderClassName,
  slideClassName,
  responsiveSettings,
  autoplay,
  fade,
  handleOnSlideClick,
  isVertical,
  delay,
  sliderContainerClassName
}) => {
  const [currentSlidesNumber, setCurrentSlidesNumber] = useState(1);
  return (
    <div
      className={`swiper-container position-relative ${sliderContainerClassName}`}>
      <div
        className={`custom-swiper-button custom-swiper-button-next ${name}-custom-swiper-button-next ${slides.length <= currentSlidesNumber && "d-none"}`}>
        {isVertical ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
      </div>

      <div
        className={`custom-swiper-button custom-swiper-button-prev ${name}-custom-swiper-button-prev ${slides.length <= currentSlidesNumber && "d-none"}`}>
        {isVertical ? <KeyboardArrowUp /> : <KeyboardArrowLeft />}
      </div>

      <CustomSwiper
        modules={[Navigation, Pagination, EffectFade, Autoplay]}
        className={`${sliderClassName}`}
        id={sliderClassName}
        direction={isVertical ? "vertical" : "horizontal"}
        slidesPerView={slidesToShow}
        loop={infinite}
        autoplay={autoplay ? { delay } : false}
        speed={speed}
        effect={fade && "fade"}
        onBreakpoint={(swiper) => {
          if (
            responsiveSettings &&
            Object.keys(responsiveSettings).includes(
              swiper.currentBreakpoint
            )
          ) {
            setCurrentSlidesNumber(
              responsiveSettings[swiper.currentBreakpoint]
                .slidesPerView
            );
          } else {
            setCurrentSlidesNumber(1);
          }
        }}
        navigation={{
          nextEl: `.${name}-custom-swiper-button-next`,
          prevEl: `.${name}-custom-swiper-button-prev`,
          disabledClass: "custom-swiper-button-disabled"
        }}
        pagination={
          dots
            ? {
                dynamicBullets: true,
                clickable: true
              }
            : false
        }
        breakpoints={responsiveSettings}>
        {slides?.map((slide, i) => (
          <SwiperSlide
            className={`${name}-swiper-slide ${slideClassName} ${handleOnSlideClick && "pointer"}`}
            onClick={() => {
              handleOnSlideClick && handleOnSlideClick(i);
            }}
            key={`${name}-swiper-slide-${i}`}>
            {slide}
          </SwiperSlide>
        ))}
      </CustomSwiper>
    </div>
  );
};

export default Swiper;

Swiper.propTypes = {
  name: PropTypes.string,
  slides: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  verticalSwiping: PropTypes.bool,
  autoplay: PropTypes.bool,
  fade: PropTypes.bool,
  isVertical: PropTypes.bool,
  speed: PropTypes.number,
  delay: PropTypes.number,
  sliderClassName: PropTypes.string,
  slideClassName: PropTypes.string,
  nextArrow: PropTypes.element,
  prevArrow: PropTypes.element,
  responsiveSettings: PropTypes.object,
  handleOnSlideClick: PropTypes.func,
  sliderContainerClassName: PropTypes.string
};
