export const WhiteEnvelopeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    fill="none"
    viewBox="0 0 16 17"
    {...props}>
    <path
      fill="#F1FAFF"
      d="M11.344 15.09H4.677c-2.433 0-3.833-1.4-3.833-3.834V6.589c0-2.433 1.4-3.833 3.833-3.833h6.667c2.433 0 3.833 1.4 3.833 3.833v4.667c0 2.433-1.4 3.833-3.833 3.833zM4.677 3.755c-1.907 0-2.833.927-2.833 2.833v4.667c0 1.906.926 2.833 2.833 2.833h6.667c1.906 0 2.833-.927 2.833-2.833V6.589c0-1.906-.927-2.833-2.833-2.833H4.677z"></path>
    <path
      fill="#F1FAFF"
      d="M8.009 9.503c-.56 0-1.127-.174-1.56-.527L4.362 7.31a.499.499 0 01.62-.78L7.07 8.197c.507.407 1.367.407 1.873 0L11.03 6.53a.492.492 0 01.7.08.492.492 0 01-.08.7L9.562 8.976c-.426.353-.993.527-1.553.527z"></path>
  </svg>
);
