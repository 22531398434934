import { useSelector } from "react-redux";

import messages from "../../../assets/locale/messages";

const CopyrightsFooter = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    <div className="copyrights-footer-container">
      <div className="title-color fsize-14 fweight-500 footer-container medium-blue-bg d-flex justify-content-center align-items-center py-3">
        {shared.copyrights}
      </div>
    </div>
  );
};

export default CopyrightsFooter;
