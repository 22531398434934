import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import giftImg from "../../../../assets/images/vectors/gift.png";

const FreeSubscription = () => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    subscription: {
      paymentPage: { freeSubscription }
    },
    shared
  } = messages[lang];

  return (
    <div className="text-center">
      <img src={giftImg} />
      <p className="fsize-22 fweight-600 title-color">
        {freeSubscription.congratulations}
      </p>
      <p className="text-color">
        {freeSubscription.receivedFreeSubscription}
      </p>
      <p className="text-color">{freeSubscription.submitForFree}</p>
    </div>
  );
};

export default FreeSubscription;
