export const WarningBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="69"
    fill="none"
    viewBox="0 0 68 69"
    {...props}>
    <rect
      width="60"
      height="60"
      x="4"
      y="4.302"
      fill="#FFF7C9"
      rx="28"></rect>
    <rect
      width="60"
      height="60"
      x="4"
      y="4.302"
      stroke="#FFFBE6"
      strokeWidth="8"
      rx="28"></rect>
    <path
      stroke="#E8B42D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M34 30.552v6.25M34 46.064h-7.576c-4.337 0-6.15-3.1-4.05-6.888l3.9-7.025 3.675-6.6c2.225-4.012 5.875-4.012 8.1 0l3.675 6.613 3.9 7.025c2.1 3.787.275 6.887-4.05 6.887H34v-.012zM33.992 40.552h.011"></path>
  </svg>
);
