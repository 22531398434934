import {
  CHANGE_PASSWORD_REQUEST,
  DELETE_EXPORTER_ACCOUNT_REQUEST,
  DELETE_IMPORTER_ACCOUNT_REQUEST,
  GET_SUBSCRIPTION_END_DATE_REQUEST,
  GET_SUBSCRIPTION_END_DATE_RESPONSE,
  DEACTIVATE_EXPORTER_ACCOUNT,
  DEACTIVATE_IMPORTER_ACCOUNT,
  SHOW_CHANGE_PASSWORD_SUCCESS_MODAL,
  CHANGE_CURRENCY_REQUEST,
  DISABLE_ENABLE_PRODUCT_REQUEST_NOTIFICATION
} from "./types";

export const changePasswordRequest = (payload) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload
});

export const showChangePasswordSuccessModal = (payload) => ({
  type: SHOW_CHANGE_PASSWORD_SUCCESS_MODAL,
  payload
});

export const deleteExporterAccountRequest = (payload) => ({
  type: DELETE_EXPORTER_ACCOUNT_REQUEST,
  payload
});

export const deleteImporterAccountRequest = (payload) => ({
  type: DELETE_IMPORTER_ACCOUNT_REQUEST,
  payload
});

export const getSubscriptionEndDateRequest = (payload) => ({
  type: GET_SUBSCRIPTION_END_DATE_REQUEST,
  payload
});

export const getSubscriptionEndDateResponse = (payload) => ({
  type: GET_SUBSCRIPTION_END_DATE_RESPONSE,
  payload
});

export const deactivateExporterAccountRequest = (payload) => ({
  type: DEACTIVATE_EXPORTER_ACCOUNT,
  payload
});

export const deactivateImporterAccountRequest = (payload) => ({
  type: DEACTIVATE_IMPORTER_ACCOUNT,
  payload
});

export const changeCurrencyRequest = (payload) => ({
  type: CHANGE_CURRENCY_REQUEST,
  payload
});

export const disableEnableProductRequestNotificationsRequest = (
  payload
) => ({
  type: DISABLE_ENABLE_PRODUCT_REQUEST_NOTIFICATION,
  payload
});
