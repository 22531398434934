import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useSearchParams,
  useNavigate,
  useParams
} from "react-router-dom";
import { Box } from "@mui/system";

import messages from "../../../../assets/locale/messages";
import Button from "../../../../components/Button";
import {
  confirmSubscriptionPayment,
  getPreviousSubscriptionTypeRequest,
  getUserSubscriptionPlanRequest,
  updateAutomaticRenewalSubscriptionTypeRequest
} from "../../../../store/Subscription/actions";
import { getUserStatusRequest } from "../../../../store/User/actions";
import { CheckGreenBgIcon } from "../../../../utils/Icons";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  SUBSCRIPTION_PAYMENT_TYPE,
  SUBSCRIPTION_PLAN_FEATURES_IDS
} from "../../../../utils/Constants";
import { isSafari } from "../../../../utils/Helpers";

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams();

  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const {
    subscription: {
      paymentPage: {
        successPayment,
        delayedSubscription,
        downgradeShowcases: { successScreen }
      }
    }
  } = messages[lang];

  const prevSubscriptionPlan = useSelector(
    (state) => state.subscription.userPrevShowcases
  );
  const successMsg = useSelector(
    (state) => state.subscription.subscriptionSuccessMsg
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );
  const userId = useSelector((state) => state.auth.login?.Id);

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");
  const cardInfo = searchParams.get("card_info");
  const priceId = searchParams.get("price_id");
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );
  const redirectStatus = searchParams.get("redirect_status");
  const isPaymentConfirmed = searchParams.get("is_payment_confirmed");
  const [showcasesCount, setShowcasesCount] = useState(0);
  const [allowedSubaccountsCount, setAllowedSubaccountsCount] =
    useState(0);

  // This is used to prevent confirmPaymentIntentRequest from being called twice
  const isRequestSentRef = useRef(false);

  const voucherCode = sessionStorage.getItem("voucherCode");

  const isSubscriptionDelayed = !!localStorage.getItem(
    `${userId}-delayed-automatic-subscription`
  );

  useEffect(() => {
    if (!isRequestSentRef.current && !isPaymentConfirmed) {
      const paramsObject = {
        subscriptionTypeId: id,
        code: voucherCode,
        setSearchParams
      };
      if (paymentIntent) {
        paramsObject.paymentIntentId = paymentIntent;
      }
      if (type === SUBSCRIPTION_PAYMENT_TYPE.automatic) {
        // if (priceId) {
        //   dispatch(
        //     updateAutomaticRenewalSubscriptionTypeRequest({
        //       data: {
        //         PriceId: priceId,
        //         SubscriptionTypeId: id,
        //         Code: voucherCode,
        //         PaymentIntentId: paymentIntent,
        //         last4Digit: cardInfo
        //       },
        //       setSearchParams
        //     })
        //   );
        // } else {
        // }
        setSearchParams((searchParams) => {
          searchParams.set("is_payment_confirmed", true);
          searchParams.delete("payment_intent");
          searchParams.delete("payment_intent_client_secret");
          searchParams.delete("redirect_status");
          return searchParams;
        });
      } else {
        dispatch(confirmSubscriptionPayment(paramsObject));
      }
      isRequestSentRef.current = true;
    }
    if (isPaymentConfirmed) {
      dispatch(getPreviousSubscriptionTypeRequest());
    }
    return () => {
      if (
        type === SUBSCRIPTION_PAYMENT_TYPE.automatic &&
        isPaymentConfirmed
      ) {
        dispatch(getUserSubscriptionPlanRequest());
        dispatch(getUserStatusRequest());
      }
    };
  }, [dispatch, paymentIntent, isPaymentConfirmed]);

  useEffect(() => {
    if (prevSubscriptionPlan?.IsNeedToEdit) {
      dispatch(getUserSubscriptionPlanRequest());
    }
  }, [prevSubscriptionPlan?.IsNeedToEdit]);

  useEffect(() => {
    if (userPlan) {
      setShowcasesCount(
        userPlan?.Features?.find(
          (feature) =>
            feature?.Id ===
            SUBSCRIPTION_PLAN_FEATURES_IDS.showcasesNumber
        )?.Value
      );
      setAllowedSubaccountsCount(
        userPlan?.Features?.find(
          (feature) =>
            feature.Id ===
            SUBSCRIPTION_PLAN_FEATURES_IDS.subaccountsNumber
        )?.Value
      );
    }
  }, [userPlan]);

  useEffect(() => {
    if (type === SUBSCRIPTION_PAYMENT_TYPE.automatic) {
      localStorage.setItem(
        `${userId}-delayed-automatic-subscription`,
        id
      );
    }
  }, [type, userId]);

  const renderSubscribeSuccessContent = () => (
    <>
      <h5 className="fsize-24 fweight-700 title-color mb-3">
        {successPayment.thankYouNote}
      </h5>
      <p className="fsize-16 text-color mb-4">
        {isSubscriptionDelayed
          ? delayedSubscription.delayMsg
          : successMsg || successPayment.explorePlan}
      </p>
      <Button
        label={successPayment.backToPlanInfo}
        onClick={() => {
          navigate(ROUTE_PATHS.subscription);
        }}
      />
    </>
  );

  const renderdowngradeShowcases = () => (
    <>
      <h5 className="fsize-24 fweight-700 title-color mb-3">
        {successScreen.successSubscription}
      </h5>
      <p className="fsize-16 text-color mb-4">
        {successScreen.showcasesReduced
          .replace(":showcasesNumber", showcasesCount)
          .replace(":subaccountsNumber", allowedSubaccountsCount)}
      </p>
      <Button
        label={successPayment.backToPlanInfo}
        onClick={() => {
          navigate(ROUTE_PATHS.subscription);
        }}
      />
      {/* <Button
        label={successScreen.editShowcases}
        onClick={() => setEditShowcasesModalOpen(true)}
      />
      <ShowHideShowcases
        open={editShowcasesModalOpen}
        onClose={() => setEditShowcasesModalOpen(false)}
      /> */}
    </>
  );

  return (
    <Box
      className="payment-success-page w-100 d-flex align-items-center justify-content-center"
      sx={{
        margin: "10rem 0"
      }}>
      {isPaymentConfirmed && !isLoading && (
        <Box
          className="text-center faint-grey-bg border-radius-10 px-3 px-md-5 py-5 position-relative"
          sx={{
            width: {
              xs: "95%",
              sm: "90%",
              md: "80%",
              lg: "50%"
            },
            margin: "0 auto"
          }}>
          <Box
            className="vector-section mb-3"
            sx={{
              position: "absolute",
              top: {
                xs: "-20%",
                sm: "-20%"
              },
              ...(isSafari()
                ? {
                    // Apply these styles only if the browser is Safari
                    left: {
                      xs: "40%",
                      sm: "44%",
                      md: "32%",
                      lg: "27%",
                      xl: "46%"
                    }
                  }
                : {
                    left: {
                      xs: "40%",
                      sm: "44%",
                      xl: "46%"
                    }
                  }),
              width: {
                xs: "5rem",
                sm: "auto"
              }
            }}>
            <CheckGreenBgIcon className="mb-4 w-100" />
          </Box>
          <Box
            className="content-section mt-5"
            sx={{
              margin: "0 auto",
              width: {
                xs: "100%",
                sm: "85%",
                md: "70%",
                lg: "90%",
                xl: "75%"
              }
            }}>
            {prevSubscriptionPlan?.IsNeedToEdit
              ? renderdowngradeShowcases()
              : renderSubscribeSuccessContent()}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionSuccess;
