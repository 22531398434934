import EuroIcon from "@mui/icons-material/Euro";
import { ROUTE_PATHS } from "./RoutesPaths";

export const EXPORTER_LAYOUT_DIMENSIONS = {
  drawerWidth: 240,
  navbarHeight: 72,
  footerHeight: 40
};
export const acceptedImagesFilesTypes = [
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/jpg"
];

export const generalImporterPagesPadding =
  "px-md-5 px-4 py-3 px-xxl-3";

export const EXGATES_CONTACT_PHONE = "(629) 222-2729";
export const EXGATES_CONTACT_EMAIL = "info@ex-gate.com";

export const EXGATES_FACEBOOK_LINK =
  "https://www.facebook.com/exgates";
export const EXGATES_TWITTER_LINK = "https://x.com/exgates";
export const EXGATES_INSTAGRAM_LINK =
  "https://www.instagram.com/exgates.b2b";
export const EXGATES_LINKEDIN_LINK =
  "https://www.linkedin.com/company/exgates";

export const DISPLAY_STYLE = {
  grid: "Grid",
  list: "List"
};

export const googleAnalyticsMeasurementId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

export const storageAccountName =
  process.env.REACT_APP_IMAGES_STORAGE_ACCOUNT_NAME;
export const storageAccountSAS =
  process.env.REACT_APP_VIDEOS_STORAGE_ACCOUNT_SAS;
export const transcribeApiAuthorizationKey =
  process.env.REACT_APP_TRANSCRIBE_API_AUTHORIZATION_KEY;

export const CURRENCIES_SYMBOLS = {
  usd: "$",
  euro: <EuroIcon fontSize="small" className="mb-1" />
};

export const CURRENCIES_IDS = {
  usd: 1,
  euro: 2
};

export const STRIPE_CURRENCIES_NAMES = {
  usd: "usd",
  euro: "eur"
};

export const FILTER_STATUS = {
  all: "All",
  published: "Published",
  unpublished: "Unpublished"
};

export const PRODUCT_STATUS = {
  draft: "Draft",
  published: "Published",
  unpublished: "Unpublished",
  pending: "Pending",
  rejected: "Rejected",
  edited: "Edited"
};
export const PRODUCT_STATUS_WITH_ID = {
  pending: { ProductStatusName: "Pending", ProductStatusId: 1 },
  published: { ProductStatusName: "Published", ProductStatusId: 2 },
  unpublished: {
    ProductStatusName: "Unpublished",
    ProductStatusId: 3
  },
  draft: { ProductStatusName: "Draft", ProductStatusId: 4 },
  rejected: { ProductStatusName: "Rejected", ProductStatusId: 5 },
  deleted: { ProductStatusName: "Deleted", ProductStatusId: 6 },
  edited: { ProductStatusName: "Edited", ProductStatusId: 7 },
  editedPendingApproval: {
    ProductStatusName: "Edited Pending Approval",
    ProductStatusId: 7
  }
};

export const LANGUAGES = {
  ar: "ar",
  en: "en"
};

export const SEARCH_RESULTS_TYPES_IDS = {
  all: 0,
  product: 1,
  subCategory: 2,
  category: 3,
  company: 4,
  exporter: 5
};

export const SORT_PRODUCTS = [
  {
    id: 1,
    label: "Recently Added",
    value: "recently-added"
  },
  {
    id: 3,
    label: "Price Range Low to High",
    value: "low-price"
  },
  {
    id: 4,
    label: "Price Range High to Low",
    value: "high-price"
  }
];
export const acceptedImgMinSize = 230400;
export const acceptedVideoMinSize = 409920;
export const acceptedImagesFilesMaxSize = 2097152;
export const acceptedPdfMaxSize = 2000000;
export const acceptedDocumentFilesTypes = [".pdf", "application/pdf"];

export const PRODUCT_SPECIFICATION_TYPES = {
  catalogue: "catalogue",
  other: "other"
};

export const SNACKBAR_TYPES = {
  success: "success",
  error: "error",
  info: "info"
};

export const USER_TYPES_IDS = {
  admin: 1,
  exporter: 2,
  importer: 3,
  both: 4
};
export const USER_STATUS_IDS = {
  registered: 1,
  verified: 2,
  pendingBusinessVerified: 3,
  businessVerified: 4,
  subscribed: 5,
  subscriptionExpired: 6
};

export const BUSINESS_TYPES_IDS = {
  factor: 1,
  trade: 2,
  both: 3
};
export const BUSINESS_TYPES = {
  factor: { businessName: "Factory", id: 1 },
  trade: { businessName: "Trade", id: 2 },
  both: { businessName: "Both", id: 3 }
};

export const BANNER_COLORS = {
  warning: "#DEA71B",
  info: "#0071bc"
};

export const VERIFICATION_METHODS = {
  onlinePayment: 1,
  bankTransfer: 2,
  offPlatform: 3
};

export const OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES = {
  image: 1,
  pdf: 2,
  text: 3
};

export const COMPANY_VERIFICATION_STATUS = {
  beforeVerify: null,
  awaitingRequiredDocuments: 1,
  pendingDocumentsUpload: 2,
  documentsUploaded: 3,
  pendingPayment: 4,
  docsNeedUpdates: 5,
  rejected: 6,
  pendingFinalApproval: 7,
  approved: 8
};

export const TRANSACTION_STATUS = {
  succeeded: 1,
  pending: 2,
  failed: 3
};

export const formPaths = [
  new RegExp(`${ROUTE_PATHS.editProfile}$`),
  new RegExp(`${ROUTE_PATHS.changePassword}$`),
  new RegExp(`${ROUTE_PATHS.addProduct}$`),
  new RegExp(`${ROUTE_PATHS.editProduct.replace(":id", "\\d+")}$`),
  new RegExp(`${ROUTE_PATHS.addShowcase}$`),
  new RegExp(`${ROUTE_PATHS.editShowcase.replace(":id", "\\d+")}$`),
  new RegExp(`${ROUTE_PATHS.editCompanyProfile}$`),
  new RegExp(`${ROUTE_PATHS.addImporterProductRequests}$`),
  new RegExp(
    `${ROUTE_PATHS.editImporterProductRequests.replace(
      ":id",
      "\\d+"
    )}$`
  )
];
export const bothPaths = [
  ROUTE_PATHS.viewProfile,
  ROUTE_PATHS.settings
];
export const SUBSCRIPTION_PLANS_IDS = {
  basic: 1,
  standard: 2,
  premium: 3
};

export const SUBSCRIPTION_PLAN_FEATURES_IDS = {
  price: 1,
  productsListing: 2,
  searchKeywords: 3,
  showcasesNumber: 4,
  subaccountsNumber: 5,
  priorityRanking: 6
};

export const CHIPS_CLASSES = {
  yellow: "yellow-chip",
  green: "green-chip",
  red: "red-chip",
  blue: "blue-chip",
  grey: "grey-chip",
  orange: "orange-chip",
  pink: "pink-chip",
  purple: "purple-chip",
  darkBlue: "dark-blue-chip",
  darkYellow: "dark-yellow-chip"
};

export const PRODUCT_REQUEST_STATUS_ID = {
  published: 1,
  closed: 2
};

export const EXGATES_BANK_INFO_PDF =
  "https://exgates.blob.core.windows.net/specifications/Exgates-BankInfo-[15-10-2024].pdf";

export const COMPANY_PROFILE_CATEGORY_STATUS = {
  accepted: 1,
  pending_admin_approval: 2,
  rejected: 3
};
export const SUB_ACCOUNT_ROLE_ID = {
  systemAdmin: 1,
  manager: 2,
  agent: 3
};

export const SUBSCRIPTION_PAYMENT_TYPE = {
  manual: "manual",
  automatic: "automatic"
};

export const EXGATES_INSTRUCTIONS_PDF =
  "https://exgates.blob.core.windows.net/specifications/Exgates_instructions_Document.pdf";
