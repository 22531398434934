import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/user";
import * as types from "./types";
import * as actions from "./actions";
import {
  checkBackgroundImageValidity,
  showErrorMsg,
  showSuccessMsg
} from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { deleteBlob } from "../../utils/blobStorageServices";
import store from "../index";
import { loginResponse } from "../Auth/actions";
import { setUser } from "../../utils/Auth";

function* getLoggedInExporterDetailsSaga() {
  try {
    const response = yield call(apis.getLoggedInExporterDetails);
    yield put(
      actions.getLoggedInExporterDetailsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editLoggedInExporterDetailsSaga({ payload }) {
  const { data, dbData } = payload;
  try {
    yield call(apis.editLoggedInExporterDetails, data);
    if (dbData.dbCompanyLogo && dbData.companyLogoInfo.isNew) {
      checkBackgroundImageValidity(dbData.dbCompanyLogo, (exists) => {
        if (exists) {
          deleteBlob(
            dbData.dbCompanyLogo?.split("/images/")[1],
            "images"
          );
        }
      });
    }
    if (
      dbData.dbCompanyCoverImage &&
      dbData.companyCoverImageInfo.isNew
    ) {
      checkBackgroundImageValidity(
        dbData.dbCompanyCoverImage,
        (exists) => {
          if (exists) {
            deleteBlob(
              dbData.dbCompanyCoverImage?.split("/images/")[1],
              "images"
            );
          }
        }
      );
    }
    const companyData = store.getState().auth.login;
    const rememberMe = !!localStorage.getItem("user");

    if (!!dbData.newImageUrl) {
      const updatedCompanyDataWithNameAndLogo = {
        ...companyData,
        CompanyLogo: dbData.newImageUrl,
        CompanyName: data.DisplayName
      };
      yield put(loginResponse(updatedCompanyDataWithNameAndLogo));
      yield rememberMe
        ? setUser(updatedCompanyDataWithNameAndLogo, "local")
        : setUser(updatedCompanyDataWithNameAndLogo, "session");
    }

    if (!!dbData.countryId && !!dbData.countryNameState) {
      const updatedCompanyDataWithCountryNameAndCode = {
        ...companyData,
        CountryId: dbData.countryId,
        CountryName: dbData.countryNameState
      };
      yield put(
        loginResponse(updatedCompanyDataWithCountryNameAndCode)
      );
      yield rememberMe
        ? setUser(updatedCompanyDataWithCountryNameAndCode, "local")
        : setUser(
            updatedCompanyDataWithCountryNameAndCode,
            "session"
          );
    }

    yield showSuccessMsg("editCompanyDetailsSuccess");
    yield payload.navigate(ROUTE_PATHS.companyProfile);
  } catch (error) {
    if (dbData.CompanyLogo && dbData.companyLogoInfo.isNew)
      yield deleteBlob(dbData.CompanyLogo, "images");
    if (
      dbData.CompanyCoverImage &&
      dbData.companyCoverImageInfo.isNew
    )
      yield deleteBlob(dbData.CompanyCoverImage, "images");
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* subCategoryProductsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSubCategoryProductsList,
      payload
    );
    const responseData = response.data.Data;
    yield put(
      actions.getSubCategoryProductsListResponse({
        list: responseData.Items,
        meta: {
          currentPage: +responseData.CurrentPage,
          count: +responseData.ItemCount,
          pages: +responseData.TotalPages
        }
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* addShowcaseSaga({ payload }) {
  try {
    const response = yield call(apis.addShowcase, payload);
    const responseMsg = response.data.Data.Message;
    yield showSuccessMsg("", responseMsg);
    yield payload.navigate(ROUTE_PATHS.companyProfile);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* publishExporterSaga({ payload }) {
  try {
    yield call(apis.publishExporter);
    payload.hasShowcase
      ? yield showSuccessMsg("companyWithShowcasePublish")
      : yield showSuccessMsg("firstTimePublish");
    const userData = store.getState().auth.login;
    const updatedUserDataWithPublished = {
      ...userData,
      IsPublished: true
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithPublished));
    yield rememberMe
      ? setUser(updatedUserDataWithPublished, "local")
      : setUser(updatedUserDataWithPublished, "session");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editShowcaseSaga({ payload }) {
  const { data, dbData } = payload;
  try {
    const response = yield call(apis.editShowcase, data);
    const responseMsg = response.data.Data.Message;
    if (dbData.coverPhotoInfo.isNew) {
      yield deleteBlob(
        dbData.dbCoverPhoto?.split("/images/")[1],
        "images"
      );
    }
    if (dbData.websiteCoverPhotoInfo.isNew) {
      yield deleteBlob(
        dbData.dbWebsiteCoverPhoto?.split("/images/")[1],
        "images"
      );
    }
    yield showSuccessMsg("", responseMsg);
    yield payload.navigate(ROUTE_PATHS.companyProfile);
  } catch (error) {
    if (dbData.coverPhotoInfo.isNew && data.CoverPhoto)
      yield deleteBlob(data.CoverPhoto, "images");
    if (dbData.websiteCoverPhotoInfo.isNew && data.WebsiteCoverPhoto)
      yield deleteBlob(data.WebsiteCoverPhoto, "images");
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deleteShowcaseSaga({ payload }) {
  try {
    yield call(apis.deleteShowcase, payload);
    payload.src === ROUTE_PATHS.editShowcase
      ? yield payload.navigate(ROUTE_PATHS.companyProfile)
      : yield put(actions.getLoggedInExporterDetailsRequest());
    yield showSuccessMsg("deleteShowcaseSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getUserStatusSaga({ payload }) {
  try {
    const response = yield call(apis.getUserStatus, payload);
    const userData = store.getState().auth.login;
    const updatedUserDataWithStatus = {
      ...userData,
      UserStatusId: response.data.Data.Id
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithStatus));
    yield rememberMe
      ? setUser(updatedUserDataWithStatus, "local")
      : setUser(updatedUserDataWithStatus, "session");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* hideInstructionsBannerSaga() {
  try {
    yield call(apis.hideInstructionsBanner);
    const userData = store.getState().auth.login;
    const updatedUserDataWithDisplayBanner = {
      ...userData,
      DisplayBanner: false
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithDisplayBanner));
    yield rememberMe
      ? setUser(updatedUserDataWithDisplayBanner, "local")
      : setUser(updatedUserDataWithDisplayBanner, "session");
    yield put(loginResponse(updatedUserDataWithDisplayBanner));
    yield showSuccessMsg("hideInstructionsBannerSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* UserSagas() {
  yield takeLatest(
    types.GET_LOGGED_IN_EXPORTER_DETAILS_REQUEST,
    getLoggedInExporterDetailsSaga
  );
  yield takeEvery(
    types.EDIT_LOGGED_IN_EXPORTER_DETAILS_REQUEST,
    editLoggedInExporterDetailsSaga
  );
  yield takeEvery(
    types.SUBCATEGORY_PRODUCTS_LIST_REQUEST,
    subCategoryProductsListSaga
  );
  yield takeEvery(
    types.PUBLISH_EXPORTER_REQUEST,
    publishExporterSaga
  );
  yield takeEvery(types.ADD_SHOWCASE_REQUEST, addShowcaseSaga);
  yield takeEvery(types.EDIT_SHOWCASE_REQUEST, editShowcaseSaga);
  yield takeEvery(types.DELETE_SHOWCASE_REQUEST, deleteShowcaseSaga);
  yield takeEvery(types.GET_USER_STATUS_REQUEST, getUserStatusSaga);
  yield takeEvery(
    types.HIDE_INSTRUCTIONS_BANNER_REQUEST,
    hideInstructionsBannerSaga
  );
}
