import PropTypes from "prop-types";
import SlickSlider from "react-slick";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp
} from "@mui/icons-material";

import "./slick.css";
import "./slick-theme.css";

const CustomNextArrow = ({
  className,
  style,
  onClick,
  icon,
  isVertical
}) =>
  icon ? (
    <span
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      {icon}
    </span>
  ) : isVertical ? (
    <KeyboardArrowDown
      className={className}
      style={{ ...style }}
      onClick={onClick}
      fontSize="large"
    />
  ) : (
    <KeyboardArrowRight
      className={className}
      style={{ ...style }}
      onClick={onClick}
      fontSize="large"
    />
  );

CustomNextArrow.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  isVertical: PropTypes.bool
};

const CustomPrevArrow = ({
  className,
  style,
  onClick,
  icon,
  isVertical
}) =>
  icon ? (
    <span
      className={className}
      style={{ ...style }}
      onClick={onClick}>
      {icon}
    </span>
  ) : isVertical ? (
    <KeyboardArrowUp
      className={className}
      style={{ ...style }}
      onClick={onClick}
      fontSize="large"
    />
  ) : (
    <KeyboardArrowLeft
      className={className}
      style={{ ...style }}
      onClick={onClick}
      fontSize="large"
    />
  );

CustomPrevArrow.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  isVertical: PropTypes.bool
};

const Slider = ({
  slides,
  slidesToShow,
  slidesToScroll,
  dots,
  infinite,
  speed,
  sliderClassName,
  slideClassName,
  nextArrow,
  prevArrow,
  responsiveSettings,
  vertical,
  verticalSwiping,
  autoplay,
  fade,
  handleOnSlideClick,
  isVertical
}) => {
  const settings = {
    slidesToShow,
    dots,
    infinite,
    vertical,
    verticalSwiping,
    autoplay: autoplay || false,
    fade: fade || false,
    slidesToScroll: slidesToScroll || 1,
    speed: speed || 1000,
    nextArrow: (
      <CustomNextArrow icon={nextArrow} isVertical={isVertical} />
    ),
    prevArrow: (
      <CustomPrevArrow icon={prevArrow} isVertical={isVertical} />
    ),
    responsive: responsiveSettings || []
  };
  return (
    <div className={`${sliderClassName} slider-container`}>
      <SlickSlider {...settings}>
        {slides?.map((slide, i) => (
          <div
            className={` ${handleOnSlideClick && "pointer"} ${slideClassName}`}
            onClick={() => {
              handleOnSlideClick && handleOnSlideClick(i);
            }}
            key={`slide-${i}`}>
            {slide}
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default Slider;

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  vertical: PropTypes.bool,
  verticalSwiping: PropTypes.bool,
  autoplay: PropTypes.bool,
  fade: PropTypes.bool,
  isVertical: PropTypes.bool,
  speed: PropTypes.number,
  sliderClassName: PropTypes.string,
  slideClassName: PropTypes.string,
  nextArrow: PropTypes.element,
  prevArrow: PropTypes.element,
  responsiveSettings: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number.isRequired,
      settings: PropTypes.shape({
        slidesToShow: PropTypes.number.isRequired,
        slidesToScroll: PropTypes.number.isRequired,
        infinite: PropTypes.bool.isRequired,
        dots: PropTypes.bool
      })
    })
  ),
  handleOnSlideClick: PropTypes.func
};
