import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import messages from "../../../../assets/locale/messages";
import Input from "../../../../components/Input";
import addProductRequestGuideCircles from "../../../../assets/images/addProductRequestGuideCircles.svg";
import addProductRequestGuideMainImg from "../../../../assets/images/addProductRequestGuideMainImg.svg";
import Select from "../../../../components/Select";

import Button from "../../../../components/Button";
import {
  addImporterProductRequestRequest,
  editImporterProductRequestRequest,
  getImporterProductRequestDetailsRequest,
  getImporterProductRequestDetailsResponse
} from "../../../../store/ProductRequests/actions";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  getCategoriesListRequest,
  getMOQListRequest
} from "../../../../store/Lookups/actions";
import ProductRequestCurrencyChange from "../ProductRequestCurrencyChange";
import RichTextInput from "../../../../components/RichTextInput";
import { convertRichTextToPlainText } from "../../../../utils/Helpers";
import "./AddEditProductRequest.scss";

const AddEditProductRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);

  const userCurrency = useSelector(
    (state) => state.auth.login?.currency.CurrencySymbol
  );

  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const moqList = useSelector((state) => state.lookups.moqList);

  const importerProductRequestDetails = useSelector(
    (state) => state.productRequests?.importerProductRequestDetails
  );

  const loggedInUserId = useSelector((state) => state.auth.login.Id);
  const userCurrencyId = useSelector(
    (state) => state.auth.login.currency.CurrencyId
  );

  const [categoryLookupListState, setCategoryLookupListState] =
    useState([]);
  const [moqUnitLookupListState, setMoqUnitLookupListState] =
    useState([]);
  const [
    isDifferentCurrencyProductRequest,
    setIsDifferentCurrencyProductRequest
  ] = useState(false);
  const [
    editedProductRequestDetails,
    setEditedProductRequestDetails
  ] = useState({});
  const [
    isChangeProductRequestCurrencyModalOpen,
    setIsChangeProductRequestCurrencyModalOpen
  ] = useState(false);

  const handleCloseModal = () => {
    setIsChangeProductRequestCurrencyModalOpen(false);
    setEditedProductRequestDetails({});
  };

  const renderStepValue = (stepTitle, stepSubtitle, className) => (
    <div className={className}>
      <p className="title-color fweight-500 fsize-16">{stepTitle}</p>
      <span className="my-3 d-block text-light-grey fweight-400 fsize-14">
        {stepSubtitle}
      </span>
    </div>
  );

  const {
    shared,
    productRequests: {
      importerProductRequests: {
        addEditProductRequest: {
          addPageTitle,
          editPageTitle,
          optional,
          blockLabels,
          inputLabels,
          placeholders,
          guideSection
        }
      }
    }
  } = messages[lang];

  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    setValues,
    handleSubmit,
    dirty,
    isValid,
    setFieldError
  } = useFormik({
    initialValues: {
      requestTitle: "",
      requestDescription: { pureText: "", richText: "" },
      categories: [],
      minimumRequiredQuantity: "",
      maximumRequiredQuantity: "",
      requiredQuantityUnit: "",
      requiredQuantityOtherUnit: "",
      minimumBudgetRange: "",
      maximumBudgetRange: ""
    },
    validationSchema: Yup.object({
      requestTitle: Yup.string()
        .required("productRequestTitleRequired")
        .max(50, "productRequestTitleLong"),
      requestDescription: Yup.object().shape({
        pureText: Yup.string()
          .max(200, "requestDescriptionLong")
          .required("requestDescriptionRequired"),
        richText: Yup.string()
      }),
      categories: Yup.array().nullable(),
      minimumRequiredQuantity: Yup.number()
        .required("minimumRequiredQuantityRequired")
        .min(1, "minimumRequiredQuantityLow")
        .integer("invalidMinimumRequiredQuantity")
        .positive("invalidMinimumRequiredQuantity")
        .test(
          "is-minimum-required-quantity-long",
          "minimumRequiredQuantityLong",
          function (value) {
            if (!!value) {
              const valueAsString = value.toString();
              return valueAsString.length <= 6;
            }
            return true;
          }
        ),
      maximumRequiredQuantity: Yup.number()
        .required("maximumRequiredQuantityRequired")
        .integer("invalidMaximumRequiredQuantity")
        .positive("invalidMaximumRequiredQuantity")
        .test(
          "is-smaller",
          "maximumOrderQuantitySmaller",
          function (value) {
            const { minimumRequiredQuantity } = this.parent;
            if (!minimumRequiredQuantity || !value) {
              return true;
            }
            return value > minimumRequiredQuantity;
          }
        )
        .test(
          "is-maximum-required-quantity-long",
          "maximumRequiredQuantityLong",
          function (value) {
            if (!!value) {
              const valueAsString = value.toString();
              return valueAsString.length <= 6;
            }
            return true;
          }
        ),
      requiredQuantityUnit: Yup.mixed().when(
        ["minimumRequiredQuantity", "maximumRequiredQuantity"],
        {
          is: (minQuantity, maxQuantity) =>
            !!minQuantity && !!maxQuantity,
          then: (schema) => schema.required("orderUnitRequired"),
          otherwise: (schema) => schema.nullable()
        }
      ),
      requiredQuantityOtherUnit: Yup.string().when(
        "requiredQuantityUnit",
        {
          is: (orderUnitOtherValue) =>
            orderUnitOtherValue?.label === "Other",
          then: (schema) => schema.required("orderUnitOtherRequired"),
          otherwise: (schema) => schema.nullable()
        }
      ),
      minimumBudgetRange: Yup.number()
        .required("minimumBudgetRangeRequired")
        .min(1, "minimumBudgetRangeLow")
        .integer("invalidMinimumBudgetRange")
        .positive("invalidMinimumBudgetRange")
        .test(
          "is-maximum-required-quantity-long",
          "minimumBudgetRangeLong",
          function (value) {
            if (!!value) {
              const valueAsString = value.toString();
              return valueAsString.length <= 6;
            }
            return true;
          }
        ),
      maximumBudgetRange: Yup.number()
        .required("maximumBudgetRangeRequired")
        .integer("invalidMaximumBudgetRange")
        .positive("invalidMaximumBudgetRange")
        .test(
          "is-smaller",
          "maximumOrderQuantitySmaller",
          function (value) {
            const { minimumBudgetRange } = this.parent;
            if (!minimumBudgetRange) {
              return true;
            }
            return value > minimumBudgetRange;
          }
        )
        .test(
          "is-maximum-required-quantity-long",
          "maximumBudgetRangeLong",
          function (value) {
            if (!!value) {
              const valueAsString = value.toString();
              return valueAsString.length <= 6;
            }
            return true;
          }
        )
    }),
    onSubmit: async ({
      requestTitle,
      requestDescription,
      categories,
      minimumRequiredQuantity,
      maximumRequiredQuantity,
      requiredQuantityUnit,
      requiredQuantityOtherUnit,
      minimumBudgetRange,
      maximumBudgetRange
    }) => {
      const requestData = {
        Id: id ?? 0,
        UserId: loggedInUserId,
        Title: requestTitle.trim(),
        Details: requestDescription.richText.trim(),
        QuantityFrom: minimumRequiredQuantity,
        QuantityTo: maximumRequiredQuantity,
        QuantityUnitId: requiredQuantityUnit.id,
        OtherQuantityName: requiredQuantityOtherUnit.trim(),
        PriceFrom: minimumBudgetRange,
        PriceTo: maximumBudgetRange,
        Categories: !!categories.length
          ? categories.map((category) => category.id)
          : [],
        CurrencyId: userCurrencyId
      };
      if (id) {
        if (isDifferentCurrencyProductRequest) {
          setEditedProductRequestDetails({
            requestData,
            productRequestCurrency:
              importerProductRequestDetails.CurrencyId
          });
          setIsChangeProductRequestCurrencyModalOpen(true);
        } else {
          requestData.CurrencyId =
            importerProductRequestDetails.CurrencyId;
          dispatch(
            editImporterProductRequestRequest({
              data: {
                ...requestData
              },
              navigate
            })
          );
        }
      } else {
        dispatch(
          addImporterProductRequestRequest({
            data: {
              ...requestData
            },
            navigate
          })
        );
      }
    }
  });

  useEffect(() => {
    if (!!id) {
      dispatch(
        getImporterProductRequestDetailsRequest({
          params: { id }
        })
      );
    }
    return () => {
      dispatch(getImporterProductRequestDetailsResponse({}));
    };
  }, [id]);

  useEffect(() => {
    dispatch(getCategoriesListRequest());
    dispatch(getMOQListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (moqList) {
      setMoqUnitLookupListState([
        ...moqList.map((MoqUnit) => {
          return {
            id: MoqUnit.Id,
            label: MoqUnit.Name
          };
        })
      ]);
    }
    if (categoriesList) {
      setCategoryLookupListState([
        ...categoriesList.map((category) => {
          return {
            id: category.Id,
            label: category.Name
          };
        })
      ]);
    }
  }, [categoriesList, moqList]);

  useEffect(() => {
    if (
      importerProductRequestDetails &&
      Object.keys(importerProductRequestDetails).length
    ) {
      const {
        Title,
        Details,
        QuantityFrom,
        QuantityTo,
        QuantityUnit,
        QuantityUnitId,
        OtherQuantityUnit,
        PriceFrom,
        PriceTo,
        CurrencyId,
        Categories
      } = importerProductRequestDetails;

      const categoriesIdList = Categories.map(
        (category) => category.Id
      );

      setIsDifferentCurrencyProductRequest(
        CurrencyId !== userCurrencyId
      );
      setValues({
        requestTitle: Title,
        requestDescription: {
          pureText: convertRichTextToPlainText(Details),
          richText: Details
        },
        categories: categoriesList
          ?.filter((category) =>
            categoriesIdList?.includes(category.Id)
          )
          .map((unit) => ({
            id: unit.Id,
            label: unit.Name
          })),
        minimumRequiredQuantity: QuantityFrom,
        maximumRequiredQuantity: QuantityTo,
        requiredQuantityUnit: {
          id: QuantityUnitId,
          label: QuantityUnit
        },
        requiredQuantityOtherUnit: OtherQuantityUnit,
        minimumBudgetRange: PriceFrom,
        maximumBudgetRange: PriceTo
      });
    }
  }, [importerProductRequestDetails]);

  const renderProductRequestForm = () => (
    <div className="add-edit-product-request-form-container bg-white w-100 p-5 bg-white border-radius-8">
      <form
        noValidate
        onSubmit={handleSubmit}
        className="add-edit-product-request-form d-flex">
        <div className="data-container col-xl-8 col-lg-7 col-12 pe-lg-5">
          <div className="request-details-block mb-3">
            <div className={`block-title  pb-4 `}>
              <Typography variant="h6">
                {blockLabels.requestDetails}
              </Typography>
            </div>
            <Input
              required
              inputWrapperClass={"product-form-field pb-3"}
              key={inputLabels.requestTitle}
              label={inputLabels.requestTitle}
              labelClassName="pb-2 font-medium  main-text-color"
              placeholder={placeholders.requestTitlePlaceholder}
              onChange={(value) => {
                if (!value || !!value.trim()) {
                  const modifiedValue = value.replace(/\s\s+/g, " ");
                  setFieldTouched("requestTitle");
                  setFieldValue("requestTitle", modifiedValue);
                }
              }}
              value={values.requestTitle}
              isInputHasErr={
                !!(touched.requestTitle && errors.requestTitle)
              }
              errMsg={errors.requestTitle}
              name="requestTitle"
              id="requestTitle"
            />
            <RichTextInput
              required
              key={inputLabels.requestDescription}
              label={inputLabels.requestDescription}
              placeholder={placeholders.requestDescriptionPlaceholder}
              labelClassName="font-medium  main-text-color"
              inputWrapperClass={"py-3"}
              inputClass={`${
                touched.requestDescription &&
                errors.requestDescription &&
                "rich-text-error"
              }`}
              rows={4}
              onChange={(value, pureTextValue) => {
                setFieldTouched("requestDescription");
                setFieldValue("requestDescription", {
                  richText: value,
                  pureText: pureTextValue
                });
              }}
              value={values.requestDescription.richText}
              isInputHasErr={
                !!(
                  touched?.requestDescription &&
                  errors?.requestDescription?.pureText
                )
              }
              errMsg={errors.requestDescription?.pureText}
              name="requestDescription.pureText"
              id="requestDescription.pureText"
            />
            <Select
              isMultipleSelect
              key={inputLabels.categories}
              wrapperClass="company-form-field pt-3"
              labelClassName="pb-1 font-medium"
              label={
                <div>
                  {inputLabels.categories}
                  <span className="text-light-grey ms-1">
                    {optional}
                  </span>
                </div>
              }
              placeholder={placeholders.categoriesPlaceholder}
              options={categoryLookupListState}
              onChange={(value) => {
                setFieldTouched("categories");
                setFieldValue("categories", value);
              }}
              value={values.categories}
              hasError={!!(touched.categories && errors.categories)}
              errMsg={errors.categories}
              id="categories"
              name="categories"
            />
          </div>
          <div className="required-quantity-block my-3">
            <div className={`block-title  pb-4 `}>
              <Typography variant="h6">
                {blockLabels.requiredQuantity}
              </Typography>
            </div>
            <div
              className={`inputs-container justify-content-between d-flex flex-wrap gap-4 align-items-start`}>
              <Input
                inputWrapperClass={`${values.requiredQuantityUnit?.label === "Other" ? " product-form-field " : "product-small-form-field"}`}
                label={inputLabels.from}
                type={"number"}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.requiredQuantityPlaceholder}
                onChange={(value) => {
                  setFieldTouched("minimumRequiredQuantity");
                  setFieldValue("minimumRequiredQuantity", value);
                }}
                value={values.minimumRequiredQuantity}
                isInputHasErr={
                  !!id
                    ? errors.minimumRequiredQuantity
                    : !!(
                        touched.minimumRequiredQuantity &&
                        errors.minimumRequiredQuantity
                      )
                }
                errMsg={errors.minimumRequiredQuantity}
                name="minimumRequiredQuantity"
                id="minimumRequiredQuantity"
              />
              <Input
                inputWrapperClass={`${values.requiredQuantityUnit?.label === "Other" ? " product-form-field " : "product-small-form-field"}`}
                label={inputLabels.to}
                disabled={
                  !values?.maximumRequiredQuantity &&
                  !values?.minimumRequiredQuantity &&
                  !touched.maximumRequiredQuantity
                }
                type={"number"}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.requiredQuantityPlaceholder}
                onChange={(value) => {
                  setFieldTouched("maximumRequiredQuantity");
                  setFieldValue("maximumRequiredQuantity", value);
                }}
                value={values.maximumRequiredQuantity}
                isInputHasErr={
                  !!id
                    ? errors.maximumRequiredQuantity
                    : !!(
                        touched.maximumRequiredQuantity &&
                        errors.maximumRequiredQuantity
                      )
                }
                errMsg={errors.maximumRequiredQuantity}
                name="maximumRequiredQuantity"
                id="maximumRequiredQuantity"
              />
              <Select
                wrapperClass={`${values.requiredQuantityUnit?.label === "Other" ? " product-form-field " : "product-small-form-field"}`}
                labelClassName="pb-1 font-medium"
                disabled={
                  !values.maximumRequiredQuantity &&
                  !values?.requiredQuantityUnit?.label
                }
                label={inputLabels.unit}
                placeholder={placeholders.budgetRangeUnitPlaceholder}
                options={moqUnitLookupListState}
                onChange={(value) => {
                  setFieldTouched("requiredQuantityUnit");
                  setFieldTouched("requiredQuantityOtherUnit", false);
                  setFieldError("requiredQuantityOtherUnit", null);
                  setValues({
                    ...values,
                    requiredQuantityUnit: value ? value : null,
                    requiredQuantityOtherUnit: ""
                  });
                }}
                value={values.requiredQuantityUnit}
                hasError={
                  !!(
                    touched.requiredQuantityUnit &&
                    errors.requiredQuantityUnit
                  )
                }
                errMsg={errors.requiredQuantityUnit}
                id="requiredQuantityUnit"
                name="requiredQuantityUnit"
              />

              {values.requiredQuantityUnit?.label === "Other" && (
                <Input
                  label={inputLabels.unit}
                  inputWrapperClass={`product-form-field`}
                  labelClassName="pb-2 font-medium"
                  placeholder={
                    placeholders.budgetRangeUnitPlaceholder
                  }
                  onChange={(value) => {
                    if (value.length <= 20) {
                      if (!value || !!value.trim()) {
                        const modifiedValue = value.replace(
                          /\s\s+/g,
                          " "
                        );
                        setFieldTouched("requiredQuantityOtherUnit");
                        setFieldValue(
                          "requiredQuantityOtherUnit",
                          modifiedValue
                        );
                      }
                    } else {
                      setFieldError(
                        "requiredQuantityOtherUnit",
                        "orderUnitOtherLong"
                      );
                    }
                  }}
                  value={values.requiredQuantityOtherUnit}
                  isInputHasErr={
                    !!(
                      touched.requiredQuantityOtherUnit &&
                      errors.requiredQuantityOtherUnit
                    )
                  }
                  errMsg={errors.requiredQuantityOtherUnit}
                  id="requiredQuantityOtherUnit"
                  name="requiredQuantityOtherUnit"
                />
              )}
            </div>
          </div>
          <div className="budget-range-block mt-3">
            <div className={`block-title  pb-4 `}>
              <Typography variant="h6">
                {blockLabels.budgetRange}
              </Typography>
            </div>
            <div
              className={`inputs-container justify-content-between d-flex flex-wrap gap-4`}>
              <Input
                inputWrapperClass={"product-form-field"}
                label={inputLabels.from}
                type={"number"}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.budgetRangePlaceholder}
                endAdornment={
                  !!id
                    ? importerProductRequestDetails &&
                      importerProductRequestDetails.CurrencySymbol
                    : userCurrency
                }
                onChange={(value) => {
                  setFieldTouched("minimumBudgetRange");
                  setFieldValue("minimumBudgetRange", value);
                }}
                value={values.minimumBudgetRange}
                isInputHasErr={
                  !!id
                    ? errors.minimumBudgetRange
                    : !!(
                        touched.minimumBudgetRange &&
                        errors.minimumBudgetRange
                      )
                }
                errMsg={errors.minimumBudgetRange}
                name="minimumBudgetRange"
                id="minimumBudgetRange"
              />
              <Input
                inputWrapperClass={"product-form-field"}
                disabled={
                  !values.minimumBudgetRange &&
                  !touched.maximumBudgetRange &&
                  !values.maximumBudgetRange
                }
                endAdornment={
                  !!id
                    ? importerProductRequestDetails &&
                      importerProductRequestDetails.CurrencySymbol
                    : userCurrency
                }
                label={inputLabels.to}
                type={"number"}
                labelClassName="pb-2 font-medium  main-text-color"
                placeholder={placeholders.budgetRangePlaceholder}
                onChange={(value) => {
                  if (value === "") {
                    setFieldValue("maximumBudgetRange", "");
                    setFieldTouched("maximumBudgetRange", false);
                    setFieldError("maximumBudgetRange", null);
                  }
                  setFieldTouched("maximumBudgetRange");
                  setFieldValue("maximumBudgetRange", value);
                }}
                value={values.maximumBudgetRange}
                isInputHasErr={
                  !!id
                    ? errors.maximumBudgetRange
                    : !!(
                        touched.maximumBudgetRange &&
                        errors.maximumBudgetRange
                      )
                }
                errMsg={errors.maximumBudgetRange}
                name="maximumBudgetRange"
                id="maximumBudgetRange"
              />
            </div>
          </div>
        </div>
        <div className="guide-container col-xl-4 col-lg-5 d-lg-block border-radius-8 d-none py-3 px-3">
          <div className="d-flex justify-content-center">
            <img src={addProductRequestGuideMainImg} />
          </div>
          <div className="text-center my-3 mx-xl-5 mx-3">
            <Typography className="title-color fweight-600 fsize-22">
              {guideSection.title}
            </Typography>
            <Typography className="text-light-grey mt-2 fweight-400  fsize-14">
              {guideSection.subtitle}
            </Typography>
          </div>
          <div className="steps-img-container d-flex">
            <div className="circle-img-container pt-3 ps-xl-4 ps-2 col-3">
              <img src={addProductRequestGuideCircles} />
            </div>
            <div className="steps-container ps-4 col-9">
              {renderStepValue(
                guideSection.firstStepTitle,
                guideSection.firstStepSubtitle,
                "first-step pe-4"
              )}

              {renderStepValue(
                guideSection.secondStepTitle,
                guideSection.secondStepSubtitle,
                "second-step pe-4 py-2  w-100"
              )}
              {renderStepValue(
                guideSection.thirdStepTitle,
                guideSection.thirdStepSubtitle,
                "third-step pe-4 pt-xl-2"
              )}
            </div>
          </div>
        </div>
      </form>
      {isChangeProductRequestCurrencyModalOpen && (
        <ProductRequestCurrencyChange
          closeModal={handleCloseModal}
          productRequestInfo={editedProductRequestDetails}
          open={isChangeProductRequestCurrencyModalOpen}
        />
      )}
    </div>
  );

  const renderProductRequestFormButtons = () => (
    <div
      className={`form-buttons-container bg-white w-100  p-4 bg-white border-radius-8 mt-4`}>
      <div
        className={`product-inputs-container d-md-flex justify-content-md-between justify-content-center`}>
        <Button
          outlined
          className={"col-xl-2 col-md-3 col-12"}
          labelClass={`py-1 ps-5 pe-5`}
          label={shared.cancel}
          onClick={() =>
            navigate(ROUTE_PATHS.importerProductRequests)
          }
        />
        <Button
          disabled={!dirty || !isValid}
          type="button"
          onClick={handleSubmit}
          className={"col-xl-2 col-md-3 col-12 mt-md-0 mt-4"}
          labelClass={`py-1 ps-5 pe-5`}
          label={id ? shared.save : shared.submit}
        />
      </div>
    </div>
  );

  return (
    <div className="add-edit-product-request-container">
      <div className="page-title pb-4">
        <Typography variant="h5" className="title-color">
          {id ? editPageTitle : addPageTitle}
        </Typography>
      </div>
      {id
        ? importerProductRequestDetails &&
          !!Object.keys(importerProductRequestDetails).length &&
          renderProductRequestForm()
        : renderProductRequestForm()}
      {id
        ? importerProductRequestDetails &&
          !!Object.keys(importerProductRequestDetails).length &&
          renderProductRequestFormButtons()
        : renderProductRequestFormButtons()}
    </div>
  );
};

export default AddEditProductRequest;
