export const GET_SEARCH_SUGGESTIONS_REQUEST =
  "GET_SEARCH_SUGGESTIONS_REQUEST";

export const GET_SEARCH_SUGGESTIONS_RESPONSE =
  "GET_SEARCH_SUGGESTIONS_RESPONSE";

export const GET_ALL_SEARCH_RESULTS_REQUEST =
  "GET_ALL_SEARCH_RESULTS_REQUEST";

export const GET_ALL_SEARCH_RESULTS_RESPONSE =
  "GET_ALL_SEARCH_RESULTS_RESPONSE";

export const GET_SEARCH_PRODUCTS_REQUEST =
  "GET_SEARCH_PRODUCTS_REQUEST";

export const GET_SEARCH_PRODUCTS_RESPONSE =
  "GET_SEARCH_PRODUCTS_RESPONSE";

export const GET_SEARCH_SUB_CATEGORIES_REQUEST =
  "GET_SEARCH_SUB_CATEGORIES_REQUEST";

export const GET_SEARCH_SUB_CATEGORIES_RESPONSE =
  "GET_SEARCH_SUB_CATEGORIES_RESPONSE";

export const GET_SEARCH_EXPORTERS_REQUEST =
  "GET_SEARCH_EXPORTERS_REQUEST";

export const GET_SEARCH_EXPORTERS_RESPONSE =
  "GET_SEARCH_EXPORTERS_RESPONSE";

export const SET_TOTAL_SEARCH_COUNT = "SET_TOTAL_SEARCH_COUNT";
