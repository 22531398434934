import {
  GET_HOME_DETAILS_RESPONSE,
  SEND_SUPPORT_EMAIL_RESPONSE,
  SET_WEBSITE_CURRENCY
} from "./types";

const INITIAL_STATE = {
  homeDetails: {
    CategoryDetails: [],
    ExporterDetails: [],
    LatestProductDetails: [],
    PopularProductDetails: [],
    FeaturedExporterDetails: [],
    SubcriptionPlansDetails: []
  },
  isSupportEmailSent: false,
  websiteCurrency: sessionStorage.getItem("website-currency")
    ? JSON.parse(sessionStorage.getItem("website-currency"))
    : null
};

const home = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOME_DETAILS_RESPONSE:
      return {
        ...state,
        homeDetails: action.payload
      };
    case SEND_SUPPORT_EMAIL_RESPONSE:
      return {
        ...state,
        isSupportEmailSent: action.payload
      };
    case SET_WEBSITE_CURRENCY:
      return {
        ...state,
        websiteCurrency: action.payload
      };
    default:
      return state;
  }
};

export default home;
