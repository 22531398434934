import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/verification";
import * as types from "./types";
import * as actions from "./actions";
import { loginResponse } from "../Auth/actions";
import { showErrorMsg } from "../../utils/Helpers";
import store from "../index";
import {
  COMPANY_VERIFICATION_STATUS,
  USER_STATUS_IDS,
  VERIFICATION_METHODS
} from "../../utils/Constants";
import { setUser } from "../../utils/Auth";

function* getVerificationMethodsSaga() {
  try {
    const response = yield call(apis.getVerificationMethods);
    yield put(
      actions.getVerificationMethodsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getStripeClientSecretSaga({ payload }) {
  try {
    const response = yield call(apis.getStripeClientSecret, payload);
    yield put(
      actions.getStripeClientSecretResponse(
        response.data.ClientSecret
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* confirmPaymentIntentSaga({ payload }) {
  try {
    yield call(apis.confirmPaymentIntent, payload);
    const userData = store.getState().auth.login;
    const updatedUserDataWithStatus = {
      ...userData,
      UserStatusId: USER_STATUS_IDS.pendingBusinessVerified
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithStatus));
    yield rememberMe
      ? setUser(updatedUserDataWithStatus, "local")
      : setUser(updatedUserDataWithStatus, "session");
    if (payload.setSearchParams) {
      payload.setSearchParams((searchParams) => {
        searchParams.set("is_payment_confirmed", true);
        searchParams.delete("payment_intent");
        searchParams.delete("payment_intent_client_secret");
        searchParams.delete("redirect_status");
        return searchParams;
      });
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getExgatesBankInfoSaga() {
  try {
    const response = yield call(apis.getExgatesBankInfo);
    yield put(actions.getExgatesBankInfoResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* sendBankTransferRequestSaga({ payload }) {
  try {
    yield call(apis.sendBankTransferRequest, payload);
    const userData = store.getState().auth.login;
    const updatedUserDataWithStatus = {
      ...userData,
      UserStatusId: USER_STATUS_IDS.pendingBusinessVerified
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithStatus));
    yield rememberMe
      ? setUser(updatedUserDataWithStatus, "local")
      : setUser(updatedUserDataWithStatus, "session");
    yield put(actions.setBankTransferSuccess(true));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getCompanyVerificationStatusSaga() {
  try {
    const response = yield call(apis.getCompanyVerificationStatus);
    yield put(
      actions.getCompanyVerificationStatusResponse(
        response.data.Data.StatusId
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* requestOffPlatformVerificationSaga() {
  try {
    yield call(apis.requestOffPlatformVerificaiton);
    yield put(
      actions.getCompanyVerificationStatusResponse(
        COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments
      )
    );
    yield put(actions.setVerificationStep(1));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* requestOffplatformRequiredDocsSaga() {
  try {
    const response = yield call(
      apis.requestOffPlatformRequiredDocuments
    );
    yield put(
      actions.requestOffplatformRequiredDocsResponse(
        response.data.Data
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* uploadOffPlatformRequestedDocumentsSaga({ payload }) {
  try {
    const response = yield call(
      apis.uploadOffplatformRequestedDocuments,
      payload
    );
    if (response) {
      yield put(
        actions.getCompanyVerificationStatusResponse(
          COMPANY_VERIFICATION_STATUS.documentsUploaded
        )
      );
      yield put(actions.setVerificationStep(2));
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editOffPlatformRequestedDocumentsSaga({ payload }) {
  try {
    const response = yield call(
      apis.editOffplatformRequestedDocuments,
      payload
    );
    if (response) {
      yield put(
        actions.getCompanyVerificationStatusResponse(
          COMPANY_VERIFICATION_STATUS.documentsUploaded
        )
      );
      yield put(actions.setVerificationStep(2));
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* requestOffplatformRequestDataSaga() {
  try {
    const response = yield call(apis.requestOffPlatformRequestData);
    yield put(
      actions.requestOffplatformRequestDataResponse(
        response.data.Data
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* confirmOffPlatformPaymentIntentSaga({ payload }) {
  try {
    yield call(apis.confirmOffplatformPaymentIntent, payload);
    const userData = store.getState().auth.login;
    const updatedUserDataWithStatus = {
      ...userData,
      UserStatusId: USER_STATUS_IDS.pendingBusinessVerified
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithStatus));
    yield rememberMe
      ? setUser(updatedUserDataWithStatus, "local")
      : setUser(updatedUserDataWithStatus, "session");
    if (payload.setSearchParams) {
      payload.setSearchParams((searchParams) => {
        searchParams.set("is_payment_confirmed", true);
        searchParams.delete("payment_intent");
        searchParams.delete("payment_intent_client_secret");
        searchParams.delete("redirect_status");
        return searchParams;
      });
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* cancelVerificationRequestSaga() {
  try {
    yield call(apis.cancelVerificationRequest);
    yield put(actions.setVerificationStep(0));
    yield put(
      actions.setSelectedVerificationMethod(
        VERIFICATION_METHODS.offPlatform
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* VerificationSagas() {
  yield takeEvery(
    types.GET_VERIFICATION_METHODS_REQUEST,
    getVerificationMethodsSaga
  );
  yield takeEvery(
    types.GET_STRIPE_CLIENT_SECRET_REQUEST,
    getStripeClientSecretSaga
  );
  yield takeEvery(
    types.CONFIRM_PAYMENT_INTENT_REQUEST,
    confirmPaymentIntentSaga
  );
  yield takeEvery(
    types.GET_EXGATES_BANK_INFO_REQUEST,
    getExgatesBankInfoSaga
  );
  yield takeEvery(
    types.SEND_BANK_TRANSFER_REQUEST,
    sendBankTransferRequestSaga
  );
  yield takeEvery(
    types.REQUEST_OFF_PLATFORM_VERIFICATION,
    requestOffPlatformVerificationSaga
  );
  yield takeEvery(
    types.GET_COMPANY_VERIFICATION_STATUS_REQUEST,
    getCompanyVerificationStatusSaga
  );
  yield takeEvery(
    types.REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_REQUEST,
    requestOffplatformRequiredDocsSaga
  );
  yield takeEvery(
    types.UPLOAD_OFF_PLATFORM_REQUESTED_DOCUMENTS,
    uploadOffPlatformRequestedDocumentsSaga
  );
  yield takeEvery(
    types.EDIT_OFF_PLATFORM_REQUESTED_DOCUMENTS,
    editOffPlatformRequestedDocumentsSaga
  );
  yield takeEvery(
    types.REQUEST_OFF_PLATFORM_REQUEST_DATA_REQUEST,
    requestOffplatformRequestDataSaga
  );
  yield takeEvery(
    types.CONFIRM_OFF_PLATFORM_PAYMENT_INTENT_REQUEST,
    confirmOffPlatformPaymentIntentSaga
  );
  yield takeEvery(
    types.CANCEL_VERIFICATION_REQUEST,
    cancelVerificationRequestSaga
  );
}
