import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

// import backgroundImg from "../../assets/images/vectors/background.png";
import messages from "../../assets/locale/messages";
import coloredLogo from "../../assets/images/logo-colored.png";
import fullLogoColored from "../../assets/images/full-logo-colored.png";
// import authAvatar from "../../assets/images/auth-avatar.png";
import Footer from "../ExporterLayout/Footer";
import NotificationSnackbar from "../NotificationSnackbar";
import { EXPORTER_LAYOUT_DIMENSIONS } from "../../utils/Constants";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./AuthLayout.scss";

const backgroundImg = require("../../assets/images/vectors/background.png");
const authAvatar = require("../../assets/images/auth-avatar.png");

const AuthLayout = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    <div className="auth-layout-container">
      <div className="d-flex main-content">
        <div className="d-flex justify-content-center form-section">
          <div className="component-section border-radius-20 light-box-shadow px-sm-5 px-0 py-md-4 py-0 my-sm-4 my-0 mb-4">
            <div className="desktop-logo-container mb-5">
              <Link to={ROUTE_PATHS.home}>
                <div className="logo pointer">
                  <img src={fullLogoColored} />
                </div>
              </Link>
            </div>
            <Box
              className="layout-card w-100"
              sx={{
                minHeight: "80%"
              }}>
              <div className="mobile-logo-container text-center pt-4 pb-3 px-3 mb-4">
                <Link to={ROUTE_PATHS.home}>
                  <div className="logo mb-3 pointer">
                    <img src={coloredLogo} />
                  </div>
                </Link>
                <h3 className="title-color fsize-20 fweight-500 welcome-title">
                  {shared.auth.message1}
                </h3>
              </div>
              <Box
                className="w-100 d-flex align-items-center"
                sx={{
                  height: "100%"
                }}>
                <Outlet />
              </Box>
            </Box>
          </div>
        </div>
        <div className="gradient-section">
          <div
            className="gradient-bg d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${backgroundImg})`
            }}>
            <div className="content text-center px-3">
              <div className="auth-avatar mb-4">
                <img src={authAvatar} className="w-100" />
              </div>
              <div className="welcome-text">
                <h3 className="main-msg text-white fsize-24 fweight-600 mb-3 welcome-title">
                  {shared.auth.message1}
                </h3>
                <p className="sub-msg text-white fsize-16 mb-0">
                  {shared.auth.message2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerHeight={EXPORTER_LAYOUT_DIMENSIONS.footerHeight}
      />
      <NotificationSnackbar />
    </div>
  );
};

export default AuthLayout;
