import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import DeactivateAccount from "../DeactivateAccount";
import DeleteProfile from "../DeleteProfile";
import DeleteProfileConfirm from "../DeleteProfileConfirm";
import ChangeCurrency from "../ChangeCurrency";
import { downloadPDF, isExporterRole } from "../../../utils/Helpers";
import Switch from "../../../components/Switch";
import { disableEnableProductRequestNotificationsRequest } from "../../../store/Settings/actions";
import {
  PERMISSIONS_IDS,
  hasPermission
} from "../../../utils/Permissions";
import {
  EXGATES_INSTRUCTIONS_PDF,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import VerificationsNotifications from "../../Exporters/Verification/VerificationsNotifications";

const ViewSettings = () => {
  const lang = useSelector((state) => state.locale.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currency = useSelector(
    (state) => state.auth.login.currency.CurrencyCode
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const canRecieveProductRequestNotifications = useSelector(
    (state) => state.auth.login.CanRecieveProductRequestNotifications
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isDeactivateAccountModalOpen, setIsDeactivateModalOpen] =
    useState(false);
  const [isChangeCurrencyModalOpen, setIsChangeCurrencyModalOpen] =
    useState(false);
  const {
    shared,
    settings: {
      viewSettings: {
        pageTitle,
        currentCurrency,
        deleteWarning,
        notificationsWarning,
        blockLabels: {
          currencySettings,
          security,
          accountDeleteAndDeactivate,
          productRequestsNotifications,
          instructionsDocument
        },
        buttons: {
          changeCurrency,
          changePassword,
          deactivateAccount,
          deleteAccount,
          downloadPdf
        }
      }
    }
  } = messages[lang];

  const renderLabelValue = (label, value) => (
    <div className="d-flex align-items-center text-break">
      <div>
        <p className="fsize-13 fweight-400 title-color font-medium field-label">
          {label}
        </p>
        <p className="text-black fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  return (
    <div className="view-settings-container">
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) &&
        isExporterRole(userCurrentRole, userType) && (
          <div className="mb-3">
            <VerificationsNotifications />
          </div>
        )}
      <div className="page-title mb-4 title-color">
        <Typography variant="h5" className="title-color">
          {pageTitle}
        </Typography>
      </div>
      <div className="currency-settings-block bg-white px-3 mb-4 border-radius-10 p-md-4 p-3 col-12">
        <div className="mb-3 block-title title-color">
          <Typography variant="h6">{currencySettings}</Typography>
        </div>
        <div className="d-flex pb-2 block-data flex-column flex-md-row">
          <div>
            {renderLabelValue(currentCurrency, currency || "--")}
          </div>
          {(!isExporterRole(userCurrentRole, userType) ||
            (isExporterRole(userCurrentRole, userType) &&
              hasPermission(
                PERMISSIONS_IDS.currency_management
              ))) && (
            <div className="block-btn mt-3 ms-md-5 mt-md-0">
              <Button
                className={`bg-white px-0 `}
                outlined
                labelClass={"fsize-14 p-1"}
                label={<span className="">{changeCurrency}</span>}
                onClick={() => setIsChangeCurrencyModalOpen(true)}
              />
            </div>
          )}
        </div>
      </div>
      {isExporterRole(userCurrentRole, userType) && (
        <div className="security-block bg-white border-radius-10 px-3 mb-4 p-md-4 p-3 col-12">
          <div className="mb-3 block-title title-color">
            <Typography variant="h6">
              {instructionsDocument}
            </Typography>
          </div>
          <div className="block-btn pb-2 mt-md-0 mt-3">
            <Button
              className={`bg-white px-0 `}
              outlined
              labelClass={"fsize-14 p-1"}
              label={<span>{downloadPdf}</span>}
              onClick={() => {
                downloadPDF({
                  pdfURL: EXGATES_INSTRUCTIONS_PDF,
                  pdfDownloadName: shared.exgatesInstructionsPDF,
                  dispatch
                });
              }}
            />
          </div>
        </div>
      )}
      {isExporterRole(userCurrentRole, userType) &&
        hasPermission(
          PERMISSIONS_IDS.products_requests_notifications
        ) && (
          <div className="activate-deactivate-notifications-block mb-4 bg-white px-3 border-radius-10 p-md-4 p-3 col-12">
            <div className="mb-3 block-title title-color">
              <Typography variant="h6">
                {productRequestsNotifications}
              </Typography>
            </div>
            <div className="mb-3">
              <Switch
                primary
                checked={canRecieveProductRequestNotifications}
                label={
                  canRecieveProductRequestNotifications
                    ? shared.on
                    : shared.off
                }
                handleChange={(isChecked) => {
                  dispatch(
                    disableEnableProductRequestNotificationsRequest({
                      canRecieveProductRequestNotifications: isChecked
                    })
                  );
                }}
              />
            </div>
            <Typography className="text-secondary fsize-13 ps-md-1 pt-2">
              {notificationsWarning}
            </Typography>
          </div>
        )}
      {hasPermission(PERMISSIONS_IDS.change_password) && (
        <div className="security-block bg-white border-radius-10 px-3 mb-4 p-md-4 p-3 col-12">
          <div className="mb-3 block-title title-color">
            <Typography variant="h6">{security}</Typography>
          </div>
          <div className="block-btn pb-2 mt-md-0 mt-3">
            <Button
              className={`bg-white px-0 `}
              outlined
              labelClass={"fsize-14 p-1"}
              label={<span>{changePassword}</span>}
              onClick={() => {
                navigate(ROUTE_PATHS.changePassword);
              }}
            />
          </div>
        </div>
      )}
      {hasPermission(PERMISSIONS_IDS.delete_deactivate_account) && (
        <div className="activate-deactivate-block bg-white px-3 border-radius-10 p-md-4 p-3 col-12">
          <div className="mb-3 block-title title-color">
            <Typography variant="h6">
              {accountDeleteAndDeactivate}
            </Typography>
          </div>
          <div className="buttons-container d-flex gap-md-3 flex-column flex-md-row">
            <div className="block-btn pb-2 mt-md-0 mt-3">
              <Button
                className={`bg-white px-0 `}
                outlined
                labelClass={"fsize-14 p-1"}
                label={<span>{deactivateAccount}</span>}
                onClick={() => {
                  setIsDeactivateModalOpen(true);
                }}
              />
            </div>
            <div className="block-btn pb-2 mt-md-0 mt-2">
              <Button
                className={`bg-white px-0 `}
                outlined
                danger
                labelClass={"fsize-14 p-1"}
                label={<span>{deleteAccount}</span>}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              />
            </div>
          </div>
          <Typography className="text-secondary fsize-13 ps-md-1 pt-2">
            {deleteWarning}
          </Typography>
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteProfile
          handleConfirmDeleteModal={setIsConfirmDeleteModalOpen}
          open={isDeleteModalOpen}
          closeModal={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <DeleteProfileConfirm
          open={isConfirmDeleteModalOpen}
          closeModal={() => {
            setIsConfirmDeleteModalOpen(false);
          }}
        />
      )}
      {isDeactivateAccountModalOpen && (
        <DeactivateAccount
          open={isDeactivateAccountModalOpen}
          closeModal={() => {
            setIsDeactivateModalOpen(false);
          }}
        />
      )}
      {isChangeCurrencyModalOpen && (
        <ChangeCurrency
          open={isChangeCurrencyModalOpen}
          onClose={() => setIsChangeCurrencyModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ViewSettings;
