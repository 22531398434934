export const GET_EXPORTER_DETAILS_REQUEST =
  "GET_EXPORTER_DETAILS_REQUEST";
export const GET_EXPORTER_DETAILS_RESPONSE =
  "GET_EXPORTER_DETAILS_RESPONSE";
export const GET_EXPORTER_CONTACT_INFO_REQUEST =
  "GET_EXPORTER_CONTACT_INFO_REQUEST";
export const GET_EXPORTER_CONTACT_INFO_RESPONSE =
  "GET_EXPORTER_CONTACT_INFO_RESPONSE";
export const GET_EXPORTERS_LIST_REQUEST =
  "GET_EXPORTERS_LIST_REQUEST";
export const GET_EXPORTERS_LIST_RESPONSE =
  "GET_EXPORTERS_LIST_RESPONSE";
