import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_RESPONSE,
  SUB_CATEGORIES_LIST_REQUEST,
  SUB_CATEGORIES_LIST_RESPONSE,
  SPECIFICATIONS_LIST_REQUEST,
  SPECIFICATIONS_LIST_RESPONSE,
  MOQ_LIST_REQUEST,
  MOQ_LIST_RESPONSE,
  COUNTRIES_LIST_REQUEST,
  COUNTRIES_LIST_RESPONSE,
  CURRENCIES_LIST_REQUEST,
  CURRENCIES_LIST_RESPONSE,
  PRODUCT_REQUEST_STATUS_REQUEST,
  PRODUCT_REQUEST_STATUS_RESPONSE
} from "./types";

export const getCategoriesListRequest = () => ({
  type: CATEGORIES_LIST_REQUEST
});

export const getCategoriesListResponse = (payload) => ({
  type: CATEGORIES_LIST_RESPONSE,
  payload
});

export const getSubCategoriesListRequest = (payload) => ({
  type: SUB_CATEGORIES_LIST_REQUEST,
  payload
});

export const getSubCategoriesListsResponse = (payload) => ({
  type: SUB_CATEGORIES_LIST_RESPONSE,
  payload
});

export const getMOQListRequest = () => ({
  type: MOQ_LIST_REQUEST
});

export const getMOQListResponse = (payload) => ({
  type: MOQ_LIST_RESPONSE,
  payload
});

export const getSpecificationsListRequest = (payload) => ({
  type: SPECIFICATIONS_LIST_REQUEST,
  payload
});

export const getSpecificationsListsResponse = (payload) => ({
  type: SPECIFICATIONS_LIST_RESPONSE,
  payload
});

export const getCountriesListRequest = () => ({
  type: COUNTRIES_LIST_REQUEST
});

export const getCountriesListsResponse = (payload) => ({
  type: COUNTRIES_LIST_RESPONSE,
  payload
});

export const getCurrenciesListRequest = () => ({
  type: CURRENCIES_LIST_REQUEST
});

export const getCurrenciesListResponse = (payload) => ({
  type: CURRENCIES_LIST_RESPONSE,
  payload
});
export const getProductRequestStatusRequest = () => ({
  type: PRODUCT_REQUEST_STATUS_REQUEST
});

export const getProductRequestStatusResponse = (payload) => ({
  type: PRODUCT_REQUEST_STATUS_RESPONSE,
  payload
});
