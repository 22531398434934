import PropTypes from "prop-types";

export const NotificationsIcon = ({ hasUnseen, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="21"
    fill="none"
    viewBox="0 0 19 21"
    {...props}>
    <path
      fill="#fff"
      d="M17.314 15.095l-1.871-3.455V8.042a6.522 6.522 0 00-6.515-6.514 6.522 6.522 0 00-6.514 6.514v3.598L.542 15.095a1.029 1.029 0 00.905 1.519h3.725a3.772 3.772 0 107.511 0h3.727a1.029 1.029 0 00.904-1.519zm-5.986 1.861a2.4 2.4 0 11-4.775-.342h4.75c.017.113.025.228.025.342zm-9.306-1.714l1.763-3.255V8.042a5.143 5.143 0 0110.286 0v3.945l1.763 3.255H2.022z"></path>
    {hasUnseen && (
      <circle cx="14.418" cy="5.128" r="4.4" fill="#D32F2F"></circle>
    )}
  </svg>
);

NotificationsIcon.propTypes = {
  hasUnseen: PropTypes.bool
};
