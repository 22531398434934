import {
  ADD_SUB_ACCOUNT_REQUEST,
  DEACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
  EDIT_SUB_ACCOUNT_PROFILE_REQUEST,
  GET_SUB_ACCOUNT_PROFILE_REQUEST,
  GET_SUB_ACCOUNT_PROFILE_RESPONSE,
  GET_SUB_ACCOUNTS_COUNT_REQUEST,
  GET_SUB_ACCOUNTS_COUNT_RESPONSE,
  GET_SUB_ACCOUNTS_LIST_REQUEST,
  GET_SUB_ACCOUNTS_LIST_RESPONSE,
  REACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
  SET_SUB_ACCOUNTS_IS_NEED_EDIT
} from "./types";

export const getSubAccountsListRequest = (payload) => ({
  type: GET_SUB_ACCOUNTS_LIST_REQUEST,
  payload
});
export const getSubAccountsListResponse = (payload) => ({
  type: GET_SUB_ACCOUNTS_LIST_RESPONSE,
  payload
});

export const getSubAccountsCountRequest = () => ({
  type: GET_SUB_ACCOUNTS_COUNT_REQUEST
});
export const getSubAccountsCountResponse = (payload) => ({
  type: GET_SUB_ACCOUNTS_COUNT_RESPONSE,
  payload
});
export const setSubAccountsIsNeedEdit = (payload) => ({
  type: SET_SUB_ACCOUNTS_IS_NEED_EDIT,
  payload
});

export const addSubAccountRequest = (payload) => ({
  type: ADD_SUB_ACCOUNT_REQUEST,
  payload
});

export const getSubAccountProfileRequest = (payload) => ({
  type: GET_SUB_ACCOUNT_PROFILE_REQUEST,
  payload
});

export const getSubAccountProfileResponse = (payload) => ({
  type: GET_SUB_ACCOUNT_PROFILE_RESPONSE,
  payload
});

export const editSubAccountProfileRequest = (payload) => ({
  type: EDIT_SUB_ACCOUNT_PROFILE_REQUEST,
  payload
});

export const reactivateSubAccountProfileRequest = (payload) => ({
  type: REACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
  payload
});

export const deactivateSubAccountProfileRequest = (payload) => ({
  type: DEACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST,
  payload
});
