import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  BANNER_COLORS,
  PRODUCT_STATUS,
  PRODUCT_STATUS_WITH_ID,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import { ChipInfoIcon } from "../../../utils/Icons";
import { formatDate, getUserCurrency } from "../../../utils/Helpers";
import messages from "../../../assets/locale/messages";
import noProductImage from "../../../assets/images/vectors/placeholders/noProductImagePlacholder.svg";
import noProductsImage from "../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import Pagination from "../../../components/Pagination";
import Filters from "../../../components/Filters";
import Button from "../../../components/Button";
import SearchInput from "../../../components/SearchInput";
import EmptyState from "../../../components/EmptyState";
import Table from "../../../components/Table";
import ListFilter from "../../../components/Filters/ListFilter";
import NumberRangeFilter from "../../../components/Filters/NumberRangeFilter";
import TruncateText from "../../../components/TruncateText";
import Switch from "../../../components/Switch";
import DateRangeFilter from "../../../components/Filters/DateRangeFilter";
import Alert from "../../../components/Alert";
import { getProductsListRequest } from "../../../store/Products/actions";
import {
  getSubCategoriesListRequest,
  getCategoriesListRequest
} from "../../../store/Lookups/actions";
import PublishProduct from "./PublishProduct";
import UnpublishProduct from "./UnpublishProduct";
import VerificationsNotifications from "../Verification/VerificationsNotifications";
import "./Products.scss";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const meta = useSelector((state) => state.products.meta);
  const productsList = useSelector(
    (state) => state.products.productsList
  );
  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const subCategoriesList = useSelector(
    (state) => state.lookups.subCategoriesList
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userId = useSelector((state) => state.auth.login?.Id);
  const isSubscriptionDelayed = !!localStorage.getItem(
    `${userId}-delayed-automatic-subscription`
  );

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [subCategoryFilterValue, setSubCategoryFilterValue] =
    useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState([]);
  const [pricesFilterValue, setPricesFilterValue] = useState({
    from: null,
    to: null
  });
  const [priceFilterError, setPriceFilterError] = useState();
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState({
    from: null,
    to: null
  });
  const [clearFilters, setClearFilters] = useState(false);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [productsListState, setProductsListState] = useState([]);
  const [paramsObject, setParamsObject] = useState({
    Category_ids: [],
    SubCategory_ids: [],
    status_ids: [],
    Price_range_from: null,
    Price_range_to: null,
    date_range_from: null,
    date_range_to: null
  });

  const [publishedProductDetails, setPublishedProductDetails] =
    useState({});
  const [publishProductModalOpen, setPublishProductModalOpen] =
    useState(false);
  const [unpublishedProductDetails, setUnPublishedProductDetails] =
    useState({});
  const [unpublishProductModalOpen, setUnpublishProductModalOpen] =
    useState(false);

  const handlePublishProduct = (id, name, status) => {
    setPublishedProductDetails({
      id,
      name,
      status,
      src: ROUTE_PATHS.products,
      params: {
        search_value: searchValue,
        page,
        ...paramsObject
      }
    });
    setPublishProductModalOpen(true);
  };

  const handleUnpublishProduct = (id, name, status, IsEdit) => {
    setUnPublishedProductDetails({
      id,
      name,
      status,
      IsEdit,
      src: ROUTE_PATHS.products,
      params: {
        search_value: searchValue,
        page,
        ...paramsObject
      }
    });
    setUnpublishProductModalOpen(true);
  };

  const handleCloseModal = () => {
    setPublishProductModalOpen(false);
    setPublishedProductDetails({});
    setUnpublishProductModalOpen(false);
    setUnPublishedProductDetails({});
  };

  useEffect(() => {
    if (clearFilters) {
      setCategoryFilterValue([]);
      setSubCategoryFilterValue([]);
      setStatusFilterValue([]);
      setPricesFilterValue({ from: "", to: "" });
      setDateRangeFilterValue({ from: null, to: null });
      setParamsObject({
        Category_ids: [],
        SubCategory_ids: [],
        status_ids: [],
        Price_range_from: null,
        Price_range_to: null,
        date_range_from: null,
        date_range_to: null
      });
      setTimeout(() => {
        setClearFilters(false);
      }, [500]);
    }
  }, [clearFilters]);

  useEffect(() => {
    dispatch(getCategoriesListRequest());
    dispatch(getSubCategoriesListRequest());
  }, []);

  useEffect(() => {
    dispatch(
      getSubCategoriesListRequest({
        id: categoryFilterValue
      })
    );
  }, [categoryFilterValue]);

  const getProducts = () => {
    dispatch(
      getProductsListRequest({
        params: {
          search_value: searchValue,
          page,
          ...paramsObject
        }
      })
    );
  };

  useEffect(() => {
    getProducts();
  }, [page, searchValue]);

  useEffect(() => {
    page > 1 ? setPage(1) : getProducts();
  }, [paramsObject]);

  useEffect(() => {
    if (isApplyFilters) {
      setParamsObject({
        Category_ids: categoryFilterValue,
        SubCategory_ids: subCategoryFilterValue,
        status_ids: statusFilterValue,
        Price_range_from: pricesFilterValue?.from || null,
        Price_range_to: pricesFilterValue?.to || null,
        date_range_from: dateRangeFilterValue.from || null,
        date_range_to: dateRangeFilterValue.to || null
      });
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    setProductsListState([...productsList]);
  }, [productsList]);

  useEffect(() => {
    const tempProductsList = productsListState.map((product) => {
      if (product && Object.keys(product).length !== 0) {
        const tempStatus = Object.values(PRODUCT_STATUS_WITH_ID).find(
          (status) => {
            return (
              status.ProductStatusId === product?.ProductStatusId
            );
          }
        );

        if (
          tempStatus &&
          Object.keys(tempStatus).length !== 0 &&
          (tempStatus?.ProductStatusId === 4 ||
            tempStatus?.ProductStatusId === 2 ||
            tempStatus?.ProductStatusId === 1 ||
            tempStatus?.ProductStatusId === 7) &&
          product?.IsEdit
        ) {
          product.ProductStatusName =
            PRODUCT_STATUS_WITH_ID.edited.ProductStatusName;
          if (tempStatus?.ProductStatusId === 1) {
            product.isPendingEdit = true;
          }
        }
      }
      return product;
    });

    setProductsListState([...tempProductsList]);
  }, [productsListState]);

  const handleAddProduct = () => {
    navigate(ROUTE_PATHS.addProduct);
  };

  const handleStatusName = (ProductStatusName) => {
    let chipClass;
    switch (ProductStatusName) {
      case PRODUCT_STATUS_WITH_ID.published.ProductStatusName: {
        chipClass = "green-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.edited.ProductStatusName: {
        chipClass = "blue-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.pending.ProductStatusName: {
        chipClass = "orange-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.draft.ProductStatusName: {
        chipClass = "yellow-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.rejected.ProductStatusName: {
        chipClass = "red-chip";
        break;
      }
      default:
        chipClass = "";
    }
    return chipClass;
  };

  const {
    shared,
    products: {
      listProducts: {
        listPageTitle,
        productsCount,
        addMoreBtn,
        searchPlaceholder,
        noProductsPlaceholder,
        noSearchFilterResults,
        subscribeWarningText,
        subscribeWarningHighlightedText,
        noSubcategoriesPlaceholder,
        list: {
          product,
          category,
          subCategory,
          publishDate,
          status,
          action
        }
      }
    }
  } = messages[lang];

  const statusList = [
    {
      Id: PRODUCT_STATUS_WITH_ID.pending.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.pending.ProductStatusName
    },
    {
      Id: PRODUCT_STATUS_WITH_ID.published.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.published.ProductStatusName
    },
    {
      Id: PRODUCT_STATUS_WITH_ID.unpublished.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.unpublished.ProductStatusName
    },
    {
      Id: PRODUCT_STATUS_WITH_ID.draft.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.draft.ProductStatusName
    },
    {
      Id: PRODUCT_STATUS_WITH_ID.rejected.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.rejected.ProductStatusName
    },
    {
      Id: PRODUCT_STATUS_WITH_ID.edited.ProductStatusId,
      Name: PRODUCT_STATUS_WITH_ID.edited.ProductStatusName
    }
  ];

  const onImageError = (e) => {
    e.target.src = noProductImage;
  };

  const renderProductsTableRow = (product) => {
    const chipClass = handleStatusName(product.ProductStatusName);
    return (
      <TableRow key={product.Id}>
        <TableCell>
          <div className="d-flex align-items-center gap-3">
            <img
              src={product.DefaultImage || noProductImage}
              onError={onImageError}
              alt="productImage"
              className="product-img"
            />
            <div
              className="pointer"
              onClick={() => {
                navigate(
                  ROUTE_PATHS.viewProduct.replace(":id", product.Id)
                );
              }}>
              <TruncateText length={15} text={product.Name || "__"} />
            </div>
          </div>
        </TableCell>
        <TableCell>
          <TruncateText
            length={15}
            text={product.CategoryName || "__"}
          />
        </TableCell>
        <TableCell>
          <TruncateText
            length={15}
            text={product.SubCategoryName || "__"}
          />
        </TableCell>
        <TableCell>
          <div className="ps-3">
            {product.PublishedUnPublishedDate
              ? formatDate({ date: product.PublishedUnPublishedDate })
              : "__"}
          </div>
        </TableCell>
        <TableCell>
          <Chip
            label={product.ProductStatusName}
            className={` border-radius-8 fw-bold ${chipClass}`}
            sx={{ width: "115px", fontWeight: "semibold" }}
          />
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-center">
            <Switch
              checked={
                product.ProductStatusName === PRODUCT_STATUS.published
              }
              disabled={
                product.ProductStatusName ===
                  PRODUCT_STATUS.pending ||
                product.ProductStatusName ===
                  PRODUCT_STATUS.rejected ||
                (product?.isPendingEdit &&
                  product?.isPendingEdit === true)
              }
              id={product.id}
              handleChange={(isChecked) => {
                if (isChecked) {
                  handlePublishProduct(
                    product.Id,
                    product.Name,
                    product.ProductStatusName
                  );
                } else {
                  handleUnpublishProduct(
                    product.Id,
                    product.Name,
                    product.ProductStatusId,
                    product.IsEdit
                  );
                }
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const FilterContent = () => {
    return (
      <div className="filter-content-container h-100">
        <div className="border-bottom py-3 px-md-5 px-3">
          <ListFilter
            list={categoriesList}
            label={shared.filtersDrawerLabels.categories}
            name="Category"
            selectedOptionsIds={categoryFilterValue}
            setSelectedOptionsIds={setCategoryFilterValue}
            showSearchBar={true}
            clearFilters={clearFilters}
            collabsible
            isApplyFilters={isApplyFilters}
            searchPlaceholder={
              shared.filtersDrawerLabels.categoriesSearchBar
            }
            filterModalOpen={filterModalOpen}
          />
        </div>
        <div className="border-bottom py-3 px-md-5 px-3">
          <ListFilter
            list={subCategoriesList}
            label={shared.filtersDrawerLabels.subCategories}
            name="subCategories"
            selectedOptionsIds={subCategoryFilterValue}
            setSelectedOptionsIds={setSubCategoryFilterValue}
            searchPlaceholder={
              shared.filtersDrawerLabels.subCategoriesSearchBar
            }
            placeholder={
              !!categoryFilterValue.length
                ? noSubcategoriesPlaceholder
                : ""
            }
            showSearchBar={true}
            clearFilters={clearFilters}
            collabsible
            isApplyFilters={isApplyFilters}
            filterModalOpen={filterModalOpen}
          />
        </div>
        <div className="border-bottom py-3 px-md-5 px-3">
          <ListFilter
            list={statusList}
            label={shared.filtersDrawerLabels.status}
            name="Status"
            selectedOptionsIds={statusFilterValue}
            setSelectedOptionsIds={setStatusFilterValue}
            showSearchBar={false}
            clearFilters={clearFilters}
            collabsible
            isApplyFilters={isApplyFilters}
            filterModalOpen={filterModalOpen}
          />
        </div>
        <div className="number-range-filter-container border-bottom py-3 px-md-5 px-3">
          <NumberRangeFilter
            name="price"
            label={shared.filtersDrawerLabels.priceRange}
            endAdorment={getUserCurrency()?.symbol}
            clearFilters={clearFilters}
            hasConfirmBtn={false}
            collabsible
            value={pricesFilterValue}
            setValue={setPricesFilterValue}
            hasError={priceFilterError}
            setHasError={setPriceFilterError}
          />
        </div>
        <div className="number-range-filter-container pt-4 pb-5 px-md-5 px-3">
          <DateRangeFilter
            name="Date"
            label={shared.filtersDrawerLabels.dateRange}
            onRequestFiltering={setDateRangeFilterValue}
            clearFilters={clearFilters}
            collabsible
            selectedDateFrom={dateRangeFilterValue?.from}
            selectedDateTo={dateRangeFilterValue?.to}
            addBottomMargin
          />
        </div>
      </div>
    );
  };

  const renderSubscribeBanner = () => (
    <div className="rejected-product-warning-container">
      <Typography
        component={"span"}
        style={{ whiteSpace: "normal" }}
        className="d-flex align-items-center gap-2 fsize-16 fweight-500 chip-text">
        <div className="icon-text-container d-flex align-items-center">
          {subscribeWarningText}
        </div>
        <Link to={ROUTE_PATHS.pricingDetails}>
          <span className="highlighted-text fw-bold pointer text-underline subscribe-text text-center">
            {subscribeWarningHighlightedText}
          </span>
        </Link>
      </Typography>
    </div>
  );

  return (
    <div className="products-list">
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) && (
        <div className="mb-3">
          <VerificationsNotifications />
        </div>
      )}
      <div className="page-title pb-4 d-flex justify-content-between align-items-center">
        <div className="title-count-container d-flex justify-content-center align-items-center">
          <div>
            <Typography variant="h5" className="title-color">
              {listPageTitle}
            </Typography>
          </div>
          {
            <div className="block-title text-secondary ps-1 ">
              <Typography>{`(${meta?.count || "0"} ${productsCount})`}</Typography>
            </div>
          }
        </div>
        <div className="title-buttons-container d-flex justify-content-center gap-3 align-items-center">
          <Filters
            handleClearFilters={setClearFilters}
            handleApplyFilters={setIsApplyFilters}
            FilterContent={FilterContent()}
            disableApplyBtn={priceFilterError}
            setIsModalOpen={(isOpen) => setFilterModalOpen(isOpen)}
          />
          <Button
            labelClass=" text-white"
            label={
              <span className="d-flex align-items-center">
                <Add fontSize="medium" />
                <span className="ps-1 ">{addMoreBtn}</span>
              </span>
            }
            onClick={handleAddProduct}
          />
        </div>
      </div>
      {[
        USER_STATUS_IDS.businessVerified,
        USER_STATUS_IDS.subscriptionExpired
      ].includes(userStatusId) &&
        !isSubscriptionDelayed && (
          <div className="rejection-reason-container mb-3">
            <Alert
              type="warning"
              content={renderSubscribeBanner()}
              icon={
                <ChipInfoIcon
                  color={BANNER_COLORS.warning}
                  style={{ width: "1.5rem" }}
                  className="ChipInfoIcon"
                />
              }
            />
          </div>
        )}
      <div className="products-table-container bg-white d-flex flex-column w-100  p-4 pb-0 px-0 bg-white border-radius-8">
        <div className="table-header d-flex px-4 align-items-center w-100 mb-4">
          <SearchInput
            name="ProductSearch"
            id="ProductSearch"
            value={searchValue}
            inputClass="border-radius-8"
            inputWrapperClass="search-input-wrapper search-input-wrapper-width"
            placeholder={searchPlaceholder}
            onChange={(value) => {
              setPage(1);
              setSearchValue(value);
            }}
          />
        </div>
        <div className="table-content">
          <div>
            {productsListState?.length ? (
              <Table
                className={"px-0"}
                headlines={[
                  product,
                  category,
                  subCategory,
                  publishDate,
                  status,
                  action
                ]}
                rows={
                  !isLoading &&
                  productsListState &&
                  productsListState.map((product) =>
                    renderProductsTableRow(product)
                  )
                }
              />
            ) : (
              <div className="border-top">
                {!isLoading && (
                  <EmptyState
                    subTitle={
                      (!!searchValue ||
                        !!paramsObject.Category_ids.length ||
                        !!paramsObject.SubCategory_ids.length ||
                        !!paramsObject.status_ids.length ||
                        !!paramsObject.Price_range_from ||
                        !!paramsObject.Price_range_to ||
                        !!paramsObject.date_range_from ||
                        !!paramsObject.date_range_to) &&
                      !productsList.length
                        ? noSearchFilterResults
                        : noProductsPlaceholder
                    }
                    showActionButton={
                      !searchValue &&
                      !paramsObject.Category_ids.length &&
                      !paramsObject.SubCategory_ids.length &&
                      !paramsObject.status_ids.length &&
                      !paramsObject.Price_range_from &&
                      !paramsObject.Price_range_to &&
                      !paramsObject.date_range_from &&
                      !paramsObject.date_range_to &&
                      productsList.length === 0
                    }
                    buttonLabel={
                      <span className="d-flex align-items-center">
                        <Add fontSize="medium" />
                        <span className="ps-1 ">{addMoreBtn}</span>
                      </span>
                    }
                    onButtonClick={handleAddProduct}
                    buttonClassName="empty-state-create-button border-radius-8"
                    imgSrc={noProductsImage}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="Pagination-container d-flex justify-content-center py-3 mt-2">
          {!isLoading && productsList.length > 0 && (
            <Pagination
              count={meta?.pages}
              page={page || meta.currentPage}
              handleChange={(page) => {
                setPage(page);
              }}
              defaultPage={1}
            />
          )}
        </div>
      </div>
      {publishProductModalOpen && (
        <PublishProduct
          open={publishProductModalOpen}
          closeModal={handleCloseModal}
          productInfo={publishedProductDetails}
        />
      )}
      {unpublishProductModalOpen && (
        <UnpublishProduct
          open={unpublishProductModalOpen}
          closeModal={handleCloseModal}
          productInfo={unpublishedProductDetails}
        />
      )}
    </div>
  );
};

export default Products;
