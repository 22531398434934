import { ExpandMore } from "@mui/icons-material";
import { AppBar as MuiAppBar, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import whiteLogo from "../../../assets/images/white-logo.svg";
import messages from "../../../assets/locale/messages";
import Button from "../../Button";
import UserProfileButton from "../../UserDataDropDownBtn";
import NotificationsButton from "../../NotificationsBtn";
import CategoryDropdown from "../CategoryDropdown";
import MainSearch from "../MainSearch";
import ChangeCurrency from "../ChangeCurrency";
import { getCategoriesListRequest } from "../../../store/Lookups/actions";
import { USER_TYPES_IDS } from "../../../utils/Constants";
import {
  isExporterRole,
  setCurrentRole
} from "../../../utils/Helpers";
import {
  MenuIcon,
  MyStoreIcon,
  ProfileIcon,
  StayTunedIcon
} from "../../../utils/Icons";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  PERMISSIONS_IDS,
  hasPermission
} from "../../../utils/Permissions";
import { isUserForcedLogoutRequest } from "../../../store/Auth/actions";
import { getDeviceId } from "../../../utils/Auth";
import { getImporterProductRequestDetailsResponse } from "../../../store/ProductRequests/actions";
import "./Navbar.scss";

const Navbar = ({ onHeightChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navbarRef = useRef(null);

  const lang = useSelector((state) => state.locale.lang);
  const {
    home: { navbar },
    shared
  } = messages[lang];

  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const id = useSelector((state) => state.auth.login?.Id);
  const [categoriesListState, setCategoriesListState] = useState([]);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const navList = [
    { label: navbar.home, link: ROUTE_PATHS.home },
    { label: navbar.exporters, link: ROUTE_PATHS.exporters },
    {
      label: navbar.productRequests,
      link: ROUTE_PATHS.websiteProductRequests
    },
    userType === USER_TYPES_IDS.importer ||
    (userType === USER_TYPES_IDS.exporter &&
      !hasPermission(PERMISSIONS_IDS.subscription_management))
      ? null
      : {
          label: navbar.pricing,
          link: ROUTE_PATHS.pricingDetails
        },
    {
      label: navbar.aboutUs,
      link: ROUTE_PATHS.aboutUs
    },
    {
      label: navbar.contactUs,
      link: ROUTE_PATHS.contactUs
    }
  ];

  // const handleLogout = () => {
  //   dispatch(
  //     logoutRequest({
  //       device_id: getDeviceId(),
  //       navigate
  //     })
  //   );
  // };
  const userMenuList = [
    {
      icon: <MyStoreIcon />,
      label: isExporterRole(userCurrentRole, userType)
        ? shared.myStore
        : shared.myDashboard,
      onClick: () => {
        isExporterRole(userCurrentRole, userType)
          ? navigate(
              hasPermission(
                PERMISSIONS_IDS.company_profile_management
              )
                ? ROUTE_PATHS.companyProfile
                : ROUTE_PATHS.products
            )
          : navigate(ROUTE_PATHS.importerProductRequests);
      }
    }
  ];

  useEffect(() => {
    if (isAuth) {
      dispatch(
        isUserForcedLogoutRequest({
          device_id: getDeviceId(),
          navigate,
          params: {
            id,
            isEmployee: false
          }
        })
      );
    }
  }, [location]);

  useEffect(() => {
    dispatch(getCategoriesListRequest());
  }, []);

  useEffect(() => {
    setCategoriesListState([
      ...categoriesList.map(({ Name, Id }) => ({
        label: Name,
        link: ROUTE_PATHS.categoryDetails.replace(":id", Id)
      }))
    ]);
  }, [categoriesList]);

  useEffect(() => {
    const updateHeight = () => {
      if (navbarRef.current) {
        const { height } = navbarRef.current.getBoundingClientRect();
        onHeightChange(height);
      }
    };
    const resizeObserver = new ResizeObserver(updateHeight);
    if (navbarRef.current) {
      resizeObserver.observe(navbarRef.current);
    }
    return () => {
      if (navbarRef.current) {
        resizeObserver.unobserve(navbarRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [onHeightChange]);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobileDevice(width <= 426);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="home-navbar-container primary-bg">
      <MuiAppBar
        position={isMobileDevice ? "relative" : "fixed"}
        ref={navbarRef}>
        <div className="under-development-disclaimer faint-blue-bg d-flex justify-content-center py-2 px-4 px-md-0">
          <div className="d-flex align-items-center flex-column flex-md-row">
            <p className="mb-0 text-color fsize-15 width-fit-content text-center">
              {navbar.underDevelopmentDisclaimer}
            </p>
            <p className="mb-0 ms-1 text-color fsize-15 width-fit-content fweight-600 position-relative">
              {navbar.stayTuned}
              <StayTunedIcon
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-18px"
                }}
              />
            </p>
          </div>
        </div>
        <Toolbar>
          <div className="navbar-info-container w-100 px-xl-4 px-lg-2 px-0 py-3 container-xxl position-relative">
            <div className="top-section row mb-2">
              <div className="col-lg-2 col-md-4 col-sm-3 col-12 mb-2 mb-lg-0 order-1 d-flex d-md-block align-items-center justify-content-between">
                <div className="logo-container">
                  {location.pathname === ROUTE_PATHS.home ? (
                    <HashLink
                      to={"#top"}
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth"
                        })
                      }>
                      <img
                        src={whiteLogo}
                        alt="Logo"
                        className="pointer w-100"
                        onClick={() => navigate(ROUTE_PATHS.home)}
                      />
                    </HashLink>
                  ) : (
                    <Link to={ROUTE_PATHS.home}>
                      <img
                        src={whiteLogo}
                        alt="Logo"
                        className="pointer w-100"
                      />
                    </Link>
                  )}
                </div>
                {isAuth ? (
                  <UserProfileButton
                    parentMenuList={userMenuList}
                    className={"d-block d-sm-none"}
                  />
                ) : (
                  <Button
                    label={
                      <>
                        <ProfileIcon className="me-2" />
                        {navbar.login}
                      </>
                    }
                    customSpacing="px-3 px-md-4 py-2 py-md-0"
                    faintPrimary
                    onClick={() => navigate(ROUTE_PATHS.login)}
                    className={"d-block d-sm-none"}
                  />
                )}
              </div>
              <div className="col-xl-5 col-lg-4 col-md-8 col-12 mb-2 mb-lg-0 order-3 order-lg-2 ps-xl-0">
                <MainSearch />
              </div>
              <div
                className={`col-xl-5 col-lg-6 col-md-8 col-sm-9 pe-0 d order-2 order-lg-3 mb-2 mb-lg-0`}>
                <div
                  className={`d-flex flex-wrap gap-2 ${isExporterRole() ? "justify-content-sm-end" : "justify-content-sm-end"} `}>
                  {isAuth ? (
                    <>
                      <NotificationsButton />
                      <ChangeCurrency />
                      <UserProfileButton
                        parentMenuList={userMenuList}
                        className={"d-none d-sm-block"}
                      />
                    </>
                  ) : (
                    <>
                      <ChangeCurrency />
                      <Button
                        label={
                          <>
                            <ProfileIcon className="me-2" />
                            {navbar.login}
                          </>
                        }
                        customSpacing="px-2 px-md-3 py-1 py-md-0"
                        faintPrimary
                        onClick={() => navigate(ROUTE_PATHS.login)}
                        className={"d-none d-sm-block"}
                      />
                    </>
                  )}
                  {userType !== USER_TYPES_IDS.exporter && (
                    <Button
                      label={
                        <span className="d-flex align-items-center">
                          {navbar.requestProduct}
                        </span>
                      }
                      reversedcolor
                      onClick={() => {
                        if (userType !== USER_TYPES_IDS.exporter) {
                          if (
                            userType === USER_TYPES_IDS.both &&
                            isExporterRole(userCurrentRole, userType)
                          ) {
                            setCurrentRole();
                          }
                          navigate(
                            ROUTE_PATHS.addImporterProductRequests
                          );
                          dispatch(
                            getImporterProductRequestDetailsResponse(
                              {}
                            )
                          );
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="navigation-section row">
              <div className="col-xl-2 col-lg-2 col-sm-3 col-5 p-0">
                <div className="sub-categories-section">
                  <CategoryDropdown
                    menuList={categoriesList}
                    label={
                      <div className="sub-categories-btn text-white fweight-700 fsize-14 d-flex align-items-center">
                        <MenuIcon />
                        <span className="mx-1">
                          {navbar.categories}
                        </span>
                        <ExpandMore />
                      </div>
                    }
                  />
                </div>
              </div>
              <div
                className={`${userType !== USER_TYPES_IDS.importer ? "col-xl-6 col-xl-5 col-lg-7 col-md-9" : "col-xl-4 col-lg-6 col-md-8"} col-sm-9 col-7 p-0 navigation-list-container`}>
                <div className="navigation-list d-flex align-items-center justify-content-between ms-xl-0 ms-lg-4 ms-0">
                  {navList.map(
                    (navItem, i) =>
                      navItem && (
                        <HashLink
                          key={`nav-item-${i}`}
                          to={navItem.link}
                          scroll={(el) =>
                            el.scrollIntoView({
                              behavior: "smooth"
                            })
                          }
                          className="mx-2 mx-lg-0">
                          <span className="text-white">
                            {navItem.label}
                          </span>
                        </HashLink>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </MuiAppBar>
    </div>
  );
};

export default Navbar;

Navbar.propTypes = {
  onHeightChange: PropTypes.func.isRequired
};
