export const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
    {...props}>
    <g clipPath="url(#clip0_17068_26348)">
      <path
        fill="#0071BC"
        d="M13.343 15.117H5.374a1.76 1.76 0 01-1.758-1.758V5.391c0-.97.789-1.758 1.758-1.758h7.969c.97 0 1.758.788 1.758 1.758v7.968a1.76 1.76 0 01-1.758 1.758zM5.374 4.805a.587.587 0 00-.586.586v7.968c0 .323.263.586.586.586h7.969a.587.587 0 00.586-.586V5.391a.587.587 0 00-.586-.586H5.374zm-2.93 5.625h-.586a.587.587 0 01-.586-.586V1.875c0-.323.263-.586.586-.586h7.97c.322 0 .585.263.585.586v.557h1.172v-.557A1.76 1.76 0 009.827.117H1.858A1.76 1.76 0 00.101 1.875v7.969c0 .97.788 1.758 1.757 1.758h.586V10.43z"></path>
    </g>
    <defs>
      <clipPath id="clip0_17068_26348">
        <path
          fill="#fff"
          d="M0 0H16V16H0z"
          transform="translate(.1 .117)"></path>
      </clipPath>
    </defs>
  </svg>
);
