import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import messages from "../../../assets/locale/messages";
import { forgetPasswordRequest } from "../../../store/Auth/actions";
import { EMAIL_REGEX } from "../../../utils/Patterns";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const { forgetPassword } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_REGEX, "validEmailFormat")
        .required("emailRequired")
    }),
    onSubmit: ({ email }) => {
      dispatch(forgetPasswordRequest({ data: { email }, navigate }));
    }
  });

  return (
    <div className={`forget-password-page w-100`}>
      <div className="w-100 text-center">
        <h3 className="title fweight-700 fsize-32 title-color mb-4 decreased-title-font">
          {forgetPassword.forgetPassword}
        </h3>
        <p className="instructions text-color fsize-16 mb-2">
          {forgetPassword.instructions}
        </p>
      </div>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <Input
          id="email"
          label={forgetPassword.labels.email}
          name="email"
          type="email"
          placeholder={forgetPassword.placeholders.email}
          required
          onChange={(value) => {
            setFieldTouched("email");
            setFieldValue("email", value.trim());
          }}
          value={values.email}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched.email && errors.email)}
          errMsg={errors.email}
        />
        <div className="text-center">
          <Button
            label={forgetPassword.actionBTN}
            type="submit"
            className="mt-4 w-70 submit-btn"
            disabled={!dirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
