import {
  axiosInstanceAdmin,
  axiosInstanceExporters,
  axiosInstanceImporters
} from "./";

const login = async (data) =>
  await axiosInstanceAdmin.post("/api/Authenticate/Login", data);

const logout = async (data) =>
  await axiosInstanceAdmin.delete("/sessions", { data });

const forgetPassword = async ({ data }) =>
  await axiosInstanceAdmin.post(
    "/api/Authenticate/RequestPasswordReset",
    data
  );

const resetPassword = async (data) =>
  await axiosInstanceAdmin.post(
    "/api/Authenticate/ResetPassword",
    data
  );

const registeration = async (data) =>
  await axiosInstanceAdmin.post(
    "/api/Authenticate/Registeration",
    data
  );

const checkCanUserResendVerifyEmail = async (params) =>
  await axiosInstanceAdmin.get(
    "/api/Authenticate/CheckUserCanResendVerficationMail",
    { params }
  );

const resendVerifyEmail = async (params) =>
  await axiosInstanceAdmin.get(
    "/api/Authenticate/ResendVerficationMail",
    { params }
  );

const setNewPassword = async (data) =>
  await axiosInstanceExporters.put("/api/User/ResetPassword", data);

const checkEmailUnique = async (params) =>
  await axiosInstanceAdmin.get(
    "/api/Authenticate/CheckEmailValidations",
    { params }
  );

const isUserForcedLogout = async (params) =>
  await axiosInstanceAdmin.get("/api/Employee/GetForceLogout", {
    params
  });
const modifyIsForceLogout = async (params) =>
  await axiosInstanceAdmin.get("/api/Employee/ForceLogout", {
    params
  });

const contactUs = async (data) =>
  await axiosInstanceImporters.post("/api/User/ContactUs", data);

export {
  login,
  logout,
  forgetPassword,
  resetPassword,
  registeration,
  checkCanUserResendVerifyEmail,
  resendVerifyEmail,
  setNewPassword,
  checkEmailUnique,
  isUserForcedLogout,
  modifyIsForceLogout,
  contactUs
};
