import {
  EDIT_EXPORTER_PROFILE_DETAILS_REQUEST,
  EDIT_IMPORTER_PROFILE_DETAILS_REQUEST,
  GET_EXPORTER_PROFILE_REQUEST,
  GET_EXPORTER_PROFILE_RESPONSE,
  GET_EXPORTER_PROFILE_TRANSACTIONS_REQUEST,
  GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE
} from "./types";

export const getExporterProfileRequest = () => ({
  type: GET_EXPORTER_PROFILE_REQUEST
});

export const getExporterProfileResponse = (payload) => ({
  type: GET_EXPORTER_PROFILE_RESPONSE,
  payload
});

export const getExporterProfileTransactionsRequest = (payload) => ({
  type: GET_EXPORTER_PROFILE_TRANSACTIONS_REQUEST,
  payload
});

export const getExporterProfileTransactionsResponse = (payload) => ({
  type: GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE,
  payload
});

export const editExporterProfileDetailsRequest = (payload) => ({
  type: EDIT_EXPORTER_PROFILE_DETAILS_REQUEST,
  payload
});
export const editImporterProfileDetailsRequest = (payload) => ({
  type: EDIT_IMPORTER_PROFILE_DETAILS_REQUEST,
  payload
});
