const inputsValidations = {
  emailRequired: "Email is required",
  nameRequired: "Name is required",
  enNameRequired: "English name is required",
  arNameRequired: "Arabic name is required",
  pickOneSubCategoryAtLeast:
    "At least one Sub category must be selected",
  specialtyRequired: "Specialty is required",
  roleRequired: "Role is requierd",
  logoRequired: "Logo is requierd",
  bannerRequired: "Banner is requierd",
  passwordRequired: "Password is required",
  tempPasswordRequired: "Temporary password is required",
  oldPasswordRequired: "Old password is required",
  newPasswordRequired: "New password is required",
  validEmailFormat: "Please enter a valid email format",
  uniqeEmailRequired:
    "This email is used before, email should be uniqe",
  confirmNewPasswordRequired: "Confirm new password is required",
  passwordIsNotMatched: "Passwords don’t match, please try again",
  passwordNotFollowREGEX:
    "Password should contain at least 8 characters; mix of letters (upper and lower case), numbers, and symbols",
  invalidPassword: "Invalid Password",
  nameNotFollowREGEX:
    "Name should not contain any special characters",
  englishLettersOnly: "This field only accepts English input",
  arabicLettersOnly: "This field only accepts Arabic input",
  categoryRequired: "Product Category is required",
  subCategoryRequired: "Product Sub Category is required",
  productNameRequired: "Product Name is required",
  invalidProductName:
    "Product Name can not start with number or special character",
  invalidProductHsCode: "Product HS Code must be digits only",
  zeroStartHsCode: "Product HS Code must not start with 0",
  productHsCodeLength: "Product HS Code must be 6-10 digits",
  productHsCodeRequired: "Product HS Code is required",
  productDescriptionRequired: "Product Description is required",
  invalidProductDescription: "Product Description is invalid",
  productImageRequired: "Product Image is required",
  MinimumOrderQuantityLow:
    "Minimium Order Quantity can not be less than 1",
  invalidMinimumOrderQuantity: "Minimium Order Quantity invalid",
  orderUnitRequired: "Order unit is required",
  specificationNameRequired: "Specification Name is required",
  specificationValueLow: "Specification Value can not be less than 1",
  specificationValueHigh:
    "Specification Value can not be more than 500 characters",
  invalidSpecificationValue: "Specification Value invalid",
  specificationValueRequired: "Specification Value is required",
  catalogueRequired: "Catalogue is required",
  SpecificationOtherNameRequired: "Specification Name is required",
  MinimumPriceRangeQuantityLow:
    "Minimium Quantity can not be less than 1",
  invalidMinimumPriceRangeQuantity: "Minimum Quantity is invalid",
  minimumPriceRangeQuantityRequired: "Minimum Quantity is required",
  priceRangeNumberExceeded:
    "Max number of price ranges allowed is 10",
  minimumOrderQuantityLarger:
    "Minimum Order Quantity can not be greater Than Maximum Order Quantity",
  maximumOrderQuantitySmaller:
    "To value can not be less than or equal to the From value",
  maximumPriceRangeQuantityRequired: "Maximum Quantity is required",
  invalidMaximumPriceRangeQuantity: "Maximum Quantity is invalid",
  invalidMaximumOrderQuantity: "Max Order Quantity invalid",
  pricePerUnitRequired: "Price Per Unit is required",
  pricePerUnitLow: "Price can not be less than 1",
  invalidPricePerUnit: "Price is invalid",
  productNameLong: "Product Name can not be more than 100 characters",
  orderUnitOtherLong: "Unit can not be more than 20 characters",
  keywordLong: "Keyword can not be more than 20 characters",
  keywordsNumberExceeded: "Max number of keywords allowed is",
  keywordsLimitExceeded: "Max number of Keywords is exceeded",
  keywordSizeExceeded:
    "Max number of words allowed in keyword is 2 words",
  invalidKeywordCharacter:
    "Keyword can not include special characters",
  orderUnitOtherRequired: "Order Unit required",
  invalidOrderUnitOther:
    "Order Unit Other can not start with number or special character",
  invalidSpecificationOther:
    "Specification Name can not start with number or special character",
  productDescriptionLong:
    "Product Description can not be more than 1000 characters",
  audioFailed:
    "Sorry , audio was not detected correctly please try recording again or write description manually",
  micDisabled:
    "Please allow mic permissions in order to begin recording",
  specificationNameLong:
    "Specification Name can not be more than 100 characters",
  SpecificationOtherNameRepeated:
    "Specification Name can not be repeated more than once",
  specificationValueLong:
    "Specification Value can not be more than 500 characters",
  specificationNumberExceeded:
    "Max number of specifications allowed is 10",
  minimumOrderQuantityLong:
    "Minimum Order Quantity can not be more than 6 digits",
  minimumPriceRangeQuantityLong:
    "Price range from can not be more than 6 digits",
  maximumPriceRangeQuantityLong:
    "Price range to can not be more than 6 digits",
  productImageExceeded: "Max number of Product Images is 15 image",
  productVideoExceeded: "Max number of Product Videos is 1 video",
  uploadVideoSizeError: "Minimum accepted quality for video is 480p",
  uploadVideoTypeError: "Accepted format is mp4 only ",
  overlap:
    "From value can not be smaller than or equal to previous To value",
  emptyFirstRange: "First From value can not be empty",
  invalidToDate: "To Date can not be before from date",
  invalidFileType: "Only .pdf files allowed",
  uploadImgTypeError:
    "Accepted formats are jpg, jpeg, png, and bmp only",
  uploadImgSizeError: "Max Image size is 2MB",
  uploadImgResolutionError:
    "Minimum accepted quality for image is 256px*256px ",
  uploadCatalogueSizeError: "Max Catalogue size is 2MB",
  fromLessThanTo: "From value must be less than To value",
  userTypeRequired: "User Type is required",
  fullNameRequired: "Name is required",
  fullNameMaxLength: "Name can not be more than 50 characters",
  fullNameNoSpecialChars:
    "The input must include letters. Special characters are allowed only if there is at least one letter, but numbers are not allowed",
  specialCharactersNoNumbers:
    "The input must include letters. Special characters are allowed only if there is at least one letter, but numbers are not allowed",
  companyLogoRequired: "Company Logo is required",
  tradeNameRequired: "Company Name is required",
  tradeNameMaxLength:
    "Company name can not be more than 50 characters",
  countryRequired: "Country is required",
  businessTypeRequired: "Business Type is required",
  categoriesRequired: "Categories are required",
  exporterNameRequired: "Exporter Name is required",
  exporterEmailRequired: "Exporter Email is required",
  CategoryNameRequired: "Category name is required",
  categoryNameLong:
    "Category name can not be more than 50 characters",
  CategoryDescriptionRequired: "Category description is required",
  categoryDescriptionLong:
    "Category description can not be more than 500 characters",
  categoryImageRequired: "Category image is required",
  companyDisplayNameRequired: "Company display Name is required",
  companyDisplayNameLong:
    "Company display name can not be more than 50 characters",
  companyLegalNameRequired: "Company legal Name is required",
  companyLegalNameLong:
    "Company legal name can not be more than 50 characters",
  invalidYearEstablished:
    "Year Established must be digits only , no special characters or spaces allowed",
  yearEstablishedLength: "Year Established must be 4 digits",
  yearEstablishedRequired: "Establishing year is required",
  companyIntroductionLong:
    "Company Introduction can not be more than 500 characters",
  invalidExporterName:
    "Exporter Name can not start with number or special character",
  exporterNameLong:
    "Exporter name can not be more than 50 characters",
  invalidExporterEmail: "Exporter Email is invalid",
  invalidCompanyWebsite: "Company Website is invalid",
  isFutureYear: "Establishing year can not be more than current year",
  showcaseSubCategoryRequired: "Showcase subcategory is required",
  showcaseDescriptionLong:
    "Showcase Description can not be more than 500 characters",
  coverPhotoRequired: "Showcase Cover Photo required",
  websiteCoverPhotoRequired: "Showcase Website Cover Photo required",
  pdfAndImgsAllowed:
    "Accepted formats are pdf, jpg, jpeg, png, and bmp only",
  uploadFileSizeError: "Max file size is 2MB",
  bankTransciptRequired: "Please upload a bank transfer transcript",
  showcaseProductsRequired: "Showcase must have minimum of 1 product",
  invalidProfileFullName:
    "The input must include letters. Special characters are allowed only if there is at least one letter, but numbers are not allowed",
  profileFullNameRequired: "Full name required",
  profileFullNameLong: "Full Name can not be more than 50 characters",
  exporterMobileNumberLength:
    "Phone number should be minimum 6 digits",
  currentPasswordRequired: "Current password is required",
  offPlatformDocsRequired: "You must upload at least one document",
  offPlatformDocsMaxLength:
    "The max allowed number of documents is 15",
  offPlatformRepNameRequired: "Representative Name is required",
  offPlatformRepNameLength:
    "Representative Name can not be more than 50 characters",
  offPlatformRepNameValidation:
    "The input must include letters. Special characters are allowed only if there is at least one letter, but numbers are not allowed",
  offPlatformJobTitleRequired: "Job Title is required",
  offPlatformJobTitleLength:
    "Job Title can not be more than 50 characters",
  offPlatformJobTitleValidation:
    "The input must include letters. Special characters are allowed only if there is at least one letter, but numbers are not allowed",
  offPlatformEmailRequired: "Email Adress required",
  offPlatformEmailInvalid: "Email Adress is invalid",
  offPlatformPhoneLength:
    "Mobile number should be between 6 - 15 digits",
  offPlatformIdRequired: "Proof of Identity is required",
  invalidProductRequestTitle:
    "Product request title can not start with number or special character",
  productRequestTitleRequired: "Product request title is required",
  productRequestTitleLong:
    "Product request title can not be more than 50 characters",
  requestDescriptionLong:
    "Product request description can not be more than 200 characters",
  requestDescriptionRequired:
    "Product request description is required",
  minimumRequiredQuantityLow:
    "Required quantity from can not be less than 1",
  invalidMinimumRequiredQuantity: "Required quantity from is invalid",
  minimumRequiredQuantityRequired:
    "Required quantity from is Required",
  maximumRequiredQuantitySmaller:
    "Required quantity to can not be smaller than or equal From value",
  maximumRequiredQuantityRequired: "Required quantity to is Required",
  invalidMaximumRequiredQuantity: "Required quantity to is invalid",
  minimumRequiredQuantityUnitRequired:
    "Required quantity unit is required",
  requiredQuantityUnitOtherRequired:
    "Required quantity Other unit is required",
  minimumBudgetRangeRequired: "Budget range from is required",
  minimumBudgetRangeLow: "Budget range from can not be less than 1",
  invalidMinimumBudgetRange: "Budget range from is invalid",
  maximumBudgetRangeRequired: "Budget range to is required",
  maximumBudgetRangeSmaller:
    "Budget range To value can not be smaller than or equal From value",
  invalidMaximumBudgetRange: "Budget range to is invalid",
  minimumBudgetRangeLong:
    "Budget range from can not be more than 6 digits",
  maximumBudgetRangeLong:
    "Budget range to can not be more than 6 digits",
  minimumRequiredQuantityLong:
    "Required quantity from can not be more than 6 digits",
  maximumRequiredQuantityLong:
    "Required quantity to can not be more than 6 digits",
  changeCurrencyFieldRequired: "Currency is required",
  companyIntroductionRequired: "Company introduction is required",
  exporterMobileNumberRequired: "Exporter mobile number is required",
  companyWebsiteRequired: "Company website is required",
  companyCoverImageRequired: "Company cover image is required",
  companyNameRequired: "Company name is required",
  messageRequired: "Message is required"
};

export default inputsValidations;
