import {
  HIDE_LOADER_OVERLAY,
  SHOW_HIDE_LOADER,
  SHOW_HIDE_LOADER_TEXT
} from "./types";

export const showHideLoader = (payload) => ({
  type: SHOW_HIDE_LOADER,
  payload
});

export const showHideLoaderText = (payload) => ({
  type: SHOW_HIDE_LOADER_TEXT,
  payload
});

export const hideLoaderOverlay = (payload) => ({
  type: HIDE_LOADER_OVERLAY,
  payload
});
