import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { PublishModalIcon } from "../../../../utils/Icons";

const ChangeShowcaseProductSubcategory = ({
  open,
  closeModal,
  handleConfirmChange
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    products: {
      changeShowcaseProductSubcategory: {
        content,
        confirmationContent
      }
    },
    shared
  } = messages[lang];

  const changeShowcaseProductSubcategoryContent = () => {
    return (
      <div className="change-showcase-product-subcategory-container container d-flex flex-column justify-content-center align-items-center">
        <PublishModalIcon />
        <div className="change-showcase-product-subcategory mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmChange(true);
        closeModal();
      }}
      handleClose={() => {
        handleConfirmChange(false);
        closeModal();
      }}
      isContentText={false}
      content={changeShowcaseProductSubcategoryContent()}
      hasActions={true}
      confirmBtnTxt={shared.continue}
      cancelBtnTxt={shared.cancel}
      handleCancel={() => {
        handleConfirmChange(false);
        closeModal();
      }}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default ChangeShowcaseProductSubcategory;

ChangeShowcaseProductSubcategory.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmChange: PropTypes.func.isRequired
};
