import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  RecordVoiceOver,
  Create,
  InfoOutlined
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { RecordRTCPromisesHandler } from "recordrtc";

import {
  showHideLoader,
  showHideLoaderText
} from "../../store/Loader/actions";
import {
  getTranscribedAudioIdRequest,
  isAudioTranscribedRequest
} from "../../store/Products/actions";
import {
  RecordVoiceActiveIcon,
  RecordVoiceIcon,
  RecordVoicePauseIcon,
  RecordVoiceSendIcon,
  ViewDeleteIcon
} from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer,
  deleteBlob
} from "../../utils/blobStorageServices";
import "./VoiceRecord.scss";

const VoiceRecord = ({
  showVoiceRecord,
  setShowVoiceRecord,
  setTranscribedText,
  setIsAudioDescription,
  isAudioDescription,
  isAudioTranscribeSuccessful,
  setIsAudioTranscribeSuccessful,
  handleIsRecordingClicked
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const transcribedAudioText = useSelector(
    (state) => state.products.transcribedAudioText
  );
  const isAudioTranscribed = useSelector(
    (state) => state.products.isAudioTranscribed
  );
  const { products } = messages[lang];
  const dispatch = useDispatch();

  const [isRecording, setIsRecording] = useState(false);
  const [isAudioRecordClicked, setIsAudioRecordClicked] =
    useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [uploadedFileState, setUploadedFileState] = useState();
  const recorderRef = useRef(null);
  const streamRef = useRef(null);

  const handleRecordAudio = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        streamRef.current = stream;
        const recorder = new RecordRTCPromisesHandler(stream, {
          type: "audio"
        });
        recorderRef.current = recorder;
        await recorder.startRecording();
        setIsRecording(true);
      } catch (error) {
        console.error("Error starting recording:", error);
      }
    } else {
      dispatch(showHideLoader(true));
      dispatch(
        showHideLoaderText({
          loadingText:
            products.addEditProduct.audioDescription
              .loadingTranscribedText,
          showText: true
        })
      );
      try {
        const recorder = recorderRef.current;
        await recorder.stopRecording();
        const stream = streamRef.current;
        stream.getTracks().forEach((track) => track.stop());
        const blob = await recorder.getBlob();
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        const blobFile = blobToFile(blob);
        const uploadedFile = await UploadBlobToContainer(
          {
            blob: blobFile,
            name: blobFile.name,
            type: blobFile.type
          },
          BLOB_CONTAINERS.images,
          true
        );
        sendToAPI(uploadedFile);
        setIsRecording(false);
        setShowVoiceRecord(false);
        setUploadedFileState(uploadedFile);
      } catch (error) {
        console.error("Error stopping recording:", error);
      } finally {
        recorderRef.current = null;
        streamRef.current = null;
      }
    }
  };
  const handleDeleteRecording = async () => {
    try {
      const recorder = recorderRef.current;
      await recorder.stopRecording();
      const stream = streamRef.current;
      stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setShowVoiceRecord(false);
    } catch (error) {
      console.error("Error stopping recording:", error);
    } finally {
      recorderRef.current = null;
      streamRef.current = null;
    }
  };

  const blobToFile = (theBlob) => {
    return new File([theBlob], `recording-${Date.now()}.mp3`, {
      type: theBlob.type
    });
  };

  const sendToAPI = (uploadedFile) => {
    dispatch(
      getTranscribedAudioIdRequest({
        body: JSON.stringify({
          audio_url: uploadedFile.url.split("?")[0],
          language_code: "en_us"
        })
      })
    );
  };

  const deleteRecording = async () => {
    if (uploadedFileState?.name)
      try {
        await deleteBlob(uploadedFileState.name, "images");
      } catch (error) {
        console.log(error);
      }
  };

  useEffect(() => {
    if (transcribedAudioText !== null) {
      dispatch(
        showHideLoaderText({
          loadingText: "",
          showText: false
        })
      );
      setTranscribedText(transcribedAudioText);
      if (transcribedAudioText === "") {
        setIsAudioDescription(false);
        setIsAudioTranscribeSuccessful(false);
      } else {
        setIsAudioDescription(true);
        setIsAudioTranscribeSuccessful(true);
        dispatch(showHideLoader(false));
      }
      setShowVoiceRecord(false);
      setIsRecording(false);
      deleteRecording();
      dispatch(isAudioTranscribedRequest(false));
    } else {
      if (!!transcribedAudioText && isAudioRecordClicked) {
        setIsAudioDescription(false);
        setIsAudioTranscribeSuccessful(false);
      }
    }
  }, [isAudioTranscribed]);

  useEffect(() => {
    if (isRecording) {
      setIsAudioTranscribeSuccessful(true);
    }
  }, [isRecording]);

  let timeoutId;

  const handleStopRecording = () => {
    clearTimeout(timeoutId);
    setShowVoiceRecord(false);
    setIsRecording(false);
    setIsAudioRecordClicked(false);
    handleRecordAudio();
  };

  useEffect(() => {
    if (isRecording && isAudioRecordClicked) {
      timeoutId = setTimeout(() => {
        handleStopRecording();
      }, 120000);
    }

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component re-renders or unmounts
    };
  }, [isRecording, isAudioRecordClicked]);

  return (
    <div
      className={` ${!showVoiceRecord ? "voice-input-container-closed-voice" : "voice-input-container"}`}>
      {showVoiceRecord ? (
        isRecording ? (
          <div className="active-voice-record-container d-flex justify-content-between w-100 align-items-center px-3">
            <div
              className="pointer d-flex align-items-center"
              onClick={handleDeleteRecording}>
              <ViewDeleteIcon />
            </div>
            <div
              className=" d-flex gap-2 align-items-center text-danger"
              onClick={handleStopRecording}>
              <RecordVoicePauseIcon className="pointer" />
              <div className="record-text recording-active">
                {products.addEditProduct.audioDescription.recording}
              </div>
              <div className="record-circle"></div>
              <PulseLoader
                color="#21a1f6 "
                size={4}
                speedMultiplier={0.5}
              />
              <RecordVoiceSendIcon />
            </div>
          </div>
        ) : (
          <div
            className={`active-voice-record-container d-flex justify-content-between w-100 align-items-center px-3`}>
            <div className="pe-md-3 col-xl-10 col-md-9 p d-md-block d-none">
              {
                products.addEditProduct.audioDescription
                  .voiceRecordOption
              }
            </div>
            <div className="text-icon-container d-flex gap-2 col-xl-2 col-md-3 col-12 justify-content-lg-end justify-content-between align-items-center">
              <span
                className="record-text"
                onClick={() => {
                  handleRecordAudio();
                  handleIsRecordingClicked(true);
                  setIsAudioRecordClicked(true);
                }}>
                {products.addEditProduct.audioDescription.recordNow}
              </span>
              <span
                className="pointer"
                onClick={() => {
                  setShowVoiceRecord(false);
                }}>
                <RecordVoiceActiveIcon />
              </span>
            </div>
          </div>
        )
      ) : (
        <div className="tooltip-container d-flex gap-2 align-items-center">
          {transcribedAudioText && transcribedAudioText !== "" && (
            <div
              className="pointer"
              onClick={() => {
                setIsAudioDescription(!isAudioDescription);
              }}>
              {isAudioDescription && (
                <Tooltip
                  classes={{
                    tooltip: "light-blue-tooltip-img-icon"
                  }}
                  title={
                    products.addEditProduct.audioDescription
                      .showWrittenDescription
                  }>
                  <Create
                    width="25"
                    height="25"
                    className="description-type-selector"
                  />
                </Tooltip>
              )}
              {!isAudioDescription && (
                <Tooltip
                  classes={{
                    tooltip: "light-blue-tooltip-img-icon"
                  }}
                  title={
                    products.addEditProduct.audioDescription
                      .showAudioDescription
                  }>
                  <RecordVoiceOver
                    width="25"
                    height="25"
                    className="description-type-selector"
                  />
                </Tooltip>
              )}
            </div>
          )}
          <Tooltip
            classes={{
              tooltip: "light-blue-tooltip-img-icon"
            }}
            title={
              <div className="d-flex gap-2 w-90">
                <ul className="mb-0 ps-0">
                  <div className="d-flex gap-2">
                    <InfoOutlined />
                    <li className="mb-2">
                      {
                        products.addEditProduct.audioDescription
                          .voiceRecordOption
                      }
                    </li>
                  </div>
                  <div className="d-flex gap-2">
                    <InfoOutlined />
                    <li>
                      {
                        products.addEditProduct.audioDescription
                          .audioMaxLength
                      }
                    </li>
                  </div>
                </ul>
              </div>
            }>
            <div
              onClick={() => {
                setShowVoiceRecord(true);
              }}>
              <RecordVoiceIcon className="record-icon" />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

VoiceRecord.propTypes = {
  showVoiceRecord: PropTypes.bool,
  setShowVoiceRecord: PropTypes.func,
  setTranscribedText: PropTypes.func,
  setIsAudioDescription: PropTypes.func,
  isAudioDescription: PropTypes.bool,
  isAudioTranscribeSuccessful: PropTypes.bool,
  setIsAudioTranscribeSuccessful: PropTypes.func,
  handleIsRecordingClicked: PropTypes.func
};

export default VoiceRecord;
