export const SnackbarErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 0 25 25"
    {...props}>
    <path
      fill="#D32F2F"
      d="M7.467 8.48l1.166-1.166 9.334 9.333-1.167 1.167-9.333-9.333z"></path>
    <path
      fill="#D32F2F"
      d="M12.717 2.064a10.436 10.436 0 00-10.5 10.5 10.435 10.435 0 0010.5 10.5 10.436 10.436 0 0010.5-10.5 10.435 10.435 0 00-10.5-10.5zm0 19.5a9 9 0 119-9 9.026 9.026 0 01-9 9z"></path>
  </svg>
);
