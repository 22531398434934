import { useDispatch, useSelector } from "react-redux";
import { Snackbar as MuiSnackbar } from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";

import { showHideSnackbar } from "./../../store/Snackbar/actions";
import {
  SnackbarSuccessIcon,
  SnackbarErrorIcon
} from "../../utils/Icons";
import { SNACKBAR_TYPES } from "../../utils/Constants";
import "./Snackbar.scss";

const Snackbar = () => {
  const dispatch = useDispatch();
  const showSnackbar = useSelector(
    (state) => state.snackbar.showSnackbar
  );
  const message = useSelector((state) => state.snackbar.message);
  const type = useSelector((state) => state.snackbar.type);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showHideSnackbar({
        isOpen: false,
        type,
        message
      })
    );
  };

  return (
    <div className="snackbar-container">
      {message && (
        <MuiSnackbar
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          className={`snackbar ${type}`}
          message={
            <div className="snackbar-msg-icon-container d-flex align-items-start justify-content-between mx-2">
              <div className="d-flex message-container">
                {type === SNACKBAR_TYPES.success ? (
                  <SnackbarSuccessIcon className="me-2 snackbar-icon" />
                ) : type === SNACKBAR_TYPES.info ? (
                  <InfoOutlined className="me-2 snackbar-icon" />
                ) : (
                  <SnackbarErrorIcon className="me-2 snackbar-icon" />
                )}
                <span className="fsize-16 fweight-500">
                  {message}
                </span>
              </div>
              <div
                className="close-icon mx-2 pointer"
                onClick={handleClose}>
                <Close />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
export default Snackbar;
