import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import messages from "../../../../../assets/locale/messages";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import Button from "../../../../../components/Button";
import SuggestCategoryModal from "../../../../../components/SuggestCategoryModal";
import Upload from "../../../../../components/Upload";
import DatePicker from "../../../../../components/DatePicker";

import {
  getCategoriesListRequest,
  getCountriesListRequest
} from "../../../../../store/Lookups/actions";
import {
  registerationRequest,
  setRegisterationFormData
} from "../../../../../store/Auth/actions";
import { setCategorySuggestionModalOpen } from "../../../../../store/Categories/actions";
import {
  BUSINESS_TYPES_IDS,
  USER_TYPES_IDS
} from "../../../../../utils/Constants";
import { showHideLoader } from "../../../../../store/Loader/actions";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../../../utils/blobStorageServices";

const CompanyInfo = ({ onPrevious }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadElementRef = useRef();

  const lang = useSelector((state) => state.locale.lang);
  const { registeration, shared, inputsValidations } = messages[lang];

  const countriesList = useSelector(
    (state) => state.lookups.countriesList
  );
  const categoriesList = useSelector(
    (state) => state.lookups.categoriesList
  );
  const companyFormData = useSelector(
    (state) => state.auth.registeration.formData
  );
  const suggestCategoryModalOpen = useSelector(
    (state) => state.categories.categorySuggestionModalOpen
  );
  const suggestedCategoryData = useSelector(
    (state) => state.categories.suggestedCategoryData
  );

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [logoError, setLogoError] = useState(null);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    dispatch(getCountriesListRequest());
    dispatch(getCategoriesListRequest());
  }, []);

  useEffect(() => {
    if (countriesList?.length) {
      setCountriesOptions(
        countriesList.map(({ Id, Name }) => ({ id: Id, label: Name }))
      );
    }
  }, [countriesList]);

  useEffect(() => {
    if (categoriesList?.length) {
      setCategoriesOptions([
        ...categoriesList.map(({ Id, Name }) => ({
          id: Id,
          label: Name
        })),
        { id: 0, label: registeration.companyInfo.other }
      ]);
    }
  }, [categoriesList]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      LogoImage: "",
      LogoImageInfo: { name: "", type: "" },
      TradeName: "",
      CountryId: null,
      BusinessTypeId: null,
      CategoryIds: [],
      YearEstablished: ""
    },
    validationSchema: Yup.object({
      LogoImage: Yup.mixed().when([], {
        is: () =>
          companyFormData.UserTypeId == USER_TYPES_IDS.importer,
        then: (schema) => schema,
        otherwise: (schema) => schema.required("companyLogoRequired")
      }),
      TradeName: Yup.string()
        .required("tradeNameRequired")
        .max(50, "tradeNameMaxLength"),
      CountryId: Yup.object().required("countryRequired"),
      BusinessTypeId: Yup.object().required("businessTypeRequired"),
      CategoryIds: Yup.array().min(1, "categoriesRequired"),
      YearEstablished: Yup.number().when([], {
        is: () =>
          companyFormData.UserTypeId == USER_TYPES_IDS.importer,
        then: (schema) => schema,
        otherwise: (schema) =>
          schema.required("yearEstablishedRequired")
      })
    }),
    onSubmit: async ({
      LogoImage,
      LogoImageInfo,
      TradeName,
      CountryId,
      BusinessTypeId,
      CategoryIds,
      YearEstablished
    }) => {
      let logoName = "";
      if (LogoImage) {
        if (typeof LogoImage === "object") {
          dispatch(showHideLoader(true));
          logoName = await UploadBlobToContainer(
            {
              blob: LogoImage,
              ...LogoImageInfo
            },
            BLOB_CONTAINERS.images
          );
        } else {
          logoName = LogoImage.split("/images/")[1];
        }
      }
      const registerationData = {
        user: {
          ...companyFormData,
          Company: {
            Id: 0,
            LogoImage:
              companyFormData.UserTypeId != USER_TYPES_IDS.importer
                ? logoName
                : null,
            OriginalLogoImage:
              companyFormData.UserTypeId != USER_TYPES_IDS.importer
                ? LogoImageInfo.name
                : null,
            TradeName,
            CountryId: CountryId.id,
            BusinessTypeId: BusinessTypeId.id,
            CategoryIds: CategoryIds.filter(
              (category) => !!category.id
            ).map((category) => category.id),
            YearEstablished:
              companyFormData.UserTypeId != USER_TYPES_IDS.importer
                ? YearEstablished
                : null
          }
        }
      };
      if (
        suggestedCategoryData &&
        !!CategoryIds.find((category) => category.id === 0)
      ) {
        registerationData.suggestedCategory = {
          ...suggestedCategoryData
        };
      }
      dispatch(
        registerationRequest({
          data: { ...registerationData },
          navigate
        })
      );
      dispatch(showHideLoader(false));
    }
  });

  useEffect(() => {
    if (
      companyFormData?.Company &&
      Object.keys(companyFormData.Company).length
    ) {
      const { Company } = companyFormData;
      setValues({ ...Company });
    }
  }, [companyFormData]);

  const businessTypesOptions = [
    {
      id: BUSINESS_TYPES_IDS.trade,
      label: registeration.companyInfo.businessTypesOptions.trade
    },
    {
      id: BUSINESS_TYPES_IDS.factor,
      label: registeration.companyInfo.businessTypesOptions.factor
    },
    {
      id: BUSINESS_TYPES_IDS.both,
      label: registeration.companyInfo.businessTypesOptions.both
    }
  ];

  const handlePreviousClick = async () => {
    const {
      LogoImage,
      LogoImageInfo,
      TradeName,
      CountryId,
      BusinessTypeId,
      CategoryIds,
      YearEstablished
    } = values;

    let logoData = "";
    if (LogoImage) {
      if (typeof LogoImage === "object") {
        dispatch(showHideLoader(true));
        logoData = await UploadBlobToContainer(
          {
            blob: LogoImage,
            ...LogoImageInfo
          },
          BLOB_CONTAINERS.images,
          true
        );
      } else {
        logoData = { url: LogoImage };
      }
    }
    dispatch(
      setRegisterationFormData({
        Company: {
          Id: 0,
          LogoImage: logoData?.url?.split("?")[0],
          LogoImageInfo: LogoImageInfo,
          TradeName,
          CountryId,
          BusinessTypeId,
          CategoryIds,
          YearEstablished
        }
      })
    );
    dispatch(showHideLoader(false));

    onPrevious();
  };

  const handleCloseModalWithoutSaving = () => {
    dispatch(setCategorySuggestionModalOpen(false));
    setFieldValue(
      "CategoryIds",
      values.CategoryIds.filter((category) => category.id !== 0)
    );
  };

  return (
    <div className="contact-info-section">
      <h3 className="fsize-16 fweight-700 title-color mb-5">
        {registeration.steps.companyInfo}
      </h3>
      <form
        className="register-company-info-form"
        onSubmit={handleSubmit}
        noValidate>
        {companyFormData.UserTypeId != USER_TYPES_IDS.importer && (
          <div
            className={`${(logoError || (touched.LogoImage && errors.LogoImage)) && !values.LogoImage ? "mb-5 pb-3" : "mb-4"}`}>
            <div className="d-flex align-items-center mb-3">
              <p className="fsize-13 fweight-300 title-color me-2 mb-0">
                {registeration.companyInfo.labels.companyLogo}
              </p>
              <Tooltip
                classes={{
                  tooltip: "light-blue-tooltip-fill-icon"
                }}
                className="text-secondary"
                title={
                  <ul className="mb-0 ps-0">
                    <li>{inputsValidations.uploadImgSizeError}</li>
                    <li>
                      {inputsValidations.uploadImgResolutionError}
                    </li>
                    <li>{inputsValidations.uploadImgTypeError}</li>
                  </ul>
                }>
                <InfoOutlined />
              </Tooltip>
            </div>
            <div className="circle-upload-container company-logo circular-border-radius light-border">
              <Upload
                isCircleUploader
                isEditOnly
                touchInput={() => {
                  setFieldTouched("LogoImage");
                }}
                className={`circle-upload `}
                customPlaceholder
                placeholderText={
                  registeration.companyInfo.placeholders.companyLogo
                }
                label={<div ref={uploadElementRef}></div>}
                onClear={() => {
                  setFieldTouched("LogoImage");
                  setValues({
                    ...values,
                    LogoImage: null,
                    LogoImageInfo: {}
                  });
                }}
                userImage={values.LogoImage}
                onChange={(img) => {
                  setFieldTouched("LogoImage");
                  setValues({
                    ...values,
                    LogoImage: img.blob,
                    LogoImageInfo: {
                      ...img?.imageInfo
                    }
                  });
                }}
                name="LogoImage"
                id={"circle-upload"}
                onChangeError={setLogoError}
                isInputHasErr={touched.LogoImage && errors.LogoImage}
                errMsg={errors.LogoImage}
                isCropMode={true}
                cropAspect={1 / 1}
              />
            </div>
          </div>
        )}
        <Input
          required
          label={registeration.companyInfo.labels.companyName}
          placeholder={
            registeration.companyInfo.placeholders.companyName
          }
          onChange={(value) => {
            if (!value || value?.trim()) {
              const modifiedValue = value.replace(/\s\s+/g, " ");
              setFieldTouched("TradeName");
              setFieldValue("TradeName", modifiedValue);
            }
          }}
          value={values.TradeName}
          isInputHasErr={!!(touched.TradeName && errors.TradeName)}
          errMsg={errors.TradeName}
          name="TradeName"
          id="TradeName"
          inputClass="mb-1"
          inputWrapperClass="mb-4"
        />
        <Select
          required
          label={registeration.companyInfo.labels.country}
          placeholder={registeration.companyInfo.placeholders.country}
          options={countriesOptions}
          onChange={(value) => {
            setFieldTouched("CountryId");
            setFieldValue("CountryId", value);
          }}
          value={values.CountryId}
          hasError={!!(touched.CountryId && errors.CountryId)}
          errMsg={errors.CountryId}
          id="CountryId"
          name="CountryId"
          inputClassName="mb-1"
          wrapperClass="mb-4"
        />
        <Select
          required
          label={registeration.companyInfo.labels.businessType}
          placeholder={
            registeration.companyInfo.placeholders.businessType
          }
          options={businessTypesOptions}
          onChange={(value) => {
            setFieldTouched("BusinessTypeId");
            setFieldValue("BusinessTypeId", value);
          }}
          value={values.BusinessTypeId}
          hasError={
            !!(touched.BusinessTypeId && errors.BusinessTypeId)
          }
          errMsg={errors.BusinessTypeId}
          id="BusinessTypeId"
          name="BusinessTypeId"
          inputClassName="mb-1"
          wrapperClass="mb-4"
        />
        <Select
          required
          label={registeration.companyInfo.labels.categories}
          placeholder={
            registeration.companyInfo.placeholders.categories
          }
          options={categoriesOptions}
          onChange={(value, reason, option) => {
            setFieldTouched("CategoryIds");
            setFieldValue("CategoryIds", value);
            if (
              reason &&
              option &&
              reason === "selectOption" &&
              option.id === 0
            ) {
              dispatch(setCategorySuggestionModalOpen(true));
            }
          }}
          value={values.CategoryIds}
          hasError={!!(touched.CategoryIds && errors.CategoryIds)}
          errMsg={errors.CategoryIds}
          id="CategoryIds"
          name="CategoryIds"
          isMultipleSelect
          inputClassName="mb-1"
          wrapperClass="mb-4"
        />
        {companyFormData.UserTypeId != USER_TYPES_IDS.importer && (
          <DatePicker
            id={`YearEstablished`}
            name={`YearEstablished`}
            key={registeration.companyInfo.labels.yearEstablished}
            onChange={(value) => {
              if (
                !value ||
                (!!value.trim() && !value.includes(" "))
              ) {
                setFieldTouched("YearEstablished");
                setValues({
                  ...values,
                  YearEstablished: value
                });
              }
            }}
            wrapperClass="p-0 mb-4"
            inputClass="w-100 p-0"
            label={registeration.companyInfo.labels.yearEstablished}
            labelClass="fsize-13 fweight-300 title-color pb-2"
            placeholder={
              registeration.companyInfo.placeholders.yearEstablished
            }
            margin="normal"
            value={values.YearEstablished}
            format="YYYY"
            disableFuture
            maxDate={currentYear}
            views={["year"]}
            yearOnlyDate
            isInputHasErr={
              !!(touched.YearEstablished && errors.YearEstablished)
            }
            errMsg={errors.YearEstablished}
          />
        )}
        <div className="actions-section d-flex align-items-center justify-content-between">
          <Button
            label={shared.previous}
            customSpacing={"px-5 py-2"}
            outlined
            onClick={handlePreviousClick}
          />
          <Button
            label={shared.submit}
            type={"submit"}
            disabled={!dirty || !isValid}
            customSpacing={"px-5 py-2"}
          />
        </div>
      </form>
      {suggestCategoryModalOpen && (
        <SuggestCategoryModal
          onClose={handleCloseModalWithoutSaving}
          isDirectSubmit={false}
        />
      )}
    </div>
  );
};

export default CompanyInfo;

CompanyInfo.propTypes = {
  onPrevious: PropTypes.func.isRequired
};
