import PropTypes from "prop-types";

export const ListDisplayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    {...props}>
    <path
      stroke={`${props.active ? "#0071BC" : "#AFAFAF"}`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.9 14.267H4.1c-1.5 0-2.1.64-2.1 2.23v4.04c0 1.59.6 2.23 2.1 2.23h15.8c1.5 0 2.1-.64 2.1-2.23v-4.04c0-1.59-.6-2.23-2.1-2.23zM19.9 2.767H4.1c-1.5 0-2.1.64-2.1 2.23v4.04c0 1.59.6 2.23 2.1 2.23h15.8c1.5 0 2.1-.64 2.1-2.23v-4.04c0-1.59-.6-2.23-2.1-2.23z"></path>
  </svg>
);

ListDisplayIcon.propTypes = {
  active: PropTypes.string
};
