export const RecordVoicePauseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      fill="#0071BC"
      d="M9.441 16.254V4.404c0-1.125-.475-1.575-1.675-1.575H4.741c-1.2 0-1.675.45-1.675 1.575v11.85c0 1.125.475 1.575 1.675 1.575h3.025c1.2 0 1.675-.45 1.675-1.575zM18.066 16.254V4.404c0-1.125-.475-1.575-1.675-1.575h-3.025c-1.191 0-1.675.45-1.675 1.575v11.85c0 1.125.475 1.575 1.675 1.575h3.025c1.2 0 1.675-.45 1.675-1.575z"></path>
  </svg>
);
