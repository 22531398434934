const login = {
  signin: "Sign In",
  labels: {
    email: "Email Adress",
    password: "Password"
  },
  placeholders: {
    email: "Enter your email address",
    password: "Enter your password"
  },
  remember: "Remember me",
  forgetPassword: "Forgot password?",
  dontHaveAccount: "Don’t have an account?",
  signup: "Sign Up"
};

export default login;
