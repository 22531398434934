import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import messages from "../../../../../assets/locale/messages";
import { setBankTransferSuccess } from "../../../../../store/Verification/actions";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import { ClockPendingIcon } from "../../../../../utils/Icons";

const TransferSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { bankTransfer, actions }
  } = messages[lang];

  const modalOpen = useSelector(
    (state) => state.verification.bankTransferSuccess
  );

  const renderModalContent = () => (
    <div className="text-center">
      <div className="vector">
        <ClockPendingIcon className="mb-4" />
      </div>
      <div className="content">
        <h5 className="fsize-24 fweight-700 title-color mb-3">
          {bankTransfer.successTransfer}
        </h5>
        <p className="fsize-16 text-color mb-1">
          {bankTransfer.successMsg1}
        </p>
        <p className="fsize-16 text-color mb-4">
          {bankTransfer.successMsg2}
        </p>
      </div>
      <Button
        label={actions.continueBrowsing}
        onClick={() => {
          navigate(ROUTE_PATHS.home);
          dispatch(setBankTransferSuccess(false));
        }}
      />
    </div>
  );

  return (
    <Modal
      open={modalOpen}
      disableEscapeKeyDown
      disableBackdropClick
      isContentText={false}
      content={renderModalContent()}
      modalClass="bank-transfer-success-modal"
    />
  );
};

export default TransferSuccess;
