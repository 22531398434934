import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";

import messages from "../../assets/locale/messages";

export const PasswordValidation = ({
  passwordValue,
  validColor,
  wrapperClass
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    shared: { passwordValidations }
  } = messages[lang];

  const FormatPasswordColor = (regex) => {
    return passwordValue?.match(regex) ? true : false;
  };
  const passwordRegexValidation = [
    {
      regex: /^(.{8,})$/,
      message: passwordValidations.eightCharacters
    },
    {
      regex: /^(?=.*[A-Z]).*$/,
      message: passwordValidations.oneUpper
    },
    {
      regex: /^(?=.*[a-z]).*$/,
      message: passwordValidations.oneLower
    },
    {
      regex: /^(?=.*[0-9])(?=.*[!@#$%^&*()_+<>?/-]).*$/,
      message: passwordValidations.numbersAndSpecial
    }
  ];

  const passwordValidationList = passwordRegexValidation.map(
    (requirement) => {
      const isMet = FormatPasswordColor(requirement.regex);
      return (
        <Typography
          key={requirement.message}
          className="fsize-12 grey-text"
          color={isMet ? requirement.color : ""}>
          {isMet ? (
            <CheckCircle className="me-1" color="success" />
          ) : (
            <Cancel className="me-1" color="error" />
          )}
          {requirement.message}
        </Typography>
      );
    }
  );

  return (
    <div className={`${wrapperClass}`}>
      <p className="text-dark-blue fsize-12 fweight-600">
        {passwordValidations.passwordConditions}
      </p>
      <Typography
        component="ul"
        color="text.secondary"
        className={`p-0`}>
        {passwordValidationList}
      </Typography>
    </div>
  );
};

export default PasswordValidation;

PasswordValidation.propTypes = {
  passwordValue: PropTypes.string.isRequired,
  validColor: PropTypes.string,
  wrapperClass: PropTypes.string
};
