import { axiosInstanceExporters } from ".";

const getSubAccountsList = async ({ params }) =>
  await axiosInstanceExporters.get("/api/User/GetSubaccounts", {
    params,
    paramsSerializer: { indexes: null }
  });

const getSubAccountsCount = async () =>
  await axiosInstanceExporters.get("/api/User/GetSubaccountsCount");

const addSubAccount = async ({ data }) =>
  await axiosInstanceExporters.post("/api/User/AddSubAccount", data);

const getSubAccountProfile = async ({ params }) =>
  await axiosInstanceExporters.get("/api/User/ViewSubaccount", {
    params
  });

const editSubAccountProfile = async (data) =>
  await axiosInstanceExporters.put(`/api/User/EditSubaccount`, data);

const reactivateSubAccountProfile = async (params) =>
  await axiosInstanceExporters.get(`/api/User/ReactivateSubaccount`, {
    params
  });

const deactivateSubAccountProfile = async (params) =>
  await axiosInstanceExporters.get(`/api/User/DeactivateSubaccount`, {
    params
  });

export {
  getSubAccountsList,
  getSubAccountsCount,
  addSubAccount,
  getSubAccountProfile,
  editSubAccountProfile,
  reactivateSubAccountProfile,
  deactivateSubAccountProfile
};
