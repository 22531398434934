export const GET_SUB_ACCOUNTS_LIST_REQUEST =
  "GET_SUB_ACCOUNTS_LIST_REQUEST";
export const GET_SUB_ACCOUNTS_LIST_RESPONSE =
  "GET_SUB_ACCOUNTS_LIST_RESPONSE";

export const GET_SUB_ACCOUNTS_COUNT_REQUEST =
  "GET_SUB_ACCOUNTS_COUNT_REQUEST";
export const GET_SUB_ACCOUNTS_COUNT_RESPONSE =
  "GET_SUB_ACCOUNTS_COUNT_RESPONSE";
export const SET_SUB_ACCOUNTS_IS_NEED_EDIT =
  "SET_SUB_ACCOUNTS_IS_NEED_EDIT";

export const GET_SUB_ACCOUNT_PROFILE_REQUEST =
  "GET_SUB_ACCOUNT_PROFILE_REQUEST";
export const GET_SUB_ACCOUNT_PROFILE_RESPONSE =
  "GET_SUB_ACCOUNT_PROFILE_RESPONSE";

export const ADD_SUB_ACCOUNT_REQUEST = "ADD_SUB_ACCOUNT_REQUEST";

export const EDIT_SUB_ACCOUNT_PROFILE_REQUEST =
  "EDIT_SUB_ACCOUNT_PROFILE_REQUEST";

export const REACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST =
  "REACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST";

export const DEACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST =
  "DEACTIVATE_SUB_ACCOUNT_PROFILE_REQUEST";
