import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/lookups";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* subCategoriesListSaga({ payload }) {
  try {
    const response = yield call(apis.getSubCategoriesList, payload);
    yield put(
      actions.getSubCategoriesListsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* categoriesListSaga() {
  try {
    const response = yield call(apis.getCategoriesList);
    yield put(actions.getCategoriesListResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* MoqListSaga() {
  try {
    const response = yield call(apis.getMoqList);
    yield put(actions.getMOQListResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* specificationsListSaga({ payload }) {
  try {
    const response = yield call(apis.getSpecificationsList, payload);
    yield put(
      actions.getSpecificationsListsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* countriesListSaga() {
  try {
    const response = yield call(apis.getCountriesList);
    yield put(actions.getCountriesListsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* currenciesListSaga() {
  try {
    const response = yield call(apis.getCurrenciesList);
    yield put(actions.getCurrenciesListResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* LookupsSagas() {
  yield takeEvery(
    types.SUB_CATEGORIES_LIST_REQUEST,
    subCategoriesListSaga
  );
  yield takeEvery(types.CATEGORIES_LIST_REQUEST, categoriesListSaga);
  yield takeEvery(types.MOQ_LIST_REQUEST, MoqListSaga);
  yield takeEvery(
    types.SPECIFICATIONS_LIST_REQUEST,
    specificationsListSaga
  );
  yield takeEvery(types.COUNTRIES_LIST_REQUEST, countriesListSaga);
  yield takeEvery(types.CURRENCIES_LIST_REQUEST, currenciesListSaga);
}
