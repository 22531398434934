import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import messages from "../../../../../../assets/locale/messages";
import { CloseBgIcon } from "../../../../../../utils/Icons";
import { confirmOffPlatformPaymentIntentRequest } from "../../../../../../store/Verification/actions";

const FailurePayment = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      actions,
      offPlatform: { payment }
    }
  } = messages[lang];

  const paymentFailureMsg = useSelector(
    (state) => state.verification.paymentFailureErrMsg
  );
  const paymentIntentId = useSelector(
    (state) => state.verification.paymentIntentId
  );

  useEffect(() => {
    if (open && paymentIntentId) {
      dispatch(
        confirmOffPlatformPaymentIntentRequest({
          paymentIntentId
        })
      );
    }
  }, [open, paymentIntentId]);

  const renderModalContent = () => (
    <div className="text-center">
      <CloseBgIcon className="mb-4" />
      <h5 className="fsize-24 fweight-700 title-color mb-3">
        {payment.failedTransfer}
      </h5>
      <p className="fsize-16 text-color mb-4">
        {`${paymentFailureMsg || payment.failureMsg1} ${payment.failureMsg2}`}
      </p>
      <Button
        label={actions.tryAgain}
        onClick={() => {
          onClose();
        }}
      />
    </div>
  );

  return (
    <Modal
      open={open}
      title
      modalClass="online-payment-failure-modal"
      content={renderModalContent()}
      handleClose={onClose}
      isContentText={false}
    />
  );
};

export default FailurePayment;

FailurePayment.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
