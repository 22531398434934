import {
  APPLY_VOUCHER_REQUEST,
  APPLY_VOUCHER_RESPONSE,
  CANCEL_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST,
  CHECK_CAN_RENEW_SUBSCRIPTION_REQUEST,
  CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE,
  CONFIRM_SUBSCRIPTION_PAYMENT,
  CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
  CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
  CREATE_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
  CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
  DELETE_EXTRA_SHOWCASES,
  ENABLE_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST,
  GET_PREVIOUS_SUBSCRIPTION_TYPE_REQUEST,
  GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE,
  GET_SUBSCRIPTION_FINAL_FEES_REQUEST,
  GET_SUBSCRIPTION_FINAL_FEES_RESPONSE,
  GET_SUBSCRIPTION_PLANS_DETAILS_REQUEST,
  GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE,
  GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST,
  GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE,
  GET_USER_SAVED_CARDS_REQUEST,
  GET_USER_SAVED_CARDS_RESPONSE,
  GET_USER_SUBSCRIPTION_PLAN_REQUEST,
  GET_USER_SUBSCRIPTION_PLAN_RESPONSE,
  REQUEST_VOUCHER_REQUEST,
  REQUEST_VOUCHER_RESPONSE,
  SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG,
  SET_SUBSCRIPTION_PAYMENT_INTENT_ID,
  SET_SUBSCRIPTION_SUCCESS_MSG,
  UPDATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_TYPE_REQUEST
} from "./types";

export const getSubscriptionPlansDetailsRequest = () => ({
  type: GET_SUBSCRIPTION_PLANS_DETAILS_REQUEST
});
export const getSubscriptionPlansDetailsResponse = (payload) => ({
  type: GET_SUBSCRIPTION_PLANS_DETAILS_RESPONSE,
  payload
});

export const getSubscriptionPlanDetailsRequest = (payload) => ({
  type: GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST,
  payload
});
export const getSubscriptionPlanDetailsResponse = (payload) => ({
  type: GET_SUBSCRIPTION_PLAN_DETAILS_RESPONSE,
  payload
});

export const createSubscriptionPaymentIntentRequest = (payload) => ({
  type: CREATE_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
  payload
});
export const createSubscriptionPaymentIntentResponse = (payload) => ({
  type: CREATE_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
  payload
});

export const confirmSubscriptionPayment = (payload) => ({
  type: CONFIRM_SUBSCRIPTION_PAYMENT,
  payload
});
export const setSubscriptionSuccessMsg = (payload) => ({
  type: SET_SUBSCRIPTION_SUCCESS_MSG,
  payload
});

export const setSubscriptionPaymentFailureMsg = (payload) => ({
  type: SET_SUBSCRIPTION_PAYMENT_FAILURE_MSG,
  payload
});

export const setSubscriptionPaymentIntentId = (payload) => ({
  type: SET_SUBSCRIPTION_PAYMENT_INTENT_ID,
  payload
});

export const getUserSubscriptionPlanRequest = () => ({
  type: GET_USER_SUBSCRIPTION_PLAN_REQUEST
});
export const getUserSubscriptionPlanResponse = (payload) => ({
  type: GET_USER_SUBSCRIPTION_PLAN_RESPONSE,
  payload
});

export const getPreviousSubscriptionTypeRequest = () => ({
  type: GET_PREVIOUS_SUBSCRIPTION_TYPE_REQUEST
});
export const getPreviousSubscriptionTypeResponse = (payload) => ({
  type: GET_PREVIOUS_SUBSCRIPTION_TYPE_RESPONSE,
  payload
});

export const deleteExtraShowcases = (payload) => ({
  type: DELETE_EXTRA_SHOWCASES,
  payload
});

export const requestVoucherRequest = () => ({
  type: REQUEST_VOUCHER_REQUEST
});
export const requestVoucherResponse = (payload) => ({
  type: REQUEST_VOUCHER_RESPONSE,
  payload
});

export const applyVoucherRequest = (payload) => ({
  type: APPLY_VOUCHER_REQUEST,
  payload
});
export const applyVoucherResponse = (payload) => ({
  type: APPLY_VOUCHER_RESPONSE,
  payload
});

export const checkCanRenewSubscriptionRequest = (payload) => ({
  type: CHECK_CAN_RENEW_SUBSCRIPTION_REQUEST,
  payload
});
export const checkCanRenewSubscriptionResponse = (payload) => ({
  type: CHECK_CAN_RENEW_SUBSCRIPTION_RESPONSE,
  payload
});

export const getSubscriptionFinalFeesRequest = (payload) => ({
  type: GET_SUBSCRIPTION_FINAL_FEES_REQUEST,
  payload
});
export const getSubscriptionFinalFeesResponse = (payload) => ({
  type: GET_SUBSCRIPTION_FINAL_FEES_RESPONSE,
  payload
});

export const createAutomaticRenewalSubscriptionPaymentIntentRequest =
  (payload) => ({
    type: CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
    payload
  });
export const createAutomaticRenewalSubscriptionPaymentIntentResponse =
  (payload) => ({
    type: CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_RESPONSE,
    payload
  });

export const enableAutomaticRenewalSubscriptionRequest = () => ({
  type: ENABLE_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST
});

export const cancelAutomaticRenewalSubscriptionRequest = () => ({
  type: CANCEL_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST
});

export const updateAutomaticRenewalSubscriptionTypeRequest = (
  payload
) => ({
  type: UPDATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_TYPE_REQUEST,
  payload
});

export const getUserSavedCardsRequest = () => ({
  type: GET_USER_SAVED_CARDS_REQUEST
});
export const getUserSavedCardsResponse = (payload) => ({
  type: GET_USER_SAVED_CARDS_RESPONSE,
  payload
});
