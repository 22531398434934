const aboutUs = {
  coverTitle: "About Us",
  coverSubtitle:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.",
  logoAboutContainer: {
    title: "About Exgates",
    firstSubtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type .en the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type .",
    secondSubtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ."
  },
  servicesContainer: {
    title: "Our Services",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    servicesTitles: {
      virtualExhibitionBooths: "Virtual Exhibition Booths",
      productListingAndManagement: "Product Listing and Management",
      advancedSearchAndFiltering: "Advanced Search and Filtering",
      tradeLeads: "Trade Leads and Recommendations",
      multiCurrencySupport: "Multi-Currency Support"
    },
    servicesDescription: {
      virtualExhibitionBooths:
        "Create your online booth to showcase your products to a global audience without the high costs of physical exhibitions.",
      productListingAndManagement:
        "Upload, manage, and organize your product catalog with ease, ensuring maximum visibility and impact.",
      advancedSearchAndFiltering:
        "Find exactly what you’re looking for with powerful search tools that filter by product category, location, or business type.",
      tradeLeads:
        "Receive tailored trade opportunities based on your industry and business needs, connecting you with the right partners.",
      multiCurrencySupport:
        "Facilitate seamless international transactions by allowing users to view and trade in their preferred currencies, with real-time exchange rate updates."
    }
  },
  questionsContainer: {
    title: "Any Questions?",
    secondTitle: "We got you.",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    questionsTitles: {
      register: "How do I register on the platform?",
      showcases: "How can I showcase my products?",
      contactSellerBuyer: "How do I contact a seller or buyer?",
      upgradePlan: "How can I upgrade to a premium plan?"
    },
    questionsDescription: {
      register:
        "To register, click on the “Sign Up” button on the homepage. Choose whether you are an exporter or importer, fill in the required details, and verify your email to get started.",
      showcases:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
      contactSellerBuyer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
      upgradePlan:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
    }
  }
};
export default aboutUs;
