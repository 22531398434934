import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import messages from "../../../assets/locale/messages";
import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import { LocationIcon } from "../../../utils/Icons";
import TruncateText from "../../../components/TruncateText";
import "./ProductCardGrid.scss";

const ProductCardGrid = ({
  src,
  label,
  country,
  count,
  minPrice,
  maxPrice,
  link,
  unit,
  greyBg,
  cardClassName,
  selectedModalCard,
  modalCard,
  currency
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };
  const renderCardContent = () => (
    <div
      className={`product-card border-radius-10 p-3 ${cardClassName} ${greyBg ? "grey-bg" : "white-bg"} ${link && "pointer box-shadow-hover"} ${selectedModalCard ? "selected-product" : modalCard && "single-product-container"}`}
      style={{
        height: "18rem"
      }}>
      <div
        className="w-100 card-img-container mb-2 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
        style={{
          height: "8rem"
        }}>
        <img
          src={src || emptyProductPlaceholder}
          onError={onImageError}
          className="w-100 item-cards-img"
        />
      </div>
      <div className="card-label mb-2">
        <TruncateText
          text={label}
          linesNo={1}
          textClassName={"mb-0 title-color"}
        />
      </div>
      {country && (
        <div className="country d-flex mb-2">
          <LocationIcon />
          <span className="text-grey fsize-12 mx-1">{country}</span>
        </div>
      )}
      <div className="moq d-flex mb-2">
        <span className="text-grey fsize-12">{shared.moq}</span>
        <span className="fsize-12 mx-1">
          {!count ? `${shared.any} ` : `${count} `}
          {unit || ""}
        </span>
      </div>
      {(minPrice || maxPrice) && (
        <div className="price">
          <p className="fsize-18 fweight-600 mb-0">
            {!!minPrice && (
              <span>
                {currency}
                {minPrice}
              </span>
            )}
            {!!minPrice && !!maxPrice && <span>-</span>}
            {!!maxPrice && (
              <span>
                {currency}
                {maxPrice}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <>
      {link ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default ProductCardGrid;

ProductCardGrid.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  count: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  link: PropTypes.string,
  unit: PropTypes.string,
  greyBg: PropTypes.bool,
  cardClassName: PropTypes.string,
  selectedModalCard: PropTypes.bool,
  modalCard: PropTypes.bool,
  currency: PropTypes.any
};

ProductCardGrid.defaultProps = {
  greyBg: true
};
