import {
  SUB_CATEGORIES_LIST_RESPONSE,
  COUNTRIES_LIST_RESPONSE,
  CATEGORIES_LIST_RESPONSE,
  MOQ_LIST_RESPONSE,
  SPECIFICATIONS_LIST_RESPONSE,
  CURRENCIES_LIST_RESPONSE,
  PRODUCT_REQUEST_STATUS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  subCategoriesList: [],
  categoriesList: [],
  moqList: [],
  specificationsList: [],
  countriesList: [],
  currenciesList: [],
  productRequestStatusList: []
};

const lookups = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SUB_CATEGORIES_LIST_RESPONSE:
      return { ...state, subCategoriesList: payload };
    case CATEGORIES_LIST_RESPONSE:
      return { ...state, categoriesList: payload };
    case MOQ_LIST_RESPONSE:
      return { ...state, moqList: payload };
    case SPECIFICATIONS_LIST_RESPONSE:
      return { ...state, specificationsList: payload };
    case COUNTRIES_LIST_RESPONSE:
      return { ...state, countriesList: payload };
    case CURRENCIES_LIST_RESPONSE:
      return { ...state, currenciesList: payload };
    case PRODUCT_REQUEST_STATUS_RESPONSE:
      return { ...state, productRequestStatusList: payload };
    default:
      return state;
  }
};

export default lookups;
