import {
  GET_ALL_SEARCH_RESULTS_REQUEST,
  GET_ALL_SEARCH_RESULTS_RESPONSE,
  GET_SEARCH_SUB_CATEGORIES_REQUEST,
  GET_SEARCH_SUB_CATEGORIES_RESPONSE,
  GET_SEARCH_PRODUCTS_REQUEST,
  GET_SEARCH_PRODUCTS_RESPONSE,
  GET_SEARCH_SUGGESTIONS_REQUEST,
  GET_SEARCH_SUGGESTIONS_RESPONSE,
  GET_SEARCH_EXPORTERS_REQUEST,
  GET_SEARCH_EXPORTERS_RESPONSE,
  SET_TOTAL_SEARCH_COUNT
} from "./types";

export const getSearchSuggestionsRequest = (payload) => ({
  type: GET_SEARCH_SUGGESTIONS_REQUEST,
  payload
});

export const getSearchSuggestionsResponse = (payload) => ({
  type: GET_SEARCH_SUGGESTIONS_RESPONSE,
  payload
});

export const getAllSearchResultsRequest = (payload) => ({
  type: GET_ALL_SEARCH_RESULTS_REQUEST,
  payload
});

export const getAllSearchResultsResponse = (payload) => ({
  type: GET_ALL_SEARCH_RESULTS_RESPONSE,
  payload
});

export const getSearchProductsRequest = (payload) => ({
  type: GET_SEARCH_PRODUCTS_REQUEST,
  payload
});

export const getSearchProductsResponse = (payload) => ({
  type: GET_SEARCH_PRODUCTS_RESPONSE,
  payload
});

export const getSearchSubCategoriesRequest = (payload) => ({
  type: GET_SEARCH_SUB_CATEGORIES_REQUEST,
  payload
});

export const getSearchSubCategoriesResponse = (payload) => ({
  type: GET_SEARCH_SUB_CATEGORIES_RESPONSE,
  payload
});

export const getSearchExportersRequest = (payload) => ({
  type: GET_SEARCH_EXPORTERS_REQUEST,
  payload
});

export const getSearchExportersResponse = (payload) => ({
  type: GET_SEARCH_EXPORTERS_RESPONSE,
  payload
});

export const setTotalSearchCount = (payload) => ({
  type: SET_TOTAL_SEARCH_COUNT,
  payload
});
