import { axiosInstanceExporters } from ".";
import axios from "axios";
import { transcribeApiAuthorizationKey } from "../../utils/Constants";

const getProductsList = async ({ params }) =>
  await axiosInstanceExporters.get("/api/Product/Get", {
    params,
    paramsSerializer: { indexes: null }
  });

const addProduct = async ({ data }) =>
  await axiosInstanceExporters.post("api/Product/AddProduct", data);

const getProductDetails = async ({ id }) =>
  await axiosInstanceExporters.get(`/api/Product/GetById/${id}`);

const getProductVersionDetails = async ({ id }) =>
  await axiosInstanceExporters.get(
    `/api/Product/GetVersionById/${id}`
  );

const editProduct = async ({ data }) =>
  await axiosInstanceExporters.put("/api/Product/EditProduct", data);

const publishProduct = async ({ data }) =>
  await axiosInstanceExporters.put("/api/Product/Publish", data);

const unpublishProduct = async ({ data }) =>
  await axiosInstanceExporters.put("/api/Product/Unpublish", data);

const revertEdit = async ({ data }) =>
  await axiosInstanceExporters.put(
    "/api/Product/RevertEditProduct",
    data
  );

const deleteProduct = async ({ data }) =>
  await axiosInstanceExporters.put("/api/Product/Delete", data);

const sendAddProductSupportEmail = async () =>
  await axiosInstanceExporters.get(`/api/Product/SendSupportMail`);

const getTranscribedAudioId = async ({ body }) =>
  await axios.post("https://api.assemblyai.com/v2/transcript", body, {
    headers: {
      Authorization: transcribeApiAuthorizationKey,
      "Content-Type": "application/json"
    }
  });

const getTranscribedAudioText = async ({ id }) =>
  await axios.get(`https://api.assemblyai.com/v2/transcript/${id}`, {
    headers: {
      Authorization: transcribeApiAuthorizationKey
    }
  });

export {
  getProductsList,
  addProduct,
  getProductDetails,
  getProductVersionDetails,
  editProduct,
  publishProduct,
  unpublishProduct,
  revertEdit,
  deleteProduct,
  sendAddProductSupportEmail,
  getTranscribedAudioId,
  getTranscribedAudioText
};
