import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { DeleteOutlinedIcon } from "../../../../utils/Icons";
import pdfImg from "../../../../assets/images/vectors/pdfFile.png";
import messages from "../../../../assets/locale/messages";
import ViewImageModal from "../ViewImageModal";
import TruncateText from "../../../TruncateText";
import "./ViewUploadedFile.scss";

const ViewUploadedFile = ({
  file,
  type,
  name,
  onDelete,
  viewMode,
  isFileLink,
  lightTheme
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const [fileSrc, setFileSrc] = useState();
  const [viewImgModalOpen, setViewImgModalOpen] = useState(false);

  useEffect(() => {
    if (file) {
      setFileSrc(isFileLink ? file : URL.createObjectURL(file));
    }
  }, [file, isFileLink]);

  const handleViewFile = () => {
    if (type?.includes("pdf")) {
      window.open(fileSrc, "_blank");
    } else {
      setViewImgModalOpen(true);
    }
  };

  return (
    <div className="viewed-file-card">
      <div
        className={`light-border border-radius-10 py-sm-3 py-2 px-sm-4 px-2 ${lightTheme ? "white-bg" : "grey-bg"}`}>
        <div className="row align-items-center">
          <div className="col-sm-2 col-3">
            <div
              className="file-view"
              style={{
                backgroundImage: `url(${type?.includes("pdf") ? pdfImg : fileSrc})`
              }}></div>
          </div>
          <div className="col-sm-9 col-7">
            <TruncateText
              text={name}
              textClassName={"fsize-14 title-color word-break"}
            />
            <p
              className="mb-0 text-underline text-primary fsize-13 fweight-500 pointer"
              onClick={handleViewFile}>
              {shared.clickToView}
            </p>
          </div>
          {!viewMode && (
            <div className="col-1">
              <DeleteOutlinedIcon
                className="pointer"
                onClick={onDelete}
              />
            </div>
          )}
        </div>
      </div>
      {fileSrc && (
        <ViewImageModal
          open={viewImgModalOpen}
          imgSrc={fileSrc}
          imgName={name}
          onClose={() => setViewImgModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ViewUploadedFile;

ViewUploadedFile.propTypes = {
  file: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  viewMode: PropTypes.bool,
  isFileLink: PropTypes.bool,
  lightTheme: PropTypes.bool
};
