import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormHelperText, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import { showHideLoader } from "../../../store/Loader/actions";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../utils/blobStorageServices";
import Upload from "../../../components/Upload";
import Input from "../../../components/Input";
import { ALLOW_ALL_WITH_MANDATORY_CHARACTERS } from "../../../utils/Patterns";
import {
  editExporterProfileDetailsRequest,
  editImporterProfileDetailsRequest,
  getExporterProfileRequest,
  getExporterProfileResponse
} from "../../../store/MyAccount/actions";
import { checkBackgroundImageValidity } from "../../../utils/Helpers";
import Button from "../../../components/Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getCountriesListRequest } from "../../../store/Lookups/actions";
import { USER_TYPES_IDS } from "../../../utils/Constants";
import { InfoOutlined } from "@mui/icons-material";
import PhoneInput from "../../../components/PhoneInput";
import "./EditProfile.scss";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const exporterProfile = useSelector(
    (state) => state.myAccount.exporterProfile
  );
  const countriesList = useSelector(
    (state) => state.lookups.countriesList
  );
  const userCountry = useSelector(
    (state) => state.auth.login.CountryName
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const loggedInUserId = useSelector((state) => state.auth.login.Id);

  const profileImageElementRef = useRef();
  const {
    myAccount: { editProfile },
    inputsValidations,
    shared
  } = messages[lang];

  const [isProfileImgValid, setIsProfileImgValid] = useState(false);
  const [profileImageError, setProfileImageError] = useState(false);
  const [countryShort, setCountryShort] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const exporterSchema = Yup.object({
    fullName: Yup.string()
      .matches(
        ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
        "fullNameNoSpecialChars"
      )
      .required("profileFullNameRequired")
      .max(50, "profileFullNameLong"),
    mobileNumber: Yup.string()
      .test(
        "valid-length",
        "exporterMobileNumberLength",
        function (value) {
          const { countryCodeLength } = this.parent;
          if (!countryCodeLength || !value) {
            return true;
          }
          const actualLength = value.length;
          if (actualLength !== countryCodeLength) {
            return actualLength >= countryCodeLength + 6;
          }
          return true;
        }
      )
      .nullable(),
    countryCodeLength: Yup.number(),
    companyLogo: Yup.string().nullable()
  });
  const importerSchema = Yup.object({
    fullName: Yup.string()
      .matches(
        ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
        "fullNameNoSpecialChars"
      )
      .required("profileFullNameRequired")
      .max(50, "profileFullNameLong"),
    countryCodeLength: Yup.number(),
    mobileNumber: Yup.string()
      .test(
        "valid-length",
        "exporterMobileNumberLength",
        function (value) {
          const { countryCodeLength } = this.parent;
          if (!countryCodeLength || !value) {
            return true;
          }
          const actualLength = value.length;
          if (actualLength !== countryCodeLength) {
            return actualLength >= countryCodeLength + 6;
          }
          return true;
        }
      )
      .nullable(),
    companyLogo: Yup.string().nullable(),
    companyName: Yup.string()
      .nullable()
      .max(50, "companyNameLong")
      .required("companyNameRequired")
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    setValues,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      fullName: "",
      mobileNumber: "",
      profileImage: "",
      companyName: "",
      countryCodeLength: 0
    },
    validationSchema:
      userType !== USER_TYPES_IDS.importer
        ? exporterSchema
        : importerSchema,
    onSubmit: async ({
      fullName,
      mobileNumber,
      profileImage,
      dbProfileImage,
      profileImageInfo,
      companyName
    }) => {
      dispatch(showHideLoader(true));
      let profileImageName = "";
      let profileImageObject = {};
      const dbData = {
        dbProfileImage,
        profileImageInfo
      };
      const userData = {
        Id: loggedInUserId,
        Name: fullName,
        MobileNumber: `${mobileNumber.length > 0 && !mobileNumber.startsWith("+") ? `+` : ""}${mobileNumber}`,
        ProfileImage: "",
        OriginalProfileImage: profileImageInfo.name,
        CompanyName: companyName
      };

      if (profileImage) {
        if (profileImageInfo.isNew) {
          profileImageObject = await UploadBlobToContainer(
            {
              blob: profileImage,
              ...profileImageInfo
            },
            BLOB_CONTAINERS.images,
            true
          );
          profileImageName = profileImageObject.name;
          dbData.newImageUrl = profileImageObject.url;
        } else {
          profileImageName = profileImage.split("/images/")[1];
          dbData.newImageUrl = profileImage;
        }
        userData.ProfileImage = profileImageName;
      }
      userType !== USER_TYPES_IDS.importer
        ? dispatch(
            editExporterProfileDetailsRequest({
              data: {
                ...userData
              },
              dbData,
              navigate
            })
          )
        : dispatch(
            editImporterProfileDetailsRequest({
              data: {
                ...userData
              },
              dbData,
              navigate
            })
          );
      dispatch(showHideLoader(false));
    }
  });

  useEffect(() => {
    dispatch(getExporterProfileRequest());
    dispatch(getCountriesListRequest());
    return () => {
      dispatch(getExporterProfileResponse({}));
    };
  }, []);

  useEffect(() => {
    checkBackgroundImageValidity(
      exporterProfile?.ProfileImage,
      (isValid) => {
        setIsProfileImgValid(isValid);
      }
    );
  }, [exporterProfile, dispatch]);

  useEffect(() => {
    if (countriesList.length) {
      setCountryShort(
        countriesList.filter(
          (country) => country.Name === userCountry
        )[0].CountryNameShort
      );
    }
  }, [countriesList]);

  useEffect(() => {
    if (exporterProfile && Object.keys(exporterProfile).length) {
      const {
        Name,
        MobileNumber,
        ProfileImage,
        OriginalProfileImage,
        CompanyName
      } = exporterProfile;
      setValues({
        ...values,
        fullName: Name,
        mobileNumber: MobileNumber ?? "",
        profileImage: ProfileImage,
        dbProfileImage: ProfileImage,
        profileImageInfo: {
          isNew: false,
          name: OriginalProfileImage
        },
        companyName: CompanyName
      });
    }
  }, [exporterProfile]);

  const renderLabelValue = (label, value) => (
    <div className="d-flex align-items-center text-break render-label-container">
      <div className="single-not-editable-field">
        <p className="fsize-13 fweight-400 title-color font-medium field-label">
          {label}
        </p>
        <p className="text-black fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  return (
    <div className="edit-profile-container">
      <div className="page-title mb-4">
        <Typography variant="h5" className="title-color">
          {editProfile.pageTitle}
        </Typography>
      </div>
      {exporterProfile && !!Object.keys(exporterProfile).length && (
        <form
          className="edit-profile-form"
          onSubmit={handleSubmit}
          noValidate>
          <div className="profile-img-data-container bg-white border-radius-10 p-md-4 p-3 col-12">
            <div className="title-tips-container d-flex align-items-center py-2">
              <div className="title-optional-container d-flex align-items-center gap-md-0 gap-1 pe-1">
                <Typography className="title-color fweight-400 fsize-12">
                  {editProfile.profileImg}
                </Typography>
                <Typography
                  variant="span"
                  className="text-light-grey ps-md-1 fweight-400 fsize-12">
                  {editProfile.optional}
                </Typography>
              </div>
              <Tooltip
                classes={{
                  tooltip: "light-blue-tooltip-fill-icon"
                }}
                style={{ width: "16px", height: "16px" }}
                className="text-secondary"
                title={
                  <ul className="mb-0 ps-0">
                    <li>{inputsValidations.uploadImgSizeError}</li>
                    <li>
                      {inputsValidations.uploadImgResolutionError}
                    </li>
                    <li>{inputsValidations.uploadImgTypeError}</li>
                  </ul>
                }>
                <InfoOutlined />
              </Tooltip>
            </div>
            <div className="profile-img circular-border-radius light-border">
              <Upload
                isCircleUploader
                isEditOnly
                hideErrMsg
                className={`circle-upload exporter-profile-circle-upload`}
                customPlaceholder
                touchInput={() => {
                  setFieldTouched("profileImage");
                }}
                onClear={() => {
                  setFieldTouched("profileImage");
                  setValues({
                    ...values,
                    profileImage: null,
                    profileImageInfo: {}
                  });
                }}
                label={<div ref={profileImageElementRef}></div>}
                userImage={
                  isProfileImgValid ? values.profileImage : ""
                }
                onChange={(img) => {
                  setFieldTouched("profileImage");
                  setValues({
                    ...values,
                    profileImage: img.blob,
                    profileImageInfo: {
                      ...img?.imageInfo,
                      isNew: true
                    }
                  });
                }}
                name="profileImage"
                isCropMode={true}
                cropAspect={1 / 1}
                onChangeError={setProfileImageError}
                isInputHasErr={
                  profileImageError ||
                  (touched.profileImage && errors.profileImage)
                }
                errMsg={profileImageError || errors.profileImage}
              />
            </div>

            {(profileImageError ||
              (touched.profileImage &&
                inputsValidations[errors.profileImage])) && (
              <div className="w-100">
                <FormHelperText
                  error={true}
                  className={`${"fsize-12 mt-2"} ${
                    lang === "en" ? "" : "text-end"
                  } `}>
                  {profileImageError ||
                    (touched.profileImage &&
                      inputsValidations[errors.profileImage])}
                </FormHelperText>
              </div>
            )}
            <div className="data-container d-md-flex justify-content-md-between w-100 mb-md-5">
              <div className="editable-fields col-md-6 col-12">
                <div className={`my-4`}>
                  <Input
                    inputWrapperClass={"company-form-field"}
                    key={editProfile.inputLabels.fullName}
                    label={editProfile.inputLabels.fullName}
                    labelClassName="font-medium  main-text-color"
                    placeholder={
                      editProfile.placeholders.fullNamePlaceholder
                    }
                    onChange={(value) => {
                      if (!value || !!value.trim()) {
                        const modifiedValue = value.replace(
                          /\s\s+/g,
                          " "
                        );
                        setFieldTouched("fullName");
                        setFieldValue("fullName", modifiedValue);
                      }
                    }}
                    value={values.fullName}
                    isInputHasErr={
                      !!(touched.fullName && errors.fullName)
                    }
                    errMsg={errors.fullName}
                    name="fullName"
                    id="fullName"
                  />
                </div>
                <div className={`mt-4`}>
                  <PhoneInput
                    onFocus={() => {
                      setIsPhoneFocused(true);
                    }}
                    onBlur={() => {
                      setIsPhoneFocused(false);
                    }}
                    isFocused={isPhoneFocused}
                    onChange={(value, dialCodeLength) => {
                      setFieldTouched("mobileNumber");
                      setFieldTouched("countryCodeLength");
                      setValues({
                        ...values,
                        mobileNumber: value,
                        countryCodeLength: dialCodeLength
                      });
                    }}
                    userCountryShort={
                      !isPhoneFocused &&
                      exporterProfile &&
                      !!Object.keys(exporterProfile)?.length &&
                      !exporterProfile.MobileNumber &&
                      values.mobileNumber.length === 0
                        ? countryShort
                        : ""
                    }
                    inputWrapperClass={"company-form-field"}
                    key={editProfile.inputLabels.mobileNumber}
                    label={
                      <div className="d-flex align-items-center">
                        <Typography className="fsize-13 fweight-300 title-color font-medium  main-text-color ">
                          {editProfile.inputLabels.mobileNumber}
                        </Typography>
                        <Typography className="text-light-grey ps-md-1 fweight-400 fsize-12">
                          {editProfile.optional}
                        </Typography>
                      </div>
                    }
                    placeholder={
                      editProfile.placeholders.mobileNumberPlaceholder
                    }
                    value={values.mobileNumber || ""}
                    isInputHasErr={
                      !!(touched.mobileNumber && errors.mobileNumber)
                    }
                    errMsg={errors.mobileNumber}
                    name="mobileNumber"
                    id="mobileNumber"
                  />
                </div>
              </div>
              <div
                className={`not-editable-fields col-md-6 col-12  ps-md-0 ps-1 ${userType === USER_TYPES_IDS.importer && "importer-side"}`}>
                <div
                  className={`${userType !== USER_TYPES_IDS.importer ? "mt-md-2 mb-md-4 mt-3" : "my-4"}`}>
                  {renderLabelValue(
                    editProfile.inputLabels.emailAddress,
                    exporterProfile?.Email || "--"
                  )}
                </div>
                {userType !== USER_TYPES_IDS.importer ? (
                  <div className="mt-md-4 mb-md-0 mt-3 mb-2">
                    {renderLabelValue(
                      editProfile.inputLabels.companyName,
                      exporterProfile?.CompanyName || "--"
                    )}
                  </div>
                ) : (
                  <div className={`mt-4`}>
                    <Input
                      inputWrapperClass={"company-form-field"}
                      key={editProfile.inputLabels.companyName}
                      label={editProfile.inputLabels.companyName}
                      labelClassName="pb-2 font-medium  main-text-color"
                      placeholder={
                        editProfile.placeholders.fullNamePlaceholderr
                      }
                      onChange={(value) => {
                        if (!value || !!value.trim()) {
                          const modifiedValue = value.replace(
                            /\s\s+/g,
                            " "
                          );
                          setFieldTouched("companyName");
                          setFieldValue("companyName", modifiedValue);
                        }
                      }}
                      value={values.companyName}
                      isInputHasErr={
                        !!(touched.companyName && errors.companyName)
                      }
                      errMsg={errors.companyName}
                      name="companyName"
                      id="companyName"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`form-buttons-container bg-white d-flex justify-content-between flex-wrap w-100 mt-4  p-4 bg-white border-radius-8`}>
            <Button
              key={shared.cancel}
              outlined
              className={"w-auto"}
              labelClass={`py-1 ps-5 pe-5`}
              label={shared.cancel}
              onClick={() => navigate(ROUTE_PATHS.viewProfile)}
            />
            <Button
              key={shared.submit}
              disabled={!dirty || !isValid}
              type="button"
              className={"w-auto"}
              labelClass={`py-1 ps-5 pe-5`}
              label={shared.save}
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default EditProfile;
