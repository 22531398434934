const myAccount = {
  viewProfile: {
    pageTitle: "Profile",
    inputLabels: {
      fullName: "Full Name",
      emailAddress: "Email Address",
      mobileNumber: "Mobile Number",
      companyName: "Company Name"
    },
    list: {
      transactionsList: "My Transactions",
      tableHeader: {
        paymentReason: "Payment Reason",
        transactionId: "Transaction Id",
        paymentMethod: "Payment Method",
        amount: "Amount",
        transactionDate: "Transaction Date",
        status: "Status"
      },
      noTransactionsFound: "No transactions found yet"
    },
    breadCrumbs: {
      home: "Home",
      myProfile: "My Profile"
    }
  },
  editProfile: {
    pageTitle: "Edit Profile",
    profileImg: "Profile Image",
    optional: "(Optional)",
    inputLabels: {
      fullName: "Full Name",
      emailAddress: "Email Address",
      mobileNumber: "Mobile Number",
      companyName: "Company Name"
    },
    placeholders: {
      fullNamePlaceholder: "Enter Full Name",
      mobileNumberPlaceholder: "Enter Mobile Number",
      companyName: "Enter Company Name"
    },
    breadCrumbs: {
      home: "Home",
      myProfile: "My Profile",
      editProfile: "Edit Profile"
    }
  },
  switchCurrentRole: {
    message: "Save changes before switching tab",
    exporter: "Exporter",
    importer: "Importer"
  },
  subscriptionDetails: {
    subscriptionPlan: "Subscription Plan",
    noSubscription: "You’re not subscribed",
    subscribeNow: "Subscribe Now",
    subscriptionExpired: "Your subscription has expired",
    renewNow: "Renew Now",
    currentPlan: "Current Plan",
    plan: "Plan",
    year: "year",
    renewalDate: "Renewal date:",
    days: "Days",
    of: "of",
    daysRemaining: "days remaining",
    expiredPlan: "Expired Plan",
    planFeatures: "Plan’s Features:",
    avatars: {
      breakFromLimits: "Break Free from Limits,",
      boostYourBusiness: "Boost Your Business",
      thankYouForChoosing: "Thank You for Choosing",
      premium: " Premium ",
      keepGorwTogether: "Let's Keep Growing Together"
    },
    actions: {
      cancelSubscription: "Cancel Subscription",
      manageSubscription: "Manage Subscription",
      automaticRenewal: "Automatic Renewal",
      renewSubscription: "Renew Subscription",
      upgradePlan: "Upgrade Plan"
    },
    automaticRenewal: {
      title: "Automatic Renewal",
      msg: "To make sure your subscription renews automatically without any interruptions, please take a moment to enter your card details. We’ll securely save your information for a hassle-free renewal experience. Don’t worry—your details are completely safe with us!"
    },
    cancelSubscription: {
      title: "Cancel Subscription",
      msg1: "Please note that canceling your subscription renewal will hide all your products and showcases from the website starting from the end of your current plan on",
      msg2: "To keep everything visible, ensure your subscription is renewed before this date.",
      confirmCancellation: "Confirm Cancellation"
    },
    delayedSubscription: {
      youSubscribedTo: "You have subscribed to the",
      pendingPayment:
        "Your payment is currently pending. Once it is completed, we will send you a notification.",
      basic: "Basic Plan",
      standard: "Standard Plan",
      premium: "Premium Plan"
    }
  }
};

export default myAccount;
