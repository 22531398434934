import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../assets/locale/messages";
import VideoPlayer from "../../../../components/VideoPlayer";

const VideoSection = ({ videoSrcFromBackend, videoName }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { subCategories } = messages[lang];

  return (
    <div className="product-video-section p-md-5 p-3 mb-4 border-radius-10 light-border">
      <h6 className="fsize-20 fweight-600 title-color mb-4">
        {subCategories.productDetails.productVideo}
      </h6>
      <div className="border-radius-10 d-flex justify-content-center align-items-center">
        <VideoPlayer
          videoSrc={videoSrcFromBackend}
          videoName={videoName}
        />
      </div>
    </div>
  );
};

export default VideoSection;

VideoSection.propTypes = {
  videoSrcFromBackend: PropTypes.string,
  videoName: PropTypes.string
};
