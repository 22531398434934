import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  FormHelperText,
  Typography,
  Tooltip
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Player } from "video-react";
import { useDropzone } from "react-dropzone";

import {
  acceptedImagesFilesTypes,
  acceptedImagesFilesMaxSize
} from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import {
  CloseMediaIcon,
  UploadIcon,
  ViewEditIcon
} from "../../utils/Icons";
import Cropper from "./Cropper";
import "./Upload.scss";

const Upload = ({
  accept,
  onChange,
  label,
  required,
  disabled,
  name,
  multiple,
  className,
  onChangeError,
  id,
  backgroundImg,
  isInputHasErr,
  errMsg,
  onClear,
  enlarged,
  placeholderText,
  customPlaceholder,
  isVideo,
  userVideo,
  userImage,
  touchInput,
  hideErrMsg,
  showTooltip,
  showValidationMsg,
  cropAspect,
  isCropMode,
  isCircleUploader,
  isEditOnly,
  buttonLabelClassName,
  isShowcase,
  isFitContainer
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { upload, inputsValidations } = messages[lang];

  const [error, setError] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [cropImgSrc, setCropImgSrc] = useState(null);
  const [croppedImgOriginalData, setCroppedImgOriginalData] =
    useState(null);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [croppedImg, setCroppedImg] = useState({});

  useEffect(() => {
    if (userVideo) {
      setVideoSrc(userVideo);
    }
  }, [userVideo]);

  useEffect(() => {
    if (userImage) {
      if (isEditOnly || isCropMode) {
        setCroppedImg({ ...croppedImg, croppedImgUrl: userImage });
      }
      setImgSrc(userImage);
    }
  }, [userImage]);

  useEffect(() => {
    if (document.getElementById(id || "raised-button-file")) {
      document.getElementById(id || "raised-button-file").accept =
        accept;
    }
    if (
      multiple &&
      document.getElementById(id || "raised-button-file")
    ) {
      document.getElementById(id || "raised-button-file").multiple =
        true;
    }
  }, [accept]);

  const acceptedFileType = isVideo
    ? { "video/mp4": [".mp4", ".MP4"] }
    : {
        "image/png": [".png"],
        "image/jpg": [".jpg"],
        "image/bmp": [".bmp"],
        "image/jpeg": [".jpeg"]
      };

  const clearUploadedImg = () => {
    onClear();
    setCroppedImg({});
    setImgSrc(null);
    setCropImgSrc(null);
    setCroppedImgOriginalData(null);
  };

  const validateImagesFiles = async (imagesFilesList) => {
    for (const file of imagesFilesList) {
      const isValid = await new Promise((resolve) => {
        const img = new Image();
        const objectUrl = window.URL.createObjectURL(file);
        img.src = objectUrl;
        img.onload = () => {
          window.URL.revokeObjectURL(objectUrl);
          const { naturalWidth, naturalHeight } = img;
          if (naturalWidth < 256 && naturalHeight < 256) {
            setError(inputsValidations.uploadImgResolutionError);
            onChangeError &&
              onChangeError(
                inputsValidations.uploadImgResolutionError
              );
            resolve(false);
          } else if (!acceptedImagesFilesTypes.includes(file.type)) {
            setError(inputsValidations.uploadImgTypeError);
            onChangeError &&
              onChangeError(inputsValidations.uploadImgTypeError);
            resolve(false);
          } else if (file.size > acceptedImagesFilesMaxSize) {
            setError(inputsValidations.uploadImgSizeError);
            onChangeError &&
              onChangeError(inputsValidations.uploadImgSizeError);
            resolve(false);
          } else {
            setError(null);
            onChangeError && onChangeError(null);
            resolve(true);
          }
        };
      });
      if (!isValid) {
        return false;
      }
    }

    return true;
  };

  const validateVideoQuality = async (VideoFile) => {
    const videoEl = document.createElement("video");

    videoEl.src = window.URL.createObjectURL(VideoFile);
    return await new Promise((resolve) => {
      videoEl.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoEl.src);

        let { type } = VideoFile;
        type = `.${type.split("/")[1]}`;

        const { videoWidth, videoHeight } = videoEl;

        if (!acceptedFileType["video/mp4"].includes(type)) {
          setError(inputsValidations.uploadVideoTypeError);
          onChangeError &&
            onChangeError(inputsValidations.uploadVideoTypeError);
          resolve(false);
        } else if (videoWidth < 680 || videoHeight < 480) {
          setError(inputsValidations.uploadVideoSizeError);
          onChangeError &&
            onChangeError(inputsValidations.uploadVideoSizeError);
          resolve(false);
        } else {
          setError(null);
          onChangeError && onChangeError(null);
          resolve(true);
        }
      };
    });
  };

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles[0]) {
      if (isVideo) {
        const isFileValid = await validateVideoQuality(
          acceptedFiles[0]
        );
        if (isFileValid) {
          const url = URL.createObjectURL(acceptedFiles[0]);
          onChange(url, acceptedFiles[0]);
          setVideoSrc(url);
        }
      } else if (multiple) {
        const isFileValid = await validateImagesFiles(acceptedFiles);
        if (isFileValid) {
          const files = acceptedFiles;
          const filesAsArray = [...files];
          const filesSrc = [];
          filesAsArray.forEach((file) => {
            filesSrc.push(URL.createObjectURL(file));
          });
          onChange(filesSrc, files);
        }
      } else {
        const isFileValid = await validateImagesFiles(acceptedFiles);
        if (isFileValid) {
          if (isCropMode) {
            setCropImgSrc(URL.createObjectURL(acceptedFiles[0]));
            setCropDialogOpen(true);
            setCroppedImgOriginalData(acceptedFiles[0]);
          } else {
            setImgSrc(URL.createObjectURL(acceptedFiles[0]));
            onChange(
              URL.createObjectURL(acceptedFiles[0]),
              acceptedFiles[0]
            );
          }
        } else {
          if (imgSrc || cropImgSrc) {
            clearUploadedImg();
          }
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: (err) => {
      err.forEach((e) => {
        if (e.errors[0].code === "file-invalid-type" && isVideo) {
          setError(inputsValidations.uploadVideoTypeError);
          onChangeError &&
            onChangeError(inputsValidations.uploadVideoTypeError);
        } else if (e.errors[0].code === "file-invalid-type") {
          setError(inputsValidations.uploadImgTypeError);
          onChangeError &&
            onChangeError(inputsValidations.uploadImgTypeError);
        }
      });
    },
    accept: acceptedFileType,
    maxFiles: 15,
    disabled:
      cropDialogOpen ||
      videoSrc ||
      (imgSrc && !isEditOnly) ||
      disabled
  });

  return (
    <div
      {...getRootProps({
        style: { width: "100%", height: "100%" },
        onClick: (e) => e.preventDefault()
      })}
      className="upload-file-container h-100">
      {videoSrc ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center position-relative video-container">
            <div
              className="clear-icon clear-video-icon pointer"
              onClick={() => {
                setVideoSrc(null);
                onClear();
              }}>
              <CloseMediaIcon />
            </div>
            <Player
              playsInline
              src={userVideo ? userVideo : videoSrc}
            />
          </div>
        </div>
      ) : !!croppedImg.croppedImgUrl || !!imgSrc ? (
        <div
          className={`img-clear-icon-container ${isCircleUploader && "circle-img-container "} ${isEditOnly && "circle-edit-icon"} ${isShowcase && "showcase-edit-icon"}`}
          onClick={(e) => {
            if (!e.target.closest(".clear-icon")) {
              e.stopPropagation();
            }
          }}>
          <div
            className={`clear-icon clear-image-icon pointer ${isCircleUploader && "circle-img "} `}
            onClick={(e) => {
              if (!isEditOnly) {
                e.stopPropagation();
                clearUploadedImg();
              }
            }}>
            {isEditOnly ? (
              <>
                <input
                  {...getInputProps({})}
                  style={{ display: "none" }}
                  id={id || "raised-button-file"}
                  type="file"
                  accept={accept}
                  key={imgSrc}
                  required={required}
                  disabled={disabled}
                  name={name}
                />
                <label
                  htmlFor={id || "raised-button-file"}
                  className={`d-flex justify-content-start position-relative grow-child-panner h-100 pointer ${buttonLabelClassName}`}></label>
                <div onClick={touchInput}>
                  <ViewEditIcon />
                </div>
              </>
            ) : (
              <CloseMediaIcon />
            )}
          </div>
          <div
            className={`uploaded-img ${isFitContainer && "fit-container-img"} ${isCircleUploader && "circle-img"} white-bg border  ${croppedImg.croppedImgUrl && "pointer"}`}
            style={{
              backgroundImage: `url(${croppedImg.croppedImgUrl || imgSrc})`
            }}
            onClick={(e) => {
              if (
                croppedImg.croppedImgUrl &&
                (isEditOnly || isCropMode)
              ) {
                e.stopPropagation();
                setCropDialogOpen(true);
              }
            }}></div>
        </div>
      ) : (
        <div className={className}>
          <input
            {...getInputProps({})}
            style={{ display: "none" }}
            id={id || "raised-button-file"}
            type="file"
            accept={accept}
            key={imgSrc}
            required={required}
            disabled={disabled}
            name={name}
          />
          <label
            htmlFor={id || "raised-button-file"}
            className={`d-flex justify-content-start position-relative grow-child-panner h-100`}
            onClick={(e) => {
              if (!e.target.closest(".btn-upload")) {
                e.stopPropagation();
              }
            }}>
            <Button
              variant="contained"
              component="span"
              className={`btn-upload border-radius-8 w-100 btn-banner ${enlarged ? "enlargedLogoInput" : ""} pointer`}
              disabled={disabled}
              style={{ backgroundImage: `url(${backgroundImg})` }}>
              <div className="addIcon position-absolute d-flex flex-column align-items-center justify-content-center">
                <div className="placeholder-container d-flex flex-column justify-content-end align-items-center ">
                  <UploadIcon className="pb-2 upload-icon" />
                  <Typography
                    variant="body"
                    className="placeholder-text fw-bold fsize-16">
                    {customPlaceholder
                      ? placeholderText
                      : isVideo
                        ? upload.mainVideoText
                        : upload.mainText}
                    {!customPlaceholder && (
                      <span className="highlighted-text fw-bold fsize-16">
                        {upload.mainTextHighlighted}
                      </span>
                    )}
                  </Typography>
                  {showValidationMsg && (
                    <span className="placeholder-text fsize-14 text-center">
                      {upload.validationText}
                    </span>
                  )}
                </div>
              </div>
              {label}
            </Button>
          </label>
          <div
            className="d-flex error-container align-items-start gap-2"
            onClick={(e) => {
              if (!e.target.closest(".btn-upload")) {
                e.stopPropagation();
              }
            }}>
            {showTooltip && (
              <Tooltip
                className="text-secondary mt-2"
                title={
                  <ul className="mb-0 ps-0">
                    <li>{inputsValidations.uploadSizeError}</li>
                    <li>{inputsValidations.uploadImgSizeError}</li>
                    <li>{inputsValidations.uploadTypeError}</li>
                  </ul>
                }>
                <InfoOutlined />
              </Tooltip>
            )}
            {!hideErrMsg && (
              <FormHelperText
                error={true}
                className={`fsize-12 mt-2  ${
                  lang === "en" ? "" : "text-end "
                } `}>
                {error ||
                  (isInputHasErr && inputsValidations[errMsg])}
              </FormHelperText>
            )}
          </div>
        </div>
      )}
      {cropDialogOpen && (
        <Cropper
          isOpen={cropDialogOpen}
          onClose={() => {
            setCropDialogOpen(false);
          }}
          image={cropImgSrc || imgSrc}
          imageType={croppedImgOriginalData?.type}
          aspect={cropAspect}
          cropInit={croppedImg.crop}
          zoomInit={croppedImg.zoom}
          setCroppedImg={(crop, zoom, croppedImg) => {
            setCroppedImg({
              crop,
              zoom,
              croppedImgUrl: croppedImg.imgUrl
            });
            onChange({
              blob: croppedImg.blob,
              imageInfo: {
                name: croppedImgOriginalData?.name,
                type: croppedImgOriginalData?.type
              }
            });
          }}
        />
      )}
    </div>
  );
};

Upload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onChangeError: PropTypes.func,
  backgroundImg: PropTypes.string,
  onClear: PropTypes.func,
  id: PropTypes.string || PropTypes.number,
  isInputHasErr: PropTypes.bool,
  enlarged: PropTypes.bool,
  errMsg: PropTypes.string,
  placeholderText: PropTypes.string,
  customPlaceholder: PropTypes.bool,
  isVideo: PropTypes.bool,
  userVideo: PropTypes.string,
  userImage: PropTypes.string,
  touchInput: PropTypes.func,
  hideErrMsg: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showValidationMsg: PropTypes.bool,
  cropAspect: PropTypes.number,
  isCropMode: PropTypes.bool,
  isCircleUploader: PropTypes.bool,
  isEditOnly: PropTypes.bool,
  buttonLabelClassName: PropTypes.string,
  isShowcase: PropTypes.bool,
  isFitContainer: PropTypes.bool
};

Upload.defaultProps = {
  accept: "image/*",
  label: "Upload",
  required: false,
  disabled: false
};

export default Upload;
