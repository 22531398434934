export const WalletCheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M7.917 15.575c0 .594-.166 1.156-.459 1.63a3.143 3.143 0 01-2.707 1.537 3.143 3.143 0 01-2.708-1.536 3.104 3.104 0 01-.459-1.631 3.166 3.166 0 116.333 0z"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3.518 15.575l.783.783 1.687-1.56"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M14.847 6.115a3.62 3.62 0 00-.594-.04H6.337c-.222 0-.436.016-.642.048.111-.222.27-.427.46-.617l2.572-2.581a2.79 2.79 0 013.927 0l1.386 1.4c.506.5.775 1.133.807 1.79z"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M18.21 10.033v3.958c0 2.375-1.583 3.958-3.958 3.958H6.834c.245-.206.46-.459.625-.744.293-.475.46-1.037.46-1.63a3.166 3.166 0 00-5.542-2.09v-3.452c0-2.154 1.298-3.658 3.317-3.911.206-.032.42-.048.641-.048h7.917c.206 0 .404.008.594.04 2.042.237 3.364 1.75 3.364 3.919z"></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M18.21 10.43h-2.375c-.87 0-1.583.712-1.583 1.583 0 .87.712 1.583 1.583 1.583h2.375"></path>
  </svg>
);
