import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";

import AppRoutes from "../routes/Routes";
import { setCurrentLang } from "../store/Lang/actions";
import Theme from "../utils/Theme";
import { USER_STATUS_IDS, USER_TYPES_IDS } from "../utils/Constants";
import Snackbar from "./../components/Snackbar";
import Loader from "./../components/Loader";
import { setupNotifications } from "../notifications/firebase";
import { getUnseenNotificationsCountRequest } from "../store/Notifications/actions";
import { getUserSubscriptionPlanRequest } from "../store/Subscription/actions";
import { getUserStatusRequest } from "../store/User/actions";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userType = useSelector(
    (state) => state.auth.login?.UserTypeId
  );
  const userId = useSelector((state) => state.auth.login?.Id);
  const IsPasswordReset = useSelector(
    (state) => state.auth.login?.IsPasswordReset
  );

  useEffect(() => {
    dispatch(setCurrentLang(lang));
    document.dir = lang === "en" ? "ltr" : "rtl";
  }, [dispatch, lang]);

  useEffect(() => {
    setupNotifications(userCurrentRole, userType);
    if (isAuth && IsPasswordReset) {
      dispatch(getUserStatusRequest());
      dispatch(
        getUnseenNotificationsCountRequest({
          params: {
            userTypeId:
              userType === USER_TYPES_IDS.both
                ? userCurrentRole
                : userType
          }
        })
      );
      if (userStatusId === USER_STATUS_IDS.subscribed) {
        dispatch(getUserSubscriptionPlanRequest());
        localStorage.removeItem(
          `${userId}-delayed-automatic-subscription`
        );
      }
    }
  }, [isAuth, userCurrentRole, IsPasswordReset]);

  return (
    <>
      <ThemeProvider theme={Theme}>
        <AppRoutes />
        <Snackbar />
      </ThemeProvider>
      <Loader />
    </>
  );
};

export default App;
