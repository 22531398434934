const home = {
  navbar: {
    login: "Log In",
    categories: "Categories",
    home: "Home",
    exporters: "Exporters",
    requestProduct: "Request Product",
    pricing: "Pricing",
    underDevelopmentDisclaimer:
      "This is the beta version and we are currently working on the final version",
    stayTuned: "Stay Tuned",
    productRequests: "Product Requests",
    selectCurrency: "Select Currency",
    searchCurrency: "Search by currency",
    aboutUs: "About Us",
    contactUs: "Contact Us"
  },
  herosection: {
    exgates: "EX-Gates",
    slogan: "Seamless trade across borders",
    description:
      "Where exporters and importers meet to create profitable connections worldwide.",
    explore: "Explore"
  },
  subCategories: "Sub Categories",
  popularSubCategories: "Popular Sub Categories",
  mostPopular: "Most Popular",
  popularProducts: "Popular Products",
  latestProducts: "Latest Products",
  popularExporters: "Popular Exporters",
  featuredExportersList: "Featured Exporters",
  moq: "MOQ",
  piece: "Piece",
  viewAll: "View All",
  exploreMore: "Explore More",
  explore: {
    title1: "Search Safely & Free",
    title2: "Save Your Time",
    paragraph1:
      "Your safety is our priority. Find everything you can think of in safety with us at no cost.",
    paragraph2:
      "Save your time traveling and searching, as we have provided you with everything in one place"
  },
  getSupport: {
    title: "Get support !",
    content:
      "Run into any trouble using our platform? Don't hesitate to ask for support! Our team is here to help.",
    button: "Get Support",
    supportRequestSuccessMsg:
      "You have contacted our support team and will respond as soon as possible"
  },
  packages: {
    bigNews: "Big News For Exporters!",
    subtitle:
      "Request voucher, and enjoy up to 100% discounts on all our pricing plans for a LIMITED TIME",
    exploreNow: "Explore Now",
    free: "Free",
    year: "Year",
    mostPopular: "Most Popular",
    currentPlan: "Current Plan"
  },
  contact: {
    title: "Can’t find what you Looking For?",
    content:
      "Import with ease. No cost, just click to discover seamless importing solutions for your needs.",
    requestQuotation: "Request Quotation"
  },
  footer: {
    description:
      "A dynamic B2B marketplace dedicated to connecting exporters and importers globally with ease, efficiency and trust",
    topSubCategories: "Top Sub Categories",
    quickLinks: "Quick Links",
    exploreProducts: "Explore Products",
    customerHelp: "Customer Help",
    exporters: "Exporters",
    pricing: "Pricing",
    requestProduct: "Request Product",
    // subscribe: "Subscribe",
    // subscribeSubtitle: "Subscribe to receive all new updates",
    contactUs: "Contact Us",
    inputPlaceholder: "Enter your Email Address",
    productRequests: "Product Requests"
  },
  noCategoryFoundPlaceholder: "No Category Found"
};

export default home;
