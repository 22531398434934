export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="58"
    fill="none"
    viewBox="0 0 53 58"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M35.335 38.73l-8.834-9.5-8.833 9.5M26.504 29.23v21.375"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M45.027 44.405c2.154-1.263 3.855-3.26 4.836-5.68.98-2.418 1.184-5.118.579-7.675-.605-2.557-1.985-4.824-3.921-6.444-1.936-1.62-4.32-2.5-6.772-2.502h-2.783a19.54 19.54 0 00-3.644-7.55c-1.73-2.188-3.898-3.926-6.342-5.084a16.594 16.594 0 00-7.768-1.597c-2.67.105-5.28.86-7.639 2.208-2.358 1.348-4.402 3.252-5.977 5.572a19.768 19.768 0 00-3.117 7.818 20.39 20.39 0 00.367 8.497 19.473 19.473 0 003.778 7.473"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M35.335 38.73l-8.834-9.5-8.833 9.5"></path>
  </svg>
);
