import ReactCountryFlag from "react-country-flag";
import PropTypes from "prop-types";

const CountryFlag = ({ countryCode, size, wrapperClass }) => (
  <div className={wrapperClass}>
    <ReactCountryFlag
      countryCode={countryCode}
      style={{
        width: size,
        height: size
      }}
      svg
    />
  </div>
);

export default CountryFlag;

CountryFlag.propTypes = {
  countryCode: PropTypes.string,
  size: PropTypes.string,
  wrapperClass: PropTypes.string
};

CountryFlag.defaultProps = {
  countryCode: "US",
  size: "2em"
};
