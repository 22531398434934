export const ClockPendingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="105"
    fill="none"
    viewBox="0 0 96 105"
    {...props}>
    <path
      fill="#0064A7"
      d="M75.556 58.79l-4.626-3.065v1.688H54.825l.033-13.066h1.216l-2.195-4.052-2.498 4.077h1.376v15.574h.439l17.76.05v1.477l4.6-2.684zM54.196 29.686h-.178a.46.46 0 01-.456-.456v-5.647a.46.46 0 01.456-.456h.178a.46.46 0 01.455.456v5.647a.454.454 0 01-.455.456zM54.196 88.848h-.178a.46.46 0 01-.456-.456v-5.647a.46.46 0 01.456-.456h.178a.46.46 0 01.455.456v5.647a.454.454 0 01-.455.456zM20.258 55.557v-.177a.46.46 0 01.456-.456h5.647a.46.46 0 01.455.456v.177a.46.46 0 01-.455.456h-5.647a.454.454 0 01-.456-.456zM81.41 55.557v-.177a.46.46 0 01.456-.456h5.647a.46.46 0 01.456.456v.177a.46.46 0 01-.456.456h-5.647a.454.454 0 01-.456-.456z"></path>
    <path
      stroke="#0064A7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="4"
      d="M52.771 102.852c-27.61 0-49.996-22.386-49.996-50.005 0-27.61 22.386-49.995 49.996-49.995 8.56 0 16.612 2.152 23.652 5.934"></path>
    <path
      fill="#003866"
      d="M84.426 17.116L80.662 3.172l-10.197 10.23 13.961 3.714-3.764-13.944-10.197 10.23 13.961 3.714zM93.235 56.383a1.996 1.996 0 01-1.992-1.992c0-7.96-2.49-15.54-7.2-21.947l3.064-2.557c5.318 7.14 8.12 15.599 8.12 24.495.009 1.098-.895 2-1.992 2zM67.188 19.648a36.773 36.773 0 00-13.066-2.389c-4.483 0-8.88.802-13.067 2.39l-1.342-3.757a40.807 40.807 0 0114.417-2.617c4.946 0 9.791.878 14.417 2.617l-1.359 3.756z"></path>
    <path
      fill="#0064A7"
      d="M15.002 56.384a1.996 1.996 0 01-1.992-1.992c0-8.905 2.81-17.363 8.12-24.496l3.064 2.558c-4.71 6.407-7.2 13.987-7.2 21.947a2 2 0 01-1.992 1.983zM21.635 29.213a41.222 41.222 0 0117.27-13.016l1.342 3.765A37.215 37.215 0 0024.69 31.77l-3.055-2.558z"></path>
    <path
      fill="#003866"
      d="M83.523 31.761a37.214 37.214 0 00-15.556-11.809l1.342-3.765a41.224 41.224 0 0117.27 13.016l-3.055 2.558z"></path>
    <path
      fill="#0064A7"
      d="M54.65 86.224c16.984 0 30.751-13.768 30.751-30.75 0-16.984-13.767-30.751-30.75-30.751S23.9 38.49 23.9 55.473s13.767 30.75 30.75 30.75z"
      opacity="0.08"></path>
  </svg>
);
