import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../CheckoutForm";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

const StripeCheckout = ({
  clientSecret,
  redirectUrl,
  setPaymentIntentFunc,
  setPaymentFailureMsgFunc,
  paymentInfo,
  isSubscription,
  isAutomaticRenewal
}) => {
  const options = {
    mode: isAutomaticRenewal ? "subscription" : "payment",
    setup_future_usage: isSubscription ? "off_session" : null,
    amount: isAutomaticRenewal
      ? 0
      : paymentInfo?.amount && Math.round(paymentInfo?.amount * 100),
    currency: isAutomaticRenewal ? "usd" : paymentInfo?.currency,
    payment_method_types: ["card"],
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      }
    ],
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
      labels: "floating",
      variables: {
        colorPrimary: "#0071bc",
        colorBackground: "#ffffff",
        colorText: "#34464e",
        colorDanger: "#d32f2f",
        colorTextPlaceholder: "#B6B6B6",
        iconCardCvcColor: "#0071bc",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSizeBase: "14px"
      }
    }
  };

  return (
    <div>
      {clientSecret &&
        (isAutomaticRenewal ||
          (paymentInfo?.amount && paymentInfo?.currency)) && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              clientSecret={clientSecret}
              redirectUrl={redirectUrl}
              setPaymentIntentFunc={setPaymentIntentFunc}
              setPaymentFailureMsgFunc={setPaymentFailureMsgFunc}
            />
          </Elements>
        )}
    </div>
  );
};

export default StripeCheckout;

StripeCheckout.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  setPaymentIntentFunc: PropTypes.func.isRequired,
  setPaymentFailureMsgFunc: PropTypes.func.isRequired,
  paymentInfo: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string
  }).isRequired,
  isSubscription: PropTypes.bool,
  isAutomaticRenewal: PropTypes.bool
};
