import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, Typography } from "@mui/material";

import productRequestDetailsCoverImg from "../../../../assets/images/productRequestDetailsCoverImg.svg";
import closedProductRequestPlaceholderImg from "../../../../assets/images/vectors/placeholders/closedProductRequestPlaceholder.svg";
import messages from "../../../../assets/locale/messages";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ContactInfoCard from "../../../../components/ContactInfoCard";
import {
  getImporterContactInfoRequest,
  getImporterProductRequestDetailsRequest,
  getImporterProductRequestDetailsResponse
} from "../../../../store/ProductRequests/actions";
import {
  PRODUCT_REQUEST_STATUS_ID,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import {
  checkForMultiline,
  formatDate,
  isExporterRole,
  isRichText,
  RichTextToHTML
} from "../../../../utils/Helpers";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import "./ViewProductRequest.scss";
import Button from "../../../../components/Button";

const ViewProductRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const { importerProductRequestDetails, importerContactInfo } =
    useSelector((state) => state.productRequests);
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );

  const {
    productRequests: { websiteProductRequests }
  } = messages[lang];

  const breadcrumbs = [
    {
      label: websiteProductRequests.list.breadcrumbs.home,
      link: ROUTE_PATHS.home
    },
    { label: websiteProductRequests.list.requestProduct }
  ];

  const renderLabelValue = (label, value, isBudget) => (
    <div className="d-lg-flex align-items-center justify-content-between text-break mb-3">
      <p className="text-light-grey mb-1 fsize-14 fweight-400">
        {label}
      </p>
      <span
        className={`d-block mb-3 ${isBudget ? "title-color fsize-18 fweight-600" : "text-color fsize-16 fweight-400"}  mb-0`}>
        {value}
      </span>
    </div>
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getImporterProductRequestDetailsRequest({
          params: {
            id,
            currencyId: websiteCurrency?.Id
          }
        })
      );
    }
    return () => {
      dispatch(getImporterProductRequestDetailsResponse({}));
    };
  }, [id, websiteCurrency]);

  useEffect(() => {
    if (
      isAuth &&
      importerProductRequestDetails &&
      importerProductRequestDetails.UserId
    ) {
      dispatch(
        getImporterContactInfoRequest({
          params: {
            id: importerProductRequestDetails.UserId
          }
        })
      );
    }
  }, [importerProductRequestDetails, isAuth]);

  return (
    importerProductRequestDetails &&
    !!Object.keys(importerProductRequestDetails).length && (
      <div className="product-request-details-container px-md-5 px-4">
        <div className="product-requests-details-section mt-3">
          <Breadcrumbs
            list={[
              ...breadcrumbs,
              {
                label:
                  importerProductRequestDetails &&
                  !!Object.keys(importerProductRequestDetails).length
                    ? importerProductRequestDetails.Title
                    : ""
              }
            ]}
          />
          {importerProductRequestDetails &&
            !!Object.keys(importerProductRequestDetails).length &&
            importerProductRequestDetails.StatusId ===
              PRODUCT_REQUEST_STATUS_ID.published && (
              <div
                className={`product-requests-cover-image w-100 border-radius-10 size-cover mb-4`}
                style={{
                  backgroundImage: `url(${productRequestDetailsCoverImg})`
                }}
              />
            )}
        </div>
        {importerProductRequestDetails &&
        !!Object.keys(importerProductRequestDetails).length &&
        importerProductRequestDetails.StatusId ===
          PRODUCT_REQUEST_STATUS_ID.published ? (
          <div className="product-request-details-container mt-3 mb-5 d-md-flex justify-content-between">
            <div className="pe-md-5 pb-md-0 pb-5 col-md-7 col-12">
              <div className="product-request-details light-border border-radius-10 px-4 pt-3">
                <div className="request-label mb-3">
                  <Typography className="fweight-500 fsize-22 title-color mb-2 text-break">
                    {importerProductRequestDetails.Title}
                  </Typography>
                </div>
                <div
                  className={`pb-3 w-100 ${importerProductRequestDetails && !!importerProductRequestDetails.Categories.length ? "" : " light-border-bottom"}`}>
                  {isRichText(
                    importerProductRequestDetails.Details
                  ) ? (
                    <p
                      className="text-break text-grey"
                      dangerouslySetInnerHTML={
                        importerProductRequestDetails.Details
                          ? RichTextToHTML(
                              checkForMultiline(
                                importerProductRequestDetails.Details
                              )
                            )
                          : ""
                      }></p>
                  ) : (
                    <p className="text-break text-grey">
                      {importerProductRequestDetails.Details
                        ? checkForMultiline(
                            importerProductRequestDetails.Details
                          )
                        : ""}
                    </p>
                  )}
                </div>
                {!!importerProductRequestDetails.Categories
                  .length && (
                  <div className="categories-section light-border-bottom pb-3">
                    <div className="categories-chips d-flex justify-content-start flex-wrap">
                      {importerProductRequestDetails?.Categories &&
                      !!importerProductRequestDetails.Categories
                        .length
                        ? importerProductRequestDetails?.Categories.map(
                            ({ Name, Id }, i) => (
                              <Chip
                                key={`category-${Id}`}
                                label={Name}
                                className={`blue-chip border-radius-5 fsize-14 fweight-400 ${i === importerProductRequestDetails.Categories.length - 1 ? "" : "me-2"}  my-2`}
                              />
                            )
                          )
                        : "--"}
                    </div>
                  </div>
                )}
                <div
                  className="budget-quantity-container border-radius-10 p-4 px-md-5 px-4 mt-4 mb-4 col-xl-8 col-lg-10 col-12"
                  style={{ backgroundColor: "#F9FAFB" }}>
                  {renderLabelValue(
                    websiteProductRequests.viewProductRequest
                      .budgetRange,
                    `${importerProductRequestDetails?.CurrencySymbol}${importerProductRequestDetails.PriceFrom} - ${importerProductRequestDetails?.CurrencySymbol}${importerProductRequestDetails.PriceTo}`,
                    true
                  )}
                  {renderLabelValue(
                    websiteProductRequests.viewProductRequest
                      .postDate,
                    formatDate({
                      date: importerProductRequestDetails.PostDate
                    })
                  )}
                  {renderLabelValue(
                    websiteProductRequests.viewProductRequest.country,
                    importerProductRequestDetails.Country
                  )}
                  {renderLabelValue(
                    websiteProductRequests.viewProductRequest
                      .requiredQuantity,
                    `${importerProductRequestDetails.QuantityFrom} - ${importerProductRequestDetails.QuantityTo} ${importerProductRequestDetails.QuantityUnit === "Other" ? importerProductRequestDetails.OtherQuantityUnit : importerProductRequestDetails.QuantityUnit}`
                  )}
                </div>
              </div>
            </div>
            <div className="contact-info-section  col-md-5 col-12">
              <ContactInfoCard
                name={importerContactInfo.Name}
                mobileNumber={importerContactInfo.MobileNumber}
                emailAddress={importerContactInfo.Email}
                companyName={importerContactInfo.CompanyName}
                isFullWidth
                isWebsiteCard={
                  isAuth
                    ? isExporterRole(userCurrentRole, userType)
                      ? userStatusId !== USER_STATUS_IDS.subscribed
                      : true
                    : true
                }
                isImporterData
              />
            </div>
          </div>
        ) : (
          <div className="not-found-container my-5 pb-5">
            <div className="d-flex justify-content-center align-items-center not-found-cover-img-container">
              <img
                className="not-found-cover-img"
                src={closedProductRequestPlaceholderImg}
                alt="product-request-not-found-cover-img"
              />
            </div>
            <Typography className="fweight-600 fsize-22 title-color mb-2 text-break text-center">
              {
                websiteProductRequests.viewProductRequest
                  .closedRequest.title
              }
            </Typography>
            <div className="d-flex justify-content-center align-items-center">
              <Typography className="text-color fsize-14 fweight-400 text-break mb-4 col-xl-5 col-lg-7 col-md-10 col-12 text-center">
                {
                  websiteProductRequests.viewProductRequest
                    .closedRequest.subtitle
                }
              </Typography>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                labelClass=" text-white"
                label={
                  <span className="d-flex align-items-center">
                    <span className="ps-1 ">
                      {
                        websiteProductRequests.viewProductRequest
                          .closedRequest.browseRequestsBtn
                      }
                    </span>
                  </span>
                }
                onClick={() => {
                  navigate(ROUTE_PATHS.websiteProductRequests);
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default ViewProductRequest;
