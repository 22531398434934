import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { PERMISSIONS_IDS, hasPermission } from "../utils/Permissions";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import { isExporterRole } from "../utils/Helpers";
import NotFound from "../containers/NotFound";
import AuthorizedRoute from "./AuthorizedRoute";
// ========= Auth Pages =========
import AuthLayout from "../components/AuthLayout";
import Login from "../containers/Auth/Login";
import ResetPassword from "../containers/Auth/ResetPassword";
import ForgetPassword from "../containers/Auth/ForgetPassword";
import ForgetPsswordConfirm from "../containers/Auth/ForgetPsswordConfirm";
import Registeration from "../containers/Auth/Registeration/RegisterationForm";
import VerifyEmail from "../containers/Auth/Registeration/VerifyEmail";
// ========= Exporter Pages =========
import ExporterLayout from "../components/ExporterLayout";
import Products from "../containers/Exporters/Products/index";
import AddEditProduct from "../containers/Exporters/Products/AddEditProduct";
import ExporterProductDetails from "../containers/Exporters/Products/ViewProductDetails";
import Notifications from "../containers/Notifications";
import ViewCompanyProfile from "../containers/Exporters/Company/ViewCompanyProfile";
import EditCompanyProfile from "../containers/Exporters/Company/EditCompanyProfile";
import AddShowcase from "../containers/Exporters/Company/AddShowcase";
import ViewProfile from "../containers/MyAccount/ViewProfile";
import EditProfile from "../containers/MyAccount/EditProfile";
import ViewSettings from "../containers/Settings/ViewSettings";
import ChangePassword from "../containers/Settings/ChangePassword";
import PricingDetails from "../containers/Subscription/PricingDetails";
import SubscriptionPayment from "../containers/Subscription/SubscriptionPayment";
import SubscriptionSuccess from "../containers/Subscription/SubscriptionPayment/SubscriptionSuccess";
import SubscriptionDetails from "../containers/MyAccount/SubscriptionDetails";
import ProductRequests from "../containers/Exporters/ProductRequests";
import AddEditProductRequest from "../containers/Exporters/ProductRequests/AddEditProductRequest";
import ViewProductRequestDetails from "../containers/Exporters/ProductRequests/ViewProductRequestDetails";
// ========= Importer Pages =========
import ImporterLayout from "../components/ImporterLayout";
import Home from "../containers/Importers/Home";
import SearchPage from "../containers/Importers/SearchPage";
import SubCategoryDetails from "../containers/Importers/SubCategories/SubCategoryDetails";
import ProductDetails from "../containers/Importers/SubCategories/ProductDetails";
import ExporterProfile from "../containers/Importers/Exporters/ExporterProfile";
import ExportersList from "../containers/Importers/Exporters";
import CategoryDetails from "../containers/Importers/Categories/CategoryDetails";
import ProductRequestsList from "../containers/Importers/ProductRequests";
import ViewProductRequest from "../containers/Importers/ProductRequests/ViewProductRequest";
import AboutUs from "../containers/Importers/AboutUs";
import ContactUs from "../containers/Importers/ContactUs";
// ========= Basic Layout Pages =========
import BasicLayout from "../components/BasicLayout";
import RegisterSuccess from "../containers/Auth/Registeration/RegisterSuccess";
import RegisterFailure from "../containers/Auth/Registeration/RegisterFailure";
import Verification from "../containers/Exporters/Verification";
import SuccessPayment from "../containers/Exporters/Verification/OnlinePayment/SuccessPayment";
import OffPlatformSuccessPayment from "../containers/Exporters/Verification/OffPlatform/OffPlatformPayment/OffPlatformSuccessPayment";
import SubAccountsList from "../containers/MyAccount/SubAccounts";
import AddEditSubAccount from "../containers/MyAccount/SubAccounts/AddEditSubAccount";

const AppRoutes = () => {
  const isAuth = useSelector((state) => state.auth.login?.Token);
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const IsPasswordReset = useSelector(
    (state) => state.auth.login?.IsPasswordReset
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            isAuth && !IsPasswordReset ? (
              <AuthLayout />
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            path={ROUTE_PATHS.setNewPassword}
            element={
              IsPasswordReset ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : (
                <ResetPassword />
              )
            }
          />
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <AuthLayout />
            )
          }>
          <Route path={ROUTE_PATHS.login} element={<Login />} />
          <Route
            path={ROUTE_PATHS.forgetPassword}
            element={<ForgetPassword />}
          />
          <Route
            path={ROUTE_PATHS.forgetPasswordConfirm}
            element={<ForgetPsswordConfirm />}
          />
          <Route
            path={ROUTE_PATHS.resetPassword}
            element={<ResetPassword />}
          />
          <Route
            path={ROUTE_PATHS.register}
            element={<Registeration />}
          />
          <Route
            path={ROUTE_PATHS.verifyEmail}
            element={<VerifyEmail />}
          />
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <BasicLayout />
            )
          }>
          <Route
            path={ROUTE_PATHS.registerSuccess}
            element={<RegisterSuccess />}
          />
          <Route
            path={ROUTE_PATHS.registerFailure}
            element={<RegisterFailure />}
          />
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                isExporterRole(userCurrentRole, userType) ? (
                  <BasicLayout />
                ) : (
                  <Navigate to={ROUTE_PATHS.home} />
                )
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[
                  PERMISSIONS_IDS.business_verification_management
                ]}
              />
            }>
            <Route
              path={ROUTE_PATHS.businessVerification}
              element={<Verification />}
            />
            <Route
              path={ROUTE_PATHS.onlinePaymentSuccess}
              element={<SuccessPayment />}
            />
            <Route
              path={ROUTE_PATHS.offPlatformPaymentSuccess}
              element={<OffPlatformSuccessPayment />}
            />
          </Route>
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                isExporterRole(userCurrentRole, userType) ? (
                  <ExporterLayout />
                ) : (
                  <Navigate to={ROUTE_PATHS.home} />
                )
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[PERMISSIONS_IDS.products_management]}
              />
            }>
            <Route
              path={ROUTE_PATHS.products}
              element={<Products />}
              exact
            />
            <Route
              path={ROUTE_PATHS.addProduct}
              element={<AddEditProduct />}
              exact
            />
            <Route
              path={ROUTE_PATHS.editProduct}
              element={<AddEditProduct />}
              exact
            />
            <Route
              path={ROUTE_PATHS.viewProduct}
              element={<ExporterProductDetails />}
              exact
            />
          </Route>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[
                  PERMISSIONS_IDS.company_profile_management
                ]}
              />
            }>
            <Route
              path={ROUTE_PATHS.companyProfile}
              element={<ViewCompanyProfile />}
              exact
            />
            <Route
              path={ROUTE_PATHS.editCompanyProfile}
              element={<EditCompanyProfile />}
            />
            <Route
              path={ROUTE_PATHS.addShowcase}
              element={<AddShowcase />}
            />
            <Route
              path={ROUTE_PATHS.editShowcase}
              element={<AddShowcase />}
            />
          </Route>
        </Route>
        <Route
          element={
            isAuth ? (
              isExporterRole(userCurrentRole, userType) ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : (
                <ExporterLayout />
              )
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            path={ROUTE_PATHS.importerProductRequests}
            element={<ProductRequests />}
            exact
          />
          <Route
            path={ROUTE_PATHS.viewImporterProductRequest}
            element={<ViewProductRequestDetails />}
            exact
          />
          <Route
            path={ROUTE_PATHS.addImporterProductRequests}
            element={<AddEditProductRequest />}
            exact
          />
          <Route
            path={ROUTE_PATHS.editImporterProductRequests}
            element={<AddEditProductRequest />}
            exact
          />
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                <ExporterLayout />
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[PERMISSIONS_IDS.profile_management]}
              />
            }>
            <Route
              path={ROUTE_PATHS.viewProfile}
              element={<ViewProfile />}
            />
            <Route
              path={ROUTE_PATHS.editProfile}
              element={<EditProfile />}
            />
          </Route>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[
                  PERMISSIONS_IDS.subscription_management
                ]}
              />
            }>
            <Route
              path={ROUTE_PATHS.subscription}
              element={<SubscriptionDetails />}
            />
          </Route>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[
                  PERMISSIONS_IDS.sub_accounts_management
                ]}
              />
            }>
            <Route
              path={ROUTE_PATHS.subAccounts}
              element={<SubAccountsList />}
            />
            <Route
              path={ROUTE_PATHS.addSubAccount}
              element={<AddEditSubAccount />}
            />
            <Route
              path={ROUTE_PATHS.editSubAccount}
              element={<AddEditSubAccount />}
            />
          </Route>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[PERMISSIONS_IDS.settings_management]}
              />
            }>
            <Route
              path={ROUTE_PATHS.settings}
              element={<ViewSettings />}
            />
            <Route
              element={
                <AuthorizedRoute
                  pemissionIds={[PERMISSIONS_IDS.change_password]}
                />
              }>
              <Route
                path={ROUTE_PATHS.changePassword}
                element={<ChangePassword />}
              />
            </Route>
          </Route>
          <Route
            exact
            path={ROUTE_PATHS.notifications}
            element={<Notifications />}
          />
          {/* <Route path={ROUTE_PATHS.importerProductRequests} exact>
            <Route index element={<ProductRequests />} exact />
            <Route
              path={ROUTE_PATHS.addImporterProductRequests}
              element={<AddEditProductRequest />}
              exact
            />
            <Route
              path={ROUTE_PATHS.editImporterProductRequests}
              element={<AddEditProductRequest />}
              exact
            />
            <Route
              path={ROUTE_PATHS.viewImporterProductRequest}
              element={<ViewProductRequestDetails />}
              exact
            />
          </Route> */}
        </Route>
        <Route element={<ImporterLayout />}>
          <Route path={ROUTE_PATHS.home} element={<Home />} exact />
          <Route
            path={ROUTE_PATHS.searchPage}
            element={<SearchPage />}
          />
          <Route
            path={ROUTE_PATHS.categoryDetails}
            element={<CategoryDetails />}
            exact
          />
          <Route
            path={ROUTE_PATHS.subCategoryDetails}
            element={<SubCategoryDetails />}
            exact
          />
          <Route
            path={ROUTE_PATHS.productDetails}
            element={<ProductDetails />}
            exact
          />
          <Route
            path={ROUTE_PATHS.exporters}
            element={<ExportersList />}
            exact
          />
          <Route
            path={ROUTE_PATHS.exporterProfile}
            element={<ExporterProfile />}
            exact
          />
          <Route
            path={ROUTE_PATHS.pricingDetails}
            element={
              isAuth && !isExporterRole(userCurrentRole, userType) ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : isAuth &&
                isExporterRole(userCurrentRole, userType) &&
                !hasPermission(
                  PERMISSIONS_IDS.subscription_management
                ) ? (
                <Navigate to={ROUTE_PATHS.home} />
              ) : (
                <PricingDetails />
              )
            }
            exact
          />
          <Route
            path={ROUTE_PATHS.websiteProductRequests}
            element={<ProductRequestsList />}
            exact
          />
          <Route
            path={ROUTE_PATHS.viewProductRequest}
            element={<ViewProductRequest />}
            exact
          />
          <Route
            path={ROUTE_PATHS.websiteProductRequests}
            element={<ProductRequestsList />}
            exact
          />
          <Route
            path={ROUTE_PATHS.aboutUs}
            element={<AboutUs />}
            exact
          />
          <Route
            path={ROUTE_PATHS.contactUs}
            element={<ContactUs />}
            exact
          />
        </Route>
        <Route
          element={
            isAuth ? (
              IsPasswordReset ? (
                isExporterRole(userCurrentRole, userType) ? (
                  <ImporterLayout />
                ) : (
                  <Navigate to={ROUTE_PATHS.home} />
                )
              ) : (
                <Navigate to={ROUTE_PATHS.setNewPassword} />
              )
            ) : (
              <Navigate to={ROUTE_PATHS.login} />
            )
          }>
          <Route
            element={
              <AuthorizedRoute
                pemissionIds={[
                  PERMISSIONS_IDS.subscription_management
                ]}
              />
            }>
            <Route
              path={ROUTE_PATHS.pricingDetailsPayment}
              element={<SubscriptionPayment />}
              exact
            />
            <Route
              path={ROUTE_PATHS.subscriptionSuccess}
              element={<SubscriptionSuccess />}
              exact
            />
          </Route>
        </Route>
        <Route element={<ExporterLayout />}>
          <Route path={ROUTE_PATHS.notFound} element={<NotFound />} />
        </Route>
        <Route
          path={ROUTE_PATHS.root}
          element={<Navigate to={ROUTE_PATHS.home} />}
        />
        <Route
          path={"*"}
          element={<Navigate to={ROUTE_PATHS.home} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
