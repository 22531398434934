import PropTypes from "prop-types";

export const GreyConvertCubeIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "24"}
    height={dimensions || "24"}
    fill="none"
    viewBox="0 0 24 25"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M22 15.951c0 3.87-3.13 7-7 7l1.05-1.75M2 9.951c0-3.87 3.13-7 7-7l-1.05 1.75M13.7 5.4l3.98 2.3 3.94-2.29M17.68 11.77V7.69"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.74 3.161l-2.4 1.33c-.54.3-.99 1.06-.99 1.68v2.54c0 .62.44 1.38.99 1.68l2.4 1.33c.51.29 1.35.29 1.87 0l2.4-1.33c.54-.3.99-1.06.99-1.68v-2.54c0-.62-.44-1.38-.99-1.68l-2.4-1.33c-.51-.28-1.35-.28-1.87 0zM2.35 16.4l3.97 2.3 3.95-2.29M6.32 22.77v-4.08"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.39 14.161l-2.4 1.33c-.54.3-.99 1.06-.99 1.68v2.54c0 .62.44 1.38.99 1.68l2.4 1.33c.51.29 1.35.29 1.87 0l2.4-1.33c.54-.3.99-1.06.99-1.68v-2.54c0-.62-.44-1.38-.99-1.68l-2.4-1.33c-.52-.28-1.36-.28-1.87 0z"></path>
  </svg>
);

GreyConvertCubeIcon.propTypes = {
  dimensions: PropTypes.string
};
