const categories = {
  categoryDetails: {
    popularProducts: "popular products",
    popularExporters: "Popular exporters",
    subscribeSection: {
      title: "Want to showcase your products?",
      content:
        "Subscribe Now and Showcase your products for importers around the world and expand your business.",
      subscribeButton: "Subscribe Now",
      addMoreProductsButton: "Add More products"
    }
  },
  suggestCategory: {
    suggestNewCategory: "Suggest New Category",
    categoryName: "Category Name",
    categoryDescription: "Category Description",
    enterName: "Enter category name",
    enterDescription: "Enter category description"
  }
};

export default categories;
