import store from "../store";
import {
  setAllNotificationsSeen,
  setNotificationSeen
} from "../store/Notifications/actions";
import { formPaths } from "../utils/Constants";
import { setCurrentRole, showErrorMsg } from "../utils/Helpers";
import { notificationsActions } from "./constants";

export const setFcmToken = (token) => {
  sessionStorage.setItem("fcm-token", token);
};

export const getFcmToken = () => {
  return sessionStorage.getItem("fcm-token");
};

export const setUnseenNotificationsCount = (count) => {
  localStorage.setItem("unseen-notification-count", count);
};

export const handleNotificationClick = ({
  id,
  clickAction,
  actionId,
  isRead,
  navigate,
  message,
  location,
  userTypeId,
  userCurrentRole,
  isCombinedUser
}) => {
  if (clickAction) {
    const notificationsAction = notificationsActions.find(
      (action) => action.clickAction === clickAction
    );
    if (notificationsAction && notificationsAction.route) {
      if (isCombinedUser && userTypeId && location && message) {
        if (userTypeId !== userCurrentRole) {
          const isFormPath = formPaths.includes(location.pathname);
          if (isFormPath) {
            showErrorMsg(message);
          } else {
            setCurrentRole();
            navigate(
              actionId && notificationsAction.actionIdKey
                ? notificationsAction.route.replace(
                    notificationsAction.actionIdKey,
                    actionId
                  )
                : notificationsAction.route
            );
          }
        }
      } else {
        navigate(
          actionId && notificationsAction.actionIdKey
            ? notificationsAction.route.replace(
                notificationsAction.actionIdKey,
                actionId
              )
            : notificationsAction.route
        );
      }
    }
  }
  if (!isRead) {
    // Handle seen notificaiton logic
    store.dispatch(setNotificationSeen({ data: { Id: id } }));
  }
};

export const markAllNotificationsAsRead = ({ request }) => {
  store.dispatch(setAllNotificationsSeen({ request }));
};
