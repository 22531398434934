import { axiosInstanceImporters } from ".";

const getSearchSuggestions = async (data) =>
  await axiosInstanceImporters.put(`/api/Search/Search`, data);

const getAllSearchResults = async ({ params }) =>
  await axiosInstanceImporters.put(
    `/api/Search/ViewAllSearch`,
    null,
    {
      params,
      paramsSerializer: { indexes: null }
    }
  );

const getSearchProducts = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/Search/FilterProduct`, {
    params,
    paramsSerializer: { indexes: null }
  });

const getSearchSubCategories = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/Search/FilterSubCategory`, {
    params,
    paramsSerializer: { indexes: null }
  });

const getSearchExporters = async ({ params }) =>
  await axiosInstanceImporters.get(`/api/Search/FilterExporter`, {
    params,
    paramsSerializer: { indexes: null }
  });

export {
  getSearchSuggestions,
  getAllSearchResults,
  getSearchProducts,
  getSearchSubCategories,
  getSearchExporters
};
