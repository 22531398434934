import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../../assets/locale/messages";
import Modal from "../../../../../components/Modal";
import { PublishModalIcon } from "../../../../../utils/Icons";

const ChangeSubcategoryModal = ({
  open,
  handleClose,
  handleConfirm,
  handleCancel
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared,
    company: {
      addShowcase: {
        changeSubcategoryModal: { title, content }
      }
    }
  } = messages[lang];

  const changeSubcategoryContent = () => {
    return (
      <div className="edit-profile-container container d-flex flex-column justify-content-center align-items-center">
        <PublishModalIcon />
        <div className="edit-profile-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {content}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"edit-profile-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirm(true);
        handleClose();
      }}
      handleCancel={() => {
        handleCancel();
        handleClose();
      }}
      handleClose={() => {
        handleCancel();
        handleClose();
      }}
      isContentText={false}
      content={changeSubcategoryContent()}
      hasActions={true}
      confirmBtnTxt={shared.continue}
      cancelBtnTxt={shared.cancel}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default ChangeSubcategoryModal;

ChangeSubcategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func
};
