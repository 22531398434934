const verification = {
  breadCrumbs: {
    home: "Home",
    verification: "Verification"
  },
  exporterVerification: "Exporter Verification",
  steps: {
    step1: "Step 1",
    step2: "Step 2",
    step3: "Step 3",
    verificationMethod: "Verification Method",
    uploadDocuments: "Upload Documents",
    paymentInformation: "Payment Information"
  },
  verificationMethod: {
    title: "Verification Method",
    subTitle:
      "Choose a method to verify your business before proceeding"
  },
  actions: {
    next: "Next",
    back: "Back",
    continueBrowsing: "Continue Browsing",
    tryAgain: "Try Again",
    submit: "Submit"
  },
  verificationNotifications: {
    verifyNow: {
      msg1: "To enjoy all the service features, please take a moment to verify your business.",
      msg2: "Verify Now"
    },
    awaitRequiredDocusAlert: {
      msg1: "You have chosen",
      msg2: "The Off-Platform Method",
      msg3: "if you need to change method ",
      msg4: "Click Here",
      msg5: "Please wait for our support team to send you the required documents according to your country's regulations."
    },
    pendingDocsUpload: {
      msg1: "Our support team has sent you the required documents according to your Country.",
      msg2: "Click here",
      msg3: "to view the documents and upload them."
    },
    docsUnderReview: {
      msg1: "Your Documents is under review.",
      msg2: "Please wait for our support team to review the documents. if you want to go back to verification ",
      msg3: "Click Here"
    },
    pendingPayment: {
      msg1: "The documents are accepted.",
      msg2: "Click here",
      msg3: "to continue the verification process."
    },
    docsNeedUpdate: {
      msg1: "The support team has requested updates to some documents.",
      msg2: "Click here",
      msg3: "to see the updated request details and provide the necessary updates."
    },
    accountUnderReview: {
      msg1: "Your account is under review for verification. Once the results are available, we will send them to your email."
    }
  },
  onlinePayment: {
    verificationMethod: "Verification Method: ",
    onlinePayment: "Online Payment",
    paymentInformation: "Payment Information",
    paymentInfoHint:
      "Please be informed that the amount paid for this verification is ",
    nonRefundable: "non-refundable",
    paymentWarning:
      "Only corporate accounts are accepted for this transaction.",
    requiredInformation: "Required Information",
    successTransfer: "Successful Transfer!",
    successMsg1: "Your account is under review",
    successMsg2:
      "Once the results are available, we will send them to your email",
    failedTransfer: "Failed Transfer!",
    failureMsg1: "Your bank transfer has failed.",
    failureMsg2:
      "Please try again or contact your bank for assistance"
  },
  bankTransfer: {
    verificationMethod: "Verification Method: ",
    bankTransfer: "Bank Transfer",
    paymentInformation: "Payment Information",
    paymentInfoHint:
      "Please be informed that the amount paid for this verification is ",
    nonRefundable: "non-refundable",
    paymentWarning:
      "Only corporate accounts are accepted for this transaction.",
    exgatesBankInfo: "ExGates Bank Info",
    bankName: "Bank name",
    name: "Name",
    accountNumber: "Account number",
    iban: "IBAN",
    branch: "Branch",
    swiftCode: "Swift code",
    requiredInformation: "Required Information",
    uploadBankTransferTranscript:
      "upload the bank transfer transcript",
    successTransfer: "Successful !",
    successMsg1: "Your account is under review",
    successMsg2:
      "Once the results are available, we will send them to your email"
  },
  offPlatform: {
    verificationMethod: "Verification Method: ",
    offplatformVerification: "Off Platform Verification",
    waitForDocuments: {
      chooseOffplatformConfirmTitle: "Thank You For Choosing ExGates",
      chooseOffplatformConfirmMsg:
        "Our support team will send you shortly a specific list of documents required for verification based on your country of registration."
    },
    requiredDocuments: {
      uploadDocuments: "Upload Documents",
      documentsNote:
        "Kindly provide us with the required documents to complete your account verification.",
      requiredDocuments: "Required Documents",
      attachedFiles: "Attached Files",
      companyInfo: "Company Info",
      companyName: "Company Name",
      uploadRequiredDocuments: "Upload The Requirement Documents",
      maxFiles: "(Max 15 Files)",
      selectMultipleFilesNote: "You can select multiple Files",
      companyRepresentativeInfo: "Company Representative Information",
      docsNeedUpdates: "Your documents need these updates:",
      inputs: {
        representativeName: "Representative Name",
        representativeNamePlaceholder: "Enter the name",
        jobtitle: "Job Title",
        jobtitlePlaceholder: "Enter the title",
        nationalId: "National ID or Passport",
        mobileNumber: "Mobile Number",
        mobileNumberPlaceholder: "Enter the number",
        emailAddress: "Email Adderss",
        emailAddressPlaceholder: "Enter the email"
      }
    },
    waitForDocumentsApproval: {
      title: "Uploaded successfully!",
      docsUnderReview: "Your Documents are under review",
      waitForApproval:
        "Our support team will review the uploaded documents, and get back to you to proceed for the verification fees payment."
    },
    payment: {
      paymentInfo: "Payment Information",
      paymentNote:
        "Please be informed that the amount paid for this verification is ",
      nonrefundable: "non-refundable",
      requiredInfo: "Required Information",
      failedTransfer: "Failed Transfer!",
      failureMsg1: "Your bank transfer has failed.",
      failureMsg2:
        "Please try again or contact your bank for assistance",
      thankYou: "Thank You!",
      successMsg1:
        "You completed the necessary steps for the business verification process!",
      successMsg2:
        "We will now proceed with our verification cycle, which is expected to take 2-3 weeks."
    },
    cancelRequest: {
      cancelVerificationRequest: "Cancel Verification Request",
      confirmMsg:
        "Going back will cancel your previously selected verification method. Do you want to proceed?"
    }
  }
};

export default verification;
