import { ROUTE_PATHS } from "../utils/RoutesPaths";

export const clickActions = {
  productApproved: "Product details",
  productRejected: "Product details",
  addRequiredVerificationDocs: "Add required documents",
  editRequiredVerificationDocs: "Edit required documents",
  renewalReminder: "Subscription Details",
  urgentRenewalReminder: "Subscription Details",
  finalRenewalReminder: "Subscription Details",
  voucherCodeSent: "Pricing plans",
  newPostedRequest: "Product request details",
  newProductRequestsPerToday: "Product requests list",
  showcaseProductRejected: "Company profile management",
  permissionsChanged: "Permissions changed",
  accountDeactivated: "Account deactivated",
  automaticSubscriptionSuccess: "Automatic subscription succeeded",
  automaticSubscriptionFailure: "Automatic subscription failed"
};

export const notificationsActions = [
  {
    clickAction: clickActions.productApproved,
    route: ROUTE_PATHS.viewProduct,
    actionIdKey: ":id"
  },
  {
    clickAction: clickActions.productRejected,
    route: ROUTE_PATHS.viewProduct,
    actionIdKey: ":id"
  },
  {
    clickAction: clickActions.addRequiredVerificationDocs,
    route: ROUTE_PATHS.businessVerification
  },
  {
    clickAction: clickActions.editRequiredVerificationDocs,
    route: ROUTE_PATHS.businessVerification
  },
  {
    clickAction: clickActions.renewalReminder,
    route: ROUTE_PATHS.subscription
  },
  {
    clickAction: clickActions.urgentRenewalReminder,
    route: ROUTE_PATHS.subscription
  },
  {
    clickAction: clickActions.finalRenewalReminder,
    route: ROUTE_PATHS.subscription
  },
  {
    clickAction: clickActions.voucherCodeSent,
    route: ROUTE_PATHS.pricingDetails
  },
  {
    clickAction: clickActions.newPostedRequest,
    route: ROUTE_PATHS.viewProductRequest,
    actionIdKey: ":id"
  },
  {
    clickAction: clickActions.newProductRequestsPerToday,
    route: ROUTE_PATHS.websiteProductRequests
  },
  {
    clickAction: clickActions.showcaseProductRejected,
    route: ROUTE_PATHS.companyProfile
  },
  {
    clickAction: clickActions.permissionsChanged,
    route: ROUTE_PATHS.login
  },
  {
    clickAction: clickActions.accountDeactivated,
    route: ROUTE_PATHS.login
  },
  {
    clickAction: clickActions.automaticSubscriptionSuccess,
    route: ROUTE_PATHS.subscription
  },
  {
    clickAction: clickActions.automaticSubscriptionFailure,
    route: ROUTE_PATHS.pricingDetails
  }
];
