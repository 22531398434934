import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { EXGATES_INSTRUCTIONS_PDF } from "../../utils/Constants";
import Alert from "../Alert";
import messages from "../../assets/locale/messages";
import alertBackground from "../../assets/images/addInstructionsAlertBackground.svg";
import { hideInstructionsBannerRequest } from "../../store/User/actions";
import { downloadPDF } from "../../utils/Helpers";

const DownloadInstructionsAlert = ({ isClosable }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const { shared } = messages[lang];

  const renderInstructionsAlertContent = () => (
    <div className="alert-content d-flex align-items-center justify-content-start">
      <div className="mb-1">
        <span className=" fweight-700 fsize-16 text-white d-block mb-2 pe-md-0 pe-1">
          {shared.downloadInstructionsBanner.boldContent}
        </span>
        <span className="main-text fw-400 fsize-14">
          {shared.downloadInstructionsBanner.normalContent}
        </span>
        <span
          onClick={() => {
            downloadPDF({
              pdfURL: EXGATES_INSTRUCTIONS_PDF,
              pdfDownloadName: shared.exgatesInstructionsPDF,
              dispatch
            });
          }}
          className=" ms-1 text-underline text-white fweight-700 fsize-14 pointer">
          {shared.downloadInstructionsBanner.downloadButton}
        </span>
      </div>
    </div>
  );
  return (
    <Alert
      isCustomBackground
      isClosable={isClosable}
      variant="filled"
      customBackground={alertBackground}
      content={renderInstructionsAlertContent()}
      onClose={() => {
        dispatch(hideInstructionsBannerRequest());
      }}
    />
  );
};

DownloadInstructionsAlert.propTypes = {
  isClosable: PropTypes.bool
};

export default DownloadInstructionsAlert;
