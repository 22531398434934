export const BoxCheckBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="57"
    fill="none"
    viewBox="0 0 56 57"
    {...props}>
    <rect
      width="48"
      height="48"
      x="4"
      y="4.455"
      fill="#DEF3FF"
      rx="24"></rect>
    <rect
      width="48"
      height="48"
      x="4"
      y="4.455"
      stroke="#F1FAFF"
      strokeWidth="8"
      rx="24"></rect>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M38.501 33.865c.02.75-.18 1.46-.54 2.07-.2.36-.47.69-.77.96-.69.64-1.6 1.03-2.61 1.06-1.46.03-2.75-.72-3.46-1.87-.38-.59-.61-1.3-.62-2.05a3.97 3.97 0 011.43-3.15c.68-.56 1.54-.91 2.48-.93a4 4 0 014.09 3.91z"></path>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M32.941 33.985l1.01.96 2.09-2.02M18.672 23.395l8.83 5.11 8.77-5.08M27.5 37.565v-9.07"></path>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M37.11 25.125v5.66c0 .05 0 .09-.01.14-.7-.61-1.6-.97-2.6-.97-.94 0-1.81.33-2.5.88a3.97 3.97 0 00-1.5 3.12c0 .75.21 1.46.58 2.06.09.16.2.31.32.45l-1.83 1.01c-1.14.64-3 .64-4.14 0l-5.34-2.96c-1.21-.67-2.2-2.35-2.2-3.73v-5.66c0-1.38.99-3.06 2.2-3.73l5.34-2.96c1.14-.64 3-.64 4.14 0l5.34 2.96c1.21.67 2.2 2.35 2.2 3.73z"></path>
  </svg>
);
