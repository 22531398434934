import {
  HIDE_LOADER_OVERLAY,
  SHOW_HIDE_LOADER,
  SHOW_HIDE_LOADER_TEXT
} from "./types";

const INITIAL_STATE = {
  showLoader: false,
  showText: false,
  loadingText: "",
  hideLoaderOverlay: false
};

const loader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_HIDE_LOADER:
      return {
        ...state,
        showLoader: action.payload
      };
    case SHOW_HIDE_LOADER_TEXT:
      return {
        ...state,
        loadingText: action.payload.loadingText,
        showText: action.payload.showText
      };
    case HIDE_LOADER_OVERLAY:
      return {
        ...state,
        hideLoaderOverlay: action.payload
      };
    default:
      return state;
  }
};

export default loader;
