export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";

export const SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL =
  "SET_DISABLE_FORGET_PASSWORD_RESEND_EMAIL";

export const SET_REGISTERATION_FORM_DATA =
  "SET_REGISTERATION_FORM_DATA";

export const REGSITERATION_REQUEST = "REGSITERATION_REQUEST";

export const CHECK_CAN_USER_RESEND_VERIFY_EMAIL_REQUEST =
  "CHECK_CAN_USER_RESEND_VERIFY_EMAIL_REQUEST";
export const CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE =
  "CHECK_CAN_USER_RESEND_VERIFY_EMAIL_RESPONSE";

export const RESEND_VERIFY_EMAIL_REQUEST =
  "RESEND_VERIFY_EMAIL_REQUEST";

export const SET_DISABLE_RESEND_EMAIL = "SET_DISABLE_RESEND_EMAIL";

export const CHANGE_CURRENT_USER_ROLE = "CHANGE_CURRENT_USER_ROLE";

export const CHECK_EMAIL_UNIQUE = "CHECK_EMAIL_UNIQUE";

export const DISABLE_SUB_ACCOUNTS_WARNING_BANNER =
  "IS_REACTIVATED_EXPORTER_LOGIN_REQUEST";

export const IS_USER_FORCED_LOGOUT_REQUEST =
  "IS_USER_FORCED_LOGOUT_REQUEST";
export const IS_USER_FORCED_LOGOUT_RESPONSE =
  "IS_USER_FORCED_LOGOUT_RESPONSE";
export const FORCE_LOGOUT_REQUEST = "FORCE_LOGOUT_REQUEST";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
