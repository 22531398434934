import {
  GET_SUB_ACCOUNT_PROFILE_RESPONSE,
  GET_SUB_ACCOUNTS_COUNT_RESPONSE,
  GET_SUB_ACCOUNTS_LIST_RESPONSE,
  SET_SUB_ACCOUNTS_IS_NEED_EDIT
} from "./types";

const INITIAL_STATE = {
  subAccountsList: [],
  subAccountsMeta: {},
  activeSubAccountsCount: 0,
  isNeedEdit: false,
  subAccountProfile: {}
};

const subAccounts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUB_ACCOUNTS_LIST_RESPONSE:
      return {
        ...state,
        subAccountsList: action.payload.list,
        subAccountsMeta: action.payload.meta
      };
    case GET_SUB_ACCOUNTS_COUNT_RESPONSE:
      return {
        ...state,
        activeSubAccountsCount: action.payload
      };
    case SET_SUB_ACCOUNTS_IS_NEED_EDIT:
      return {
        ...state,
        isNeedEdit: action.payload
      };
    case GET_SUB_ACCOUNT_PROFILE_RESPONSE:
      return {
        ...state,
        subAccountProfile: action.payload
      };

    default:
      return state;
  }
};

export default subAccounts;
