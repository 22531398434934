export const FileCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    fill="none"
    viewBox="0 0 69 69">
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.5"
      fill="#FEE4E2"
      rx="28"></rect>
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.5"
      stroke="#FEF3F2"
      strokeWidth="8"
      rx="28"></rect>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M28.25 37h6.25M28.25 26.953l-4.688-4.687M28.2 22.313L23.511 27M28.25 32h10M32 22h7.5c4.163.225 6.25 1.762 6.25 7.488V39.5M23.25 30.766v8.712c0 5.013 1.25 7.525 7.5 7.525h7.5"></path>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M45.75 39.5l-7.5 7.5v-3.75c0-2.5 1.25-3.75 3.75-3.75h3.75z"></path>
  </svg>
);
