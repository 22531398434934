import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { FileCloseIcon } from "../../../../utils/Icons";
import { closeProductRequestRequest } from "../../../../store/ProductRequests/actions";

const CloseProductRequest = ({
  open,
  closeModal,
  productRequestInfo
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    productRequests: {
      importerProductRequests: {
        closeProductRequest: { close, content, confirmationContent }
      }
    },
    shared
  } = messages[lang];

  const handleConfirmClose = () => {
    dispatch(
      closeProductRequestRequest({
        id: productRequestInfo.id,
        navigate
      })
    );
    closeModal();
  };

  const closeProductRequestContent = () => {
    return (
      <div className="close-product-request-container container d-flex flex-column justify-content-center align-items-center">
        <FileCloseIcon />
        <div className="close-product-request-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${close} "${productRequestInfo.name}"`}
          </Typography>
          <Typography className="text-grey fsize-14 mt-2 fweight-400">
            {content}
          </Typography>
          <Typography className="text-grey fsize-14 mt-2 fweight-400">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmClose();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={closeProductRequestContent()}
      hasActions={true}
      confirmBtnTxt={close}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"sm"}
    />
  );
};

export default CloseProductRequest;

CloseProductRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  productRequestInfo: PropTypes.object.isRequired
};
