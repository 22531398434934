import {
  GET_EXPORTER_PROFILE_RESPONSE,
  GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  exporterProfile: {},
  exporterTransactionsList: [],
  exporterTransactionsListMeta: {}
};

const myAccount = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPORTER_PROFILE_RESPONSE:
      return {
        ...state,
        exporterProfile: action.payload
      };
    case GET_EXPORTER_PROFILE_TRANSACTIONS_RESPONSE:
      return {
        ...state,
        exporterTransactionsList: action.payload.list,
        exporterTransactionsListMeta: action.payload.meta
      };
    default:
      return state;
  }
};

export default myAccount;
