import {
  GET_PRODUCTS_LIST_RESPONSE,
  GET_PRODUCT_DETAILS_RESPONSE,
  GET_PRODUCT_VERSION_DETAILS_RESPONSE,
  SHOW_ADD_SUCCESS_MODAL,
  SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE,
  GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE,
  AUDIO_TRANSCRIBED
} from "./types";

const INITIAL_STATE = {
  productsList: [],
  meta: {},
  productDetails: {},
  productVersionDetails: {},
  showAddModal: false,
  isSupportEmailSent: false,
  transcribedAudioText: null,
  isAudioTranscribed: false
};

const products = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_RESPONSE:
      return {
        ...state,
        productsList: action.payload.list,
        meta: action.payload.meta
      };
    case GET_PRODUCT_DETAILS_RESPONSE:
      return {
        ...state,
        productDetails: action.payload
      };
    case SHOW_ADD_SUCCESS_MODAL:
      return {
        ...state,
        showAddModal: action.payload
      };
    case GET_PRODUCT_VERSION_DETAILS_RESPONSE:
      return {
        ...state,
        productVersionDetails: action.payload
      };
    case SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE:
      return {
        ...state,
        isSupportEmailSent: action.payload
      };
    case GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE:
      return {
        ...state,
        transcribedAudioText: action.payload
      };
    case AUDIO_TRANSCRIBED:
      return {
        ...state,
        isAudioTranscribed: action.payload
      };
    default:
      return state;
  }
};

export default products;
