import * as React from "react";
import { styled } from "@mui/material/styles";
import { Switch as MUISwitch, Typography } from "@mui/material";

import "./Switch.scss";

const Switch = styled((props) => (
  <div className="switchButton-container d-flex gap-2 align-items-center ">
    <MUISwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      classes={{ materialSwitch: "pointer" }}
      className={props.className}
      disabled={props.disabled}
      checked={props.checked}
      onChange={(e) => props.handleChange(e.target.checked)}
    />
    <Typography
      className={`fsize-14 fweight-500 ${
        props.checked
          ? `switch-active-text ${props.primary ? "primary" : "success"}`
          : "switch-inactive-text"
      }`}>
      {props.label}
    </Typography>
  </div>
))(({ theme, ...props }) => ({
  width: 45,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(21px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: `${props?.primary ? "#21A1F6" : "#14BA6D"}`,
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
        cursor: "not-allowed !important"
      },
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "#fff",
        borderRadius: 26 / 2
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {
      cursor: "not-allowed !important",
      backgroundColor: "#E9E9EA",
      opacity: 0.5
    }
  },
  "& .MuiSwitch-thumb": {
    transform: "translateY(2px)",

    width: 20,
    height: 20,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center"
    }
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#C8C8C8",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 100
    })
  }
}));

export default Switch;

Switch.defaultProps = {
  primary: false,
  success: true,
  color: "success"
};
