import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import Button from "../../../../../components/Button";
import messages from "../../../../../assets/locale/messages";
import {
  requestOffPlatformVerificaiton,
  setSelectedVerificationMethod,
  setVerificationStep
} from "../../../../../store/Verification/actions";
import {
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS,
  VERIFICATION_METHODS
} from "../../../../../utils/Constants";

const SelectVerificationMethod = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { verification } = messages[lang];

  const verificationMethods = useSelector(
    (state) => state.verification.verificationMethods
  );
  const selectedMethod = useSelector(
    (state) => state.verification.selectedMethod
  );
  const basicLayoutContainerRef = useSelector(
    (state) => state.navigation.basicLayoutContainerRef
  );

  useEffect(() => {
    basicLayoutContainerRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    if (verificationMethods?.length && !selectedMethod) {
      dispatch(
        setSelectedVerificationMethod(verificationMethods[0]?.Id)
      );
    }
  }, [verificationMethods, selectedMethod]);

  return (
    <Box className="select-verification-method-container">
      <h5 className="fsize-18 fweight-600 title-color">
        {verification.verificationMethod.title}
      </h5>
      <p className="fsize-16 text-color">
        {verification.verificationMethod.subTitle}
      </p>
      <div className="verification-methods-cards-container mb-4">
        <div className="row flex-column flex-lg-row">
          {verificationMethods?.map(
            ({ Id, Name, Fees, CurrencyId, Description, Icon }) => (
              <div
                className="col-12 col-md-6 col-lg-4 mb-3 mx-auto"
                key={`verification-card-${Id}`}>
                <div
                  className={`verification-method-card border-radius-10 p-4 h-100 pointer box-shadow-hover ${selectedMethod === Id ? "primary-border faint-primary-bg" : "medium-border"}`}
                  onClick={() =>
                    dispatch(setSelectedVerificationMethod(Id))
                  }>
                  {Icon && (
                    <div
                      className="border-radius-20 light-primary-bg mx-auto mb-3 d-flex align-items-center justify-content-center"
                      style={{ width: "6rem", height: "6rem" }}>
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(Icon)}`}
                        style={{ width: "3rem" }}
                      />
                    </div>
                  )}
                  <h6 className="fsize-18 fweight-600 title-color text-center">
                    {Name}
                  </h6>
                  <h6 className="fsize-18 fweight-600 title-color text-center mb-4">
                    {`${Fees || 0} ${CurrencyId ? (CurrencyId === CURRENCIES_IDS.euro ? CURRENCIES_SYMBOLS.euro : CURRENCIES_SYMBOLS.usd) : CURRENCIES_SYMBOLS.usd}`}
                  </h6>
                  <ul
                    className="description-container text-color mb-0"
                    dangerouslySetInnerHTML={{
                      __html: Description
                    }}></ul>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="actions-section d-flex justify-content-end">
        <Button
          label={verification.actions.next}
          customSpacing={"px-5 py-2"}
          onClick={() => {
            if (selectedMethod === VERIFICATION_METHODS.offPlatform) {
              dispatch(requestOffPlatformVerificaiton());
            } else {
              dispatch(setVerificationStep(1));
            }
          }}
          disabled={!selectedMethod}
        />
      </div>
    </Box>
  );
};

export default SelectVerificationMethod;
