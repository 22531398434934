import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import EasyCropper from "react-easy-crop";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box
} from "@mui/material";

import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import getCroppedImg from "./cropImage";

const Cropper = ({
  isOpen,
  onClose,
  image,
  imageType,
  aspect,
  setCroppedImg,
  cropInit,
  zoomInit
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { upload, shared } = messages[lang];

  const [crop, setCrop] = useState(cropInit || { x: 0, y: 0 });
  const [zoom, setZoom] = useState(zoomInit || 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(_, reason) => {
          reason !== "backdropClick" && onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="cropping-dialog"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: {
              xs: "90%",
              md: "60rem"
            }
          }
        }}>
        <DialogTitle sx={{ color: "text.input" }}>
          {upload.cropImg}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: {
                xs: "15rem",
                md: "25rem"
              }
            }}>
            <EasyCropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: {
              xs: "10px 24px",
              md: "20px 24px"
            }
          }}>
          <Box
            sx={{
              width: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              gap: 2
            }}>
            <Button
              onClick={async () => {
                onClose();
              }}
              label={shared.cancel}
              outlined
            />
            <Button
              onClick={async () => {
                const croppedImg = await getCroppedImg(
                  image,
                  imageType,
                  croppedAreaPixels
                );
                setCroppedImg(crop, zoom, croppedImg);
                onClose();
              }}
              label={shared.done}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Cropper;

Cropper.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  image: PropTypes.any,
  imageType: PropTypes.string,
  aspect: PropTypes.number,
  setCroppedImg: PropTypes.func,
  cropInit: PropTypes.object,
  zoomInit: PropTypes.number
};
