import {
  GET_SUBSCRIPTION_END_DATE_RESPONSE,
  SHOW_CHANGE_PASSWORD_SUCCESS_MODAL
} from "./types";

const INITIAL_STATE = {
  showChangeSuccessModal: false,
  subscriptionEndDate: ""
};

const settings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_CHANGE_PASSWORD_SUCCESS_MODAL:
      return {
        ...state,
        showChangeSuccessModal: action.payload
      };
    case GET_SUBSCRIPTION_END_DATE_RESPONSE:
      return {
        ...state,
        subscriptionEndDate: action.payload
      };
    default:
      return state;
  }
};

export default settings;
