import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../../assets/locale/messages";
import Modal from "../../../../../components/Modal";
import { editProductRequest } from "../../../../../store/Products/actions";
import {
  showHideLoader,
  showHideLoaderText
} from "../../../../../store/Loader/actions";

const EditPublishedEditedProduct = ({
  open,
  closeModal,
  productInfo
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    products: {
      publishedEditedProduct: { content, confirmationContent }
    },
    shared
  } = messages[lang];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmEdit = () => {
    dispatch(showHideLoader(true));
    dispatch(
      showHideLoaderText({
        loadingText: productInfo.loaderEditText,
        showText: true
      })
    );
    dispatch(
      editProductRequest({
        data: productInfo.data,
        navigate
      })
    );
    closeModal();
  };

  const publishedEditedProductContent = () => {
    return (
      <div className="publish-product-container container d-flex flex-column justify-content-center align-items-center">
        <div className="publish-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${productInfo.name}"`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmEdit();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={publishedEditedProductContent()}
      hasActions={true}
      confirmBtnTxt={shared.confirm}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default EditPublishedEditedProduct;

EditPublishedEditedProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired,
  updateList: PropTypes.func,
  detailsFlag: PropTypes.bool
};
