export const GET_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST =
  "GET_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST";
export const GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE =
  "GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE";

export const GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST =
  "GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST";
export const GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE =
  "GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE";

export const GET_SUB_CATEGORY_PRODUCT_DETAILS_REQUEST =
  "GET_SUB_CATEGORY_PRODUCT_DETAILS_REQUEST";
export const GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE =
  "GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE";

export const GET_PRODUCTS_RECOMMENDATIONS_REQUEST =
  "GET_PRODUCTS_RECOMMENDATIONS_REQUEST";
export const GET_PRODUCTS_RECOMMENDATIONS_RESPONSE =
  "GET_PRODUCTS_RECOMMENDATIONS_RESPONSE";
