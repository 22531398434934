import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";
import { DeleteModalIcon } from "../../../utils/Icons";
import { isExporterRole } from "../../../utils/Helpers";
import { getDeviceId } from "../../../utils/Auth";
import {
  deleteExporterAccountRequest,
  deleteImporterAccountRequest
} from "../../../store/Settings/actions";
import { USER_STATUS_IDS } from "../../../utils/Constants";
import "./DeleteProfile.scss";

const DeleteProfile = ({
  open,
  closeModal,
  handleConfirmDeleteModal
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const userId = useSelector((state) => state.auth.login.Id);
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    settings: { deleteExporterProfile, deleteImporterProfile },
    shared
  } = messages[lang];

  const handleConfirmDelete = () => {
    if (isExporterRole()) {
      if (
        userPlan &&
        userStatusId === USER_STATUS_IDS.subscribed &&
        !!Object.keys(userPlan).length
      ) {
        handleConfirmDeleteModal(true);
      } else {
        dispatch(
          deleteExporterAccountRequest({
            logoutParams: {
              device_id: getDeviceId(),
              navigate
            }
          })
        );
      }
    } else {
      dispatch(
        deleteImporterAccountRequest({
          params: { userId },
          logoutParams: {
            device_id: getDeviceId(),
            navigate
          }
        })
      );
    }
    closeModal();
  };

  const deleteExporterProfileContent = () => {
    return (
      <div className="delete-profile-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-profile-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {deleteExporterProfile.title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deleteExporterProfile.content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deleteExporterProfile.confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };
  const deleteImporterProfileContent = () => {
    return (
      <div className="delete-profile-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-profile-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {deleteImporterProfile.title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deleteImporterProfile.content}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {deleteImporterProfile.confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"delete-account-modal"}
      open={open}
      handleConfirm={handleConfirmDelete}
      handleClose={closeModal}
      isContentText={false}
      content={
        isExporterRole()
          ? deleteExporterProfileContent()
          : deleteImporterProfileContent()
      }
      hasActions={true}
      confirmBtnTxt={deleteExporterProfile.deleteAccountButton}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default DeleteProfile;

DeleteProfile.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmDeleteModal: PropTypes.func.isRequired
};
