import {
  GET_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
  GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
  GET_SUB_CATEGORY_PRODUCT_DETAILS_REQUEST,
  GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE,
  GET_PRODUCTS_RECOMMENDATIONS_REQUEST,
  GET_PRODUCTS_RECOMMENDATIONS_RESPONSE
} from "./types";

export const getSubCategoriesProductsListRequest = (payload) => ({
  type: GET_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
  payload
});
export const getSubCategoriesProductsListResponse = (payload) => ({
  type: GET_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  payload
});

export const getAllSubCategoriesProductsListRequest = (payload) => ({
  type: GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_REQUEST,
  payload
});
export const getAllSubCategoriesProductsListResponse = (payload) => ({
  type: GET_ALL_SUB_CATEGORIES_PRODUCTS_LIST_RESPONSE,
  payload
});

export const getSubCategoryProductDetailsRequest = (payload) => ({
  type: GET_SUB_CATEGORY_PRODUCT_DETAILS_REQUEST,
  payload
});
export const getSubCategoryProductDetailsResponse = (payload) => ({
  type: GET_SUB_CATEGORY_PRODUCT_DETAILS_RESPONSE,
  payload
});

export const getProductsRecommendationsRequest = (payload) => ({
  type: GET_PRODUCTS_RECOMMENDATIONS_REQUEST,
  payload
});
export const getProductsRecommendationsResponse = (payload) => ({
  type: GET_PRODUCTS_RECOMMENDATIONS_RESPONSE,
  payload
});
