import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TextField,
  FormHelperText,
  Autocomplete
} from "@mui/material";

import messages from "../../assets/locale/messages";
import "./Select.scss";

const Select = ({
  labelClassName,
  value,
  inputClassName,
  disabled,
  label,
  options,
  placeholder,
  id,
  name,
  hasError,
  required,
  onChange,
  errMsg,
  helperText,
  helperTextClass,
  isMultipleSelect,
  wrapperClass
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations, shared } = messages[lang];

  return (
    <div
      className={`select-wrapper d-flex justify-content-center flex-column ${wrapperClass} ${
        isMultipleSelect
          ? "multiple-select-wrapper"
          : "single-select-wrapper"
      }`}>
      <Autocomplete
        className={`${inputClassName} ${
          isMultipleSelect && value?.length && "removed-placeholder"
        }`}
        name={name}
        multiple={isMultipleSelect}
        id={id || name}
        options={options}
        filterSelectedOptions
        disabled={disabled}
        getOptionLabel={(option) => option.label || ""}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id
        }
        noOptionsText={shared.noOptions}
        value={value || (isMultipleSelect ? [] : null)}
        onChange={(_, value, reason, details) => {
          onChange(value, reason, details?.option);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              required: false,
              shrink: true,
              variant: "outlined"
            }}
            label={
              <span
                className={`fsize-13 fweight-300 title-color ${labelClassName}`}>
                {label}
              </span>
            }
            placeholder={
              isMultipleSelect
                ? value?.length
                  ? ""
                  : placeholder
                : placeholder
            }
            required={required}
            error={hasError}
            className={`${
              isMultipleSelect === true ? "multi-select-style" : ""
            } ${lang === "en" ? "" : "rtl-icons-direction-handle"}`}
            dir={lang === "en" ? "ltr" : "rtl"}
          />
        )}
        renderOption={(props, option, state) => {
          return (
            <li {...props} key={state.index}>
              {option.label}
            </li>
          );
        }}
      />
      <FormHelperText
        error={hasError}
        className={`${helperTextClass || "fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {errMsg?.trim() && hasError ? (
          <>{inputsValidations[errMsg]}</>
        ) : (
          helperText
        )}
      </FormHelperText>
    </div>
  );
};

export default Select;

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  helperTextClass: PropTypes.string,
  errMsg: PropTypes.string,
  value: PropTypes.any,
  isMultipleSelect: PropTypes.bool,
  wrapperClass: PropTypes.string,
  boxLabelStyle: PropTypes.object
};
