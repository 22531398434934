import PropTypes from "prop-types";

export const SettingsIcon = ({ active, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...props}>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M10 12.785a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M1.667 11.017V9.55c0-.866.708-1.583 1.583-1.583 1.508 0 2.125-1.067 1.367-2.375A1.583 1.583 0 015.2 3.434l1.441-.825c.659-.392 1.509-.159 1.9.5l.092.158c.75 1.308 1.984 1.308 2.742 0l.091-.158c.392-.659 1.242-.892 1.9-.5l1.442.825a1.583 1.583 0 01.584 2.158c-.759 1.308-.142 2.375 1.366 2.375.867 0 1.584.708 1.584 1.583v1.467c0 .867-.709 1.583-1.584 1.583-1.508 0-2.125 1.067-1.366 2.375a1.58 1.58 0 01-.584 2.159l-1.441.825c-.659.391-1.509.158-1.9-.5l-.092-.159c-.75-1.308-1.983-1.308-2.742 0l-.091.159c-.392.658-1.242.891-1.9.5L5.2 17.134a1.582 1.582 0 01-.583-2.159c.758-1.308.141-2.375-1.367-2.375a1.588 1.588 0 01-1.583-1.583z"></path>
    </svg>
  );
};

SettingsIcon.propTypes = {
  active: PropTypes.bool
};
