import { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  OutlinedInput,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import "./NumberRangeFilter.scss";

const NumberRangeFilter = ({
  name,
  label,
  endAdorment,
  clearFilters,
  collabsible,
  value,
  setValue,
  hasError,
  setHasError,
  filterClass
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, inputsValidations } = messages[lang];

  useEffect(() => {
    if (clearFilters) {
      setValue({ from: null, to: null });
    }
  }, [clearFilters]);

  useEffect(() => {
    if (value.from && value.to) {
      if (+value.from > +value.to) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    } else {
      setHasError(false);
    }
  }, [value.from, value.to]);

  const renderFilterContent = () => (
    <div
      className={`range-inputs row ${collabsible && "mt-2"} ${filterClass}`}>
      <div className="col-4">
        <OutlinedInput
          id={`${name}-from`}
          name={`${name}-from`}
          variant="outlined"
          className="range-input border-radius-5 w-100"
          type="number"
          min={0}
          onKeyDown={(e) => {
            ["+", "-", "e", "E"].includes(e.key) &&
              e.preventDefault();
          }}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            const pasteData = e.clipboardData.getData("text");
            if (!/^\d+$/.test(pasteData)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (e.target.value >= 0 || e.target.value === "") {
              setValue({
                from: e.target.value,
                to: value.to
              });
            }
          }}
          value={value.from || ""}
        />
      </div>
      <div className="col-1 p-0 d-flex align-items-center justify-content-center">
        <span className="fsize-15 fweight-500 title-color">
          {shared.to}
        </span>
      </div>
      <div className="col-4">
        <OutlinedInput
          id={`${name}-to`}
          name={`${name}-to`}
          variant="outlined"
          className="range-input border-radius-5 w-100"
          type="number"
          min={0}
          onKeyDown={(e) => {
            ["+", "-", "e", "E"].includes(e.key) &&
              e.preventDefault();
          }}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onChange={(e) => {
            if (e.target.value >= 0 || e.target.value === "") {
              setValue({
                from: value.from,
                to: e.target.value
              });
            }
          }}
          value={value.to || ""}
        />
      </div>
      <div className="col-1 p-0 d-flex align-items-center justify-content-center">
        <span className="fsize-15 fweight-600 title-color">
          {endAdorment}
        </span>
      </div>
      {hasError && (
        <div className="col-12">
          <p className="text-error fsize-12 mt-2 mb-0">
            {inputsValidations.fromLessThanTo}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className="number-range-filter">
      {collabsible ? (
        <div className="collabsible-filter">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-${name}-content`}
              id={`panel-${name}-header`}>
              <h6 className="fweight-600 fsize-16 mb-0 title-color">
                {label}
              </h6>
            </AccordionSummary>
            <AccordionDetails>
              {renderFilterContent()}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className="non-collapsible-filter">
          <h6 className="fweight-600 fsize-16 mb-3 title-color">
            {label}
          </h6>
          {renderFilterContent()}
        </div>
      )}
    </div>
  );
};

export default NumberRangeFilter;

NumberRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  endAdorment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  clearFilters: PropTypes.bool,
  collabsible: PropTypes.bool,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  setValue: PropTypes.func,
  hasError: PropTypes.bool,
  setHasError: PropTypes.func,
  filterClass: PropTypes.string
};
