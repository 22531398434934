import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./EmptyState.scss";

const EmptyState = ({
  subTitle,
  description,
  buttonLabel,
  onButtonClick,
  showActionButton,
  imgSrc,
  className,
  buttonClassName,
  descriptionClassName,
  rowViewMode
}) => (
  <div
    className={`empty-state-container d-flex   align-items-center ${
      rowViewMode
        ? "rowView  row text-center text-lg-start"
        : "colView"
    } py-4  ${className}`}>
    {
      <img
        src={imgSrc}
        alt="No data img"
        className={`mb-3 ${rowViewMode ? "col-lg-3 offset-lg-1" : ""}`}
      />
    }
    <div
      className={` emptyPlaceholder-content ${rowViewMode ? "col-lg-4" : ""}`}>
      {subTitle && (
        <p className="fsize-18 font-medium subTitle light-text-color">
          {subTitle}
        </p>
      )}
      {description && (
        <p
          className={` fsize-16 font-medium description  ${descriptionClassName}`}>
          {description}
        </p>
      )}
      {showActionButton && (
        <Button
          label={buttonLabel}
          type="button"
          labelClass="fsize-16 text-white font-regular"
          onClick={onButtonClick}
          block={true}
          className={buttonClassName}
        />
      )}
    </div>
  </div>
);

export default EmptyState;

EmptyState.propTypes = {
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  description: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onButtonClick: PropTypes.func,
  showActionButton: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  rowViewMode: PropTypes.bool,
  imgSrc: PropTypes.string
};
