import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
  IconButton
} from "@mui/material";
import { ExpandLess, ExpandMore, Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import coloredLogo from "../../../assets/images/colored-logo.png";
import { isExporterRole } from "../../../utils/Helpers";
import {
  PERMISSIONS_IDS,
  hasPermission
} from "../../../utils/Permissions";
import {
  CompanyIcon,
  ProductRequestsIcon,
  ProductsIcon,
  SettingsIcon
} from "../../../utils/Icons/SideMenuIcons";
import { MyAccountIcon } from "../../../utils/Icons";
import "./SideMenu.scss";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const SideMenu = ({
  closeMobileSideMenu,
  drawerWidth,
  footerHeight,
  mobileSideMenuOpen
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  const [path, setPath] = useState("");
  const [openItemId, setOpenItemId] = useState(1);

  const handleItemClick = (itemId) => {
    if (openItemId === itemId) {
      setOpenItemId(null);
    } else {
      setOpenItemId(itemId);
    }
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  const activeRoute = (activekeys) => {
    return activekeys?.some((key) => path.includes(key));
  };

  const sideMenuItems = [
    isExporterRole(userCurrentRole, userType) &&
      hasPermission(PERMISSIONS_IDS.company_profile_management) && {
        id: 1,
        title: "company",
        icon: <CompanyIcon />,
        iconActive: <CompanyIcon active />,
        slug: ROUTE_PATHS.companyProfile,
        activekeys: ["company"],
        hasChildren: false,
        children: []
      },
    isExporterRole(userCurrentRole, userType) &&
      hasPermission(PERMISSIONS_IDS.products_management) && {
        id: 2,
        title: "products",
        icon: <ProductsIcon />,
        iconActive: <ProductsIcon active />,
        slug: ROUTE_PATHS.products,
        activekeys: ["products"],
        hasChildren: false,
        children: []
      },
    isExporterRole(userCurrentRole, userType) &&
      (hasPermission(PERMISSIONS_IDS.profile_management) ||
        hasPermission(PERMISSIONS_IDS.subscription_management) ||
        hasPermission(PERMISSIONS_IDS.sub_accounts_management)) && {
        id: 3,
        title: "myAccount",
        icon: <MyAccountIcon />,
        iconActive: <MyAccountIcon active />,
        slug: ROUTE_PATHS.myAccount,
        activekeys: ["my-account"],
        hasChildren: true,
        children: [
          hasPermission(PERMISSIONS_IDS.profile_management) && {
            id: 3.1,
            title: "profile",
            slug: ROUTE_PATHS.viewProfile,
            activekeys: ["profile"]
          },
          hasPermission(PERMISSIONS_IDS.subscription_management) && {
            id: 3.2,
            title: "subscription",
            slug: ROUTE_PATHS.subscription,
            activekeys: ["subscription"]
          },
          hasPermission(PERMISSIONS_IDS.sub_accounts_management) && {
            id: 3.3,
            title: "subAccounts",
            slug: ROUTE_PATHS.subAccounts,
            activekeys: ["sub-accounts"]
          }
        ]
      },
    isExporterRole(userCurrentRole, userType) &&
      hasPermission(PERMISSIONS_IDS.settings_management) && {
        id: 4,
        title: "settings",
        icon: <SettingsIcon />,
        iconActive: <SettingsIcon active />,
        slug: ROUTE_PATHS.settings,
        activekeys: ["settings"],
        hasChildren: false,
        children: []
      },
    !isExporterRole(userCurrentRole, userType) && {
      id: 5,
      title: "productRequests",
      icon: <ProductRequestsIcon />,
      iconActive: <ProductRequestsIcon active />,
      slug: ROUTE_PATHS.importerProductRequests,
      activekeys: ["product-requests"],
      hasChildren: false,
      children: []
    },
    !isExporterRole(userCurrentRole, userType) && {
      id: 6,
      title: "myProfile",
      icon: <MyAccountIcon />,
      iconActive: <MyAccountIcon active />,
      slug: ROUTE_PATHS.viewProfile,
      activekeys: ["my-account"],
      hasChildren: false,
      children: []
    },
    !isExporterRole(userCurrentRole, userType) && {
      id: 7,
      title: "settings",
      icon: <SettingsIcon />,
      iconActive: <SettingsIcon active />,
      slug: ROUTE_PATHS.settings,
      activekeys: ["settings"],
      hasChildren: false,
      children: []
    }
  ];

  const renderSideMenuItems = (device) => (
    <div className="side-menu-container pt-4">
      <DrawerHeader>
        {(device === "mobile" || device === "tablet") && (
          <div className="d-flex align-items-center justify-content-between w-100">
            <img
              src={coloredLogo}
              className="mobile-logo pointer"
              onClick={() => {
                navigate(ROUTE_PATHS.home);
              }}
            />
            <IconButton
              onClick={closeMobileSideMenu}
              className="close-side-menu-icon">
              <Close />
            </IconButton>
          </div>
        )}
      </DrawerHeader>
      <List className="side-menu-list">
        {sideMenuItems.map(
          (item) =>
            item &&
            (item?.hasChildren ? (
              <div key={`side-menu-item-${item?.id}`}>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item"
                  onClick={() => handleItemClick(item?.id)}>
                  <ListItemButton
                    selected={activeRoute(item?.activekeys)}
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5
                    }}
                    className="list-item-wrapper">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center"
                      }}>
                      {activeRoute(item?.activekeys)
                        ? item?.iconActive
                        : item?.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={shared.sideMenu[item?.title]}
                      sx={{
                        display: "block",
                        textAlign: "start"
                      }}
                      className="list-item-label"
                    />
                    {openItemId === item?.id ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={openItemId === item?.id}
                  timeout="auto"
                  unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className="child-sub-list ">
                    {item?.children.map(
                      (child, index) =>
                        child && (
                          <Link
                            to={child?.slug}
                            key={`side-menu-item-${index}`}>
                            <ListItem
                              disablePadding
                              sx={{ display: "block" }}
                              className="list-item child-list-item">
                              <ListItemButton
                                selected={activeRoute(
                                  child?.activekeys
                                )}
                                sx={{
                                  minHeight: 48,
                                  justifyContent: "initial",
                                  px: 2.5
                                }}
                                className={`list-item-wrapper `}>
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: 1,
                                    justifyContent: "center"
                                  }}>
                                  {child?.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    shared.sideMenu[child?.title]
                                  }
                                  sx={{
                                    display: "block",
                                    textAlign: "start"
                                  }}
                                  className="list-item-label"
                                />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        )
                    )}
                  </List>
                </Collapse>
              </div>
            ) : (
              <Link
                to={item?.slug}
                key={`side-menu-item-${item?.id}`}>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item">
                  <ListItemButton
                    selected={activeRoute(item?.activekeys)}
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5
                    }}
                    className="list-item-wrapper ">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center"
                      }}>
                      {activeRoute(item?.activekeys)
                        ? item?.iconActive
                        : item?.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={shared.sideMenu[item?.title]}
                      sx={{
                        display: "block",
                        textAlign: "start"
                      }}
                      className="list-item-label"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))
        )}
      </List>
    </div>
  );

  const desktopMenuCustomStyle = {
    display: { xs: "none", sm: "none", md: "block" },
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: (theme) =>
        theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
      boxSizing: "border-box"
    }
  };

  const mobileMenuCustomStyle = {
    display: { xs: "block", md: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth
    }
  };

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
      className="side-menu position-fixed">
      <Drawer
        variant="temporary"
        open={mobileSideMenuOpen}
        onClose={closeMobileSideMenu}
        ModalProps={{
          keepMounted: true
        }}
        sx={mobileMenuCustomStyle}
        className="mobile-side-menu"
        anchor={lang === "en" ? "left" : "right"}>
        {renderSideMenuItems("mobile")}
      </Drawer>
      <Drawer
        style={{
          height: `calc( 100vh - ${footerHeight}px )`
        }}
        variant="permanent"
        open={true}
        sx={desktopMenuCustomStyle}
        className="desktop-side-menu">
        {renderSideMenuItems("desktop")}
      </Drawer>
    </Box>
  );
};

SideMenu.propTypes = {
  closeMobileSideMenu: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,

  footerHeight: PropTypes.number,
  mobileSideMenuOpen: PropTypes.bool.isRequired
};

export default SideMenu;
