import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import Breadcrumbs from "../../../components/Breadcrumbs";
import Stepper from "../../../components/Stepper";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  DirectBoxSendIcon,
  ShieldIcon,
  WalletCheckIcon
} from "../../../utils/Icons";
import SelectVerificationMethod from "./Steps/SelectVerificationMethod";
import PaymentInformation from "./Steps/PaymentInformation";
import {
  getCompanyVerificationStatusRequest,
  getVerificationMethodsRequest,
  setSelectedVerificationMethod,
  setVerificationStep
} from "../../../store/Verification/actions";
import "./Verification.scss";
import {
  COMPANY_VERIFICATION_STATUS,
  VERIFICATION_METHODS
} from "../../../utils/Constants";
import UploadDocuments from "./Steps/UploadDocuments";

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [steps, setSteps] = useState([]);

  const lang = useSelector((state) => state.locale.lang);
  const { verification } = messages[lang];

  const activeStep = useSelector(
    (state) => state.verification.verificationStep
  );
  const selectedMethod = useSelector(
    (state) => state.verification.selectedMethod
  );
  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );
  const isBusinessVerified = useSelector(
    (state) => state.auth.login?.IsBusinessVerified
  );

  useEffect(() => {
    dispatch(getCompanyVerificationStatusRequest());
    dispatch(getVerificationMethodsRequest());
    return () => {
      dispatch(setVerificationStep(0));
      dispatch(setSelectedVerificationMethod(null));
    };
  }, []);

  useEffect(() => {
    switch (companyVerificationStatus) {
      case COMPANY_VERIFICATION_STATUS.beforeVerify:
      case COMPANY_VERIFICATION_STATUS.rejected:
        dispatch(setVerificationStep(0));
        dispatch(
          setSelectedVerificationMethod(
            VERIFICATION_METHODS.onlinePayment
          )
        );
        break;
      case COMPANY_VERIFICATION_STATUS.awaitingRequiredDocuments:
      case COMPANY_VERIFICATION_STATUS.pendingDocumentsUpload:
      case COMPANY_VERIFICATION_STATUS.docsNeedUpdates:
        dispatch(setVerificationStep(1));
        dispatch(
          setSelectedVerificationMethod(
            VERIFICATION_METHODS.offPlatform
          )
        );
        break;
      case COMPANY_VERIFICATION_STATUS.documentsUploaded:
      case COMPANY_VERIFICATION_STATUS.pendingPayment:
        dispatch(setVerificationStep(2));
        dispatch(
          setSelectedVerificationMethod(
            VERIFICATION_METHODS.offPlatform
          )
        );
        break;
      case COMPANY_VERIFICATION_STATUS.pendingFinalApproval:
      case COMPANY_VERIFICATION_STATUS.approved:
        navigate(ROUTE_PATHS.products);
        break;
      default:
        break;
    }
  }, [companyVerificationStatus]);

  useEffect(() => {
    if (isBusinessVerified) {
      navigate(ROUTE_PATHS.products);
    }
  }, [isBusinessVerified]);

  useEffect(() => {
    if (selectedMethod) {
      if (selectedMethod === VERIFICATION_METHODS.offPlatform) {
        setSteps([
          {
            label: (
              <div className="step-1-label">
                <p className="mb-0 text-grey-disabled fsize-12">
                  {verification.steps.step1}
                </p>
                <p
                  className={`mb-0 fsize-15 fweight-600 ${activeStep === 0 ? "text-light-blue" : "text-color"}`}>
                  {verification.steps.verificationMethod}
                </p>
              </div>
            ),
            customIcon: <ShieldIcon />
          },
          {
            label: (
              <div className="step-1-label">
                <p className="mb-0 text-grey-disabled fsize-12">
                  {verification.steps.step2}
                </p>
                <p
                  className={`mb-0 fsize-15 fweight-600 ${activeStep === 0 ? "text-grey-disabled" : "text-light-blue"} `}>
                  {verification.steps.uploadDocuments}
                </p>
              </div>
            ),
            customIcon: <DirectBoxSendIcon />
          },
          {
            label: (
              <div className="step-1-label">
                <p className="mb-0 text-grey-disabled fsize-12">
                  {verification.steps.step3}
                </p>
                <p
                  className={`mb-0 fsize-15 fweight-600 ${activeStep === 0 ? "text-grey-disabled" : "text-light-blue"} `}>
                  {verification.steps.paymentInformation}
                </p>
              </div>
            ),
            customIcon: <WalletCheckIcon />
          }
        ]);
      } else {
        setSteps([
          {
            label: (
              <div className="step-1-label">
                <p className="mb-0 text-grey-disabled fsize-12">
                  {verification.steps.step1}
                </p>
                <p
                  className={`mb-0 fsize-15 fweight-600 ${activeStep === 0 ? "text-light-blue" : "text-color"}`}>
                  {verification.steps.verificationMethod}
                </p>
              </div>
            ),
            customIcon: <ShieldIcon />
          },
          {
            label: (
              <div className="step-1-label">
                <p className="mb-0 text-grey-disabled fsize-12">
                  {verification.steps.step2}
                </p>
                <p
                  className={`mb-0 fsize-15 fweight-600 ${activeStep === 0 ? "text-grey-disabled" : "text-light-blue"} `}>
                  {verification.steps.paymentInformation}
                </p>
              </div>
            ),
            customIcon: <WalletCheckIcon />
          }
        ]);
      }
    }
  }, [selectedMethod]);

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${40}px  - ${70}px)`,
        marginTop: "5rem"
      }}
      className="verification-container w-100 px-md-5 px-3 py-4">
      <Breadcrumbs
        list={[
          {
            label: verification.breadCrumbs.home,
            link: ROUTE_PATHS.home
          },
          {
            label: verification.breadCrumbs.verification
          }
        ]}
      />
      <div className="title-section mb-4">
        <h3 className="fsize-22 fweight-700 title-color">
          {verification.exporterVerification}
        </h3>
      </div>
      <div className="stepper mb-5">
        <Stepper
          steps={steps}
          activeStep={activeStep}
          wrapperClassName={"verification-stepper"}
        />
      </div>
      <Box
        className="content-section"
        sx={{
          width: {
            lg: "80%"
          },
          margin: "0 auto"
        }}>
        {activeStep === 0 && <SelectVerificationMethod />}
        {selectedMethod === VERIFICATION_METHODS.offPlatform ? (
          <>
            {activeStep === 1 && <UploadDocuments />}
            {activeStep === 2 && <PaymentInformation />}
          </>
        ) : (
          <>{activeStep === 1 && <PaymentInformation />}</>
        )}
      </Box>
    </Box>
  );
};

export default Verification;
