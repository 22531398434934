const search = {
  searchPlaceholder: "What are you looking for",
  search: "Search",
  suggestedResults: "Suggested Results",
  noSuggestions: "No suggested results",
  showAll: "Show All",
  seeAll: "See All",
  loadMore: "Load More",
  resultsFor: "Results for",
  noresultsFound: "No results found",
  breadcrumbs: {
    home: "Home",
    search: "Search"
  },
  filters: {
    country: "Country",
    searchCountry: "Search by country name",
    subCategory: "Sub Category",
    searchSubCategory: "Search by sub category name",
    priceRange: "Price Range",
    moq: "MOQ"
  },
  tags: {
    all: "All",
    products: "Products",
    subCategories: "Sub Categories",
    exporters: "Exporters"
  },
  advancedSearchFilters: {
    all: "All",
    product: "Product",
    subCategory: "Sub Category",
    category: "Category",
    company: "Company",
    exporter: "Exporter"
  }
};

export default search;
