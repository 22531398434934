import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ChevronRightOutlined } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import ExporterCardGrid from "../../../../components/ExporterCards/ExporterCardGrid";
import Swiper from "../../../../components/Swiper";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const ExportersSection = ({ exporters, cardsNumber }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];

  const [exportersCards, setExportersCards] = useState([]);

  useEffect(() => {
    if (exporters?.length) {
      setExportersCards(
        exporters.map(
          (
            {
              CompanyId,
              TradeName,
              LogoImage,
              CountryName,
              Introduction,
              IsVerified
            },
            i
          ) => (
            <div className="mx-3 mb-3" key={`exporter-${i}`}>
              <ExporterCardGrid
                src={LogoImage}
                label={TradeName}
                country={CountryName}
                description={Introduction}
                link={ROUTE_PATHS.exporterProfile.replace(
                  ":id",
                  CompanyId
                )}
                imgHeight={"12rem"}
                cardHeight={"22rem"}
                isVerified={IsVerified}
              />
            </div>
          )
        )
      );
    }
  }, [exporters]);

  return (
    <div className="exporters-section-container">
      <div className="d-flex align-items-lg-center align-items-start justify-content-between flex-sm-row flex-column mb-3">
        <h4 className="fweight-700 fsize-22 mb-0">
          {home.popularExporters}
        </h4>
        <Link to={ROUTE_PATHS.exporters}>
          <span className="text-primary fweight-600 fsize-16 highlighted-text pointer">
            {home.viewAll} <ChevronRightOutlined />
          </span>
        </Link>
      </div>
      <div className="exporters-slider">
        <Swiper
          slides={exportersCards}
          slidesToShow={1}
          infinite={false}
          speed={750}
          sliderClassName={"exporters-slider"}
          name={"exporters-slider"}
          responsiveSettings={{
            480: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: cardsNumber
            }
          }}
        />
      </div>
    </div>
  );
};

export default ExportersSection;

ExportersSection.propTypes = {
  exporters: PropTypes.array.isRequired,
  cardsNumber: PropTypes.number
};
ExportersSection.defaultProps = {
  cardsNumber: 4
};
