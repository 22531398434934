import {
  GET_ALL_SEARCH_RESULTS_RESPONSE,
  GET_SEARCH_SUB_CATEGORIES_RESPONSE,
  GET_SEARCH_EXPORTERS_RESPONSE,
  GET_SEARCH_PRODUCTS_RESPONSE,
  GET_SEARCH_SUGGESTIONS_RESPONSE,
  SET_TOTAL_SEARCH_COUNT
} from "./types";

const INITIAL_STATE = {
  searchSuggestions: [],
  searchResults: {
    ProductDetails: [],
    SubCategoryDetails: [],
    ExporterDetails: []
  },
  products: {
    list: [],
    meta: {}
  },
  subCategories: {
    list: [],
    meta: {}
  },
  exporters: {
    list: [],
    meta: {}
  },
  totalSearchCount: 0
};

const search = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SEARCH_SUGGESTIONS_RESPONSE:
      return {
        ...state,
        searchSuggestions: action.payload
      };
    case GET_ALL_SEARCH_RESULTS_RESPONSE:
      return {
        ...state,
        searchResults: action.payload
      };
    case GET_SEARCH_PRODUCTS_RESPONSE:
      return {
        ...state,
        products: {
          list:
            action.payload.meta.currentPage > 1
              ? [...state.products.list, ...action.payload.list]
              : action.payload.list,
          meta: action.payload.meta
        }
      };
    case GET_SEARCH_SUB_CATEGORIES_RESPONSE:
      return {
        ...state,
        subCategories: {
          list:
            action.payload.meta.currentPage > 1
              ? [...state.subCategories.list, ...action.payload.list]
              : action.payload.list,
          meta: action.payload.meta
        }
      };
    case GET_SEARCH_EXPORTERS_RESPONSE:
      return {
        ...state,
        exporters: {
          list:
            action.payload.meta.currentPage > 1
              ? [...state.exporters.list, ...action.payload.list]
              : action.payload.list,
          meta: action.payload.meta
        }
      };
    case SET_TOTAL_SEARCH_COUNT:
      return {
        ...state,
        totalSearchCount: action.payload
      };
    default:
      return state;
  }
};

export default search;
