export const GoToArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19">
    <path
      stroke="#5A6D76"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M15.375 16.904H2.625"></path>
    <path
      stroke="#5A6D76"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      d="M14.25 3.03l-10.5 10.5M14.25 10.732V3.029H6.548"></path>
  </svg>
);
