import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { DeleteModalIcon } from "../../../../utils/Icons";
import { revertEditRequest } from "../../../../store/Products/actions";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const CancelProductEdits = ({ open, closeModal, productInfo }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    products: {
      cancelProductEdits: {
        content,
        contentAction,
        confirmationContent,
        subConfirmationContent
      }
    },
    shared
  } = messages[lang];

  const handleConfirmCancel = () => {
    dispatch(
      revertEditRequest({
        data: {
          id: productInfo.id
        },
        params: {
          id: productInfo.id,
          name: productInfo.productName,
          src: ROUTE_PATHS.viewProduct
        },
        navigate
      })
    );
    closeModal();
  };

  const CancelProductEditsContent = () => {
    return (
      <div className="delete-product-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${productInfo.name}" ${contentAction}`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {subConfirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmCancel();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={CancelProductEditsContent()}
      hasActions={true}
      confirmBtnTxt={shared.confirm}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
    />
  );
};

export default CancelProductEdits;

CancelProductEdits.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired
};
