import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import img1 from "../../../../assets/images/home/image 7.png";
import img2 from "../../../../assets/images/home/image 6.png";

const ExploreSection = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];

  const renderExploreCard = (title, content, img, isPinkBg) => (
    <div
      className={`explore-card border-radius-10 py-md-0 py-4 ${isPinkBg ? "pink-bg" : "medium-blue-bg"} ${isPinkBg && "mb-lg-0 mb-3"}`}>
      <div className="row h-100">
        <div className="col-xl-8 col-md-7 col-12 my-lg-3 my-0">
          <div
            className={`content h-100 d-flex flex-column justify-content-center align-items-start ${isPinkBg ? "mx-md-5 mx-3" : " mx-md-5 mx-3"}`}>
            <h3 className="fsize-24 fweight-700 mb-3 title-color">
              {title}
            </h3>
            <p className="fsize-16 text-color">{content}</p>
          </div>
        </div>
        <div className="col-xl-4 col-md-5  d-md-block d-none">
          <div className="avatar h-100">
            <img src={img} className="w-100 h-100" />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="explore-section-container">
      <div className="d-flex justify-content-between flex-lg-row flex-column">
        {renderExploreCard(
          home.explore.title1,
          home.explore.paragraph1,
          img1,
          true
        )}
        {renderExploreCard(
          home.explore.title2,
          home.explore.paragraph2,
          img2,
          false
        )}
      </div>
    </div>
  );
};

export default ExploreSection;
