export const GuideCrossRedBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    fill="none"
    viewBox="0 0 31 31"
    {...props}>
    <circle
      cx="15.5"
      cy="15.509"
      r="14"
      fill="#D32F2F"
      stroke="#F1FAFF"
      strokeWidth="2"></circle>
    <path
      fill="#fff"
      d="M20.629 11.212l-1.058-1.057-4.192 4.192-4.193-4.192-1.057 1.057 4.192 4.193-4.192 4.192 1.057 1.058 4.193-4.193 4.192 4.193 1.058-1.058-4.193-4.192 4.193-4.193z"></path>
  </svg>
);
