const resetPassword = {
  setNewPassword: "Set A New Password",
  resetPassword: "Reset Password",
  labels: {
    oldPassword: "current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm Password"
  },
  placeholders: {
    newPassword: "Enter your new password",
    confirmNewPassword: "Enter confirm password"
  },
  actionBTN: "Reset Password"
};

export default resetPassword;
