export const SET_CATEGORY_SUGGESTION_MODAL_OPEN =
  "SET_CATEGORY_SUGGESTION_MODAL_OPEN";
export const SUGGEST_NEW_CATEGORY_REQUEST =
  "SUGGEST_NEW_CATEGORY_REQUEST";
export const SET_SUGGESTED_CATEGORY_TEMP_ID =
  "SET_SUGGESTED_CATEGORY_TEMP_ID";
export const GET_CATEGORY_DETAILS_REQUEST =
  "GET_CATEGORY_DETAILS_REQUEST";
export const GET_CATEGORY_DETAILS_RESPONSE =
  "GET_CATEGORY_DETAILS_RESPONSE";
export const SET_SUGGESTED_CATEGORY_DATA =
  "SET_SUGGESTED_CATEGORY_DATA";
export const GET_CATEGORY_DROPDOWN_RESPONSE =
  "GET_CATEGORY_DROPDOWN_RESPONSE";
export const GET_CATEGORY_DROPDOWN_REQUEST =
  "GET_CATEGORY_DROPDOWN_REQUEST";
