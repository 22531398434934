export const LoginBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    fill="none"
    viewBox="0 0 69 69">
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.5"
      fill="#DEF3FF"
      rx="28"></rect>
    <rect
      width="60"
      height="60"
      x="4.5"
      y="4.5"
      stroke="#F1FAFF"
      strokeWidth="8"
      rx="28"></rect>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M30.625 28.95c.387-4.5 2.7-6.337 7.763-6.337h.162c5.588 0 7.825 2.238 7.825 7.825v8.15c0 5.588-2.237 7.825-7.825 7.825h-.162c-5.026 0-7.338-1.812-7.75-6.237M22 34.5h16.1"></path>
    <path
      stroke="#0071BC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M35.313 30.313L39.5 34.5l-4.188 4.188"></path>
  </svg>
);
