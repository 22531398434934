import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import messages from "../../../../../assets/locale/messages";
import envelopeImg from "../../../../../assets/images/vectors/envelopeArrow.png";

const WaitForDocuments = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      offPlatform: { waitForDocuments }
    }
  } = messages[lang];

  return (
    <div className="wait-for-documents-container">
      <div className="border-radius-10 light-border text-center py-5">
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "80%",
              lg: "50%"
            },
            margin: "0 auto"
          }}>
          <img src={envelopeImg} className="mb-3" />
          <p className="fsize-22 fweight-600 title-color">
            {waitForDocuments.chooseOffplatformConfirmTitle}
          </p>
          <p className="text-color">
            {waitForDocuments.chooseOffplatformConfirmMsg}
          </p>
        </Box>
      </div>
    </div>
  );
};

export default WaitForDocuments;
