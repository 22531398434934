import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import Navbar from "./Navbar";
import Footer from "./Footer";
import CopyrightsFooter from "./CopyrightsFooter";
import NotificationSnackbar from "../NotificationSnackbar";
import { setImporterLayoutContainerRef } from "../../store/Navigation/actions";
import { getUserStatusRequest } from "../../store/User/actions";

const ImporterLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef();
  const isAuth = useSelector((state) => state.auth.login?.Token);

  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    containerRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (isAuth) dispatch(getUserStatusRequest());
  }, [location, containerRef, isAuth, dispatch]);

  useEffect(() => {
    dispatch(setImporterLayoutContainerRef(containerRef));
  }, [containerRef, dispatch]);

  const handleNavbarHeightChange = useCallback((height) => {
    setNavbarHeight(height);
  }, []);

  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobileDevice(width <= 426);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      className="importers-layout-container"
      sx={{
        height: "100vh",
        pt: `${isMobileDevice ? 0 : navbarHeight}px`,
        transition: "padding-top 0.3s ease-in-out"
      }}>
      <div
        ref={containerRef}
        className="importer-layout-main-section scroll-within"
        style={{ height: "100%" }}>
        <Navbar onHeightChange={handleNavbarHeightChange} />
        <Outlet />
        <Footer />
        <CopyrightsFooter />
        <NotificationSnackbar />
      </div>
    </Box>
  );
};

export default ImporterLayout;
