import PropTypes from "prop-types";

export const GreyCalendarIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "24"}
    height={dimensions || "24"}
    fill="none"
    viewBox="0 0 24 25"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M8 2.951v3M16 2.951v3M3.5 10.041h17M21 9.451v8.5c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5v-8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5z"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.694 14.65h.009M15.694 17.65h.009M11.995 14.65h.009M11.995 17.65h.009M8.295 14.65h.01M8.295 17.65h.01"></path>
  </svg>
);

GreyCalendarIcon.propTypes = {
  dimensions: PropTypes.string
};
