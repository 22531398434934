export const MoneyBillCheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="23"
    fill="none"
    viewBox="0 0 28 23">
    <path
      fill="#8F8F8F"
      d="M13.688 18.094c0 .502-.42.91-.938.91h-7.5c-3.022 0-4.688-1.616-4.688-4.547V4.76C.563 1.828 2.228.213 5.25.213h15c3.023 0 4.688 1.615 4.688 4.546v3.637c0 .502-.42.909-.938.909-.517 0-.938-.407-.938-.91V4.76c0-1.912-.84-2.728-2.812-2.728h-15c-1.971 0-2.813.816-2.813 2.728v9.698c0 1.912.842 2.728 2.813 2.728h7.5c.518 0 .938.407.938.91zM4 9.608c0 .67.56 1.212 1.25 1.212s1.25-.543 1.25-1.212c0-.67-.56-1.212-1.25-1.212S4 8.939 4 9.608zm8.75-4.546c2.585 0 4.688 2.04 4.688 4.546 0 2.507-2.103 4.546-4.688 4.546-2.585 0-4.688-2.039-4.688-4.546s2.103-4.546 4.688-4.546zm0 1.818c-1.551 0-2.813 1.224-2.813 2.728 0 1.505 1.262 2.728 2.813 2.728 1.551 0 2.813-1.223 2.813-2.728 0-1.504-1.262-2.728-2.813-2.728zm14.688 10.002c0 3.175-2.664 5.758-5.938 5.758-3.274 0-5.938-2.583-5.938-5.758s2.664-5.759 5.938-5.759c3.274 0 5.938 2.584 5.938 5.759zm-1.875 0c0-2.173-1.823-3.94-4.063-3.94-2.24 0-4.063 1.767-4.063 3.94 0 2.172 1.823 3.94 4.063 3.94 2.24 0 4.063-1.768 4.063-3.94zM22.4 15.228l-1.42 1.377-.379-.367a.96.96 0 00-1.326 0 .89.89 0 000 1.286l1.041 1.01a.95.95 0 00.663.266c.24 0 .48-.089.662-.267l2.084-2.02a.89.89 0 000-1.287.962.962 0 00-1.325.002z"></path>
  </svg>
);
