import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/myAccount";
import * as types from "./types";
import * as actions from "./actions";
import {
  checkBackgroundImageValidity,
  showErrorMsg,
  showSuccessMsg
} from "../../utils/Helpers";
import { loginResponse } from "../Auth/actions";
import { setUser } from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { deleteBlob } from "../../utils/blobStorageServices";
import store from "..";

function* getExporterProfileSaga({ payload }) {
  try {
    const response = yield call(apis.getExporterProfile, payload);
    yield put(actions.getExporterProfileResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getExportersProfileTransactionsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getExporterProfileTransactionsList,
      payload
    );
    const {
      data: {
        Data: { Items, CurrentPage, ItemCount, TotalPages }
      }
    } = response;
    yield put(
      actions.getExporterProfileTransactionsResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editExporterProfileDetailsSaga({ payload }) {
  const { data, dbData } = payload;
  try {
    yield call(apis.editExporterProfileDetails, data);

    if (dbData.profileImageInfo.isNew) {
      checkBackgroundImageValidity(
        dbData.dbProfileImage,
        (exists) => {
          if (exists) {
            deleteBlob(
              dbData.dbProfileImage?.split("/images/")[1],
              "images"
            );
          }
        }
      );
    }

    const userData = store.getState().auth.login;
    const updatedUserDataWithUserImage = {
      ...userData,
      ProfileImage: dbData.newImageUrl,
      UserName: data.Name
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithUserImage));
    yield rememberMe
      ? setUser(updatedUserDataWithUserImage, "local")
      : setUser(updatedUserDataWithUserImage, "session");

    yield showSuccessMsg("editProfileDetailsSuccess");
    yield payload.navigate(ROUTE_PATHS.viewProfile);
  } catch (error) {
    yield deleteBlob(data.ProfileImage, "images");
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* editImporterProfileDetailsSaga({ payload }) {
  const { data, dbData } = payload;
  try {
    yield call(apis.editImporterProfileDetails, data);

    if (dbData.profileImageInfo.isNew) {
      checkBackgroundImageValidity(
        dbData.dbProfileImage,
        (exists) => {
          if (exists) {
            deleteBlob(
              dbData.dbProfileImage?.split("/images/")[1],
              "images"
            );
          }
        }
      );
    }

    const userData = store.getState().auth.login;
    const updatedUserDataWithUserImage = {
      ...userData,
      ProfileImage: dbData.newImageUrl,
      UserName: data.Name
    };
    const rememberMe = !!localStorage.getItem("user");
    yield put(loginResponse(updatedUserDataWithUserImage));
    yield rememberMe
      ? setUser(updatedUserDataWithUserImage, "local")
      : setUser(updatedUserDataWithUserImage, "session");

    yield showSuccessMsg("editProfileDetailsSuccess");
    yield payload.navigate(ROUTE_PATHS.viewProfile);
  } catch (error) {
    yield deleteBlob(data.ProfileImage, "images");
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* MyAccountSagas() {
  yield takeLatest(
    types.GET_EXPORTER_PROFILE_REQUEST,
    getExporterProfileSaga
  );
  yield takeLatest(
    types.GET_EXPORTER_PROFILE_TRANSACTIONS_REQUEST,
    getExportersProfileTransactionsSaga
  );
  yield takeEvery(
    types.EDIT_EXPORTER_PROFILE_DETAILS_REQUEST,
    editExporterProfileDetailsSaga
  );
  yield takeEvery(
    types.EDIT_IMPORTER_PROFILE_DETAILS_REQUEST,
    editImporterProfileDetailsSaga
  );
}
