import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import { CardBgIcon } from "../../../../utils/Icons";
import CardInfo from "./CardInfo";
import {
  CURRENCIES_IDS,
  STRIPE_CURRENCIES_NAMES,
  VERIFICATION_METHODS
} from "../../../../utils/Constants";

const OnlinePayment = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { onlinePayment }
  } = messages[lang];
  const verificationMethods = useSelector(
    (state) => state.verification.verificationMethods
  );

  const [onlineMethod, setOnlineMethod] = useState(null);

  useEffect(() => {
    if (verificationMethods?.length) {
      setOnlineMethod(
        verificationMethods.find(
          (method) => method.Id === VERIFICATION_METHODS.onlinePayment
        )
      );
    }
  }, [verificationMethods]);

  return (
    <div className="online-payment-container">
      <div className="method-declaration mb-4">
        <Box
          className="border-radius-10 light-border d-flex align-items-center p-3"
          sx={{
            width: {
              xs: "100%",
              md: "40%"
            }
          }}>
          <CardBgIcon />
          <p className="ms-3 mb-0 fsize-16 fweight-600 title-color">
            {onlinePayment.verificationMethod}
            {onlinePayment.onlinePayment}
          </p>
        </Box>
      </div>
      <CardInfo
        paymentInfo={{
          amount: onlineMethod?.Fees,
          currency:
            onlineMethod?.CurrencyId === CURRENCIES_IDS.usd
              ? STRIPE_CURRENCIES_NAMES.usd
              : STRIPE_CURRENCIES_NAMES.euro
        }}
      />
    </div>
  );
};

export default OnlinePayment;
