import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Verified } from "@mui/icons-material";

import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import messages from "../../../assets/locale/messages";
import TruncateText from "../../../components/TruncateText";
import { LocationIcon, MaintenanceIcon } from "../../../utils/Icons";
import "./ExporterCardGrid.scss";

const ExporterCardGrid = ({
  src,
  label,
  country,
  description,
  link,
  imgHeight,
  cardHeight,
  isVerified
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  const [truncateLength, setTruncateLength] = useState(0);
  const [notVerifiedTruncateLength, setNotVerifiedTruncateLength] =
    useState("");

  const handleResize = () => {
    const width = window.innerWidth;

    if (width >= 1440) {
      setTruncateLength(11);
      setNotVerifiedTruncateLength(13);
    } else if (width >= 1024) {
      setTruncateLength(12);
      setNotVerifiedTruncateLength(11);
    } else if (width >= 768) {
      setTruncateLength(14);
      setNotVerifiedTruncateLength(16);
    } else if (width >= 425) {
      setTruncateLength(28);
      setNotVerifiedTruncateLength(29);
    } else if (width >= 375) {
      setTruncateLength(23);
      setNotVerifiedTruncateLength(29);
    } else {
      setTruncateLength(23);
      setNotVerifiedTruncateLength(22);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderCardContent = () => (
    <div
      className={`exporter-card-grid border-radius-10 p-3 grey-bg ${link && isVerified && "pointer box-shadow-hover"} ${!isVerified && "disabled"}`}
      style={{
        height: cardHeight || "18rem"
      }}>
      <div
        className="w-100 card-img-container mb-2 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
        style={{
          height: imgHeight || "8rem"
        }}>
        <img
          src={src || emptyProductPlaceholder}
          onError={onImageError}
          className="w-100 item-cards-img"
        />
      </div>
      <div className="card-label mb-2 d-flex align-items-center justify-content-start text-break">
        <TruncateText
          text={label}
          // linesNo={1}
          length={
            isVerified ? +truncateLength : +notVerifiedTruncateLength
          }
          textClassName={"mb-0 title-color fsize-16 fweight-700"}
        />
        {isVerified && <Verified color="primary" className="ms-2" />}
      </div>
      {country && (
        <div className="country d-flex mb-2 justify-content-start">
          <LocationIcon />
          <span className="text-grey fsize-12 mx-1">{country}</span>
        </div>
      )}
      {description && (
        <div className="mb-2 description">
          <TruncateText
            showTooltip={false}
            text={description}
            linesNo={2}
            textClassName={"text-grey fsize-12 mb-0"}
          />
        </div>
      )}
      <div className="disabled-hovering-layout w-100 h-100 border-radius-10">
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <div className="text-center ">
            <MaintenanceIcon className="mb-1" />
            <p className="text-white mb-0 fsize-15 fweight-600">
              {shared.exporterStillUnverified}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {link && isVerified ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default ExporterCardGrid;

ExporterCardGrid.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  imgHeight: PropTypes.string,
  cardHeight: PropTypes.string,
  isVerified: PropTypes.bool
};
