import PropTypes from "prop-types";

export const ListEditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}>
    <path
      stroke="#AFAFAF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      d="M10.433 3.676l-6.158 6.518c-.232.247-.457.735-.502 1.072l-.278 2.43c-.097.878.533 1.478 1.403 1.328l2.415-.412c.337-.06.81-.308 1.042-.563l6.158-6.518c1.065-1.125 1.545-2.407-.113-3.975-1.65-1.552-2.902-1.005-3.967.12zM9.406 4.764a4.595 4.595 0 004.088 3.863M2.738 17.477h13.5"></path>
  </svg>
);

ListEditIcon.propTypes = {
  active: PropTypes.string
};
