export const EmailFaintBlueBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    fill="none"
    viewBox="0 0 51 51"
    {...props}>
    <rect
      width="50"
      height="50"
      x="0.025"
      y="0.136"
      fill="#F1FAFF"
      rx="8"></rect>
    <path
      fill="#0071BC"
      d="M30.025 34.386h-10c-3.65 0-5.75-2.1-5.75-5.75v-7c0-3.65 2.1-5.75 5.75-5.75h10c3.65 0 5.75 2.1 5.75 5.75v7c0 3.65-2.1 5.75-5.75 5.75m-10-17c-2.86 0-4.25 1.39-4.25 4.25v7c0 2.86 1.39 4.25 4.25 4.25h10c2.86 0 4.25-1.39 4.25-4.25v-7c0-2.86-1.39-4.25-4.25-4.25z"></path>
    <path
      fill="#0071BC"
      d="M25.025 26.006c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 0 1 .93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79"></path>
  </svg>
);
