import { v4 as uuid } from "uuid";
import { browserName } from "react-device-detect";

import store from "../store";
import { getUserSubscriptionPlanResponse } from "../store/Subscription/actions";

export const isAuth = () => {
  const state = store.getState();
  return !!state.auth.login?.Token;
};

export const isSubscribed = () => {
  const state = store.getState();
  return !!state.auth.login?.subscription;
};

export const generateDeviceId = () => {
  return `${browserName}-${uuid()}`;
};

export const setDeviceId = (device_id) => {
  return localStorage.setItem("device_id", device_id);
};

export const getDeviceId = () => {
  return localStorage.getItem("device_id");
};

export const setUser = (user, storage = "session") => {
  storage === "session"
    ? sessionStorage.setItem("user", JSON.stringify(user))
    : localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem(`${user.Id}-isLogged`, true);
};

export const clearUserData = () => {
  localStorage.removeItem("device_id");
  const user = !!localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : !!sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : "";
  localStorage.removeItem(`${user.Id}-isLogged`);
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  localStorage.removeItem("userType");
  sessionStorage.removeItem("userType");
  sessionStorage.removeItem("voucherCode");
  store.dispatch(getUserSubscriptionPlanResponse({}));
};
