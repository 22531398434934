import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

import messages from "../../../assets/locale/messages";
import whiteLogo from "../../../assets/images/white-logo.svg";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  WhitePhoneIcon,
  WhiteEnvelopeIcon
} from "../../../utils/Icons";
import {
  EXGATES_CONTACT_EMAIL,
  EXGATES_CONTACT_PHONE,
  EXGATES_FACEBOOK_LINK,
  EXGATES_INSTAGRAM_LINK,
  EXGATES_LINKEDIN_LINK,
  EXGATES_TWITTER_LINK,
  USER_TYPES_IDS
} from "../../../utils/Constants";
import "./Footer.scss";

const Footer = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    home: { footer }
  } = messages[lang];

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );

  const {
    homeDetails: { CategoryDetails }
  } = useSelector((state) => state.home);

  const subCategories = CategoryDetails?.slice(0, 6);

  const quickLinks = [
    { label: footer.exploreProducts, link: "" },
    { label: footer.exporters, link: ROUTE_PATHS.exporters },
    userType !== USER_TYPES_IDS.importer
      ? { label: footer.pricing, link: ROUTE_PATHS.pricingDetails }
      : null,
    {
      label: footer.productRequests,
      link: ROUTE_PATHS.websiteProductRequests
    }
  ];
  return (
    <div className="footer-section-container  dark-blue-bg p-lg-5 p-3">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-4 col-lg-3 mb-lg-0 mb-5">
            <div className="contact">
              <img src={whiteLogo} className="mb-2" />
              <p className="text-white col-xl-8 col-12">
                {footer.description}
              </p>
              <div className="social-media d-flex justify-content-between col-xl-4 col-lg-8 col-md-2 col-6">
                <a
                  href={EXGATES_FACEBOOK_LINK}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
                <a
                  href={EXGATES_INSTAGRAM_LINK}
                  target="_blank"
                  rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
                <a
                  href={EXGATES_TWITTER_LINK}
                  target="_blank"
                  rel="noopener noreferrer">
                  <TwitterIcon />
                </a>
                <a
                  href={EXGATES_LINKEDIN_LINK}
                  target="_blank"
                  rel="noopener noreferrer">
                  <LinkedinIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 mb-xl-0 mb-lg-4 mb-md-0 mb-4 d-flex justify-content-xl-start justify-content-lg-end justify-content-start">
            <div className="top-sub-categories px-2">
              <h6 className="text-white fweight-700 fsize-16 mb-3">
                {footer.topSubCategories}
              </h6>
              {!!subCategories.length &&
                subCategories?.map((subCategory, i) => (
                  // <p className="text-white" key={`sub-category-${i}`}>
                  //   {subCategory.Name}
                  // </p>
                  <HashLink
                    key={`top-subcategory-link-${i}`}
                    to={ROUTE_PATHS.subCategoryDetails.replace(
                      ":id",
                      subCategory.Id
                    )}
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "smooth"
                      })
                    }>
                    <p
                      className="text-white"
                      key={`sub-category-${i}`}>
                      {subCategory.Name}
                    </p>
                  </HashLink>
                ))}
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 mb-md-0 mb-4 d-flex justify-content-xl-start justify-content-lg-center justify-content-md-center justify-content-start">
            <div className="quick-links px-2">
              <h6 className="text-white fweight-700 fsize-16 mb-3">
                {footer.quickLinks}
              </h6>
              {quickLinks?.map(
                (quickLink, i) =>
                  quickLink && (
                    <HashLink
                      key={`quick-link-${i}`}
                      to={quickLink.link}
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth"
                        })
                      }>
                      <p className="text-white">{quickLink.label}</p>
                    </HashLink>
                  )
              )}
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 mb-md-0 mb-4 d-flex justify-content-xl-start justify-content-lg-end justify-content-md-end justify-content-start">
            <div className="contact-us px-2">
              <h6 className="text-white fweight-700 fsize-16 mb-3">
                {footer.contactUs}
              </h6>
              <p className="text-white">
                <WhitePhoneIcon />
                <span className="ms-2">{EXGATES_CONTACT_PHONE}</span>
              </p>
              <p className="text-white">
                <WhiteEnvelopeIcon />
                <span className="ms-2">{EXGATES_CONTACT_EMAIL}</span>
              </p>
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-6 col-md-6 mt-lg-0 mt-md-4 mt-0">
            <h6 className="text-white fweight-700 fsize-16 mb-3">
              {footer.subscribe}
            </h6>
            <p className="text-white">{footer.subscribeSubtitle}</p>
            <div className="subscribe-input-container d-flex justify-content-lg-center">
              <OutlinedInput
                id="subscribe-email"
                name="subscribe-email"
                placeholder={footer.inputPlaceholder}
                variant="outlined"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                label={footer.subscribe}
                className="subscribe-btn"
              />
            </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
