import { useSelector } from "react-redux";

import Alert from "../../../../../components/Alert";
import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import { BANNER_COLORS } from "../../../../../utils/Constants";

const AccountUnderReview = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      verificationNotifications: { accountUnderReview }
    }
  } = messages[lang];

  return (
    <Alert
      type="info"
      content={
        <p className="mb-0 fsize-16">{accountUnderReview.msg1}</p>
      }
      icon={<ChipInfoIcon color={BANNER_COLORS.info} />}
    />
  );
};

export default AccountUnderReview;
