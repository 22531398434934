import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import messages from "../../../../../assets/locale/messages";
import Button from "../../../../../components/Button";
import CardInfo from "./CardInfo";
import WaitForDocumentsApproval from "../WaitForDocumentsApproval";
import CancelRequestModal from "../CancelRequestModal";
import { cancelVerificationRequest } from "../../../../../store/Verification/actions";
import {
  COMPANY_VERIFICATION_STATUS,
  CURRENCIES_IDS,
  STRIPE_CURRENCIES_NAMES,
  VERIFICATION_METHODS
} from "../../../../../utils/Constants";
import { DocumentsBgIcon } from "../../../../../utils/Icons";

const OffPlatformPayment = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { offPlatform, actions }
  } = messages[lang];

  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );
  const verificationMethods = useSelector(
    (state) => state.verification.verificationMethods
  );

  const [offPlatformMethod, setOffPlatformMethod] = useState(null);
  const [cancelRequestModalOpen, setCancelRequestModalOpen] =
    useState(false);

  useEffect(() => {
    if (verificationMethods?.length) {
      setOffPlatformMethod(
        verificationMethods.find(
          (method) => method.Id === VERIFICATION_METHODS.offPlatform
        )
      );
    }
  }, [verificationMethods]);

  return (
    <div className="off-platform-payment-container">
      <div className="method-declaration mb-4">
        <Box
          className="border-radius-10 light-border d-flex align-items-center p-3"
          sx={{
            width: {
              xs: "100%",
              md: "50%"
            }
          }}>
          <DocumentsBgIcon />
          <p className="ms-3 mb-0 fsize-16 fweight-600 title-color">
            {offPlatform.verificationMethod}
            {offPlatform.offplatformVerification}
          </p>
        </Box>
      </div>
      <div className="mb-4">
        {companyVerificationStatus ===
          COMPANY_VERIFICATION_STATUS.documentsUploaded && (
          <WaitForDocumentsApproval />
        )}
        {companyVerificationStatus ===
          COMPANY_VERIFICATION_STATUS.pendingPayment && (
          <CardInfo
            paymentInfo={{
              amount: offPlatformMethod?.Fees,
              currency:
                offPlatformMethod?.CurrencyId === CURRENCIES_IDS.usd
                  ? STRIPE_CURRENCIES_NAMES.usd
                  : STRIPE_CURRENCIES_NAMES.euro
            }}
          />
        )}
      </div>
      <div className="actions-section w-100 d-flex justify-content-end align-items-center">
        <Button
          outlined
          label={actions.back}
          customSpacing={"px-5 py-2"}
          className={"me-3"}
          onClick={() => {
            setCancelRequestModalOpen(true);
          }}
        />
        <Button
          label={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.documentsUploaded
              ? actions.next
              : actions.submit
          }
          customSpacing={"px-5 py-2"}
          disabled={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.documentsUploaded
          }
          form={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.pendingPayment
              ? "online-payment-form"
              : null
          }
          type={
            companyVerificationStatus ===
            COMPANY_VERIFICATION_STATUS.pendingPayment
              ? "submit"
              : "button"
          }
        />
      </div>
      <CancelRequestModal
        open={cancelRequestModalOpen}
        onClose={() => setCancelRequestModalOpen(false)}
        onConfirm={() => {
          dispatch(cancelVerificationRequest());
          setCancelRequestModalOpen(false);
        }}
      />
    </div>
  );
};

export default OffPlatformPayment;
