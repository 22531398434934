const subAccounts = {
  subAccountsList: {
    subAccounts: "Sub Accounts",
    searchPlaceholder: "Search by sub account owner name",
    noSubAccountsPlaceholder:
      "You haven’t added any sub accounts yet.",
    noSearchFilterResults:
      "There is no search result or filter result that matches the entered data.",
    addMoreBtn: "Add Sub Account",
    list: {
      name: "Full Name",
      email: "Email Address",
      role: "Role",
      edit: "Edit",
      activate: "Activate"
    },
    banners: {
      subscribe: {
        msg: "Unlock sub-account setup by subscribing today! Don't miss out, subscribe now to access full features.",
        subscribeNow: "Subscribe Now"
      },
      renewSubscription: {
        msg: "To continue managing your sub-accounts, please renew your subscription plan.",
        renewNow: "Renew Now"
      },
      maxAccounts: {
        withThe: "With the",
        plan: "plan",
        allowedToAdd: ", you're allowed to add up to",
        subAccounts: "sub accounts.",
        needToAddMore: "If you need to add more, please",
        upgradePlan: "Upgrade your plan ",
        now: "Now"
      },
      downgradePlan: {
        msg1: "Due to your plan ",
        downgraded: " downgrade",
        msg2: ", only  ",
        subaccounts: " subaccounts",
        msg3: " remain active. You can edit these two active accounts as needed."
      }
    }
  },
  addEditSubAccount: {
    addNewSubAccount: "Add New Sub Account",
    editSubAccount: "Edit Sub Account",
    labels: {
      fullName: "Full Name",
      emailAddress: "Email Address",
      theRole: "Role"
    },
    placeholders: {
      name: "Enter the name",
      email: "Enter the email address",
      role: "Select the role"
    },
    roleOptions: {
      manager: "Manager",
      agent: "Agent"
    }
  },
  deactivateSubAccount: {
    content: "Deactivate",
    confirmationContent:
      "Are you sure you want to deactivate this account?",
    deactivate: "Deactivate"
  },
  activateSubAccount: {
    content: "Reactivate",
    confirmationContent:
      "Are you sure you want to Reactivate this account?",
    reactivate: "Reactivate"
  }
};

export default subAccounts;
