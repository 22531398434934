export const DeleteModalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="57"
    fill="none"
    viewBox="0 0 56 57">
    <rect
      width="48"
      height="48"
      x="4"
      y="4.814"
      fill="#FEE4E2"
      rx="24"></rect>
    <rect
      width="48"
      height="48"
      x="4"
      y="4.814"
      stroke="#FEF3F2"
      strokeWidth="8"
      rx="24"></rect>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M32 22.814v-.8c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874c-.428-.218-.988-.218-2.108-.218h-1.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874c-.218.428-.218.988-.218 2.108v.8m2 5.5v5m4-5v5m-11-10.5h18m-2 0v11.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.31c-.642.328-1.482.328-3.162.328h-4.4c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C21 36.534 21 35.694 21 34.014v-11.2"></path>
  </svg>
);
