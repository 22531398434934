import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormHelperText, Typography } from "@mui/material";
import ReactPhoneInput from "react-phone-input-2";

import messages from "../../assets/locale/messages";
import "react-phone-input-2/lib/material.css";
import "./PhoneInput.scss";

const PhoneInput = ({
  value,
  onChange,
  name,
  id,
  label,
  placeholder,
  required,
  isInputHasErr,
  errMsg,
  disabled,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  userCountryShort,
  isFocused,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const handlePhoneChange = (value, country) => {
    onChange(value, country.dialCode.length);
  };
  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      <div className="phone-input-container">
        <Typography
          variant="caption"
          className={`phone-input-label ${labelClassName} ${isFocused ? "focused" : ""}`}>
          {label}
          {labelAdornment && (
            <span className="ms-2">{labelAdornment}</span>
          )}
        </Typography>
        <ReactPhoneInput
          id={id || name}
          name={name}
          value={value}
          onChange={(value, country) =>
            handlePhoneChange(value, country)
          }
          enableSearch={true}
          specialLabel=""
          inputClass={`${inputClass} phone-input-field ${isInputHasErr ? "error" : ""}`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          country={userCountryShort}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
          countryCodeEditable={false}
          {...props}
        />
      </div>
      <FormHelperText
        error={true}
        className={`${helperTextClass || "fsize-12"} ${
          lang === "en" ? "" : "text-end"
        } `}>
        {!!value && isInputHasErr
          ? inputsValidations[errMsg]
          : helperText}
      </FormHelperText>
    </div>
  );
};

export default PhoneInput;

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  userCountryShort: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

PhoneInput.defaultProps = {
  value: ""
};
