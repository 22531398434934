import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";

import messages from "../../../assets/locale/messages";
import ViewEditButton from "../../../components/ViewEditButton";
import noProfileImgPlaceholder from "../../../assets/images/noProfileImgPlaceholder.png";
import Table from "../../../components/Table";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import noTransactionsPlaceholder from "../../../assets/images/vectors/placeholders/noTransactionsPlaceholder.svg";
import {
  CURRENCIES_IDS,
  CURRENCIES_SYMBOLS,
  TRANSACTION_STATUS,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import { isExporterRole } from "../../../utils/Helpers";
import {
  checkBackgroundImageValidity,
  formatDate
} from "../../../utils/Helpers";
import {
  getExporterProfileRequest,
  getExporterProfileResponse,
  getExporterProfileTransactionsRequest,
  getExporterProfileTransactionsResponse
} from "../../../store/MyAccount/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import VerificationsNotifications from "../../Exporters/Verification/VerificationsNotifications";
import "./ViewProfile.scss";

const ViewProfile = () => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    myAccount: { viewProfile }
  } = messages[lang];
  const isLoading = useSelector((state) => state.loader.showLoader);
  const exporterProfile = useSelector(
    (state) => state.myAccount.exporterProfile
  );
  const meta = useSelector(
    (state) => state.myAccount.exporterTransactionsListMeta
  );

  const transactionsList = useSelector(
    (state) => state.myAccount.exporterTransactionsList
  );

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const userCurrentRole = useSelector(
    (state) => state.auth.userCurrentRole
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );

  const [isProfileImgValid, setIsProfileImgValid] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getExporterProfileTransactionsRequest({
        params: {
          page
        }
      })
    );
  }, [page]);

  useEffect(() => {
    dispatch(getExporterProfileRequest());
    return () => {
      dispatch(getExporterProfileResponse({}));
      dispatch(
        getExporterProfileTransactionsResponse({ list: [], meta: {} })
      );
    };
  }, []);

  useEffect(() => {
    checkBackgroundImageValidity(
      exporterProfile?.ProfileImage,
      (isValid) => {
        setIsProfileImgValid(isValid);
      }
    );
  }, [exporterProfile]);

  const renderLabelValue = (label, value) => (
    <div className="d-flex align-items-center text-break">
      <div className="">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>
        <p className="text-color fsize-15 fweight-500 mb-0">
          {value}
        </p>
      </div>
    </div>
  );

  const handleStatusName = (transactionStatusId) => {
    let chipClass;
    switch (transactionStatusId) {
      case TRANSACTION_STATUS.succeeded: {
        chipClass = "green-chip";
        break;
      }
      case TRANSACTION_STATUS.pending: {
        chipClass = "orange-chip";
        break;
      }
      case TRANSACTION_STATUS.failed: {
        chipClass = "red-chip";
        break;
      }
      default:
        chipClass = "";
    }
    return chipClass;
  };

  const renderTransactionsTableRow = ({
    PaymentReason,
    TransactionId,
    PaymentMethod,
    Amount,
    CurrencyId,
    TransactionDate,
    TransactionStatusId,
    TransactionStatusName
  }) => {
    const chipClass = handleStatusName(TransactionStatusId);

    return (
      <TableRow key={`transaction-row-${TransactionId}`}>
        <TableCell>
          <div className="w-auto">{PaymentReason}</div>
        </TableCell>
        <TableCell>
          <div className="w-auto">{TransactionId}</div>
        </TableCell>
        <TableCell>
          <div className="w-auto">{PaymentMethod}</div>
        </TableCell>
        <TableCell>
          <span className="d-flex gap-2">
            {CurrencyId
              ? CurrencyId === CURRENCIES_IDS.euro
                ? CURRENCIES_SYMBOLS.euro
                : CURRENCIES_SYMBOLS.usd
              : CURRENCIES_SYMBOLS.usd}
            {Amount}
          </span>
        </TableCell>
        <TableCell>
          <div className="ps-1">
            {TransactionDate
              ? formatDate({ date: TransactionDate })
              : "__"}
          </div>
        </TableCell>
        <TableCell>
          <Chip
            label={TransactionStatusName}
            className={` border-radius-8 fw-bold ${chipClass}`}
            sx={{ fontWeight: "semibold" }}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="profile-container">
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) &&
        isExporterRole(userCurrentRole, userType) && (
          <div className="mb-3">
            <VerificationsNotifications />
          </div>
        )}
      <div className="page-title mb-4">
        <Typography variant="h5" className="title-color">
          {viewProfile.pageTitle}
        </Typography>
      </div>
      {exporterProfile && !!Object.keys(exporterProfile).length && (
        <div className="data-btn-container bg-white border-radius-10 d-flex  p-md-4 p-3 col-12">
          <div className="profile-img-data-container d-md-flex  col-md-11 col-12">
            <div className="img-btn-container d-md-block d-flex justify-content-between me-md-4 ">
              <div
                className="profile-img circular-border-radius d-flex justify-content-center align-items-center col-1"
                style={{
                  backgroundImage: `url(${isProfileImgValid ? exporterProfile.ProfileImage : noProfileImgPlaceholder})`
                }}></div>
            </div>
            <div className="col-xl-10 col-md-9 col-12 mt-md-0 mt-3 ">
              <div className="col-xl-6 col-md-12 mb-4">
                {renderLabelValue(
                  viewProfile.inputLabels.fullName,
                  exporterProfile?.Name || "--"
                )}
              </div>
              <div className="col-xl-6 col-md-12 mb-4">
                {renderLabelValue(
                  viewProfile.inputLabels.emailAddress,
                  exporterProfile?.Email || "--"
                )}
              </div>
              <div className="col-xl-6 col-md-12 mb-4">
                {renderLabelValue(
                  viewProfile.inputLabels.mobileNumber,
                  exporterProfile?.MobileNumber &&
                    exporterProfile?.MobileNumber.length >= 6
                    ? exporterProfile?.MobileNumber
                    : "--"
                )}
              </div>
              <div className="col-xl-6 col-md-12">
                {renderLabelValue(
                  viewProfile.inputLabels.companyName,
                  exporterProfile?.CompanyName || "--"
                )}
              </div>
            </div>
          </div>
          <div
            className="edit-btn-container"
            onClick={() => {
              navigate(ROUTE_PATHS.editProfile);
            }}>
            <ViewEditButton />
          </div>
        </div>
      )}

      {isExporterRole(userCurrentRole, userType) &&
        exporterProfile &&
        !!Object.keys(exporterProfile).length && (
          <div className="transactions-table-container bg-white border-radius-10 py-md-4 py-3  col-12 mt-4">
            <div className="px-3 mb-3">
              <Typography variant="h6">
                {viewProfile.list.transactionsList}
              </Typography>
            </div>
            <div className="table-content">
              <div>
                {transactionsList?.length ? (
                  <Table
                    className={"px-0"}
                    headlines={[
                      viewProfile.list.tableHeader.paymentReason,
                      viewProfile.list.tableHeader.transactionId,
                      viewProfile.list.tableHeader.paymentMethod,
                      viewProfile.list.tableHeader.amount,
                      viewProfile.list.tableHeader.transactionDate,
                      viewProfile.list.tableHeader.status
                    ]}
                    rows={
                      !isLoading &&
                      transactionsList &&
                      transactionsList.map((category) =>
                        renderTransactionsTableRow(category)
                      )
                    }
                  />
                ) : (
                  <div>
                    {!isLoading && (
                      <EmptyState
                        subTitle={
                          viewProfile.list.noTransactionsFound
                        }
                        imgSrc={noTransactionsPlaceholder}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="Pagination-container d-flex justify-content-center py-3 mt-2">
              {!isLoading && transactionsList?.length > 0 && (
                <Pagination
                  count={meta?.pages}
                  page={page || meta.currentPage}
                  handleChange={(page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default ViewProfile;
