import moment from "moment";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

import messages from "../assets/locale/messages";
import store from "../store";
import { showHideSnackbar } from "../store/Snackbar/actions";
import { LANGUAGES, USER_TYPES_IDS } from "./Constants";
import { changeUserCurrentRoleRequest } from "../store/Auth/actions";
import { ROUTE_PATHS } from "./RoutesPaths";
import { showHideLoader } from "../store/Loader/actions";

export const showSuccessMsg = (msg, customMsg) => {
  const { lang } = store.getState().locale;
  const { successMsgs } = messages[lang];
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "success",
      message: customMsg || successMsgs[msg]
    })
  );
};

export const showErrorMsg = (message) => {
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "error",
      message
    })
  );
};

export const generateUniqueSrc = (src) => {
  const timestamp = new Date().getTime();
  return `${src}?v=${timestamp}`;
};

export const getNamesInitials = (fullName) => {
  return fullName
    ? fullName
        .split(" ")
        .filter((_, i) => i < 2)
        .map((name) => name[0]?.toUpperCase())
        .join("")
    : "";
};

export const formatDate = ({
  date,
  row,
  format,
  stringInterpolation
}) => {
  return date ? (
    stringInterpolation ? (
      moment(date)
        .locale("en")
        .format(format || "D MMM, YYYY")
    ) : (
      <div className={`${row && "d-flex gap-2"}`}>
        <p className={"mb-0"}>
          {moment(date)
            .locale("en")
            .format(format || "D MMM, YYYY")}
        </p>
      </div>
    )
  ) : (
    "__"
  );
};

export const checkForMultiline = (
  text,
  specialFormatting = "",
  normalTextSize = false
) => {
  const { lang } = store.getState().locale;
  if (!text || (text && typeof text === "string" && !text.trim())) {
    return null;
  }

  let multiLineArray = [];
  multiLineArray = `${text}`.split("\n");
  if (multiLineArray.length > 1) {
    return multiLineArray.map((line) => {
      return (
        <Typography
          key={line}
          variant={`${normalTextSize ? "body1" : "body2"}`}
          sx={{ flexGrow: 1 }}
          className={`brand-data mt-2  ${
            specialFormatting
              ? ""
              : lang === LANGUAGES.en
                ? "me-5"
                : "ms-5"
          } m-md-0 ${specialFormatting ?? ""}`}>
          {line}
        </Typography>
      );
    });
  } else {
    return text;
  }
};

export const checkBackgroundImageValidity = (url, callback) => {
  const img = new Image();
  img.onload = function () {
    // Image loaded successfully
    callback(true);
  };
  img.onerror = function () {
    // Image failed to load
    callback(false);
  };
  img.src = url;
};

export const isExporterRole = (userRole, userType) => {
  const {
    userCurrentRole,
    login: { UserTypeId }
  } = store.getState().auth;
  return (
    (userType || UserTypeId) === USER_TYPES_IDS.exporter ||
    ((userType || UserTypeId) === USER_TYPES_IDS.both &&
      (userRole || userCurrentRole) === USER_TYPES_IDS.exporter)
  );
};

export const setCurrentRole = (navigate) => {
  const isSessionStorage = sessionStorage.getItem("user");

  const userRole = isExporterRole()
    ? USER_TYPES_IDS.importer
    : USER_TYPES_IDS.exporter;

  if (isExporterRole() && navigate) {
    navigate(ROUTE_PATHS.importerProductRequests);
  } else if (!isExporterRole() && navigate) {
    navigate(ROUTE_PATHS.companyProfile);
  }

  isSessionStorage
    ? sessionStorage.setItem("userType", userRole)
    : localStorage.setItem("userType", userRole);

  store.dispatch(changeUserCurrentRoleRequest(userRole));
};

export const convertRichTextToPlainText = (htmlString) => {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Get the plain text content of the document
  return doc.body.textContent || "";
};

export const RichTextToHTML = (richText) => {
  // First, sanitize the rich text content to avoid XSS vulnerabilities.
  const sanitizedHTML = DOMPurify.sanitize(richText, {
    USE_PROFILES: { html: true }
  });

  // Create a container to parse the sanitized HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHTML, "text/html");

  // Get all anchor tags
  const links = doc.querySelectorAll("a");

  // Loop through each <a> tag to fix the href if needed
  links.forEach((link) => {
    const href = link.getAttribute("href");

    // Check if the href does not start with "http://" or "https://"
    if (
      href &&
      !href.startsWith("http://") &&
      !href.startsWith("https://")
    ) {
      // Prepend "https://" for external links that do not have a proper protocol
      link.setAttribute("href", `https://${href}`);
    }
    link.setAttribute("target", `_blank`);
    link.setAttribute(
      "class",
      "text-primary fsize-14 fweight-500 text-underline forget-password-link"
    );
  });

  // Return the modified HTML as a string
  const updatedHTML = doc.body.innerHTML;

  return { __html: updatedHTML };
};

export const isRichText = (text) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(text);
};

export const getUserCurrency = () => {
  const currency = store.getState().auth.login.currency;
  return {
    id: currency?.CurrencyId,
    name: currency?.CurrencyName,
    code: currency?.CurrencyCode,
    symbol: currency?.CurrencySymbol
  };
};

export const getCookieValue = (key) => {
  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // If cookie not found
};

export const downloadPDF = async ({
  pdfURL,
  pdfDownloadName,
  dispatch
}) => {
  try {
    dispatch(showHideLoader(true));
    const response = await fetch(pdfURL);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", pdfDownloadName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    error("Error downloading the PDF:", error);
  } finally {
    dispatch(showHideLoader(false));
  }
};

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
