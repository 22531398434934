import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import noRequestImg from "../../../assets/images/vectors/placeholders/noProductImagePlacholder.svg";
import { getCategoryDropdownRequest } from "../../../store/Categories/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./CategoryDropdown.scss";

const CategoryDropdown = ({ label }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const dropdownRef = useRef(null);

  const categoryDropdown = useSelector(
    (state) => state.categories.categoryDropdown
  );

  useEffect(() => {
    setIsTouchDevice(
      "ontouchstart" in window || navigator.maxTouchPoints > 0
    );
  }, []);

  useEffect(() => {
    dispatch(getCategoryDropdownRequest());
  }, [dispatch]);

  useEffect(() => {
    if (categoryDropdown?.length > 0 && hoveredCategoryId === null) {
      setHoveredCategoryId(categoryDropdown[0].Id);
    }
  }, [categoryDropdown, hoveredCategoryId]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };
  useEffect(() => {
    if (showMenu) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
      setHoveredCategoryId(null);
    };
  }, [showMenu]);

  const onErrorImage = (e) => {
    e.target.src = noRequestImg;
  };

  const hoveredCategory = categoryDropdown?.find(
    (category) => category.Id === hoveredCategoryId
  );

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu((prev) => !prev);
  };

  return (
    <div ref={dropdownRef} className="category-dropdown-wrapper">
      <button
        className="border-0 bg-transparent"
        onClick={toggleMenu}>
        {label}
      </button>

      {showMenu && (
        <div
          className="dropdown-container d-flex p-2 light-box-shadow position-absolute start-0 end-0 top-100 bg-white"
          style={{ zIndex: 2 }}>
          <div className="dropdown-list scroll-within p-2 d-flex flex-column gap-2">
            {categoryDropdown?.map(({ Id, Name }) => (
              <button
                className={`fweight-500 title-color category-btn pointer border-0 bg-transparent text-start ${hoveredCategoryId === Id ? "active" : ""}`}
                key={Id}
                onMouseEnter={() => {
                  if (!isTouchDevice) {
                    setHoveredCategoryId(Id);
                  }
                }}
                onClick={() => {
                  if (isTouchDevice) {
                    setHoveredCategoryId(Id);
                  } else {
                    navigate(
                      ROUTE_PATHS.categoryDetails.replace(":id", Id)
                    );
                    setShowMenu(false);
                  }
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  setHoveredCategoryId(Id);
                }}>
                {Name}
              </button>
            ))}
          </div>
          <div className="py-2 px-4 flex-grow-1">
            {hoveredCategory ? (
              <>
                <p className="title-color fweight-600 fsize-16 py-2 light-border-bottom">
                  {hoveredCategory.Name}
                </p>
                <div className="subcategories-container scroll-within">
                  {hoveredCategory.Subcategories &&
                  hoveredCategory.Subcategories?.length ? (
                    hoveredCategory.Subcategories.map(
                      ({ Name, Image, Id }) => (
                        <div
                          key={Id}
                          className="d-flex align-items-center gap-3 pointer subcategory"
                          onClick={() => {
                            navigate(
                              ROUTE_PATHS.subCategoryDetails.replace(
                                ":id",
                                Id
                              )
                            );
                            setShowMenu((show) => !show);
                          }}>
                          <div className="subcategory-image light-faint-blue-bg border-radius-5 d-flex align-items-center justify-content-center d-none d-md-flex">
                            <img
                              src={Image}
                              alt={Name}
                              className="w-75"
                              onError={onErrorImage}
                            />
                          </div>
                          <p className="title-color m-0">{Name}</p>
                        </div>
                      )
                    )
                  ) : (
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <p className="title-color fweight-600 fsize-16 m-0 ">
                        No subcategories found
                      </p>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                <p className="title-color fweight-600 fsize-16 m-0 ">
                  Please select a category
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;

CategoryDropdown.propTypes = {
  label: PropTypes.node.isRequired
};
