import {
  GET_EXPORTERS_LIST_RESPONSE,
  GET_EXPORTER_CONTACT_INFO_RESPONSE,
  GET_EXPORTER_DETAILS_RESPONSE
} from "./types";

const INITIAL_STATE = {
  exporterDetails: {},
  contactInfo: {},
  exportersList: [],
  exportersMeta: {}
};

const exporters = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPORTER_DETAILS_RESPONSE:
      return {
        ...state,
        exporterDetails: action.payload
      };
    case GET_EXPORTER_CONTACT_INFO_RESPONSE:
      return {
        ...state,
        contactInfo: action.payload
      };
    case GET_EXPORTERS_LIST_RESPONSE:
      return {
        ...state,
        exportersList:
          action.payload.meta.currentPage === 1
            ? [...action.payload.list]
            : [...state.exportersList, ...action.payload.list],
        exportersMeta: action.payload.meta
      };
    default:
      return state;
  }
};

export default exporters;
