import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const Counter = ({
  initialCount,
  onChangeCount,
  restartCount,
  disabled
}) => {
  const isLoading = useSelector((state) => state.loader.showLoader);

  const [count, setCount] = useState(initialCount);
  let countDecrementInterval = null;

  useEffect(() => {
    countDecrementInterval = setInterval(() => {
      if (count > 0) {
        !isLoading &&
          !disabled &&
          setCount((prevCount) => prevCount - 1);
      } else {
        clearInterval(countDecrementInterval);
      }
    }, 1000);

    onChangeCount(count);

    return () => clearInterval(countDecrementInterval);
  }, [count, isLoading, disabled]);

  useEffect(() => {
    if (restartCount) {
      setCount(initialCount);
    }
  }, [restartCount]);

  return (
    <div className="counter-container">
      <p
        className={`${disabled ? "text-light-grey" : "text-primary"} fsize-16 fweight-600`}>
        {disabled ? (
          <span>00 : 00</span>
        ) : (
          <>
            <span>{count === 60 ? "1" : "00"}</span>
            <span> : </span>
            <span>
              {count === 60 || count === 0
                ? "00"
                : count > 9
                  ? count
                  : `0${count}`}
            </span>
          </>
        )}
      </p>
    </div>
  );
};

export default Counter;

Counter.propTypes = {
  initialCount: PropTypes.number.isRequired,
  onChangeCount: PropTypes.func.isRequired,
  restartCount: PropTypes.bool,
  disabled: PropTypes.bool
};
