import { put, call, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/exporters";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getExporterDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getExporterDetails, payload);
    yield put(actions.getExporterDetailsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getExporterContactInfoSaga({ payload }) {
  try {
    const response = yield call(apis.getExporterContactInfo, payload);
    yield put(
      actions.getExporterContactInfoResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getExportersListSaga({ payload }) {
  try {
    const response = yield call(apis.getExporterList, payload);
    const {
      data: {
        Data: { Items, CurrentPage, ItemCount, TotalPages }
      }
    } = response;
    yield put(
      actions.getExportersListResponse({
        list: Items,
        meta: {
          currentPage: +CurrentPage,
          count: +ItemCount,
          pages: +TotalPages
        }
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* ExportersSagas() {
  yield takeLatest(
    types.GET_EXPORTER_DETAILS_REQUEST,
    getExporterDetailsSaga
  );
  yield takeLatest(
    types.GET_EXPORTER_CONTACT_INFO_REQUEST,
    getExporterContactInfoSaga
  );
  yield takeLatest(
    types.GET_EXPORTERS_LIST_REQUEST,
    getExportersListSaga
  );
}
