import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../assets/locale/messages";
import noProductsImg from "../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import Modal from "../../../components/Modal";
import Alert from "../../../components/Alert";
import Checkbox from "../../../components/Checkbox";
import ShowCase from "../../Importers/Exporters/ExporterProfile/ShowCase";
import { deleteExtraShowcases } from "../../../store/Subscription/actions";
import { ChipInfoIcon, WarningBgIcon } from "../../../utils/Icons";
import {
  BANNER_COLORS,
  SUBSCRIPTION_PLAN_FEATURES_IDS
} from "../../../utils/Constants";
import "./ShowHideShowcases.scss";

const ShowHideShowcases = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    subscription: {
      paymentPage: {
        downgradeShowcases: { editShowcasesModal, saveChangesModal }
      }
    },
    shared
  } = messages[lang];

  const prevSubscriptionPlan = useSelector(
    (state) => state.subscription.userPrevShowcases
  );
  const currentSubscriptionPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  const [selectedShowcases, setSelectedShowcases] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showcasesCount, setShowcasesCount] = useState(0);

  useEffect(() => {
    if (prevSubscriptionPlan?.CompanyShowCases?.length && open) {
      setSelectedShowcases(
        prevSubscriptionPlan?.CompanyShowCases.filter(
          (showcase) => !showcase.IsHidden
        ).map((showcase) => showcase.Id)
      );
    }
  }, [prevSubscriptionPlan, open]);

  useEffect(() => {
    if (
      currentSubscriptionPlan &&
      !!Object.keys(currentSubscriptionPlan).length
    ) {
      setShowcasesCount(
        parseInt(
          currentSubscriptionPlan?.Features?.find((feature) => {
            return (
              feature.Id ===
              SUBSCRIPTION_PLAN_FEATURES_IDS.showcasesNumber
            );
          }).Value
        )
      );
    }
  }, [currentSubscriptionPlan]);

  const handleCloseModal = () => {
    setSelectedShowcases([]);
    onClose();
  };

  const handleClickShowcase = (showcaseId, isChecked) => {
    if (isChecked) {
      setSelectedShowcases((prevState) => [...prevState, showcaseId]);
    } else {
      setSelectedShowcases((prevState) =>
        prevState.filter((id) => id !== showcaseId)
      );
    }
  };

  const handleConfirmShowcasesEdits = () => {
    dispatch(
      deleteExtraShowcases({
        data: {
          deletedShowcasesIds:
            prevSubscriptionPlan?.CompanyShowCases?.filter(
              (showcase) => !selectedShowcases.includes(showcase.Id)
            ).map((showcase) => showcase.Id)
        },
        navigate
      })
    );
    setConfirmModalOpen(false);
    handleCloseModal();
  };

  const renderNoProductsPlaceholder = () => (
    <div className="d-flex align-items-center justify-content-center">
      <div className="text-center w-80">
        <img src={noProductsImg} className="mb-3" />
        <p className="fsize-18 fweight-500 text-color">
          {editShowcasesModal.noProducts}
        </p>
      </div>
    </div>
  );

  const renderModalContent = () => (
    <div>
      <Alert
        type="info"
        content={
          <>
            <p className="mb-0 fsize-16 text-color">
              <span>{editShowcasesModal.banner.msg1}</span>
              <span className="fweight-600">
                {showcasesCount} {editShowcasesModal.banner.showcases}
              </span>
            </p>
            <p className="mb-0 fsize-16 text-color">
              <span>{editShowcasesModal.banner.msg2}</span>
              <span className="fweight-600">
                {editShowcasesModal.banner.only}
              </span>
              <span>{editShowcasesModal.banner.msg3}</span>
              <span className="fweight-600">
                {editShowcasesModal.banner.once}
              </span>
              <span>{editShowcasesModal.banner.msg4}</span>
            </p>
          </>
        }
        icon={<ChipInfoIcon color={BANNER_COLORS.info} />}
      />
      <div className="mt-3">
        <h6 className="fsize-20 fweight-600 title-color mb-3">
          {editShowcasesModal.selectShowcases}
        </h6>
        <div>
          {prevSubscriptionPlan?.CompanyShowCases?.map(
            (showcase, i) => (
              <div
                key={`show-case-${i}`}
                className="mb-4 d-flex align-items-start justify-content-start">
                <Checkbox
                  checked={selectedShowcases.includes(showcase.Id)}
                  onChange={(isChecked) =>
                    handleClickShowcase(showcase.Id, isChecked)
                  }
                />
                <div
                  className={`showcase-container pointer ${selectedShowcases.includes(showcase.Id) && "selected-showcase thick-primary-border border-radius-10 p-2"}`}
                  onClick={() =>
                    handleClickShowcase(
                      showcase.Id,
                      !selectedShowcases.includes(showcase.Id)
                    )
                  }>
                  <ShowCase
                    products={showcase?.Products.map(
                      (showcaseProduct, i) => ({
                        src: showcaseProduct.DefaultImage,
                        label: showcaseProduct.Name,
                        count: showcaseProduct.MinOrderQuantity,
                        minPrice: showcaseProduct.MinPriceRange,
                        maxPrice: showcaseProduct.MaxPriceRange,
                        unit: showcaseProduct.MoqUnitName
                      })
                    )}
                    info={{
                      name: showcase.Name,
                      description: showcase.Description
                    }}
                    showcaseImages={[
                      showcase.Image,
                      ...showcase.ProductImages.map(
                        (productImg) => productImg.DefaultImage
                      )
                    ]}
                    collapseOnLargeSize
                    noProductsPlaceholder={renderNoProductsPlaceholder()}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );

  const renderConfirmModalContent = () => (
    <div className="text-center">
      <WarningBgIcon className="mb-4" />
      <h5 className="fsize-18 fweight-900 title-color mb-3">
        {saveChangesModal.saveChangesCarefully}
      </h5>
      <p className="mb-0 text-color">
        <span>{saveChangesModal.msg1}</span>
        <span className="fweight-600">{saveChangesModal.msg2}</span>
        <span>{saveChangesModal.msg3}</span>
      </p>
    </div>
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          modalClass={`show-hide-showcases-modal ${confirmModalOpen && "d-none"}`}
          handleClose={handleCloseModal}
          handleCancel={handleCloseModal}
          handleConfirm={() => setConfirmModalOpen(true)}
          content={renderModalContent()}
          confirmBtnTxt={editShowcasesModal.saveChanges}
          cancelBtnTxt={shared.cancel}
          alignBtnsCenter={false}
          isContentText={false}
          confirmBtnClass={"px-5 py-2"}
          cancelBtnClass={"px-5 py-2"}
          disableConfirmBtn={
            !selectedShowcases.length ||
            selectedShowcases.length > showcasesCount
          }
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {confirmModalOpen && (
        <Modal
          open={confirmModalOpen}
          handleClose={() => setConfirmModalOpen(false)}
          handleCancel={() => setConfirmModalOpen(false)}
          content={renderConfirmModalContent()}
          isContentText={false}
          confirmBtnTxt={shared.save}
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleConfirmShowcasesEdits}
        />
      )}
    </>
  );
};

export default ShowHideShowcases;

ShowHideShowcases.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
