export const CheckGreenBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="101"
    fill="none"
    viewBox="0 0 100 101"
    {...props}>
    <path
      fill="#22B573"
      d="M50 .27c-27.613 0-50 22.378-50 50 0 27.62 22.387 50 50 50s50-22.393 50-50c0-27.608-22.387-50-50-50z"></path>
    <path
      fill="#74DA7F"
      d="M0 50.27a49.78 49.78 0 005.955 23.677 54.444 54.444 0 0015.377 2.205c30.059 0 54.426-24.367 54.426-54.425a54.435 54.435 0 00-2.274-15.614A49.777 49.777 0 0050 .27c-27.613 0-50 22.378-50 50z"></path>
    <path
      fill="#fff"
      d="M78.516 41.985l-30.053 31.56a8.82 8.82 0 01-6.344 2.735h-.039a8.811 8.811 0 01-6.328-2.678L19.805 57.176a8.816 8.816 0 1112.652-12.279l9.56 9.848 23.73-24.918a8.816 8.816 0 1112.769 12.158z"></path>
  </svg>
);
