import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import PasswordValidation from "../../../components/PasswordValidation";
import { PASSWORD_REGEX } from "../../../utils/Patterns";
import messages from "../../../assets/locale/messages";
import { resetPasswordRequest } from "../../../store/Auth/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const lang = useSelector((state) => state.locale.lang);
  const { resetPassword } = messages[lang];

  const tempPassword = sessionStorage.getItem("tempPassword");

  const [focused, setFocused] = useState(false);
  const [token, setToken] = useState(null);
  const [isFirstTimePassword, setIsFirstTimePassword] =
    useState(false);

  useEffect(() => {
    if (searchParams) {
      setToken(searchParams.get("token"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (location?.pathname === ROUTE_PATHS.setNewPassword) {
      setIsFirstTimePassword(true);
    }
  }, [location]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("newPasswordRequired")
        .matches(PASSWORD_REGEX, "invalidPassword"),
      confirm_password: Yup.string()
        .required("confirmNewPasswordRequired")
        .oneOf(
          [Yup.ref("new_password"), null],
          "passwordIsNotMatched"
        )
    }),
    onSubmit: ({ new_password, confirm_password }) => {
      dispatch(
        resetPasswordRequest({
          data: {
            Token: isFirstTimePassword ? undefined : token,
            NewPassword: new_password,
            ConfirmPassword: confirm_password
          },
          navigate,
          isFirstTimePassword
        })
      );
    }
  });

  return (
    <div className={`change-password-page w-100`}>
      <h3 className="title fweight-700 fsize-32 title-color mb-5 decreased-title-font">
        {isFirstTimePassword
          ? resetPassword.resetPassword
          : resetPassword.setNewPassword}
      </h3>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        {isFirstTimePassword && (
          <Input
            id="temp-password"
            label={resetPassword.labels.oldPassword}
            name="temp-password"
            type="password"
            placeholder={resetPassword.placeholders.oldPassword}
            value={tempPassword}
            disabled
            inputClass="mb-1"
            labelClassName="font-medium mb-2"
            inputWrapperClass="my-4"
          />
        )}
        <Input
          id="newPassword"
          label={resetPassword.labels.newPassword}
          name="new_password"
          type="password"
          placeholder={resetPassword.placeholders.newPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("new_password");
            setFieldValue("new_password", value.trim());
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={values.new_password}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={
            !!(touched.new_password && errors.new_password)
          }
          errMsg={errors.new_password}
        />
        <Input
          id="confirmNewPassword"
          label={resetPassword.labels.confirmNewPassword}
          name="confirm_password"
          type="password"
          placeholder={resetPassword.placeholders.confirmNewPassword}
          required={true}
          onChange={(value) => {
            setFieldTouched("confirm_password");
            setFieldValue("confirm_password", value.trim());
          }}
          value={values.confirm_password}
          inputClass="mb-1"
          labelClassName="font-medium mb-2"
          inputWrapperClass="my-4"
          isInputHasErr={
            !!(touched.confirm_password && errors.confirm_password)
          }
          errMsg={errors.confirm_password}
        />
        {focused && (
          <PasswordValidation
            passwordValue={values.new_password}
            wrapperClass="my-2"
          />
        )}

        <div className="text-center">
          <Button
            label={resetPassword.actionBTN}
            type="submit"
            className="mt-4 w-70 submit-btn"
            disabled={!dirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};
export default ResetPassword;
