import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "../../../../components/Button";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import subscribeImg from "../../../../assets/images/categoryDetails/subscribe_boxes.svg";
import messages from "../../../../assets/locale/messages";

const SubscribeSection = ({ isUserSubscribed }) => {
  const lang = useSelector((state) => state.locale.lang);
  const navigate = useNavigate();
  const {
    categories: {
      categoryDetails: { subscribeSection }
    }
  } = messages[lang];
  return (
    <div className="subscribe-section-container ">
      <div className="gradient-bg border-radius-10 py-md-3 py-3 px-md-0 px-3 d-flex align-items-center justify-content-center gap-5">
        <div className="d-md-block d-none">
          <img className="subscribe-img" src={subscribeImg} />
        </div>
        <Box
          className="support-content p-md-0 p-2"
          sx={{
            width: {
              xs: "100%",
              md: "50%"
            }
          }}>
          <h3 className="text-dark-blue text-uppercase section-title fsize-30 fweight-700 ">
            {subscribeSection.title}
          </h3>
          <p className="text-light-grey section-content fsize-18 fweight-400">
            {subscribeSection.content}
          </p>
          <Button
            label={
              isUserSubscribed
                ? subscribeSection.addMoreProductsButton
                : subscribeSection.subscribeButton
            }
            onClick={() => {
              navigate(
                isUserSubscribed
                  ? ROUTE_PATHS.products
                  : ROUTE_PATHS.pricingDetails
              );
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default SubscribeSection;
SubscribeSection.propTypes = {
  isUserSubscribed: PropTypes.bool
};
