export const CloseBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94"
    height="93"
    fill="none"
    viewBox="0 0 94 93">
    <rect
      width="80.879"
      height="80"
      x="6.561"
      y="6.129"
      fill="#FEE4E2"
      rx="40"></rect>
    <rect
      width="80.879"
      height="80"
      x="6.561"
      y="6.129"
      stroke="#FEF3F2"
      strokeWidth="12"
      rx="40"></rect>
    <path
      fill="#D32F2F"
      d="M37.775 36.904a1.373 1.373 0 011.947 0L47 44.184l7.276-7.28a1.377 1.377 0 011.947 1.947l-7.28 7.276 7.28 7.277a1.376 1.376 0 11-1.947 1.947l-7.276-7.28-7.277 7.28a1.379 1.379 0 01-2.245-.447 1.376 1.376 0 01.298-1.5l7.28-7.277-7.28-7.276a1.375 1.375 0 010-1.947z"></path>
  </svg>
);
