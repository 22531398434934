export const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      fill="#F1FAFF"
      d="M12.606 9.106l6.329-7.41h-1.5L11.94 8.13 7.55 1.696H2.488l6.638 9.73-6.638 7.77h1.5l5.803-6.795 4.636 6.795h5.063l-6.884-10.09zm-2.054 2.405l-.672-.968-5.352-7.71h2.304l4.317 6.222.673.969 5.614 8.087h-2.304l-4.58-6.6z"></path>
  </svg>
);
