import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";
import { Close } from "@mui/icons-material";

import Button from "../Button";
import "./Modal.scss";

const Modal = ({
  open,
  handleClose,
  title,
  isContentText,
  content,
  hasActions,
  confirmBtnTxt,
  cancelBtnTxt,
  handleConfirm,
  handleCancel,
  showCloseIcon,
  maxWidth,
  scrollMode,
  modalClass,
  titleClass,
  contentClass,
  actionsClass,
  confirmBtnClass,
  cancelBtnClass,
  disableEscapeKeyDown,
  disableBackdropClick,
  alignBtnsCenter,
  disableConfirmBtn,
  isLoading
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const onClose = (event, reason) => {
    if (reason === "backdropClick" && disableBackdropClick) {
      return;
    }
    handleClose(false);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      scroll={scrollMode}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`modal-container ${modalClass}`}
      disableEscapeKeyDown={disableEscapeKeyDown}>
      {title && (
        <DialogTitle id="alert-dialog-title" className={titleClass}>
          {title === true ? "" : title}
          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={() => handleClose(false)}
              sx={
                lang === "en"
                  ? {
                      position: "absolute",
                      top: 8,
                      right: 8
                    }
                  : {
                      position: "absolute",
                      top: 8,
                      left: 8
                    }
              }>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {content && (
        <DialogContent
          id="alert-dialog-description"
          className={contentClass}>
          {isContentText ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            <>{content}</>
          )}
        </DialogContent>
      )}
      {hasActions && (
        <DialogActions
          className={`container mb-4 d-flex align-items-center ${actionsClass} ${
            alignBtnsCenter
              ? "justify-content-center"
              : "justify-content-end"
          }`}>
          {cancelBtnTxt && (
            <Button
              label={cancelBtnTxt}
              outlined
              onClick={handleCancel}
              className={`modal-cancel-btn ${cancelBtnClass} mx-2 fsize-14 text-dark px-4`}
              disabled={isLoading}
            />
          )}
          {confirmBtnTxt && (
            <Button
              label={confirmBtnTxt}
              onClick={handleConfirm}
              className={`modal-confirm-btn ${confirmBtnClass} fsize-14 text-white px-4`}
              disabled={disableConfirmBtn || isLoading}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool
  ]),
  isContentText: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasActions: PropTypes.bool,
  confirmBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  scrollMode: PropTypes.oneOf(["paper", "body"]),
  modalClass: PropTypes.string,
  titleClass: PropTypes.string,
  contentClass: PropTypes.string,
  actionsClass: PropTypes.string,
  confirmBtnClass: PropTypes.string,
  cancelBtnClass: PropTypes.string,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableConfirmBtn: PropTypes.bool,
  isLoading: PropTypes.bool,
  alignBtnsCenter: PropTypes.bool
};

Modal.defaultProps = {
  isContentText: true,
  hasActions: true,
  showCloseIcon: true,
  maxWidth: "sm",
  scrollMode: "paper",
  alignBtnsCenter: true
};

export default Modal;
