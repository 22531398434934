import { put, call, takeLatest } from "redux-saga/effects";
import * as apis from "../../network/apis/subscription";
import * as types from "./types";
import * as actions from "./actions";
import {
  getLoggedInExporterDetailsRequest,
  getUserStatusRequest
} from "../User/actions";
import {
  formatDate,
  showErrorMsg,
  showSuccessMsg
} from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import store from "..";
import messages from "../../assets/locale/messages";
import { SUBSCRIPTION_PAYMENT_TYPE } from "../../utils/Constants";

function* getSubscriptionPlansDetailsSaga() {
  try {
    const response = yield call(apis.getSubscriptionPlansDetails);
    const responseData = response.data.Data;
    yield put(
      actions.getSubscriptionPlansDetailsResponse(responseData)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSubscriptionPlanDetailsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSubscriptionPlanDetails,
      payload
    );
    const responseData = response.data.Data;
    yield put(
      actions.getSubscriptionPlanDetailsResponse(responseData)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* createSubscriptionPaymentIntentSaga({ payload }) {
  try {
    yield put(actions.createSubscriptionPaymentIntentResponse(null));
    const response = yield call(
      apis.createSubscriptionPaymentIntent,
      payload
    );
    yield put(
      actions.createSubscriptionPaymentIntentResponse(
        response.data.Data.ClientSecret
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* confirmSubscriptionPaymentSaga({ payload }) {
  try {
    const response = yield call(
      apis.confirmSubscriptionPayment,
      payload
    );
    if (payload.setSearchParams) {
      payload.setSearchParams((searchParams) => {
        searchParams.set("is_payment_confirmed", true);
        searchParams.delete("payment_intent");
        searchParams.delete("payment_intent_client_secret");
        searchParams.delete("redirect_status");
        searchParams.delete("card_info");
        return searchParams;
      });
    }
    yield put(
      actions.setSubscriptionSuccessMsg(response.data.Data.Message)
    );
    sessionStorage.setItem(
      "subscription-success-msg",
      response.data.Data.Message
    );
    yield put(actions.getUserSubscriptionPlanRequest());
    yield put(getUserStatusRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getUserSubscriptionPlanSaga() {
  try {
    const response = yield call(apis.getUserSubscriptionPlan);
    yield put(
      actions.getUserSubscriptionPlanResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getPreviousSubscriptionTypeSaga() {
  try {
    const response = yield call(apis.getPreviousSubsctriptionType);
    yield put(
      actions.getPreviousSubscriptionTypeResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deleteExtraShowcasesSaga({ payload }) {
  try {
    yield call(apis.deleteExtraShowcases, payload.data);
    yield payload.navigate(ROUTE_PATHS.companyProfile);
    yield put(getLoggedInExporterDetailsRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* requestVoucherSaga() {
  try {
    const response = yield call(apis.requestVoucher);
    yield put(actions.requestVoucherResponse(true));
    yield showSuccessMsg("", response.data.Data.Message);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* applyVoucherSaga({ payload }) {
  try {
    const response = yield call(apis.applyVoucher, payload);
    const responseData = response.data.Data;

    yield put(
      actions.applyVoucherResponse({
        voucherPercent: responseData.Percent,
        totalAfterDiscount: responseData.AfterDiscountValue
      })
    );
    yield put(actions.requestVoucherResponse(false));
    sessionStorage.setItem("voucherCode", payload.params.code);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* checkCanRenewSubscriptionSaga({ payload }) {
  try {
    const { params, navigate, fromPaymentScreen, route } = payload;
    const response = yield call(
      apis.checkCanRenewSubscription,
      params
    );
    if (response.data.Data?.CanRenew) {
      yield put(
        actions.checkCanRenewSubscriptionResponse({
          IsDowngrade: response.data.Data.IsDowngrad,
          CanRenew: response.data.Data?.CanRenew
        })
      );
      if (
        navigate &&
        !response.data.Data.IsDowngrad &&
        !fromPaymentScreen
      ) {
        yield put(navigate(route));
        yield put(actions.checkCanRenewSubscriptionResponse({}));
      }
    } else {
      yield put(
        actions.checkCanRenewSubscriptionResponse({
          CanRenew: response.data.Data?.CanRenew
        })
      );
      const { lang } = store.getState().locale;
      const { userPlan } = store.getState().subscription;
      const errMsg = messages[
        lang
      ].subscription.cannotRenewPlanMsg.replace(
        ":date",
        formatDate({
          date: userPlan?.RenewalDate,
          stringInterpolation: true,
          format: "D MMM YYYY"
        })
      );
      showErrorMsg(errMsg);
      if (fromPaymentScreen && navigate) {
        yield put(navigate(route));
        yield put(actions.checkCanRenewSubscriptionResponse({}));
      }
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getSubscriptionFinalFeesSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSubscriptionFinalFees,
      payload
    );
    yield put(
      actions.getSubscriptionFinalFeesResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* createAutomaticRenewalSubscriptionPaymentIntentSaga({
  payload
}) {
  try {
    yield put(
      actions.createAutomaticRenewalSubscriptionPaymentIntentResponse(
        { ClientSecret: null, SubscriptionId: null }
      )
    );
    const response = yield call(
      apis.createAutomaticRenewalSubscriptionPaymentIntent,
      payload
    );
    yield put(
      actions.createAutomaticRenewalSubscriptionPaymentIntentResponse(
        response.data.Data
      )
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* enableAutomaticRenewalSubscriptionSaga() {
  try {
    yield call(apis.enableAutomaticRenewalSubscription);
    yield showSuccessMsg("automaticRenewalEnabled");
    yield put(actions.getUserSubscriptionPlanRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* cancelAutomaticRenewalSubscriptionSaga() {
  try {
    yield call(apis.cancelAutomaticRenewalSubscription);
    yield showSuccessMsg("automaticRenewalCancelled");
    yield put(actions.getUserSubscriptionPlanRequest());
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* updateAutomaticRenewalSubscriptionTypeSaga({ payload }) {
  try {
    yield call(
      apis.updateAutomaticRenewalSubscriptionPaymentIntent,
      payload.data
    );
    if (payload.setSearchParams) {
      payload.setSearchParams((searchParams) => {
        searchParams.set("is_payment_confirmed", true);
        searchParams.delete("payment_intent");
        searchParams.delete("payment_intent_client_secret");
        searchParams.delete("redirect_status");
        searchParams.delete("card_info");
        searchParams.delete("price_id");
        return searchParams;
      });
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getUserSavedCardsSaga() {
  try {
    const response = yield call(apis.getUserSavedCards);
    yield put(actions.getUserSavedCardsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* SubscriptionSagas() {
  yield takeLatest(
    types.GET_SUBSCRIPTION_PLANS_DETAILS_REQUEST,
    getSubscriptionPlansDetailsSaga
  );
  yield takeLatest(
    types.GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST,
    getSubscriptionPlanDetailsSaga
  );
  yield takeLatest(
    types.CREATE_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
    createSubscriptionPaymentIntentSaga
  );
  yield takeLatest(
    types.CONFIRM_SUBSCRIPTION_PAYMENT,
    confirmSubscriptionPaymentSaga
  );
  yield takeLatest(
    types.GET_USER_SUBSCRIPTION_PLAN_REQUEST,
    getUserSubscriptionPlanSaga
  );
  yield takeLatest(
    types.GET_PREVIOUS_SUBSCRIPTION_TYPE_REQUEST,
    getPreviousSubscriptionTypeSaga
  );
  yield takeLatest(
    types.DELETE_EXTRA_SHOWCASES,
    deleteExtraShowcasesSaga
  );
  yield takeLatest(types.REQUEST_VOUCHER_REQUEST, requestVoucherSaga);
  yield takeLatest(types.APPLY_VOUCHER_REQUEST, applyVoucherSaga);
  yield takeLatest(
    types.CHECK_CAN_RENEW_SUBSCRIPTION_REQUEST,
    checkCanRenewSubscriptionSaga
  );
  yield takeLatest(
    types.GET_SUBSCRIPTION_FINAL_FEES_REQUEST,
    getSubscriptionFinalFeesSaga
  );
  yield takeLatest(
    types.CREATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_PAYMENT_INTENT_REQUEST,
    createAutomaticRenewalSubscriptionPaymentIntentSaga
  );
  yield takeLatest(
    types.ENABLE_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST,
    enableAutomaticRenewalSubscriptionSaga
  );
  yield takeLatest(
    types.CANCEL_AUTOMATIC_RENEWAL_SUBSCRIPTION_REQUEST,
    cancelAutomaticRenewalSubscriptionSaga
  );
  yield takeLatest(
    types.UPDATE_AUTOMATIC_RENEWAL_SUBSCRIPTION_TYPE_REQUEST,
    updateAutomaticRenewalSubscriptionTypeSaga
  );
  yield takeLatest(
    types.GET_USER_SAVED_CARDS_REQUEST,
    getUserSavedCardsSaga
  );
}
