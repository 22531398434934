import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { UnpublishModalIcon } from "../../../../utils/Icons";
import { unPublishProductRequest } from "../../../../store/Products/actions";
import { PRODUCT_STATUS_WITH_ID } from "../../../../utils/Constants";

const UnpublishProduct = ({ open, closeModal, productInfo }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    products: {
      unpublishProduct: {
        content,
        confirmationContent,
        editedProductConfirmationContent
      }
    },
    shared
  } = messages[lang];

  const handleConfirmUnpublish = () => {
    dispatch(
      unPublishProductRequest({
        data: {
          id: productInfo.id
        },
        src: productInfo.src,
        params: productInfo.params,
        navigate
      })
    );
    closeModal();
  };

  const publishProductContent = () => {
    return (
      <div className="unpublish-product-container container d-flex flex-column justify-content-center align-items-center">
        <UnpublishModalIcon />
        <div className="unpublish-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${productInfo.name}"`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {(productInfo.IsEdit &&
              productInfo.status ===
                PRODUCT_STATUS_WITH_ID.pending.ProductStatusId &&
              productInfo.originalProductStatus ===
                PRODUCT_STATUS_WITH_ID.published.ProductStatusId) ||
            (productInfo.IsEdit &&
              productInfo.status ===
                PRODUCT_STATUS_WITH_ID.published.ProductStatusId)
              ? editedProductConfirmationContent
              : confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmUnpublish();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={publishProductContent()}
      hasActions={true}
      confirmBtnTxt={shared.unPublish}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default UnpublishProduct;

UnpublishProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired,
  updateList: PropTypes.func,
  detailsFlag: PropTypes.bool
};
