import {
  DECREMENT_NOTIFICATIONS_COUNT,
  GET_INITIAL_NOTIFICATIONS_REQUEST,
  GET_INITIAL_NOTIFICATIONS_RESPONSE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_RESPONSE,
  GET_UNSEEN_NOTIFICATIONS_COUNT_REQUEST,
  INCREMENT_NOTIFICATIONS_COUNT,
  SET_ALL_NOTIFICATIONS_SEEN,
  SET_NOTIFICATION_SEEN,
  SET_PUSH_NOTIFICATION,
  SET_UNSEEN_NOTIFICATIONS_COUNT
} from "./types";

export const setPushNotification = (payload) => ({
  type: SET_PUSH_NOTIFICATION,
  payload
});

export const getInitialNotificationsRequest = (payload) => ({
  type: GET_INITIAL_NOTIFICATIONS_REQUEST,
  payload
});

export const getInitialNotificationsResponse = (payload) => ({
  type: GET_INITIAL_NOTIFICATIONS_RESPONSE,
  payload
});

export const getNotificationsRequest = (payload) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload
});

export const getNotificationsResponse = (payload) => ({
  type: GET_NOTIFICATIONS_RESPONSE,
  payload
});

export const setNotificationSeen = (payload) => ({
  type: SET_NOTIFICATION_SEEN,
  payload
});

export const setAllNotificationsSeen = (payload) => ({
  type: SET_ALL_NOTIFICATIONS_SEEN,
  payload
});

export const setUnseenNotificationsCountRequest = (payload) => ({
  type: SET_UNSEEN_NOTIFICATIONS_COUNT,
  payload
});

export const incrementNotificationsCount = () => ({
  type: INCREMENT_NOTIFICATIONS_COUNT
});

export const decrementNotificationsCount = () => ({
  type: DECREMENT_NOTIFICATIONS_COUNT
});

export const getUnseenNotificationsCountRequest = (payload) => ({
  type: GET_UNSEEN_NOTIFICATIONS_COUNT_REQUEST,
  payload
});
