import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import messages from "../../../assets/locale/messages";
import SubCategoriesSection from "./SubCategoriesSection";
import ExploreSection from "./ExploreSection";
import HeroSection from "./HeroSection";
import ProductsSection from "./ProductsSection";
import ExportersSection from "./ExportersSection";
import PackagesSection from "./PackagesSection";
import ContactSection from "./ContactSection";
import GetSupportSection from "./GetSupportSection";
import FeaturedExportersSection from "./FeaturedExporters";
import { getHomeDetailsRequest } from "../../../store/Home/actions";
import { USER_TYPES_IDS } from "../../../utils/Constants";
import "./Home.scss";

const Home = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { home } = messages[lang];
  const {
    homeDetails: {
      CategoryDetails,
      ExporterDetails,
      LatestProductDetails,
      PopularProductDetails,
      FeaturedExporterDetails,
      SubcriptionPlansDetails
    }
  } = useSelector((state) => state.home);

  const userType = useSelector(
    (state) => state.auth.login.UserTypeId
  );
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  useEffect(() => {
    if (websiteCurrency?.Id) {
      dispatch(
        getHomeDetailsRequest({
          currencyId: websiteCurrency?.Id
        })
      );
    }
  }, [websiteCurrency]);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [location]);

  const sectionsResponsiveSpacing =
    "px-3 px-md-4 px-lg-5 px-xxl-0 py-3 mb-xl-3 mb-lg-3 mb-md-2 mb-0";

  return (
    <div className="home-page-container">
      <div className="mb-xl-3 mb-lg-3 mb-md-2 mb-0" id="top">
        <HeroSection />
      </div>
      <div className="container-xxl">
        <div className={sectionsResponsiveSpacing}>
          <SubCategoriesSection subCategories={CategoryDetails} />
        </div>
        <div className={sectionsResponsiveSpacing}>
          <ExploreSection />
        </div>
        <div className={sectionsResponsiveSpacing}>
          <ProductsSection
            sectionTitle={home.popularProducts}
            products={PopularProductDetails}
            sectionKey="popular"
          />
        </div>
        <div className={sectionsResponsiveSpacing}>
          <GetSupportSection />
        </div>
        <div className={sectionsResponsiveSpacing}>
          <ExportersSection exporters={ExporterDetails} />
        </div>
        {userType !== USER_TYPES_IDS.importer && (
          <div className="py-3 mb-xl-3 mb-lg-3 mb-md-2 mb-0">
            <PackagesSection plans={SubcriptionPlansDetails} />
          </div>
        )}
        <div className={sectionsResponsiveSpacing}>
          <ProductsSection
            sectionTitle={home.latestProducts}
            products={LatestProductDetails}
            sectionKey="latest"
          />
        </div>
        {!!FeaturedExporterDetails.length && (
          <div className={sectionsResponsiveSpacing}>
            <FeaturedExportersSection
              exporters={FeaturedExporterDetails}
            />
          </div>
        )}
        <div className={sectionsResponsiveSpacing}>
          <ContactSection />
        </div>
      </div>
    </div>
  );
};

export default Home;
