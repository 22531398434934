export const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="16"
    fill="none"
    viewBox="0 0 23 16"
    {...props}>
    <path
      fill="#fff"
      d="M.525 8.108c.014-.024.031-.046.04-.071.172-.505.54-.777 1.085-.793.207-.006.415 0 .622 0 6.321 0 12.642-.001 18.963.002.184 0 .376.014.55.068.481.15.78.62.73 1.107-.05.49-.434.884-.937.95a2.72 2.72 0 01-.364.02c-6.435 0-12.87-.004-19.306.007-.684.001-1.164-.215-1.383-.871v-.42zM14.264.819c2.37 0 4.739-.002 7.108.001.665 0 1.15.462 1.146 1.076-.004.56-.46 1.028-1.034 1.06-.057.004-.115.003-.171.003H7.224c-.588 0-1.017-.291-1.157-.78A1.063 1.063 0 017.091.822C7.993.812 8.895.82 9.797.82h4.467zM16.453 15.816c-1.638 0-3.276.003-4.915-.002-.72-.002-1.216-.588-1.094-1.27.091-.505.545-.866 1.106-.867 1.188-.003 2.376-.001 3.563-.001h6.268c.446 0 .79.184 1.003.567.2.36.186.727-.038 1.073s-.558.502-.977.501c-1.638-.003-3.276-.001-4.916-.002z"></path>
  </svg>
);
