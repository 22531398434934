export const BuildingsSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25">
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M6.7 18.367H4.15c-1.43 0-2.15-.72-2.15-2.15v-11.7c0-1.43.72-2.15 2.15-2.15h4.3c1.43 0 2.15.72 2.15 2.15v1.85"></path>
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M17.37 8.787v11.16c0 1.61-.8 2.42-2.41 2.42H9.12c-1.61 0-2.42-.81-2.42-2.42V8.787c0-1.61.81-2.42 2.42-2.42h5.84c1.61 0 2.41.81 2.41 2.42z"></path>
    <path
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M13.4 6.367v-1.85c0-1.43.72-2.15 2.15-2.15h4.3c1.43 0 2.15.72 2.15 2.15v11.7c0 1.43-.72 2.15-2.15 2.15h-2.48M10 11.367h4M10 14.367h4M12 22.367v-3"></path>
  </svg>
);
