export const EGYPT_MOBILEPHONE_REGEX = /^0?1[0-2,5]{1}[0-9]{8}$/;
export const IBAN_REGEX = /^[a-zA-Z0-9]{29}$/;
export const ARABIC_REGEX = /^[\u0600-\u06FF0-9\s_]+$/;
export const ENGLISH_REGEX = /^[a-zA-Z0-9\s_]+$/;
export const PRICE_REGEX = /^\d+(\.[0-9]?[0-9])?$/;
export const ONLY_LITTERS_AND_NUMBERS = /^[A-Za-z0-9_-]*$/;
export const NO_SPACE = /^\S*$/;
export const ONLY_POSITIVE_NUMBERS = /^[1-9][0-9]*$/;
export const DIGITS_ONLY = /^\d+$/;
export const FROM_ONE_TO_HUNDRED_ONLY = /^[1-9][0-9]?$|^100$/;
export const FROM_ONE_TO_NINETY_NINE_ONLY = /^[1-9][0-9]?$|^99$/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const ONLY_URL =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const NAME_REGEX_NO_SPECIAL_CHARACTERS =
  /^[A-Za-z\u0621-\u064A\s0-9 ]+$/;
export const NAME_REGEX_START_WITH_CHARACTERS_ONLY =
  /^[a-zA-Z\u0621-\u064A]+([\s\S]*)$/;
export const TWO_WORDS_ONLY =
  /^[\p{L}\p{N}\p{S}]+(?:\s[\p{L}\p{N}\p{S}]+)?$/u;
export const WORDS_SPECIAL_CHARACTERS_ONLY = /^[a-zA-Z\s\W]*$/;
export const ONLY_DIGITS_SIX_FIGURES = /^(?=.*\d)\d{1,6}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LIMITED_DOMAIN_LENGTH_EMAIL_REGEX =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const WEBSITE_REGEX = /^www\.[a-zA-Z0-9-]+\.com$/;
export const COMPANY_WEBSITE_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
export const ALLOW_ALL_WITH_MANDATORY_CHARACTERS =
  /^(?=.*[a-zA-Z\u0621-\u064A])[a-zA-Z\u0621-\u064A\s\W]*$/;
