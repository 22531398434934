import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import img1 from "../../../../assets/images/home/hero-section-image-bg.png";
import img2 from "../../../../assets/images/home/green_hero_section_bg_only.png";
import img3 from "../../../../assets/images/home/hero_section_main_img.svg";
import img4 from "../../../../assets/images/home/green_hero_section_main_img_bg_mobile_only.png";
import messages from "../../../../assets/locale/messages";
import Button from "../../../../components/Button";
import Swiper from "../../../../components/Swiper";

const HeroSection = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    home: { herosection }
  } = messages[lang];

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;

    if (width <= 767) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderSlide = () => (
    <div
      className={`hero-section-bg-img d-md-flex align-items-center ${
        isMobileScreen ? "" : "position-relative"
      } `}
      style={{
        backgroundImage: isMobileScreen ? `url(${img2})` : ``,
        backgroundSize: "cover"
      }}>
      {!isMobileScreen && <img src={img1} className="w-100" />}
      <div
        className={`slide-description  ${isMobileScreen ? "w-100 mobile-description" : "w-50 position-absolute"}`}>
        <h1 className="text-dark-blue fweight-700 fsize-45 mb-0 ex-gates pt-md-0 pt-5">
          {herosection.exgates}
        </h1>
        <p className="text-dark-blue fweight-700 fsize-40 mb-2 slogan">
          {herosection.slogan}
        </p>
        <p className="text-color fsize-20 mb-3 mb-lg-5 description text-break col-md-12 col-10">
          {herosection.description}
        </p>
        <Button label={herosection.explore} className="explore-btn" />
      </div>
      {isMobileScreen && (
        <div
          style={{
            backgroundImage: isMobileScreen ? `url(${img4})` : ``,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
          className="mobile-slogan-img-container w-100 d-flex justify-content-center pb-md-0 pt-md-0 pt-5 pb-5">
          <img
            src={img3}
            className="mobile-slogan-img w-75"
            style={{ objectFit: "cover" }}
          />
        </div>
      )}
    </div>
  );

  const slides = [
    <div key={`slide-${0}`} className="slide-container">
      {renderSlide()}
    </div>,
    <div key={`slide-${1}`} className="slide-container">
      {renderSlide()}
    </div>,
    <div key={`slide-${2}`} className="slide-container">
      {renderSlide()}
    </div>,
    <div key={`slide-${3}`} className="slide-container">
      {renderSlide()}
    </div>,
    <div key={`slide-${4}`} className="slide-container">
      {renderSlide()}
    </div>
  ];
  return (
    <div className="hero-section-container">
      <Swiper
        slides={slides}
        sliderClassName={"hero-section-slider"}
        name={"hero-section-slider"}
        slidesToShow={1}
        infinite={true}
        autoplay={true}
        speed={750}
        delay={5000}
        dots={true}
      />
    </div>
  );
};

export default HeroSection;
