export const UnpublishModalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="57"
    fill="none"
    viewBox="0 0 56 57">
    <rect
      width="48"
      height="48"
      x="4"
      y="4.642"
      fill="#FEE4E2"
      rx="24"></rect>
    <rect
      width="48"
      height="48"
      x="4"
      y="4.642"
      stroke="#FEF3F2"
      strokeWidth="8"
      rx="24"></rect>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.17 24.634l8.83 5.11 8.77-5.08M28 38.804v-9.07"></path>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M37.61 26.364v5.66c0 .05 0 .09-.01.14-.7-.61-1.6-.97-2.6-.97-.94 0-1.81.33-2.5.88a3.97 3.97 0 00-1.5 3.12c0 .75.21 1.46.58 2.06.09.16.2.31.32.45l-1.83 1.01c-1.14.64-3 .64-4.14 0l-5.34-2.96c-1.21-.67-2.2-2.35-2.2-3.73v-5.66c0-1.38.99-3.06 2.2-3.73l5.34-2.96c1.14-.64 3-.64 4.14 0l5.34 2.96c1.21.67 2.2 2.35 2.2 3.73z"></path>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M39 35.194c0 .75-.21 1.46-.58 2.06-.21.36-.48.68-.79.94-.7.63-1.62 1-2.63 1a3.97 3.97 0 01-3.42-1.94 3.92 3.92 0 01-.58-2.06c0-1.26.58-2.39 1.5-3.12a3.999 3.999 0 016.5 3.12zM36.07 36.234l-2.12-2.11M36.05 34.154l-2.12 2.11"></path>
  </svg>
);
