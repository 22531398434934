import {
  GET_CATEGORY_DETAILS_REQUEST,
  GET_CATEGORY_DETAILS_RESPONSE,
  SET_CATEGORY_SUGGESTION_MODAL_OPEN,
  SET_SUGGESTED_CATEGORY_DATA,
  SET_SUGGESTED_CATEGORY_TEMP_ID,
  SUGGEST_NEW_CATEGORY_REQUEST,
  GET_CATEGORY_DROPDOWN_REQUEST,
  GET_CATEGORY_DROPDOWN_RESPONSE
} from "./types";

export const setCategorySuggestionModalOpen = (payload) => ({
  type: SET_CATEGORY_SUGGESTION_MODAL_OPEN,
  payload
});

export const suggestNewCategoryRequest = (payload) => ({
  type: SUGGEST_NEW_CATEGORY_REQUEST,
  payload
});

export const setSuggestedCategoryTempId = (payload) => ({
  type: SET_SUGGESTED_CATEGORY_TEMP_ID,
  payload
});

export const getCategoryDetailsRequest = (payload) => ({
  type: GET_CATEGORY_DETAILS_REQUEST,
  payload
});

export const getCategoryDetailsResponse = (payload) => ({
  type: GET_CATEGORY_DETAILS_RESPONSE,
  payload
});

export const setSuggestedCategoryData = (payload) => ({
  type: SET_SUGGESTED_CATEGORY_DATA,
  payload
});

export const getCategoryDropdownResponse = (payload) => ({
  type: GET_CATEGORY_DROPDOWN_RESPONSE,
  payload
});
export const getCategoryDropdownRequest = (payload) => ({
  type: GET_CATEGORY_DROPDOWN_REQUEST,
  payload
});
