import {
  newPipeline,
  AnonymousCredential,
  BlobServiceClient
} from "@azure/storage-blob";
import { showErrorMsg } from "./Helpers";
import messages from "../assets/locale/messages";
import store from "../store";
import { showHideLoader } from "../store/Loader/actions";

const account = process.env.REACT_APP_IMAGES_STORAGE_ACCOUNT_NAME;
const accountSas = process.env.REACT_APP_VIDEOS_STORAGE_ACCOUNT_SAS;

export const BLOB_CONTAINERS = {
  images: "images",
  videos: "videos",
  specifications: "specifications",
  pdfs: "pdfs"
};

export async function UploadBlobToContainer(
  file,
  containerName,
  fullLink = false,
  isCustomNameFile
) {
  const pipeline = newPipeline(new AnonymousCredential(), {
    retryOptions: { maxTries: 4 }, // Retry options
    userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
    keepAliveOptions: {
      enable: false
    }
  });

  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net${accountSas}`,
    pipeline
  );

  // Create a blob
  const regex = /(?:\.([^.]+))?$/;
  let extension = regex.exec(file.name)[1];

  const blobName = isCustomNameFile
    ? file.name
    : guid() + new Date().getTime() + "." + extension;
  const containerClient =
    blobServiceClient.getContainerClient(containerName);
  const blockBlobClient =
    containerClient.getBlockBlobClient(blobName);

  try {
    await blockBlobClient.uploadBrowserData(file.blob, {
      blobHTTPHeaders: {
        blobContentType: file.type
      }
    });
    return fullLink
      ? { name: blockBlobClient.name, url: blockBlobClient.url }
      : blockBlobClient.name;
  } catch (err) {
    if (!navigator.onLine) {
      const lang = localStorage.getItem("lang");
      showErrorMsg(messages[lang].shared.internetConnection);
      store.dispatch(showHideLoader(false));
    }
    console.log(
      `uploadFile failed, requestId - ${err.request.requestId}`
    );
    return "";
  }
}

export async function deleteBlob(blobName, containerName) {
  const pipeline = newPipeline(new AnonymousCredential(), {
    retryOptions: { maxTries: 4 }, // Retry options
    userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
    keepAliveOptions: {
      enable: false
    }
  });

  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net${accountSas}`,
    pipeline
  );

  const containerClient =
    blobServiceClient.getContainerClient(containerName);

  await containerClient.deleteBlob(blobName);
}

const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  var id =
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4();
  return id;
};
