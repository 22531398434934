import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout
} from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import "./VideoPlayer.scss";

const VideoPlayer = ({ videoSrc, videoName }) => {
  const playerRef = useRef(null);
  let clickedVideo = null;

  // This is used to scroll back to video after exiting full screen mode
  useEffect(() => {
    const handleFullScreenChange = (event) => {
      if (document.fullscreenElement) {
        clickedVideo = event.target;
      } else {
        clickedVideo.scrollIntoView();
      }
    };

    // Add event listeners for fullscreen changes
    document.addEventListener(
      "fullscreenchange",
      handleFullScreenChange
    );
    document.addEventListener(
      "webkitfullscreenchange",
      handleFullScreenChange
    ); // Safari support
    document.addEventListener(
      "mozfullscreenchange",
      handleFullScreenChange
    ); // Firefox support
    document.addEventListener(
      "MSFullscreenChange",
      handleFullScreenChange
    ); // IE/Edge support

    return () => {
      // Clean up event listeners when the component unmounts
      document.removeEventListener(
        "fullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  return (
    <div className="video-player-container">
      <MediaPlayer
        playsInline
        ref={playerRef}
        title={videoName || ""}
        src={videoSrc}
        className="h-100">
        <MediaProvider />
        <DefaultVideoLayout icons={defaultLayoutIcons} />
      </MediaPlayer>
    </div>
  );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoName: PropTypes.string
};
