import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  CircularProgress,
  Typography
} from "@mui/material";
import { Add } from "@mui/icons-material";

import messages from "../../../../../assets/locale/messages";
import Modal from "../../../../../components/Modal";
import SearchInput from "../../../../../components/SearchInput";
import {
  getSubCategoryProductsListRequest,
  getSubCategoryProductsListResponse
} from "../../../../../store/User/actions";
import EmptyState from "../../../../../components/EmptyState";
import ProductCardGrid from "../../../../../components/ProductCards/ProductCardGrid";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import InfiniteScroll from "../../../../../components/InfiniteScroll";
import noProductsImage from "../../../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import Tabs from "../../../../../components/Tabs";
import { PRODUCT_STATUS_WITH_ID } from "../../../../../utils/Constants";
import { getUserCurrency } from "../../../../../utils/Helpers";
import "./AddShowcaseProductModal.scss";

const AddShowcaseProduct = ({
  open,
  handleClose,
  subcategoryId,
  setShowcaseProductsIds,
  setShowcaseProducts,
  showcaseProducts,
  showcaseId
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const subcategoryProductsList = useSelector(
    (state) => state.user.subcategoryProductsList
  );
  const subcategoryProductsListMeta = useSelector(
    (state) => state.user.subcategoryProductsListMeta
  );
  const {
    company: {
      addShowcase: {
        modal,
        modal: { emptyState, tabs }
      }
    },
    shared
  } = messages[lang];
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsId, setSelectedProductsId] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setSelectedProducts(showcaseProducts);
    setSelectedProductsId(
      showcaseProducts.map((product) => product.Id)
    );
  }, [showcaseProducts]);

  useEffect(() => {
    dispatch(
      getSubCategoryProductsListResponse({
        list: [],
        meta: {}
      })
    );
    dispatch(
      getSubCategoryProductsListRequest({
        params: {
          SubCategory_ids: subcategoryId,
          showcase: showcaseId ? null : true,
          page,
          search_value: searchValue,
          showcaseId
        }
      })
    );
    return () => {
      dispatch(
        getSubCategoryProductsListResponse({
          list: [],
          meta: {}
        })
      );
    };
  }, [subcategoryId, searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, [tabValue]);

  const handleSelected = (selectedProduct) => {
    if (selectedProductsId.includes(selectedProduct.Id)) {
      const filteredProductsIdArray = selectedProductsId.filter(
        (listProductId) => listProductId !== selectedProduct.Id
      );
      const filteredArray = selectedProducts.filter(
        (product) => product.Id !== selectedProduct.Id
      );
      setSelectedProductsId(filteredProductsIdArray);
      setSelectedProducts(filteredArray);
    } else {
      setSelectedProductsId([
        ...selectedProductsId,
        selectedProduct.Id
      ]);
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }
  };

  const getInfiniteSCrollData = () => {
    dispatch(
      getSubCategoryProductsListRequest({
        params: {
          SubCategory_ids: subcategoryId,
          showcase: showcaseId ? null : true,
          page: page + 1,
          search_value: searchValue,
          showcaseId
        }
      })
    );
    setPage((page) => page + 1);
  };
  const handleCloseModal = () => {
    setSelectedProducts([]);
    setSelectedProductsId([]);
    setPage(0);
    setTabValue(0);
    setSearchValue("");
    handleClose();
  };

  const handleConfirmSelect = () => {
    setShowcaseProductsIds(selectedProductsId);
    setShowcaseProducts(selectedProducts);
    handleCloseModal();
    setSelectedProducts([]);
    setSelectedProductsId([]);
    setTabValue(0);
    setPage(1);
    setSearchValue("");
  };

  const handleEditedProductStatusCheck = (product) => {
    if (product && Object.keys(product).length !== 0) {
      const tempStatus = Object.values(PRODUCT_STATUS_WITH_ID).find(
        (status) => {
          return status.ProductStatusId === product?.ProductStatusId;
        }
      );

      if (
        tempStatus &&
        Object.keys(tempStatus).length !== 0 &&
        (tempStatus?.ProductStatusId === 4 ||
          tempStatus?.ProductStatusId === 2 ||
          tempStatus?.ProductStatusId === 1 ||
          tempStatus?.ProductStatusId === 7) &&
        product?.IsEdit
      ) {
        product.ProductStatusName =
          PRODUCT_STATUS_WITH_ID.edited.ProductStatusName;
        if (tempStatus?.ProductStatusId === 1) {
          product.isPendingEdit = true;
        }
      }
    }
    return product;
  };

  const handleStatusName = (ProductStatusName) => {
    let chipClass;
    switch (ProductStatusName) {
      case PRODUCT_STATUS_WITH_ID.published.ProductStatusName: {
        chipClass = "green-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.edited.ProductStatusName: {
        chipClass = "blue-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.pending.ProductStatusName: {
        chipClass = "orange-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.draft.ProductStatusName: {
        chipClass = "yellow-chip";
        break;
      }
      case PRODUCT_STATUS_WITH_ID.rejected.ProductStatusName: {
        chipClass = "red-chip";
        break;
      }
      default:
        chipClass = "medium-grey-chip";
    }
    return chipClass;
  };

  const productCardContainer = (product) => {
    const {
      Id,
      Name,
      DefaultImage,
      MinOrderQuantity,
      MoqUnitName,
      MinPriceRange,
      MaxPriceRange,
      ProductStatusId,
      ProductStatusName,
      Country
    } = product;
    const checkedProduct = handleEditedProductStatusCheck(product);
    const chipClass = handleStatusName(
      checkedProduct.ProductStatusName
    );

    return (
      <Box
        className={`product-container
                         my-3 border-radius-8 col-xl-4 col-lg-4 col-md-4 `}
        key={Id}
        onClick={() => {
          handleSelected(product);
        }}>
        {ProductStatusId && (
          <div className="unpublished-cover border-radius-8">
            <Chip
              label={
                <div
                  className={` d-flex justify-content-center align-items-center fsize-12 fweight-500 pointer`}>
                  {checkedProduct.ProductStatusName}
                </div>
              }
              className={` border-radius-8  ${chipClass}`}
            />
          </div>
        )}
        <ProductCardGrid
          selectedModalCard={selectedProductsId.includes(Id)}
          modalCard
          key={Id}
          label={Name}
          src={DefaultImage}
          count={MinOrderQuantity}
          unit={MoqUnitName}
          minPrice={MinPriceRange}
          maxPrice={MaxPriceRange}
          country={Country}
          currency={getUserCurrency()?.symbol}
        />
      </Box>
    );
  };

  const selectedProductsContent = () => {
    return (
      <div className="add-items-container">
        {!isLoading && selectedProducts.length === 0 && (
          <EmptyState
            subTitle={emptyState.noSelectedProductsPlaceholder}
            imgSrc={noProductsImage}
          />
        )}
        {selectedProducts.length > 0 && (
          <div className="row">
            {selectedProducts?.length &&
              selectedProducts.map((product) => {
                return productCardContainer(product);
              })}
          </div>
        )}
        {isLoading && (
          <div className="text-center my-md-1">
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    );
  };

  const addProductsContent = () => {
    return (
      <div className="add-items-container">
        {!isLoading && subcategoryProductsList.length === 0 && (
          <EmptyState
            subTitle={
              !!searchValue && !subcategoryProductsList.length
                ? emptyState.noSearchFilterResults
                : emptyState.noProductsPlaceholder
            }
            showActionButton={
              !searchValue && subcategoryProductsList.length === 0
            }
            buttonLabel={
              <span className="d-flex align-items-center">
                <Add fontSize="medium" />
                <span className="ps-1 ">{emptyState.addMoreBtn}</span>
              </span>
            }
            onButtonClick={() => {
              navigate(ROUTE_PATHS.addProduct);
            }}
            buttonClassName="empty-state-create-button border-radius-8"
            imgSrc={noProductsImage}
          />
        )}

        {subcategoryProductsList.length > 0 && (
          <InfiniteScroll
            containerHeight={"20rem"}
            dataLength={subcategoryProductsList.length}
            nextFunction={getInfiniteSCrollData}
            hasMore={
              subcategoryProductsListMeta?.currentPage <
              subcategoryProductsListMeta?.pages
            }
            containerClass="menus-container mb-2 "
            data={
              subcategoryProductsList?.length &&
              subcategoryProductsList.map((product) => {
                return productCardContainer(product);
              })
            }
          />
        )}
        {isLoading && !searchValue && (
          <div className="text-center my-md-1">
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    );
  };

  const tabsArray = [
    {
      label: tabs.allProducts,
      content: addProductsContent(),
      isCountShow: true,
      isCountShowValue: subcategoryProductsListMeta?.count
    },
    {
      label: tabs.selectedProducts,
      content: selectedProductsContent(),
      isCountShow: true,
      isCountShowValue: selectedProducts?.length
    }
  ];
  const modalContent = () => {
    return (
      <div
        className="h-100 modal-content-container"
        style={{ minHeight: "28rem", height: "28rem" }}>
        {subcategoryProductsList.length > 0 || searchValue ? (
          <div>
            {selectedProducts.length >= 6 && (
              <div className="block-title text-secondary mb-3">
                <Typography>{modal.maxSelectedProduct}</Typography>
              </div>
            )}
            <SearchInput
              disabled={tabValue === 1}
              name="selectedProductsSearch"
              id="AssignMenuToBranchSearch"
              value={searchValue}
              inputClass="border-radius-12"
              inputWrapperClass={
                "search-input-wrapper search-input-wrapper-width large-placeholder-search"
              }
              placeholder={modal.searchPlaceholder}
              onChange={(value) => {
                setPage(1);
                setSearchValue(value);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <Tabs
          className={"add-product-tabs"}
          tabs={tabsArray}
          value={tabValue}
          handleChange={setTabValue}
        />
      </div>
    );
  };

  return (
    <Modal
      modalClass={"add-products-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirmSelect();
      }}
      handleClose={handleClose}
      isContentText={false}
      content={modalContent()}
      contentClass={"modal-content"}
      hasActions={!!subcategoryProductsList.length}
      confirmBtnTxt={shared.add}
      cancelBtnTxt={shared.cancel}
      handleCancel={handleClose}
      showCloseIcon
      title={modal.title}
      confirmBtnClass="border-radius-12 ps-5 pe-5 me-3"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"md"}
      disableConfirmBtn={selectedProducts.length > 6}
      alignBtnsCenter={false}
    />
  );
};

export default AddShowcaseProduct;

AddShowcaseProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  subcategoryId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  showcaseId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  setShowcaseProductsIds: PropTypes.func,
  setShowcaseProducts: PropTypes.func,
  showcaseProducts: PropTypes.array
};
