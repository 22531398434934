export const GuideCheckGreenBgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    fill="none"
    viewBox="0 0 30 31"
    {...props}>
    <circle
      cx="15"
      cy="15.509"
      r="14"
      fill="#22B573"
      stroke="#F1FAFF"
      strokeWidth="2"></circle>
    <path
      fill="#fff"
      d="M12.8 18.637l-3.128-3.128-1.065 1.058 4.193 4.192 9-9-1.058-1.057-7.942 7.935z"></path>
  </svg>
);
