import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import ListFilter from "../../../components/Filters/ListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DisplayStyleButton from "../../../components/DisplayStyleButton";
import Button from "../../../components/Button";
import Filters from "../../../components/Filters";
import EmptyState from "../../../components/EmptyState";
import messages from "../../../assets/locale/messages";
import noExportersImage from "../../../assets/images/vectors/placeholders/noExporterPlaceholder.svg";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  DISPLAY_STYLE,
  generalImporterPagesPadding,
  USER_STATUS_IDS
} from "../../../utils/Constants";
import {
  getCategoriesListRequest,
  getCountriesListRequest
} from "../../../store/Lookups/actions";
import { getExportersListRequest } from "../../../store/Exporters/actions";
import exportersCoverImg from "../../../assets/images/exportersListCoverImg.svg";
import ExporterCardList from "../../../components/ExporterCards/ExporterCardList";
import ExporterCardGrid from "../../../components/ExporterCards/ExporterCardGrid";
import SortList from "../../../components/Filters/SortList";
import "./ExportersList.scss";

const ExportersList = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const exportersList = useSelector(
    (state) => state.exporters.exportersList
  );
  const exportersMeta = useSelector(
    (state) => state.exporters.exportersMeta
  );
  const { countriesList } = useSelector((state) => state.lookups);
  const { categoriesList } = useSelector((state) => state.lookups);
  const { exporters, shared } = messages[lang];

  const sortingOptions = [
    {
      id: 1,
      label: exporters.list.filter.mostPopular,
      value: false
    },
    {
      id: 2,
      label: exporters.list.filter.alphabetically,
      value: true
    }
  ];

  const verificationOptions = [
    {
      Id: 1,
      Name: exporters.list.filter.verified
    },
    {
      Id: 2,
      Name: exporters.list.filter.unverified
    }
  ];

  const [sortingRange, setSortingRange] = useState(sortingOptions[0]);
  const [countryFilterValue, setCountryFilterValue] = useState([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [displayStyle, setDisplayStyle] = useState(
    DISPLAY_STYLE.grid
  );
  const [clearFilters, setClearFilters] = useState(false);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getCategoriesListRequest({ page: -1 }));
    dispatch(getCountriesListRequest({ page: -1 }));
  }, []);

  const handleShowMore = () => {
    setPage(page + 1);
  };

  const requestExportersList = (isClear) => {
    dispatch(
      getExportersListRequest({
        params: {
          page,
          grid: displayStyle === DISPLAY_STYLE.grid,
          isSortAlpha: sortingRange?.value,
          country_ids: isClear
            ? null
            : countryFilterValue?.length
              ? countryFilterValue
              : null,
          category_ids: isClear
            ? null
            : categoryFilterValue?.length
              ? categoryFilterValue
              : null,
          status_ids: isClear
            ? null
            : statusFilterValue?.length
              ? statusFilterValue
              : null
        }
      })
    );
  };

  useEffect(() => {
    requestExportersList();
  }, [page, displayStyle]);

  useEffect(() => {
    if (isApplyFilters) {
      page > 1 ? setPage(1) : requestExportersList();
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    page > 1 ? setPage(1) : requestExportersList();
  }, [sortingRange]);

  useEffect(() => {
    if (clearFilters) {
      setCountryFilterValue([]);
      setCategoryFilterValue([]);
      page > 1 ? setPage(1) : requestExportersList(true);
      setTimeout(() => {
        setClearFilters(false);
      }, [500]);
    }
  }, [clearFilters]);

  const breadcrumbs = [
    {
      label: exporters.list.breadcrumbs.home,
      link: ROUTE_PATHS.home
    }
  ];

  const FilterContent = () => {
    return (
      <div className="filters-container">
        <div className="border-bottom pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <ListFilter
            list={countriesList}
            label={exporters.list.filter.country}
            name="countries"
            searchPlaceholder={
              exporters.list.filter.countrySearchPLaceholder
            }
            selectedOptionsIds={countryFilterValue}
            setSelectedOptionsIds={setCountryFilterValue}
            clearFilters={clearFilters}
            collabsible
            showSearchBar
          />
        </div>
        <div className="border-bottom pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <ListFilter
            list={categoriesList}
            label={exporters.list.filter.categories}
            name="categories"
            searchPlaceholder={
              exporters.list.filter.categorySearchPLaceholder
            }
            selectedOptionsIds={categoryFilterValue}
            setSelectedOptionsIds={setCategoryFilterValue}
            clearFilters={clearFilters}
            collabsible
            showSearchBar
          />
        </div>
        <div className="pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <ListFilter
            list={verificationOptions}
            label={exporters.list.filter.verificationStatus}
            name="status"
            selectedOptionsIds={statusFilterValue}
            setSelectedOptionsIds={setStatusFilterValue}
            clearFilters={clearFilters}
            collabsible
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`exporters-details-container container-xxl ${generalImporterPagesPadding}`}>
      <div>
        <div>
          <Breadcrumbs
            list={[
              ...breadcrumbs,
              { label: exporters.list.exporters }
            ]}
          />

          <div className="products-section">
            <div className="row align-items-start">
              <div
                className="col-lg-3 col-md-4 filters-col d-none d-md-block border-radius-10"
                style={{
                  position: "sticky",
                  top: 0
                }}>
                <div className="filters-container">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="title-color fsize-22 font-semibold mb-0">
                      {exporters.list.filters}
                    </p>
                    <Button
                      label={shared.clear}
                      variant="text"
                      onClick={() => {
                        setClearFilters(true);
                        setTimeout(() => {
                          setClearFilters(false);
                        }, [500]);
                      }}
                      className="fsize-14 btn no-shadow"
                    />
                  </div>
                  <div className="filters-section scroll-within">
                    {FilterContent()}
                  </div>
                  <div className="py-3 px-2 d-flex justify-content-between align-items-center">
                    <Button
                      onClick={() => {
                        setIsApplyFilters(true);
                      }}
                      label={shared.apply}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-12 content-col">
                <div className="ps-3">
                  <div className="exporter-info-section mb-4">
                    <div
                      className={`exporter-image w-100 border-radius-10 size-cover`}
                      style={{
                        backgroundImage: `url(${exportersCoverImg})`
                      }}>
                      <div className="exporter-info border-radius-10 d-flex align-items-center justify-content-center">
                        <Box
                          className="text-center"
                          sx={{
                            width: {
                              xs: "100%",
                              md: "60%"
                            }
                          }}>
                          <Typography
                            className="text-white fweight-700 mb-2"
                            sx={{
                              fontSize: {
                                sx: "1.3rem",
                                sm: "2.3rem"
                              }
                            }}>
                            {exporters.list.exporters}
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="controls-section d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-between mb-3">
                    <SortList
                      options={sortingOptions}
                      onSelectOption={setSortingRange}
                      defaultOptionIndex={0}
                      selectedOption={sortingRange}
                    />
                    <div className="filters-sort-buttons-container d-flex align-items-center justify-content-between mt-3 mt-md-0">
                      <div className="filters-button d-block d-md-none">
                        <Filters
                          filterButtonClassName={
                            "exporters-filter-button "
                          }
                          handleClearFilters={setClearFilters}
                          handleApplyFilters={setIsApplyFilters}
                          FilterContent={FilterContent()}
                        />
                      </div>

                      <div className="display-style-section d-flex align-items-center">
                        <DisplayStyleButton
                          displayStyle={displayStyle}
                          onDisplayChange={setDisplayStyle}
                        />
                        <span className="ms-2 title-color">
                          {`${exportersMeta?.count || "0"} ${exporters.list.exportersFound}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  {exportersList && exportersList.length ? (
                    <div className="products-list">
                      <div className="row products-container mb-3">
                        {exportersList?.map(
                          (
                            {
                              CompanyId,
                              CompanyLogo,
                              CompanyName,
                              Country,
                              Intro,
                              IsVerified,
                              UserStatusId
                            },
                            i
                          ) =>
                            displayStyle === DISPLAY_STYLE.grid ? (
                              <div
                                className="grid-item-container col-lg-3 col-sm-6 col-12 mb-4"
                                key={`grid-product-${i}`}>
                                <ExporterCardGrid
                                  src={CompanyLogo}
                                  label={CompanyName}
                                  country={Country}
                                  description={Intro}
                                  link={ROUTE_PATHS.exporterProfile.replace(
                                    ":id",
                                    CompanyId
                                  )}
                                  isVerified={
                                    IsVerified &&
                                    UserStatusId !==
                                      USER_STATUS_IDS.verified
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                key={`list-product-${i}`}
                                className="col-12 mb-4">
                                <ExporterCardList
                                  src={CompanyLogo}
                                  label={CompanyName}
                                  country={Country}
                                  description={Intro}
                                  link={ROUTE_PATHS.exporterProfile.replace(
                                    ":id",
                                    CompanyId
                                  )}
                                  isVerified={
                                    IsVerified &&
                                    UserStatusId !==
                                      USER_STATUS_IDS.verified
                                  }
                                />
                              </div>
                            )
                        )}
                      </div>
                      {!isLoading &&
                        !!exportersList?.length &&
                        exportersMeta.pages >= 1 &&
                        exportersMeta.currentPage <
                          exportersMeta.pages && (
                          <div className="load-more w-100 text-center">
                            <Button
                              label={shared.loadMore}
                              onClick={() => {
                                handleShowMore();
                              }}
                              outlined
                            />
                          </div>
                        )}
                    </div>
                  ) : (
                    !isLoading && (
                      <EmptyState
                        subTitle={
                          exporters.list.noExportersEmptyPlaceholder
                        }
                        imgSrc={noExportersImage}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportersList;
