export const DeactivateAccountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="69"
    fill="none"
    viewBox="0 0 68 69">
    <rect
      width="60"
      height="60"
      x="4"
      y="4.26"
      fill="#FEE4E2"
      rx="28"></rect>
    <rect
      width="60"
      height="60"
      x="4"
      y="4.26"
      stroke="#FEF3F2"
      strokeWidth="8"
      rx="28"></rect>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M41.387 42.498l-3.525 3.525M41.387 46.023l-3.525-3.525M33.575 33.472a2.272 2.272 0 00-.412 0 5.525 5.525 0 01-5.338-5.537 5.545 5.545 0 015.55-5.55 5.553 5.553 0 015.55 5.55c-.012 3-2.375 5.437-5.35 5.537zM33.375 47.147c-2.275 0-4.538-.575-6.262-1.725-3.026-2.025-3.026-5.325 0-7.338 3.437-2.3 9.075-2.3 12.512 0"></path>
  </svg>
);
