import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/home";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getHomeDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getHomeDetails, payload);
    yield put(actions.getHomeDetailsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* sendSupportEmailSaga() {
  try {
    const response = yield call(apis.sendSupportEmail);
    yield put(actions.sendSupportEmailResponse(true));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* HomeSagas() {
  yield takeEvery(types.GET_HOME_DETAILS_REQUEST, getHomeDetailsSaga);
  yield takeEvery(
    types.SEND_SUPPORT_EMAIL_REQUEST,
    sendSupportEmailSaga
  );
}
