export const DeleteOutlinedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.172 5.483a84.752 84.752 0 00-8.35-.417c-1.65 0-3.3.084-4.95.25l-1.7.167M7.756 4.641l.183-1.092c.134-.791.234-1.383 1.642-1.383h2.183c1.409 0 1.517.625 1.642 1.392l.183 1.083M16.38 8.117l-.542 8.392c-.092 1.308-.167 2.325-2.492 2.325h-5.35c-2.325 0-2.4-1.017-2.491-2.325l-.542-8.392M9.28 14.25h2.774M8.588 10.916h4.167"></path>
  </svg>
);
