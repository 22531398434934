import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { EMAIL_REGEX } from "../../../utils/Patterns";
import messages from "../../../assets/locale/messages";
import { loginRequest } from "../../../store/Auth/actions";
import Checkbox from "../../../components/Checkbox";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { getFcmToken } from "../../../notifications/helpers";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { login } = messages[lang];

  const location = useLocation();

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_REGEX, "validEmailFormat")
        .required("emailRequired"),
      password: Yup.string().required("passwordRequired")
    }),
    onSubmit: ({ email, password, rememberMe }) => {
      dispatch(
        loginRequest({
          data: {
            email,
            password,
            deviceToken: getFcmToken() || null,
            is_admin: false
          },
          rememberMe,
          navigate,
          prevLocation: location?.state?.prevLocation
        })
      );
    }
  });

  return (
    <div className={`login-page w-100`}>
      <h3 className="mb-5 title-color fweight-700 fsize-32">
        {login.signin}
      </h3>
      <form className="form-login" onSubmit={handleSubmit} noValidate>
        <Input
          id="email"
          label={login.labels.email}
          name="email"
          type="email"
          placeholder={login.placeholders.email}
          required
          onChange={(value) => {
            setFieldTouched("email");
            setFieldValue("email", value.trim());
          }}
          value={values.email}
          inputClass="mb-1"
          inputWrapperClass="mb-5"
          isInputHasErr={!!(touched.email && errors.email)}
          errMsg={errors.email}
          autoComplete={false}
        />
        <Input
          id="password"
          label={login.labels.password}
          name="password"
          type="password"
          placeholder={login.placeholders.password}
          required={true}
          onChange={(value) => {
            setFieldTouched("password");
            setFieldValue("password", value.trim());
          }}
          value={values.password}
          inputClass="mb-1"
          inputWrapperClass="mt-4"
          isInputHasErr={!!(touched.password && errors.password)}
          errMsg={errors.password}
          autoComplete={false}
        />
        <div className="d-flex align-items-center justify-content-between extra-actions-container">
          <Checkbox
            id="rememberMe"
            label={login.remember}
            labelClass="title-color fsize-16 fweight-500"
            name="rememberMe"
            checked={values.rememberMe}
            onChange={(checked) => {
              setFieldTouched("rememberMe");
              setFieldValue("rememberMe", checked);
            }}
            checkboxClass="text-secondary"
          />
          <Link
            to={ROUTE_PATHS.forgetPassword}
            className="text-primary fsize-14 fweight-500 text-underline forget-password-link">
            {login.forgetPassword}
          </Link>
        </div>
        <div className="text-center">
          <Button
            label={login.signin}
            type="submit"
            className="mt-5 mb-4 w-70 submit-btn"
            disabled={!dirty || !isValid}
          />
          <p className="text-color">
            {login.dontHaveAccount}
            <span
              className="text-primary fweight-600 ms-2 text-underline pointer"
              onClick={() => navigate(ROUTE_PATHS.register)}>
              {login.signup}
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
