export const FacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      fill="#F1FAFF"
      fillRule="evenodd"
      d="M9.729 3.603a4.375 4.375 0 013.093-1.282h2.25a.625.625 0 01.625.625v3a.625.625 0 01-.625.625h-2.25a.126.126 0 00-.125.125v1.625h2.375a.625.625 0 01.607.777l-.75 3a.625.625 0 01-.607.473h-1.625v5.375a.625.625 0 01-.625.625h-3a.625.625 0 01-.625-.625v-5.375H6.822a.625.625 0 01-.625-.625v-3a.625.625 0 01.625-.625h1.625V6.696c0-1.16.461-2.273 1.282-3.093zm3.093-.032a3.125 3.125 0 00-3.125 3.125v2.25a.625.625 0 01-.625.625H7.447v1.75h1.625a.625.625 0 01.625.625v5.375h1.75v-5.375a.625.625 0 01.625-.625h1.762l.437-1.75h-2.199a.625.625 0 01-.625-.625v-2.25a1.375 1.375 0 011.375-1.375h1.625v-1.75h-1.625z"
      clipRule="evenodd"></path>
  </svg>
);
