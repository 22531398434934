import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import messages from "../../../../assets/locale/messages";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ViewProduct from "../../../Products/ViewProduct";
import ProductsSection from "../../Home/ProductsSection";
import {
  getProductsRecommendationsRequest,
  getSubCategoryProductDetailsRequest,
  getSubCategoryProductDetailsResponse
} from "../../../../store/SubCategories/actions";
import { getExporterContactInfoRequest } from "../../../../store/Exporters/actions";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { generalImporterPagesPadding } from "../../../../utils/Constants";

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.login?.Token);
  const isLoading = useSelector((state) => state.loader.showLoader);
  const lang = useSelector((state) => state.locale.lang);
  const subCategoryProductDetails = useSelector(
    (state) => state.subCategoriesProducts.subCategoryProductDetails
  );
  const productsRecommendations = useSelector(
    (state) => state.subCategoriesProducts.productsRecommendations
  );
  const { subCategories } = messages[lang];
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  const [
    subCategoryProductDetailsState,
    setSubCategoryProductDetailsState
  ] = useState({});

  const breadcrumbs = [
    {
      label: subCategories.breadCrumbs.home,
      link: ROUTE_PATHS.home
    },
    {
      label: subCategories.breadCrumbs.products
    }
  ];

  useEffect(() => {
    if (
      id &&
      isAuth &&
      subCategoryProductDetails &&
      subCategoryProductDetails?.ExporterId
    ) {
      dispatch(
        getExporterContactInfoRequest({
          id: subCategoryProductDetails.CompanyId
        })
      );
    }
  }, [subCategoryProductDetails, id, isAuth]);

  useEffect(() => {
    if (id) {
      dispatch(
        getSubCategoryProductDetailsRequest({
          id,
          currencyId: websiteCurrency?.Id
        })
      );
    }
  }, [id, websiteCurrency]);

  useEffect(() => {
    if (id && subCategoryProductDetails?.SubCategoryId) {
      dispatch(
        getProductsRecommendationsRequest({
          subcategoryId: subCategoryProductDetails?.SubCategoryId,
          currencyId: websiteCurrency?.Id,
          productId: id
        })
      );
    }
  }, [id, websiteCurrency, subCategoryProductDetails]);

  useEffect(() => {
    return () => {
      dispatch(getSubCategoryProductDetailsResponse({}));
    };
  }, []);

  useEffect(() => {
    if (
      subCategoryProductDetails &&
      Object.keys(subCategoryProductDetails).length
    ) {
      const {
        Name,
        HsCode,
        Description,
        DefaultImage,
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        Attachments,
        PriceRanges,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        Country,
        Specifications,
        User,
        IsEdit,
        CompanyName,
        CompanyLogo,
        IsVerified
      } = subCategoryProductDetails;
      setSubCategoryProductDetailsState({
        productName: Name,
        productHsCode: HsCode,
        productDescription: Description,
        DefaultImage,
        productImages: Attachments.filter(
          (img) => img.AttachmentTypeId === 1 && img
        ).map((img) => {
          return img.MediaName;
        }),
        productVideo: Attachments.filter(
          (attachment) =>
            attachment.AttachmentTypeId === 2 && attachment
        ).map((video) => {
          return video;
        })[0],
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        PriceRanges,
        currency: websiteCurrency?.Symbol,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        Country: Country || null,
        Specifications,
        User,
        IsEdit,
        CompanyName,
        CompanyLogo,
        IsVerified
      });
    }
  }, [subCategoryProductDetails, websiteCurrency]);

  return (
    <div
      className={`product-details-page container-xxl ${generalImporterPagesPadding}`}>
      <Breadcrumbs
        list={[
          ...breadcrumbs,
          {
            label: subCategoryProductDetails?.Name
          }
        ]}
      />
      {!isLoading && (
        <>
          <ViewProduct
            productDetails={subCategoryProductDetailsState}
          />
          {productsRecommendations &&
            !!productsRecommendations.length && (
              <div className={"my-5"}>
                <ProductsSection
                  sectionTitle={
                    subCategories.productDetails.recommendedForYou
                  }
                  products={productsRecommendations}
                  sectionKey="recommended"
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default ProductDetails;
