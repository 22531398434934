import {
  CANCEL_VERIFICATION_REQUEST,
  CONFIRM_OFF_PLATFORM_PAYMENT_INTENT_REQUEST,
  CONFIRM_PAYMENT_INTENT_REQUEST,
  EDIT_OFF_PLATFORM_REQUESTED_DOCUMENTS,
  GET_COMPANY_VERIFICATION_STATUS_REQUEST,
  GET_COMPANY_VERIFICATION_STATUS_RESPONSE,
  GET_EXGATES_BANK_INFO_REQUEST,
  GET_EXGATES_BANK_INFO_RESPONSE,
  GET_STRIPE_CLIENT_SECRET_REQUEST,
  GET_STRIPE_CLIENT_SECRET_RESPONSE,
  GET_VERIFICATION_METHODS_REQUEST,
  GET_VERIFICATION_METHODS_RESPONSE,
  REQUEST_OFF_PLATFORM_REQUEST_DATA_REQUEST,
  REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE,
  REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_REQUEST,
  REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE,
  REQUEST_OFF_PLATFORM_VERIFICATION,
  SEND_BANK_TRANSFER_REQUEST,
  SET_BANK_TRANSFER_SUCCESS,
  SET_PAYMENT_FAILURE_ERR_MSG,
  SET_PAYMENT_INTENT_ID,
  SET_SELECTED_VERIFICATION_METHOD,
  SET_VERIFICATION_STEP,
  UPLOAD_OFF_PLATFORM_REQUESTED_DOCUMENTS
} from "./types";

export const setVerificationStep = (payload) => ({
  type: SET_VERIFICATION_STEP,
  payload
});

export const setSelectedVerificationMethod = (payload) => ({
  type: SET_SELECTED_VERIFICATION_METHOD,
  payload
});

export const getVerificationMethodsRequest = () => ({
  type: GET_VERIFICATION_METHODS_REQUEST
});

export const getVerificationMethodsResponse = (payload) => ({
  type: GET_VERIFICATION_METHODS_RESPONSE,
  payload
});

export const getStripeClientSecretRequest = (payload) => ({
  type: GET_STRIPE_CLIENT_SECRET_REQUEST,
  payload
});

export const getStripeClientSecretResponse = (payload) => ({
  type: GET_STRIPE_CLIENT_SECRET_RESPONSE,
  payload
});

export const setPaymentFailureErrMsg = (payload) => ({
  type: SET_PAYMENT_FAILURE_ERR_MSG,
  payload
});

export const setPaymentIntentId = (payload) => ({
  type: SET_PAYMENT_INTENT_ID,
  payload
});

export const confirmPaymentIntentRequest = (payload) => ({
  type: CONFIRM_PAYMENT_INTENT_REQUEST,
  payload
});

export const getExgatesBankInfoRequest = () => ({
  type: GET_EXGATES_BANK_INFO_REQUEST
});

export const getExgatesBankInfoResponse = (payload) => ({
  type: GET_EXGATES_BANK_INFO_RESPONSE,
  payload
});

export const sendBankTransferRequest = (payload) => ({
  type: SEND_BANK_TRANSFER_REQUEST,
  payload
});

export const setBankTransferSuccess = (payload) => ({
  type: SET_BANK_TRANSFER_SUCCESS,
  payload
});

export const getCompanyVerificationStatusRequest = () => ({
  type: GET_COMPANY_VERIFICATION_STATUS_REQUEST
});

export const getCompanyVerificationStatusResponse = (payload) => ({
  type: GET_COMPANY_VERIFICATION_STATUS_RESPONSE,
  payload
});

export const requestOffPlatformVerificaiton = () => ({
  type: REQUEST_OFF_PLATFORM_VERIFICATION
});

export const requestOffplatformRequiredDocsRequest = () => ({
  type: REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_REQUEST
});

export const requestOffplatformRequiredDocsResponse = (payload) => ({
  type: REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE,
  payload
});

export const uploadOffPlatformRequestedDocuments = (payload) => ({
  type: UPLOAD_OFF_PLATFORM_REQUESTED_DOCUMENTS,
  payload
});

export const editOffPlatformRequestedDocuments = (payload) => ({
  type: EDIT_OFF_PLATFORM_REQUESTED_DOCUMENTS,
  payload
});

export const requestOffplatformRequestDataRequest = () => ({
  type: REQUEST_OFF_PLATFORM_REQUEST_DATA_REQUEST
});

export const requestOffplatformRequestDataResponse = (payload) => ({
  type: REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE,
  payload
});

export const confirmOffPlatformPaymentIntentRequest = (payload) => ({
  type: CONFIRM_OFF_PLATFORM_PAYMENT_INTENT_REQUEST,
  payload
});

export const cancelVerificationRequest = () => ({
  type: CANCEL_VERIFICATION_REQUEST
});
