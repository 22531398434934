export const SUB_CATEGORIES_LIST_REQUEST =
  "SUB_CATEGORIES_LIST_REQUEST";
export const SUB_CATEGORIES_LIST_RESPONSE =
  "SUB_CATEGORIES_LIST_RESPONSE";

export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_RESPONSE = "CATEGORIES_LIST_RESPONSE";

export const MOQ_LIST_REQUEST = "MOQ_LIST_REQUEST";
export const MOQ_LIST_RESPONSE = "MOQ_LIST_RESPONSE";

export const SPECIFICATIONS_LIST_REQUEST =
  "SPECIFICATIONS_LIST_REQUEST";
export const SPECIFICATIONS_LIST_RESPONSE =
  "SPECIFICATIONS_LIST_RESPONSE";

export const COUNTRIES_LIST_REQUEST = "COUNTRIES_LIST_REQUEST";
export const COUNTRIES_LIST_RESPONSE = "COUNTRIES_LIST_RESPONSE";

export const CURRENCIES_LIST_REQUEST = "CURRENCIES_LIST_REQUEST";
export const CURRENCIES_LIST_RESPONSE = "CURRENCIES_LIST_RESPONSE";

export const PRODUCT_REQUEST_STATUS_REQUEST =
  "PRODUCT_REQUEST_STATUS_REQUEST";
export const PRODUCT_REQUEST_STATUS_RESPONSE =
  "PRODUCT_REQUEST_STATUS_RESPONSE";
