import PropTypes from "prop-types";

export const ProductsIcon = ({ active, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...props}>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M2.642 6.486L10 10.744l7.308-4.233M10 18.294v-7.558"></path>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M8.275 2.353l-4.45 2.475c-1.008.558-1.833 1.958-1.833 3.108v4.708c0 1.15.825 2.55 1.833 3.109l4.45 2.475c.95.525 2.508.525 3.458 0l4.45-2.475c1.009-.559 1.834-1.959 1.834-3.109V7.936c0-1.15-.825-2.55-1.834-3.108l-4.45-2.475c-.958-.534-2.508-.534-3.458 0z"></path>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M14.167 11.32V8.27L6.258 3.702"></path>
    </svg>
  );
};

ProductsIcon.propTypes = {
  active: PropTypes.bool
};
