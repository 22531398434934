import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Verified } from "@mui/icons-material";

import emptyProductPlaceholder from "../../../assets/images/empty_data.png";
import messages from "../../../assets/locale/messages";
import TruncateText from "../../../components/TruncateText";
import { LocationIcon, MaintenanceIcon } from "../../../utils/Icons";
import "./ExporterCardList.scss";

const ExporterCardList = ({
  src,
  label,
  country,
  description,
  link,
  isVerified
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const onImageError = (e) => {
    e.target.src = emptyProductPlaceholder;
  };

  const renderCardContent = () => (
    <div
      className={`exporter-card-list border-radius-10 p-3 grey-bg ${link && isVerified && "pointer box-shadow-hover"} ${!isVerified && "disabled"}`}>
      <div className="info-section d-flex align-items-center">
        <div
          className="card-img-container me-3 white-bg border-radius-8 d-flex align-items-center justify-content-center overflow-hidden"
          style={{
            width: "11rem",
            height: "8rem"
          }}>
          <img
            src={src || emptyProductPlaceholder}
            onError={onImageError}
            className="w-100 item-cards-img"
          />
        </div>
        <div className="info-container w-100">
          <div className="card-label mb-2 d-flex align-items-center justify-content-start">
            <TruncateText
              text={label}
              linesNo={1}
              textClassName={"mb-0 title-color fsize-16 fweight-700"}
            />
            {isVerified && (
              <Verified color="primary" className="ms-2" />
            )}
          </div>
          <div className="country d-flex mb-2">
            <LocationIcon />
            <span className="text-grey fsize-12 mx-1">{country}</span>
          </div>
          <div className="mb-2 w-100">
            <TruncateText
              showTooltip={false}
              text={description}
              linesNo={2}
              textClassName={"text-grey fsize-12 mb-0"}
            />
          </div>
        </div>
      </div>
      <div className="disabled-hovering-layout w-100 h-100 border-radius-10">
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <div className="text-center ">
            <MaintenanceIcon className="mb-1" />
            <p className="text-white mb-0 fsize-15 fweight-600">
              {shared.exporterStillUnverified}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {link && isVerified ? (
        <Link to={link}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};

export default ExporterCardList;

ExporterCardList.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  isVerified: PropTypes.bool
};
