import { axiosInstanceExporters } from ".";

const getNotificationsList = async ({ params }) =>
  await axiosInstanceExporters.get(
    `/api/Notification/GetNotifications`,
    { params }
  );

const setNotificationSeen = async (data) =>
  await axiosInstanceExporters.put(
    `/api/Notification/MarkNotificationReaded`,
    data
  );

const setAllNotificationsSeen = async () =>
  await axiosInstanceExporters.get(
    `/api/Notification/MarkAllNotificationReaded`
  );

const getUnseenNotificationsCount = async ({ params }) =>
  await axiosInstanceExporters.get(
    `/api/Notification/GetUnreadNotificationsCount`,
    { params }
  );

export {
  getNotificationsList,
  setNotificationSeen,
  setAllNotificationsSeen,
  getUnseenNotificationsCount
};
