export const ShieldIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.045 2.73L4.886 4.299c-.958.358-1.741 1.491-1.741 2.508v6.192c0 .983.65 2.275 1.441 2.866L8.17 18.54c1.175.883 3.108.883 4.283 0l3.583-2.675c.792-.591 1.442-1.883 1.442-2.867V6.807c0-1.025-.784-2.158-1.742-2.517l-4.158-1.558c-.708-.258-1.842-.258-2.533 0z"></path>
  </svg>
);
