export const GET_PRODUCTS_LIST_REQUEST = "GET_PRODUCTS_LIST_REQUEST";
export const GET_PRODUCTS_LIST_RESPONSE =
  "GET_PRODUCTS_LIST_RESPONSE";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";

export const GET_PRODUCT_DETAILS_REQUEST =
  "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_RESPONSE =
  "GET_PRODUCT_DETAILS_RESPONSE";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const PUBLISH_PRODUCT_REQUEST = "PUBLISH_PRODUCT_REQUEST";
export const UNPUBLISH_PRODUCT_REQUEST = "UNPUBLISH_PRODUCT_REQUEST";
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const REVERT_EDIT_REQUEST = "REVERT_EDIT_REQUEST";

export const SHOW_ADD_SUCCESS_MODAL = "SHOW_ADD_SUCCESS_MODAL";

export const GET_PRODUCT_VERSION_DETAILS_REQUEST =
  "GET_PRODUCT_VERSION_DETAILS_REQUEST";
export const GET_PRODUCT_VERSION_DETAILS_RESPONSE =
  "GET_PRODUCT_VERSION_DETAILS_RESPONSE";

export const SEND_ADD_PRODUCT_SUPPORT_EMAIL_REQUEST =
  "SEND_ADD_PRODUCT_SUPPORT_EMAIL_REQUEST";
export const SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE =
  "SEND_ADD_PRODUCT_SUPPORT_EMAIL_RESPONSE";

export const ADD_PRODUCT_VOICE_DESCRIPTION =
  "ADD_PRODUCT_VOICE_DESCRIPTION";

export const GET_TRANSCRIBED_AUDIO_ID_REQUEST =
  "GET_TRANSCRIBED_AUDIO_ID_REQUEST";
export const GET_TRANSCRIBED_AUDIO_ID_RESPONSE =
  "GET_TRANSCRIBED_AUDIO_ID_RESPONSE";

export const GET_TRANSCRIBED_AUDIO_TEXT_REQUEST =
  "GET_TRANSCRIBED_AUDIO_TEXT_REQUEST";
export const GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE =
  "GET_TRANSCRIBED_AUDIO_TEXT_RESPONSE";
export const AUDIO_TRANSCRIBED = "AUDIO_TRANSCRIBED";
