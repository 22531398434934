import {
  put,
  call,
  takeEvery,
  takeLatest,
  delay
} from "redux-saga/effects";
import * as apis from "../../network/apis/products";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import store from "..";
import { PRODUCT_STATUS_WITH_ID } from "../../utils/Constants";
import {
  showHideLoader,
  showHideLoaderText
} from "../Loader/actions";

function* getProductsListSaga({ payload }) {
  try {
    const response = yield call(apis.getProductsList, payload);
    const responseData = response.data.Data;
    yield put(
      actions.getProductsListResponse({
        list: responseData.Items,
        meta: {
          currentPage: +responseData.CurrentPage,
          count: +responseData.ItemCount,
          pages: +responseData.TotalPages
        }
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* addProductSaga({ payload }) {
  try {
    yield call(apis.addProduct, payload);
    store.dispatch(actions.showAddSuccessModal(true));
    store.dispatch(showHideLoader(false));
    store.dispatch(
      showHideLoaderText({
        loadingText: "",
        showText: false
      })
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getProductDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getProductDetails, payload);
    yield put(actions.getProductDetailsResponse(response.data.Data));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getProductVersionDetailsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getProductVersionDetails,
      payload
    );
    yield put(
      actions.getProductVersionDetailsResponse(response.data.Data)
    );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* editProductSaga({ payload }) {
  try {
    yield call(apis.editProduct, payload);
    yield showSuccessMsg("editProductSuccess");
    store.dispatch(showHideLoader(false));
    store.dispatch(
      showHideLoaderText({
        loadingText: "",
        showText: false
      })
    );
    yield payload.navigate(ROUTE_PATHS.products);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* publishProductSaga({ payload }) {
  try {
    yield call(apis.publishProduct, payload);
    payload.src === ROUTE_PATHS.viewProduct
      ? yield put(actions.getProductDetailsRequest(payload.data)) &&
          put(actions.getProductVersionDetailsRequest(payload.data))
      : yield put(actions.getProductsListRequest(payload));

    switch (payload.status) {
      case PRODUCT_STATUS_WITH_ID.draft.ProductStatusName: {
        yield showSuccessMsg("publishPendingProductSuccess");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.unpublished.ProductStatusName: {
        yield showSuccessMsg("publishProductSuccess");
        break;
      }
      case PRODUCT_STATUS_WITH_ID.edited.ProductStatusName: {
        yield showSuccessMsg("publishEditedProductSuccess");
        break;
      }
      default:
        yield showSuccessMsg("publishProductSuccess");
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* unPublishProductSaga({ payload }) {
  try {
    yield call(apis.unpublishProduct, payload);
    payload.src === ROUTE_PATHS.viewProduct
      ? yield put(
          actions.getProductVersionDetailsRequest(payload.data)
        )
      : yield put(actions.getProductsListRequest(payload));
    yield showSuccessMsg("unpublishProductSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}
function* revertEditSaga({ payload }) {
  try {
    yield call(apis.revertEdit, payload);
    yield put(
      actions.getProductVersionDetailsRequest(payload.params)
    );
    yield showSuccessMsg("revertEditSuccess");
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* deleteProductSaga({ payload }) {
  try {
    yield call(apis.deleteProduct, payload);
    yield showSuccessMsg("deleteProductSuccess");
    yield payload.navigate(ROUTE_PATHS.products);
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* sendAddProductSupportEmailSaga() {
  try {
    const response = yield call(apis.sendAddProductSupportEmail);
    yield put(actions.sendAddProductSupportEmailResponse(true));
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getTranscribedAudioIdSaga({ payload }) {
  try {
    const response = yield call(apis.getTranscribedAudioId, payload);
    yield put(
      actions.getTranscribedAudioIdResponse(response.data.id)
    );
    if (response.data.id)
      yield put(
        actions.getTranscribedAudioTextRequest({
          id: response.data.id
        })
      );
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

function* getTranscribedAudioTextSaga({ payload }) {
  try {
    const response = yield call(
      apis.getTranscribedAudioText,
      payload
    );
    if (response.data.status === "complete") {
      yield put(
        actions.getTranscribedAudioTextResponse(response.data.text)
      );
    } else {
      let retries = 0;
      const maxRetries = 10;
      const delayTime = 5000;
      while (retries < maxRetries) {
        try {
          const repeatAttemptResponse = yield call(
            apis.getTranscribedAudioText,
            payload
          );
          if (repeatAttemptResponse.data.status === "completed") {
            yield put(
              actions.getTranscribedAudioTextResponse(
                repeatAttemptResponse.data.text
              )
            );
            yield put(actions.isAudioTranscribedRequest(true));
            retries = maxRetries;
            return repeatAttemptResponse.data.text;
          } else if (repeatAttemptResponse.data.status === "failed") {
            return null;
          }
          yield delay(delayTime);
          retries++;
        } catch (error) {
          console.error("Error fetching transcript:", error);
          return null;
        }
      }
    }
  } catch (error) {
    yield error?.response?.data?.Message &&
      showErrorMsg(error.response.data?.Message);
  }
}

export default function* ProductsSagas() {
  yield takeLatest(
    types.GET_PRODUCTS_LIST_REQUEST,
    getProductsListSaga
  );
  yield takeEvery(types.ADD_PRODUCT_REQUEST, addProductSaga);
  yield takeEvery(
    types.GET_PRODUCT_DETAILS_REQUEST,
    getProductDetailsSaga
  );
  yield takeEvery(
    types.GET_PRODUCT_VERSION_DETAILS_REQUEST,
    getProductVersionDetailsSaga
  );
  yield takeEvery(types.EDIT_PRODUCT_REQUEST, editProductSaga);
  yield takeEvery(types.PUBLISH_PRODUCT_REQUEST, publishProductSaga);
  yield takeEvery(
    types.UNPUBLISH_PRODUCT_REQUEST,
    unPublishProductSaga
  );
  yield takeEvery(types.DELETE_PRODUCT_REQUEST, deleteProductSaga);
  yield takeEvery(types.REVERT_EDIT_REQUEST, revertEditSaga);
  yield takeEvery(
    types.SEND_ADD_PRODUCT_SUPPORT_EMAIL_REQUEST,
    sendAddProductSupportEmailSaga
  );
  yield takeEvery(
    types.GET_TRANSCRIBED_AUDIO_ID_REQUEST,
    getTranscribedAudioIdSaga
  );
  yield takeEvery(
    types.GET_TRANSCRIBED_AUDIO_TEXT_REQUEST,
    getTranscribedAudioTextSaga
  );
}
