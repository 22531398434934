import store from "../store";

export const PERMISSIONS_IDS = {
  business_verification_management: 1,
  subscription_management: 2,
  company_profile_management: 3,
  products_management: 4,
  sub_accounts_management: 5,
  profile_management: 6,
  settings_management: 7,
  currency_management: 8,
  products_requests_notifications: 9,
  change_password: 10,
  delete_deactivate_account: 11
};

export const USER_ROLES_IDS = {
  systemAdmin: 1,
  manager: 2,
  agent: 3
};

export const getUserPermissions = (userRole) => {
  let permissions = [];
  switch (userRole) {
    case USER_ROLES_IDS.systemAdmin:
      permissions = Object.keys(PERMISSIONS_IDS).map(
        (permission) => PERMISSIONS_IDS[permission]
      );
      break;
    case USER_ROLES_IDS.manager:
      permissions = [
        PERMISSIONS_IDS.company_profile_management,
        PERMISSIONS_IDS.products_management,
        PERMISSIONS_IDS.profile_management,
        PERMISSIONS_IDS.settings_management,
        PERMISSIONS_IDS.change_password
      ];
      break;
    case USER_ROLES_IDS.agent:
      permissions = [
        PERMISSIONS_IDS.products_management,
        PERMISSIONS_IDS.profile_management,
        PERMISSIONS_IDS.settings_management,
        PERMISSIONS_IDS.change_password
      ];
      break;
    default:
      permissions = Object.keys(PERMISSIONS_IDS).map(
        (permission) => PERMISSIONS_IDS[permission]
      );
  }
  return permissions;
};

export const hasPermission = (permissionId) => {
  const { Permissions } = store.getState().auth.login;
  return Permissions?.includes(permissionId);
};
