import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import Button from "../../../components/Button";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import TruncateText from "../../../components/TruncateText";
import noProductRequestsPlaceholder from "../../../assets/images/vectors/placeholders/noProductRequestsPlaceholder.svg";
import EmptyState from "../../../components/EmptyState";
import SearchInput from "../../../components/SearchInput";
import { formatDate } from "../../../utils/Helpers";
import {
  CHIPS_CLASSES,
  PRODUCT_REQUEST_STATUS_ID
} from "../../../utils/Constants";
import Table from "../../../components/Table";
import { importerProductRequestsListRequest } from "../../../store/ProductRequests/actions";
import Pagination from "../../../components/Pagination";
import Tabs from "../../../components/Tabs";
import { showHideLoader } from "../../../store/Loader/actions";

const ProductRequests = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.showLoader);

  const importerProductRequestsList = useSelector(
    (state) => state.productRequests.importerProductRequestsList.list
  );
  const productRequestsListMeta = useSelector(
    (state) => state.productRequests.importerProductRequestsList.meta
  );

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [paramsObject, setParamsObject] = useState({
    statusId: []
  });
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    dispatch(showHideLoader(true));
    setSearchValue("");
    setPage(1);
    setParamsObject({
      statusId: tabValue === 0 ? null : tabValue
    });
  }, [tabValue]);

  const requestProductRequestList = () => {
    dispatch(
      importerProductRequestsListRequest({
        params: {
          page,
          search_value: searchValue,
          ...paramsObject
        }
      })
    );
  };

  useEffect(() => {
    requestProductRequestList();
  }, [page, searchValue]);

  useEffect(() => {
    page > 1 ? setPage(1) : requestProductRequestList();
  }, [paramsObject]);

  const {
    productRequests: {
      importerProductRequests: { list }
    },
    shared
  } = messages[lang];

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 426) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderProductRequestsTableRow = ({
    Id,
    Title,
    QuantityFrom,
    QuantityTo,
    QuantityUnit,
    SubmissionDate,
    StatusId,
    StatusName
  }) => (
    <TableRow
      key={`request-row-${Id}`}
      className="pointer"
      onClick={() => {
        navigate(
          ROUTE_PATHS.viewImporterProductRequest.replace(":id", Id)
        );
      }}>
      <TableCell className="w-30">
        <TruncateText text={Title || "__"} />
      </TableCell>
      <TableCell className="w-30">
        {`${QuantityFrom}-${QuantityTo} ${QuantityUnit}` || "__"}
      </TableCell>
      <TableCell className="w-25">
        {SubmissionDate ? formatDate({ date: SubmissionDate }) : "__"}
      </TableCell>
      <TableCell className="w-25">
        <Chip
          label={StatusName}
          className={`${StatusId === PRODUCT_REQUEST_STATUS_ID.published ? CHIPS_CLASSES.green : CHIPS_CLASSES.red}`}
          sx={{ width: "110px" }}
        />
      </TableCell>
    </TableRow>
  );

  const renderTabContent = () => (
    <div>
      <div className="table-header d-flex px-4 align-items-center my-4">
        <SearchInput
          name="product-requests-search"
          id="product-requests-search"
          value={searchValue}
          inputClass="border-radius-8"
          inputWrapperClass="search-input-wrapper-width"
          placeholder={list.searchPlaceholder}
          onChange={(value) => {
            setPage(1);
            setSearchValue(value);
          }}
        />
      </div>
      <div className="table-content">
        <div>
          {importerProductRequestsList?.length ? (
            <Table
              className={"px-0 nowrap-table hover-table-row"}
              headlines={[
                list.tableHeader.requestTitle,
                list.tableHeader.requestQuantity,
                list.tableHeader.submissionDate,
                shared.lists.status
              ]}
              rows={
                !isLoading &&
                importerProductRequestsList &&
                importerProductRequestsList.map((request) =>
                  renderProductRequestsTableRow(request)
                )
              }
            />
          ) : (
            <div className="border-top">
              {!isLoading && (
                <EmptyState
                  subTitle={
                    !!searchValue &&
                    !importerProductRequestsList?.length
                      ? list.noSearchResults
                      : list.notProductRequestFound
                  }
                  showActionButton={
                    !searchValue &&
                    !importerProductRequestsList?.length
                  }
                  buttonLabel={
                    <span className="d-flex align-items-center">
                      <Add fontSize="medium" />
                      <span className="ps-1 ">
                        {list.addMoreButton}
                      </span>
                    </span>
                  }
                  onButtonClick={() => {
                    navigate(ROUTE_PATHS.addImporterProductRequests);
                  }}
                  buttonClassName="empty-state-create-button border-radius-8"
                  imgSrc={noProductRequestsPlaceholder}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="Pagination-container d-flex justify-content-center py-3 mt-2">
        {!isLoading && importerProductRequestsList?.length > 0 && (
          <Pagination
            count={productRequestsListMeta?.pages}
            page={page || productRequestsListMeta.currentPage}
            handleChange={(page) => {
              setPage(page);
            }}
            defaultPage={1}
          />
        )}
      </div>
    </div>
  );

  const tabsArray = [
    {
      label: list.tabs.all,
      content: renderTabContent(),
      isCountShow: tabValue === 0,
      isCountShowValue: isLoading
        ? null
        : productRequestsListMeta?.count || "0"
    },
    {
      label: list.tabs.published,
      content: renderTabContent(),
      isCountShow: tabValue === 1,
      isCountShowValue: isLoading
        ? null
        : productRequestsListMeta?.count || "0"
    },
    {
      label: list.tabs.closed,
      content: renderTabContent(),
      isCountShow: tabValue === 2,
      isCountShowValue: isLoading
        ? null
        : productRequestsListMeta?.count || "0"
    }
  ];

  return (
    <div className="product-requests-container">
      <div className="page-title pb-4 d-md-flex justify-content-between align-items-center">
        <div className="title-count-container d-flex justify-content-center align-items-center">
          <div>
            <Typography variant="h5" className="title-color">
              {list.pageTitle}
            </Typography>
          </div>
        </div>
        <div className="title-buttons-container d-flex justify-content-end align-items-center gap-3 mt-md-0 mt-3">
          <Button
            labelClass="text-white"
            label={
              <span className="d-flex align-items-center">
                <Add
                  fontSize="medium"
                  style={{ width: isSmallScreen && "0.9rem" }}
                />
                <span
                  className={`ps-1 ${isSmallScreen && "fsize-10"}`}>
                  {list.addMoreButton}
                </span>
              </span>
            }
            onClick={() => {
              navigate(ROUTE_PATHS.addImporterProductRequests);
            }}
          />
        </div>
      </div>
      <div className="product-requests-table-container bg-white w-100 p-4 mb-4 bg-white border-radius-8">
        <div className="tabs-section">
          <Tabs
            tabs={tabsArray}
            value={tabValue}
            handleChange={setTabValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductRequests;
