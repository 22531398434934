import PropTypes from "prop-types";

export const GreyBriefCaseIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "24"}
    height={dimensions || "24"}
    fill="none"
    viewBox="0 0 25 25"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M8.716 22.951h8c4.02 0 4.74-1.61 4.95-3.57l.75-8c.27-2.44-.43-4.43-4.7-4.43h-10c-4.27 0-4.97 1.99-4.7 4.43l.75 8c.21 1.96.93 3.57 4.95 3.57zM8.715 6.951v-.8c0-1.77 0-3.2 3.2-3.2h1.6c3.2 0 3.2 1.43 3.2 3.2v.8"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M14.715 13.951v1.02c0 1.09-.01 1.98-2 1.98-1.98 0-2-.88-2-1.97v-1.03c0-1 0-1 1-1h2c1 0 1 0 1 1zM22.365 11.951a16.484 16.484 0 01-7.65 3.02M3.334 12.22c2.25 1.54 4.79 2.47 7.38 2.76"></path>
  </svg>
);

GreyBriefCaseIcon.propTypes = {
  dimensions: PropTypes.string
};
