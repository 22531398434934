import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../assets/locale/messages";
import TruncateText from "../TruncateText";
import {
  GreyBriefCaseIcon,
  GreyEnvelopeIcon,
  GreyGlobeIcon,
  GreyProfileIcon,
  GreyWhatsappIcon
} from "../../utils/Icons";
import ExporterContactDetailsModal from "../ExporterContactDetailsModal";

const ContactInfoCard = ({
  name,
  mobileNumber,
  emailAddress,
  website,
  companyName,
  isWebsiteCard,
  isImporterData,
  isFullWidth
}) => {
  const [truncateLength, setTruncateLength] = useState(0);
  const [contactDetailsModalOpen, setContactDetailsModalOpen] =
    useState(false);
  const isAuth = useSelector((state) => state.auth.login?.Token);

  const handleResize = () => {
    const width = window.innerWidth;

    if (width >= 1440) {
      setTruncateLength(isFullWidth ? 24 : 17);
    } else if (width >= 1024) {
      setTruncateLength(isFullWidth ? 45 : 23);
    } else if (width >= 768) {
      setTruncateLength(isFullWidth ? 23 : 15);
    } else if (width >= 425) {
      setTruncateLength(29);
    } else if (width >= 375) {
      setTruncateLength(23);
    } else {
      setTruncateLength(19);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const renderLabelValue = (label, value, icon) => (
    <div className="d-flex align-items-center text-break">
      <div>{icon}</div>
      <div className="ms-3">
        <p className="text-light-grey mb-1 fsize-12">{label}</p>
        <div className="text-color fsize-15 fweight-500 mb-0">
          <TruncateText text={value} length={truncateLength} />
        </div>
      </div>
    </div>
  );
  return (
    <div className="contact-info-section light-box-shadow border-radius-10 py-4 px-md-4 px-3">
      <div
        className={
          "mb-4 d-flex justify-content-between align-items-center"
        }>
        <h4 className="fsize-17 fweight-600 title-color mb-0">
          {shared.contactInfo}
        </h4>
        {(isWebsiteCard || !isAuth) && (
          <p
            className="mb-0 fweight-600 text-primary pointer"
            onClick={() => setContactDetailsModalOpen(true)}>
            {shared.exporterContactCard.showContactInfo}
          </p>
        )}
        {contactDetailsModalOpen && (
          <ExporterContactDetailsModal
            open={contactDetailsModalOpen}
            onClose={() => setContactDetailsModalOpen(false)}
            isImporterModal={isImporterData}
          />
        )}
      </div>
      <div className="row">
        <div className="col-xl-6 col-md-12 mb-4">
          {renderLabelValue(
            isImporterData
              ? shared.importerName
              : shared.exporterName,
            !isWebsiteCard ? name || "__" : "*************",
            <GreyProfileIcon dimensions={"25"} />
          )}
        </div>
        <div className=" col-xl-6 col-md-12 mb-4">
          {renderLabelValue(
            shared.mobileNumber,
            !isWebsiteCard
              ? mobileNumber && mobileNumber.length >= 6
                ? mobileNumber
                : "--"
              : "*************",
            <GreyWhatsappIcon dimensions="25" />
          )}
        </div>
        <div className="col-xl-6 col-md-12 mb-4">
          {renderLabelValue(
            shared.emailAddress,
            !isWebsiteCard ? emailAddress || "--" : "*************",
            <GreyEnvelopeIcon dimensions="25" />
          )}
        </div>
        <div className="col-xl-6 col-md-12 mb-4">
          {isImporterData
            ? renderLabelValue(
                shared.companyName,
                !isWebsiteCard
                  ? companyName || "--"
                  : "*************",
                <GreyBriefCaseIcon />
              )
            : renderLabelValue(
                shared.website,
                !isWebsiteCard ? website || "--" : "*************",
                <GreyGlobeIcon />
              )}
        </div>
      </div>
    </div>
  );
};

export default ContactInfoCard;

ContactInfoCard.propTypes = {
  name: PropTypes.string,
  mobileNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  website: PropTypes.string,
  isWebsiteCard: PropTypes.bool,
  isImporterData: PropTypes.bool,
  companyName: PropTypes.string,
  isFullWidth: PropTypes.bool
};

ContactInfoCard.defaultProps = {
  isWebsiteCard: false,
  isImporterData: false,
  isFullWidth: false
};
