import PropTypes from "prop-types";

export const ProductRequestsIcon = ({ active, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      {...props}>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M17.926 6.323v8.333c0 2.5-1.25 4.167-4.167 4.167H7.092c-2.916 0-4.166-1.667-4.166-4.167V6.323c0-2.5 1.25-4.167 4.166-4.167h6.667c2.917 0 4.167 1.667 4.167 4.167z"></path>
      <path
        stroke={active ? "#0071BC" : "#455A64"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12.51 4.242V5.91c0 .917.75 1.667 1.666 1.667h1.667M7.092 11.328h3.333M7.092 14.656h6.667"></path>
    </svg>
  );
};

ProductRequestsIcon.propTypes = {
  active: PropTypes.bool
};
