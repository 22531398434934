import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import messages from "../../../../assets/locale/messages";
import envelopeImg from "../../../../assets/images/vectors/envelope.png";
import Counter from "../../../../components/Counter";
import {
  checkCanUserResendVerifyEmailRequest,
  resendVerifyEmailRequest,
  setDisableResendEmail
} from "../../../../store/Auth/actions";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const { registeration } = messages[lang];

  const disableResendEmail = useSelector(
    (state) => state.auth.registeration.disableResendEmail
  );

  const [count, setCount] = useState(0);
  const [restartCount, setRestartCount] = useState(false);

  const userEmail = sessionStorage.getItem("resend_email");

  useEffect(() => {
    return () => {
      dispatch(setDisableResendEmail(false));
    };
  }, []);

  useEffect(() => {
    if (userEmail) {
      dispatch(
        checkCanUserResendVerifyEmailRequest({
          data: { email: userEmail },
          navigate
        })
      );
    }
  }, [userEmail]);

  return (
    <div className="verify-email-page">
      <div className="text-center">
        <div className="vector-section mb-3">
          <img src={envelopeImg} />
        </div>
        <div className="content-section">
          <h3 className="fsize-24 fweight-700 title-color mb-4">
            {registeration.verifyEmail.verifyYourEmail}
          </h3>
          <p className="text-light-grey fsize-16 mb-4">
            {registeration.verifyEmail.message1}
          </p>
          <p className="text-light-grey fsize-16 mb-4">
            {registeration.verifyEmail.message2}
            <span className="fweight-600">{` ${userEmail} `}</span>
            {registeration.verifyEmail.message3}
          </p>
          <div className="counter">
            <Counter
              initialCount={60}
              onChangeCount={(newCount) => setCount(newCount)}
              restartCount={restartCount}
              disabled={disableResendEmail}
            />
          </div>
          <p className="text-light-grey">
            <span>{registeration.verifyEmail.notGetEmail}</span>
            <span
              className={`fweight-600 text-underline ${!count && !disableResendEmail && "pointer text-primary"}`}
              onClick={() => {
                if (!count) {
                  dispatch(
                    resendVerifyEmailRequest({
                      params: { email: userEmail }
                    })
                  );
                  setRestartCount(true);
                  setTimeout(() => {
                    setRestartCount(false);
                  }, 2000);
                }
              }}>
              {registeration.verifyEmail.resend}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
