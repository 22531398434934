import PropTypes from "prop-types";
import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    {value === index && <Box>{children}</Box>}
  </div>
);

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  textTransform: "none"
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`
});

const Tabs = ({ value, handleChange, tabs, className }) => {
  return (
    <div className={`tabs-container ${className}`}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={value}
          onChange={(_, newValue) => handleChange(newValue)}
          aria-label="basic tabs example"
          variant="scrollable">
          {tabs?.map(
            (
              {
                label,
                disabled,
                isCountShow = false,
                isCountShowValue
              },
              i
            ) => (
              <CustomTab
                key={`tab-header-${i}`}
                label={
                  <span className="fweight-600 gap-3">
                    {isCountShow ? (
                      <div className="d-flex justify-content-center align-items-center text-center">
                        {label}
                        <div
                          className={`records-count border-radius-50 d-flex justify-content-center align-items-center ms-2 ${value === i ? "faint-blue-bg" : "faint-grey-bg"}`}
                          style={{ width: "28px", height: "28px" }}>
                          {isCountShowValue || 0}
                        </div>
                      </div>
                    ) : (
                      label
                    )}
                  </span>
                }
                disabled={disabled}
                className="ps-0 me-5"
                {...a11yProps(i)}
              />
            )
          )}
        </MuiTabs>
      </Box>
      {tabs?.map(({ content }, i) => (
        <CustomTabPanel
          key={`tab-content-${i}`}
          value={value}
          index={i}>
          {content}
        </CustomTabPanel>
      ))}
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  className: PropTypes.string
};
