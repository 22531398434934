import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { DeleteModalIcon } from "../../../../utils/Icons";
import { deleteProductRequest } from "../../../../store/Products/actions";

const DeleteProduct = ({ open, closeModal, productInfo }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    products: {
      deleteProduct: {
        content,
        confirmationContent,
        subConfirmationContent,
        showcaseProductConfirmationContent
      }
    },
    shared
  } = messages[lang];

  const handleConfirmDelete = () => {
    dispatch(
      deleteProductRequest({
        data: {
          Id: productInfo.id
        },
        navigate
      })
    );
    closeModal();
  };

  const deleteProductContent = () => {
    return (
      <div className="delete-product-container container d-flex flex-column justify-content-center align-items-center">
        <DeleteModalIcon />
        <div className="delete-product-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content} "${productInfo.name}"`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {productInfo.isShowcaseProduct
              ? showcaseProductConfirmationContent
              : confirmationContent}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {subConfirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleConfirm={() => {
        handleConfirmDelete();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={deleteProductContent()}
      hasActions={true}
      confirmBtnTxt={shared.delete}
      cancelBtnTxt={shared.cancel}
      handleCancel={closeModal}
      showCloseIcon
      title
      confirmBtnClass="bg-danger border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 ps-5 pe-5"
      maxWidth={"xs"}
    />
  );
};

export default DeleteProduct;

DeleteProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired
};
