import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import ListFilter from "../../../../components/Filters/ListFilter";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import NumberRangeFilter from "../../../../components/Filters/NumberRangeFilter";
import SortList from "../../../../components/Filters/SortList";
import DisplayStyleButton from "../../../../components/DisplayStyleButton";
import ProductCardGrid from "../../../../components/ProductCards/ProductCardGrid";
import ProductCardList from "../../../../components/ProductCards/ProductCardList";
import Button from "../../../../components/Button";
import Filters from "../../../../components/Filters";
import EmptyState from "../../../../components/EmptyState";
import messages from "../../../../assets/locale/messages";
import noProductsImage from "../../../../assets/images/vectors/placeholders/noProductsPlacheolder.svg";
import noImgPlaceholder from "../../../../assets/images/empty_data.png";
import { checkBackgroundImageValidity } from "../../../../utils/Helpers";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  DISPLAY_STYLE,
  generalImporterPagesPadding,
  SORT_PRODUCTS
} from "../../../../utils/Constants";
import {
  getAllSubCategoriesProductsListRequest,
  getAllSubCategoriesProductsListResponse,
  getSubCategoriesProductsListRequest,
  getSubCategoriesProductsListResponse
} from "../../../../store/SubCategories/actions";
import { getCountriesListRequest } from "../../../../store/Lookups/actions";
import "./SubCategoryDetails.scss";

const SubCategoryDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const isLoading = useSelector((state) => state.loader.showLoader);
  const subCategoriesProductsList = useSelector(
    (state) => state.subCategoriesProducts.subCategoriesProductsList
  );
  const subCategoriesAllProductsList = useSelector(
    (state) =>
      state.subCategoriesProducts.subCategoriesAllProductsList
  );
  const subCategoriesAllProductsMeta = useSelector(
    (state) =>
      state.subCategoriesProducts.subCategoriesAllProductsMeta
  );
  const { countriesList } = useSelector((state) => state.lookups);
  const lang = useSelector((state) => state.locale.lang);
  const { subCategories, shared } = messages[lang];
  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  const [sortingRange, setSortingRange] = useState(SORT_PRODUCTS[0]);
  const [countryFilterValue, setCountryFilterValue] = useState([]);
  const [displayStyle, setDisplayStyle] = useState(
    DISPLAY_STYLE.grid
  );
  const [priceFilterValue, setPriceFilterValue] = useState({
    from: null,
    to: null
  });
  const [priceFilterError, setPriceFilterError] = useState();
  const [moqFilterValue, setMoqFilterValue] = useState({
    from: null,
    to: null
  });
  const [moqFilterError, setMoqFilterError] = useState();
  const [clearFilters, setClearFilters] = useState(false);
  const [isApplyFilters, setIsApplyFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [isSubCategoryImgValid, setIsSubCategoryImgValid] =
    useState(true);

  useEffect(() => {
    dispatch(getCountriesListRequest());
  }, []);

  useEffect(() => {
    if (location?.state?.showcaseCoverImg) {
      checkBackgroundImageValidity(
        location?.state?.showcaseCoverImg,
        (isLoaded) => {
          setIsSubCategoryImgValid(isLoaded);
        }
      );
    } else {
      if (subCategoriesProductsList?.DefaultImage) {
        checkBackgroundImageValidity(
          subCategoriesProductsList?.DefaultImage,
          (isLoaded) => {
            setIsSubCategoryImgValid(isLoaded);
          }
        );
      }
    }
  }, [
    subCategoriesProductsList?.DefaultImage,
    location?.state?.showcaseCoverImg
  ]);

  const handleShowMore = () => {
    setPage(page + 1);
  };

  const requestSubCategoriesProducts = (isClear) => {
    dispatch(
      getAllSubCategoriesProductsListRequest({
        params: {
          userId: location?.state?.exporterId ?? "",
          id,
          page,
          currencyId: websiteCurrency?.Id,
          sort_id: sortingRange.id,
          country_ids: isClear
            ? null
            : countryFilterValue?.length
              ? countryFilterValue
              : null,
          Price_range_from: isClear
            ? null
            : priceFilterValue?.from
              ? priceFilterValue.from
              : null,
          Price_range_to: isClear
            ? null
            : priceFilterValue?.to
              ? priceFilterValue.to
              : null,
          Moq_from: isClear
            ? null
            : moqFilterValue?.from
              ? moqFilterValue?.from
              : null,
          Moq_to: isClear
            ? null
            : moqFilterValue?.to
              ? moqFilterValue?.to
              : null
        }
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getSubCategoriesProductsListRequest({
          params: {
            id,
            currencyId: websiteCurrency?.Id
          }
        })
      );
      setClearFilters(true);
      setSortingRange(SORT_PRODUCTS[0]);
      setPage(1);
      requestSubCategoriesProducts();
    }
  }, [id, websiteCurrency]);

  useEffect(() => {
    requestSubCategoriesProducts();
  }, [page, websiteCurrency, location?.state?.exporterId]);

  useEffect(() => {
    return () => {
      dispatch(
        getAllSubCategoriesProductsListResponse({
          list: [],
          meta: {}
        })
      );
      dispatch(
        getSubCategoriesProductsListResponse({
          list: [],
          meta: {}
        })
      );
    };
  }, []);

  useEffect(() => {
    if (isApplyFilters) {
      page > 1 ? setPage(1) : requestSubCategoriesProducts();
      setIsApplyFilters(false);
    }
  }, [isApplyFilters]);

  useEffect(() => {
    page > 1 ? setPage(1) : requestSubCategoriesProducts();
  }, [sortingRange]);

  useEffect(() => {
    if (clearFilters) {
      setCountryFilterValue([]);
      setPriceFilterValue({
        from: null,
        to: null
      });
      setMoqFilterValue({
        from: null,
        to: null
      });
      page > 1 ? setPage(1) : requestSubCategoriesProducts(true);
      setTimeout(() => {
        setClearFilters(false);
      }, [500]);
    }
  }, [clearFilters]);

  const breadcrumbs = [
    { label: subCategories.breadCrumbs.home, link: ROUTE_PATHS.home },
    {
      label: subCategories.breadCrumbs.subCategories
    }
  ];

  const FilterContent = () => {
    return (
      <div className="filters-container">
        <div className="border-bottom pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <ListFilter
            list={countriesList}
            label={subCategories.filters.country}
            name="subCategories"
            searchPlaceholder={subCategories.filters.searchCountry}
            selectedOptionsIds={countryFilterValue}
            setSelectedOptionsIds={setCountryFilterValue}
            clearFilters={clearFilters}
            collabsible
            showSearchBar
            isApplyFilters={isApplyFilters}
          />
        </div>

        <div className="border-bottom pt-lg-3 pb-lg-4 px-lg-2 py-3 px-md-5 px-3">
          <NumberRangeFilter
            name="price"
            label={subCategories.filters.priceRange}
            endAdorment={websiteCurrency?.Symbol}
            collabsible
            clearFilters={clearFilters}
            value={priceFilterValue}
            setValue={setPriceFilterValue}
            hasError={priceFilterError}
            setHasError={setPriceFilterError}
            filterClass={
              "sub-categories-range-filters mobile-version"
            }
          />
        </div>
        <div className="pt-lg-3 px-lg-2 py-3 px-md-5 px-3">
          <NumberRangeFilter
            name="moq"
            label={subCategories.filters.moq}
            collabsible
            clearFilters={clearFilters}
            value={moqFilterValue}
            setValue={setMoqFilterValue}
            hasError={moqFilterError}
            setHasError={setMoqFilterError}
            filterClass={
              "sub-categories-range-filters mobile-version"
            }
          />
        </div>
      </div>
    );
  };

  return (
    subCategoriesAllProductsList &&
    !!subCategoriesAllProductsList.length && (
      <div
        className={`sub-category-details-container container-xxl ${generalImporterPagesPadding}`}>
        <div>
          {subCategoriesProductsList &&
          !!Object.keys(subCategoriesProductsList).length ? (
            <div>
              <Breadcrumbs
                list={[
                  ...breadcrumbs,
                  { label: subCategoriesProductsList?.Name }
                ]}
              />

              <div className="products-section">
                <div className="row align-items-start">
                  <div
                    className="col-lg-3 col-md-4 filters-col d-none d-md-block border-radius-10"
                    style={{
                      position: "sticky",
                      top: 0
                    }}>
                    <div className="filters-container">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title-color fsize-22 font-semibold m-0">
                          Filters
                        </p>
                        <Button
                          label={shared.clear}
                          variant="text"
                          onClick={() => {
                            setClearFilters(true);
                            setTimeout(() => {
                              setClearFilters(false);
                            }, [500]);
                          }}
                          className="fsize-14 btn no-shadow"
                        />
                      </div>
                      <div className="filters-section scroll-within">
                        <div className="border-bottom pb-4 px-2">
                          <ListFilter
                            list={countriesList}
                            label={subCategories.filters.country}
                            name="subCategories"
                            searchPlaceholder={
                              subCategories.filters.searchCountry
                            }
                            selectedOptionsIds={countryFilterValue}
                            setSelectedOptionsIds={
                              setCountryFilterValue
                            }
                            clearFilters={clearFilters}
                            collabsible
                            showSearchBar
                            isApplyFilters={isApplyFilters}
                          />
                        </div>

                        <div className="border-bottom pt-3 pb-4 px-2">
                          <NumberRangeFilter
                            name="price"
                            label={subCategories.filters.priceRange}
                            endAdorment={websiteCurrency?.Symbol}
                            collabsible
                            clearFilters={clearFilters}
                            value={priceFilterValue}
                            setValue={setPriceFilterValue}
                            hasError={priceFilterError}
                            setHasError={setPriceFilterError}
                            filterClass={
                              "sub-categories-range-filters desktop-version"
                            }
                          />
                        </div>
                        <div className="pt-3 pb-4 px-2">
                          <NumberRangeFilter
                            name="moq"
                            label={subCategories.filters.moq}
                            collabsible
                            clearFilters={clearFilters}
                            value={moqFilterValue}
                            setValue={setMoqFilterValue}
                            hasError={moqFilterError}
                            setHasError={setMoqFilterError}
                            filterClass={
                              "sub-categories-range-filters desktop-version"
                            }
                          />
                        </div>
                      </div>
                      <div className="py-3 px-2 d-flex justify-content-between align-items-center">
                        <Button
                          onClick={() => {
                            setIsApplyFilters(true);
                          }}
                          label={shared.apply}
                          className="w-100"
                          disabled={
                            priceFilterError || moqFilterError
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 col-12 content-col">
                    <div className="ps-3">
                      <div className="sub-category-info-section mb-4">
                        <div
                          className={`sub-category-image w-100 border-radius-10 ${isSubCategoryImgValid ? "size-cover" : "size-contain"}`}
                          style={{
                            backgroundImage: `url(${isSubCategoryImgValid ? location?.state?.showcaseCoverImg || subCategoriesProductsList?.DefaultImage : noImgPlaceholder})`
                          }}>
                          <div className="sub-category-info border-radius-10 d-flex align-items-center justify-content-center">
                            <Box
                              className="text-center"
                              sx={{
                                width: {
                                  xs: "100%",
                                  md: "60%"
                                }
                              }}>
                              <Typography
                                className="text-white fweight-700 mb-2"
                                sx={{
                                  fontSize: {
                                    sx: "1.3rem",
                                    sm: "2.285rem"
                                  }
                                }}>
                                {subCategoriesProductsList?.Name}
                              </Typography>
                              <p className="mb-0 text-white fweight-500">
                                {
                                  subCategoriesProductsList?.Description
                                }
                              </p>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className="controls-section mb-3 d-flex justify-content-between align-items-md-center align-items-start flex-column flex-md-row">
                        <SortList
                          options={SORT_PRODUCTS}
                          onSelectOption={(selectedOption) =>
                            setSortingRange(selectedOption)
                          }
                          defaultOptionIndex={0}
                          selectedOption={sortingRange}
                        />
                        <div className="filters-sort-buttons-container d-flex mt-3 mt-md-0">
                          <div className="filters-button d-md-none d-block me-4">
                            <Filters
                              filterButtonClassName={
                                "sub-categories-filter-button "
                              }
                              handleClearFilters={setClearFilters}
                              handleApplyFilters={setIsApplyFilters}
                              FilterContent={FilterContent()}
                            />
                          </div>

                          <div className="display-style-section d-flex align-items-center">
                            <DisplayStyleButton
                              displayStyle={displayStyle}
                              onDisplayChange={(newDisplay) =>
                                setDisplayStyle(newDisplay)
                              }
                            />
                            <span className="ms-2 title-color">
                              {`${subCategoriesAllProductsMeta?.count} ${subCategories.productsFound}`}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="products-list">
                        <div className="row products-container mb-3">
                          {subCategoriesAllProductsList?.length
                            ? subCategoriesAllProductsList?.map(
                                (
                                  {
                                    Id,
                                    DefaultImage,
                                    Name,
                                    ExporterCountry,
                                    MinOrderQuantity,
                                    MoqUnitName,
                                    MinPriceRange,
                                    MaxPriceRange
                                  },
                                  i
                                ) =>
                                  displayStyle ===
                                  DISPLAY_STYLE.grid ? (
                                    <div
                                      className="grid-item-container col-lg-3 col-sm-6 col-12 mb-4"
                                      key={`grid-product-${i}`}>
                                      <ProductCardGrid
                                        src={DefaultImage}
                                        label={Name}
                                        country={ExporterCountry}
                                        count={MinOrderQuantity}
                                        unit={MoqUnitName}
                                        minPrice={MinPriceRange}
                                        maxPrice={MaxPriceRange}
                                        link={ROUTE_PATHS.productDetails.replace(
                                          ":id",
                                          Id
                                        )}
                                        currency={
                                          websiteCurrency?.Symbol
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      key={`list-product-${i}`}
                                      className="col-12 mb-4">
                                      <ProductCardList
                                        src={DefaultImage}
                                        label={Name}
                                        country={ExporterCountry}
                                        count={MinOrderQuantity}
                                        unit={MoqUnitName}
                                        minPrice={MinPriceRange}
                                        maxPrice={MaxPriceRange}
                                        link={ROUTE_PATHS.productDetails.replace(
                                          ":id",
                                          Id
                                        )}
                                        currency={
                                          websiteCurrency?.Symbol
                                        }
                                      />
                                    </div>
                                  )
                              )
                            : !isLoading && (
                                <EmptyState
                                  subTitle={subCategories.noProducts}
                                  showActionButton={false}
                                  buttonClassName="empty-state-create-button border-radius-8"
                                  imgSrc={noProductsImage}
                                />
                              )}
                        </div>
                        {!isLoading &&
                          !!subCategoriesAllProductsList?.length &&
                          subCategoriesAllProductsMeta.pages >= 1 &&
                          subCategoriesAllProductsMeta.currentPage <
                            subCategoriesAllProductsMeta.pages && (
                            <div className="load-more w-100 text-center">
                              <Button
                                label={shared.loadMore}
                                onClick={() => {
                                  handleShowMore();
                                }}
                                outlined
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EmptyState
              subTitle={subCategories.noSubCategoryFound}
              imgSrc={noProductsImage}
            />
          )}
        </div>
      </div>
    )
  );
};

export default SubCategoryDetails;
