import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Modal from "../Modal";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import avatarStars from "../../assets/images/vectors/avatarStars.png";
import { USER_STATUS_IDS } from "../../utils/Constants";
import { isExporterRole } from "../../utils/Helpers";

const ExporterContactDetailsModal = ({
  open,
  onClose,
  isImporterModal
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const isAuth = useSelector((state) => state.auth.login?.Token);

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (userStatusId) {
      setIsSubscribed(userStatusId === USER_STATUS_IDS.subscribed);
    }
  }, [userStatusId]);

  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const renderModalContent = () => (
    <div className="text-center">
      <div className="avatar-section mb-3">
        <img src={avatarStars} />
      </div>
      <p className="mb-3 fsize-24 fweight-700 title-color">
        {shared.exporterContactCard.sorry}
      </p>
      <p className="text-grey fsize-16 mb-0">
        {isAuth && !isSubscribed
          ? shared.exporterContactCard.notSubscribedSignInMsg
          : isImporterModal
            ? shared.exporterContactCard.signInImporterMsg
            : shared.exporterContactCard.signInMsg}
      </p>
    </div>
  );
  return (
    <Modal
      open={open}
      handleClose={onClose}
      modalClass={"exporter-contact-details-modal"}
      isContentText={false}
      content={renderModalContent()}
      hasActions={!isAuth || (isAuth && isExporterRole())}
      cancelBtnTxt={shared.exporterContactCard.cancel}
      handleCancel={onClose}
      confirmBtnTxt={
        !isAuth
          ? shared.exporterContactCard.signIn
          : shared.exporterContactCard.subscribe
      }
      handleConfirm={() =>
        !isAuth
          ? navigate(ROUTE_PATHS.login, {
              state: {
                prevLocation: location?.pathname
              }
            })
          : navigate(ROUTE_PATHS.pricingDetails)
      }
      title
    />
  );
};

export default ExporterContactDetailsModal;

ExporterContactDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isImporterModal: PropTypes.bool
};

ExporterContactDetailsModal.defaultProps = {
  isImporterModal: PropTypes.bool
};
