import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import messages from "../../../../assets/locale/messages";
import Stepper from "../../../../components/Stepper";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import ContactInfo from "./ContactInfo";
import CompanyInfo from "./CompanyInfo";
import { setRegisterationFormData } from "../../../../store/Auth/actions";
import { setSuggestedCategoryData } from "../../../../store/Categories/actions";
import "./Registeration.scss";

const Registeration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const { registeration } = messages[lang];

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(setRegisterationFormData({ clearForm: true }));
      dispatch(setSuggestedCategoryData({}));
    };
  }, []);

  return (
    <div className="registeration-page w-100">
      <h3 className="mb-4 title-color fweight-700 fsize-32">
        {registeration.createAccount}
      </h3>
      <Stepper
        steps={[
          {
            label: registeration.steps.contactInfo,
            className: "contact-info-step"
          },
          {
            label: registeration.steps.companyInfo
          }
        ]}
        activeStep={activeStep}
        wrapperClassName="mb-5"
      />
      <div className="form-section w-100">
        {activeStep === 0 && (
          <ContactInfo onSubmit={() => setActiveStep(1)} />
        )}
        {activeStep === 1 && (
          <CompanyInfo onPrevious={() => setActiveStep(0)} />
        )}
      </div>
      <div className="footer-section mt-4 mb-5">
        <p className="text-color text-center">
          {registeration.alreadyMember}
          <span
            className="text-primary fweight-600 ms-2 text-underline pointer"
            onClick={() => navigate(ROUTE_PATHS.login)}>
            {registeration.signIn}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Registeration;
