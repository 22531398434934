import {
  GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE,
  IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE,
  WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE,
  GET_IMPORTER_CONTACT_INFO_RESPONSE
} from "./types";

const INITIAL_STATE = {
  productRequestsList: {
    list: [],
    meta: {}
  },
  importerProductRequestDetails: {},
  importerProductRequestsList: {
    list: [],
    meta: {}
  },
  websiteProductRequestDetails: {},
  importerContactInfo: {}
};

const productRequests = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE:
      return {
        ...state,
        productRequestsList: {
          list:
            action.payload.meta.currentPage === 1
              ? [...action.payload.list]
              : [
                  ...state.productRequestsList.list,
                  ...action.payload.list
                ],
          meta: action.payload.meta
        }
      };
    case GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE:
      return {
        ...state,
        importerProductRequestDetails: action.payload
      };
    case IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE:
      return {
        ...state,
        importerProductRequestsList: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };
    case GET_IMPORTER_CONTACT_INFO_RESPONSE:
      return {
        ...state,
        importerContactInfo: action.payload
      };
    default:
      return state;
  }
};

export default productRequests;
