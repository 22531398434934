import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { editImporterProductRequestRequest } from "../../../../store/ProductRequests/actions";
import "./ProductRequestCurrencyChange.scss";

const ProductRequestCurrencyChange = ({
  open,
  closeModal,
  productRequestInfo
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    productRequests: {
      importerProductRequests: {
        addEditProductRequest: {
          ProductRequestCurrencyChangeModal: {
            content,
            confirmationContent,
            keepOldCurrencyBtn,
            proceedBtn
          }
        }
      }
    }
  } = messages[lang];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmEdit = () => {
    dispatch(
      editImporterProductRequestRequest({
        data: {
          ...productRequestInfo.requestData
        },
        navigate
      })
    );
    closeModal();
  };

  const handleKeepRequestCurrency = () => {
    productRequestInfo.requestData.CurrencyId =
      productRequestInfo.productRequestCurrency;
    dispatch(
      editImporterProductRequestRequest({
        data: {
          ...productRequestInfo.requestData
        },
        navigate
      })
    );
    closeModal();
  };

  const publishedEditedProductContent = () => {
    return (
      <div className="product-request-currency-container container d-flex flex-column justify-content-center align-items-center">
        <div className="product-request-currency-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {`${content}`}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {confirmationContent}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"product-request-currency-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirmEdit();
      }}
      handleClose={closeModal}
      isContentText={false}
      content={publishedEditedProductContent()}
      hasActions={true}
      confirmBtnTxt={proceedBtn}
      cancelBtnTxt={keepOldCurrencyBtn}
      handleCancel={handleKeepRequestCurrency}
      showCloseIcon
      title
      confirmBtnClass="border-radius-12 ps-5 pe-5"
      cancelBtnClass="text-secondary border-radius-12 "
      maxWidth={"xs"}
    />
  );
};

export default ProductRequestCurrencyChange;

ProductRequestCurrencyChange.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  productRequestInfo: PropTypes.object.isRequired
};
