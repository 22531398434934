export const MyStoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19">
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M2.605 8.47v3.367c0 3.368 1.35 4.718 4.718 4.718h4.043c3.367 0 4.717-1.35 4.717-4.718V8.47"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M9.348 9.055c1.372 0 2.385-1.118 2.25-2.49l-.495-5.01H7.601l-.503 5.01c-.135 1.372.878 2.49 2.25 2.49z"></path>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M14.08 9.055c1.515 0 2.625-1.23 2.475-2.738l-.21-2.062c-.27-1.95-1.02-2.7-2.985-2.7h-2.288l.525 5.257c.128 1.238 1.245 2.243 2.483 2.243zM4.578 9.055c1.238 0 2.355-1.005 2.475-2.243l.165-1.657.36-3.6H5.291c-1.965 0-2.715.75-2.985 2.7l-.203 2.062c-.15 1.508.96 2.738 2.475 2.738zM9.348 12.805c-1.253 0-1.875.622-1.875 1.875v1.875h3.75V14.68c0-1.253-.623-1.875-1.875-1.875z"></path>
  </svg>
);
