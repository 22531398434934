export const BuildingSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="27"
    fill="none"
    viewBox="0 0 26 27"
    {...props}>
    <path
      stroke="#455A64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.827 25.27H3.723c-1.392 0-2.532-1.116-2.532-2.484V4.978c0-3.144 2.34-4.572 5.208-3.168l5.328 2.616c1.152.564 2.1 2.064 2.1 3.336V25.27zM25.192 16.943v4.536c0 2.592-1.2 3.792-3.792 3.792h-7.572V11.375l.564.12 5.4 1.212 2.436.54c1.584.348 2.88 1.164 2.952 3.468.012.072.012.144.012.228zM5.428 9.668h4.164M5.428 14.469h4.164"></path>
  </svg>
);
