import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ProductCardGrid from "../../../../components/ProductCards/ProductCardGrid";
import Swiper from "../../../../components/Swiper";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const ProductsSection = ({
  sectionTitle,
  products,
  sectionKey,
  cardsNumber
}) => {
  const [productsCards, setProductsCards] = useState([]);

  const websiteCurrency = useSelector(
    (state) => state.home.websiteCurrency
  );

  useEffect(() => {
    if (products?.length) {
      setProductsCards(
        products.map(
          (
            {
              Id,
              Name,
              DefaultImage,
              ExporterCountry,
              MinOrderQuantity,
              MoqUnitName,
              MaxPriceRange,
              MinPriceRange
            },
            i
          ) => (
            <div key={`popular-product-${i}`} className="mx-3 ">
              <ProductCardGrid
                src={DefaultImage}
                label={Name}
                country={ExporterCountry}
                count={MinOrderQuantity}
                unit={MoqUnitName}
                minPrice={MinPriceRange}
                maxPrice={MaxPriceRange}
                link={ROUTE_PATHS.productDetails.replace(":id", Id)}
                currency={websiteCurrency?.Symbol}
              />
            </div>
          )
        )
      );
    }
  }, [products, websiteCurrency]);

  return (
    <div className="products-section-container">
      <div className="d-flex align-items-lg-center align-items-start justify-content-between flex-sm-row flex-column mb-3">
        <h4 className="fweight-700 fsize-22 mb-0">{sectionTitle}</h4>
        {/* <Link>
            <span className="text-primary fweight-600 fsize-16 highlighted-text pointer">
              {home.viewAll} <ChevronRightOutlined />
            </span>
          </Link> */}
      </div>
      <Swiper
        slides={productsCards}
        slidesToShow={1}
        infinite={false}
        speed={750}
        sliderClassName={"products-slider"}
        name={`${sectionKey}-products-slider`}
        slideClassName={"my-2"}
        responsiveSettings={{
          480: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: cardsNumber
          }
        }}
      />
    </div>
  );
};

export default ProductsSection;

ProductsSection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  sectionKey: PropTypes.string.isRequired,
  cardsNumber: PropTypes.number
};
ProductsSection.defaultProps = {
  cardsNumber: 6
};
