import {
  ADD_IMPORTER_PRODUCT_REQUEST_REQUEST,
  EDIT_IMPORTER_PRODUCT_REQUEST_REQUEST,
  GET_IMPORTER_PRODUCT_REQUEST_DETAILS_REQUEST,
  GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE,
  IMPORTER_PRODUCT_REQUESTS_LIST_REQUEST,
  IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE,
  WEBSITE_PRODUCT_REQUESTS_LIST_REQUEST,
  WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE,
  GET_IMPORTER_CONTACT_INFO_REQUEST,
  GET_IMPORTER_CONTACT_INFO_RESPONSE,
  CLOSE_PRODUCT_REQUEST_REQUEST
} from "./types";

export const websiteProductRequestsListRequest = (payload) => ({
  type: WEBSITE_PRODUCT_REQUESTS_LIST_REQUEST,
  payload
});

export const websiteProductRequestsListResponse = (payload) => ({
  type: WEBSITE_PRODUCT_REQUESTS_LIST_RESPONSE,
  payload
});

export const addImporterProductRequestRequest = (payload) => ({
  type: ADD_IMPORTER_PRODUCT_REQUEST_REQUEST,
  payload
});

export const editImporterProductRequestRequest = (payload) => ({
  type: EDIT_IMPORTER_PRODUCT_REQUEST_REQUEST,
  payload
});

export const getImporterProductRequestDetailsRequest = (payload) => ({
  type: GET_IMPORTER_PRODUCT_REQUEST_DETAILS_REQUEST,
  payload
});

export const getImporterProductRequestDetailsResponse = (
  payload
) => ({
  type: GET_IMPORTER_PRODUCT_REQUEST_DETAILS_RESPONSE,
  payload
});

export const importerProductRequestsListRequest = (payload) => ({
  type: IMPORTER_PRODUCT_REQUESTS_LIST_REQUEST,
  payload
});

export const importerProductRequestsListResponse = (payload) => ({
  type: IMPORTER_PRODUCT_REQUESTS_LIST_RESPONSE,
  payload
});

export const getImporterContactInfoRequest = (payload) => ({
  type: GET_IMPORTER_CONTACT_INFO_REQUEST,
  payload
});

export const getImporterContactInfoResponse = (payload) => ({
  type: GET_IMPORTER_CONTACT_INFO_RESPONSE,
  payload
});

export const closeProductRequestRequest = (payload) => ({
  type: CLOSE_PRODUCT_REQUEST_REQUEST,
  payload
});
