import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import Modal from "../../../../components/Modal";
import { PublishModalIcon } from "../../../../utils/Icons";
import { publishExporterRequest } from "../../../../store/User/actions";

const PublishProfileModal = ({ open, handleClose }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    (state) => state.user.accountDetails
  );

  const handleConfirmPublish = () => {
    dispatch(
      publishExporterRequest({
        hasShowcase: accountDetails.ShowCases.length !== 0
      })
    );
    handleClose();
  };

  const {
    shared,
    company: {
      publishProfile: { title, content }
    }
  } = messages[lang];

  const publishProductContent = () => {
    return (
      <div className="add-product-success-container container d-flex flex-column justify-content-center align-items-center">
        <PublishModalIcon />
        <div className="add-product-success-content mt-2 d-flex flex-column justify-content-center align-items-center text-center">
          <Typography className="fsize-20 fweight-700">
            {title}
          </Typography>
          <Typography className="text-secondary fsize-14 mt-2 fweight-600">
            {content}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalClass={"add-product-success-modal"}
      open={open}
      handleConfirm={() => {
        handleConfirmPublish();
      }}
      handleCancel={handleClose}
      handleClose={handleClose}
      isContentText={false}
      content={publishProductContent()}
      showCloseIcon
      hasActions={true}
      confirmBtnTxt={shared.publish}
      cancelBtnTxt={shared.cancel}
      title
    />
  );
};

export default PublishProfileModal;

PublishProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
