import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../../../../components/Alert";
import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import { BANNER_COLORS } from "../../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const VerifyNow = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      verificationNotifications: { verifyNow }
    }
  } = messages[lang];

  return (
    <Alert
      type="warning"
      content={
        <p className="mb-0 fsize-16 fweight-500">
          {verifyNow.msg1}
          <Link
            to={ROUTE_PATHS.businessVerification}
            className="pointer">
            <span className="text-primary text-underline pointer ms-2">
              {verifyNow.msg2}
            </span>
          </Link>
        </p>
      }
      icon={<ChipInfoIcon color={BANNER_COLORS.warning} />}
    />
  );
};

export default VerifyNow;
