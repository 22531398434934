import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../../../../assets/locale/messages";
import Input from "../../../../../../components/Input";
import PhoneInput from "../../../../../../components/PhoneInput";
import FileUpload from "../../../../../../components/Upload/FileUpload";
import { getCountriesListRequest } from "../../../../../../store/Lookups/actions";
import {
  editOffPlatformRequestedDocuments,
  uploadOffPlatformRequestedDocuments
} from "../../../../../../store/Verification/actions";
import { showHideLoader } from "../../../../../../store/Loader/actions";
import { BuildingSmallIcon } from "../../../../../../utils/Icons";
import {
  ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
  LIMITED_DOMAIN_LENGTH_EMAIL_REGEX
} from "../../../../../../utils/Patterns";
import {
  BLOB_CONTAINERS,
  UploadBlobToContainer
} from "../../../../../../utils/blobStorageServices";
import {
  COMPANY_VERIFICATION_STATUS,
  OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES
} from "../../../../../../utils/Constants";

const UploadDocumentsForm = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: { offPlatform },
    shared
  } = messages[lang];

  const requiredDocuments = useSelector(
    (state) => state.verification.offplatformRequiredDocs
  );
  const countriesList = useSelector(
    (state) => state.lookups.countriesList
  );
  const userCountry = useSelector(
    (state) => state.auth.login.CountryName
  );

  const requestDetails = useSelector(
    (state) => state.verification.offplatformRequiredDocs
  );

  const requestData = useSelector(
    (state) => state.verification.offplatformRequestData
  );

  const companyVerificationStatus = useSelector(
    (state) => state.verification.companyVerificationStatus
  );

  const [countryShort, setCountryShort] = useState("");
  const [countryCodeLength, setCountryCodeLength] = useState(0);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  useEffect(() => {
    dispatch(getCountriesListRequest());
  }, []);

  useEffect(() => {
    if (countriesList.length) {
      setCountryShort(
        countriesList.filter(
          (country) => country.Name === userCountry
        )[0].CountryNameShort
      );
    }
  }, [countriesList]);

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    setValues
  } = useFormik({
    initialValues: {
      documents: [],
      representativeName: "",
      email: "",
      mobileNumber: "",
      countryCodeLength: 0,
      jobTitle: "",
      personalId: null,
      personalIdInfo: { name: "", type: "" }
    },
    validationSchema: Yup.object({
      documents: Yup.array()
        .min(1, "offPlatformDocsRequired")
        .max(15, "offPlatformDocsMaxLength"),
      representativeName: Yup.string()
        .required("offPlatformRepNameRequired")
        .max(50, "offPlatformRepNameLength")
        .matches(
          ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
          "offPlatformRepNameValidation"
        ),
      email: Yup.string()
        .required("offPlatformEmailRequired")
        .matches(
          LIMITED_DOMAIN_LENGTH_EMAIL_REGEX,
          "offPlatformEmailInvalid"
        ),
      countryCodeLength: Yup.number(),
      mobileNumber: Yup.string()
        .test(
          "valid-length",
          "exporterMobileNumberLength",
          function (value) {
            const { countryCodeLength } = this.parent;
            if (!countryCodeLength || !value) {
              return true;
            }
            const actualLength = value.length;
            if (actualLength !== countryCodeLength) {
              return actualLength >= countryCodeLength + 6;
            }
            return true;
          }
        )
        .nullable(),
      jobTitle: Yup.string()
        .required("offPlatformJobTitleRequired")
        .max(50, "offPlatformJobTitleLength")
        .matches(
          ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
          "offPlatformJobTitleValidation"
        ),
      personalId: Yup.mixed().required("offPlatformIdRequired")
    }),
    onSubmit: async ({
      documents,
      representativeName,
      email,
      mobileNumber,
      jobTitle,
      personalId,
      personalIdInfo
    }) => {
      dispatch(showHideLoader(true));
      const documentsNamesArr = await Promise.all(
        documents.map(async (doc) => {
          let docName = "";
          if (doc && doc.file) {
            if (doc.isNew) {
              docName = await UploadBlobToContainer(
                {
                  blob: doc.file,
                  name: doc.name,
                  type: doc.type
                },
                doc.type.includes("pdf")
                  ? BLOB_CONTAINERS.pdfs
                  : BLOB_CONTAINERS.images
              );
            } else {
              docName = doc?.file?.split(
                doc?.type?.includes("pdf")
                  ? `/${BLOB_CONTAINERS.pdfs}/`
                  : `/${BLOB_CONTAINERS.images}/`
              )[1];
            }
          }
          return {
            fileName: docName,
            originalFileName: doc.name,
            fileType: doc.type
          };
        })
      );

      let personalDocumentName = "";
      if (personalId) {
        if (personalIdInfo.isNew) {
          personalDocumentName = await UploadBlobToContainer(
            {
              blob: personalId,
              ...personalIdInfo
            },
            BLOB_CONTAINERS.pdfs
          );
        } else {
          personalDocumentName = personalId?.split(
            `/${BLOB_CONTAINERS.pdfs}/`
          )[1];
        }
      }

      if (documentsNamesArr?.length && personalDocumentName) {
        const data = {
          Id: requestData?.Id || 0,
          VerificationRequestId:
            requestData?.VerificationRequestId || 0,
          JobTitle: jobTitle,
          MobileNumber: `${mobileNumber.length > 0 && !mobileNumber.startsWith("+") ? `+` : ""}${mobileNumber}`,
          Email: email,
          RepresentativeName: representativeName,
          RepresentativeIDDoc: personalDocumentName,
          OriginalRepresentativeIDDoc: personalIdInfo?.name,
          CompanyId: requestDetails?.CompanyId,
          CompanyDocuments: documentsNamesArr.map((doc) => ({
            Id: 0,
            VerificationRequestCompanyDataId: 0,
            DocumentName: doc.fileName,
            OriginalDocumentName: doc.originalFileName,
            DocumentTypeId: doc.fileType.includes("pdf")
              ? OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.pdf
              : OFF_PLATFORM_VERIFICATION_DOCUMENTS_TYPES.image
          }))
        };

        if (
          companyVerificationStatus ===
          COMPANY_VERIFICATION_STATUS.docsNeedUpdates
        ) {
          dispatch(editOffPlatformRequestedDocuments(data));
        } else {
          dispatch(uploadOffPlatformRequestedDocuments(data));
        }
      } else {
        dispatch(showHideLoader(false));
      }
    }
  });

  useEffect(() => {
    if (requestData && Object.keys(requestData)?.length) {
      const {
        CompanyDocuments,
        Email,
        JobTitle,
        countryCodeLength,
        MobileNumber,
        OriginalRepresentativeIDDoc,
        RepresentativeIDDoc,
        RepresentativeName
      } = requestData;
      const documents = CompanyDocuments?.map((doc) => {
        const docNameArr = doc.OriginalDocumentName.split(".");
        const docType = docNameArr[docNameArr.length - 1];
        return {
          file: doc.DocumentName,
          name: doc.OriginalDocumentName,
          type: docType,
          isNew: false
        };
      });
      const representativeDocNameArr =
        OriginalRepresentativeIDDoc.split(".");
      const representativeDocType =
        representativeDocNameArr[representativeDocNameArr.length - 1];

      setValues({
        ...values,
        documents,
        representativeName: RepresentativeName,
        email: Email,
        countryCodeLength,
        mobileNumber: MobileNumber,
        jobTitle: JobTitle,
        personalId: RepresentativeIDDoc,
        personalIdInfo: {
          name: OriginalRepresentativeIDDoc,
          type: representativeDocType,
          isNew: false
        }
      });
    }
  }, [requestData]);

  return (
    <div className="upload-required-documents-container ">
      <form
        onSubmit={handleSubmit}
        noValidate
        id="upload-offplatform-required-documents-form">
        <div className="p-4 border-radius-10 light-border mb-4">
          <h6 className="fsize-16 fweight-600 title-color mb-3">
            {offPlatform.requiredDocuments.companyInfo}
          </h6>
          <Box
            className="grey-bg border-radius-10 px-4 py-3 mb-4 d-flex justify-content-start align-items-center"
            sx={{
              minWidth: {
                xs: "100%",
                sm: "75%",
                md: "40%",
                lg: "30%"
              },
              width: "fit-content"
            }}>
            <BuildingSmallIcon />
            <div className="ms-3">
              <p className="medium-text-color fsize-13 mb-0">
                {offPlatform.requiredDocuments.companyName}
              </p>
              <p className="text-color fsize-15 fweight-600 mb-0">
                {requiredDocuments?.CompanyName}
              </p>
            </div>
          </Box>
          <div className="uploading-docs">
            <p className="fsize-12 title-color">
              {offPlatform.requiredDocuments.uploadRequiredDocuments}
              <span className="fsize-12 text-light-grey ms-2">
                {offPlatform.requiredDocuments.maxFiles}
              </span>
            </p>
            <FileUpload
              name="documents"
              id="required-documents"
              fileUploadLabel={shared.browseFile}
              placeholder={
                offPlatform.requiredDocuments.selectMultipleFilesNote
              }
              onChange={(files) => {
                let filesArr = [];
                for (const file of files) {
                  if (
                    !values.documents.find(
                      (doc) => doc.name === file?.name
                    )
                  ) {
                    filesArr.push({
                      file: file || null,
                      name: file?.name,
                      type: file?.type,
                      isNew: true
                    });
                  }
                }
                setFieldTouched("documents");
                setFieldValue("documents", [
                  ...values.documents,
                  ...filesArr
                ]);
              }}
              onDeleteFile={(index) => {
                setFieldTouched("documents");
                setFieldValue(
                  "documents",
                  values.documents?.filter((_, i) => index !== i)
                );
              }}
              value={values.documents?.map((doc) => doc.file)}
              fileInfo={values.documents?.map((doc) => ({
                name: doc.name,
                type: doc.type
              }))}
              required={true}
              disabled={values.documents?.length >= 15}
              acceptsImgs
              multiple
              isInputHasErr={
                !!(touched.documents && errors.documents)
              }
              errMsg={errors.documents}
            />
          </div>
        </div>
        <div className="p-4 border-radius-10 light-border">
          <h6 className="fsize-16 fweight-600 title-color mb-4">
            {offPlatform.requiredDocuments.companyRepresentativeInfo}
          </h6>
          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              <Input
                name="representativeName"
                id="representativeName"
                label={
                  offPlatform.requiredDocuments.inputs
                    .representativeName
                }
                placeholder={
                  offPlatform.requiredDocuments.inputs
                    .representativeNamePlaceholder
                }
                onChange={(value) => {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("representativeName");
                    setFieldValue(
                      "representativeName",
                      modifiedValue
                    );
                  }
                }}
                value={values.representativeName}
                isInputHasErr={
                  !!(
                    touched.representativeName &&
                    errors.representativeName
                  )
                }
                errMsg={errors.representativeName}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <Input
                name="email"
                id="email"
                label={
                  offPlatform.requiredDocuments.inputs.emailAddress
                }
                placeholder={
                  offPlatform.requiredDocuments.inputs
                    .emailAddressPlaceholder
                }
                onChange={(value) => {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("email");
                    setFieldValue("email", modifiedValue);
                  }
                }}
                value={values.email}
                isInputHasErr={!!(touched.email && errors.email)}
                errMsg={errors.email}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <PhoneInput
                name="mobileNumber"
                id="mobileNumber"
                label={
                  offPlatform.requiredDocuments.inputs.mobileNumber
                }
                placeholder={
                  offPlatform.requiredDocuments.inputs
                    .mobileNumberPlaceholder
                }
                onFocus={() => {
                  setIsPhoneFocused(true);
                }}
                onBlur={() => {
                  setIsPhoneFocused(false);
                }}
                isFocused={isPhoneFocused}
                onChange={(value, dialCodeLength) => {
                  setFieldTouched("mobileNumber");
                  setFieldTouched("countryCodeLength");
                  setValues({
                    ...values,
                    mobileNumber: value,
                    countryCodeLength: dialCodeLength
                  });
                }}
                userCountryShort={
                  !isPhoneFocused &&
                  requestData &&
                  !Object.keys(requestData)?.length &&
                  !requestData.MobileNumber &&
                  values.mobileNumber?.length === 0
                    ? countryShort
                    : ""
                }
                value={values.mobileNumber}
                isInputHasErr={
                  !!(touched.mobileNumber && errors.mobileNumber)
                }
                errMsg={errors.mobileNumber}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <Input
                name="jobTitle"
                id="jobTitle"
                label={offPlatform.requiredDocuments.inputs.jobtitle}
                placeholder={
                  offPlatform.requiredDocuments.inputs
                    .jobtitlePlaceholder
                }
                onChange={(value) => {
                  if (!value || !!value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("jobTitle");
                    setFieldValue("jobTitle", modifiedValue);
                  }
                }}
                value={values.jobTitle}
                isInputHasErr={
                  !!(touched.jobTitle && errors.jobTitle)
                }
                errMsg={errors.jobTitle}
              />
            </div>
            <div className="col-12 col-md-6">
              <FileUpload
                name="personalId"
                id="personal-id"
                fileUploadLabel={shared.browseFile}
                label={
                  offPlatform.requiredDocuments.inputs.nationalId
                }
                onChange={(file) => {
                  setFieldTouched("personalId");
                  setValues({
                    ...values,
                    personalId: file,
                    personalIdInfo: {
                      name: file?.name || "",
                      type: file?.type || "",
                      isNew: true
                    }
                  });
                }}
                value={values.personalId || ""}
                fileInfo={values.personalIdInfo}
                required={true}
                acceptsImgs
                isInputHasErr={
                  !!(touched.personalId && errors.personalId)
                }
                errMsg={errors.personalId}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadDocumentsForm;
