const company = {
  companyProfile: {
    companyProfileTitle: "Company Profile",
    buttons: {
      publishButton: "Publish Profile",
      editButton: "Edit Profile info",
      addShowcaseButton: "Add New Showcase"
    },
    banner: {
      boldContent: "Take the next step! ",
      normalContent:
        "Complete and publish your profile to start connecting with potential partners worldwide."
    },
    subAccountsWarningBanner: {
      boldContent: "Welcome back ! ",
      normalContent:
        "Please note that reactivating your main account does not automatically reactivate any sub-accounts. Sub-accounts will need to be reactivated manually from the Sub account list."
    },
    emptyShowCase: {
      addShowcaseButton: "Add Showcase",
      noShowcasePlaceholder: "You haven’t added any showcases"
    },
    plan: "Plan",
    pendingCategoryWarning:
      "The categories that you suggest are still pending",
    rejectedCategoryWarning:
      "The categories that you suggest is rejected, you can edit your profile and select new categories",
    addProduct: "Add Product",
    showcases: "Showcases"
  },
  publishProfile: {
    title: "Publish Company Profile",
    content: "Are you sure, you want to publish the company profile ?"
  },
  editProfileModal: {
    title: "Edit Company Profile",
    content:
      "Kindly note that upon saving the profile edits, it'll be displayed on the website"
  },

  editCompanyProfile: {
    title: "Edit Company Profile",
    companyDetails: "Company Details",
    contactInfo: "Contact Info",
    uploadLogo: "Upload Logo",
    breadCrumbs: {
      company: "Company",
      editCompany: "Edit Company"
    },
    inputLabels: {
      businessType: "Business Type",
      categories: "Categories",
      country: "Country",
      companyLegalName: "Company Legal Name",
      companyDisplayName: "Company Display Name",
      companyIntroduction: "Company Introduction",
      yearEstablished: "Year Established",
      experienceYears: "Years of exporting experience",
      exporterName: "Exporter Name",
      exporterEmail: "Email Address",
      exporterMobileNumber: "Mobile Number",
      companyWebsite: "Company website",
      companyLogo: "Upload Logo",
      companyCoverImage: "Upload Cover Photo",
      companyImages: "Upload Cover Photo & Logo"
    },
    placeholders: {
      businessTypePlaceholder: "Enter the Business Type",
      categoriesPlaceholder: "Select the Categories",
      countryPlaceholder: "Enter the Country",
      companyDisplayNamePlaceholder: "Enter the Company Display Name",
      companyLegalNamePlaceholder: "Enter the Company Legal Name",
      companyIntroductionPlaceholder:
        "Enter the Company Introduction",
      yearEstablishedPlaceholder: "Enter the Establishing Year",
      experienceYearsPlaceholder: "Experience Years",
      exporterNamePlaceholder: "Enter the Exporter Name",
      exporterEmailPlaceholder: "Enter the Email Address",
      exporterMobileNumberPlaceholder: "Enter the Mobile Number",
      companyWebsitePlaceholder: "Enter the Company website"
    },
    businessTypesOptionsLabels: {
      trade: "Trade",
      factory: "Factory",
      both: "Both"
    }
  },
  addShowcase: {
    title: "Showcases Setup",
    showcaseInfo: "Showcase Info",
    subcategoryWarning:
      "You must select a subcategory before adding products",
    breadCrumbs: {
      company: "Company",
      showcasesSetup: "ShowCases Setup"
    },
    inputLabels: {
      subcategory: "Sub Category",
      description: "Showcase Description",
      addProducts: "Add Products",
      coverPhoto: "Click here to upload image",
      websiteCoverPhoto: "Cover Image For Website"
    },
    placeholders: {
      subcategory: "Select sub category ",
      description: "description",
      maxProductNumber: "(Max 6)",
      websiteCoverPhoto:
        "Please upload the same image used for the showcase cover to display it in the products list. This will help users recognize your screen easily."
    },
    modal: {
      title: "Select Products",
      searchPlaceholder: "Search using Product Name",
      maxSelectedProduct:
        "Maximum number of products reached: You can only have up to 6 products.",
      emptyState: {
        addMoreBtn: "Add Products",
        noSearchFilterResults:
          "There is no search result that matches the entered data.",
        noProductsPlaceholder: "There is no products found",
        noSelectedProductsPlaceholder: "There is no Selected products"
      },
      tabs: {
        allProducts: "All Products",
        selectedProducts: "Selected Products"
      }
    },
    changeSubcategoryModal: {
      title: "Change Subcategory",
      content:
        "Kindly note that changing the subcategory will delist all the selected products?"
    }
  },
  deleteShowcaseModal: {
    content: "Delete",
    confirmationContent:
      "Are you sure you want to delete this showcase?",
    subConfirmationContent: " This action cannot be undone."
  },
  showcasesAlert: {
    textFirstPart: "With the",
    textSecondPartUnsubscribed:
      "To publish showcases, please subscribe now. If you need to add more than ",
    textSecondPart: "you're allowed to add up to",
    showcases: "showcases",
    textThirdPart: "If you need to add more, please",
    upgradePlanPart: "Upgrade your plan",
    subscribePart:
      "Consider upgrading to the Standard or Premium plan",
    now: "now"
  },
  maxShowcasesCountModal: {
    content: "Sorry, you have reached the limit.",
    confirmationContent:
      "Level up your profile! Upgrade for more showcases."
  },
  subscribeModal: {
    content: "To publish showcases, please subscribe now.",
    confirmationContent:
      "If you need to add more than two showcases, consider upgrading to the Standard or Premium plan",
    confirmationContentExpiredSubscription:
      "To continue managing your showcases, please renew your subscription plan"
  },
  maxPremiumShowcasesModal: {
    content: "You have reached the maximum number of showcases.",
    confirmationContent:
      "Please delete an existing showcase to add a new one.",
    cancelButton: "Back to the company tab"
  },
  downgradeShowcases: {
    msg1: "Since you've ",
    downgraded: "downgraded ",
    msg2: "your plan, you can choose ",
    showcases: " showcases ",
    msg3: "from the ones currently available.",
    msg4: "Please note that after making your selection, the remaining extra showcases will be deleted. ",
    editShowcaseSelection: "Edit Showcases Selection"
  }
};
export default company;
