export const ChangePasswordSuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="101"
    fill="none"
    viewBox="0 0 100 101">
    <g clipPath="url(#clip0_3801_68656)">
      <path
        fill="#22B573"
        d="M50 .031c-27.613 0-50 22.38-50 50 0 27.621 22.387 50 50 50s50-22.392 50-50c0-27.607-22.387-50-50-50z"></path>
      <path
        fill="#74DA7F"
        d="M0 50.031A49.78 49.78 0 005.955 73.71a54.444 54.444 0 0015.377 2.205c30.059 0 54.426-24.367 54.426-54.426a54.435 54.435 0 00-2.274-15.613A49.777 49.777 0 0050 .031c-27.613 0-50 22.38-50 50z"></path>
      <path
        fill="#fff"
        d="M78.516 41.747l-30.053 31.56a8.819 8.819 0 01-6.344 2.735h-.039a8.811 8.811 0 01-6.328-2.678L19.805 56.938a8.816 8.816 0 1112.652-12.28l9.56 9.849 23.73-24.918a8.818 8.818 0 1112.769 12.158z"></path>
    </g>
    <defs>
      <clipPath id="clip0_3801_68656">
        <path
          fill="#fff"
          d="M0 0H100V100H0z"
          transform="translate(0 .031)"></path>
      </clipPath>
    </defs>
  </svg>
);
