const notifications = {
  notifications: "Notifications",
  noNotifications: "No notifications found",
  markAllAsRead: "Mark All As Read",
  seeAllNotifications: "See All Notifications",
  new: "New",
  read: "Read",
  loadMore: "Load More",
  copyCode: "Copy Code"
};

export default notifications;
