import { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, Menu } from "@mui/material";

import Button from "../Button";
import "./menu.scss";

const MenuDropDown = ({
  mainText,
  menuList,
  menuClass,
  mainWrapperClass,
  itemClass,
  buttonClassName
}) => {
  const [anchorEl, setAnchorEl] = useState("");
  const open = anchorEl ? true : false;
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (onClick) => {
    onClick();
    handleClose();
  };

  return (
    <div className={`dropdown-menu-list ${mainWrapperClass} `}>
      <Button
        label={mainText}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={buttonClassName}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        className={`dropdown-menu-wrapper ${menuClass}`}>
        {menuList?.map((item, i) => (
          <div key={`menu-item-${i}`}>
            {item.icon ? (
              <div className="d-flex">
                <MenuItem
                  onClick={() => {
                    item.onClick && handleItemClick(item.onClick);
                  }}
                  className={`menu-item d-flex gap-3 ps-2 ${itemClass} ${item.class} ${menuList?.length === 1 && "single-item"}`}>
                  {item.icon}
                  {item.label}
                </MenuItem>
              </div>
            ) : (
              <MenuItem
                onClick={() => {
                  item.onClick && handleItemClick(item.onClick);
                }}
                className={`menu-item ${itemClass} ${item.class} ${menuList?.length === 1 && "single-item"}`}>
                {item.label}
              </MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </div>
  );
};

MenuDropDown.propTypes = {
  mainText: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired
  ]),
  menuList: PropTypes.array.isRequired,
  menuClass: PropTypes.string,
  buttonClassName: PropTypes.string,
  mainWrapperClass: PropTypes.string,
  itemClass: PropTypes.string,
  handleChosen: PropTypes.func
};
export default MenuDropDown;
