export const AddProductSuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="105"
    fill="none"
    viewBox="0 0 96 105">
    <path
      fill="#0064A7"
      d="M75.556 58.74l-4.626-3.065v1.688H54.825l.033-13.066h1.216l-2.195-4.052-2.498 4.077h1.376v15.574h.439l17.76.05v1.477l4.6-2.684zM54.197 29.635h-.178a.46.46 0 01-.456-.456v-5.646a.46.46 0 01.456-.456h.178a.46.46 0 01.455.455v5.648a.454.454 0 01-.456.455zM54.197 88.797h-.178a.46.46 0 01-.456-.456v-5.647a.46.46 0 01.456-.456h.178a.46.46 0 01.455.456v5.647a.454.454 0 01-.456.456zM20.258 55.506v-.177a.46.46 0 01.456-.456h5.647a.46.46 0 01.455.456v.177a.46.46 0 01-.455.456h-5.647a.454.454 0 01-.456-.456zM81.411 55.506v-.177a.46.46 0 01.456-.456h5.647a.46.46 0 01.456.456v.177a.46.46 0 01-.456.456h-5.647a.454.454 0 01-.456-.456z"></path>
    <path
      stroke="#0064A7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="4"
      d="M52.771 102.801c-27.61 0-49.996-22.386-49.996-50.004C2.775 25.187 25.161 2.8 52.771 2.8c8.56 0 16.612 2.152 23.652 5.934"></path>
    <path
      fill="#003866"
      d="M84.426 17.067L80.662 3.122l-10.197 10.23 13.961 3.715-3.764-13.945-10.197 10.23 13.961 3.715zM93.233 56.332a1.996 1.996 0 01-1.992-1.992c0-7.96-2.49-15.54-7.2-21.947l3.064-2.558c5.318 7.142 8.12 15.6 8.12 24.496.009 1.097-.894 2-1.992 2zM67.186 19.597a36.773 36.773 0 00-13.066-2.389c-4.482 0-8.88.802-13.067 2.389l-1.342-3.756a40.808 40.808 0 0114.417-2.617c4.947 0 9.792.878 14.417 2.617l-1.359 3.756z"></path>
    <path
      fill="#0064A7"
      d="M15 56.332a1.996 1.996 0 01-1.991-1.992c0-8.905 2.81-17.363 8.12-24.495l3.064 2.557c-4.71 6.407-7.2 13.987-7.2 21.947A2 2 0 0115 56.332zM21.635 29.162a41.222 41.222 0 0117.27-13.017l1.342 3.765A37.215 37.215 0 0024.69 31.72l-3.055-2.558z"></path>
    <path
      fill="#003866"
      d="M83.522 31.71A37.215 37.215 0 0067.966 19.9l1.342-3.764a41.224 41.224 0 0117.27 13.016l-3.056 2.558z"></path>
    <path
      fill="#0064A7"
      d="M54.652 86.173c16.983 0 30.75-13.768 30.75-30.75 0-16.984-13.767-30.751-30.75-30.751s-30.75 13.767-30.75 30.75 13.767 30.75 30.75 30.75z"
      opacity="0.08"></path>
  </svg>
);
