import PropTypes from "prop-types";
import {
  Checkbox as MuiCheckbox,
  FormGroup,
  FormControlLabel
} from "@mui/material";

import { EmptyCheckBox, FilledCheckBox } from "../../utils/Icons";

const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  color,
  labelClass,
  id,
  disabled,
  checkboxClass
}) => (
  <>
    <FormGroup className={checkboxClass}>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            color={color}
            name={name}
            id={id || name}
            disabled={disabled}
            icon={<EmptyCheckBox />}
            checkedIcon={<FilledCheckBox />}
          />
        }
        label={<span className={`${labelClass}`}>{label}</span>}
      />
    </FormGroup>
  </>
);

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxClass: PropTypes.string
};

Checkbox.defaultProps = {
  color: "primary"
};

export default Checkbox;
