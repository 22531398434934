import PropTypes from "prop-types";

export const GreyLocationIcon = ({ dimensions, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions || "24"}
    height={dimensions || "24"}
    fill="none"
    viewBox="0 0 18 22"
    {...props}>
    <path
      stroke="#455A64"
      strokeWidth="1.5"
      d="M16.714 9.148c0 2.73-2.857 6.804-5.126 9.608-1.183 1.464-1.775 2.195-2.73 2.195-.957 0-1.548-.731-2.732-2.195C3.857 15.952 1 11.878 1 9.148 1 4.62 4.518.95 8.857.95c4.34 0 7.857 3.67 7.857 8.197z"></path>
    <path
      stroke="#455A64"
      strokeWidth="1.5"
      d="M5.91 9.52c0 1.578 1.32 2.857 2.947 2.857s2.947-1.28 2.947-2.857c0-1.577-1.32-2.856-2.947-2.856S5.911 7.943 5.911 9.52z"></path>
  </svg>
);

GreyLocationIcon.propTypes = {
  dimensions: PropTypes.string
};
