import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Modal from "../../../../components/Modal";
import messages from "../../../../assets/locale/messages";
import { CloseBgIcon } from "../../../../utils/Icons";
import { formatDate } from "../../../../utils/Helpers";
import { cancelAutomaticRenewalSubscriptionRequest } from "../../../../store/Subscription/actions";

const CancelSubscription = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const {
    myAccount: {
      subscriptionDetails: { cancelSubscription }
    },
    shared
  } = messages[lang];
  const userPlan = useSelector(
    (state) => state.subscription.userPlan
  );

  const renderModalContent = () => (
    <div className="text-center">
      <CloseBgIcon />
      <p className="mb-4 title-color fsize-18 fweight-600">
        {cancelSubscription.title}
      </p>
      <p className="text-color">
        {cancelSubscription.msg1}
        <span className="fweight-600 mx-1">
          {userPlan?.RenewalDate
            ? formatDate({
                date: userPlan?.RenewalDate,
                stringInterpolation: true
              })
            : ""}
        </span>
        {cancelSubscription.msg2}
      </p>
    </div>
  );

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title
      isContentText={false}
      content={renderModalContent()}
      confirmBtnTxt={cancelSubscription.confirmCancellation}
      cancelBtnTxt={shared.back}
      handleCancel={onClose}
      handleConfirm={() => {
        dispatch(cancelAutomaticRenewalSubscriptionRequest());
        onClose();
      }}
      confirmBtnClass={"bg-danger px-4 py-2 w-auto"}
      cancelBtnClass={"px-4 py-2"}
    />
  );
};

export default CancelSubscription;

CancelSubscription.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
