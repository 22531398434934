import { useEffect } from "react";
import { useSelector } from "react-redux";

import { VERIFICATION_METHODS } from "../../../../../utils/Constants";
import OnlinePayment from "../../OnlinePayment";
import BankTransfer from "../../BankTransfer";
import OffPlatformPayment from "../../OffPlatform/OffPlatformPayment";

const PaymentInformation = () => {
  const selectedMethod = useSelector(
    (state) => state.verification.selectedMethod
  );
  const basicLayoutContainerRef = useSelector(
    (state) => state.navigation.basicLayoutContainerRef
  );

  useEffect(() => {
    basicLayoutContainerRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className="payment-information-container">
      {selectedMethod === VERIFICATION_METHODS.onlinePayment && (
        <OnlinePayment />
      )}
      {selectedMethod === VERIFICATION_METHODS.bankTransfer && (
        <BankTransfer />
      )}
      {selectedMethod === VERIFICATION_METHODS.offPlatform && (
        <OffPlatformPayment />
      )}
    </div>
  );
};

export default PaymentInformation;
