import {
  ADD_SHOWCASE_REQUEST,
  EDIT_LOGGED_IN_EXPORTER_DETAILS_REQUEST,
  GET_LOGGED_IN_EXPORTER_DETAILS_REQUEST,
  GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE,
  SUBCATEGORY_PRODUCTS_LIST_REQUEST,
  SUBCATEGORY_PRODUCTS_LIST_RESPONSE,
  PUBLISH_EXPORTER_REQUEST,
  EDIT_SHOWCASE_REQUEST,
  DELETE_SHOWCASE_REQUEST,
  GET_USER_STATUS_REQUEST,
  HIDE_INSTRUCTIONS_BANNER_REQUEST
} from "./types";

export const getLoggedInExporterDetailsRequest = () => ({
  type: GET_LOGGED_IN_EXPORTER_DETAILS_REQUEST
});

export const getLoggedInExporterDetailsResponse = (payload) => ({
  type: GET_LOGGED_IN_EXPORTER_DETAILS_RESPONSE,
  payload
});

export const editLoggedInExporterDetailsRequest = (payload) => ({
  type: EDIT_LOGGED_IN_EXPORTER_DETAILS_REQUEST,
  payload
});

export const getSubCategoryProductsListRequest = (payload) => ({
  type: SUBCATEGORY_PRODUCTS_LIST_REQUEST,
  payload
});

export const getSubCategoryProductsListResponse = (payload) => ({
  type: SUBCATEGORY_PRODUCTS_LIST_RESPONSE,
  payload
});

export const addShowcaseRequest = (payload) => ({
  type: ADD_SHOWCASE_REQUEST,
  payload
});

export const publishExporterRequest = (payload) => ({
  type: PUBLISH_EXPORTER_REQUEST,
  payload
});

export const editShowcaseRequest = (payload) => ({
  type: EDIT_SHOWCASE_REQUEST,
  payload
});

export const deleteShowcaseRequest = (payload) => ({
  type: DELETE_SHOWCASE_REQUEST,
  payload
});

export const getUserStatusRequest = () => ({
  type: GET_USER_STATUS_REQUEST
});

export const hideInstructionsBannerRequest = () => ({
  type: HIDE_INSTRUCTIONS_BANNER_REQUEST
});
