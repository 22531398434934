import axios from "axios";

import {
  requestHandler,
  successHandler,
  errorHandler
} from "../interceptors";

export const axiosInstanceImporters = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_IMPORTERS_URL
});

// Handle request process
axiosInstanceImporters.interceptors.request.use((request) =>
  requestHandler(request)
);

// Handle response process
axiosInstanceImporters.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const axiosInstanceExporters = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

// Handle request process
axiosInstanceExporters.interceptors.request.use((request) =>
  requestHandler(request)
);

// Handle response process
axiosInstanceExporters.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const axiosInstanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_ADMIN_URL
});

// Handle request process
axiosInstanceAdmin.interceptors.request.use((request) =>
  requestHandler(request)
);

// Handle response process
axiosInstanceAdmin.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
