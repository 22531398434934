export const LinkedinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      fill="#F1FAFF"
      fillRule="evenodd"
      d="M5.155 1.488a2.292 2.292 0 100 4.584 2.292 2.292 0 000-4.584zM4.113 3.78a1.042 1.042 0 112.084 0 1.042 1.042 0 01-2.084 0zm-1.25 3.333a.625.625 0 01.625-.625h3.334a.625.625 0 01.625.625v10.834a.625.625 0 01-.625.625H3.488a.625.625 0 01-.625-.625V7.113zm1.25.625v9.584h2.084V7.738H4.113zm4.584-.625a.625.625 0 01.625-.625h3.333a.625.625 0 01.625.625v.362l.362-.156a6.494 6.494 0 011.965-.496c2.313-.21 4.34 1.607 4.34 3.94v7.184a.625.625 0 01-.625.625h-3.334a.625.625 0 01-.625-.625v-5.834a1.042 1.042 0 00-2.083 0v5.834a.625.625 0 01-.625.625H9.322a.625.625 0 01-.625-.625V7.113zm1.25.625v9.584h2.083v-5.209a2.292 2.292 0 014.583 0v5.209h2.084v-6.559c0-1.586-1.385-2.84-2.975-2.695a5.259 5.259 0 00-1.587.4l-1.233.53a.625.625 0 01-.872-.576v-.684H9.947z"
      clipRule="evenodd"></path>
  </svg>
);
