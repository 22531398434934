import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip, Typography } from "@mui/material";
import { DoNotDisturb } from "@mui/icons-material";

import {
  PRODUCT_STATUS_WITH_ID,
  USER_STATUS_IDS
} from "../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  getProductDetailsRequest,
  getProductDetailsResponse,
  getProductVersionDetailsRequest,
  getProductVersionDetailsResponse
} from "../../../../store/Products/actions";
import messages from "../../../../assets/locale/messages";
import ViewProduct from "../../../Products/ViewProduct";
import Button from "../../../../components/Button";
import ViewDeleteButton from "../../../../components/ViewDeleteButton";
import ViewEditButton from "../../../../components/ViewEditButton";
import Alert from "../../../../components/Alert";
import PublishProduct from "../PublishProduct";
import UnpublishProduct from "../UnpublishProduct";
import DeleteProduct from "../DeleteProduct";
import CancelProductEdits from "../CancelProductEdits";
import {
  checkForMultiline,
  getUserCurrency
} from "../../../../utils/Helpers";
import VerificationsNotifications from "../../Verification/VerificationsNotifications";
import "./ViewProductDetails.scss";

const ExporterProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader.showLoader);

  const productDetails = useSelector(
    (state) => state.products.productDetails
  );
  const productVersionDetails = useSelector(
    (state) => state.products.productVersionDetails
  );
  const userStatusId = useSelector(
    (state) => state.auth.login?.UserStatusId
  );
  const [productDetailsState, setProductDetailsState] = useState({});
  const [publishedProductDetails, setPublishedProductDetails] =
    useState({});
  const [publishProductModalOpen, setPublishProductModalOpen] =
    useState(false);
  const [unpublishedProductDetails, setUnPublishedProductDetails] =
    useState({});
  const [unpublishProductModalOpen, setUnpublishProductModalOpen] =
    useState(false);

  const [deletedProductDetails, setDeletedProductDetails] = useState(
    {}
  );
  const [deleteProductModalOpen, setDeleteProductModalOpen] =
    useState(false);
  const [cancelEditsProductDetails, setCancelEditsProductDetails] =
    useState({});
  const [
    cancelProductEditsModalOpen,
    setCancelProductEditsModalOpen
  ] = useState(false);
  const [isShowPublished, setIsShowPublished] = useState(false);
  const [
    isShowPublishUnpublishButton,
    setIsShowPublishUnpublishButton
  ] = useState(true);
  useEffect(() => {
    if (!productDetailsState.original) {
      if (productDetailsState.IsEdit) {
        setIsShowPublishUnpublishButton(
          !(
            productDetailsState.ProductStatusId ===
              PRODUCT_STATUS_WITH_ID.unpublished.ProductStatusId ||
            (productDetailsState.OriginalProductStatusId ===
              PRODUCT_STATUS_WITH_ID.unpublished.ProductStatusId &&
              productDetailsState.ProductStatusId ===
                PRODUCT_STATUS_WITH_ID.pending.ProductStatusId)
          )
        );
      } else {
        setIsShowPublishUnpublishButton(
          !productDetailsState.ProductStatusId !==
            PRODUCT_STATUS_WITH_ID.pending.ProductStatusId
        );
      }
    }
  }, [productDetailsState, dispatch]);
  const {
    products: {
      viewProduct: {
        viewPageTitle,
        unpublishProduct,
        publishProduct,
        cancelEdits,
        rejectedProductTitle,
        disabledProductTooltipWarning
      }
    }
  } = messages[lang];

  const handlePublishProduct = () => {
    if (
      Object.keys(productDetails).length ||
      Object.keys(productVersionDetails).length
    ) {
      setPublishedProductDetails({
        id,
        name: productDetailsState.productName,
        status: productDetailsState.ProductStatusName,
        src: ROUTE_PATHS.viewProduct
      });
      setPublishProductModalOpen(true);
    }
  };

  const handleUnpublishProduct = () => {
    if (
      (productDetails && Object.keys(productDetails).length) ||
      (productVersionDetails &&
        Object.keys(productVersionDetails).length)
    ) {
      setUnPublishedProductDetails({
        id,
        name: productDetailsState.productName,
        src: ROUTE_PATHS.viewProduct,
        status: productDetailsState.ProductStatusId,
        originalProductStatus:
          productDetailsState.OriginalProductStatusId,
        IsEdit: productDetailsState.IsEdit
      });
      setUnpublishProductModalOpen(true);
    }
  };

  const handleEdit = () => {
    navigate(ROUTE_PATHS.editProduct.replace(":id", id));
  };

  const handleCancelProductEdits = () => {
    if (
      (productDetails && Object.keys(productDetails).length) ||
      (productVersionDetails &&
        Object.keys(productVersionDetails).length)
    ) {
      setCancelEditsProductDetails({
        id,
        name: productDetailsState.productName
      });
      setCancelProductEditsModalOpen(true);
    }
  };

  const handleDeleteProduct = () => {
    if (
      (productDetails && Object.keys(productDetails).length) ||
      (productVersionDetails &&
        Object.keys(productVersionDetails).length)
    ) {
      setDeletedProductDetails({
        id,
        name: productDetailsState.productName,
        isShowcaseProduct: productDetailsState.IsInShowcase
      });
      setDeleteProductModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setPublishProductModalOpen(false);
    setPublishedProductDetails({});
    setUnpublishProductModalOpen(false);
    setUnPublishedProductDetails({});
    setDeleteProductModalOpen(false);
    setDeletedProductDetails({});
    setCancelProductEditsModalOpen(false);
    setCancelEditsProductDetails({});
  };

  useEffect(() => {
    if (id) {
      if (isShowPublished) {
        dispatch(
          getProductDetailsRequest({
            id
          })
        );
      } else {
        dispatch(
          getProductVersionDetailsRequest({
            id
          })
        );
      }
    }
  }, [id, isShowPublished]);

  useEffect(() => {
    return () => {
      dispatch(getProductDetailsResponse({}));
      dispatch(getProductVersionDetailsResponse({}));
    };
  }, []);

  useEffect(() => {
    if (Object.keys(productVersionDetails).length) {
      const {
        Name,
        HsCode,
        Description,
        DefaultImage,
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        CategoryId,
        SubCategoryId,
        Attachments,
        PriceRanges,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        OriginalProductStatusId,
        Country,
        Specifications,
        IsEdit,
        DefaultRejectionReasonMessage,
        RejectionReasonMessage,
        CompanyName,
        IsInShowcase
      } = productVersionDetails;
      setProductDetailsState({
        productName: Name,
        productHsCode: HsCode,
        productDescription: Description,
        DefaultImage,
        productImages:
          Attachments.length > 0
            ? Attachments.filter(
                (img) => img.AttachmentTypeId === 1
              ).map((img) => img.MediaName)
            : [],
        productVideo: Attachments.filter(
          (attachment) => attachment.AttachmentTypeId === 2
        )[0],
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        PriceRanges,
        currency: getUserCurrency()?.symbol,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        OriginalProductStatusId,
        Country: Country || null,
        Specifications,
        IsEdit,
        DefaultRejectionReasonMessage,
        RejectionReasonMessage,
        original: false,
        CompanyName,
        IsInShowcase
      });
    }
  }, [productVersionDetails]);

  useEffect(() => {
    if (productDetails && Object.keys(productDetails).length) {
      const {
        Name,
        HsCode,
        Description,
        DefaultImage,
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        Attachments,
        PriceRanges,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        Country,
        Specifications,
        IsEdit,
        DefaultRejectionReasonMessage,
        RejectionReasonMessage,
        CompanyName,
        IsInShowcase
      } = productDetails;
      setProductDetailsState({
        productName: Name,
        productHsCode: HsCode,
        productDescription: Description,
        DefaultImage,
        productImages:
          Attachments.length > 0
            ? Attachments.filter(
                (img) => img.AttachmentTypeId === 1
              ).map((img) => img.MediaName)
            : [],
        productVideo: Attachments.filter(
          (attachment) => attachment.AttachmentTypeId === 2
        )[0],
        MinOrderQuantity,
        MoqUnitName,
        MoqOtherUnit,
        CategoryName,
        SubCategoryName,
        PriceRanges,
        currency: getUserCurrency()?.symbol,
        Keywords,
        ProductStatusName,
        ProductStatusId,
        Country: Country || null,
        Specifications,
        IsEdit,
        DefaultRejectionReasonMessage,
        RejectionReasonMessage,
        original: true,
        CompanyName,
        IsInShowcase
      });
    }
  }, [productDetails]);

  const renderRejectionReason = () => (
    <div className="rejection-reason">
      <p className="fsize-16 fweight-500 text-error mb-1">
        {productDetailsState?.DefaultRejectionReasonMessage ||
          rejectedProductTitle}
      </p>
      {productDetailsState?.RejectionReasonMessage && (
        <p className="text-light-grey mb-0">
          {checkForMultiline(
            productDetailsState?.RejectionReasonMessage
          )}
        </p>
      )}
    </div>
  );

  const renderPublishButton = () => (
    <Button
      disabled={
        productDetailsState.ProductStatusId ===
        PRODUCT_STATUS_WITH_ID.rejected.ProductStatusId
      }
      labelClass=" text-white d-flex align-items-center fw-bold py-1 ps-1 pe-1 fsize-14"
      label={
        <span className="d-flex align-items-center">
          <span>
            {productDetailsState.ProductStatusId ===
              PRODUCT_STATUS_WITH_ID.pending.ProductStatusId ||
            productDetailsState.ProductStatusId ===
              PRODUCT_STATUS_WITH_ID.published.ProductStatusId
              ? unpublishProduct
              : publishProduct}
          </span>
        </span>
      }
      onClick={() => {
        productDetailsState.ProductStatusId ===
          PRODUCT_STATUS_WITH_ID.pending.ProductStatusId ||
        productDetailsState.ProductStatusId ===
          PRODUCT_STATUS_WITH_ID.published.ProductStatusId
          ? handleUnpublishProduct()
          : handlePublishProduct();
      }}
    />
  );

  return (
    <div className="view-product-details-container pb-4 d-flex flex-column">
      {[
        USER_STATUS_IDS.registered,
        USER_STATUS_IDS.verified,
        USER_STATUS_IDS.pendingBusinessVerified
      ].includes(userStatusId) && (
        <div className="mb-3">
          <VerificationsNotifications />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="page-title-buttons-container pb-4 d-flex justify-content-between align-items-center">
            <div className="page-title">
              <Typography variant="h5" className="title-color">
                {viewPageTitle}
              </Typography>
            </div>
            <div className="title-buttons-container d-flex justify-content-center gap-4 align-items-center">
              <div className="d-flex gap-3 flex-md-row flex-column">
                {productDetailsState?.IsEdit &&
                  productDetailsState &&
                  !!Object.keys(productDetailsState).length && (
                    <Button
                      danger
                      labelClass=" text-white  d-flex align-items-center fw-bold py-1 ps-1 pe-1 fsize-14"
                      label={
                        <span className="d-flex align-items-center">
                          <span>{cancelEdits}</span>
                        </span>
                      }
                      onClick={handleCancelProductEdits}
                    />
                  )}

                {isShowPublishUnpublishButton &&
                  productDetailsState &&
                  !!Object.keys(productDetailsState).length &&
                  (productDetailsState.ProductStatusId ===
                  PRODUCT_STATUS_WITH_ID.rejected.ProductStatusId ? (
                    <Tooltip
                      classes={{
                        tooltip: "light-blue-tooltip-fill-icon"
                      }}
                      className="text-secondary ms-md-2 mt-md-0 mt-1"
                      title={disabledProductTooltipWarning}>
                      <div>{renderPublishButton()}</div>
                    </Tooltip>
                  ) : (
                    renderPublishButton()
                  ))}
              </div>
              {productDetailsState &&
                !!Object.keys(productDetailsState).length && (
                  <div className="d-flex gap-3">
                    <ViewEditButton onClick={handleEdit} />
                    <ViewDeleteButton onClick={handleDeleteProduct} />
                  </div>
                )}
            </div>
          </div>

          {productDetailsState.ProductStatusId ===
            PRODUCT_STATUS_WITH_ID.rejected.ProductStatusId &&
            (productDetailsState?.DefaultRejectionReasonMessage ||
              productDetailsState?.RejectionReasonMessage) && (
              <div className="rejection-reason-container mb-3">
                <Alert
                  type="error"
                  content={renderRejectionReason()}
                  icon={<DoNotDisturb />}
                />
              </div>
            )}

          <ViewProduct
            productDetails={productDetailsState}
            fromExporter
            isShowPublished={
              productDetailsState.IsEdit &&
              (productDetailsState.ProductStatusId ===
                PRODUCT_STATUS_WITH_ID.draft.ProductStatusId ||
                productDetailsState.ProductStatusId ===
                  PRODUCT_STATUS_WITH_ID.pending.ProductStatusId ||
                productDetailsState.ProductStatusId ===
                  PRODUCT_STATUS_WITH_ID.published.ProductStatusId)
            }
            handleShowPublished={() => {
              setIsShowPublished(!isShowPublished);
            }}
          />

          {publishProductModalOpen && (
            <PublishProduct
              open={publishProductModalOpen}
              closeModal={handleCloseModal}
              productInfo={publishedProductDetails}
            />
          )}
          {unpublishProductModalOpen && (
            <UnpublishProduct
              open={unpublishProductModalOpen}
              closeModal={handleCloseModal}
              productInfo={unpublishedProductDetails}
            />
          )}
          {deleteProductModalOpen && (
            <DeleteProduct
              open={deleteProductModalOpen}
              closeModal={handleCloseModal}
              productInfo={deletedProductDetails}
            />
          )}
          {cancelProductEditsModalOpen && (
            <CancelProductEdits
              open={cancelProductEditsModalOpen}
              closeModal={handleCloseModal}
              productInfo={cancelEditsProductDetails}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ExporterProductDetails;
