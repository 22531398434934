export const SET_VERIFICATION_STEP = "SET_VERIFICATION_STEP";
export const SET_SELECTED_VERIFICATION_METHOD =
  "SET_SELECTED_VERIFICATION_METHOD";

export const GET_VERIFICATION_METHODS_REQUEST =
  "GET_VERIFICATION_METHODS_REQUEST";
export const GET_VERIFICATION_METHODS_RESPONSE =
  "GET_VERIFICATION_METHODS_RESPONSE";

export const GET_STRIPE_CLIENT_SECRET_REQUEST =
  "GET_STRIPE_CLIENT_SECRET_REQUEST";
export const GET_STRIPE_CLIENT_SECRET_RESPONSE =
  "GET_STRIPE_CLIENT_SECRET_RESPONSE";

export const SET_PAYMENT_FAILURE_ERR_MSG =
  "SET_PAYMENT_FAILURE_ERR_MSG";

export const SET_PAYMENT_INTENT_ID = "SET_PAYMENT_INTENT_ID";

export const CONFIRM_PAYMENT_INTENT_REQUEST =
  "CONFIRM_PAYMENT_INTENT_REQUEST";

export const GET_EXGATES_BANK_INFO_REQUEST =
  "GET_EXGATES_BANK_INFO_REQUEST";
export const GET_EXGATES_BANK_INFO_RESPONSE =
  "GET_EXGATES_BANK_INFO_RESPONSE";

export const SEND_BANK_TRANSFER_REQUEST =
  "SEND_BANK_TRANSFER_REQUEST";

export const SET_BANK_TRANSFER_SUCCESS = "SET_BANK_TRANSFER_SUCCESS";

export const GET_COMPANY_VERIFICATION_STATUS_REQUEST =
  "GET_COMPANY_VERIFICATION_STATUS_REQUEST";
export const GET_COMPANY_VERIFICATION_STATUS_RESPONSE =
  "GET_COMPANY_VERIFICATION_STATUS_RESPONSE";

export const REQUEST_OFF_PLATFORM_VERIFICATION =
  "REQUEST_OFF_PLATFORM_VERIFICATION";

export const REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_REQUEST =
  "REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_REQUEST";
export const REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE =
  "REQUEST_OFF_PLATFORM_REQUIRED_DOCUMENTS_RESPONSE";

export const UPLOAD_OFF_PLATFORM_REQUESTED_DOCUMENTS =
  "UPLOAD_OFF_PLATFORM_REQUESTED_DOCUMENTS";

export const EDIT_OFF_PLATFORM_REQUESTED_DOCUMENTS =
  "EDIT_OFF_PLATFORM_REQUESTED_DOCUMENTS";

export const REQUEST_OFF_PLATFORM_REQUEST_DATA_REQUEST =
  "REQUEST_OFF_PLATFORM_REQUEST_DATA_REQUEST";
export const REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE =
  "REQUEST_OFF_PLATFORM_REQUEST_DATA_RESPONSE";

export const CONFIRM_OFF_PLATFORM_PAYMENT_INTENT_REQUEST =
  "CONFIRM_OFF_PLATFORM_PAYMENT_INTENT_REQUEST";

export const CANCEL_VERIFICATION_REQUEST =
  "CANCEL_VERIFICATION_REQUEST";
