export const ViewDeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21">
    <path
      stroke="#D32F2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18 5.78a84.752 84.752 0 00-8.35-.416c-1.65 0-3.3.083-4.95.25L3 5.78M7.584 4.938l.183-1.091c.134-.792.234-1.384 1.642-1.384h2.183c1.409 0 1.517.625 1.642 1.392l.183 1.083M16.208 8.414l-.542 8.391c-.092 1.309-.167 2.325-2.492 2.325h-5.35c-2.325 0-2.4-1.016-2.491-2.325L4.79 8.414M9.107 14.547h2.775M8.416 11.213h4.167"></path>
  </svg>
);
