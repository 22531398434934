export const SignOutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19">
    <path
      fill="#8F8F8F"
      d="M6.756 15.617h-2.25a1.978 1.978 0 01-2.063-1.898V4.39a1.98 1.98 0 012.063-1.897h2.25a.563.563 0 010 1.125h-2.25a.87.87 0 00-.938.75v9.352a.87.87 0 00.938.75h2.25a.563.563 0 010 1.126v.022zm5.25-3a.553.553 0 01-.398-.165.562.562 0 010-.795l2.603-2.602-2.603-2.603a.563.563 0 11.795-.795l3 3a.563.563 0 010 .795l-3 3a.556.556 0 01-.397.165z"></path>
    <path
      fill="#8F8F8F"
      d="M15.006 9.617h-8.25a.563.563 0 010-1.125h8.25a.563.563 0 010 1.125z"></path>
  </svg>
);
