import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { FormHelperText, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import messages from "../../assets/locale/messages";
import "./DatePicker.scss";

const DatePicker = ({
  value,
  onChange,
  name,
  id,
  label,
  required,
  placeholder,
  wrapperClass,
  labelClass,
  inputClass,
  helperTextClass,
  disabled,
  isInputHasErr,
  errMsg,
  helperText,
  readOnly,
  onError,
  disableFuture,
  disablePast,
  format,
  hintMessage,
  views,
  minDate,
  maxDate,
  yearOnlyDate,
  addBottomMargin
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations, shared } = messages[lang];

  const [date, setDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);
  const [minDateObj, setMinDateObj] = useState(null);
  const [maxDateObj, setMaxDateObj] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    if (value) {
      if (yearOnlyDate) {
        const formattedDate = moment(value, "YYYY");
        setDate(formattedDate);
      } else {
        const formattedDate = moment(value, "YYYY-MM-DD");
        setDate(formattedDate);
      }
    } else {
      setDate(null);
    }
  }, [value]);

  useEffect(() => {
    if (minDate) {
      const formattedDate = moment(minDate, "YYYY-MM-DD");
      setMinDateObj(formattedDate);
    }
  }, [minDate]);

  useEffect(() => {
    if (maxDate) {
      const formattedDate = moment(maxDate, "YYYY-MM-DD");
      setMaxDateObj(formattedDate);
    }
  }, [maxDate]);

  const handleDateChange = (selectedDate) => {
    if (selectedDate && moment(selectedDate).isValid()) {
      setInvalidDate(false);
      setDate(selectedDate);
      const formattedDate = selectedDate
        ? yearOnlyDate
          ? moment(selectedDate).format("YYYY")
          : moment(selectedDate).format("YYYY-MM-DD")
        : null;
      onChange(formattedDate);
    } else {
      setInvalidDate(true);
      setDate(selectedDate);
    }
  };

  return (
    <Box
      className={`date-picker-wrapper ${
        disabled ? "disabled" : ""
      } ${wrapperClass}`}
      sx={{
        marginBottom: addBottomMargin && pickerOpen ? "20rem" : "0"
      }}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={"en"}>
        <MuiDatePicker
          label={
            <span
              className={`fsize-13 fweight-300 title-color ${labelClass}`}>
              {label}
            </span>
          }
          name={name}
          id={id || name}
          className={`custom-date-picker ${inputClass} ${
            invalidDate || isInputHasErr ? "error-date-border" : ""
          }`}
          onOpen={() => setPickerOpen(true)}
          onClose={() => setPickerOpen(false)}
          closeOnSelect={false}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          format={format}
          onError={onError}
          onChange={(value) => handleDateChange(value)}
          value={date}
          defaultValue={date}
          readOnly={readOnly}
          required={required}
          views={views}
          minDate={minDateObj}
          maxDate={maxDateObj}
          localeText={{ okButtonLabel: shared.ok }}
          slots={{
            openPickerIcon: CalendarMonthIcon
          }}
          slotProps={{
            textField: {
              placeholder, // Show placeholder when no value is present
              InputLabelProps: {
                // Set shrink based on whether there's a value or if the picker is focused
                shrink: true
              },
              inputProps: {
                readOnly: true // Disable typing
              }
            },
            actionBar: {
              // keep in case of cancel button needed for ui
              // actions: ["cancel", "accept"] // explicitly define buttons
              actions: ["accept"]
            }
          }}
          InputProps={{
            startAdornment: !date && (
              <span
                style={{
                  position: "absolute",
                  color: "#b0b0b0",
                  pointerEvents: "none"
                }}>
                {placeholder}
              </span>
            )
          }}
        />
      </LocalizationProvider>
      <FormHelperText
        error={isInputHasErr}
        className={`${helperTextClass || "fsize-12"} `}>
        {isInputHasErr ? inputsValidations[errMsg] : helperText}
      </FormHelperText>

      {hintMessage && (
        <div
          className={`hint-msg  ${
            invalidDate || isInputHasErr ? "mt-5" : "mt-2"
          }`}>
          {hintMessage}
        </div>
      )}
    </Box>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onError: PropTypes.func,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  format: PropTypes.string,
  helperTextClass: PropTypes.string,
  mintMessage: PropTypes.string,
  views: PropTypes.array,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  helperText: PropTypes.string,
  hintMessage: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yearOnlyDate: PropTypes.bool,
  addBottomMargin: PropTypes.bool
};

DatePicker.defaultProps = {
  format: "DD/MM/YYYY"
};
