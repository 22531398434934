import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import successImg from "../../../../assets/images/vectors/green-check.png";
import Button from "../../../../components/Button";
import { USER_TYPES_IDS } from "../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";

const RegisterSuccess = () => {
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const { registeration } = messages[lang];

  const [searchParams] = useSearchParams();
  const userType =
    parseInt(searchParams.get("user_type")) ||
    USER_TYPES_IDS.importer;

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${40}px  - ${70}px)`,
        marginTop: "15rem"
      }}
      className="registeration-success-page w-100">
      <Box
        className="text-center faint-grey-bg border-radius-10 px-3 px-md-5 py-5 position-relative"
        sx={{
          width: {
            xs: "95%",
            sm: "90%",
            md: "80%",
            lg: "50%"
          },
          margin: "0 auto"
        }}>
        <Box
          className="vector-section mb-3"
          sx={{
            position: "absolute",
            top: {
              xs: "-8%",
              sm: "-20%"
            },
            left: "40%",
            width: {
              xs: "5rem",
              sm: "auto"
            }
          }}>
          <img src={successImg} className="w-100" />
        </Box>
        <Box
          className="content-section mt-4"
          sx={{
            margin: "0 auto",
            width: {
              xs: "100%",
              sm: "80%",
              md: "65%",
              lg: "85%",
              xl: "75%"
            }
          }}>
          <h3 className="fsize-24 fweight-700 title-color mb-4">
            {registeration.successPage.congratulations}
          </h3>
          <p className="text-light-grey fsize-16 mb-4">
            {userType === USER_TYPES_IDS.exporter
              ? registeration.successPage.message1
              : registeration.successPage.message3}
          </p>
          {userType === USER_TYPES_IDS.exporter && (
            <p className="text-light-grey fsize-16 mb-4">
              {registeration.successPage.message2}
            </p>
          )}
          {userType === USER_TYPES_IDS.importer ? (
            <div className="actions-section d-flex justify-content-center">
              <Button
                label={registeration.successPage.goHome}
                onClick={() => navigate(ROUTE_PATHS.home)}
              />
            </div>
          ) : (
            <div className="actions-section d-flex align-items-center justify-content-between flex-column flex-md-row">
              <Button
                label={registeration.successPage.skip}
                outlined
                className={"mb-3 mb-xl-0"}
                onClick={() => navigate(ROUTE_PATHS.home)}
              />
              <Button
                label={registeration.successPage.verifyBusiness}
                onClick={() =>
                  navigate(ROUTE_PATHS.login, {
                    state: {
                      prevLocation: ROUTE_PATHS.businessVerification
                    }
                  })
                }
              />
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterSuccess;
