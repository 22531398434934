export const MaintenanceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="41"
    fill="none"
    viewBox="0 0 42 41"
    {...props}>
    <path
      fill="#fff"
      d="M1.598 3.817l2.636 4.665a.664.664 0 00.412.315l2.46.622 8.437 8.53.932-.943-8.569-8.662a.656.656 0 00-.306-.176l-2.363-.6-2.245-3.973L4.917 1.65l3.932 2.272.593 2.39a.667.667 0 00.173.31l8.569 8.662.932-.942-8.437-8.53-.615-2.485a.668.668 0 00-.312-.417L5.138.243a.653.653 0 00-.791.107L1.711 3.016a.669.669 0 00-.113.801zm10.365 24.645a.661.661 0 00-.565-.323H7.443a.652.652 0 00-.565.323l-1.977 3.332a.672.672 0 000 .686l1.977 3.332a.663.663 0 00.565.323h3.955a.654.654 0 00.565-.323l1.977-3.331a.672.672 0 000-.687l-1.977-3.332zm-.938 6.34H7.816l-1.582-2.665 1.582-2.665h3.209l1.582 2.665-1.582 2.666z"></path>
    <path
      fill="#fff"
      d="M33.147 16.146c4.354.015 7.895-3.54 7.91-7.942a8.055 8.055 0 00-.243-1.986.659.659 0 00-1.105-.311l-4.103 4.145-3.255-1.096-1.086-3.29 4.103-4.15a.668.668 0 00-.008-.95.657.657 0 00-.301-.168c-4.222-1.08-8.51 1.503-9.58 5.77a8.04 8.04 0 00-.08 3.543L10.962 24.305a7.691 7.691 0 00-1.544-.162c-4.368 0-7.91 3.58-7.91 7.996s3.542 7.996 7.91 7.996 7.91-3.58 7.91-7.996a7.918 7.918 0 00-.16-1.561l3.455-3.494 1.511 1.528a.656.656 0 00.933 0l.33-.333a.736.736 0 011.044 0c.29.29.29.763.002 1.055l-.002.002-.33.333a.672.672 0 000 .942l7.864 7.949a5.279 5.279 0 007.523.034 5.42 5.42 0 000-7.64l-7.863-7.949a.654.654 0 00-.932 0l-.33.333a.736.736 0 01-1.045 0 .752.752 0 01-.001-1.055l.001-.002.33-.333a.671.671 0 000-.942l-1.511-1.528 3.455-3.494c.508.106 1.026.16 1.544.162zm2.59 22.657c-.479-.001-.954-.089-1.402-.259l5.148-5.204c.777 2.089-.268 4.419-2.335 5.204-.451.172-.929.26-1.41.259zm-7.467-17.32c-.73.881-.615 2.193.255 2.93a2.04 2.04 0 002.645-.001l7.397 7.484c.08.082.155.168.228.255l-5.636 5.697a3.838 3.838 0 01-.252-.23l-7.402-7.485c.73-.88.615-2.192-.255-2.93a2.04 2.04 0 00-2.645.002l-1.055-1.066 5.665-5.719 1.055 1.064zm2.662-6.701L15.979 29.895a.669.669 0 00-.174.634c.904 3.59-1.241 7.241-4.793 8.155-3.55.914-7.163-1.255-8.067-4.845-.904-3.59 1.242-7.242 4.793-8.156a6.567 6.567 0 013.274 0 .658.658 0 00.627-.176l14.948-15.114a.667.667 0 00.174-.633c-.898-3.548 1.219-7.16 4.728-8.069a6.494 6.494 0 012.033-.194l-3.477 3.517a.668.668 0 00-.16.681l1.318 3.998a.667.667 0 00.418.421l3.955 1.333a.653.653 0 00.674-.16l3.477-3.515c.008.125.012.252.012.378.018 3.661-2.903 6.645-6.524 6.663a6.495 6.495 0 01-1.66-.207.653.653 0 00-.627.175h.003z"></path>
    <path
      fill="#fff"
      d="M.485 3.817L3.12 8.482a.663.663 0 00.412.315l2.46.622 8.437 8.53.932-.943-8.569-8.662a.656.656 0 00-.306-.176l-2.363-.6-2.245-3.973L3.803 1.65l3.933 2.272.593 2.39a.666.666 0 00.173.31l8.569 8.662.932-.942-8.437-8.53-.615-2.485a.67.67 0 00-.312-.417L4.025.243a.653.653 0 00-.791.107L.597 3.016a.669.669 0 00-.112.801zm10.364 24.645a.663.663 0 00-.565-.323H6.33a.652.652 0 00-.565.323l-1.978 3.332a.672.672 0 000 .686l1.978 3.332a.663.663 0 00.565.323h3.954a.654.654 0 00.565-.323l1.977-3.331a.672.672 0 000-.687l-1.977-3.332zm-.938 6.34H6.703L5.12 32.138l1.582-2.665H9.91l1.582 2.665-1.582 2.666z"></path>
    <path
      fill="#fff"
      d="M32.034 16.146c4.353.015 7.895-3.54 7.91-7.942a8.055 8.055 0 00-.243-1.986.659.659 0 00-1.105-.311l-4.103 4.145-3.256-1.096-1.085-3.29 4.103-4.15a.668.668 0 00-.008-.95.657.657 0 00-.301-.168c-4.222-1.08-8.51 1.503-9.58 5.77a8.04 8.04 0 00-.08 3.543L9.85 24.305a7.691 7.691 0 00-1.544-.162c-4.368 0-7.91 3.58-7.91 7.996s3.542 7.996 7.91 7.996 7.91-3.58 7.91-7.996a7.925 7.925 0 00-.16-1.561l3.455-3.494 1.511 1.528a.656.656 0 00.932 0l.33-.333a.736.736 0 011.045 0c.289.29.29.763.001 1.055l-.001.002-.33.333a.672.672 0 000 .942l7.863 7.949a5.279 5.279 0 007.523.034 5.42 5.42 0 000-7.64l-7.862-7.949a.654.654 0 00-.932 0l-.33.333a.736.736 0 01-1.045 0 .752.752 0 01-.001-1.055l.001-.002.33-.333a.671.671 0 000-.942l-1.512-1.528 3.456-3.494c.508.106 1.025.16 1.544.162zm2.59 22.657c-.479-.001-.954-.089-1.402-.259l5.148-5.204c.777 2.089-.269 4.419-2.335 5.204-.451.172-.93.26-1.411.259zm-7.467-17.32c-.73.881-.616 2.193.255 2.93a2.04 2.04 0 002.645-.001l7.396 7.484c.08.082.156.168.228.255l-5.635 5.697a3.838 3.838 0 01-.252-.23l-7.402-7.485c.73-.88.615-2.192-.255-2.93a2.04 2.04 0 00-2.646.002l-1.054-1.066 5.665-5.719 1.055 1.064zm2.661-6.701L14.866 29.895a.669.669 0 00-.174.634c.904 3.59-1.242 7.241-4.793 8.155-3.551.914-7.163-1.255-8.067-4.845-.904-3.59 1.241-7.242 4.793-8.156a6.567 6.567 0 013.274 0 .658.658 0 00.626-.176l14.949-15.114a.667.667 0 00.173-.633c-.898-3.548 1.22-7.16 4.729-8.069a6.495 6.495 0 012.032-.194l-3.476 3.517a.669.669 0 00-.16.681l1.318 3.998a.667.667 0 00.418.421l3.954 1.333a.653.653 0 00.674-.16l3.477-3.515c.008.125.012.252.012.378.019 3.661-2.902 6.645-6.524 6.663a6.495 6.495 0 01-1.66-.207.653.653 0 00-.626.175h.003z"></path>
  </svg>
);
