export const FilledCheckBox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
    {...props}>
    <rect
      width="20"
      height="20"
      x="0.215"
      fill="#0071BC"
      rx="6"></rect>
    <path
      fill="#fff"
      d="M13.584 5.858a.66.66 0 111.005.854L8.981 13.31a.66.66 0 01-.94.065l-2.97-2.639a.66.66 0 01.877-.986l2.465 2.191 5.17-6.083z"></path>
  </svg>
);
