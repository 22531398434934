import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../../assets/locale/messages";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import {
  addSubAccountRequest,
  editSubAccountProfileRequest,
  getSubAccountProfileRequest,
  getSubAccountProfileResponse
} from "../../../../store/SubAccounts/actions";
import {
  ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
  EMAIL_REGEX
} from "../../../../utils/Patterns";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import {
  SUB_ACCOUNT_ROLE_ID
  // SUBSCRIPTION_PLAN_FEATURES_IDS,
  // USER_STATUS_IDS
} from "../../../../utils/Constants";

const AddEditSubAccount = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.locale.lang);
  const subAccountProfile = useSelector(
    (state) => state.subAccounts.subAccountProfile
  );
  // const userStatusId = useSelector(
  //   (state) => state.auth.login?.UserStatusId
  // );
  // const activeSubAccountsCount = useSelector(
  //   (state) => state.subAccounts.activeSubAccountsCount
  // );
  // const userPlan = useSelector(
  //   (state) => state.subscription.userPlan
  // );

  // const [allowedSubaccountsCount, setAllowedSubaccountsCount] =
  //   useState(null);

  const {
    shared,
    subAccounts: {
      addEditSubAccount,
      addEditSubAccount: {
        addNewSubAccount,
        editSubAccount,
        labels: { fullName, emailAddress, theRole },
        placeholders: { name, email, role }
      }
    }
  } = messages[lang];

  // useEffect(() => {
  //   setAllowedSubaccountsCount(
  //     +userPlan?.Features?.find(
  //       (feature) =>
  //         feature.Id ===
  //         SUBSCRIPTION_PLAN_FEATURES_IDS.subaccountsNumber
  //     )?.Value
  //   );
  // }, [userPlan?.Features]);

  // useEffect(() => {
  //   if (
  //     userStatusId === USER_STATUS_IDS.subscribed &&
  //     activeSubAccountsCount === allowedSubaccountsCount
  //   ) {
  //     navigate(ROUTE_PATHS.subAccounts);
  //   }
  // }, [userStatusId, activeSubAccountsCount, allowedSubaccountsCount]);

  useEffect(() => {
    if (id) {
      dispatch(getSubAccountProfileRequest({ params: { id } }));
    }
    return () => {
      dispatch(getSubAccountProfileResponse({}));
    };
  }, [id, dispatch]);

  const roleOptions = [
    {
      id: SUB_ACCOUNT_ROLE_ID.manager,
      label: addEditSubAccount.roleOptions.manager
    },
    {
      id: SUB_ACCOUNT_ROLE_ID.agent,
      label: addEditSubAccount.roleOptions.agent
    }
  ];

  const formik = useFormik({
    initialValues: { Name: "", Email: "", Role: null },
    validationSchema: Yup.object({
      Name: Yup.string()
        .max(50, "fullNameMaxLength")
        .matches(
          ALLOW_ALL_WITH_MANDATORY_CHARACTERS,
          "fullNameNoSpecialChars"
        )
        .required("fullNameRequired"),
      Email: Yup.string()
        .matches(EMAIL_REGEX, "validEmailFormat")
        .required("emailRequired"),
      Role: Yup.object()
        .shape({
          id: Yup.number().required(),
          label: Yup.string().required()
        })
        .required("roleRequired")
    }),
    onSubmit: ({ Name, Email, Role }) => {
      const subAccountData = {
        Name,
        RoleId: Role.id
      };

      if (id) {
        subAccountData.Id = id;
        dispatch(
          editSubAccountProfileRequest({
            data: {
              ...subAccountData
            },
            navigate
          })
        );
      } else {
        subAccountData.Email = Email;
        dispatch(
          addSubAccountRequest({
            data: subAccountData,
            navigate
          })
        );
      }
    }
  });

  const {
    setValues,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    dirty,
    isValid,
    handleSubmit,
    touched
  } = formik;

  useEffect(() => {
    if (subAccountProfile && Object.keys(subAccountProfile).length) {
      const { Name, Email, RoleId, RoleName } = subAccountProfile;
      setValues({
        Name,
        Email,
        Role: { id: RoleId, label: RoleName }
      });
    }
  }, [subAccountProfile, setValues]);

  const renderFormContent = () => (
    <div className="add-edit-sub-account-form ">
      <form noValidate onSubmit={handleSubmit}>
        <div className="main-data-section bg-white border-radius-10 p-4 mb-4">
          <div className="row">
            <div className="col-md-6">
              <Input
                required
                inputWrapperClass="mb-4"
                label={fullName}
                placeholder={name}
                onChange={(value) => {
                  if (!value || value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("Name");
                    setFieldValue("Name", modifiedValue);
                  }
                }}
                value={values.Name}
                isInputHasErr={!!(touched.Name && errors.Name)}
                errMsg={errors.Name}
                name="Name"
                id="Name"
              />
              <Input
                required
                inputWrapperClass="mb-4"
                label={emailAddress}
                placeholder={email}
                disabled={!!id}
                onChange={(value) => {
                  if (!value || value.trim()) {
                    const modifiedValue = value.replace(
                      /\s\s+/g,
                      " "
                    );
                    setFieldTouched("Email");
                    setFieldValue("Email", modifiedValue);
                  }
                }}
                value={values.Email}
                isInputHasErr={!!(touched.Email && errors.Email)}
                errMsg={errors.Email}
                name="Email"
                id="Email"
              />
              <Select
                required
                wrapperClass="mb-4"
                labelClassName=""
                label={theRole}
                placeholder={role}
                options={roleOptions}
                onChange={(value) => {
                  setFieldTouched("Role");
                  setFieldValue("Role", value);
                }}
                value={values.Role}
                hasError={!!(touched.Role && errors.Role)}
                errMsg={errors.Role}
                id="Role"
                name="Role"
              />
            </div>
          </div>
        </div>

        <div className="actions-section bg-white border-radius-10 p-4 d-flex justify-content-between align-items-center">
          <Button
            key={shared.cancel}
            outlined
            label={shared.cancel}
            onClick={() => navigate(ROUTE_PATHS.subAccounts)}
            customSpacing={"px-4 px-md-5"}
          />
          <Button
            label={id ? shared.save : shared.add}
            type="submit"
            disabled={!dirty || !isValid}
            customSpacing={"px-5 py-2 px-md-5"}
          />
        </div>
      </form>
    </div>
  );

  return (
    <div className="add-sub-account-container">
      <div className="page-title">
        <h3 className="fweight-700 fsize-22 mb-4 title-color">
          {id ? editSubAccount : addNewSubAccount}
        </h3>
      </div>
      {id
        ? !!Object.keys(subAccountProfile).length &&
          renderFormContent()
        : renderFormContent()}
    </div>
  );
};

export default AddEditSubAccount;
