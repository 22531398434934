import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../../../../components/Alert";
import messages from "../../../../../assets/locale/messages";
import { ChipInfoIcon } from "../../../../../utils/Icons";
import { BANNER_COLORS } from "../../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const PendingDocumentsUpload = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    verification: {
      verificationNotifications: { pendingDocsUpload }
    }
  } = messages[lang];

  return (
    <Alert
      type="info"
      content={
        <>
          <p className="mb-0 fsize-16 light-text-color">
            {pendingDocsUpload.msg1}
          </p>
          <p className="mb-0 fsize-16 light-text-color">
            <span className="text-primary text-underline pointer me-2 fweight-600">
              <Link to={ROUTE_PATHS.businessVerification}>
                {pendingDocsUpload.msg2}
              </Link>
            </span>
            {pendingDocsUpload.msg3}
          </p>
        </>
      }
      icon={<ChipInfoIcon color={BANNER_COLORS.info} />}
    />
  );
};

export default PendingDocumentsUpload;
