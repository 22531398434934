export const InstagramIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <path
      stroke="#F1FAFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.988 13.78a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"></path>
    <path
      stroke="#F1FAFF"
      strokeWidth="1.5"
      d="M3.488 13.78V7.113a4.167 4.167 0 014.167-4.167h6.667a4.167 4.167 0 014.166 4.167v6.667a4.167 4.167 0 01-4.166 4.166H7.655a4.167 4.167 0 01-4.167-4.166z"></path>
    <path
      stroke="#F1FAFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.572 5.871l.009-.009"></path>
  </svg>
);
